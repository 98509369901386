<template>
  <BaseModal
    :heading="$t('customers.tags.apply_tags')"
    small
    :loading="loading"
    useForm
    @close="$emit('close')"
    @submit="submit"
  >
    <BaseInputLabel :text="$t('customers.tags.select_tags')" mb />
    <div :class="$style.tags">
      <BaseCheckbox
        v-for="(tag, index) in tags"
        :key="index"
        :modelValue="selectedTagIds.includes(tag.id)"
        :label="tag.name"
        @update:modelValue="
          selectedTagIds.includes(tag.id)
            ? (selectedTagIds = selectedTagIds.filter((id) => id !== tag.id))
            : selectedTagIds.push(tag.id)
        "
        v-test="'customer-tag'"
      />
    </div>
    <template #footer>
      <BaseButton submitForm v-test="'tags-submit'">
        {{
          $t(
            'customers.tags.apply_to_customers',
            { amount: selectedAmount },
            selectedAmount
          )
        }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useQuery, useMutation } from '@vue/apollo-composable';
import { GET_CUSTOMER_TAGS } from '../graphql';
import { MASSIVE_ADD_CUSTOMER_TAGS } from './graphql';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  filters: any[];
  searchQuery: string;
}>();

const emit = defineEmits(['close']);

const { result, loading } = useQuery(GET_CUSTOMER_TAGS, {
  fetchPolicy: 'cache-first'
});

const selectedTagIds = ref<number[]>([]);
const tags = computed(() =>
  result.value?.customerTags
    ? [
        ...result.value.customerTags.salonizedTags,
        ...result.value.customerTags.companyTags
      ]
    : []
);

const totalCount = inject<number>('totalCount');
const selectedCustomers = inject<any>('selectedCustomers');
const selectedAmount = computed(
  () => selectedCustomers.value.length || totalCount.value
);

const { t } = useI18n();
const mixpanel = inject<any>('mixpanel');

const submit = () => {
  mixpanel.track('batch_action_chosen', {
    action_name: 'tag',
    tag_ids: selectedTagIds.value
  });
  const { mutate } = useMutation(MASSIVE_ADD_CUSTOMER_TAGS);
  mutate({
    input: {
      customerIds: selectedCustomers.value,
      filters: props.filters,
      query: props.searchQuery,
      tagIds: selectedTagIds.value
    }
  }).then(() => {
    flash(t('global.flash.customers_updated'));
    emit('close');
  });
};
</script>

<style lang="scss" module>
.tags {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
