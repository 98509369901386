<template>
  <div v-if="details" :class="$style.asideDetails">
    <div
      v-for="(detail, index) in details"
      :key="index"
      :class="$style.asideDetail"
    >
      <div>
        <BaseHeading size="xs" :mb="0.25">
          {{ detail.label }}
        </BaseHeading>
        <div :class="$style.asideDetailValue">
          <div v-if="detail.icon" :class="$style.asideDetailIcon">
            <BaseIcon
              :name="detail.icon.name"
              :color="detail.icon.red ? 'error' : 'default'"
              :tooltip="detail.icon.tooltip"
              size="s"
            />
          </div>
          <div v-if="detail.type === 'dateTime'">
            {{ filters.dateTime(detail.value) }}
          </div>
          <div v-else-if="detail.type === 'date'">
            {{ filters.date(detail.value) }}
          </div>
          <div v-else-if="detail.type === 'currency'">
            {{ filters.currency(detail.value) }}
          </div>
          <BaseLabel
            v-else-if="detail.type === 'state'"
            :state="detail.value"
          />
          <BaseText v-else-if="detail.type === 'html'">
            <span v-html="filters.formatEnters(detail.value)" />
          </BaseText>
          <div v-else>{{ detail.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    details: {
      type: Array,
      required: false
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.asideDetails {
  padding: $spacing;
  background-color: $grey-light;

  &:last-child {
    height: 100%;
    border-bottom-right-radius: $modal-radius;
  }
}

.asideDetail {
  display: flex;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin-bottom: $spacing;
  }
}

.asideDetailValue {
  display: flex;
  align-items: center;
}

.asideDetailIcon {
  margin-left: -3px;
  margin-right: $spacing * 0.25;
}
</style>
