<template>
  <div>
    <BaseText>
      {{ $t('global.color') }}
    </BaseText>
    <BaseRadio
      v-model="colorOption"
      mt
      mb
      :label="$t('global.color')"
      :options="colorOptions"
      @update:modelValue="onColorOptionSelect"
      v-test="'colorOptions'"
    />
    <div
      v-show="colorOption === 'other'"
      class="mt-1 mb-1"
      v-test="'colorPicker'"
    >
      <BaseHeading size="s" :mb="0.5">
        {{ $t('global.actions.select') }}
      </BaseHeading>
      <BaseColorLabel v-model="colorValue" colorPicker isSelected />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      colorOption: 'booking_widget'
    };
  },
  watch: {
    colorOption() {
      if (this.colorOption === 'booking_widget') {
        this.setDefaultColor();
      }
    },
    colorValue(value) {
      if (!value || value === this.defaultColor) {
        this.colorOption = 'booking_widget';
      }
    }
  },
  computed: {
    colorOptions() {
      return [
        {
          label: this.$t('global.booking_widget'),
          value: 'booking_widget'
        },
        {
          label: this.$t('marketing.email.sections.another_color'),
          value: 'other'
        }
      ];
    },
    ...mapGetters('marketing', ['id', 'color']),
    ...mapState(useCompanyStore, ['company', 'companySettings']),
    colorValue: {
      get() {
        return this.color;
      },
      set(value: string) {
        this.$store.commit('marketing/SET_COLOR', value);
      }
    },
    defaultColor(): string {
      const { customColor, primaryColor } = this.companySettings.bookingWidget;
      if (primaryColor === 'main') {
        return '#FF6575';
      } else if (primaryColor === 'dark') {
        return '#404043';
      } else {
        return customColor;
      }
    }
  },
  methods: {
    onColorOptionSelect(value: string) {
      this.colorOption = value;
    },
    setDefaultColor() {
      this.$store.commit('marketing/SET_COLOR', this.defaultColor);
    }
  },
  mounted() {
    if (this.id && this.color && this.color !== this.defaultColor) {
      this.colorOption = 'other';
      return;
    }

    this.setDefaultColor();
  }
});
</script>
