<template>
  <BaseCard
    closable
    @close="deleteOrderAppointment(appointment.id)"
    v-test="'register-order-appointment'"
  >
    <div :class="$style.inner">
      <div>
        <BaseText
          v-if="appointment.customerName || appointment.customer"
          bold
          iconBefore="person"
          :mb="0.5"
          v-test="'register-order-appointment-customer'"
        >
          {{ appointment.customerName || appointment.customer?.fullName }}
        </BaseText>
        <BaseText v-test="'register-order-appointment-services'">{{
          appointment.serviceNames
        }}</BaseText>
      </div>
      <div :class="$style.actions">
        <BaseText
          iconBefore="calendar"
          v-test="'register-order-appointment-date'"
        >
          {{ filters.dateTime(appointment.startAt) }}
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import filters from '@/filters';
import type { Appointment } from '@/types';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const { removeOrderAppointment } = useRegisterOrderStore();
const mixpanel = inject<any>('mixpanel');

const deleteOrderAppointment = (id: number) => {
  mixpanel.track('Register - Disconnect associated appointment');
  removeOrderAppointment(id);
};

defineProps<{
  appointment: Appointment;
}>();
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing;
}

.actions {
  display: flex;
  align-items: center;
  gap: $spacing;
}
</style>
