<template>
  <div>
    <router-view @deleted="refetch" @updated="refetch" />
    <div v-if="appointments.length" :class="$style.header">
      <BaseButton
        v-if="unleash.isEnabled('AppointmentOverviewRequest')"
        mr
        color="inverted"
        @click="onShareAppointmentClick"
        v-test="'share-appointments'"
      >
        {{ $t('global.actions.share_appointments') }}
      </BaseButton>
      <BaseButton
        @click="onCreateAppointmentClick"
        v-test="'create-appointment'"
      >
        {{ $t('global.actions.create_appointment') }}
      </BaseButton>
    </div>
    <BaseTable
      v-if="appointments.length"
      :headers="[
        $t('global.date_time'),
        $t('global.state'),
        $t('global.items.service', 2),
        $t('global.label'),
        $t('global.items.employee', 2)
      ]"
      :rows="
        appointments.map((appointment, index) => ({
          id: index,
          routerLink: appointment.original
            ? {
                name: 'customer-appointment',
                params: {
                  appointmentId: appointment.originalId
                }
              }
            : null,
          cells: [
            filters.date(appointment.startAt, {
              addTime: true,
              format: 'datePicker'
            }),
            {
              label: getLabel(appointment),
              icon: appointment.original ? null : 'repeat'
            },
            appointment.serviceNames,
            appointment.calendarLabel
              ? appointment.calendarLabel.title || ''
              : '',
            appointment.employeeNames
          ],
          clickable: true,
          actions:
            appointment.original && !appointment.deleted ? ['delete'] : []
        }))
      "
      @select="onSelect"
      @action="onAction"
      v-test="'customer-appointments'"
    />
    <LoadMore
      v-show="!allDataFetched && firstFetchFinished"
      @shown="fetchMore"
    />
    <BaseSpinner v-show="!firstFetchFinished" inline mt />

    <EmptyPageContent
      v-if="!loading && !appointments.length"
      imageName="appointments"
      :text="{
        title: $t('customers.empty_state.appointments.title'),
        description: $t('customers.empty_state.appointments.description'),
        btnPrimary: $t('global.actions.create_appointment')
      }"
      :moreLink="{
        text: $t('customers.empty_state.appointments.more_text'),
        url: $t('customers.empty_state.appointments.more_url')
      }"
      @click="onCreateAppointmentClick"
    />
    <RequestRefundModal
      v-if="refundId"
      :appointmentId="refundId"
      :appointmentStartAt="refundStartAt"
      :amount="refundAmount"
      @onClose="refundId = null"
      @onSubmit="refundId = null"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'CustomerAppointments'
};
</script>

<script setup lang="ts">
import filters from '@/filters';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import { usePagination } from '@/apollo/pagination';
import gql from 'graphql-tag';
import unleash from '@/unleash';
import { useRouter } from 'vue-router';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { computed, inject, ref } from 'vue';
import { modal } from '@/helpers/ui';
import {
  deleteAppointment,
  activateAppointment
} from '@/modules/calendar/actions/appointments';
import { useI18n } from 'vue-i18n';
import { scrollPage } from '@/helpers/scroll';
import dayjs from 'dayjs';
import RequestRefundModal from '@/modals/appointment/RequestRefundModal.vue';
import { useAppointmentOverview } from '../appointmentOverview';

const customer = inject<any>('customer');
const router = useRouter();
const customerId = ref(
  parseInt(router.currentRoute.value.params.customerId as string)
);

const { t } = useI18n();

const GET_APPOINTMENTS = gql`
  query getAppointments($pagination: PaginationAttributes!, $customerId: ID) {
    appointments(pagination: $pagination, customerId: $customerId) {
      calendarLabel {
        id
        title
      }
      cancelled
      deleted
      employeeNames
      noShow
      original
      originalId
      paidThroughTreatwell
      pricePaidThroughTreatwell
      serviceNames
      startAt
    }
  }
`;
const {
  appointments,
  allDataFetched,
  loading,
  fetchMore,
  firstFetchFinished,
  refetch: _refetch
} = usePagination({
  fieldName: 'appointments',
  query: GET_APPOINTMENTS,
  variables: {
    customerId
  }
});

const refetch = () => {
  scrollPage({
    position: 'top',
    instant: true
  });

  _refetch();
};

const onCreateAppointmentClick = () => {
  router.push({ name: 'calendar' });

  const { setState } = usePreCreateStore();
  setState({
    action: 'CREATE',
    customer: {
      firstName: customer.value.firstName,
      lastName: customer.value.lastName,
      email: customer.value.email,
      mobilePhone: customer.value.mobilePhone,
      id: customer.value.id
    }
  });
};

const appointmentsCount = computed(() => appointments.value.length || 0);

const { showModal: onShareAppointmentClick } = useAppointmentOverview({
  pageName: 'appointments page',
  appointmentsCount
});

const onSelect = (index: number) => {
  const appointment = appointments.value[index];

  if (appointment && !appointment.original) {
    modal('confirmation', {
      message: t('appointment.activate_confirmation')
    }).then(() => {
      activateAppointment({
        input: {
          id: appointment.originalId,
          recurrenceStartAt: appointment.startAt
        }
      }).then((newAppointment: any) => {
        router.push({
          name: 'customer-appointment',
          params: { appointmentId: newAppointment.id }
        });
        refetch();
      });
    });
  }
};

const onAction = ({ id, option }: { id: number; option: string }) => {
  if (option === 'delete') {
    const appointment = appointments.value[id];

    if (appointment.paidThroughTreatwell) {
      refundId.value = appointment.originalId;
      refundAmount.value = appointment.pricePaidThroughTreatwell;
      refundStartAt.value = appointment.startAt;
    } else {
      modal('confirmation', {
        type: 'delete',
        item: t('global.items.appointment')
      }).then(() => {
        deleteAppointment({ id: appointment.originalId }).then(() => {
          refetch();
        });
      });
    }
  }
};

const refundId = ref();
const refundStartAt = ref();
const refundAmount = ref();

const getLabel = (appointment: any) => {
  if (appointment.deleted) {
    return 'deleted';
  } else if (appointment.noShow) {
    return 'no_show';
  } else if (appointment.cancelled) {
    return 'legacy_cancelled_online';
  } else if (dayjs().isAfter(appointment.startAt)) {
    return 'previous';
  } else {
    return 'upcoming';
  }
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing;
}
</style>
