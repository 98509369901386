<template>
  <BaseCard :heading="$t('cashups.cash_transactions')">
    <BaseTable
      :zebra="transactions && transactions.length"
      :headers="[
        $t('global.date'),
        $t('global.description'),
        $t('global.amount_currency')
      ]"
      :rows="[
        {
          cells: [
            filters.dateTime(cashup.openedOn),
            $t('cashups.starting_balance'),
            filters.currency(cashup.openingAmount)
          ]
        },
        ...transactions.map((r) => ({
          id: r.orderId,
          actions: r.orderNumber ? ['pdf'] : null,
          cells: [
            filters.dateTime(r.transactionAt),
            r.orderNumber || r.description,
            filters.currency(r.amount)
          ]
        }))
      ]"
      :footers="[
        '&nbsp',
        '&nbsp',
        `${$t('cashups.cash_overfloat')} ${filters.currency(cashup.transactionsTotal)}`
      ]"
      @action="onActionClick"
      v-test="'cash-transactions-rows'"
    />
    <BaseAlert
      v-if="cashup.orderCount > 100"
      :text="$t('cashups.not_displayed', { amount: cashup.orderCount - 100 })"
      mt
      v-test="'orders-limit'"
    />
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { defineComponent } from 'vue';
import type { Transaction } from '@/types';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import config from '@/config';

interface ActionItem {
  id: string;
  option: string;
}

export default defineComponent({
  props: {
    cashup: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      transactions: [] as Transaction[]
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId', 'dataScope'])
  },
  methods: {
    onActionClick(item: ActionItem): void {
      window.open(`${config.backendUrl}/orders/${item.id}.pdf`, '_blank');
    }
  },
  apollo: {
    transactions: {
      query: gql`
        query getTransactions(
          $cashupId: ID
          $onlyCash: Boolean
          $pagination: PaginationAttributes
          $sortBy: String
          $locationId: ID
          $dataScope: DataScope
        ) {
          transactions(
            cashupId: $cashupId
            onlyCash: $onlyCash
            pagination: $pagination
            sortBy: $sortBy
            locationId: $locationId
            dataScope: $dataScope
          ) {
            amount
            description
            id
            orderId
            orderNumber
            transactionAt
          }
        }
      `,
      variables() {
        return {
          cashupId: this.cashup.id,
          onlyCash: true,
          sortBy: 'transaction_at',
          pagination: {
            currentPage: 1,
            perPage: 100
          },
          locationId: this.cashup.location.id || this.locationId,
          dataScope: this.dataScope
        };
      },
      skip() {
        return !this.cashup.id;
      }
    }
  }
});
</script>
