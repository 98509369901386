<template>
  <BaseModal
    :heading="$t('global.pages.product_imports')"
    @close="$emit('close')"
  >
    <div v-if="products && products.length">
      <div :class="$style.header">
        <BaseHeading>
          {{
            $t('products.imported_on', {
              amount: selectedImport.productsCount,
              date: importDate
            })
          }}
        </BaseHeading>
        <BaseButton :loading="saving" @click="submit">
          {{
            filters.capitalize(
              $t(
                isDeleted
                  ? 'global.actions.restore_item'
                  : 'global.actions.delete_item',
                { item: $t('global.items.product', 2) }
              )
            )
          }}
        </BaseButton>
      </div>
      <div mt v-test="'product-imports-table'">
        <BaseTableRow head>
          <BaseTableCell>{{ $t('global.items.product', 1) }}</BaseTableCell>
          <BaseTableCell>{{ $t('global.price') }}</BaseTableCell>
        </BaseTableRow>

        <BaseTableRow
          v-for="(item, index) in products"
          :key="`row-${index}`"
          zebra
          v-test="'baseTableRow'"
        >
          <BaseTableCell>{{ item.name }}</BaseTableCell>
          <BaseTableCell>{{ withCurrency(item.price) }}</BaseTableCell>
        </BaseTableRow>
      </div>
    </div>
    <Pagination
      :items="products"
      :loading="$apollo.loading"
      fieldName="products"
      :apolloQuery="$apollo.queries.products"
    />
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import Pagination from '@/components/_deprecated/Pagination.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Pagination,
    BaseTableRow,
    BaseTableCell
  },
  props: {
    selectedImport: {
      type: Object,
      required: true
    },
    saving: Boolean
  },
  emits: ['openConfirmationModal', 'close'],
  setup() {
    return {
      filters
    };
  },
  apollo: {
    products: {
      query: gql`
        query getProducts(
          $search: String
          $pagination: PaginationAttributes
          $importId: Int
        ) {
          products(
            search: $search
            pagination: $pagination
            importId: $importId
          ) {
            id
            name
            price
          }
        }
      `,
      variables() {
        return {
          pagination: {
            currentPage: 1,
            perPage: 25
          },
          importId: this.selectedImport.id
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  },
  computed: {
    importDate() {
      return this.filters.date(this.selectedImport?.createdAt);
    },
    isDeleted() {
      return this.selectedImport.state === 'deleted';
    }
  },
  methods: {
    withCurrency(price) {
      return price ? this.filters.currency(price) : '-';
    },
    submit() {
      this.$emit('openConfirmationModal', this.selectedImport, this.isDeleted);
    }
  }
});
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing;
  margin-bottom: $spacing;
}
</style>
