import type { User } from '@/types';
import type { UserAccountsType, UseAccountContext } from './types';

export const provideUserAccounts = () => {
  const userAccounts = ref<UserAccountsType>([]);

  const updateUserAccounts = (accounts: UserAccountsType) => {
    userAccounts.value = accounts.sort((a: User, b: User) =>
      a.id > b.id ? 1 : -1
    );
  };

  provide<UseAccountContext>('userAccounts', {
    userAccounts,
    updateUserAccounts
  });
};
