<template>
  <BaseModalLarge
    :heading="serviceId ? $t('service.edit') : $t('service.create')"
    :loading="isLoading"
    noPadding
    useForm
    white
    headerRightAlign
    :hasBackButton="$screen === 's' && !!activeView"
    :unclosable="!($screen !== 's' || !activeView)"
    @back="activeView = null"
    @close="handleClose"
    @submit="handleSubmit"
    @validationError="onValidationError"
  >
    <template #nav>
      <ServiceNav
        v-if="activeView"
        :activeView="activeView"
        @nav="(view) => (activeView = view)"
      />
    </template>
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseAlert
        v-if="hasErrorResponse"
        color="error"
        :text="$t('global.error_generic')"
        mb
      />

      <ServiceSummary v-if="!activeView" @nav="(view) => (activeView = view)" />
      <GeneralView v-show="activeView === 'about'" />
      <RequirementsView v-show="activeView === 'resources'" />
      <AdvancedView v-show="activeView === 'advanced'" />
    </div>

    <template v-if="serviceId" #footerSub>
      <div
        v-if="$screen !== 's' || !activeView"
        :class="$style.deleteButton"
        @click="onDeleteClick"
        v-test="'service-group-delete-button'"
      >
        <BaseText iconBefore="delete" link center color="error">
          {{ $t('global.actions.delete_service') }}
        </BaseText>
      </div>
    </template>
    <template #footer>
      <BaseButton v-if="$screen !== 's'" color="inverted" @click="handleClose">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        v-if="$screen !== 's' || !activeView"
        submitForm
        :loading="isSaving"
        v-test="'service-btn-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
      <BaseButton v-else @click="activeView = null" v-test="'service-btn-done'">
        {{ $t('global.done') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useServicesStore } from '@/stores/services';
import { useServiceGroupsStore } from '@/stores/service-groups';
export default defineComponent({
  name: 'ServiceVariations'
});
</script>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useResourcesStore } from '@/stores/resources';
import { flash, modal } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { useGtm } from '@gtm-support/vue-gtm';

import ServiceNav from './service-nav/index.vue';
import GeneralView from './general-view/index.vue';
import AdvancedView from './AdvancedView.vue';
import ServiceSummary from './ServiceSummary.vue';
import RequirementsView from './requirements-view/index.vue';

import { useServiceVariations } from './useServiceVariations';

const route = useRoute();
const router = useRouter();
const serviceId = ref(route.params.serviceId as string);
const { screenSize } = usePageLayoutStore();
const { t } = useI18n();

const isSaving = ref(false);
const isSubmitted = ref(false);
const hasErrorResponse = ref(false);
const activeView = ref<null | 'about' | 'resources' | 'advanced'>(
  serviceId.value && screenSize === 's' ? null : 'about'
);

const {
  isLoading,
  formData,
  onlineBookableError,
  requirementsPerVariation,
  resetConfirmation,
  createServiceGroup,
  updateServiceGroup
} = useServiceVariations(serviceId.value);

const activeVariations = computed(() =>
  formData.variations.filter((variation) => !variation.destroy)
);

provide('serviceFormData', formData);
provide('activeVariations', activeVariations);
provide('requirementsPerVariation', requirementsPerVariation);
provide('onlineBookableError', onlineBookableError);
provide('isServiceFormSubmitted', isSubmitted);

const handleClose = () => {
  router.push({
    name: 'admin-services'
  });
};

const mixpanel = inject<any>('mixpanel');
const { getServiceGroupsRefetch, deleteServiceVariationGroup } =
  useServiceGroupsStore();
const { getServices } = useServicesStore();

const afterSave = () => {
  getServices();
  getServiceGroupsRefetch().then(() => {
    isSaving.value = false;
    handleClose();
  });
};

const onDeleteClick = () => {
  if (serviceId.value) {
    isSaving.value = true;
    resetConfirmation();
    modal('confirmation', {
      type: 'delete',
      item: formData.name
    }).then(() => {
      deleteServiceVariationGroup(serviceId.value).then(() => {
        isSaving.value = false;
        flash(t('global.flash.service_deleted'));
        mixpanel.track('Service group deleted');
        handleClose();
      });
    });
  }
};

const onErrorResponse = () => {
  hasErrorResponse.value = true;
  isSaving.value = false;
};

const onValidationError = () => {
  isSubmitted.value = true;
  activeView.value = 'about';
};

const handleSubmit = () => {
  if (isSaving.value) {
    return;
  }

  isSubmitted.value = true;

  if (onlineBookableError.value) {
    activeView.value = 'advanced';
    return;
  }

  const { resourcesByType } = useResourcesStore();
  formData.requirementsAttributes = formData.requirementsAttributes
    .filter((requirement: any) => {
      if (requirement.type === 'EMPLOYEE' || requirement.id) {
        return true;
      }

      const resources = resourcesByType(requirement.type.toLowerCase());
      return !!resources.length;
    })
    .map((requirement: any) => {
      if (requirement.primary) {
        return requirement;
      }

      if (requirement.resourceIds.length) {
        return requirement;
      }

      return {
        ...requirement,
        destroy: true
      };
    });

  isSaving.value = true;
  hasErrorResponse.value = false;

  formData.variations.forEach((_variation, index) => {
    if (!formData.variations[index].requiresProcessingTime) {
      formData.variations[index].durationSetup = 0;
      formData.variations[index].durationProcessing = 0;
      formData.variations[index].durationFinish = 0;
    }
  });

  if (serviceId.value) {
    updateServiceGroup()
      .then(({ data: { errors } }) => {
        if (errors) {
          onErrorResponse();
        } else {
          resetConfirmation();
          flash(t('global.flash.service_updated'));
          mixpanel.track('Service group updated');
          afterSave();
        }
      })
      .catch(() => {
        onErrorResponse();
      });
  } else {
    createServiceGroup()
      .then(({ data: { errors } }) => {
        if (errors) {
          onErrorResponse();
        } else {
          resetConfirmation();
          flash(t('global.flash.service_created'));
          mixpanel.track('Service group created', {
            variationsAmount: formData.variations.length
          });
          afterSave();
        }

        const { isTrial } = useCompanyStore();
        if (isTrial) {
          const gtm = useGtm();
          if (gtm) {
            gtm.trackEvent({ event: 'service-created' });
          }
        }
      })
      .catch(() => {
        onErrorResponse();
      });
  }
};
</script>

<style lang="scss" module>
.base {
  padding: 1.5 * $spacing 2 * $spacing;

  &.smallScreen {
    padding: $spacing;
  }
}

.deleteButton {
  display: flex;
  justify-content: center;
}
</style>
