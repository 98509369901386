<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :fullHeight="true"
    :text="{
      title: $t('upgrade_subscription_page.register.invoices.title'),
      description: $t(
        'upgrade_subscription_page.register.invoices.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.invoices.learn_more'),
      url: $t('upgrade_subscription_page.register.invoices.learn_more_url')
    }"
    imageName="invoices"
  />

  <div v-else>
    <router-view />
    <PageHeader>
      <template #left>
        <BaseDropdown
          v-model="variables.filterScope"
          :label="$t('global.state')"
          :options="stateOptions"
        />
        <BaseDropdown
          v-model="variables.employeeId"
          :label="$t('global.items.employee', 1)"
          :options="[
            {
              value: null,
              label: filters.capitalize($t('global.all'))
            },
            ...employees.map((employee) => ({
              value: employee.id,
              label: employee.name
            }))
          ]"
          resources
        />
        <BaseSearch v-model="variables.search" debounce />
      </template>
    </PageHeader>
    <BaseTable
      v-if="orders && orders.length"
      :headers="[
        {
          value: $t('global.number'),
          sort: 'number'
        },
        {
          value: $t('global.date'),
          sort: 'created_on'
        },
        {
          value: $t('global.items.location', 1),
          hide: !multiLocation
        },
        {
          value: $t('global.items.employee', 2),
          hide: !showEmployees
        },
        $t('global.items.customer', 1),
        {
          value: $t('global.total'),
          alignRight: true,
          sort: 'total'
        },
        $t('global.status')
      ]"
      :rows="
        orders.map((o) => ({
          id: o.id,
          routerLink: { name: 'invoice', params: { id: o.id } },
          cells: [
            o.number,
            filters.dateTime(o.invoicedAt),
            {
              value: o.location.name,
              hide: !multiLocation
            },
            o.items && o.items.length && o.items.find((item) => item.employee)
              ? {
                  value: [
                    ...new Set(
                      o.items
                        ?.filter((item) => item.employee)
                        .map((item) => item.employee.name)
                    )
                  ].join(', '),
                  hide: !showEmployees
                }
              : {
                  value: null,
                  hide: !showEmployees
                },
            o.customer?.fullName,
            {
              value: filters.currency(o.total),
              alignRight: true
            },
            {
              label: o.state?.toLowerCase()
            }
          ]
        }))
      "
      :sortOrder="{
        sortBy: variables.sortBy,
        direction: variables.direction
      }"
      @sort="
        ($event) => {
          variables.sortBy = $event.sortBy;
          variables.direction = $event.direction;
        }
      "
      v-test="'register-invoices'"
    />
    <EmptyPageContent
      v-show="!loading && !orders.length"
      imageName="invoices"
      :text="
        showEmptyPageContent
          ? {
              title: $t('invoices.empty_state.title'),
              description: $t('invoices.empty_state.description'),
              btnPrimary: $t('global.actions.create_invoice')
            }
          : null
      "
      :moreLink="
        showEmptyPageContent
          ? {
              text: $t('invoices.empty_state.more_text'),
              url: $t('invoices.empty_state.more_url')
            }
          : null
      "
      @click="$router.push({ name: 'register-builder' })"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>
<script lang="ts">
import filters from '@/filters';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import PageHeader from '@/modules/PageHeader.vue';
import gql from 'graphql-tag';
import type { Component } from 'vue';
import { defineComponent } from 'vue';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

interface Option {
  value: string | null;
  label: string;
}

export default defineComponent({
  name: 'Invoices',
  components: {
    EmptyPageContent: EmptyPageContent as Component,
    PageHeader: PageHeader as Component,
    LoadMore: LoadMore as Component,
    EmptySubscriptionPage
  },
  setup() {
    const { orders, loading, variables, allDataFetched, fetchMore } =
      usePagination({
        fieldName: 'orders',
        query: gql`
          query getOrders(
            $filterScope: FilterScope
            $search: String
            $locationId: ID
            $dataScope: DataScope
            $employeeId: ID
            $direction: SortDirectionEnum
            $pagination: PaginationAttributes
            $sortBy: String
          ) {
            orders(
              filterScope: $filterScope
              search: $search
              locationId: $locationId
              dataScope: $dataScope
              employeeId: $employeeId
              direction: $direction
              pagination: $pagination
              sortBy: $sortBy
            ) {
              id
              number
              invoicedAt
              location {
                id
                name
              }
              items {
                employee {
                  name
                }
              }
              customer {
                fullName
              }
              total
              state
            }
          }
        `,
        variables: {
          filterScope: null,
          search: '',
          employeeId: null,
          direction: 'DESC',
          sortBy: 'created_on'
        },
        options: {
          multiLocation: true
        }
      });
    return {
      orders,
      loading,
      variables,
      fetchMore,
      allDataFetched,
      filters
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['multiLocation']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    ...mapState(useResourcesStore, ['employees']),
    showEmptyPageContent(): boolean {
      return (
        !this.variables.filterScope &&
        !this.variables.search &&
        !this.variables.employeeId &&
        !this.orders.length
      );
    },
    stateOptions(): Array<Option> {
      return [
        {
          value: null,
          label: this.$t('global.all')
        },
        {
          value: 'DRAFT',
          label: this.$t('global.draft')
        },
        {
          value: 'OUTSTANDING',
          label: this.$t('global.outstanding')
        },
        {
          value: 'PAID',
          label: this.$t('global.paid')
        },
        {
          value: 'ONLINE',
          label: this.$t('global.online')
        }
      ];
    },
    showEmployees(): boolean {
      return this.employees.length > 1;
    }
  }
});
</script>
