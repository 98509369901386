import gql from 'graphql-tag';

export const fragment = gql`
  fragment label on CalendarLabel {
    id
    color
    title
  }
`;

export const query = gql`
  query getCalendarLabels {
    calendarLabels {
      ...label
    }
  }
  ${fragment}
`;
