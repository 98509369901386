<template>
  <BaseCard>
    <div :class="$style.wrapper">
      <div :class="$style.slots">
        <div v-for="time in times" :key="time" :class="$style.slot">
          {{ time }}
        </div>
      </div>
      <div
        :class="$style.appointment"
        :style="{ gridRowEnd }"
        v-html="exampleAppointment"
      />
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import dayjs from '@/dayjs';
import filters from '@/filters';
import createItem from '@/modules/calendar/calendar/calendar-content/CreateCalendarItem';

const { t } = useI18n();

const props = defineProps<{
  interval: 5 | 10 | 15 | 30;
}>();

const exampleAppoinmentDurationMinutes = 60;

const gridRowEnd = computed(() =>
  Math.floor(Math.floor(exampleAppoinmentDurationMinutes / props.interval) + 1)
);

const startTime = computed(() => dayjs().hour(10).minute(0).second(0));

const times = computed(() =>
  Array.apply(
    null,
    Array(Math.floor(exampleAppoinmentDurationMinutes / props.interval))
  ).map((_x, i) =>
    filters.time(startTime.value.add(i * props.interval, 'minutes'))
  )
);

const exampleAppointment = computed(() => {
  const event = {
    title: t('admin.calendar.mock_service_name'),
    startStr: startTime.value,
    endStr: startTime.value.add(exampleAppoinmentDurationMinutes, 'minutes'),
    isSmall: gridRowEnd.value <= 3,
    extendedProps: {
      type: 'appointment',
      color: '#EB4762'
    }
  };
  return createItem({ event });
});
</script>

<style lang="scss" module>
$slotWidth: 60px;

.wrapper {
  display: flex;
}

.slots {
  width: $slotWidth;
}

.slot {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text-secondary;
  height: 28px;
  border: 1px solid $color-border;
  border-bottom: none;
  font-size: 12px;

  &:first-child {
    color: $color-text;
  }

  &:last-child {
    border-bottom: 1px solid $color-border;
  }
}

.appointment {
  width: calc(100% - #{$slotWidth});
}
</style>
