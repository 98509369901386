<template>
  <BaseModal
    :heading="
      formSubmissions && formSubmissions.length
        ? filters.capitalize($t('global.items.form', 2))
        : $t('forms.form_templates')
    "
    :loading="isLoading"
    testId="forms"
    :parentRoute="parentRouteName"
  >
    <div v-if="formSubmissions && formSubmissions.length" class="mb-1">
      <BaseHeading mb>
        {{ $t('forms.current_forms') }}
      </BaseHeading>
      <BaseTableRow head emptyOptionsCell>
        <BaseTableCell :width="7" />
        <BaseTableCell :width="53">
          {{ $t('global.name') }}
        </BaseTableCell>
        <BaseTableCell :width="40">
          {{ $t('global.date') }}
        </BaseTableCell>
      </BaseTableRow>
      <BaseTableRow
        v-for="(submission, index) in formSubmissions"
        :key="`submission-${index}`"
        :options="
          submission.template.formType === 'contract' ? ['pdf'] : ['edit']
        "
        zebra
        @optionClick="onSubmissionOptionClick($event, submission)"
        v-test="'form-submission'"
      >
        <BaseTableCell :width="7">
          <BaseIcon name="check" />
        </BaseTableCell>
        <BaseTableCell :width="53">
          {{ submission.template.name }}
        </BaseTableCell>
        <BaseTableCell :width="40">
          {{ filters.dateTime(submission.createdAt) }}
        </BaseTableCell>
      </BaseTableRow>
    </div>

    <BaseHeading v-if="formSubmissions && formSubmissions.length" mb>
      {{ $t('forms.available_forms') }}
    </BaseHeading>

    <BaseTableRow
      v-for="(template, index) in sortedTemplates"
      :key="`template-${index}`"
      :options="options"
      zebra
      @optionClick="onTemplateOptionClick($event, template)"
      v-test="'form-template'"
    >
      <BaseTableCell :width="7">
        <BaseIcon
          :name="template.formType === 'contract' ? 'edit-2' : 'clipboard'"
        />
      </BaseTableCell>
      <BaseTableCell :width="93">
        {{ template.name }}
      </BaseTableCell>
    </BaseTableRow>

    <EmptyPageContent
      v-if="
        (!formSubmissions || !formSubmissions.length) &&
        (!sortedTemplates || !sortedTemplates.length)
      "
      imageName="forms"
      :text="{
        title: $t('form_templates.empty_state.title'),
        description: $t('form_templates.empty_state.description'),
        btnPrimary: $t('form_templates.new_consultation'),
        btnSecondary: $t('form_templates.new_contract')
      }"
      :moreLink="{
        text: $t('form_templates.empty_state.more_text'),
        url: $t('form_templates.empty_state.more_url')
      }"
      @click="onEmptyPageClick"
    />
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import { formSubmissionFragment } from '@/graphql-fragments';
import axios from '@/axios';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import config from '@/config';
import EmptyPageContent from '@/components/EmptyPageContent.vue';

import { defineComponent, inject } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell,
    EmptyPageContent
  },
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup() {
    const customerData = inject<any>('customer');

    return {
      filters,
      customerData
    };
  },
  data() {
    return {
      sortedTemplates: [],
      formTemplates: [],
      isLoading: true
    };
  },
  apollo: {
    appointment: {
      query: gql`
        query getAppointment($id: Int!) {
          appointment(id: $id) {
            id
            customer {
              id
              email
            }

            customerMember {
              firstName
              lastName
            }
            treatwell
          }
        }
      `,
      fetchPolicy: 'cache-first',
      variables() {
        return {
          id: this.appointmentId
        };
      },
      skip() {
        return !this.appointmentId;
      }
    },
    formSubmissions: {
      query: gql`
        query getFormSubmissions($appointmentId: Int) {
          formSubmissions(appointmentId: $appointmentId) {
            ...formSubmission
          }
        }
        ${formSubmissionFragment}
      `,
      fetchPolicy: 'cache-first',
      variables() {
        return {
          appointmentId: this.appointmentId
        };
      },
      skip() {
        return !this.appointmentId;
      }
    },
    formTemplates: {
      query: gql`
        query getFormTemplates {
          formTemplates {
            corona
            id
            name
            formType
            questions {
              id
            }
          }
        }
      `,
      result() {
        this.sortedTemplates = this.formTemplates.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.isLoading = false;
      },
      operationName: 'getFormTemplates'
    }
  },
  computed: {
    appointmentId() {
      return parseInt(this.$route.params.appointmentId);
    },
    baseRoute() {
      return this.appointmentId ? 'forms/' : '';
    },
    customer() {
      return this.appointment?.customer || this.customerData;
    },
    options() {
      const options = ['fill'];
      if (this.customer?.email && !this.appointment?.treatwell) {
        options.push('email');
      }
      return options;
    }
  },
  methods: {
    sendMail(templateId) {
      axios
        .post('/messages', {
          message: {
            destination: this.customer.email,
            customer_id: this.customer.id,
            message_type: 'form_template',
            form_template_id: templateId,
            appointment_id: this.appointment?.id,
            gateway: 'email'
          }
        })
        .then(() => {
          flash(this.$t('global.flash.email_sent'));

          this.$router.push({
            name: this.parentRouteName
          });
        });
    },
    onSubmissionOptionClick(option, submission) {
      if (option === 'edit') {
        this.$router.push(
          `${this.baseRoute}${submission.id}/edit/${submission.template.id}`
        );
      }

      if (option === 'pdf') {
        window.open(
          `${config.backendUrl}/form_submissions/${submission.id}.pdf`
        );
      }
    },
    onTemplateOptionClick(option, template) {
      if (option === 'fill') {
        this.$router.push(`${this.baseRoute}new/${template.id}`);
      }

      if (option === 'email') {
        modal('confirmation').then(() => {
          this.sendMail(template.id);
        });
      }
    },
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.$router.push({ name: 'admin-forms-new' });
      } else if (button === 'secondary') {
        this.$router.push({ name: 'admin-forms-new-contract' });
      }
    }
  }
});
</script>
