<template>
  <div>
    <BaseTableCell v-if="item.originalOrder" v-test="'hasOriginalOrder'">
      <a
        target="_blank"
        :class="$style.link"
        :href="`${backendUrl}/orders/${item.orderId}.pdf`"
      >
        <BaseIcon name="pdf" color="primary" :mr="0.25" />
        {{ $t('products.reason_credited', { orderNumber: item.orderNumber }) }}
      </a>
    </BaseTableCell>
    <BaseTableCell v-else-if="item.orderId" v-test="'hasOrderId'">
      <a
        target="_blank"
        :class="$style.link"
        :href="`${backendUrl}/orders/${item.orderId}.pdf`"
      >
        <BaseIcon name="pdf" color="primary" :mr="0.25" />
        {{ $t('products.reason_sold', { orderNumber: item.orderNumber }) }}
      </a>
    </BaseTableCell>
    <BaseTableCell v-else-if="item.stockOrderId">
      <router-link
        :class="$style.link"
        :to="{
          name: isOpen ? 'products-order-build' : 'stock-order',
          params: { stockOrderId: item.stockOrderId }
        }"
      >
        {{ item.reason }}
      </router-link>
    </BaseTableCell>
    <BaseTableCell
      v-else-if="
        item.type === 'create_action' || item.type === 'product_import'
      "
    >
      {{ $t(`products.${item.type}`) }}
    </BaseTableCell>
    <BaseTableCell v-else v-test="'isPreSelectedReason'">
      {{ isPreSelectedReason }}
    </BaseTableCell>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import config from '@/config';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      backendUrl: config.backendUrl
    };
  },
  computed: {
    isOpen() {
      return (
        this.item.stockOrderStatus && this.item.stockOrderStatus === 'open'
      );
    },
    isPreSelectedReason() {
      const options = [
        'no_reason',
        'salon_usage',
        'gift',
        'damage',
        'new_stock'
      ];
      const item = this.item.reason;
      const isPartOfOptions = options.includes(item);

      if (item && isPartOfOptions) {
        return this.$t(`products.reasons.${item}`);
      } else if (item) {
        const trimmed = `${item.substring(0, 48)}..`;
        return item.length > 50 ? trimmed : item;
      } else {
        return '-';
      }
    }
  }
});
</script>

<style lang="scss" module>
.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-primary !important;
}
</style>
