<template>
  <Modal
    v-bind="{ parentRouteName, loading }"
    noPadding
    :saving="creating"
    @submit="submit"
  >
    <PageSplit>
      <template #left>
        <BaseAlert
          :text="$t('integrations.franchise.settings.alert')"
          color="warning"
          mb
        />
        <BaseInput
          v-model="token"
          :label="$t('integrations.franchise.settings.identifier')"
          :info="$t('integrations.franchise.settings.identifier_info')"
          required
          :error="
            invalidToken
              ? $t('integrations.franchise.settings.identifier_invalid')
              : ''
          "
          v-test="'franchise-token'"
        />
      </template>
      <template #right>
        <BaseHeading mb>
          {{ $t('integrations.franchise.settings.permissions.heading') }}
        </BaseHeading>
        <BaseText mb>
          {{ $t('integrations.franchise.settings.permissions.description') }}
        </BaseText>
        <BaseText
          :href="
            $t(
              'integrations.franchise.settings.permissions.description_link_url'
            )
          "
          :mb="2"
        >
          {{
            $t('integrations.franchise.settings.permissions.description_link')
          }}
        </BaseText>
        <div :class="$style.permissions">
          <BaseCheckbox
            v-model="permissions.owner"
            :label="$t('admin.user_accounts.modal.permissions.owner.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.owner.description')
            "
            v-test="'permissions-owner'"
          />
          <BaseCheckbox
            v-model="permissions.admin"
            :label="$t('admin.user_accounts.modal.permissions.admin.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.admin.description')
            "
            :disabled="permissions.owner === true"
            v-test="'permissions-admin'"
          />
          <BaseCheckbox
            v-model="permissions.manager"
            :label="$t('admin.user_accounts.modal.permissions.manager.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.manager.description')
            "
            :disabled="permissions.admin === true"
            v-test="'permissions-manager'"
          />
          <BaseCheckbox
            v-model="permissions.reports"
            :label="$t('admin.user_accounts.modal.permissions.reports.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.reports.description')
            "
            :disabled="permissions.admin === true"
            v-test="'permissions-reports'"
          />
          <BaseCheckbox
            v-model="permissions.basic"
            :label="$t('admin.user_accounts.modal.permissions.basic.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.basic.description')
            "
            :disabled="
              permissions.manager === true || permissions.reports === true
            "
            v-test="'permissions-basic'"
          />
          <BaseCheckbox
            :modelValue="true"
            disabled
            :label="$t('admin.user_accounts.modal.permissions.calendar.title')"
            :description="
              $t('admin.user_accounts.modal.permissions.calendar.description')
            "
          />
        </div>
      </template>
    </PageSplit>
  </Modal>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import Modal from './shared/Modal.vue';
import PageSplit from '@/modules/PageSplit.vue';
import gql from 'graphql-tag';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const props = defineProps<{
  parentRouteName: string;
}>();

const token = ref('');
const permissions = reactive({
  admin: false,
  basic: true,
  manager: true,
  owner: false,
  reports: true
});

watchEffect(() => {
  if (permissions.owner) {
    permissions.admin = true;
  }
  if (permissions.admin) {
    permissions.manager = true;
    permissions.reports = true;
  }
  if (permissions.manager || permissions.reports) {
    permissions.basic = true;
  }
});

const invalidToken = ref(false);
watch(token, () => {
  invalidToken.value = false;
});

const { onResult, loading } = useQuery(gql`
  query getFranchise {
    franchise {
      app {
        id
        settings {
          token
          permissions {
            admin
            basic
            manager
            owner
            reports
          }
        }
      }
    }
  }
`);

onResult(({ data: { franchise } }) => {
  const existingToken = franchise?.app?.settings.token;
  const existingPermissions = franchise?.app?.settings.permissions;

  if (existingToken) {
    token.value = existingToken;
  }

  if (existingPermissions) {
    Object.keys(permissions).forEach((key) => {
      permissions[key] = existingPermissions[key];
    });
  }
});

const { mutate: createApp, loading: creating } = useMutation(gql`
  mutation createFranchiseApp($input: CreateFranchiseAppInput!) {
    createFranchiseApp(input: $input) {
      franchiseApp {
        id
      }
    }
  }
`);

const { t } = useI18n();
const router = useRouter();

const submit = () => {
  createApp({
    input: {
      token: token.value,
      permissions
    }
  }).then(({ data }) => {
    afterMutation(data);
  });
};

const apps = inject<any>('apps');

const afterMutation = (data: any) => {
  if (
    data.createFranchiseApp?.franchiseApp ||
    data.updateFranchiseApp?.franchiseApp
  ) {
    if (data.createFranchiseApp) {
      flash(t('global.flash.integration_enabled'));
    } else if (data.updateFranchiseApp) {
      flash(t('global.flash.integration_updated'));
    }

    const franchiseApp = apps.value.find((app) => app.key === 'franchise');
    franchiseApp.enabled = true;

    router.push({
      name: props.parentRouteName
    });
  } else {
    // No app was created, likely due to an incorrect token
    invalidToken.value = true;
  }
};
</script>

<style lang="scss" module>
.permissions {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 2;
  }
}
</style>
