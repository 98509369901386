<template>
  <BaseModal
    :heading="$t('company.vat_id.title')"
    useForm
    small
    @close="emit('close')"
    @submit="onSubmit"
  >
    <BaseAlert
      v-if="submitErrorMessage"
      color="error"
      :text="submitErrorMessage"
      mb
      v-test="'company-settings-vat-id-error'"
    />
    <div>
      <BaseRadio
        v-model="hasVatNumber"
        :label="$t('company.vat_id.vat_preference_label')"
        :options="[
          {
            label: $t('company.vat_id.salon_has_vat_id'),
            value: true
          },
          {
            label: $t('company.vat_id.salon_has_no_vat_id'),
            value: false
          }
        ]"
        mb
        v-test="'company-settings-vat-id-radio'"
      />
    </div>
    <div>
      <BaseInput
        v-if="hasVatNumber"
        v-model="vatId"
        :label="$t('company.vat_id.label')"
        :error="vatValidationError"
        type="text"
        required
        v-test="'company-settings-has-vat-id'"
      />
      <BaseAlert
        v-else
        :text="$t('company.vat_id.salon_has_no_vat_id_description')"
        v-test="'company-settings-vat-id-no-vat-id-alert'"
      />
    </div>
    <template #footer>
      <BaseButton
        submitForm
        color="primary"
        :loading="isLoading"
        v-test="'company-settings-vat-id-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useMutation } from '@vue/apollo-composable';
import { flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';

const emit = defineEmits(['close']);

const { updateCompany } = useCompanyStore();
const { company } = storeToRefs(useCompanyStore());
const { t } = useI18n();
const isLoading = ref(false);
const hasVatNumber = ref(!company.value.billingInfo?.dontHaveVatNumber);
const vatId = ref(company.value.billingInfo?.stripeTaxId?.value || '');

const vatValidationError = ref();
const submitErrorMessage = ref('');

const UPDATE_STRIPE_TAX_ID = gql`
  mutation updateStripeTaxId($input: UpdateStripeTaxIdInput!) {
    updateStripeTaxId(input: $input) {
      stripeTaxId {
        value
      }
      errors
    }
  }
`;

const onSubmitSuccess = () => {
  flash(t('global.flash.company_updated'));
  emit('close');
};

const onSubmit = () => {
  isLoading.value = true;

  if (hasVatNumber.value) {
    const { mutate } = useMutation(UPDATE_STRIPE_TAX_ID, {
      variables: {
        input: {
          vatNumber: vatId.value
        }
      }
    });

    vatValidationError.value = '';
    submitErrorMessage.value = '';

    mutate()
      .then(
        ({
          data: {
            updateStripeTaxId: { errors, stripeTaxId }
          }
        }) => {
          if (errors && errors.type === 'tax_id_invalid') {
            vatValidationError.value = t('company.vat_id.invalid_vat_id');
            return;
          }

          if (errors) {
            submitErrorMessage.value = t('company.vat_id.generic_update_error');
            return;
          }

          company.value.billingInfo.stripeTaxId = stripeTaxId;
          company.value.billingInfo.dontHaveVatNumber = false;

          onSubmitSuccess();
        }
      )
      .finally(() => {
        isLoading.value = false;
      });
  } else {
    updateCompany({
      billingInfo: {
        dontHaveVatNumber: true
      }
    })
      .then(() => {
        company.value.billingInfo.stripeTaxId = null;
        onSubmitSuccess();
      })
      .finally(() => {
        isLoading.value = false;
      });
  }
};
</script>
