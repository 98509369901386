<template>
  <BasePopoverNav
    :style="{
      top: '60px'
    }"
    arrow="topLeft"
    :keepVisibleOn="keepVisibleOn"
    autoPositioning
    :positionOn="positionOn"
    :items="
      [
        {
          name: 'calendar-settings',
          icon: 'settings',
          label: $t('calendar.nav.calendar_settings')
        },
        {
          name: 'roster-settings',
          icon: 'calendar',
          label: $t('calendar.nav.roster_settings')
        },
        {
          name: 'service-settings',
          icon: 'scissors',
          label: $t('calendar.nav.service_settings')
        },
        {
          name: 'online-booking',
          icon: 'globe',
          label: $t('calendar.nav.online_booking')
        },
        {
          name: 'appointment-requests',
          icon: 'message-circle',
          label: $t('calendar.appointment_requests.heading'),
          hide: !unleash.isEnabled('AppointmentConfirmationEnabled')
        },
        {
          name: 'print-calendar',
          icon: 'printer',
          label: $t('calendar.nav.print_calendar')
        }
      ].filter((item) => !item.hide)
    "
    @close="$emit('close')"
    @select="onSelect"
  />
</template>

<script setup lang="ts">
import { getDateRangeWeek } from '@/modules/calendar/helpers';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import config from '@/config';
import { useRouter } from 'vue-router';
import unleash from '@/unleash';

defineProps<{
  keepVisibleOn: HTMLElement;
  positionOn: HTMLElement;
}>();

defineEmits(['close']);

const router = useRouter();

const onSelect = (item: { name: string }) => {
  let routeName;

  switch (item.name) {
    case 'calendar-settings':
      routeName = 'admin-calendar';
      break;
    case 'roster-settings':
      routeName = 'admin-roster';
      break;
    case 'service-settings':
      routeName = 'admin-services';
      break;
    case 'online-booking':
      routeName = 'admin-online-bookings';
      break;
    case 'appointment-requests':
      routeName = 'appointment-requests';
      break;
  }

  if (routeName) {
    router.push({ name: routeName });
  }

  if (item.name === 'print-calendar') {
    const { viewType, date } = useCalendarFiltersStore();

    const { start, end } =
      viewType === 'WEEK' ? getDateRangeWeek(date) : { start: date, end: date };
    window.open(`${config.backendUrl}/appointments?start=${start}&end=${end}`);
  }
};
</script>
