<template>
  <BaseModal :loading="loading" testId="welcome" @close="$emit('close')">
    <Confetti v-test="'confetti'" />
    <div :class="$style.base">
      <div :class="$style.visual">
        <img src="/img/visual-welcome.svg" />
      </div>
      <BaseHeading size="xl" mb>
        {{ $t('subscription.welcome.heading') }}
      </BaseHeading>
      <BaseText mb>
        {{ $t('subscription.welcome.description_1') }}
      </BaseText>
      <BaseText :mb="2">
        {{
          isSubscribed
            ? $t('subscription.welcome.description_2')
            : $t('subscription.welcome.description_3')
        }}
      </BaseText>
      <BaseButton @click="handleSubmitButton" v-test="'welcome-submit'">
        {{
          $t(
            hasSubscriptionIntention
              ? 'subscription.welcome.set_treatwell'
              : 'subscription.welcome.button'
          )
        }}
      </BaseButton>
      <BaseText
        v-if="showTreatwell && !hasSubscriptionIntention"
        link
        mt
        @click="onTwLinkClick"
        v-test="'welcome-treatwell'"
      >
        {{ $t('subscription.treatwell_link') }}
      </BaseText>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { updateDataLayer } from '@/helpers/gtm';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useTreatwellStore } from '@/stores/treatwell';
import Confetti from '@/components/Confetti.vue';

import { defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export default defineComponent({
  components: {
    Confetti
  },
  inject: ['mixpanel'],
  emits: ['close'],
  setup() {
    const { showTreatwell } = useTreatwellStore();

    const { result, loading } = useQuery(gql`
      query getCurrentCompany {
        currentCompany {
          settings {
            treatwell {
              hasSubscriptionIntention
            }
          }
        }
      }
    `);

    const hasSubscriptionIntention = computed(
      () =>
        result.value?.currentCompany.settings.treatwell.hasSubscriptionIntention
    );

    return {
      loading,
      showTreatwell,
      hasSubscriptionIntention
    };
  },
  watch: {
    isSubscribed: {
      handler() {
        if (this.isSubscribed) {
          // IMPORTANT! Track subscription conversion
          updateDataLayer().then(() => {
            if (this.$gtm) {
              this.$gtm.trackEvent({ event: 'subscription' });
            }
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['isSubscribed'])
  },
  methods: {
    onTwLinkClick() {
      this.$emit('close');
      this.mixpanel.track('Welcome modal - Treatwell button clicked');
      this.$router.push({ name: 'treatwell-landing' });
    },
    handleSubmitButton() {
      this.$emit('close');
      if (this.hasSubscriptionIntention) {
        this.mixpanel.track('Welcome modal - Treatwell start button clicked');
        this.$router.push({ name: 'treatwell-landing' });
      }
    }
  },
  mounted() {
    this.mixpanel.track('Welcome modal - Shown');
  },
  beforeUnmount() {
    this.mixpanel.track('Welcome modal - Closed');
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  padding: $spacing * 2 $spacing;
}

.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.visual {
  margin-bottom: $spacing * 2;
  padding: 0 $spacing;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
