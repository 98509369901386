import gql from 'graphql-tag';

export const UPDATE_SUBSCRIPTION_CANCEL_REASONS_MUTATION = gql`
  mutation updateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      errors {
        message
        type
      }
      subscription {
        cancelReasons
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      errors {
        message
        type
      }
      subscription {
        cancelled
      }
    }
  }
`;

export const PAUSE_SUBSCRIPTION_MUTATION = gql`
  mutation pauseSubscription($input: PauseSubscriptionInput!) {
    pauseSubscription(input: $input) {
      errors {
        message
        type
      }
    }
  }
`;

export const UNPAUSE_SUBSCRIPTION_MUTATION = gql`
  mutation unpauseSubscription($input: UnpauseSubscriptionInput!) {
    unpauseSubscription(input: $input) {
      errors {
        message
        type
      }
    }
  }
`;
