<template>
  <div
    :class="[$style.base, { [$style.dragover]: dragover }]"
    @drop="onDrop"
    @dragover="onDragover"
    @dragleave="onDragleave"
  >
    <BaseSpinner v-if="isLoading" />
    <div :class="[$style.content, { [$style.isLoading]: isLoading }]">
      <BaseIcon :mb="0.25" name="cloud-upload" />
      <BaseText>
        {{ $t('global.actions.drag_files') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['dropped'],
  data() {
    return {
      dragover: false
    };
  },
  methods: {
    onDragover(e) {
      e.preventDefault();
      this.dragover = true;
    },
    onDragleave(e) {
      e.preventDefault();
      this.dragover = false;
    },
    onDrop(e) {
      e.preventDefault();
      this.$emit('dropped', e.dataTransfer.files);
      this.dragover = false;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  width: 100%;
  border: 1px dashed $color-border-input;
  border-radius: $radius;
  padding: $spacing * 3;
  transition: background 0.1s ease-in;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.isLoading {
    opacity: 0;
  }
}

.dragover {
  background: $color-highlight;
}
</style>
