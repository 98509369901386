<template>
  <div>
    <BaseGrid container mb>
      <BaseGrid alignCenter :size="colSize">
        <BaseHeading v-test="'rangedHeader'">
          {{ $t(`reports.ranges.${translationKey}_header`) }} {{ currentRange }}
        </BaseHeading>
      </BaseGrid>
      <BaseGrid
        alignRight
        alignCenter
        :class="[{ [$style.isSmall]: $screen === 's' }]"
        :size="colSize"
      >
        <BaseExport :urlTemplate="exportUrl" mr v-test="'exportButton'" />
        <DateManipulator
          :mt="$screen === 's'"
          :year="!day"
          @change="onRangeChange"
          v-test="'dateManipulator'"
        />
      </BaseGrid>
    </BaseGrid>
    <div class="mb-1">
      <BaseCard :loading="loading">
        <div :class="$style.chartWrapper">
          <BarLineChart :chartData="chartData" v-test="'barLineChart'" />
        </div>
      </BaseCard>
    </div>
    <BaseCard :loading="loading">
      <RangedTotalsTable
        :totals="reportTotals"
        :data="reportData"
        :day="day"
        v-test="'rangedTotalsTable'"
      />
    </BaseCard>
  </div>
</template>

<script lang="ts">
import RangedTotalsTable from './RangedTotalsTable.vue';
import BarLineChart from './BarLineChart.vue';
import DateManipulator from './DateManipulator.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RangedReports',
  components: {
    RangedTotalsTable,
    BarLineChart,
    DateManipulator
  },
  props: {
    day: {
      type: Boolean,
      default: false
    },
    reportTotals: {
      type: Object,
      required: true
    },
    reportData: {
      type: Array,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    currentRange: {
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    startDate: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  computed: {
    translationKey() {
      return this.day ? 'day' : 'month';
    },
    exportUrl() {
      const range_name = this.day ? 'daily' : 'monthly';
      return `reports/${range_name}_reports.extension?start=${this.startDate}`;
    },
    colSize() {
      return this.$screen === 's' ? 12 : 6;
    }
  },

  methods: {
    onRangeChange(date) {
      this.$emit('change', date);
    }
  }
});
</script>

<style lang="scss" module>
.chartWrapper {
  // Fix for mobile safari
  position: relative;
  z-index: 0;
}

.isSmall {
  flex-direction: column;
}
</style>
