<template>
  <div
    :class="[
      $style.base,
      { [$style.isSmall]: $screen === 's' || $screen === 'm' }
    ]"
  >
    <div :class="$style.heading">
      <BaseHeading size="xl" v-test="'orderTotal'">
        {{ filters.currency(totalSelectedAmount) }}
      </BaseHeading>
    </div>
    <div>
      <BaseButton
        :disabled="!selectedProducts.length || isLoadingOrder"
        :loading="isLoadingDraft"
        color="inverted"
        mr
        @click="placeOrder(true)"
        v-test="'placeOrderDraft'"
      >
        {{ $t('global.actions.save_draft') }}
      </BaseButton>
      <BaseButton
        :disabled="!selectedProducts.length || isLoadingDraft"
        :loading="isLoadingOrder"
        @click="placeOrder(false)"
        v-test="'placeOrder'"
      >
        {{ $t('global.actions.place_order') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import type { SelectedProduct } from './types';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    selectedProducts: {
      type: Array,
      required: true
    },
    deletedProducts: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      isLoadingDraft: false,
      isLoadingOrder: false
    };
  },
  computed: {
    totalSelectedAmount() {
      if (!this.selectedProducts.length) {
        return 0;
      }

      return this.selectedProducts
        .map((product) => (product.costPrice || 0) * product.quantity)
        .reduce((a, b) => a + b);
    },
    selectedProductsForMutation(): SelectedProduct[] {
      const products = [...this.selectedProducts, ...this.deletedProducts];

      const withoutDuplicates = products.reduce((acc, current) => {
        const duplicate = acc.find(
          (item) => item.productId === current.productId
        );
        if (!duplicate) {
          return acc.concat([current]);
        } else {
          if (current.id) {
            // make sure an item retains its id when deleted > added again
            duplicate.id = current.id;
          }
          return acc;
        }
      }, []);
      return withoutDuplicates;
    }
  },
  methods: {
    placeOrder(isDraft: boolean) {
      const itemsAttributes: SelectedProduct[] = [];
      const status = isDraft ? 'open' : 'ordered';
      isDraft ? (this.isLoadingDraft = true) : (this.isLoadingOrder = true);

      this.selectedProductsForMutation.forEach((product: SelectedProduct) => {
        itemsAttributes.push({
          id: product.id,
          costPrice: product.costPrice,
          productId: product.productId,
          quantity: product.quantity,
          stockOrderId: parseInt(this.$route.params.stockOrderId as string),
          _destroy: product._destroy
        });
      });

      const input = {
        id: this.$route.params.stockOrderId,
        status,
        itemsAttributes
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateStockOrder($input: UpdateStockOrderInput!) {
              updateStockOrder(input: $input) {
                stockOrder {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            input
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'stockOrders' });
            cache.gc();
          }
        })
        .then(() => {
          this.$router.push({
            name: isDraft ? 'products-stock-orders' : 'stock-order'
          });
          flash(
            this.$t(
              `global.flash.${isDraft ? 'stock_order_saved' : 'marked_as_order'}`
            )
          );
        })
        .finally(() => {
          isDraft
            ? (this.isLoadingDraft = false)
            : (this.isLoadingOrder = false);
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $spacing;
  width: 100%;
  border-top: 1px solid $color-border;
  background: $white;
  border-bottom-right-radius: $modal-radius;
  border-bottom-left-radius: $modal-radius;

  &.isSmall {
    flex-direction: column;
    align-items: flex-end;
  }
}

.heading {
  .isSmall & {
    align-self: flex-start;
    margin-bottom: $spacing;
  }
}
</style>
