<template>
  <ModuleBase
    :fullHeight="true"
    :navItems="
      hasFeatureFlag('module-messages') && hasFeatureFlag('admin-communication')
        ? [
            {
              text: $t('messages.nav.logs'),
              name: 'message-logs'
            },
            {
              text: $t('messages.nav.email_templates'),
              name: 'message-templates'
            },
            {
              text: $t('messages.nav.sms'),
              name: 'messages-sms'
            }
          ]
        : []
    "
  >
    <EmptySubscriptionPage
      v-if="!hasFeatureFlag('module-messages')"
      :text="{
        title: $t('upgrade_subscription_page.messages.main.title'),
        description: $t('upgrade_subscription_page.messages.main.description'),
        btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
      }"
      :moreLink="{
        text: $t('upgrade_subscription_page.messages.main.learn_more'),
        url: $t('upgrade_subscription_page.messages.main.learn_more_url')
      }"
      imageName="messages"
    />
  </ModuleBase>
</template>

<script lang="ts">
export default {
  name: 'Messages'
};
</script>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

const { hasFeatureFlag } = useUserStore();
</script>
