import { useRouter } from 'vue-router';

export const useNavBack = () => {
  const router = useRouter();

  const navBack = () => {
    const backUrl = router.options.history.state.back;
    const prevRoute = router.resolve({ path: `${backUrl}` });

    if (prevRoute.name === '404') {
      router.push({ name: 'marketing-email' });
    } else {
      router.back();
    }
  };

  return { navBack };
};
