<template>
  <div>
    <MessageBlock
      :title="$t('marketing.email.new_message.title')"
      :description="$t('marketing.email.new_message.description')"
      :buttonText="$t('global.actions.create')"
      mixpanelEvent="Create newsletter button clicked"
      buttonRoute="newsletter-template"
    />
    <TemplatesOverview />
    <AutomatedEmails v-test="'automated-emails'" />
    <MessageBlock
      :title="$t('marketing.email.messages_log.title')"
      :description="$t('marketing.email.messages_log.description')"
      :buttonText="$t('marketing.email.messages_log.route')"
      buttonRoute="messages"
    />
  </div>
</template>

<script lang="ts">
import MessageBlock from './MessageBlock.vue';
import AutomatedEmails from './AutomatedEmails.vue';
import TemplatesOverview from './templates-overview/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MessageBlock,
    AutomatedEmails,
    TemplatesOverview
  }
});
</script>
