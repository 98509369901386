<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.largeScreen]: $screen === 'l'
      }
    ]"
  >
    <div :class="$style.inner">
      <div>
        <div :class="$style.header">
          <BaseText
            bold
            :mb="0.5"
            iconBefore="email"
            v-test="'email-template-name'"
          >
            <div :class="$style.textContainer">
              {{ template.name || $t('marketing.email.templates.no_title') }}
            </div>
          </BaseText>
          <div :class="{ [$style.isSmall]: $screen === 's' }">
            <BaseLabel
              v-if="template.emailType === 'DEFAULT_MARKETING'"
              ml
              color="primary"
              v-test="'enabledStateLabel'"
            >
              {{ $t('global.default') }}
            </BaseLabel>
            <BaseLabel
              v-if="showRemainingDaysLabel"
              color="success"
              :mt="-0.5"
              v-test="'remainingDaysLabel'"
            >
              <BaseIcon name="clock" :mr="0.25" color="inverted" size="xs" />
              {{ filters.fromNow(template.deliverAt) }}
            </BaseLabel>
          </div>
        </div>
        <div
          v-if="hasImage"
          :class="$style.visual"
          :style="{ 'background-image': `url(${template.imageUrl})` }"
          v-test="'email-template-image'"
        />
        <BaseText
          v-if="template.subject"
          oneLine
          mb
          :mt="hasImage ? null : 1.5"
          v-test="'email-template-subject'"
        >
          {{ template.subject }}
        </BaseText>
      </div>
      <div :class="$style.buttons">
        <BaseButton
          color="inverted"
          icon="delete"
          @click="deleteTemplate"
          v-test="'email-template-delete'"
        />
        <BaseButton
          :routerLink="{
            name: 'newsletter-template-edit',
            params: { id: template.id }
          }"
          color="inverted"
          @click="trackTemplate"
          v-test="'email-template-edit'"
        >
          {{ $t('marketing.email.templates.edit') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import dayjs from '@/dayjs';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    hasImage() {
      return this.template.hasImage && !!this.template.imageUrl;
    },

    showRemainingDaysLabel() {
      return (
        this.template?.hasDeliverySchedule &&
        dayjs().isBefore(dayjs(this.template.deliverAt))
      );
    }
  },
  methods: {
    trackTemplate() {
      return this.mixpanel.track('Edit newsletter template');
    },
    deleteTemplate() {
      modal('confirmation', {
        type: 'delete',
        item: this.$t('global.items.email_template')
      }).then(() => {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation deleteEmailTemplate($input: DeleteEmailTemplateInput!) {
                deleteEmailTemplate(input: $input) {
                  emailTemplate {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                id: this.template.id
              }
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'emailTemplates' });
              cache.gc();
            }
          })
          .then(() => {
            flash(this.$t('global.flash.template_deleted'));
          });
      });
    }
  }
});
</script>

<style lang="scss" module>
p {
  margin: 0;
}
.base {
  padding: $spacing * 0.5;
  min-width: 250px;
  max-width: 400px;

  &.largeScreen {
    width: 33.333%;
  }

  &.smallScreen {
    width: 100%;
  }

  &:not(.smallScreen):not(.largeScreen) {
    width: 50%;
  }
}

.textContainer {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner {
  background-color: white;
  border-radius: $radius;
  border: 1px solid $color-border;
  padding: $spacing;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;
}

.visual {
  background-size: cover;
  background-position: center center;
  padding-bottom: 40%;
  margin-bottom: $spacing;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: $spacing;
}
</style>
