import gql from 'graphql-tag';

export const GET_GOOGLE_CALENDAR = gql`
  query getGoogleCalendar {
    googleCalendar {
      app {
        id
        settings {
          calendars
          companyCalendar
          mode
        }
      }
      unwritableLists {
        id
        summary
      }
      writableLists {
        id
        summary
      }
    }
  }
`;

export const UPDATE_GOOGLE_CALENDAR_APP_MUTATION = gql`
  mutation updateGoogleCalendarApp($input: UpdateGoogleCalendarAppInput!) {
    updateGoogleCalendarApp(input: $input) {
      googleCalendarApp {
        id
      }
    }
  }
`;

export const GET_INCOMING_GOOGLE_CALENDARS = gql`
  query getIncomingGoogleCalendars {
    incomingGoogleCalendars {
      calendarId
      enabled
      id
      locationId
      resourceId
    }
  }
`;

export const CREATE_INCOMING_GOOGLE_CALENDAR_MUTATION = gql`
  mutation createIncomingGoogleCalendars(
    $input: CreateIncomingGoogleCalendarsInput!
  ) {
    createIncomingGoogleCalendars(input: $input) {
      incomingGoogleCalendars {
        calendarId
        enabled
        id
        locationId
        resourceId
      }
      errors {
        message
        type
      }
    }
  }
`;
