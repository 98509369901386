<template>
  <BaseModal
    small
    :heading="$t('marketing.email.variables-modal.heading')"
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('marketing.email.variables-modal.info') }}
    </BaseText>
    <BaseText mb bold>
      {{ $t('marketing.email.variables-modal.what_you_can_use') }}
    </BaseText>
    <div v-if="template">
      <BaseTableRow head>
        <BaseTableCell>
          {{ $t('global.purpose') }}
        </BaseTableCell>
        <BaseTableCell>
          {{ $t('global.code') }}
        </BaseTableCell>
      </BaseTableRow>
      <BaseTableRow
        v-for="(item, index) in template.variables"
        :key="index"
        zebra
      >
        <BaseTableCell>
          {{ $t(`template_variables.${item}`) }}
        </BaseTableCell>
        <BaseTableCell @click="copyCode(item, index)">
          <div :class="$style.copyStyles">
            <BaseIcon name="copy" color="primary" mr />
            {{
              copiedText && index === copiedId
                ? $t('custom_booking_widget.copied')
                : withCurlyBrackets(item)
            }}
          </div>
        </BaseTableCell>
      </BaseTableRow>
    </div>
    <BaseSpinner v-else-if="$apollo.loading && !template" inline />
    <template #footer>
      <BaseButton @click="$emit('close')">
        {{ $t('global.ok') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  emits: ['close'],
  data() {
    return {
      templates: [],
      copiedText: false,
      copiedId: 0
    };
  },
  computed: {
    emailType() {
      return this.$store.getters['marketing/builder/emailType'];
    },
    template() {
      return this.templates.find((template) => {
        if (this.emailType === 'REBOOK_REMINDER') {
          return template.id === 'rebook_reminder_email';
        } else if (this.emailType === 'BIRTHDAY') {
          return template.id === 'marketing_birthday_message';
        } else if (this.emailType === 'ONLINE_FORMS') {
          return template.id === 'online_forms_email';
        } else {
          return template.id === 'marketing_email';
        }
      });
    }
  },
  methods: {
    withCurlyBrackets(value) {
      return `{{${value}}}`;
    },
    copyCode(value: any, index: number) {
      this.copiedText = true;
      this.copiedId = index;
      navigator.clipboard.writeText(`{{${value}}}`);

      setTimeout(() => {
        this.copiedText = false;
        this.copiedId = 0;
      }, 3000);
    }
  },
  apollo: {
    templates: {
      query: gql`
        query templates {
          templates {
            id
            variables
          }
        }
      `
    }
  }
});
</script>

<style lang="scss" module>
.copyStyles {
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>
