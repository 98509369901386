<template>
  <div :class="$style.base">
    <CreateDiscountCode
      v-if="showCreateModal"
      @discountCode="setDiscountCode"
      @close="showCreateModal = false"
    />
    <div :class="$style.flex">
      <BaseText bold>
        {{ $t('marketing.email.sections.discount_code') }}
      </BaseText>
      <BaseToggle v-model="hasDiscount" v-test="'toggleDiscount'" />
    </div>
    <div v-show="hasDiscount">
      <BaseDropdown
        v-model="selectedDiscount"
        :label="$t('global.actions.select_code')"
        :options="
          discountCodes.map((d) => ({
            value: d.id,
            label: d.code
          }))
        "
        :required="hasDiscount"
        mt
        v-test="'select-discount-code'"
      />
      <BaseText
        :mt="0.5"
        link
        @click="showCreateModal = true"
        v-test="'createDiscountCode'"
      >
        + {{ $t('marketing.discounts.new_discount_code') }}
      </BaseText>
      <BaseInput
        v-model="discountMessage"
        mt
        type="textarea"
        :label="$t('global.message')"
        :required="hasDiscount"
        :maxLength="150"
        v-test="'discountMessage'"
      />
      <BaseText :mt="0.25" color="secondary" size="s">
        {{ $t('marketing.email.sections.discount_max_characters') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import useVuelidate from '@vuelidate/core';
import CreateDiscountCode from '@/modules/marketing/modals/CreateDiscountCode.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CreateDiscountCode
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      discountCodes: [],
      showCreateModal: false
    };
  },
  watch: {
    'v$.$invalid': {
      handler() {
        this.$store.commit(
          'marketing/SET_DISCOUNT_VALIDATION',
          this.v$.$invalid
        );
      },
      immediate: true
    },
    isSubmitted() {
      this.v$.$touch();
    }
  },
  computed: {
    isSubmitted() {
      return this.$store.getters['marketing/isSubmitted'];
    },
    id() {
      return this.$store.getters['marketing/id'];
    },
    discount() {
      return this.$store.getters['marketing/discount'];
    },
    selectedDiscount: {
      get() {
        return this.$store.getters['marketing/discountCodeId'];
      },
      set(value) {
        const discount = this.discountCodes.find((code) => code.id === value);
        this.$store.commit('marketing/SET_DISCOUNT_CODE', discount);
      }
    },
    hasDiscount: {
      get() {
        return this.$store.getters['marketing/hasDiscount'];
      },
      set() {
        this.$store.commit('marketing/TOGGLE_DISCOUNT');
      }
    },
    discountMessage: {
      get() {
        return this.$store.getters['marketing/discountMessage'];
      },
      set(value) {
        this.$store.commit('marketing/SET_DISCOUNT_MESSAGE', value);
      }
    }
  },
  methods: {
    setDiscountCode(value) {
      this.$store.commit('marketing/SET_DISCOUNT_CODE', value);
    }
  },
  created() {
    if (this.hasDiscount && this.discount?.code) {
      this.setDiscountCode({ id: this.discount.id, code: this.discount.code });
    }
  },
  apollo: {
    discountCodes: {
      query: gql`
        query getDiscountCodes(
          $pagination: PaginationAttributes
          $state: [DiscountCodeStateEnum!]!
        ) {
          discountCodes(pagination: $pagination, state: $state) {
            id
            code
          }
        }
      `,
      variables() {
        return {
          pagination: {
            currentPage: 1,
            perPage: 1000
          },
          state: 'ENABLED'
        };
      },
      result() {
        if (!this.id && this.discountCodes?.length) {
          this.$store.commit('marketing/SET_DISCOUNT_TOGGLE', true);
        }
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
  padding: $spacing 0;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
