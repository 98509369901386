import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { ref, reactive } from 'vue';

import type {
  Appointment,
  QueryAppointmentArgs,
  Service,
  Allocation,
  CustomerMember
} from '@/types';
import type { FormData } from './index';
import type { Requirement } from './requirements';

export const getAppointmentQuery = gql`
  query getAppointment($id: Int!) {
    appointment(id: $id) {
      customer {
        alertNotes
        blocked
        email
        firstName
        id
        lastName
        mobilePhone
        phone
      }
      customerMember {
        firstName
        lastName
      }
      id
      locationId
      notes
      parts {
        allocations {
          id
          requirement {
            id
            primary
            resources {
              id
            }
            type
          }
          resourceId
        }
        duration
        durationFinish
        durationProcessing
        durationSetup
        id
        requiresProcessingTime
        service {
          category {
            id
            name
          }
          deleted
          id
          name
          price
          requirements {
            id
            resources {
              id
            }
            type
          }
          resourceAdjustments {
            id
            price
            resourceId
          }
          requiresProcessingTime
        }
        sortOrder
      }
      rrule
      startAt
      treatwell
    }
  }
`;

interface ExistingAppointmentData {
  customer?: any;
  customerMember?: CustomerMember;
  deletedServices?: Service[];
  hasRrule?: boolean;
  id?: Appointment['id'] | null;
  initialStartAt?: Appointment['startAt'];
  treatwell?: Appointment['treatwell'];
  requirements?: any[];
}

export const useExistingAppointment = () => {
  const existingAppointmentData: ExistingAppointmentData = reactive({});

  const setData = ({
    customer,
    customerMember,
    deletedServices,
    hasRrule,
    id,
    initialStartAt,
    treatwell,
    requirements
  }: ExistingAppointmentData = {}) => {
    existingAppointmentData.customer = customer || null;
    existingAppointmentData.customerMember = customerMember;
    existingAppointmentData.deletedServices = deletedServices || [];
    existingAppointmentData.hasRrule = hasRrule || false;
    existingAppointmentData.id = id || null;
    existingAppointmentData.initialStartAt = initialStartAt || '';
    existingAppointmentData.treatwell = treatwell || false;
    existingAppointmentData.requirements = requirements || [];
  };

  setData();

  const isFetchingAppointment = ref(false);

  const loadExistingAppointment = ({
    id,
    formData
  }: {
    id: QueryAppointmentArgs['id'];
    formData: FormData;
  }) => {
    isFetchingAppointment.value = true;

    const { onResult } = useQuery(getAppointmentQuery, { id });

    onResult(({ data }) => {
      const appointment: Appointment = data.appointment;

      formData.customerId = appointment.customer?.id || null;
      formData.locationId = appointment.locationId;
      formData.id = appointment.id;
      formData.notes = appointment.notes || '';
      formData.rrule = appointment.rrule;
      formData.startAt = appointment.startAt;

      // The primary allocation should always be first, because the first resource dropdown should be linked to the primary allocation
      // For some reason the backend doesn't always sort it correctly, so we sort them here
      appointment.parts.forEach((part) => {
        part.allocations.sort(
          (a, b) =>
            Number(b.requirement.primary) - Number(a.requirement.primary)
        );
      });

      formData.partsAttributes = appointment.parts.map((part) => ({
        allocationsAttributes: part.allocations.map((allocation) => ({
          id: allocation.id,
          requirementId: allocation.requirement.id,
          resourceId: allocation.resourceId
        })),
        id: part.id,
        duration: part.requiresProcessingTime ? 0 : part.duration,
        durationFinish: part.requiresProcessingTime ? part.durationFinish : 0,
        durationProcessing: part.requiresProcessingTime
          ? part.durationProcessing
          : 0,
        durationSetup: part.requiresProcessingTime ? part.durationSetup : 0,
        serviceId: part.service.id
      }));

      const allocations = appointment.parts.reduce<
        (Allocation & { serviceId: number })[]
      >(
        (acc, part) =>
          acc.concat(
            part.allocations.map((allocation) => ({
              ...allocation,
              serviceId: part.service.id
            }))
          ),
        []
      );

      const requirements: Requirement[] = allocations.map((allocation) => {
        const { requirement } = allocation;
        const { id, primary, type } = requirement;
        return {
          id,
          primary,
          resourceIds: allocation.requirement.resources.map(
            (resource: any) => resource.id
          ),
          serviceId: allocation.serviceId,
          type
        };
      });

      setData({
        customer: appointment.customer,
        customerMember: appointment.customerMember || undefined,
        deletedServices: appointment.parts
          .map((part) => part.service)
          .filter((service) => service.deleted),
        hasRrule: !!appointment.rrule,
        id: appointment.id,
        initialStartAt: appointment.startAt,
        treatwell: appointment.treatwell,
        requirements
      });

      isFetchingAppointment.value = false;
    });
  };

  const resetExistingAppointmentData = () => {
    setData();
  };

  return {
    existingAppointmentData,
    isFetchingAppointment,
    loadExistingAppointment,
    resetExistingAppointmentData
  };
};
