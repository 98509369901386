<template>
  <BaseModal
    :heading="$t('onboarding.new_tasks.import.heading')"
    @close="showConfirmation = true"
  >
    <ImportConfirmation
      v-if="showConfirmation"
      @yesClick="$emit('closeModal')"
      @noClick="showConfirmation = false"
    />
    <BaseHeading v-if="wrongRows > 0" size="l" mb>
      <BaseIcon name="alert" color="error" :mr="0.5" size="l" />
      {{ wrongRows }} {{ $t('onboarding.new_tasks.import.issues') }}
    </BaseHeading>
    <BaseHeading v-if="wrongRows <= 0" size="l" mb>
      <BaseIcon name="checkmark-circle" color="success" :mr="0.5" size="l" />
      {{ $t('onboarding.new_tasks.import.ready') }}
    </BaseHeading>
    <BaseTable
      v-if="importRows.length > 0"
      :headers="[
        $t('new_import.columns.firstName'),
        $t('new_import.columns.lastName'),
        $t('new_import.columns.email'),
        $t('new_import.columns.phone')
      ]"
      :rows="importRows.slice(0, slicedRows)"
      @valueChange="handleValidation"
    />
    <BaseText
      v-if="importRows.length > 5 && !showMoreButton"
      link
      ml
      :mt="0.5"
      @click="handleShowMore"
    >
      {{ $t('new_import.show_more') }}
    </BaseText>
    <template #footer>
      <BaseButton :loading="submitLoading" @click="handleSubmit">
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { onMounted, ref } from 'vue';
import ImportConfirmation from './ImportConfirmation.vue';
import eventBus from '@/event-bus';

const props = defineProps<{
  columnData: any[];
  columnMapping: any;
  importId: number;
}>();

const importRows = ref([]);
const slicedRows = ref(5);
const showMoreButton = ref(false);

const handleShowMore = () => {
  showMoreButton.value = true;
  slicedRows.value = importRows.value.length + 1;

  setTimeout(() => {
    v$.value.$touch();
  }, 100);
};

const emit = defineEmits(['closeModal', 'success']);

const v$ = useVuelidate();
const wrongRows = ref(0);
const showConfirmation = ref(false);

const handleValidation = () => {
  v$.value.$touch();
};

const { mutate: updateImport } = useMutation(gql`
  mutation updateImport($input: UpdateImportInput!) {
    updateImport(input: $input) {
      import {
        createdAt
        dataPreview
        headers
        id
        productsCount
        state
      }
    }
  }
`);

const { mutate: validateImport, loading: submitLoading } = useMutation(gql`
  mutation validateImport($input: ValidateImportInput!) {
    validateImport(input: $input) {
      recordAttributes {
        firstName
        lastName
        email
        phone
      }
      successfulRecords
      unsuccessfulRecords
    }
  }
`);

onMounted(() => {
  updateImport({
    input: {
      columnMapping: props.columnMapping,
      id: props.importId
    }
  }).then(() => {
    validateImport({
      input: {
        importId: props.importId
      }
    }).then(({ data }: any) => {
      wrongRows.value = data.validateImport.unsuccessfulRecords;
      importRows.value = data.validateImport.recordAttributes.map(
        (item: any, idx: number) => ({
          id: idx,
          disabled: false,
          cells: [
            {
              value: item.firstName,
              type: 'text',
              editable: true,
              required: true
            },
            {
              value: item.lastName,
              type: 'text',
              editable: true,
              required: true
            },
            {
              value: item.email,
              type: 'email',
              editable: true
            },
            { value: item.phone, editable: true, type: 'text' }
          ]
        })
      );
      setTimeout(() => {
        handleValidation();
      }, 100);
    });
  });
});

const handleSubmit = () => {
  validateImport({
    input: {
      importId: props.importId,
      correctedData: importRows.value
        .filter((item: any) => !item.disabled)
        .map((item: any) => ({
          firstName: item.cells[0].value,
          lastName: item.cells[1].value,
          email: item.cells[2].value,
          phone: item.cells[3].value
        }))
    }
  }).then(() => {
    eventBus.$emit('update-task', 'import_customers');
    emit('success');
  });
};
</script>
