<template>
  <div :class="$style.base">
    <BaseSpinner v-show="!hideSpinner" inline />
  </div>
</template>

<script lang="ts">
let observer;

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    hideSpinner: {
      type: Boolean,
      default: false
    }
  },
  emits: ['visibilityChanged', 'shown', 'hidden'],
  methods: {
    onVisibilityChange(entries) {
      this.$emit('visibilityChanged', entries[0].isIntersecting);
      this.$emit(entries[0].isIntersecting ? 'shown' : 'hidden');
    }
  },
  mounted() {
    // To watch for intersection relative to the device's viewport, specify null for root option.
    observer = new IntersectionObserver(this.onVisibilityChange, {
      root: null
    });

    observer.observe(this.$el);
  },
  beforeUnmount() {
    if (observer) {
      observer.disconnect();
      observer = null;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;
  padding: $spacing;
}
</style>
