<template>
  <BaseCard :heading="$t('cashups.payment_totals')">
    <BaseTable
      v-if="cashup"
      :headers="[
        $t('cashups.payment_type'),
        $t('global.expected'),
        $t('global.counted'),
        $t('global.difference')
      ]"
      :rows="
        cashup.payments.map((r) => ({
          cells: [
            $t(`global.payments.${r.type.toLowerCase()}`),
            filters.currency(r.expected),
            r.countable
              ? filters.currency(r.counted)
              : filters.currency(r.expected),
            filters.currency(r.difference)
          ]
        }))
      "
      :footers="[
        $t('cashups.totals'),
        cashup ? filters.currency(cashup.expectedTotal) : 0,
        cashup ? filters.currency(cashup.countedTotal) : 0,
        cashup ? filters.currency(cashup.totalDifference) : 0
      ]"
      v-test="'payment-totals-rows'"
    />
    <BaseText v-else v-test="'payment-totals-no-results'">
      {{ $t('global.no_results') }}
    </BaseText>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { Cashup } from '@/types';

export default defineComponent({
  props: {
    cashup: {
      type: Object as PropType<Cashup>
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
