<template>
  <div
    :class="[
      $style.base,
      {
        [$style.reversed]: reverse
      }
    ]"
  >
    <div v-if="!hideValue" :class="$style.value">#{{ modelValue }}</div>
    <div :class="$style.buttons">
      <div
        :class="[
          $style.button,
          {
            [$style.disabled]: disableDecrement
          }
        ]"
        @click.stop.prevent="disableDecrement ? null : changeValue(-1)"
        v-test="'_base-counter-decrement'"
      >
        <BaseIcon :name="reverse ? 'chevron-up' : 'chevron-down'" />
      </div>
      <div
        :class="[
          $style.button,
          {
            [$style.disabled]: disableIncrement
          }
        ]"
        @click.stop.prevent="disableIncrement ? null : changeValue(1)"
        v-test="'_base-counter-increment'"
      >
        <BaseIcon :name="reverse ? 'chevron-down' : 'chevron-up'" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    minValue: {
      type: Number
    },
    maxValue: {
      type: Number
    },
    hideValue: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    disableIncrement() {
      return this.maxValue && this.modelValue === this.maxValue;
    },
    disableDecrement() {
      return (
        this.modelValue === (this.minValue !== undefined ? this.minValue : 1)
      );
    }
  },
  methods: {
    changeValue(increment) {
      this.$emit('update:modelValue', this.modelValue + increment);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: inline-flex;
  align-items: center;
  padding: $spacing * 0.25;
  border-radius: $radius;
  background-color: white;
  border: 1px solid $color-border-input;
}

.value {
  padding: $spacing * 0.25 0 $spacing * 0.25 $spacing * 0.25;
  min-width: 30px;
  margin-right: $spacing * 0.25 * -1;
}

.buttons {
  display: flex;
  padding: 0 $spacing * 0.25;

  .base.reversed & {
    flex-direction: row-reverse;
  }
}

.button {
  cursor: pointer;
  padding: $spacing * 0.25 0;

  &.disabled {
    opacity: 0.5;
  }
}
</style>
