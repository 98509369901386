const Customers = () => import('@/modules/customers/index.vue');
const CustomersAll = () => import('@/modules/customers/all/index.vue');
const CustomersNew = () => import('@/modules/customers/new/index.vue');
const Customer = () => import('@/modules/customers/customer/index.vue');
const CustomerOverview = () =>
  import('@/modules/customers/customer/overview/index.vue');
const CustomerInvoices = () =>
  import('@/modules/customers/customer/invoices/index.vue');
const CustomerForms = () =>
  import('@/modules/customers/customer/forms/index.vue');
const CustomerHistory = () =>
  import('@/modules/customers/customer/history/index.vue');
const CustomerReports = () =>
  import('@/modules/customers/customer/reports/index.vue');
const CustomerLoyaltyPoints = () =>
  import('@/modules/customers/customer/loyalty-points/index.vue');
const CustomerAppointments = () =>
  import('@/modules/customers/customer/appointments/index.vue');
const CustomerPrepaidCards = () =>
  import('@/modules/customers/customer/prepaid-cards/index.vue');
const CustomerFeedback = () =>
  import('@/modules/customers/customer/feedback/index.vue');
const CustomerIPL = () => import('@/modules/customers/customer/ipl/index.vue');

const CreateCustomerReport = () => import('@/modals/CreateCustomerReport.vue');
const AppointmentReport = () => import('@/modals/AppointmentReport.vue');
const Invoice = () => import('@/modals/invoice/index.vue');
const InvoiceEmail = () => import('@/modals/InvoiceEmail.vue');
const PrepaidCard = () =>
  import('@/modules/register/prepaid-cards/PrepaidCard.vue');
const Form = () => import('@/modals/forms/Form.vue');
const FormPreview = () => import('@/modals/forms/FormPreview.vue');
const Forms = () => import('@/modals/forms/Forms.vue');

import appointmentRoutes from './appointment';
const CreateEvent = () => import('@/modules/calendar/create-event/index.vue');
const Appointment = () => import('@/modules/calendar/appointment/index.vue');

const Imports = () => import('@/modules/customers/Imports.vue');

export default {
  path: 'customers',
  name: 'customers',
  component: Customers,
  redirect: {
    name: 'customers-all'
  },
  meta: {
    featureFlag: 'module-customers',
    moduleName: 'customers',
    role: 'basic'
  },
  children: [
    {
      path: '',
      name: 'customers-all',
      component: CustomersAll
    },
    {
      path: 'new',
      name: 'customers-new',
      component: CustomersNew
    },
    {
      path: 'import',
      name: 'customers-imports',
      component: Imports
    },
    {
      path: ':customerId',
      name: 'customer',
      component: Customer,
      redirect: { name: 'customer-overview' },
      children: [
        {
          path: 'overview',
          name: 'customer-overview',
          component: CustomerOverview,
          meta: {
            mixpanelName: 'Customer - overview'
          }
        },
        {
          path: 'history',
          name: 'customer-history',
          component: CustomerHistory,
          meta: {
            mixpanelName: 'Customer - history'
          }
        },
        {
          path: 'reports',
          name: 'customer-reports',
          component: CustomerReports,
          meta: {
            mixpanelName: 'Customer - reports'
          },
          children: [
            {
              path: 'new',
              name: 'customer-report-create',
              component: CreateCustomerReport,
              props: {
                parentRouteName: 'customer-reports'
              }
            },
            {
              path: ':id',
              name: 'customer-report',
              component: AppointmentReport,
              props: {
                parentRouteName: 'customer-reports'
              }
            },
            {
              path: ':id/edit',
              name: 'customer-report-edit',
              component: CreateCustomerReport,
              props: {
                parentRouteName: 'customer-reports'
              }
            }
          ]
        },
        {
          path: 'loyalty-points',
          name: 'customer-loyalty-points',
          component: CustomerLoyaltyPoints,
          meta: {
            mixpanelName: 'Customer - loyalty points'
          }
        },
        {
          path: 'invoices',
          name: 'customer-invoices',
          component: CustomerInvoices,
          meta: {
            mixpanelName: 'Customer - invoices'
          },
          children: [
            {
              path: ':id',
              name: 'customer-invoice',
              component: Invoice,
              props: {
                parentRouteName: 'customer-invoices'
              }
            },
            {
              path: ':id/email',
              name: 'customer-invoice-email',
              component: InvoiceEmail,
              props: {
                parentRouteName: 'customer-invoices'
              }
            }
          ]
        },
        {
          path: 'appointments',
          name: 'customer-appointments',
          component: CustomerAppointments,
          meta: {
            mixpanelName: 'Customer - appointments'
          },
          children: [
            {
              ...appointmentRoutes('customer-appointment'),
              path: ':appointmentId',
              name: 'customer-appointment',
              props: {
                parentRouteName: 'customer-appointments'
              }
            },
            {
              path: ':appointmentId/edit',
              component: CreateEvent,
              redirect: {
                name: 'customer-edit-appointment'
              },
              children: [
                {
                  path: '',
                  name: 'customer-edit-appointment',
                  component: Appointment
                }
              ]
            }
          ]
        },
        {
          path: 'prepaid-cards',
          name: 'customer-prepaid-cards',
          component: CustomerPrepaidCards,
          meta: {
            mixpanelName: 'Customer - prepaid cards'
          },
          children: [
            {
              path: ':id',
              name: 'customer-prepaid-card',
              component: PrepaidCard,
              props: {
                parentRouteName: 'customer-prepaid-cards'
              }
            }
          ]
        },
        {
          path: 'forms',
          name: 'customer-forms',
          component: CustomerForms,
          meta: {
            mixpanelName: 'Customer - forms'
          },
          children: [
            {
              path: 'new/:templateId',
              name: 'customer-form',
              component: Form,
              props: {
                parentRouteName: 'customer-forms'
              }
            },
            {
              path: ':subId/edit/:templateId',
              name: 'customer-form-edit',
              component: Form,
              props: {
                parentRouteName: 'customer-forms'
              }
            },
            {
              path: 'select',
              name: 'customer-forms-select',
              component: Forms,
              props: {
                parentRouteName: 'customer-forms'
              }
            },
            {
              path: ':id',
              name: 'customer-form-preview',
              component: FormPreview,
              props: {
                parentRouteName: 'customer-forms'
              }
            }
          ]
        },
        {
          path: 'feedback',
          name: 'customer-feedback',
          component: CustomerFeedback,
          meta: {
            mixpanelName: 'Customer - feedback'
          }
        },
        {
          path: 'ipl-treatments',
          name: 'customer-ipl',
          component: CustomerIPL,
          meta: {
            mixpanelName: 'Customer - IPL'
          }
        }
      ]
    },
    {
      path: ':id/edit',
      name: 'customers-edit',
      component: CustomersNew
    }
  ]
};
