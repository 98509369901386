import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const GET_COMPANY_QUERY = gql`
  query getCurrentUser {
    currentUser {
      company {
        integrations
        invoiceFooter
        invoiceFooterThermal
        invoiceHeader
        invoiceImage
        invoiceRecipientTemplate
        settings {
          sales {
            paymentLink
            printNextAppointment
            showTreatmentDateOnInvoice
            showVatOnInvoice
            thermalSupport
          }
          datev {
            bankAccount
            billsReceivableAccount
            cashAccount
            deferredRevenueAccount
            revenueAccount
          }
        }
      }
    }
  }
`;

export const useInvoices = () => {
  const { onResult } = useQuery(GET_COMPANY_QUERY);
  const hasPaymentProvider = ref(false);

  const currentImageUrl = ref('');
  const formData = reactive({
    invoiceHeader: '',
    invoiceFooter: '',
    invoiceFooterThermal: '',
    invoiceRecipientTemplate: '',
    invoiceImage: '',
    settings: {
      sales: {
        paymentLink: false,
        printNextAppointment: false,
        showTreatmentDateOnInvoice: false,
        showVatOnInvoice: false,
        thermalSupport: false
      },
      datev: {
        bankAccount: 0,
        billsReceivableAccount: 0,
        cashAccount: 0,
        deferredRevenueAccount: 0,
        revenueAccount: 0
      }
    }
  });

  onResult(({ data: { currentUser } }) => {
    formData.invoiceHeader = currentUser.company.invoiceHeader;
    formData.invoiceFooter = currentUser.company.invoiceFooter;
    formData.invoiceFooterThermal = currentUser.company.invoiceFooterThermal;
    formData.invoiceRecipientTemplate =
      currentUser.company.invoiceRecipientTemplate;
    formData.invoiceImage = currentUser.company.invoiceImage;
    formData.settings.sales.paymentLink =
      currentUser.company.settings.sales.paymentLink;
    formData.settings.sales.printNextAppointment =
      currentUser.company.settings.sales.printNextAppointment;
    formData.settings.sales.showTreatmentDateOnInvoice =
      currentUser.company.settings.sales.showTreatmentDateOnInvoice;
    formData.settings.sales.showVatOnInvoice =
      currentUser.company.settings.sales.showVatOnInvoice;
    formData.settings.sales.thermalSupport =
      currentUser.company.settings.sales.thermalSupport;
    formData.settings.datev.bankAccount =
      currentUser.company.settings.datev.bankAccount;
    formData.settings.datev.billsReceivableAccount =
      currentUser.company.settings.datev.billsReceivableAccount;
    formData.settings.datev.cashAccount =
      currentUser.company.settings.datev.cashAccount;
    formData.settings.datev.deferredRevenueAccount =
      currentUser.company.settings.datev.deferredRevenueAccount;
    formData.settings.datev.revenueAccount =
      currentUser.company.settings.datev.revenueAccount;

    currentImageUrl.value = currentUser.company.invoiceImage;

    hasPaymentProvider.value =
      currentUser.company.integrations.stripe ||
      currentUser.company.integrations.mollie ||
      currentUser.company.integrations.adyen;
  });

  return {
    formData,
    currentImageUrl,
    hasPaymentProvider
  };
};
