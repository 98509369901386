<template>
  <Draggable :list="modelValue" item-key="index" :class="$style.base">
    <template #item="{ element, index }">
      <div :class="$style.imageWrapper">
        <BaseImage
          :cloudinaryId="element"
          crop="fill"
          width="150"
          height="100"
          mt
          v-test="'baseImageGallery-image'"
        />
        <div v-if="showActions" :class="$style.actions">
          <div
            v-if="remove"
            :class="$style.actionIcon"
            @click="removeImage(index)"
            v-test="'baseImageGallery-delete'"
          >
            <BaseIcon name="delete" />
          </div>
          <div :class="$style.actionIcon">
            <BaseIcon name="drag" />
          </div>
        </div>
      </div>
    </template>
  </Draggable>
</template>

<script lang="ts">
import Draggable from 'vuedraggable';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Draggable
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    remove: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    showActions() {
      return this.remove || this.sort || this.drag;
    },
    images: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    removeImage(index) {
      const arr = [...this.images];
      arr.splice(index, 1);
      this.images = arr;
    }
  }
});
</script>

<style module lang="scss">
.base {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: $spacing * 0.5;

  img {
    border-radius: $radius $radius 0 0;
  }
}

.actions {
  width: 150px;
  background-color: #d5e3ea;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  margin: -3px auto;
  border-radius: 0 0 $radius $radius;
}

.actionIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border: 1px solid $color-border-input;
  cursor: pointer;
  background-color: white;
  border-radius: $radius;
}
</style>
