<template>
  <div>
    <BaseText bold>
      {{ $t('onboarding.new_onboarding.employee.title') }}
    </BaseText>
    <div :class="$style.base">
      <div :class="$style.input">
        <BaseCard gray>
          <BaseInputLabel :text="$t('global.name')" required />
          <BaseInput
            v-if="showInput"
            v-model="employeeName"
            focus
            :class="$style.input"
            required
            v-test="'employeeInput'"
          />
          <div v-else :class="$style.name">
            <BaseText>
              {{ employeeName }}
            </BaseText>
            <BaseText link @click="onEdit" v-test="'edit-resource-name'">
              {{ $t('global.actions.edit') }}
            </BaseText>
          </div>
        </BaseCard>
      </div>
      <img v-if="$screen !== 's'" src="/img/onboarding/employee.svg" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const emit = defineEmits(['update:modelValue']);
const showInput = ref(false);

const props = defineProps<{
  modelValue: string;
}>();

const employeeName = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const onEdit = () => {
  showInput.value = true;
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.input {
  width: 330px;
  padding-top: $spacing;
  padding-right: $spacing;
}

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
