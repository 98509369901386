import gql from 'graphql-tag';

export const getTreatwellTreatments = gql`
  query getTreatwellTreatments {
    treatwellTreatments {
      id
      name
      group {
        id
        name
      }
    }
  }
`;

export const getCategories = gql`
  query getServiceCategories {
    serviceCategories {
      color
      id
      name
      services {
        bookable
        id
        name
        price
        popular
        twTreatments {
          id
        }
      }
    }
  }
`;

export const updateServices = gql`
  mutation updateServices($input: UpdateServicesInput!) {
    updateServices(input: $input) {
      services {
        id
      }
    }
  }
`;
