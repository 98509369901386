<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <BaseTableRow head>
        <BaseTableCell :width="5" />
        <BaseTableCell :width="20">{{ $t('global.sent') }}</BaseTableCell>
        <BaseTableCell :width="20">{{ $t('global.type') }}</BaseTableCell>
        <BaseTableCell :width="20">{{
          $t('global.items.customer', 1)
        }}</BaseTableCell>
        <BaseTableCell :width="20">{{ $t('global.contact') }}</BaseTableCell>
        <BaseTableCell :width="25">{{ $t('global.message') }}</BaseTableCell>
        <BaseTableCell :width="10">{{ $t('global.status') }}</BaseTableCell>
        <BaseTableCell v-show="hasTrackingData" :width="10">{{
          $t('messages.tracking')
        }}</BaseTableCell>
        <div v-if="!hasTrackingData" :class="$style.showMore">
          <BaseTableCell />
        </div>
      </BaseTableRow>

      <BaseTableRow
        v-for="(item, index) in messages"
        :key="`row-${index}`"
        :class="$style.row"
        :options="canResend(item)"
        zebra
        :routerLink="{
          name: 'message',
          query: filter ? { filter } : {},
          params: { messageId: item.id }
        }"
        @optionClick="onOptionClick($event, item)"
        v-test="'messages-message'"
      >
        <BaseTableCell :width="5">
          <div>
            <BaseIcon
              v-if="item.gateway"
              :name="icon(item)"
              v-test="`message-icon-${icon(item)}`"
            />
          </div>
        </BaseTableCell>
        <BaseTableCell :width="20">{{
          filters.dateTime(item.createdAt)
        }}</BaseTableCell>
        <BaseTableCell :width="20">{{
          $t(`messages.types.${item.messageType}`)
        }}</BaseTableCell>
        <BaseTableCell :width="20">
          {{ renderCustomer(item) }}
        </BaseTableCell>
        <BaseTableCell
          v-if="item.gateway && item.gateway === 'email' && item.destination"
          wordBreak
          :width="20"
        >
          {{ renderDestination(item) }}
        </BaseTableCell>
        <BaseTableCell
          v-else-if="
            item.gateway &&
            item.gateway === 'sms' &&
            item.customer &&
            item.customer.mobilePhone
          "
          wordBreak
          :width="20"
        >
          {{ item.customer.mobilePhone }}
        </BaseTableCell>
        <BaseTableCell v-else :width="20"> - </BaseTableCell>
        <BaseTableCell v-if="item.subject" :width="25">{{
          item.subject
        }}</BaseTableCell>
        <BaseTableCell v-else :width="25" v-html="messageTitle(item.message)" />
        <BaseTableCell :width="10">
          <BaseLabel
            v-if="item.status"
            :color="
              item.status === 'sent' || item.status === 'delivered'
                ? 'success'
                : item.status !== 'sent' &&
                    item.status !== 'delivered' &&
                    item.status !== 'pending'
                  ? 'warning'
                  : 'default'
            "
          >
            {{ $t(`messages.status.${item.status}`) }}
          </BaseLabel>
          <BaseText v-else> - </BaseText>
        </BaseTableCell>
        <BaseTableCell v-show="hasTrackingData" :width="10">
          <BaseIcon
            name="email-open"
            :disabled="!item.openedAt"
            :tooltip="
              item.openedAt
                ? $t('messages.opened_at', {
                    time: filters.dateTime(item.openedAt)
                  })
                : null
            "
            :mr="0.5"
          />
          <BaseIcon
            name="button-clicked"
            :disabled="!item.buttonClickAt"
            :tooltip="
              item.buttonClickAt
                ? $t('messages.button_click_at', {
                    time: filters.dateTime(item.buttonClickAt)
                  })
                : null
            "
          />
        </BaseTableCell>
        <div
          v-if="!canResend(item).length && !hasTrackingData"
          :class="$style.showMore"
        >
          <BaseTableCell />
        </div>
      </BaseTableRow>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    messages: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    options() {
      return ['resend'];
    },
    hasTrackingData() {
      return (
        this.filter === 'rebook_reminder' ||
        this.filter === 'marketing_campaign'
      );
    }
  },
  methods: {
    messageTitle(message) {
      return message.substring(0, 50) + '...';
    },
    canResend(item) {
      if (
        item.gateway === 'sms' ||
        item.messageType === 'marketing_campaign' ||
        item.messageType === 'rebook_reminder' ||
        item.messageType === 'custom' ||
        item.messageType === 'feedback_response' ||
        item.status === 'pending' ||
        item.messageType === 'form_submission_request' ||
        item.status === 'undeliverable'
      ) {
        return [];
      }
      return this.options;
    },
    resendMessage(messageId) {
      const input = { messageId };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation resendMessage($input: ResendMessageInput!) {
              resendMessage(input: $input) {
                status
              }
            }
          `,
          variables: {
            input
          }
        })
        .then(() => {
          flash(this.$t('global.flash.msg_on_its_way'));
        });
    },
    onOptionClick(option, item) {
      if (option === 'resend') {
        modal('confirmation').then(() => {
          this.resendMessage(item.id);
        });
      }
    },
    renderCustomer(item) {
      if (item.customer && item.customer.fullName) {
        return item.customer.fullName;
      } else {
        return '-';
      }
    },
    renderDestination(item) {
      return item.destination;
    },
    icon(item) {
      if (item.gateway === 'email') {
        return 'email';
      } else {
        return 'mobile';
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;
  min-height: 70vh;
}

.inner {
  min-width: 1050px;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
