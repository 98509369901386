<template>
  <BaseCard v-test="`billing-rattings-card`">
    <div :class="{ [$style.small]: $screen === 's' || $screen === 'm' }">
      <BaseHeading mt center size="l">{{
        t('admin.cards.ratting.title')
      }}</BaseHeading>
      <div :class="$style.imageContainer">
        <img
          :class="$style.image"
          src="/img/billing/facebook-5-star.png"
          alt="facebook 5 start ratting"
        />
        <img
          :class="$style.image"
          src="/img/billing/getapp-5-star.png"
          alt="getapp 5 start ratting"
        />
        <img
          :class="$style.image"
          src="/img/billing/capterra-5-star.png"
          alt="capterra 5 start ratting"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" module>
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2 * $spacing 0 $spacing;

  .small & {
    flex-direction: column;
  }
}

.image {
  margin: 0 3 * $spacing;

  .small & {
    margin: 2 * $spacing 0;
  }
}
</style>
