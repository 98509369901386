<template>
  <BaseModal
    :heading="$t('register.salonized_pay.terminal_checkout.heading')"
    useForm
    :noPadding="true"
    @submit="
      () => {
        submit();
        if ($gtm) {
          $gtm.trackEvent({ event: 'sp-checkout-save' });
        }
      }
    "
    @close="$emit('close')"
  >
    <PageSplit inverted>
      <template #left>
        <Form :data="formData" />
      </template>
      <template #right>
        <Summary />
      </template>
    </PageSplit>
    <template #footer>
      <BaseButton
        submitForm
        :loading="loading"
        v-test="'sp-order-terminal-save'"
      >
        {{ $t('register.salonized_pay.terminal_checkout.complete') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import PageSplit from '@/modules/PageSplit.vue';
import { reactive } from 'vue';
import Form from './Form.vue';
import Summary from './Summary.vue';
import { useUserStore } from '@/stores/user';
import { useLocationsStore } from '@/stores/locations';
import { useAdyenStore } from '@/stores/adyen';
import { useMutation } from '@vue/apollo-composable';
import {
  COMPLETE_TERMINAL_CHECKOUT,
  CREATE_STRIPE_CHECKOUT_SESSION
} from './graphql';
import { modal } from '@/helpers/ui';
import { storeToRefs } from 'pinia';
import { SAVE_ADYEN_SELECTED_TERMINAL_MODEL } from '../../graphql';

defineEmits(['close']);

const mixpanel = inject<any>('mixpanel');
const { user } = useUserStore();
const { location } = useLocationsStore();
const { adyenAppState } = storeToRefs(useAdyenStore());

const formData = reactive({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  addressLine: location.address,
  postalCode: location.postalcode,
  city: location.city,
  termsAndConditions: false,
  locationId: location.id
});

const { mutate: completeCheckout, loading: completingCheckout } = useMutation(
  COMPLETE_TERMINAL_CHECKOUT
);
const {
  mutate: createStripeCheckoutSession,
  loading: creatingStripeCheckoutSession
} = useMutation(CREATE_STRIPE_CHECKOUT_SESSION);
const { mutate: saveSelectedTerminal, loading: savingTerminal } = useMutation(
  SAVE_ADYEN_SELECTED_TERMINAL_MODEL
);
const loading = computed(
  () =>
    completingCheckout.value ||
    creatingStripeCheckoutSession.value ||
    savingTerminal.value
);

const submit = () => {
  mixpanel.track('Salonized Pay Checkout - Complete checkout clicked', {
    otherLocation: location.id !== formData.locationId
  });

  saveSelectedTerminal({
    input: {
      terminalModel: adyenAppState.value.terminalModel
    }
  }).then(() => {
    completeCheckout({
      input: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        addressLine: formData.addressLine,
        postalCode: formData.postalCode,
        city: formData.city,
        locationId: formData.locationId,
        terminalModel: adyenAppState.value.terminalModel
      }
    }).then(
      ({
        data: {
          completeTerminalCheckout: { adyenShippingLocation }
        }
      }) => {
        if (adyenShippingLocation?.id) {
          redirectToStripe();
        } else {
          modal('error');
        }
      }
    );
  });
};

const redirectToStripe = () => {
  createStripeCheckoutSession({
    input: {
      type: adyenAppState.value.terminalModel
    }
  }).then(({ data: { createPayTerminalCheckoutSession } }) => {
    const url = createPayTerminalCheckoutSession?.url;

    if (url) {
      window.location.href = url;
    } else {
      modal('error');
    }
  });
};
</script>
