<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.section">
      <slot name="left" />
    </div>
    <div :class="$style.section">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: $spacing * -1 0 $spacing $spacing * -1;
  width: calc(100% + #{$spacing});
}

.section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .base.smallScreen & {
    width: 100%;
  }

  & > * {
    margin: $spacing 0 0 $spacing;

    .base.smallScreen & {
      width: 100%;
      flex-shrink: 1;
    }
  }
}
</style>
