<template>
  <div>
    <BaseRadio
      v-if="radioOptions.length > 1"
      v-model="radioValue"
      inline
      :label="$t('global.applies_to')"
      :options="radioOptions"
      mb
      v-test="'applies-to-radio'"
    />
    <BaseDropdown
      v-if="
        radioValue === 'employee' ||
        radioValue === 'room' ||
        radioValue === 'equipment'
      "
      v-model="dropdownValue"
      :options="resourcesData"
      :label="filters.capitalize($t(`global.items.${radioValue}`, 1))"
      resources
      v-test="'applies-to-resource'"
    />
    <BaseDropdown
      v-if="radioValue === 'location'"
      v-model="dropdownValue"
      :options="
        locations.map((l) => ({
          value: l.id,
          label: l.internalName
        }))
      "
      :label="filters.capitalize($t('global.items.location', 1))"
      v-test="'applies-to-location'"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { useResourcesStore } from '@/stores/resources';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      required: false
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:id'],
  setup() {
    return {
      filters
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locations']),
    ...mapState(useCompanyStore, ['multiLocation', 'companySettings']),
    ...mapState(useResourcesStore, [
      'employees',
      'rooms',
      'equipment',
      'hasSingleEmployee',
      'hasSingleResource',
      'resourceById'
    ]),
    radioValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('update:id', null);
      }
    },
    dropdownValue: {
      get() {
        return this.id;
      },
      set(value) {
        this.$emit('update:id', value);
      }
    },
    radioOptions() {
      return [
        {
          label: this.$t('global.entire_company'),
          value: 'company',
          hide: !this.options.includes('company')
        },
        {
          label: this.filters.capitalize(this.$t('global.items.employee', 1)),
          value: 'employee',
          hide: !this.options.includes('employee')
        },
        {
          label: this.filters.capitalize(this.$t('global.items.room', 1)),
          value: 'room',
          hide: !this.options.includes('room') || !this.rooms.length
        },
        {
          label: this.filters.capitalize(this.$t('global.items.equipment', 1)),
          value: 'equipment',
          hide: !this.options.includes('equipment') || !this.equipment.length
        },
        {
          label: this.filters.capitalize(this.$t('global.items.location', 1)),
          value: 'location',
          hide: !this.options.includes('location') || !this.multiLocation
        }
      ].filter((option) => !option.hide);
    },
    resourcesData() {
      let data;
      switch (this.radioValue) {
        case 'employee':
          data = this.employees;
          break;
        case 'room':
          data = this.rooms;
          break;
        case 'equipment':
          data = this.equipment;
          break;
        default:
          data = [];
          break;
      }
      return data.map((r) => ({
        value: r.id,
        label: r.name
      }));
    }
  }
});
</script>
