<template>
  <div v-if="!showEditTerminalButton" :class="$style.terminalWrapper">
    <div :class="$style.terminalInfo">
      <img
        :src="`/img/salonized-pay/onboarding/${adyenAppState.terminalModel}.png`"
      />
      <div>
        <BaseHeading :mb="0.5">{{ selectedTerminal.name }}</BaseHeading>
        <div :class="$style.terminalDetails">
          <div v-for="feature in features" :key="feature">
            <BaseText>{{
              $t(
                `register.salonized_pay.landing.terminals.modal.info.${feature}`
              ).toUpperCase()
            }}</BaseText>
          </div>
        </div>
      </div>
    </div>
    <BaseButton
      v-if="!showEditTerminalButton"
      icon="edit"
      ml
      color="inverted"
      @click="editTerminal"
      v-test="'sp-terminal-order-edit'"
    />
  </div>
  <div
    v-else
    :class="$style.terminalPicker"
    v-test="'sp-terminal-order-picker'"
  >
    <div
      v-for="(terminal, idx) in terminals"
      :key="terminal.id"
      :class="$style.terminalOptionWrapper"
      @click="handlePickTerminal(terminal, idx)"
      v-test="'sp-terminal-order-picker-option'"
    >
      <BaseCard fullWidth :mb="idx === 0" :selected="pickedTerminalIdx === idx">
        <div :class="$style.terminalOptionWrapper">
          <div :class="$style.terminalOption">
            <BaseRadio
              v-model="pickedTerminalIdx"
              :options="[{ value: idx, label: null }]"
            />
            <BaseText bold>{{ terminal.name }}</BaseText>
          </div>
          <BaseText>{{ filters.currency(terminal.price) }}</BaseText>
        </div>
        <div v-if="pickedTerminalIdx === idx" :class="$style.infoContainer">
          <img :src="`/img/salonized-pay/onboarding/${terminal.key}.png`" />
          <div :class="$style.infoArray">
            <BaseText v-for="(info, idx) in terminal.info" :key="idx">
              <li :class="$style.info">
                {{
                  $t(
                    `register.salonized_pay.landing.terminals.modal.info.${info}`
                  )
                }}
              </li>
            </BaseText>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
  <div :class="$style.summary">
    <BaseHeading mt mb>{{
      $t('register.salonized_pay.terminal_checkout.summary')
    }}</BaseHeading>
    <div :class="$style.summaryRow">
      <BaseText>{{ terminals[pickedTerminalIdx].name }}</BaseText>
      <BaseText>{{ filters.currency(price) }}</BaseText>
    </div>
    <div :class="$style.summaryRow">
      <BaseText>{{
        $t('register.salonized_pay.terminal_checkout.vat')
      }}</BaseText>
      <BaseText>{{ filters.currency(vat) }}</BaseText>
    </div>
    <div :class="[$style.summaryRow, $style.totalRow]">
      <BaseText bold>{{ $t('global.total') }}</BaseText>
      <BaseText bold>{{ filters.currency(total) }}</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { terminals } from '../../terminals';
import filters from '@/filters';
import { useAdyenStore } from '@/stores/adyen';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';

const { adyenAppState } = storeToRefs(useAdyenStore());
const { company } = useCompanyStore();
const mixpanel = inject<any>('mixpanel');

const formats = {
  nl: {
    vat: 0.21
  },
  de: {
    vat: 0
  },
  be: {
    vat: 0.21
  }
};

const selectedTerminal = computed(() =>
  terminals.find(
    (terminal) => terminal.key === adyenAppState.value.terminalModel
  )
);
const price = computed(() => terminals[pickedTerminalIdx.value].price);
const vat = computed(
  () => price.value * formats[company.country as keyof typeof formats].vat
);

const total = computed(() => price.value + vat.value);
const features = computed(() => terminals[pickedTerminalIdx.value].info);

const showEditTerminalButton = ref(false);
const pickedTerminalIdx = ref(0);

watch(
  () => selectedTerminal.value,
  () => {
    pickedTerminalIdx.value = terminals.findIndex(
      (terminal) => terminal.key === selectedTerminal.value.key
    );
  },
  { immediate: true }
);

const handlePickTerminal = (terminal: any, idx: any) => {
  mixpanel.track('Salonized Pay Checkout - Terminal saved', {
    terminal: terminal.key
  });
  pickedTerminalIdx.value = idx;
  adyenAppState.value.terminalModel = terminal.key;
};

const editTerminal = () => {
  showEditTerminalButton.value = true;
  mixpanel.track('Salonized Pay Checkout - Edit Terminal clicked');
};
</script>

<style lang="scss" module>
.terminalPicker {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: $spacing;
  margin: -$spacing;
  margin-bottom: $spacing;
  background: linear-gradient(
    115deg,
    #c0ede7 0%,
    #f4f5f9 45% 70%,
    #bab9e7 100%
  );
}

.terminalOptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terminalOption {
  display: flex;
  place-items: center;
}

.infoArray {
  display: flex;
  flex-direction: column;

  li::marker {
    color: $color-primary;
  }
}

.infoContainer {
  display: flex;
  gap: $spacing;
  margin-top: $spacing;
}

.terminalOptionWrapper {
  width: 100%;
  cursor: pointer;
}

.terminalWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing;
  border-radius: $radius;
  background: linear-gradient(
    115deg,
    #c0ede7 0%,
    #f4f5f9 45% 70%,
    #bab9e7 100%
  );
}

.terminalInfo {
  display: flex;
  align-items: center;
  gap: $spacing;
}

.terminalDetails {
  display: flex;
  gap: calc($spacing / 2);
  font-size: 10px;
  white-space: nowrap;
  font-size: 10px;
  flex-wrap: wrap;
}

.summaryRow {
  display: flex;
  justify-content: space-between;

  :first-child & {
    margin-bottom: calc($spacing / 2);
  }
}

.totalRow {
  margin-top: $spacing;
  padding-top: $spacing;
  border-top: 1px solid $color-border;
}
</style>
