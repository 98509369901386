<template>
  <div v-if="typeof localValue === 'boolean'" :class="$style.base">
    <BaseHeading size="l" :mb="2">
      {{ $t('admin.assistant.existing_customers.heading') }}
    </BaseHeading>
    <div :class="$style.form">
      <RadioCards
        v-model="localValue"
        :options="[
          {
            label: $t('admin.assistant.existing_customers.option_1.heading'),
            image: `/img/salonized-assistant/${getImageLanguage}/customers-current.png`,
            value: false
          },
          {
            label: $t('admin.assistant.existing_customers.option_2.heading'),
            image: `/img/salonized-assistant/${getImageLanguage}/customers-all.png`,
            value: true
          }
        ]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import getImageLanguage from '../imageLanguage';
import RadioCards from './_shared/RadioCards.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue?: boolean | null;
}>();

const localValue = ref(props.modelValue);

watch(
  localValue,
  () => {
    emit('update:modelValue', localValue.value);
  },
  {
    deep: true
  }
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  flex-grow: 1;
}
</style>
