<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm',
        [$style.largeScreen]: $screen === 'l'
      }
    ]"
  >
    <router-view />
    <BaseSpinner v-if="loading" />
    <div v-else :class="$style.templates">
      <div v-for="(group, index) in groupedTemplates" :key="index">
        <div :class="$style.templateHeading">
          <BaseHeading mb>
            {{ $t(`email_templates.categories.${group.name.toLowerCase()}`) }}
          </BaseHeading>
        </div>
        <router-link
          v-for="(item, itemIndex) in group.items"
          :key="itemIndex"
          :to="{
            name: 'message-template',
            params: {
              id: item.id
            }
          }"
          v-test="'company-email-template'"
        >
          <TemplateLink :heading="item.heading" />
        </router-link>
      </div>
    </div>
    <Banner v-if="!loading" v-test="'email-templates-banner'" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'EmailTemplates'
};
</script>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { useTemplateData } from './data';
import gql from 'graphql-tag';
import { groupBy } from '@/helpers/formatting';
import TemplateLink from '../TemplateLink.vue';
import Banner from './Banner.vue';

const { templates } = useTemplateData();

const { result, loading } = useQuery(gql`
  query getCompanyEmailTemplates {
    companyEmailTemplates {
      id
    }
  }
`);

const fetchedTemplates = computed(
  () => result.value?.companyEmailTemplates || []
);

const groupedTemplates = computed(() => {
  const filteredTemplates = templates.filter(
    (template) =>
      !!fetchedTemplates.value.find((t: any) => t.id === template.id)
  );
  return groupBy(filteredTemplates, 'category').map((group, index) => ({
    ...group,
    slot: index + 1
  }));
});
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen) {
    padding: $spacing;
  }
}

.templates {
  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing * 2;
  }

  .base.smallScreen & {
    padding-top: $spacing;
  }

  & > * {
    .base.smallScreen & {
      &:not(:last-child) {
        margin-bottom: $spacing * 2;
      }
    }

    .base.mediumScreen & {
      width: calc(50% - #{$spacing * 3});
    }

    .base.largeScreen & {
      width: calc(25% - #{$spacing * 1.5});
    }
  }
}

.templateHeading {
  padding: 0 $spacing;
}

.templateItem {
  display: block;
  cursor: pointer;
  padding: $spacing;
  border-bottom: 1px solid $color-border;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include hover {
    background-color: $color-highlight;
  }
}
</style>
