<template>
  <ModuleBase
    :gray="changeBg"
    :navItems="links"
    keepAliveComponentName="CustomersOverview"
  />
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useQuery } from '@vue/apollo-composable';
import { defineComponent, computed } from 'vue';
import gql from 'graphql-tag';

export default defineComponent({
  name: 'Customers',
  components: {
    ModuleBase
  },
  setup() {
    const { result } = useQuery(
      gql`
        query getCurrentUser {
          currentUser {
            company {
              hasFillableForms
            }
          }
        }
      `,
      null,
      {
        fetchPolicy: 'cache-first'
      }
    );

    const hasFillableForms = computed(
      () => !!result.value?.currentUser.company.hasFillableForms
    );

    return {
      hasFillableForms
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    changeBg() {
      return (
        this.$route.name === 'customer-feedback' ||
        this.$route.name === 'customer-overview' ||
        this.$route.name === 'customer-ipl' ||
        this.$route.name === 'customers-new'
      );
    },
    links() {
      if (!this.$route.matched.find((route) => route.name === 'customer')) {
        return [];
      }

      const links = [
        {
          text: this.$t('global.pages.overview'),
          name: 'customer-overview'
        }
      ];

      const invoices = {
        text: this.$t('global.pages.invoices'),
        name: 'customer-invoices'
      };

      const appointments = {
        text: this.$t('global.pages.appointments'),
        name: 'customer-appointments'
      };

      const reports = {
        text: this.$t('global.pages.reports'),
        name: 'customer-reports'
      };

      const forms = {
        text: this.$t('global.pages.forms'),
        name: 'customer-forms'
      };

      const history = {
        text: this.$t('global.pages.history'),
        name: 'customer-history'
      };

      const loyaltyPoints = {
        text: this.$t('global.pages.loyalty_points'),
        name: 'customer-loyalty-points'
      };

      const ipl = {
        text: this.$t('global.pages.ipl'),
        name: 'customer-ipl'
      };

      const prepaidCards = {
        text: this.$t('global.pages.prepaid_cards'),
        name: 'customer-prepaid-cards'
      };

      const feedbackEnabled = {
        text: this.$t('global.pages.feedback'),
        name: 'customer-feedback'
      };

      if (this.hasFeatureFlag('module-register')) {
        links.push(invoices);
      }

      if (this.hasFeatureFlag('module-appointments')) {
        links.push(appointments);
      }

      if (this.hasFeatureFlag('appointment-reports')) {
        links.push(reports);
      }

      if (this.hasFeatureFlag('customer-forms') && this.hasFillableForms) {
        links.push(forms);
      }

      if (this.hasFeatureFlag('module-register')) {
        links.push(history);
      }

      if (
        this.hasFeatureFlag('module-register') &&
        this.hasFeatureFlag('customer-loyalty-points')
      ) {
        links.push(loyaltyPoints);
      }

      if (this.hasFeatureFlag('customer-ipl')) {
        links.push(ipl);
      }

      if (this.hasFeatureFlag('module-register')) {
        links.push(prepaidCards);
      }

      if (this.hasFeatureFlag('feedback-enabled')) {
        links.push(feedbackEnabled);
      }

      return links;
    }
  }
});
</script>
