<template>
  <BaseTable
    :headers="[$t('global.purpose'), $t('global.code'), '']"
    :rows="
      formattedVariables.map((variable) => ({
        id: variable,
        breakLines: true,
        cells: [
          {
            value: $t(`template_variables.${variable}`)
          },
          copiedVariable === variable
            ? $t('custom_booking_widget.copied')
            : `{{${variable}}}`,
          {
            clickable: true,
            icon: 'copy'
          }
        ]
      }))
    "
    @clickCell="copyCode"
    v-test="'template-variables'"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  variables: string[];
}>();

const formattedVariables = computed(() =>
  props.variables.map((variable: string) => variable.toLowerCase())
);

const copiedVariable = ref<string | null>(null);

const copyCode = (variable: string) => {
  navigator.clipboard.writeText(`{{${variable}}}`);
  copiedVariable.value = variable;

  setTimeout(() => {
    copiedVariable.value = null;
  }, 2000);
};
</script>
