<template>
  <BaseModal
    small
    :heading="$t('onboarding.new_onboarding.import_customers.header')"
    @close="$emit('close')"
  >
    <div :class="$style.base">
      <img :class="$style.image" src="/img/tasks/import_customers.svg" />
      <BaseHeading bold size="l" mt center>
        {{ $t('imports.customer_import_successful.title') }}
      </BaseHeading>
      <BaseText size="m" mt mb center>
        {{ $t('imports.customer_import_successful.subtitle') }}
      </BaseText>
      <BaseButton :routerLink="{ name: 'customers' }" @click="handlePush">
        {{ $t('imports.customer_import_successful.button') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import { inject } from 'vue';

const mixpanel = inject<any>('mixpanel');
const emit = defineEmits(['close']);

const handlePush = () => {
  mixpanel.track('Imports V2 completed - Link clicked');
  emit('close');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 250px;
  max-width: 50%;
  max-height: 250px;
}
</style>
