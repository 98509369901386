<template>
  <div :class="$style.base">
    <div :class="$style.main">
      <Card
        v-for="(item, key) in items"
        :key="key"
        v-bind="item"
        v-test="`card-${item.testId}`"
      />
    </div>
    <div>
      <BaseButton ml @click="printPage" v-test="'btn-print'">
        {{ $t('global.print') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import type { Component } from 'vue';
import { defineComponent } from 'vue';
import Card from './CashupInfoCard.vue';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

interface Item {
  heading: string;
  content: string | number;
  icon: string;
  testId: string;
  html?: boolean;
  hide?: boolean;
}

export default defineComponent({
  components: {
    Card: Card as Component
  },
  props: {
    cashup: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    items(): Item[] {
      const items = [
        {
          heading: this.cashup.location.name,
          content: this.fullAddress,
          icon: 'home',
          html: true,
          testId: 'location'
        },
        {
          heading: this.$t('cashups.cashup_number'),
          content: this.cashup.number,
          icon: 'hash',
          testId: 'number'
        },
        {
          heading: this.$t('cashups.opened_on'),
          content: `${this.filters.date(this.cashup.openedOn)} ${this.$t('global.by')}: ${this.cashup.openedBy?.fullName}`,
          icon: 'clock',
          hide: !this.cashup.openedBy,
          testId: 'opened-by'
        },
        {
          heading: this.$t('cashups.closed_on'),
          content: `${this.filters.date(this.cashup.closedOn)} ${this.$t('global.by')}: ${this.cashup.closedBy?.fullName}`,
          icon: 'clock',
          hide: !this.cashup.closedBy,
          testId: 'closed-by'
        },
        {
          heading: this.$t('cashups.starting_balance'),
          content: this.filters.currency(this.cashup.openingAmount),
          icon: 'pricetag',
          testId: 'starting-balance'
        },
        {
          heading: this.$t('cashups.note', 1),
          content: this.cashup.comment,
          icon: 'message',
          hide: !this.cashup.comment,
          testId: 'comment'
        }
      ];
      return items;
    },
    address(): string {
      return this.company.fullAddress;
    },
    fullAddress(): string {
      return this.address ? this.address.replace(/\n/g, '<br />') : '';
    }
  },
  methods: {
    printPage(): void {
      window.print();
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: space-between;
}

.main {
  display: flex;
  flex-wrap: wrap;

  > * {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    min-width: 200px;
    margin-right: $spacing;
    margin-bottom: $spacing;
  }
}
</style>
