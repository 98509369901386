<template>
  <Transition name="payment-fade" mode="out-in">
    <slot />
  </Transition>
</template>
<style scoped lang="scss">
$easing: ease-in-out;

.payment-fade-enter-active {
  transition:
    transform 0.3s $easing,
    opacity 0.3s;
  transition-delay: 0.5s;
}

.payment-fade-leave-active {
  transition:
    transform 0.5s $easing,
    opacity 0.5s;
  transform: translateX(-15%);
  opacity: 0;
}

.payment-fade-enter-from {
  transform: translateX(15%);
  opacity: 0;
}
</style>
