<template>
  <BaseModal
    :heading="$t('customers.create_filter_group')"
    small
    testId="create-filter-group"
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('customers.create_filter_group_info') }}
    </BaseText>
    <FilterLabels :filters="filters" @removeFilter="onRemoveFilter" />
    <AddFilter @setFilter="setFilter" />
    <BaseInput
      v-model="name"
      :mt="2"
      :label="$t('global.name')"
      required
      v-test="'createFilterGroupName'"
    />
    <template #footer>
      <BaseButton color="inverted" @click="routeSwitch">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="isLoading"
        @click="submit"
        v-test="'createFilterGroupSave'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import AddFilter from '@/components/customer-filters/AddFilter.vue';
import FilterLabels from '@/components/customer-filters/FilterLabels.vue';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { formatFilters } from '@/helpers/formatting';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AddFilter,
    FilterLabels
  },
  props: {
    parentRouteName: String
  },
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      isLoading: false,
      filters: [],
      customerFilterGroup: null
    };
  },
  watch: {
    customerFilterGroup: {
      handler() {
        if (this.customerFilterGroup) {
          this.filters = this.formattedFilters;
          this.name = this.customerFilterGroup.name;
        }
      },
      deep: true
    }
  },
  computed: {
    allFilters() {
      return this.$store.getters['marketing/allFilters'];
    },
    routeId() {
      return parseInt(this.$route.params.filterGroupId);
    },
    isNewsletter() {
      return this.$route.name === 'newsletter-template';
    },
    filtersForGraphql() {
      const allFilters = JSON.parse(JSON.stringify(this.filters));
      const mapped = allFilters.map((filter) => {
        const objForGraphql = {
          filterName: filter.filter_name.toUpperCase(),
          values: filter.values
        };
        return objForGraphql;
      });
      return mapped;
    },
    formattedFilters() {
      return this.customerFilterGroup
        ? formatFilters(this.customerFilterGroup.filters)
        : null;
    }
  },
  methods: {
    routeSwitch() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },
    create(input) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createCustomerFilterGroup(
              $input: CreateCustomerFilterGroupInput!
            ) {
              createCustomerFilterGroup(input: $input) {
                errors
              }
            }
          `,
          variables: {
            input
          },
          update(cache) {
            cache.evict({
              id: 'ROOT_QUERY',
              fieldName: 'customerFilterGroups'
            });
            cache.gc();
          }
        })
        .then(() => {
          this.callback();
        })
        .finally(() => {
          this.isLoading = true;
        });
    },
    edit(input) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateCustomerFilterGroup(
              $input: UpdateCustomerFilterGroupInput!
            ) {
              updateCustomerFilterGroup(input: $input) {
                customerFilterGroup {
                  name
                  id
                  filters {
                    filterName
                    values
                  }
                }
              }
            }
          `,
          variables: {
            input
          }
        })
        .then(() => {
          this.callback();
        })
        .finally(() => {
          this.isLoading = true;
        });
    },
    callback() {
      if (this.isNewsletter) {
        this.$store.commit('marketing/SET_ALL_FILTERS', this.filters);
      }
      flash(
        this.$t(
          `global.flash.filter_group_${this.routeId ? 'updated' : 'created'}`
        )
      );
      this.routeSwitch();
    },
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      const input = {
        name: this.name,
        filters: this.filtersForGraphql
      };

      if (this.routeId) {
        this.edit({ ...input, id: this.routeId });
      } else {
        this.create(input);
      }
    },
    setFilter(filter) {
      if (!this.filters.length) {
        filter.labelIndex = 0;
      } else {
        filter.labelIndex =
          this.filters[this.filters.length - 1].labelIndex + 1;
      }

      this.filters.push(filter);
    },
    onRemoveFilter(toDelete) {
      this.filters = this.filters.filter(
        (filter) => filter.labelIndex !== toDelete.labelIndex
      );
    }
  },
  created() {
    if (this.isNewsletter && this.allFilters.length) {
      this.filters = [...this.allFilters];
    }
  },
  apollo: {
    customerFilterGroup: {
      query: gql`
        query getCustomerFilterGroup($id: Int!) {
          customerFilterGroup(id: $id) {
            name
            id
            filters {
              filterName
              values
            }
          }
        }
      `,
      skip() {
        return !this.routeId;
      },
      variables() {
        return {
          id: this.routeId
        };
      }
    }
  }
});
</script>
