<template>
  <ModuleBase noBorder gray>
    <PageWrap :heading="$t('admin.nav.links.customers')">
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
      >
        <Tags v-model="tags" />
        <BaseCard
          :mb="2"
          :heading="$t('customers_settings.additional_fields.heading')"
        >
          <BaseText mb>
            {{ $t('customers_settings.additional_fields.description') }}
          </BaseText>
          <div :class="$style.customFields">
            <BaseCard v-for="(field, index) in customFields" :key="index" gray>
              <BaseInput
                :modelValue="field"
                :label="
                  $t('customers_settings.additional_fields.field_label', {
                    number: index + 1
                  })
                "
                @update:modelValue="customFields[index] = $event"
                v-test="'customers-custom-label'"
              />
            </BaseCard>
          </div>
          <div v-if="customFields.length < 10" class="mt-1">
            <BaseText
              link
              inline
              @click="customFields.push('')"
              v-test="'customers-custom-label-add'"
            >
              + {{ $t('customers_settings.additional_fields.add_field') }}
            </BaseText>
          </div>
          <BaseText v-else color="secondary">
            {{ $t('customers_settings.additional_fields.max_reached') }}
          </BaseText>
        </BaseCard>

        <BaseCard :heading="$t('integrations.ipl.name')" :mb="2">
          <BaseText mb>
            {{ $t('integrations.ipl.description') }}
          </BaseText>
          <BaseCheckbox
            v-model="formData.customers.iplEnabled"
            :label="$t('customers_settings.ipl.checkbox')"
            v-test="'customers-ipl'"
          />
        </BaseCard>
        <BaseCard :heading="$t('customers_settings.communication_heading')">
          <BaseText mb>
            {{ $t('customers_settings.communication_description') }}
          </BaseText>
          <BaseCard v-if="hasFeatureFlag('feedback-enabled')" gray mb>
            <BaseCheckbox
              v-model="formData.customers.appointmentFeedback"
              :label="$t('customers_settings.request_feedback.heading')"
              :description="
                $t('customers_settings.request_feedback.description')
              "
              v-test="'customers-feedback'"
            />
            <div
              v-show="formData.customers.appointmentFeedback"
              :class="$style.settingsCardInner"
            >
              <BaseDropdown
                v-model="formData.customers.appointmentFeedbackDelay"
                :label="
                  $t('customers_settings.request_feedback.interval.label')
                "
                :options="[
                  {
                    value: 0,
                    label: $t(
                      'customers_settings.request_feedback.interval.send_always'
                    )
                  },
                  {
                    value: 1,
                    label: `${1} ${$t('global.items.month', 1)}`
                  },
                  {
                    value: 2,
                    label: `${2} ${$t('global.items.month', 2)}`
                  },
                  {
                    value: 3,
                    label: `${3} ${$t('global.items.month', 2)}`
                  },
                  {
                    value: 6,
                    label: `${6} ${$t('global.items.month', 2)}`
                  },
                  {
                    value: 12,
                    label: `${12} ${$t('global.items.month', 2)}`
                  }
                ]"
                mt
                v-test="'customers-feedback-interval'"
              />
              <BaseText
                :routerLink="{
                  name: 'message-template',
                  params: {
                    id: 'feedback_request_email'
                  }
                }"
              >
                {{ $t('customers_settings.edit_message') }}
              </BaseText>
            </div>
          </BaseCard>
          <BaseCard
            v-if="!(hasFeatureFlag('module-marketing') || isTrial)"
            gray
            v-test="'customers-birthday-message'"
          >
            <div :class="$style.settingsCardInner">
              <BaseCheckbox
                v-model="formData.customers.birthdayMessageEnabled"
                :label="$t('customers_settings.birthday_email.heading')"
                :description="
                  $t('customers_settings.birthday_email.description')
                "
                v-test="'customers-birthdaymessage'"
              />
              <BaseText
                v-show="formData.customers.birthdayMessageEnabled"
                :routerLink="{ name: 'message-templates' }"
              >
                {{ $t('customers_settings.edit_message') }}
              </BaseText>
            </div>
          </BaseCard>
        </BaseCard>
      </div>
      <template #footer>
        <BaseButton
          :loading="isSaving"
          @click="submitForm"
          v-test="'customers-settings-submit'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </template>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useCompanyStore } from '@/stores/company';
import { ref, reactive, defineComponent } from 'vue';
import i18n from '@/i18n';
import { useLeaveConfirmation } from '@/helpers/routing';
import { useUserStore } from '@/stores/user';
import PageWrap from '@/modules/admin/PageWrap.vue';
import Tags from './Tags.vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import unleash from '@/unleash';

export default defineComponent({
  name: 'CustomersSettings',
  components: {
    ModuleBase,
    PageWrap,
    Tags
  },
  setup() {
    const mixpanel = inject<any>('mixpanel');
    const { companySettings } = useCompanyStore();
    const { isTrial } = useCompanyStore();
    const {
      appointmentFeedback,
      appointmentFeedbackDelay,
      birthdayMessageEnabled,
      iplEnabled,
      sendOnlineForms
    } = companySettings.customers;
    const formData = reactive({
      communication: {
        onlineFormSubmissions:
          companySettings.communication.onlineFormSubmissions
      },
      customers: {
        appointmentFeedback,
        appointmentFeedbackDelay,
        birthdayMessageEnabled,
        iplEnabled,
        sendOnlineForms
      }
    });

    const customFields = ref<string[]>([]);

    for (let i = 0; i < 10; i++) {
      const keyName = `custom${i + 1}Label`;
      customFields.value.push(companySettings.customers[keyName] || '');
    }

    let lastFilledFieldIndex = 0;

    for (let i = 0; i < customFields.value.length; i++) {
      if (customFields.value[i]) {
        lastFilledFieldIndex = i;
      }
    }

    customFields.value = customFields.value.slice(0, lastFilledFieldIndex + 1);

    const tags = ref([]);

    const { resetConfirmation, confirmLeave } = useLeaveConfirmation(
      reactive({
        formData,
        customFields: customFields.value
      })
    );

    watch(tags, (newValue, prevValue) => {
      // When tags change, set the leave confirmation to true,
      // but only if there is a previous value so that it doesnt trigger after fetching the tags
      if (prevValue.length) {
        confirmLeave.value = true;
      }
    });

    const isSaving = ref(false);

    const updateTags = () => {
      tags.value = tags.value.filter((tag) => tag.name);

      const { mutate } = useMutation(gql`
        mutation updateCustomerTags($input: UpdateCustomerTagsInput!) {
          updateCustomerTags(input: $input) {
            customerTags {
              id
            }
          }
        }
      `);

      return mutate({
        input: {
          customerTags: tags.value
            .filter((tag) => tag.name && !tag.salonized)
            .map((tag) => ({
              id: tag.id,
              name: tag.name
            }))
        }
      });
    };

    const submitForm = () => {
      mixpanel.track('form_submitted', { form: 'admin_customers' });
      const { updateCompany } = useCompanyStore();

      for (let i = 0; i < 10; i++) {
        formData.customers[`custom${i + 1}Label`] = customFields.value[i] || '';
      }

      isSaving.value = true;

      updateCompany({
        settings: formData
      }).then(() => {
        updateTags().then(() => {
          isSaving.value = false;
          flash(i18n.t('global.flash.settings_updated'));
          resetConfirmation();

          if (
            companySettings.customers.iplEnabled !==
            formData.customers.iplEnabled
          ) {
            const { getUser } = useUserStore();
            getUser();
          }
        });
      });
    };

    const { hasFeatureFlag } = useUserStore();

    return {
      formData,
      submitForm,
      isSaving,
      customFields,
      hasFeatureFlag,
      isTrial,
      tags,
      unleash
    };
  }
});
</script>

<style lang="scss" module>
.customFields {
  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing;

    & > * {
      width: calc(50% - #{$spacing * 0.5});
    }
  }

  .base.smallScreen & {
    & > * {
      margin-bottom: $spacing;
    }
  }
}

.customFieldDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $input-height;
  width: $input-height;
  cursor: pointer;
}

.settingsCardInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: $spacing;
}
</style>
