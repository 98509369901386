<template>
  <div>
    <div :class="$style.signedBy">
      <BaseText v-if="fullName" :mr="0.25" bold>{{ fullName }} -</BaseText>
      <BaseText v-if="signedDate">{{ filters.dateTime(signedDate) }}</BaseText>
    </div>
    <div v-if="!image">
      <VueSignaturePad
        ref="signaturePad"
        :class="$style.signature"
        height="150px"
        :options="{ onEnd }"
      />
      <div v-if="showError" :class="$style.error">
        {{ $t('forms.signature_empty') }}
      </div>
      <div :class="$style.bottom">
        <div>
          <BaseButton mr color="inverted" @click="clear">{{
            $t('global.actions.clear')
          }}</BaseButton>
        </div>
      </div>
    </div>
    <img v-else :src="image" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';

import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String
    },
    image: {
      type: String,
      required: false
    },
    createdAt: {
      type: String,
      required: false
    },
    submittedAt: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const customer = inject<any>('customer');

    return {
      filters,
      customer
    };
  },
  data() {
    return {
      showError: false
    };
  },
  computed: {
    fullName() {
      return this.customer
        ? `${this.customer.firstName} ${this.customer.lastName}`
        : null;
    },
    signedDate() {
      const date = this.submittedAt ? this.submittedAt : this.createdAt;
      return date ? date : dayjs().format();
    }
  },
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.$emit('update:modelValue', []);
    },
    onEnd() {
      const { isEmpty, data } =
        this.$refs.signaturePad.saveSignature('image/svg+xml');
      if (isEmpty) {
        this.showError = true;
      } else {
        this.$emit('update:modelValue', data);
      }
    }
  }
});
</script>

<style lang="scss" module>
.signature {
  border-bottom: solid black 1px;
  margin-bottom: $spacing;
}

.signedBy {
  display: flex;
  align-items: baseline;
}

.error {
  color: $color-error;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
