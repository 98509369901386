import dayjs from '@/dayjs';
import { defineStore } from 'pinia';
import { UserNotificationScope } from '@/types';

interface State {
  appLoadedAt: string;
  version: number | null;
  versionMismatch: boolean;
  versionMismatchMessage: boolean;
  versionMismatchReload: boolean;
  versionMismatchScope: UserNotificationScope;
}

export const useVersionMismatchStore = defineStore('versionMismatch', {
  state: (): State => ({
    appLoadedAt: dayjs().format(),
    version: null,
    versionMismatch: false,
    versionMismatchMessage: false,
    versionMismatchReload: false,
    versionMismatchScope: UserNotificationScope.Base
  }),
  actions: {
    checkVersionMismatch(versionString: string) {
      if (!versionString || versionString === 'unknown') {
        return;
      }

      const version = parseInt(versionString);

      if (!this.version) {
        this.version = version;
      } else if (!this.versionMismatch && version > this.version) {
        this.versionMismatch = true;
      }

      if (this.versionMismatch && dayjs().diff(this.appLoadedAt, 'hour') >= 8) {
        this.versionMismatchReload = true;
      }

      if (
        this.versionMismatch &&
        dayjs().diff(this.appLoadedAt, 'hour') >= 48
      ) {
        this.versionMismatchMessage = true;
      }
    },
    triggerReload(scope?: UserNotificationScope) {
      this.versionMismatch = true;
      this.versionMismatchReload = true;
      this.versionMismatchMessage = true;

      if (scope) {
        this.versionMismatchScope = scope;
      }
    }
  }
});
