<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mobileMenuActive]: showMobileMenu,
        [$style.isTreatwell]: isTreatwellUser
      }
    ]"
    v-test="'the-header'"
  >
    <div
      v-if="$screen === 's'"
      :class="$style.logoMobile"
      @click="toggleMenu('logo')"
      v-test="'btn-mobile-nav'"
    >
      <div :class="$style.logoMobileInner">
        <div :class="$style.logo">
          <BaseLogo />
        </div>
      </div>
    </div>
    <div :class="$style.inner">
      <div :class="[$style.section, { [$style.mobileNav]: $screen === 's' }]">
        <div :class="$style.title">
          <div id="header-teleport" :class="$style.teleport" />
          <div :class="$style.titleText">
            {{ title }}
          </div>
          <div
            v-if="showCalendarNav"
            ref="calendarNavBtn"
            :class="$style.item"
            @click="onNavClick('calendar', $event)"
            v-test="'calendar-nav-settings-btn'"
          >
            <BaseIcon
              name="settings"
              :tooltip="
                activeNav === 'calendar'
                  ? null
                  : {
                      position: 'bottom',
                      text: $t('calendar.nav.tooltip')
                    }
              "
            />
          </div>
          <div
            v-if="showCalendarOptionsIcon"
            ref="calendarOptions"
            :class="[$style.item, $style.navIcon]"
            @click="calendarOptionsActive = !calendarOptionsActive"
            v-test="'calendar-options-icon'"
          >
            <BaseIcon
              name="options"
              :color="calendarDynamicPricingMode ? 'primary' : 'default'"
            />
          </div>
        </div>
        <div v-if="$screen === 's'" :class="$style.mobileNavRight">
          <div :class="$style.mobileNavItem">
            <MobileContext />
          </div>
          <div :class="$style.mobileNavItem">
            <Search />
          </div>
          <Analyze
            v-if="user && (user.salonizedAdmin || user.treatwellAdmin)"
            :class="$style.mobileNavItem"
            @click="user.impersonated ? onNavClick('analyze', $event) : null"
          />
          <div
            v-if="!!whatsNewItem"
            ref="whatsNewButton"
            :class="$style.mobileNavItem"
            @click="onNavClick('whats-new', $event)"
          >
            <WhatsNewButton :isClicked="whatsNewItem.clicked" />
          </div>
          <TrialTasks :class="$style.trialTasksMobile" />
          <div :class="$style.mobileNavItem" @click="toggleMenu('burger')">
            <BaseIcon
              :name="showMobileMenu ? 'close' : 'menu'"
              size="l"
              :color="showMobileMenu ? 'inverted' : undefined"
            />
          </div>
        </div>
      </div>
      <div
        v-if="$screen !== 's'"
        v-show="!previewLayoutActive"
        :class="$style.section"
      >
        <Search />
        <LocationSelect v-if="multiLocation" :ml="0.5" />
        <FranchiseSelect v-if="hasFeatureFlag('franchise-scope')" :ml="0.5" />
        <Analyze
          v-if="user && (user.salonizedAdmin || user.treatwellAdmin)"
          :class="$style.item"
        />
        <BaseIcon
          v-if="hasFeatureFlag('trade-show-demo-account')"
          clickable
          name="play-circle"
          @click="showDemoOnboarding = true"
          v-test="'onboardingIcon'"
        />
        <TrialTasks :ml="0.5" />
        <div
          v-if="!!whatsNewItem"
          ref="whatsNewButton"
          :class="$style.item"
          @click="onNavClick('whats-new', $event)"
        >
          <WhatsNewButton :isClicked="whatsNewItem.clicked" />
        </div>
        <div
          ref="user"
          :class="$style.avatar"
          @click="onNavClick('user', $event)"
          v-test="'header-user-avatar'"
        >
          <BaseAvatar :name="user.fullName" size="l" />
        </div>
      </div>
      <CalendarSettingsNav
        v-show="showCalendarNav && activeNav === 'calendar'"
        :keepVisibleOn="$refs.calendarNavBtn"
        :positionOn="$refs.calendarNavBtn"
        @close="activeNav = ''"
        v-test="'calendar-settings-nav'"
      />
      <CalendarOptionsNav
        v-if="showCalendarOptionsIcon"
        v-show="calendarOptionsActive"
        :keepVisibleOn="$refs.calendarOptions"
        :positionOn="$refs.calendarOptions"
        @close="calendarOptionsActive = false"
        v-test="'calendar-options-nav'"
      />
      <BasePopoverNav
        v-show="activeNav === 'user'"
        :style="{
          top: '60px',
          right: '17px'
        }"
        arrow="topRight"
        :items="userItems"
        :keepVisibleOn="$refs.user || null"
        @close="activeNav = ''"
        @select="onSubNavItemSelect"
        v-test="'header-user-avatar-popover'"
      >
        <BaseText bold>
          {{ user.fullName }}
        </BaseText>
        <BaseText size="s" :style="{ marginTop: '4px' }">
          {{ user.email }}
        </BaseText>
      </BasePopoverNav>

      <BasePopoverNav
        v-if="!!whatsNewItem && activeNav === 'whats-new'"
        arrow="topRight"
        :keepVisibleOn="$refs.whatsNewButton"
        :style="{
          top: '60px',
          right: $screen === 's' ? '52px' : '58px'
        }"
        @close="activeNav = ''"
        v-test="'whats-new-nav'"
      />
    </div>
    <DemoOnboarding
      v-if="showDemoOnboarding"
      @close="showDemoOnboarding = false"
    />
  </div>
</template>

<script lang="ts">
import Search from './search/index.vue';
import Analyze from './Analyze.vue';
import FranchiseSelect from './FranchiseSelect.vue';
import CalendarSettingsNav from './navs/CalendarSettingsNav.vue';
import CalendarOptionsNav from './navs/CalendarOptionsNav.vue';
import LocationSelect from './LocationSelect.vue';
import WhatsNewButton from './whats-new/Button.vue';
import TrialTasks from './trial-tasks/index.vue';
import MobileContext from './MobileContext.vue';

import { useLocationsStore } from '@/stores/locations';
import { mapState, storeToRefs } from 'pinia';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useWhatsNew } from './whats-new';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { logout } from '@/helpers/session';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';

import { defineComponent } from 'vue';
import DemoOnboarding from './DemoOnboarding.vue';
import unleash from '@/unleash';
import { useAdyenStore } from '@/stores/adyen';

export default defineComponent({
  name: 'TheHeader',
  components: {
    Search,
    Analyze,
    FranchiseSelect,
    CalendarSettingsNav,
    CalendarOptionsNav,
    LocationSelect,
    WhatsNewButton,
    DemoOnboarding,
    TrialTasks,
    MobileContext
  },
  setup() {
    const mixpanel = inject<any>('mixpanel');
    const pageLayoutStore = usePageLayoutStore();
    const { currentItem } = useWhatsNew();
    const { isInsideTestBucket, isTrial, isTreatwellUser } = useCompanyStore();
    const { previewLayoutActive } = storeToRefs(useCalendarPreviewStore());
    const { startAdyenVerificationSubscription } = useAdyenStore();

    startAdyenVerificationSubscription();

    return {
      pageLayoutStore,
      whatsNewItem: currentItem,
      isInsideTestBucket,
      isTrial,
      previewLayoutActive,
      unleash,
      isTreatwellUser,
      mixpanel
    };
  },
  data() {
    return {
      activeNav: '',
      calendarOptionsActive: false,
      isSearchActive: false,
      showDemoOnboarding: false
    };
  },
  watch: {
    $screen() {
      if (this.showMobileMenu && this.$screen !== 's') {
        this.pageLayoutStore.showMobileMenu = false;
      }
    },
    $route() {
      this.activeNav = '';
    }
  },
  computed: {
    ...mapState(useCompanyStore, [
      'company',
      'companySettings',
      'multiLocation'
    ]),
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useLocationsStore, ['location']),
    ...mapState(usePageLayoutStore, ['showMobileMenu']),
    ...mapState(useCalendarFiltersStore, {
      calendarDynamicPricingMode: 'dynamicPricingMode'
    }),
    userItems() {
      const { isTreatwellUser } = useCompanyStore();

      const items = [
        {
          name: 'logout',
          icon: 'logout',
          label: this.$t('nav.logout')
        }
      ];

      const account = {
        name: 'account',
        icon: 'person',
        label: this.$t('nav.edit_profile')
      };

      const billing = {
        name: 'billing',
        icon: 'pricetag',
        label: this.$t('nav.billing')
      };

      const referrals = {
        name: 'referrals',
        icon: 'gift',
        label: this.$t('nav.invite_colleagues')
      };

      if (this.hasFeatureFlag('module-billing') && !isTreatwellUser) {
        items.unshift(billing);
      }

      if (this.hasFeatureFlag('module-admin') && !isTreatwellUser) {
        items.unshift(referrals);
      }

      items.unshift(account);

      return items;
    },
    showCalendarNav() {
      return (
        this.$screen !== 's' &&
        !!this.$route.matched.find((route) => route.name === 'calendar')
      );
    },
    dynamicPricingEnabled() {
      return this.companySettings.bookings?.dynamicPricingEnabled || false;
    },
    showCalendarOptionsIcon() {
      return (
        this.showCalendarNav &&
        this.dynamicPricingEnabled &&
        !this.previewLayoutActive
      );
    },
    title() {
      let title;
      const baseRoute = this.$route.matched.find(
        (route) => route.meta.moduleName
      );
      const routeName = baseRoute?.meta.moduleName || '';
      switch (routeName) {
        case 'calendar':
          title = this.$t('nav.calendar');
          break;
        case 'customers':
          title = this.$t('nav.customers');
          break;
        case 'register':
          title = this.$t('nav.register');
          break;
        case 'products':
          title = this.$t('nav.products');
          break;
        case 'messages':
          title = this.$t('nav.messages');
          break;
        case 'feedback':
          title = this.$t('nav.feedback');
          break;
        case 'reports':
          title = this.$t('nav.reports');
          break;
        case 'marketing':
          title = this.$t('nav.marketing');
          break;
        case 'admin':
          title = this.$t('nav.settings');
          break;
        case 'integrations':
          title = this.$t('nav.integrations');
          break;
        case 'new-features':
          title = this.$t('nav.new_features');
          break;
        case 'treatwell':
          title = this.$t('nav.treatwell');
          break;
        case 'treatwell-integration':
          title = this.$t('nav.treatwell-integration');
          break;
        case 'support':
          title = this.$t('nav.support_hub');
          break;
        case 'treatwell-landing':
          title = this.$t('nav.treatwell_landing');
          break;
      }

      if (this.showMobileMenu) {
        title = 'Menu';
      }
      return title;
    }
  },
  methods: {
    toggleMenu(context: string) {
      this.pageLayoutStore.showMobileMenu = !this.showMobileMenu;
      this.mixpanel.track('mobile_nav_toggled', {
        context,
        open: this.showMobileMenu
      });
    },
    onNavClick(name, e) {
      e.stopPropagation();
      this.activeNav = this.activeNav === name ? '' : name;

      if (name === 'whats-new') {
        this.whatsNewItem.clicked = true;
      }
    },
    onSubNavItemSelect(item) {
      switch (item.name) {
        case 'account':
          if (this.$route.name !== 'account') {
            this.$router.push({ name: 'account' });
          }
          break;
        case 'billing':
          if (this.$route.name !== 'admin-billing') {
            this.$router.push({ name: 'admin-billing' });
          }
          break;
        case 'referrals':
          if (this.$route.name !== 'admin-referrals') {
            if (this.$gtm) {
              this.$gtm.trackEvent({
                event: 'referral-profile-click'
              });
            }
            this.$router.push({ name: 'admin-referrals' });
          }
          break;
        case 'logout':
          logout();
          break;
      }

      this.activeNav = '';
      this.pageLayoutStore.showMobileMenu = false;
      this.activeNav = '';
    }
  },
  mounted() {
    this.pageLayoutStore.headerHeight = this.$el.offsetHeight;
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  background-color: $color-body-bg;

  &:not(.smallScreen) {
    z-index: 6;
  }

  &.smallScreen {
    position: fixed;
    width: 100%;
    padding-right: 0;
    height: $header-height;
    z-index: 6;

    &:not(.mobileMenuActive) {
      border-bottom: 1px solid $color-border;
    }
  }

  &.mobileMenuActive {
    background-color: $color-primary;
  }

  @media print {
    display: none;
  }
}

.inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height - 1px;
}

.section {
  display: flex;
  align-items: center;
  padding: 0 $spacing;
}

.item {
  @include header-item;
}
.navIcon {
  margin-left: 0;
}

.title {
  display: flex;
  align-items: center;
  font-size: 19px;
  font-weight: bold;
  color: $color-text;
  height: 100%;

  .base.smallScreen & {
    font-size: 16px;
    padding-left: $spacing;
  }

  .base.mobileMenuActive & {
    color: white;
  }
}

.titleText {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.avatar {
  margin-left: $spacing;
  cursor: pointer;
}

.searchIcon {
  &:global(.v-enter-active) {
    transition:
      transform 0.1s $easeOutBack 0.1s,
      opacity 0.1s linear 0.1s;
  }
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
    transform: scale(0.5);
  }
}

.mobileNav {
  position: relative;
  z-index: 2011;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  height: 50px;
  padding-left: 50px;
}

.mobileNavRight {
  display: flex;
  height: 100%;
}

.mobileNavItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 50px;
}

.trialTasksMobile {
  margin-right: $spacing * 0.5;
}

.logo {
  width: 24px;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.logoMobile {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: $color-primary;
  cursor: pointer;
  // z-index must be higher than the one in nav-main for the mobile transition
  z-index: 2211;

  .base.isTreatwell & {
    background-color: $treatwell-primary;
  }
}

.logoMobileInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.teleport {
  &:not(:empty) {
    & + .titleText {
      display: none;
    }
  }
}
</style>
