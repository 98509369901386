<template>
  <form
    novalidate
    :class="[
      $style.base,
      {
        [$style.fullHeight]: fullHeight
      }
    ]"
    @submit.prevent="submit"
  >
    <div :class="$style.content">
      <slot />
    </div>
    <!-- eslint-disable-next-line vue/no-restricted-html-elements -->
    <button :class="$style.button" />
  </form>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import eventBus from '@/event-bus';
import { scrollPage, scrollToElement } from '@/helpers/scroll';
import { nextTick, onMounted, onUnmounted } from 'vue';
import useVuelidate from '@vuelidate/core';

const props = defineProps<{
  disableScroll?: boolean;
  fullHeight?: boolean;
  scrollContainer?: HTMLElement;
}>();

const emit = defineEmits(['submit', 'validationError']);

const v$ = useVuelidate();

const submit = () => {
  v$.value.$reset();

  nextTick(() => {
    v$.value.$touch();

    if (!v$.value.$invalid) {
      emit('submit');
    } else {
      emit('validationError');
    }
  });
};

let formErrorTriggered = false;

onMounted(() => {
  eventBus.$on('form-error', scroll);
});

onUnmounted(() => {
  eventBus.$off('form-error', scroll);
});

const scroll = (element: HTMLElement) => {
  nextTick(() => {
    // The event is fired by multiple form elements at the same time,
    // but we only want to trigger the scroll once

    // Setting a variable for this instead of using eventBus.$once,
    // since we want to be able to reset it and trigger it again when the user submits the form again

    if (formErrorTriggered || !element || props.disableScroll) {
      return;
    }

    if (props.scrollContainer) {
      scrollToElement({ element, scrollContainer: props.scrollContainer });
    } else {
      scrollPage({ element });
    }

    formErrorTriggered = true;
    setTimeout(() => {
      formErrorTriggered = false;
    }, 200);
  });
};
</script>

<style lang="scss" module>
.base {
  &.fullHeight {
    height: 100%;
  }
}

.content {
  .base.fullHeight & {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.button {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  pointer-events: none;
}
</style>
