<template>
  <div>
    <div>
      <BaseHeading border>{{
        $t('reports.employees.time_keeping')
      }}</BaseHeading>
      <BaseScroll v-if="report.data.length" :contentWidth="820">
        <BaseTableRow head>
          <BaseTableCell>
            {{ $t('reports.employees.employee') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ $t('reports.employees.hours') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ $t('reports.employees.booked_hours') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ $t('reports.employees.worked_hours') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ $t('reports.employees.utilisation') }}
          </BaseTableCell>
        </BaseTableRow>
        <BaseTableRow
          v-for="(employee, index) in report.data"
          :key="index"
          v-test="'timeKeepingRow'"
        >
          <BaseTableCell>
            {{ employee.name }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ employee.hours }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ employee.bookedHours }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ employee.workedHours }}
          </BaseTableCell>
          <BaseTableCell right> {{ employee.utilization }}% </BaseTableCell>
        </BaseTableRow>
        <BaseTableRow v-if="report.totals" footer v-test="'totalsRow'">
          <BaseTableCell>
            {{ $t('reports.ranges.total') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.hours }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.bookedHours }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.workedHours }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.utilization }}%
          </BaseTableCell>
        </BaseTableRow>
      </BaseScroll>
    </div>
    <NoResultsFound v-if="!report.data.length" />
  </div>
</template>

<script lang="ts">
import NoResultsFound from '@/modules/reports/NoResultsFound.vue';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    NoResultsFound,
    BaseTableRow,
    BaseTableCell
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  }
});
</script>
