<template>
  <div>
    <router-view />
    <BaseTable
      v-if="prepaidCards.length"
      :headers="[
        $t('global.date'),
        $t('global.description'),
        $t('global.quantity'),
        $t('global.used'),
        $t('global.remaining'),
        $t('global.price'),
        $t('global.status')
      ]"
      :rows="
        prepaidCards.map((card) => ({
          id: card.id,
          routerLink: {
            name: 'customer-prepaid-card',
            params: {
              id: card.id
            }
          },
          cells: [
            {
              value: filters.date(card.createdAt),
              type: 'date'
            },
            card.description,
            card.quantity,
            card.used,
            card.remaining,
            filters.currency(card.price),
            {
              label: card.status.toLowerCase()
            }
          ]
        }))
      "
      v-test="'customer-cards'"
    />
    <LoadMore
      v-show="!allDataFetched && firstFetchFinished"
      @shown="fetchMore"
    />
    <BaseSpinner v-show="!firstFetchFinished" inline mt />
    <EmptyPageContent
      v-if="!loading && !prepaidCards.length"
      imageName="prepaid-cards"
      :text="{
        title: $t('customers.empty_state.prepaid_cards.title'),
        description: $t('customers.empty_state.prepaid_cards.description'),
        btnPrimary: filters.capitalize(
          $t('global.actions.create_item', {
            item: $t('global.items.prepaid_card', 1)
          })
        )
      }"
      :moreLink="{
        text: $t('prepaid_cards.empty_state.more_text'),
        url: $t('prepaid_cards.empty_state.more_url')
      }"
      @click="onCreatePrepaidClick"
    />
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { inject } from 'vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { setCustomer } from '@/modules/register/builder/actions/shared';
import { useRouter } from 'vue-router';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import { GET_PREPAID_CARDS } from './graphql';

const customer = inject<any>('customer');
const router = useRouter();

const { prepaidCards, allDataFetched, loading, firstFetchFinished, fetchMore } =
  usePagination({
    fieldName: 'prepaidCards',
    query: GET_PREPAID_CARDS,
    variables: {
      customerId: customer.value.id
    }
  });

const onCreatePrepaidClick = () => {
  const { reset: resetRegisterOrder } = useRegisterOrderStore();
  resetRegisterOrder();
  setCustomer(customer.value);
  router.push({ name: 'register-builder-prepaidcard' });
};
</script>
