<template>
  <div :class="$style.base">
    <BaseDropdown
      v-model="calendar.calendarId"
      :placeholder="$t('integrations.google_calendar.settings.select_calendar')"
      :options="[
        {
          value: null,
          label: '-- ' + $t('global.disabled') + ' --'
        },
        ...filteredList
      ]"
      v-test="'gooogle-sync-employee-lists'"
    />
    <div :class="$style.employee">
      <BaseAvatar
        v-if="employees.find((employee) => employee.id === calendar.resourceId)"
        :resource="
          employees.find((employee) => employee.id === calendar.resourceId)
        "
      />
      <BaseText bold :ml="0.5">
        {{
          employees.find((employee) => employee.id === calendar.resourceId)
            ? employees.find((employee) => employee.id === calendar.resourceId)
                ?.name
            : $t(
                'integrations.google_calendar.settings.absences_for_the_entire_company'
              )
        }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SalonizedGoogleCalendarData, IncomingCalendar } from '../types';
import { useResourcesStore } from '@/stores/resources';

const props = defineProps<{
  calendar: IncomingCalendar;
}>();

const { employees } = useResourcesStore();

const { incomingCalendars, writableLists } =
  inject<SalonizedGoogleCalendarData>('salonized-google-calendar')!;

const filteredList = computed(() =>
  writableLists.value.filter(
    (list) =>
      list.value === props.calendar.calendarId ||
      !incomingCalendars.value.find(
        (calendar) => calendar.calendarId === list.value
      )
  )
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  gap: $spacing;
  margin-bottom: $spacing;

  & > * {
    width: calc(50% - #{$spacing / 2});
  }
}

.employee {
  display: flex;
  align-items: center;
}
</style>
