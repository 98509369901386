import dayjs from '@/dayjs';

type ScrollToOptions = {
  behavior: string;
  left?: number;
  top?: number;
};

export const animateTo = ({
  date = null,
  resourceId = null,
  delay = 0,
  horizontal = true,
  vertical = true
}: {
  date?: string | null;
  resourceId?: string | number | null;
  delay?: number;
  horizontal?: boolean;
  vertical?: boolean;
}) => {
  // Animate the calendar so that the date/time that are passed become visible
  // This uses DOM elements which Fullcalendar renders as selectors, which is not ideal. Might break when upgrading Fullcalendar

  setTimeout(() => {
    if ((!horizontal && !vertical) || (!date && !resourceId)) {
      // Either date or resourceId is required, and either horizontal or vertical is required
      return;
    }

    const dayjsDate = date ? dayjs(date).tz() : null;
    if (date && !dayjsDate?.isValid()) {
      return;
    }

    const scrollContainerEls = document.querySelectorAll(
      '#calendar-container .fc-scrollgrid-section-body .fc-scroller.fc-scroller-liquid-absolute'
    );

    if (scrollContainerEls?.length > 1) {
      const scrollContainer = scrollContainerEls[1];

      const scrollToOptions: ScrollToOptions = {
        behavior: 'smooth'
      };

      if (vertical) {
        let selector;
        if (dayjsDate) {
          selector = `#calendar-container .fc-timegrid-slot-lane[data-time="${dayjsDate.subtract(30, 'minute').format('HH:mm')}:00"]`;
        } else if (resourceId) {
          selector = `#calendar-container .fc-col-header-cell[data-resource-id="${resourceId}"]`;
        }

        if (selector) {
          const rowEl = document.querySelector(selector);
          if (rowEl) {
            const containerTop = scrollContainer.getBoundingClientRect().top;
            const containerScroll = scrollContainer.scrollTop;
            const rowTop = rowEl.getBoundingClientRect().top;
            scrollToOptions.top = rowTop - (containerTop - containerScroll);
          }
        }
      }

      if (horizontal) {
        const columnEls = document.querySelectorAll(
          '#calendar-container .fc-col-header-cell'
        );
        const columnWidth = columnEls[0].clientWidth;
        const columnIndex = Array.from(columnEls).findIndex((el) => {
          if (dayjsDate) {
            return (
              el.getAttribute('data-date') === dayjsDate.format('YYYY-MM-DD')
            );
          } else if (resourceId) {
            return (
              el.getAttribute('data-resource-id') === resourceId.toString()
            );
          } else {
            return false;
          }
        });

        if (columnIndex >= 0) {
          scrollToOptions.left = columnIndex * columnWidth;
        }
      }

      if (
        scrollToOptions.left !== undefined ||
        scrollToOptions.top !== undefined
      ) {
        scrollContainer.scrollTo(scrollToOptions);
      }
    }
  }, delay);
};
