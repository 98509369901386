<template>
  <div
    :class="[
      $style.base,
      {
        [$style.editable]: editable
      }
    ]"
    v-test="'register-transaction'"
  >
    <div :class="$style.heading">
      <div :class="$style.name">
        <BaseHeading v-if="type" size="s">
          {{ $t(`global.payments.${type.toLowerCase()}`) }}
        </BaseHeading>
        <BaseText v-if="isGiftcardOrPrepaidCard" inline>{{
          transaction.name
        }}</BaseText>
        <BaseText v-else-if="cashupEnabled" size="s">
          {{ filters.dateTime(transaction.transactionAt || dayjs()) }}
        </BaseText>
      </div>
      <BaseText v-test="'register-transaction-amount'">
        {{ filters.currency(transaction.amount || 0) }}
      </BaseText>
      <BaseMore
        v-if="deletable && editable"
        :options="['delete']"
        size="s"
        @select="
          cashupEnabled
            ? removeTransaction(props.transaction)
            : removeAllTransactions(props.transaction)
        "
        v-test="'register-transaction-options'"
      />
      <div
        v-else-if="editable"
        :class="$style.undeletable"
        v-test="'register-transaction-disabled'"
      >
        <BaseIcon
          name="slash"
          :tooltip="{
            text: $t(
              `register.${cashupEnabled && transaction.type !== 'POS' && transaction.type !== 'ONLINE' ? 'payment_undeletable_tooltip' : 'transaction_undeletable'}`
            ),
            position: 'left',
            width: 100
          }"
          size="s"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import type { OrderTransaction } from '@/modules/register/stores/order';
import { useRegisterStore } from '@/modules/register/stores/register';
import { computed } from 'vue';
import dayjs from '@/dayjs';

const { removeTransaction, removeAllTransactions } = useRegisterOrderStore();
const { cashupEnabled } = useRegisterStore();

const props = defineProps<{
  editable?: boolean;
  transaction: OrderTransaction;
}>();

const type = computed(() => props.transaction.type);
const isGiftcardOrPrepaidCard = computed(
  () => type.value === 'GIFTCARD' || type.value === 'PREPAID_CARD'
);

const deletable = computed(
  () => type.value !== 'POS' && (!cashupEnabled || props.transaction.deletable)
);
</script>

<style lang="scss" module>
.heading {
  display: flex;
  align-items: center;
  padding-left: $spacing;
  padding-right: $spacing;
  transition: background-color 1s $easeOutExpo;

  .base.editable & {
    padding-right: 0;
  }
}

.name {
  display: flex;
  align-items: center;
  flex: 1;
  padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 0;
  gap: $spacing;
}

.undeletable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
</style>
