<template>
  <div :class="$style.base">
    <div
      :class="[
        $style.row,
        {
          [$style.isHeader]: isHeader,
          [$style.isFooter]: isFooter,
          [$style.isExpanded]: isExpanded,
          [$style.isGroupParent]: isGroupParent,
          [$style.isGroupChild]: isGroupChild,
          [$style.hasColor]: !!color,
          [$style.gray]: gray,
          [$style.disabled]: disabled
        }
      ]"
      :style="style"
      @click="handleClick"
    >
      <div :class="$style.section">
        <BaseIcon v-if="icon" :name="icon" :color="iconColor" />
        <BaseCheckbox
          v-if="!isFooter"
          :modelValue="isChecked"
          @update:modelValue="emitValue($event)"
        />
        <BaseAvatar
          v-if="item?.hasAvatar || item?.picture"
          size="l"
          :name="label"
          :color="color"
          :picture="item?.picture"
        />
        <span :style="labelStyle" :class="$style.label">{{ label }}</span>
      </div>
      <div v-if="isExpandable" :class="[$style.section, $style.sectionRight]">
        <div>{{ amountSelected }} / {{ amountTotal }}</div>
        <div v-test="'_base-grouped-checkboxes-icon-dropdown'">
          <BaseIcon :name="isExpanded ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>
    </div>
    <div v-show="isExpanded">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import type { IconName } from '../base-icon/types';
import { lightColor } from '@/helpers/formatting';

import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },
    isFooter: {
      type: Boolean,
      default: false
    },
    isGroupParent: {
      type: Boolean,
      default: false
    },
    isGroupChild: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    selected: {
      type: Array
    },
    items: {
      type: Array
    },
    item: {
      type: Object
    },
    gray: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String as () => IconName
    },
    iconColor: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle'],
  data() {
    return {
      isExpanded: this.expanded
    };
  },
  computed: {
    isExpandable() {
      return this.items?.length;
    },
    lightColor() {
      return lightColor(this.color);
    },
    amountTotal() {
      return this.items?.length || null;
    },
    amountSelected() {
      if (!this.items) {
        return null;
      }

      const itemIds = this.items.map((item) => item.id);
      let amount = 0;

      itemIds.forEach((id) => {
        if (this.selected?.includes(id)) {
          amount++;
        }
      });

      return amount;
    },
    isChecked() {
      return (
        this.items?.length === this.amountSelected ||
        this.selected?.includes(this.item?.id)
      );
    },
    style() {
      if (!this.color) {
        return;
      } else if (this.isGroupParent) {
        return {
          'background-color': this.lightColor,
          border: `1px solid ${this.lightColor}`,
          'border-left': `3px solid ${this.color}`
        };
      } else if (this.isGroupChild) {
        return {
          border: `1px solid ${this.lightColor}`
        };
      } else {
        return {
          'border-left': `3px solid ${this.color}`
        };
      }
    },
    labelStyle() {
      if (!this.color || !this.item?.hasAvatar) {
        return;
      } else {
        return {
          'font-weight': 'bold',
          'background-color': this.lightColor,
          padding: '8px',
          'border-radius': '8px'
        };
      }
    }
  },
  methods: {
    handleClick() {
      if (this.isExpandable) {
        this.isExpanded = !this.isExpanded;
      } else if (!this.isGroupParent) {
        this.emitValue(!this.isChecked);
      } else if (this.isFooter) {
        this.$emit('toggle');
      }
    },
    emitValue(value) {
      if (this.disabled) {
        return;
      }

      this.$emit('toggle', value);
    }
  }
});
</script>

<style lang="scss" module>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  cursor: pointer;
  overflow: hidden;

  &.isHeader {
    background-color: white;
    border: 1px solid $color-border;
    border-radius: $radius;

    &.isExpanded {
      border-radius: $radius $radius 0 0;
    }

    &.gray {
      background-color: $grey-light;
    }
  }

  &.isFooter {
    background-color: white;
    border: 1px solid $color-border;
    border-radius: 0 0 $radius $radius;
    font-weight: bold;

    &.gray {
      background-color: $grey-light;
    }
  }

  &:not(.isHeader) {
    background-color: white;
  }

  &:not(.isHeader):not(.isFooter):not(.isGroupParent) {
    padding-left: $spacing * 1.5;
    border-left: 1px solid $color-border;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
  }

  &.isGroupParent {
    margin-top: 2px;
    padding-left: $spacing * 1.5;
  }

  &.isGroupChild {
    border-top: 0 !important;
  }

  &.disabled {
    pointer-events: none;
  }
}

.section {
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: $spacing * 0.5;
  }

  .row.disabled & {
    opacity: 0.4;
  }
}

.sectionRight {
  flex-shrink: 0;
}

.label {
  overflow-wrap: anywhere;
}
</style>
