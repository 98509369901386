<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <div :class="$style.newsletter" v-test="'marketingMailPreviewContainer'">
        <BaseImage
          v-show="hasLogo"
          v-if="company.logo"
          :cloudinaryId="company.logo.large"
          :width="100"
          mt
          mb
        />
        <div v-show="hasImage" :class="$style.placeholderImage">
          <BaseImage
            v-if="imageUrl"
            :cloudinaryId="imageUrl"
            :height="280"
            cover
          />
        </div>
        <div :class="$style.content">
          <MessageBody :mt="2" />

          <DiscountField v-show="showDiscount" mb />

          <EmailButton v-show="hasButton" />
        </div>
        <Footer :company="company" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DiscountField from '@/modules/marketing/email/builder/preview/DiscountField.vue';
import MessageBody from '@/modules/marketing/email/builder/preview/Message.vue';
import EmailButton from '@/modules/marketing/email/builder/preview/EmailButton.vue';
import Footer from '@/modules/marketing/email/builder/preview/Footer.vue';
import { useCompanyStore } from '@/stores/company';
import { useStore } from 'vuex';

const props = defineProps({
  emailTemplate: {
    type: Object,
    required: true
  }
});

const { company } = useCompanyStore();
const store = useStore();
const { hasDiscount, hasLogo, hasButton, hasImage, imageUrl } = {
  ...props.emailTemplate
};
const showDiscount = hasDiscount;

store.commit('marketing/SET_ATTRS', props.emailTemplate);
</script>

<style lang="scss" module>
.base {
  background: $grey-light;
  height: calc(100vh - 100px);
  overflow-y: auto;

  &.isSmall {
    overflow: unset;
  }
}

.inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: $spacing;
}

.placeholderImage {
  background: $grey-light;
  height: 280px;
  width: 100%;
}

.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow;
  background: $white;
  max-width: 800px;
  width: 100%;

  .isSmall & {
    max-width: 340px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: $spacing * 2;
  max-width: 640px;
  padding: 0 $spacing;
}

.baseMessage {
  align-self: flex-start;
  margin-bottom: $spacing * 2;
}
.htmlStyle {
  a {
    color: #06c;
  }
  &.isSmall {
    img {
      width: 100%;
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
