<template>
  <div>
    <router-view />
    <div :class="$style.header">
      <BaseDropdown
        v-model="filter"
        :options="[
          {
            value: null,
            label: $t('global.all')
          },
          {
            value: 'services',
            label: filters.capitalize($t('global.items.service', 2))
          },
          {
            value: 'products',
            label: filters.capitalize($t('global.items.product', 2))
          },
          {
            value: 'giftcards',
            label: filters.capitalize($t('global.items.giftcard', 2))
          }
        ]"
        v-test="'customer-history-filter'"
      >
        {{ $t('global.actions.create_invoice') }}
      </BaseDropdown>
    </div>
    <BaseTable
      v-if="customerOrderHistoryItems.length"
      :headers="[
        $t('global.name'),
        $t('global.quantity'),
        $t('customers.last_purchase'),
        $t('global.type')
      ]"
      :rows="
        customerOrderHistoryItems.map((item) => ({
          id: item.id,
          cells: [
            item.name,
            item.quantity,
            filters.date(item.lastPurchasedAt),
            filters.capitalize($t(`global.items.${item.type}`, 2))
          ]
        }))
      "
      v-test="'customer-history'"
    />
    <BaseSpinner v-if="loading" inline />
    <EmptyPageContent
      v-if="!loading && !customerOrderHistoryItems.length"
      imageName="history"
      :text="{
        title: $t('customers.empty_state.history.title'),
        description: $t('customers.empty_state.history.description')
      }"
    />
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import gql from 'graphql-tag';
import { computed, inject, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';

const customer = inject<any>('customer');

const GET_CUSTOMER_ORDER_HISTORY_ITEMS = gql`
  query getCustomerOrderHistoryItems($filter: String, $customerId: ID!) {
    customerOrderHistoryItems(filter: $filter, customerId: $customerId) {
      id
      lastPurchasedAt
      name
      quantity
      type
    }
  }
`;

const filter = ref<string | null>(null);

const { result, loading } = useQuery(GET_CUSTOMER_ORDER_HISTORY_ITEMS, () => ({
  filter: filter.value,
  customerId: customer.value.id
}));

const customerOrderHistoryItems = computed(
  () => result.value?.customerOrderHistoryItems || []
);
</script>

<style lang="scss" module>
.header {
  display: flex;
  margin-bottom: $spacing;
}
</style>
