<template>
  <Builder v-if="!loading" v-model="toggleValue" :settings="settings">
    <template #actions>
      <TemplateActions :loading="isLoading" @save="onSave" />
    </template>
  </Builder>
</template>

<script lang="ts" setup>
import { ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCompanyStore } from '@/stores/company';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { modal, flash } from '@/helpers/ui';
import Builder from '@/modules/marketing/email/builder/index.vue';
import TemplateActions from '../../TemplateActions.vue';
import { useI18n } from 'vue-i18n';
import { emailTemplateFragment } from '@/graphql-fragments';

import { useStore } from 'vuex';
const { t } = useI18n();
const isLoading = ref(false);
const isInvalid = ref(false);
const router = useRouter();
const mixpanel = inject<any>('mixpanel');
const store = useStore();
const { updateCompany } = useCompanyStore();

const settings = {
  emailType: 'BIRTHDAY',
  title: t('marketing.email.automated_emails.birthday.title'),
  description: t('marketing.email.automated_emails.birthday.intro'),
  sentDescription: t('marketing.email.automated_emails.birthday.when'),
  receiveDescription: t(
    'marketing.email.automated_emails.birthday.receive_info'
  ),
  showToggle: true,
  allowImageUpload: false
};

const toggleValue = computed({
  get() {
    return (
      useCompanyStore().companySettings.customers?.birthdayMessageEnabled ||
      false
    );
  },
  set(value) {
    const input = {
      settings: {
        customers: {
          birthdayMessageEnabled: value
        }
      }
    };

    updateCompany(input).then(() => {
      flash(
        t(`global.flash.birthday_message_${value ? 'enabled' : 'disabled'}`)
      );
      mixpanel.track('Birthday template - Enabled', { value });
    });
  }
});

const onSave = () => {
  store.commit('marketing/SET_SUBMITTED', true);

  if (isInvalid.value) {
    return;
  }

  modal('confirmation').then(() => {
    isLoading.value = true;
    store.dispatch('marketing/updateEmailTemplate', 'BIRTHDAY').finally(() => {
      router.push({ name: 'marketing-email' });
      flash(t('global.flash.email_saved'));
      store.commit('marketing/SET_SUBMITTED', false);
      isLoading.value = false;
      mixpanel.track('Birthday template - Saved');
    });
  });
};

const { result, onResult, loading } = useQuery(
  gql`
    query getEmailTemplate($emailType: EmailTemplateEnum!) {
      emailTemplate(emailType: $emailType) {
        ...emailTemplate
      }
      emailTemplateDefault: emailTemplate(
        emailType: $emailType
        defaultValues: true
      ) {
        ...emailTemplate
      }
    }
    ${emailTemplateFragment}
  `,
  {
    emailType: 'BIRTHDAY'
  }
);

onResult(() => {
  if (result.value.emailTemplate) {
    result.value.emailTemplate.emailTemplateDefault =
      result.value.emailTemplateDefault;
    store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
  }
});
</script>
