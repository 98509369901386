<template>
  <div>
    <BaseRange v-model="selected" inputs @update:modelValue="emitValue" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    }
  },
  emits: ['selected'],
  data() {
    return {
      selected: [1, 100]
    };
  },
  methods: {
    emitValue(selected) {
      const values = {
        from: selected[0],
        to: selected[1]
      };
      const obj = {
        filter_name: this.name,
        values
      };
      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue([1, 100]);
  }
});
</script>
