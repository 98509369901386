<template>
  <BaseCard gray v-test="'feedback-reply'">
    <BaseHeading mb>
      {{ filters.capitalize($t('global.items.reply', 1)) }}
    </BaseHeading>
    <div :class="$style.content">
      <div v-html="text" />
      <div :class="$style.date">
        <BaseText color="secondary">
          {{ filters.date(message.createdAt) }}
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import filters from '@/filters';
import type { Message } from '@/types';
import { containsHTML } from '@/modules/feedback/helpers';
import { computed } from 'vue';

const props = defineProps<{
  message: Message;
}>();

const text = computed(() => {
  let text = props.message.message || '';
  if (!containsHTML(text)) {
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  return text;
});
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.content {
  display: flex;
  justify-content: space-between;
}

.date {
  flex-shrink: 0;
  margin-left: $spacing;
}
</style>
