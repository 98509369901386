/* eslint-disable no-console */
import config from '@/config';

export const devWarning = (warning: string) => {
  if (config.isDevelopment || config.isTest) {
    console.log(`Dev warning: %c${warning}`, 'color: #EB4762;');
  }
};

export const devLog = (log: string) => {
  if (config.isDevelopment || config.isTest) {
    console.log(`Dev log: %c${log}`, 'color: #03CFA5;');
  }
};
