<template>
  <div :class="$style.base">
    <BaseImage
      v-if="picture"
      :cloudinaryId="picture"
      width="40"
      height="40"
      mr
      v-test="'pictureUploaded'"
    />
    <BaseUpload
      :hasImage="!!picture"
      :preset="preset"
      @uploadResults="onUpload"
    />
    <BaseButton
      v-if="picture"
      color="inverted"
      ml
      @click="onDelete"
      v-test="'deletePicture'"
    >
      {{ $t('global.actions.delete') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    preset: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    picture: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    onUpload(file) {
      this.picture = file.public_id;
    },
    onDelete() {
      this.picture = '';
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}
</style>
