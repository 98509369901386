import { flash, modal } from '@/helpers/ui';
import { EmailStatusEnum } from '@/types';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { inject, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';

export const useAppointmentOverview = ({
  pageName,
  appointmentsCount
}: {
  pageName: string;
  appointmentsCount: ComputedRef<number>;
}) => {
  const customer = inject<any>('customer');
  const mixpanel = inject<any>('mixpanel');
  const { t } = useI18n();

  const showModal = () => {
    mixpanel.track(`Appointment Overview - ${pageName} - Button clicked`);
    if (!customer.value.email) {
      modal('warning', {
        message: t('customers.send_upcoming_appointments_confirmation.no_email')
      });
      mixpanel.track(`Appointment Overview - ${pageName} - no email`);
    } else if (appointmentsCount.value <= 0) {
      modal('warning', {
        message: t(
          'customers.send_upcoming_appointments_confirmation.no_appointments'
        )
      });
      mixpanel.track(`Appointment Overview - ${pageName} - no appointments`);
    } else {
      const subMessage = t(
        'customers.send_upcoming_appointments_confirmation.message',
        { email: customer.value.email, total: appointmentsCount.value }
      );
      modal('confirmation', { subMessage }).then(() => {
        mixpanel.track(
          `Appointment Overview - ${pageName} - Appointment overview requested`
        );
        const { mutate, onDone } = useMutation(
          gql`
            mutation sendAppointmentsOverview(
              $input: SendAppointmentsOverviewInput!
            ) {
              sendAppointmentsOverview(input: $input) {
                emailStatus
              }
            }
          `,
          {
            variables: {
              input: {
                email: customer.value.email
              }
            }
          }
        );
        mutate();
        onDone(
          ({
            data: {
              sendAppointmentsOverview: { emailStatus }
            }
          }) => {
            switch (emailStatus) {
              case EmailStatusEnum.Sent:
                mixpanel.track(
                  `Appointment Overview - ${pageName} - Appointment overview email sent`
                );
                flash(t('global.flash.email_sent'));
                break;
              case EmailStatusEnum.NotSent:
                mixpanel.track(
                  `Appointment Overview - ${pageName} - Appointment overview email not sent`
                );
                flash(t('global.flash.email_not_sent'));
                break;
              case EmailStatusEnum.AlreadyRequested:
                mixpanel.track(
                  `Appointment Overview - ${pageName} - Appointment overview already requested`
                );
                flash(t('global.flash.email_already_requested'));
                break;
            }
          }
        );
      });
    }
  };

  return {
    showModal
  };
};
