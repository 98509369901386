import gql from 'graphql-tag';

const serviceGroupFragment = gql`
  fragment serviceGroupAll on ServiceGroup {
    category {
      color
      id
      name
    }
    id
    name
    services {
      bookable
      bookableLocationIds
      buffer
      deleted
      description
      duration
      durationFinish
      durationProcessing
      durationSetup
      extraInformation
      favorite
      id
      medical
      name
      offeringEnabled
      popular
      price
      productId
      rebookEnabled
      rebookPeriod
      requirements {
        id
        name
        primary
        required
        resources {
          id
          name
          # serviceIds
          type
        }
        state
        target
        type
      }
      requiresProcessingTime
      resourceAdjustments {
        id
        price
        resourceId
      }
      rwgName
      serviceId
      serviceOffering {
        id
        offeringType
        payload {
          slots {
            dayOfWeek
            endTime
            max
            startTime
          }
        }
        serviceId
      }
      serviceVariationGroupId
      sortOrder
      twTreatments {
        id
        name
        treatwellTreatmentId
        group {
          id
          name
        }
      }
      twTreatmentIds
      variationGroupSortOrder
      variationName
      vatRateId
    }
    sortOrder
    top
  }
`;

export const GET_SERVICE_GROUPS = gql`
  query getServiceGroups {
    serviceGroups {
      ...serviceGroupAll
    }
  }
  ${serviceGroupFragment}
`;

export const DELETE_SERVICE_GROUP = gql`
  mutation deleteServiceGroup($input: DeleteServiceGroupInput!) {
    deleteServiceGroup(input: $input) {
      status
    }
  }
`;

export const CREATE_SERVICE_GROUP = gql`
  mutation createServiceGroup($input: CreateServiceGroupInput!) {
    createServiceGroup(input: $input) {
      serviceGroup {
        id
        name
      }
      errors {
        type
      }
    }
  }
`;

export const UPDATE_SERVICE_GROUP = gql`
  mutation updateServiceGroup($input: UpdateServiceGroupInput!) {
    updateServiceGroup(input: $input) {
      serviceGroup {
        id
        name
      }
      errors {
        type
      }
    }
  }
`;

export const DUPLICATE_SERVICE_GROUP = gql`
  mutation duplicateServiceGroup($input: DuplicateServiceGroupInput!) {
    duplicateServiceGroup(input: $input) {
      errors {
        type
      }
      serviceGroup {
        ...serviceGroupAll
      }
    }
  }
  ${serviceGroupFragment}
`;

export const SORT_SERVICE_VARIATION_GROUPS = gql`
  mutation SortServiceVariationGroups(
    $input: SortServiceVariationGroupsInput!
  ) {
    sortServiceVariationGroups(input: $input) {
      serviceVariationGroups {
        id
      }
    }
  }
`;
