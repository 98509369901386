import { UnleashClient } from 'unleash-proxy-client';
import config from '@/config';

const unleash = new UnleashClient({
  url: config.unleashUrl || '',
  clientKey: config.unleashKey || '',
  appName: 'vue',
  disableRefresh: true
});

export default unleash;
