<template>
  <div>
    <BaseSpinner v-show="$apollo.loading" />
    <BaseGrid v-if="feedbacks && feedbacks.length" container>
      <BaseGrid
        v-for="(item, index) in feedbacks"
        :key="index"
        :size="6"
        v-test="'feedback'"
      >
        <BaseCard :class="$style.card" :size="6">
          <BaseGrid container>
            <BaseGrid :size="12" :class="$style.flex">
              <Rating :score="item.score" mr />
              <div>
                <BaseHeading>{{ customer.fullName }}</BaseHeading>
                <BaseText size="s">{{
                  filters.date(item.submittedAt)
                }}</BaseText>
              </div>
            </BaseGrid>
            <BaseGrid :size="12">
              <BaseText mb>{{ item.comment }}</BaseText>
            </BaseGrid>
            <BaseGrid :size="12" alignRight>
              <BaseButton
                color="inverted"
                ml
                @click="onViewAppointment(item.appointmentId, ...arguments)"
                v-test="'view-appointment'"
              >
                {{ $t('global.actions.view_appointment') }}
              </BaseButton>
            </BaseGrid>
          </BaseGrid>
          <div :class="$style.icon">
            <BaseIcon
              v-if="item.state"
              size="s"
              :name="iconInfo(item.state).name"
              :tooltip="{
                position: 'left',
                text: iconInfo(item.state).text
              }"
            />
          </div>
        </BaseCard>
      </BaseGrid>
    </BaseGrid>
    <EmptyPageContent
      v-else-if="!$apollo.loading"
      imageName="customer-feedback"
      :text="{
        title: $t('customers.empty_state.feedback.title'),
        description: $t('customers.empty_state.feedback.description'),
        btnPrimary: $t('customers.empty_state.feedback.button_primary'),
        btnSecondary: $t('customers.empty_state.feedback.button_secondary')
      }"
      :moreLink="{
        text: $t('customers.empty_state.feedback.more_text'),
        url: $t('customers.empty_state.feedback.more_url')
      }"
      @click="onEmptyPageClick"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import Rating from '@/components/Rating.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import gql from 'graphql-tag';

import { defineComponent, inject } from 'vue';

export default defineComponent({
  components: {
    Rating,
    EmptyPageContent
  },
  setup() {
    const customer = inject<any>('customer');

    return {
      filters,
      customer
    };
  },
  data() {
    return {
      customerId: null
    };
  },
  apollo: {
    feedbacks: {
      query: gql`
        query getFeedbacks($customerId: ID) {
          feedbacks(customerId: $customerId) {
            state
            score
            comment
            appointmentId
            submittedAt
          }
        }
      `,
      variables() {
        return {
          customerId: this.$route.params.customerId
        };
      }
    }
  },
  methods: {
    onViewAppointment(appointmentId) {
      this.$router.push({ name: 'appointment', params: { appointmentId } });
    },
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.$router.push({ name: 'admin-customers' });
      } else if (button === 'secondary') {
        this.$router.push({ name: 'message-templates' });
      }
    },
    iconInfo(state) {
      const info = {
        name: '',
        text: ''
      };
      switch (state) {
        case 'published':
          info.name = 'globe';
          info.text = this.$t('feedback.published');
          break;
        case 'allowed':
          info.name = 'unlock';
          info.text = this.$t('feedback.allowed');
          break;
        case 'disallowed':
          info.name = 'slash';
          info.text = this.$t('feedback.disallowed');
          break;
        case 'personal':
          info.name = 'lock';
          info.text = this.$t('feedback.personal');
          break;
        case 'requested':
          info.name = 'clock';
          info.text = this.$t('feedback.requested');
          break;
      }
      return info;
    }
  }
});
</script>

<style lang="scss" module>
.flex {
  display: flex;
  align-items: center;
}

.card {
  position: relative;
}

.icon {
  position: absolute;
  top: $spacing;
  right: $spacing;
}
</style>
