<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <Passcodes v-if="showPasscodes" @close="showPasscodes = false" />
    <div :class="$style.terminals">
      <BaseCard
        v-for="terminal in terminals"
        :key="terminal.id"
        gray
        v-test="'sp-dashboard-terminal'"
      >
        <div :class="$style.terminal">
          <img
            :src="`/img/salonized-pay/onboarding/${terminal.model.toUpperCase()}.png`"
          />
          <div :class="$style.container">
            <Flex :gap="0.5" align="center">
              <IconConnected
                v-if="terminal.status === 'CONNECTED'"
                v-test="`sp-dashboard-terminals-status-${terminal.status}`"
              />
              <IconDisconnected
                v-else
                v-test="`sp-dashboard-terminals-status-${terminal.status}`"
              />
              <BaseText bold v-test="'sp-dashboard-terminal-name'">
                {{ terminal.name || terminal.model }}
              </BaseText>
            </Flex>
            <div
              v-if="multiLocation && terminal.location"
              v-test="'terminal-location'"
            >
              {{ terminal.location.internalName }}
            </div>
          </div>
          <div :class="$style.options">
            <BaseButton
              color="inverted"
              @click="showPasscodes = true"
              v-test="'sp-terminal-passcode-button'"
            >
              {{
                $t('register.salonized_pay.dashboard.terminal.show_passcode')
              }}
            </BaseButton>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
<script setup lang="ts">
import Passcodes from './Passcodes.vue';
import type { PosTerminal } from '@/types';
import IconConnected from './IconConnected.vue';
import IconDisconnected from './IconDisconnected.vue';
import { useCompanyStore } from '@/stores/company';

const showPasscodes = ref(false);
defineProps<{ terminals: PosTerminal[] }>();

const { multiLocation } = useCompanyStore();
</script>

<style lang="scss" module>
.terminals {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.terminal {
  display: flex;
  gap: $spacing;

  .base.smallScreen & {
    flex-direction: column;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 50%;
  gap: $spacing * 0.25;

  .base.smallScreen & {
    align-items: center;
  }
}

.icon {
  margin-right: $spacing * 0.2;
}

.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .smallScreen & {
    justify-content: center;
  }
}
</style>
