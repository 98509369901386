<template>
  <BaseModal
    useForm
    :heading="
      $t('global.actions.new_item', { item: $t('global.items.giftcard', 1) })
    "
    small
    testId="create-giftcard"
    :parentRoute="parentRouteName"
    @submit="submit"
  >
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <BaseAlert
        color="warning"
        :text="$t('giftcard.delete_warning')"
        mb
        v-test="'create-giftcard-warning'"
      />
      <div :class="[$style.row, $style.topRow]">
        <div :class="$style.codeInput">
          <BaseInput
            v-model="code"
            mr
            :label="$t('global.number')"
            required
            :error="
              isCodeTaken
                ? $t('global.validations.in_use', {
                    value: $t('global.number')
                  })
                : null
            "
            v-test="'create-giftcard-code'"
          />
        </div>
        <div :class="$style.generateButton">
          <BaseButton
            color="inverted"
            @click="generateCode"
            v-test="'create-giftcard-generate'"
          >
            {{ $t('global.actions.generate') }}
          </BaseButton>
        </div>
      </div>
      <div :class="[$style.row, $style.bottomRow]">
        <BaseInput
          v-model="price"
          mr
          :label="$t('global.amount_currency')"
          type="currency"
          required
          :minValue="1"
          :maxValue="10000000"
          v-test="'create-giftcard-price'"
        />
        <BaseDatePicker
          v-show="hasExpirationDate"
          v-model="expirationDate"
          onlyFutureDates
          mr
          :label="$t('giftcard.expiration_date')"
          v-test="'create-giftcard-date'"
        />
        <div :class="$style.checkbox">
          <BaseCheckbox
            v-model="hasExpirationDate"
            :label="$t('giftcard.has_expiration_date')"
            v-test="'create-giftcard-checkbox'"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <BaseButton
        submitForm
        :loading="fetchingGiftcard"
        v-test="'create-giftcard-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useQuery } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';

const router = useRouter();
const { addItem } = useRegisterOrderStore();
const { company } = useCompanyStore();

const props = defineProps<{
  parentRouteName?: string;
}>();

const emit = defineEmits(['submit']);

const price = ref(0);
const code = ref('');

const expirationDate = ref();
switch (company.locale.countryCode) {
  case 'nl':
    expirationDate.value = dayjs().add(2, 'year').format();
    break;
  case 'de':
    expirationDate.value = dayjs().add(3, 'year').format();
    break;
  default:
    expirationDate.value = dayjs().add(1, 'year').format();
}

const hasExpirationDate = ref(true);

const trimmedCode = computed(() => code.value.replace(/\W/g, ''));
const { result, loading: fetchingGiftcard } = useQuery(
  gql`
    query getGiftcard($code: String) {
      giftcard(code: $code) {
        code
      }
    }
  `,
  reactive({ code: trimmedCode }),
  { throttle: 300 }
);
const isCodeTaken = computed(() => !!result.value?.giftcard);
const { t } = useI18n();

const generateCode = (): void => {
  const randomNumber = Math.floor(
    Math.random() * (99999999 - 10000000 + 1) + 10000000
  ).toString();
  code.value = filters.giftCardCode(randomNumber);
};

const submit = () => {
  if (isCodeTaken.value) {
    return;
  }

  const giftcard = {
    code: code.value,
    totalAmount: price.value,
    expiresAt: hasExpirationDate.value ? expirationDate.value : null
  };

  if (props.parentRouteName === 'register-builder') {
    addItem({
      giftcard,
      price: price.value,
      name: `${t('global.payments.giftcard')}: ${code.value}`
    });
  } else {
    emit('submit', { giftcard });
  }

  router.push({ name: props.parentRouteName });
};
</script>

<style lang="scss" module>
.row {
  display: flex;
}

.topRow {
  margin-bottom: $spacing;
}

.codeInput {
  flex-grow: 1;
}

.generateButton {
  margin-top: 24px;
}

.bottomRow {
  .base.smallScreen & {
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
      width: 100%;
      margin-bottom: $spacing;
    }
  }

  .base:not(.smallScreen) & {
    & > * {
      width: calc(100% / 3);
    }
  }
}

.checkbox {
  .base:not(.smallScreen) & {
    margin-top: 36px;
  }
}
</style>
