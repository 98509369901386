import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { defineStore } from 'pinia';
import { SubscriptionPrice } from '@/types';
import type { Mutation, Quote, StripeSubscriptionItem } from '@/types';
import { useCompanyStore } from '@/stores/company';

const CREATE_STRIPE_CURRENT_PLAN_QUOTE_MUTATION = gql`
  mutation createStripeCurrentPlanQuote(
    $input: CreateStripeCurrentPlanQuoteInput!
  ) {
    createStripeCurrentPlanQuote(input: $input) {
      errors {
        type
      }
      difference
      quotes {
        amountSubtotal
        amountTotal
        lineItems {
          amountSubtotal
          amountTotal
          itemType
          currency
          quantity
        }
        percentageDiscount
        period
        plan
      }
    }
  }
`;

export const useCurrentPlanQuote = defineStore('currentPlanQuote', () => {
  const errors = ref<any[]>([]);
  const quote = ref<Quote>();
  const basePrice = ref<StripeSubscriptionItem>();
  const resourcesPrice = ref<StripeSubscriptionItem>();
  const boostPrice = ref<StripeSubscriptionItem>();
  const locationsPrice = ref<StripeSubscriptionItem>();
  const difference = ref<number>();

  const { isSubscribed } = useCompanyStore();

  const { mutate, loading } = useMutation<Mutation>(
    CREATE_STRIPE_CURRENT_PLAN_QUOTE_MUTATION,
    () => ({
      variables: {
        input: {}
      }
    })
  );

  const getData = () =>
    mutate().then((response) => {
      errors.value = [];
      quote.value = undefined;
      if (response?.data?.createStripeCurrentPlanQuote) {
        const {
          quotes,
          errors: responseErrors,
          difference: priceDifference
        } = response.data.createStripeCurrentPlanQuote;
        if (responseErrors?.length) {
          errors.value = responseErrors;
          return;
        }

        if (quotes) {
          quote.value = quotes;
          basePrice.value = quotes.lineItems?.find(
            (item) => item.itemType === SubscriptionPrice.Base
          );
          resourcesPrice.value = quotes.lineItems?.find(
            (item) => item.itemType === SubscriptionPrice.Resources
          );
          locationsPrice.value = quotes.lineItems?.find(
            (item) => item.itemType === SubscriptionPrice.Locations
          );
          boostPrice.value = quotes.lineItems?.find(
            (item) => item.itemType === SubscriptionPrice.Boost
          );
        }

        if (priceDifference) {
          difference.value = priceDifference;
        }
      } else {
        throw new Error('No data returned from mutation');
      }
    });

  if (isSubscribed) {
    getData();
  }

  return {
    quote,
    basePrice,
    resourcesPrice,
    boostPrice,
    locationsPrice,
    errors,
    difference,
    loading,
    getData
  };
});
