<template>
  <BaseCard :heading="$t('admin.booking.online_payments')" label="new">
    {{ $t('register.salonized_pay.dashboard.online_payments.description') }}
    <div class="mt-1">
      <div :class="$style.onlinePayment">
        <div>
          <BaseText bold>{{ $t('global.booking_widget') }}</BaseText>
          <Flex align="center" :gap="0.5" :mt="0.25">
            <IconConnected v-if="paymentEnabled" />
            <IconDisconnected v-else />
            {{ paymentEnabled ? $t('global.enabled') : $t('global.disabled') }}
          </Flex>
        </div>
        <BaseButton
          color="inverted"
          :routerLink="{
            name: 'admin-online-bookings',
            query: {
              section: 'online-payments'
            }
          }"
          v-test="'btn-online-payment-settings'"
        >
          {{
            paymentEnabled ? $t('global.settings') : $t('global.actions.enable')
          }}
        </BaseButton>
      </div>
      <div :class="$style.onlinePayment">
        <div>
          <BaseText bold>{{ $t('promote.nav.links.voucher_widget') }}</BaseText>
          <Flex align="center" :gap="0.5" :mt="0.25">
            <IconConnected v-if="hasPaymentProvider" />
            <IconDisconnected v-else />
            {{
              hasPaymentProvider ? $t('global.enabled') : $t('global.disabled')
            }}
          </Flex>
        </div>
        <BaseButton
          color="inverted"
          :routerLink="{
            name: 'admin-giftcard-widget'
          }"
        >
          {{ $t('global.settings') }}
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import IconConnected from './IconConnected.vue';
import IconDisconnected from './IconDisconnected.vue';
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';

const { companySettings } = useCompanyStore();

const { result: currentUser } = useQuery(
  gql`
    query getCurrentUser {
      currentUser {
        company {
          paymentGateway
        }
      }
    }
  `,
  null,
  {
    fetchPolicy: 'cache-first'
  }
);

const hasPaymentProvider = computed(
  () => !!currentUser.value?.currentUser.company.paymentGateway
);

const paymentEnabled = computed(
  () =>
    hasPaymentProvider.value &&
    companySettings.bookings.onlinePayment !== 'DISABLED'
);
</script>

<style lang="scss" module>
.onlinePayment {
  display: flex;
  gap: $spacing;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  border: 1px solid $color-border;

  &:first-child {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  &:not(:first-child) {
    border-top: none;
  }
}
</style>
