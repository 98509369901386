<template>
  <BasePopover
    :class="[
      $style.base,
      { [$style.noHeader]: !showHeader, [$style.note]: isNote }
    ]"
    :position="$screen === 's' ? undefined : position"
    :autoPositioning="$screen === 's'"
    closeButton
    @close="$emit('close')"
    v-test="'calendar-popover-event'"
  >
    <RejectAppointmentRequest
      v-if="showRejectionModal"
      :appointmentId="data.entryId"
      @close="showRejectionModal = false"
      @submit="showRejectionModal = false"
    />

    <div>
      <div v-if="showHeader">
        <div :class="$style.top">
          <div :class="$style.heading">
            <router-link
              v-if="data.customerName && data.customerId"
              :to="{
                name: 'customer',
                params: { customerId: data.customerId }
              }"
              v-test="'calendar-popover-event-customer'"
            >
              <BaseText bold size="l" inline>
                {{
                  !data.customerMember
                    ? data.customerName
                    : `${data.customerMember.firstName} ${data.customerMember.lastName}`
                }}
              </BaseText>
              <BaseText
                v-if="data.customerMember"
                v-test="'calendar-popover-event-customerMember'"
              >
                {{ $t('customer_details.booked_by') }}
                <span :class="$style.link">{{ data.customerName }}</span>
              </BaseText>
            </router-link>

            <BaseText
              v-if="isAppointment && !data.customerName"
              bold
              size="l"
              v-test="'calendar-popover-event-customerfallback'"
            >
              {{ $t('calendar.walk_in') }}
            </BaseText>
            <BaseText
              v-else-if="data.name"
              bold
              size="l"
              v-test="'calendar-popover-event-name'"
            >
              {{ data.name }}
            </BaseText>
          </div>
          <BaseSpinner
            v-if="isAppointment && data.customerName && $apollo.loading"
            inline
          />
          <CustomerDetails
            v-else-if="isAppointment && appointment && appointment.customer"
            :customer="appointment.customer"
            :appointmentStart="data.start"
            showAppointmentState
            v-test="'calendar-popover-event-customerDetails'"
          />
        </div>
        <BaseText
          v-if="data.start && data.end && !data.allDay"
          mb
          v-test="'calendar-popover-event-date'"
        >
          {{
            filters.capitalize(filters.date(data.start, { format: 'long' }))
          }}, {{ filters.time(data.start) }} - {{ filters.time(data.end) }}
        </BaseText>
      </div>
      <div :class="$style.list">
        <div
          v-if="!isAbsence || !resources.length"
          :class="[$style.listItem, $style.description]"
        >
          <div v-if="descriptionIcon" :class="$style.listItemLeft">
            <BaseIcon :name="descriptionIcon" />
          </div>
          <div
            :class="$style.listItemRight"
            v-test="'calendar-popover-event-description'"
          >
            <span v-if="isAppointment && !$apollo.loading">
              {{
                services.length === 1
                  ? services[0].name
                  : `${services.length} ${$t('global.items.service', 2)}`
              }}
            </span>
            <span v-else-if="data.title" v-html="data.title" />
          </div>
          <BaseLabel
            v-if="isNote && data.done"
            color="success"
            ml
            v-test="'popover-event-label'"
          >
            {{ $t('global.completed') }}
          </BaseLabel>
        </div>
        <div
          v-if="!hasSingleResource && resources.length"
          :class="[
            $style.listItem,
            {
              'mt-1': isNote
            }
          ]"
        >
          <div :class="$style.listItemLeft">
            <BaseAvatar
              v-if="resources.length === 1 && resources[0].type === 'employee'"
              :resource="resources[0]"
              v-test="'calendar-popover-avatar'"
            />
            <BaseIcon
              v-else
              :name="
                resources[0].type === 'employee'
                  ? 'people'
                  : resources[0].type === 'room'
                    ? 'pin'
                    : 'monitor'
              "
            />
          </div>
          <div
            :class="$style.listItemRight"
            v-test="'calendar-popover-event-resources'"
          >
            <template v-if="isAbsence">
              {{ data.title }}
            </template>
            <template v-else>
              {{
                resources.length === 1
                  ? resources[0].name
                  : `${resources.length} ${$t(`global.items.${resources[0].type}`, 2)}`
              }}
            </template>
          </div>
        </div>
        <div
          v-if="duration && !data.allDay"
          :class="[$style.listItem, $style.listItemDuration]"
        >
          <div :class="$style.listItemCol">
            <div :class="$style.listItemLeft">
              <BaseIcon name="clock" />
            </div>
            <div :class="$style.listItemRight">
              <span v-test="'calendar-popover-event-duration'">{{
                duration
              }}</span>
              {{ $t('global.minute_short') }}
            </div>
          </div>
          <div :class="$style.listItemCol">
            <div :class="$style.iconsWrap">
              <div :class="$style.iconsInner">
                <BaseIcon
                  v-for="(icon, index) in icons"
                  :key="index"
                  :name="icon.name"
                  :color="
                    icon.name === 'treatwell' &&
                    appointment &&
                    appointment.paidThroughTreatwell
                      ? 'success'
                      : 'default'
                  "
                  size="s"
                  :tooltip="{
                    text: tooltipText(icon.tooltip),
                    touch: true
                  }"
                  v-test="'calendar-popover-event-icons'"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="appointment && appointment.priceAdjustment"
          :class="$style.listItem"
          v-test="'calendar-popover-price-adjustment'"
        >
          <div :class="$style.listItemLeft">
            <BaseIcon name="percentage" />
          </div>
          <div :class="$style.listItemRight">
            {{ appointment.priceAdjustment.amount }}%
            {{
              appointment.priceAdjustment.adjustmentType ===
              'PERCENTAGE_INCREASE'
                ? 'increase'
                : 'discount'
            }}
          </div>
        </div>
      </div>
    </div>

    <template v-if="data.entryId && data.type !== 'holiday'" #footer>
      <div
        v-if="data.importProvider"
        :class="$style.footer"
        v-test="'imported-from-prodiver'"
      >
        {{
          $t('calendar.imported_from_provider', {
            provider: filters.capitalize(data.importProvider)
          })
        }}
      </div>
      <div v-else :class="$style.footer">
        <div :class="$style.footerSection">
          <BaseButton
            v-if="showEditButton"
            color="inverted"
            :mr="0.5"
            icon="edit"
            :routerLink="{
              name: isAppointment
                ? 'edit-appointment'
                : data.chore
                  ? 'chore'
                  : data.type,
              params: isAppointment
                ? { appointmentId: data.entryId }
                : { id: data.entryId },
              query: isRecurringCopy
                ? {
                    date: dayjs(data.start).format('YYYY-MM-DD')
                  }
                : null
            }"
            :tooltip="$t('global.actions.edit')"
            @click="onEditClick"
            v-test="'popover-edit'"
          />
          <BaseButton
            v-if="showDeleteButton"
            color="inverted"
            icon="delete"
            :tooltip="$t('global.actions.delete')"
            :mr="0.5"
            @click="onDeleteClick"
            v-test="'popover-delete'"
          />
          <BaseButton
            v-if="showReportButton"
            :routerLink="`calendar/appointment/${appointment.id}/report${hasReport ? `/${appointment.report.id}/edit` : ''}`"
            color="inverted"
            icon="clipboard"
            :tooltip="
              hasReport
                ? $t('global.actions.edit_report')
                : $t('global.actions.create_report')
            "
            :mr="0.5"
            @click="mixPanelTrack('edit-report')"
            v-test="'popover-report'"
          />
          <BaseButton
            v-if="showCheckoutButton"
            color="inverted"
            icon="shopping-cart"
            :tooltip="$t('global.actions.checkout')"
            :mr="0.5"
            @click="onCheckoutClick"
            v-test="'popover-checkout'"
          />
          <DirectCheckout
            v-if="showCheckoutButton"
            @click="onCheckoutClick(true)"
          />
          <BaseButton
            v-if="data.request"
            color="inverted"
            icon="close-square"
            :mr="0.5"
            :tooltip="$t('global.actions.reject')"
            @click="showRejectionModal = true"
            v-test="'popover-reject'"
          />
          <BaseButton
            v-if="data.request"
            color="inverted"
            :loading="confirming"
            icon="check-square"
            :tooltip="$t('global.actions.confirm')"
            @click="onConfirmClick"
            v-test="'popover-confirm'"
          />
        </div>
        <div :class="$style.footerSection">
          <BaseButton
            v-if="isNote"
            color="inverted"
            :loading="isSaving"
            @click="toggleNoteComplete"
            v-test="'popover-toggle-note'"
          >
            {{
              data.done
                ? $t('notes.mark_incomplete')
                : $t('notes.mark_completed')
            }}
          </BaseButton>
          <BaseButton
            v-if="showMoreButton"
            color="inverted"
            :routerLink="
              data && data.entryId
                ? {
                    name: 'appointment',
                    params: { appointmentId: data.entryId }
                  }
                : null
            "
            @click="onMoreClick"
            v-test="'popover-more'"
          >
            {{ $t('global.more') }}
          </BaseButton>
          <BaseButton
            v-if="isAppointment && isRecurringCopy"
            color="inverted"
            :mr="1"
            @click="rescheduleAppointment"
            v-test="'popover-reschedule'"
          >
            {{ $t('global.actions.reschedule') }}
          </BaseButton>
          <BaseButton
            v-if="isAppointment && isRecurringCopy"
            color="inverted"
            @click="onActivateClick"
            v-test="'popover-activate'"
          >
            {{ $t('global.actions.activate') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BasePopover>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import { resourceFragment } from '@/graphql-fragments';
import { uniqueArray } from '@/helpers/formatting';
import { getCalendarIcons, rruleFrequency } from '@/modules/calendar/helpers';
import CustomerDetails from './CustomerDetails.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import {
  updateAbsence,
  deleteAbsence
} from '@/modules/calendar/actions/absences';
import { deleteNote, updateNote } from '@/modules/calendar/actions/notes';
import { defineComponent, ref } from 'vue';
import {
  updateAppointment,
  activateAppointment,
  useAppointmentConfirmation
} from '@/modules/calendar/actions/appointments';
import RejectAppointmentRequest from '@/modules/calendar/modals/RejectAppointmentRequest.vue';
import DirectCheckout from './DirectCheckout.vue';

export default defineComponent({
  name: 'PopoverEvent',
  components: {
    CustomerDetails,
    RejectAppointmentRequest,
    DirectCheckout
  },
  inject: ['mixpanel'],
  props: {
    data: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { confirm, loading: confirming } = useAppointmentConfirmation();
    const showRejectionModal = ref(false);

    const onConfirmClick = () => {
      confirm(props.data.entryId).then(() => {
        emit('close');
      });
    };

    return {
      onConfirmClick,
      confirming,
      showRejectionModal,
      dayjs,
      filters
    };
  },
  data() {
    return {
      appointment: null,
      isSaving: false
    };
  },
  watch: {
    isVisible: {
      handler() {
        if (this.isVisible) {
          this.isSaving = false;
        }
      },
      immediate: true
    }
  },
  apollo: {
    appointment: {
      query: gql`
        query getAppointment($id: Int!, $date: DateTime!) {
          appointment(id: $id) {
            id
            orders {
              id
              paid
              total
              permissions {
                editOrderItems
              }
            }
            parts {
              id
              service {
                id
                name
              }
              resources {
                ...resource
              }
            }
            report {
              id
            }
            customer {
              id
              lastAppointmentAt(date: $date)
              mobilePhone
              phone
            }

            customerMember {
              firstName
              lastName
            }
            paidThroughTreatwell
            pricePaidThroughTreatwell
            priceAdjustment {
              amount
              adjustmentType
            }
          }
        }
        ${resourceFragment}
      `,
      variables() {
        return {
          id: this.data.entryId,
          date: this.data.start
        };
      },
      skip() {
        return !this.isVisible || !this.isAppointment;
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useResourcesStore, ['hasSingleResource']),
    isAppointment() {
      return this.data.type === 'appointment';
    },
    isAbsence() {
      return this.data.type === 'absence';
    },
    isNote() {
      return this.data.type === 'note';
    },
    icons() {
      const features = { ...this.data.features };

      return getCalendarIcons({
        features: this.isRecurringCopy
          ? {
              recurs: true
            }
          : {
              ...features,
              recurs: this.data.features?.recurs || this.data.recurs
            }
      });
    },
    rruleFrequency() {
      return this.data.rrule ? rruleFrequency(this.data.rrule) : '';
    },
    hasReport() {
      return this.appointment?.report?.id;
    },
    showCheckoutButton() {
      return (
        !this.$apollo.loading &&
        this.isAppointment &&
        this.hasFeatureFlag('module-register') &&
        (!this.appointment?.orders?.length || !!this.unpaidInvoice) &&
        !this.isRecurringCopy &&
        !this.data.request
      );
    },
    showReportButton() {
      return (
        !!this.appointment?.id &&
        this.isAppointment &&
        !this.isRecurringCopy &&
        (!!this.data.customerId || this.hasReport) &&
        this.hasFeatureFlag('appointment-reports') &&
        !this.data.request
      );
    },
    showEditButton() {
      return (
        !this.isAppointment || (!this.isRecurringCopy && !this.data.request)
      );
    },
    showDeleteButton() {
      return !this.isAppointment || this.isRecurringCopy;
    },
    showMoreButton() {
      return this.isAppointment && !this.isRecurringCopy;
    },
    unpaidInvoice() {
      return this.appointment?.orders?.find(
        (order) => !order.paid && !order.credit
      );
    },
    isRecurringCopy() {
      return (
        this.data.originalStartAt &&
        (this.data.rrule || this.data.recurs) &&
        !dayjs(this.data.start).isSame(this.data.originalStartAt, 'day')
      );
    },
    showHeader() {
      return (
        !!this.data.customerName ||
        !!this.data.name ||
        (this.data.start && this.data.end && !this.data.allDay)
      );
    },
    resources() {
      if (this.isAppointment && this.appointment?.parts?.length) {
        const { resourceType } = useCalendarFiltersStore();
        const resources = this.appointment.parts
          .map((p) => p.resources)
          .reduce((acc, val) => acc.concat(val), [])
          .filter((r) => r.type === resourceType);
        return uniqueArray(resources);
      } else if (this.data.resourceId) {
        const { resourceById } = useResourcesStore();
        const resource = resourceById(this.data.resourceId);
        return resource ? [resource] : [];
      } else {
        return [];
      }
    },
    services() {
      return this.isAppointment &&
        this.appointment?.parts &&
        this.appointment.parts.length
        ? this.appointment.parts.map((part) => part.service)
        : [];
    },
    descriptionIcon() {
      let icon;
      if (this.isAppointment) {
        icon = 'scissors';
      }
      if (this.isAbsence) {
        icon = 'slash';
      }
      return icon;
    },
    duration() {
      return this.isAppointment
        ? this.data.totalDuration
        : this.data.eventDuration;
    }
  },
  methods: {
    tooltipText(tooltip) {
      if (
        tooltip.name === 'treatwell' &&
        this.isAppointment &&
        this.appointment?.paidThroughTreatwell
      ) {
        return this.$t('appointment.paid_through_treatwell');
      } else {
        return (
          tooltip.text ||
          this.$t(
            `appointment.features.${tooltip.name}`,
            tooltip.name === 'recurring_with_frequency'
              ? { frequency: this.rruleFrequency }
              : {}
          )
        );
      }
    },
    onEditClick() {
      this.mixPanelTrack('edit');
      this.$emit('close');
    },
    onMoreClick() {
      this.mixPanelTrack('more');
      this.$emit('close');
    },
    onDeleteClick() {
      this.mixPanelTrack('delete');
      if (this.data.rrule) {
        const input = {
          deleteEntry: true,
          id: this.data.entryId,
          recurrenceStartAt: this.data.start,
          startAt: this.data.start
        };

        const params = {
          original: !this.isRecurringCopy,
          rrule: this.data.rrule,
          isChore: this.data.chore,
          input
        };

        if (this.data.type === 'appointment') {
          updateAppointment(params);
        } else {
          updateAbsence(params);
        }
      } else {
        modal('confirmation', {
          type: 'delete',
          item: this.$t(
            `global.items.${(this, this.data.chore ? 'chore' : this.data.type)}`
          )
        }).then(() => {
          if (this.data.type === 'absence' || this.data.type === 'chore') {
            deleteAbsence(this.data.entryId);
          } else if (this.data.type === 'note') {
            deleteNote(this.data.entryId);
          }
        });
      }
    },
    onCheckoutClick(direct) {
      const query = this.unpaidInvoice
        ? { orderId: this.unpaidInvoice.id }
        : { appointmentId: this.appointment.id };

      if (direct) {
        query.direct = true;
        this.mixPanelTrack('quick-checkout');
      } else {
        this.mixPanelTrack('checkout');
      }

      this.$router.push({ name: 'register', query });
    },
    toggleNoteComplete() {
      this.isSaving = true;

      updateNote({
        id: this.data.entryId,
        completedAt: !this.data.done ? dayjs().format() : null
      }).then(() => {
        this.$emit('close');
      });
    },
    onActivateClick() {
      this.mixPanelTrack('activate');
      modal('confirmation', {
        message: this.$t('appointment.activate_confirmation')
      }).then(() => {
        activateAppointment({
          input: {
            id: this.data.entryId,
            recurrenceStartAt: this.data.start
          }
        }).then((newAppointment) => {
          this.$router.push({
            name: 'appointment',
            params: { appointmentId: newAppointment.id }
          });
        });
      });
    },
    rescheduleAppointment() {
      this.mixPanelTrack('reschedule');

      const { setState } = usePreCreateStore();
      setState({
        action: 'RESCHEDULE',
        appointment: {
          ...this.data,
          parts: this.appointment.parts,
          original: !this.isRecurringCopy,
          originalStartAt: this.data.start,
          startAt: this.data.start
        }
      });

      this.$router.push({ name: 'calendar' });
      this.$emit('close');
    },
    mixPanelTrack(button) {
      this.mixpanel.track('Appointment popup - Checkout clicked', {
        button,
        'data-type': this.data.type
      });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  z-index: 240 !important;
}

.heading,
.base.noHeader .list {
  padding-right: $spacing;
}

.heading {
  overflow: hidden;
}

.link {
  color: $color-primary;
}
.top {
  padding-bottom: $spacing * 0.5;
  margin-bottom: $spacing;
  border-bottom: 1px solid $color-border;
}

.listItem {
  display: flex;
  align-items: center;
  min-height: 24px;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }
}

.listItemDuration {
  margin-top: $spacing * -0.25;

  .listItemCol > * {
    margin-top: $spacing * 0.25;
  }
}

.listItemCol {
  display: flex;
  width: 50%;
  align-items: center;
}

.listItemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 6px;
  flex-shrink: 0;
}

.listItemRight {
  .base:not(.note) & {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.iconsWrap {
  position: relative;
  width: 100%;
  z-index: 1;
}

.iconsInner {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 2px;
}

.customerLink {
  display: flex;
  align-items: center;
}

.footerSection {
  display: flex;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
