<template>
  <div :class="$style.base">
    <BaseHeading size="s" :mb="0.5">{{ $t('global.color') }}</BaseHeading>
    <div :class="$style.inner">
      <BaseColorLabel
        v-model="colorValue"
        colorPicker
        isSelected
        large
        :mt="0.5"
      />
      <div
        v-if="showAppointment"
        :class="$style.appointmentSection"
        v-test="'colorSelection-appointment'"
      >
        <div :class="$style.appointment" v-html="exampleAppointment" />
        <BaseText size="s" :mt="0.5">{{
          $t('resource.appointment_look')
        }}</BaseText>
        <BaseText
          v-if="colorSettingChanged"
          link
          @click="setColorSetting"
          v-test="'colorSelection-undo'"
        >
          {{ $t('global.undo') }}
        </BaseText>
      </div>
      <div v-else :class="$style.colorSettingSection">
        <BaseText>{{ $t(`${modelType}.calendar_color_warning`) }}</BaseText>
        <BaseText
          link
          @click="setColorSetting"
          v-test="'colorSelection-settingButton'"
        >
          {{ $t(`${modelType}.calendar_color_change`) }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import createItem from '@/modules/calendar/calendar/calendar-content/CreateCalendarItem';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useServicesStore } from '@/stores/services';
import { useCompanyStore } from '@/stores/company';

const colorSuggestions = [
  '#4061F2',
  '#03CFA5',
  '#EB4762',
  '#FD832D',
  '#DCED9B',
  '#E2CCF6',
  '#00C6D0',
  '#B7049E',
  '#2AB92C',
  '#A975E9',
  '#C28C27',
  '#F06494',
  '#60D180',
  '#EEA1A1',
  '#C2BF40',
  '#0995D2',
  '#B384C2',
  '#10B796',
  '#860606',
  '#CE47CE'
];

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String
    },
    modelType: {
      type: String
    },
    colorSetting: {
      type: String
    }
  },
  emits: ['update:color', 'update:colorSetting'],
  data() {
    return {
      colorSettingChanged: false
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useResourcesStore, ['allEmployees', 'allRoomsAndEquipment']),
    ...mapState(useServicesStore, {
      categories: 'serviceCategories'
    }),
    colorValue: {
      get() {
        return this.color;
      },
      set(value) {
        this.$emit('update:color', value);
      }
    },
    exampleAppointment() {
      const event = {
        title: this.$t('global.items.service', 1),
        startStr: dayjs(),
        endStr: dayjs().add(1, 'hour'),
        extendedProps: {
          type: 'appointment',
          color: this.color
        }
      };
      return createItem({ event });
    },
    selectedSetting: {
      get() {
        return this.colorSetting || this.companySettings.agenda.colour;
      },
      set(value) {
        this.$emit('update:colorSetting', value);
      }
    },
    isCategory() {
      return this.modelType === 'category';
    },
    isEmployee() {
      return this.modelType === 'employee';
    },
    showAppointment() {
      return (
        (!this.isCategory && this.selectedSetting === 'RESOURCE') ||
        (this.isCategory && this.selectedSetting === 'CATEGORY')
      );
    },
    availableColors() {
      if (this.usedColors.length >= colorSuggestions.length) {
        return colorSuggestions;
      } else {
        return colorSuggestions.filter(
          (color) => !this.usedColors.includes(color)
        );
      }
    },
    randomColor() {
      return this.availableColors[
        Math.floor(Math.random() * this.availableColors.length)
      ];
    },
    usedColors() {
      return this.existingObjects
        .map((object) => object.color.toUpperCase())
        .filter((color) => colorSuggestions.includes(color));
    },
    existingObjects() {
      return this.isCategory ? this.categories : this.resources;
    },
    resources() {
      return this.isEmployee ? this.allEmployees : this.allRoomsAndEquipment;
    }
  },
  methods: {
    setColorSetting() {
      this.selectedSetting =
        this.selectedSetting === 'CATEGORY' ? 'RESOURCE' : 'CATEGORY';
      this.colorSettingChanged = !this.colorSettingChanged;
    }
  },
  created() {
    if (!this.color) {
      this.colorValue = this.randomColor;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  width: 100%;
}

.inner {
  display: flex;
  min-height: 130px;
}

.appointmentSection {
  margin: auto;
}

.appointment {
  padding: $spacing * 0.5;
  border-radius: $radius;
  box-shadow: $shadow;
  width: 150px;
  margin: auto;

  & > * {
    pointer-events: none;
  }
}

.colorSettingSection {
  margin-left: $spacing;
}
</style>
