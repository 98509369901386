<template>
  <ModuleBase noBorder>
    <PageWrap v-if="!loadingDomain">
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
      >
        <div>
          <div :class="$style.domain">
            <BaseHeading>
              {{ $t('email_domain.title') }}
            </BaseHeading>
            <BaseSpinner v-if="verifyingDomain" inline />
            <BaseLabel
              v-else-if="!usesUnprocessableDomain"
              :color="labelColor"
              ml
              v-test="'email-verification-status-label'"
            >
              {{ labelText }}
            </BaseLabel>
          </div>
          <BaseText mb>
            {{
              $t('email_domain.sending_from', {
                domain: usesVerifiedDomain
                  ? contactEmail
                  : 'email.salonized.com'
              })
            }}
          </BaseText>
          <BaseText v-if="!usesVerifiedDomain" mb>
            {{ $t('email_domain.give_permission') }}
          </BaseText>
          <BaseText :href="$t('email_domain.help_url')" mb>
            {{ $t('email_domain.more_info') }}
          </BaseText>
          <BaseAlert
            v-if="hasMailGunVerifiedDomain && !usesVerifiedDomain"
            :text="$t('email_domain.mailgun_alert')"
            icon="alert"
            color="warning"
            mt
            mb
            v-test="'email-verification-mailgun-alert'"
          />
        </div>
        <BaseCard :mt="2" gray>
          <BaseHeading mb>
            {{ $t('email_domain.how_to') }}
          </BaseHeading>
          <BaseText>
            {{ $t('email_domain.step_1') }}
          </BaseText>
          <div :class="$style.emailFormHolder">
            <div :class="$style.emailForm">
              <BaseForm @submit="handleCreateSesIdentity">
                <BaseInput
                  v-model="contactEmail"
                  :label="$t('company.contact_email')"
                  :info="$t('company.contact_email_info')"
                  :mb="0.5"
                  type="email"
                  :error="
                    showGenericError ? t('email_domain.general_error') : ''
                  "
                  required
                  v-test="'email-verification-contactEmail'"
                />
                <BaseText v-if="usesUnprocessableDomain" mb>
                  {{ $t('email_domain.email_domain_popup_info') }}
                </BaseText>
                <BaseButton
                  color="inverted"
                  :loading="creatingSesIdentity"
                  submitForm
                  v-test="'email-verification-create-identity'"
                >
                  {{ $t('global.actions.submit') }}
                </BaseButton>
              </BaseForm>
            </div>
          </div>

          <div
            v-if="
              emailDomainResult?.emailDomain?.status !==
                EmailDomainStatus.Pending &&
              emailDomainResult?.emailDomain?.cnames?.length
            "
            :class="$style.section"
          >
            <BaseText mt>
              {{ $t('email_domain.step_2') }}
            </BaseText>
            <BaseCard mt mb>
              <BaseTableRow head>
                <BaseTableCell small>
                  {{ $t('email_domain.cname_record.type') }}
                </BaseTableCell>
                <BaseTableCell>
                  {{ $t('email_domain.cname_record.name') }}
                </BaseTableCell>
                <BaseTableCell>
                  {{ $t('email_domain.cname_record.value') }}
                </BaseTableCell>
              </BaseTableRow>
              <BaseTableRow
                v-for="cname in emailDomainResult.emailDomain?.cnames"
                :key="cname.key"
                v-test="'email-verification-cname-record'"
              >
                <BaseTableCell small>
                  {{ $t('email_domain.cname_record.cname') }}
                </BaseTableCell>
                <BaseTableCell>
                  <SmallCodeSnippet :snippet="cname.key" />
                </BaseTableCell>
                <BaseTableCell>
                  <SmallCodeSnippet :snippet="cname.value" />
                </BaseTableCell>
              </BaseTableRow>
              <div :class="$style.domain">
                <BaseButton
                  v-if="!usesVerifiedDomain"
                  color="inverted"
                  :loading="verifyingDomain"
                  @click="handleVerifyDomain"
                  v-test="'email-verification-verify-domain'"
                >
                  {{ $t('email_domain.check_verification_status') }}
                </BaseButton>
                <BaseSpinner v-if="verifyingDomain" inline />
                <div v-else>
                  {{ emailDomainResult?.emailDomain?.name }}
                  <BaseLabel :color="labelColor">{{ labelText }}</BaseLabel>
                </div>
              </div>
            </BaseCard>
            <div v-if="!usesVerifiedDomain" :class="$style.section">
              <BaseText mb>
                {{ $t('email_domain.pending_status') }}
              </BaseText>
            </div>
          </div>
        </BaseCard>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import SmallCodeSnippet from './SmallCodeSnippet.vue';

import { useQuery, useMutation } from '@vue/apollo-composable';
import {
  GET_EMAIL_DOMAIN,
  CREATE_SES_IDENTITY,
  VERIFY_DOMAIN
} from './graphql';

import type { BaseLabelProps } from '@/components/BaseLabel.vue';
import type { EmailDomain } from '@/types';
import { EmailDomainStatus } from '@/types';

import { useCompanyStore } from '@/stores/company';
const { company, updateCompany } = useCompanyStore();

import unleash from '@/unleash';
const hasMailGunVerifiedDomain = computed(
  () =>
    unleash.getVariant('AmazonSES-CustomDomain').name ===
    'MailGunVerifiedDomain'
);
const usesVerifiedDomain = computed(
  () =>
    emailDomainResult.value?.emailDomain?.status === EmailDomainStatus.Active
);
const usesUnprocessableDomain = computed(
  () => !emailDomainResult.value?.emailDomain
);
const showGenericError = ref(false);

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const contactEmail = ref(company.contactEmail);

const {
  loading: loadingDomain,
  result: emailDomainResult,
  refetch: refetchEmailDomain
} = useQuery<{ emailDomain: EmailDomain }>(GET_EMAIL_DOMAIN);

const { loading: creatingSesIdentity, mutate: createSesIdentity } =
  useMutation(CREATE_SES_IDENTITY);

const handleCreateSesIdentity = () => {
  contactEmail.value = contactEmail.value?.toLowerCase() || '';
  updateCompany({ contactEmail: contactEmail.value }).then(() => {
    if (
      emailDomainResult.value?.emailDomain?.status ===
        EmailDomainStatus.Pending ||
      contactEmail.value?.split('@')[1] !==
        emailDomainResult.value?.emailDomain?.name
    ) {
      refetchEmailDomain()?.then((response) => {
        if (response?.data.emailDomain?.id) {
          showGenericError.value = false;
          createSesIdentity({
            input: {
              emailDomainId: emailDomainResult.value?.emailDomain?.id
            }
          }).then((response) => {
            if (response?.data?.createSesIdentity?.errors?.length) {
              showGenericError.value = true;
              return;
            }
            refetchEmailDomain();
          });
        }
      });
    }
  });
};

const { loading: verifyingDomain, mutate: verifyDomain } =
  useMutation(VERIFY_DOMAIN);

const handleVerifyDomain = () => {
  verifyDomain({ input: {} }).then(() => {
    refetchEmailDomain();
  });
};

const labelColor = computed<BaseLabelProps['color']>(() => {
  switch (emailDomainResult.value?.emailDomain?.status) {
    case EmailDomainStatus.Unverified:
      return 'error';
    case EmailDomainStatus.Pending:
      return 'warning';
    default:
      return 'success';
  }
});

const labelText = computed(() => {
  switch (emailDomainResult.value?.emailDomain?.status) {
    case EmailDomainStatus.Unverified:
      return t('email_domain.not_verified');
    case EmailDomainStatus.Pending:
      return t('email_domain.pending');
    default:
      return t('email_domain.verified');
  }
});
</script>

<style lang="scss" module>
.grid {
  .base:not(.smallScreen) & {
    display: grid;
    grid-template-columns: 100px auto;
  }
}

.gridItem {
  .base:not(.smallScreen) & {
    padding: $spacing;
  }

  .base.smallScreen & {
    &:nth-child(even):not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}
.emailFormHolder {
  display: flex;
  width: 100%;
  margin: $spacing 0;
}
.emailForm {
  flex-grow: 1;
}
.emailDomainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.domain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $spacing 0;
}
.steps {
  margin-bottom: $spacing;

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.section {
  border-top: 1px solid $color-border;
  margin-top: $spacing * 2;
  padding-top: $spacing * 2;
}
</style>
