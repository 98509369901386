<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.resources')">
      <template #header>
        <BaseButton
          :routerLink="{ name: 'admin-resources-new' }"
          :fullWidth="$screen === 's'"
          v-test="'resource-header-create'"
        >
          {{ $t('resource.new') }}
        </BaseButton>
      </template>
      <div>
        <ResourceHeader
          v-model:filter="filter"
          v-model:searchQuery="searchQuery"
          :filterOptions="[
            { value: 'all', label: $t('global.all') },
            { value: 'active', label: $t('resource.active') },
            {
              value: 'rooms',
              label: filters.capitalize($t('global.items.room', 2))
            },
            {
              value: 'equipment',
              label: filters.capitalize($t('global.items.equipment', 2))
            },
            { value: 'deleted', label: $t('resource.deleted') },
            { value: 'bookable', label: $t('resource.bookable_online') }
          ]"
        />
        <ResourceList
          v-if="notOnlyEquipment"
          :resources="visibleRooms"
          :header="$t(`room.${subHeader}`)"
        />
        <ResourceList
          v-if="notOnlyRooms"
          :resources="visibleEquipment"
          :header="$t(`equipment.${subHeader}`)"
        />
        <div v-if="showDeletedResources">
          <ResourceList
            v-if="notOnlyEquipment"
            :resources="deletedRooms"
            :header="$t('room.deleted')"
          />
          <ResourceList
            v-if="notOnlyRooms"
            :resources="deletedEquipment"
            :header="$t('equipment.deleted')"
          />
        </div>
        <EmptyPageContent
          v-show="noResults"
          imageName="resources"
          :text="{
            title: $t('admin.resources.empty_state.title'),
            description: $t('admin.resources.empty_state.description'),
            btnPrimary: $t('admin.resources.empty_state.button_text')
          }"
          @click="$router.push({ name: 'admin-resources-new' })"
        />
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import filters from '@/filters';
import ModuleBase from '@/modules/ModuleBase.vue';
import ResourceHeader from '@/modules/admin/resources/ResourceHeader.vue';
import ResourceList from '@/modules/admin/resources/ResourceList.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import PageWrap from '../../PageWrap.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ModuleBase,
    ResourceList,
    ResourceHeader,
    EmptyPageContent,
    PageWrap
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      searchQuery: '',
      filter: 'active'
    };
  },
  computed: {
    subHeader() {
      switch (this.filter) {
        case 'all':
        case 'active':
        case 'rooms':
        case 'equipment':
          return 'active';
        case 'deleted':
          return 'deleted';
        case 'bookable':
          return 'bookable';
        default:
          return 'active';
      }
    },
    ...mapState(useResourcesStore, ['allRoomsAndEquipment']),
    filteredResources() {
      return this.searchQuery
        ? this.allRoomsAndEquipment.filter((resource) =>
            resource.name.match(new RegExp(this.searchQuery, 'gi'))
          )
        : this.allRoomsAndEquipment;
    },
    showDeletedResources() {
      return (
        this.filter === 'all' ||
        this.filter === 'rooms' ||
        this.filter === 'equipment'
      );
    },
    visibleResources() {
      switch (this.filter) {
        case 'all':
        case 'active':
        case 'rooms':
        case 'equipment':
          return this.activeResources;
        case 'deleted':
          return this.deletedResources;
        case 'bookable':
          return this.bookableResources;
        default:
          return this.filteredResources;
      }
    },
    visibleRooms() {
      return this.visibleResources.filter(
        (resource) => resource.type === 'room'
      );
    },
    visibleEquipment() {
      return this.visibleResources.filter(
        (resource) => resource.type === 'equipment'
      );
    },
    activeResources() {
      return this.filteredResources.filter(
        (resource) => resource.state === 'active'
      );
    },
    deletedResources() {
      return this.filteredResources.filter(
        (resource) => resource.state === 'deleted'
      );
    },
    bookableResources() {
      return this.filteredResources.filter(
        (resource) => resource.state === 'active' && resource.bookable
      );
    },
    deletedEquipment() {
      return this.deletedResources.filter(
        (resource) => resource.type === 'equipment'
      );
    },
    deletedRooms() {
      return this.deletedResources.filter(
        (resource) => resource.type === 'room'
      );
    },
    notOnlyEquipment() {
      return this.filter !== 'equipment';
    },
    notOnlyRooms() {
      return this.filter !== 'rooms';
    },
    noResults() {
      switch (this.filter) {
        case 'all':
          return !this.visibleResources.length && !this.deletedResources.length;
        case 'rooms':
          return !this.visibleRooms.length && !this.deletedRooms.length;
        case 'equipment':
          return !this.visibleEquipment.length && !this.deletedEquipment.length;
        case 'active':
        case 'bookable':
          return !this.visibleResources.length;
        case 'deleted':
          return false;
        default:
          return !this.visibleResources.length;
      }
    }
  }
});
</script>
