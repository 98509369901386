<template>
  <div>
    <BaseTableRow head>
      <BaseTableCell>
        {{ $t('marketing.discounts.details.used_by') }}
      </BaseTableCell>
      <BaseTableCell>
        {{ $t('marketing.discounts.details.used_at') }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ $t('marketing.discounts.details.appointment_date') }}
      </BaseTableCell>
    </BaseTableRow>

    <BaseTableRow
      v-for="(item, index) in items"
      :key="`row-${index}`"
      zebra
      v-test="'discountDetailListRow'"
    >
      <BaseTableCell>
        {{
          item.customer && item.customer.fullName ? item.customer.fullName : '-'
        }}
      </BaseTableCell>
      <BaseTableCell>
        {{ filters.date(item.usedAt) }}
      </BaseTableCell>
      <BaseTableCell right>
        {{ filters.date(item.appointmentDate) }}
      </BaseTableCell>
    </BaseTableRow>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
