import gql from 'graphql-tag';

export const GET_ABSENCES_QUERY = gql`
  query getAbsences {
    absences {
      name
      allDay
      holiday
      endAt
      id
      locationId
      name
      resourceId
      rrule
      startAt
    }
  }
`;

export const DELETE_ABSENCE_MUTATION = gql`
  mutation deleteAbsence($input: DeleteAbsenceInput!) {
    deleteAbsence(input: $input) {
      absence {
        allDay
      }
      calendarAbsence {
        id
      }
    }
  }
`;
