import { flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { useResourcesStore } from '@/stores/resources';
import { useServicesStore } from '@/stores/services';
import type { Service } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export const categories = [
  {
    name: 'BEAUTY_SALON',
    service: [
      'facial',
      'nails',
      'massage',
      'pedicure',
      'hair_cut',
      'waxing',
      'makeup',
      'eyebrows',
      'lashes',
      'permanent_makeup'
    ]
  },
  {
    name: 'HAIR_SALON',
    service: [
      'hair_cut',
      'coloring',
      'hair_extensions',
      'barber',
      'styling',
      'hair_treatment',
      'balayage',
      'children_haircut',
      'highlights',
      'hair_perming'
    ]
  },
  {
    name: 'BARBERSHOP',
    service: [
      'haircut',
      'shaves',
      'beard_trim',
      'facial_treatments',
      'head_shaves',
      'hair_coloring',
      'hair_wash_styling',
      'eyebrow_trim',
      'nose_ear_trim',
      'scalp_massage'
    ]
  },
  {
    name: 'DOG_TRIM_SALON',
    service: [
      'skin_coat',
      'teeth',
      'nail_pads',
      'full_groom',
      'wash_dry',
      'trimming',
      'puppy_groom',
      'coat_care',
      'bath',
      'plucking'
    ]
  },
  {
    name: 'MANI_PEDICURE',
    service: [
      'manicure',
      'pedicure',
      'hand_foot_massage',
      'nail_art',
      'biab_nails',
      'acrylinc_nails',
      'gel_polish',
      'nail_fix',
      'feet_treatments'
    ]
  },
  {
    name: 'THERAPIST',
    service: [
      'relax',
      'duo',
      'anti_cellulite',
      'foot',
      'sports',
      'facial',
      'neck_should_back',
      'deep_tissue',
      'thai',
      'aroma'
    ]
  },
  {
    name: 'OTHER',
    service: [
      'hair_cut',
      'massage',
      'facials',
      'hair_coloring',
      'pedicure',
      'waxing',
      'eyebrows',
      'physiotherapy',
      'wellness'
    ]
  }
];

export const companyImage = computed(() => {
  const { company } = useCompanyStore();

  switch (company.companyType) {
    case 'BEAUTY_SALON':
    case 'THERAPIST':
    case 'OTHER':
      return 'beauty_salon';
    case 'HAIR_SALON':
      return 'hair_salon';
    case 'MANI_PEDICURE':
      return 'mani_pedi';
    case 'DOG_TRIM_SALON':
      return 'trim_salon';
    default:
      return 'beauty_salon';
  }
});

export const createEmployee = async (employee: string) => {
  const { t } = useI18n();
  const { services } = useServicesStore();
  const { createResource } = useResourcesStore();

  const getOnlyIds = services.map((service: Service) => service.id);

  await createResource({
    name: employee,
    gender: null,
    phone: null,
    email: null,
    color: '#4c49c5',
    bookable: true,
    customRoster: false,
    picture: null,
    type: 'EMPLOYEE',
    locationIds: [],
    rosterLocationId: null,
    serviceIds: getOnlyIds
  }).then(() => {
    flash(t('global.flash.resource_created'));
  });
};
