<template>
  <BaseModal
    small
    :heading="$t('products.schedule_prices')"
    @close="emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseText bold mb>
          {{ $t('products.schedule_changes_heading') }}
        </BaseText>
        <BaseDateTime
          v-model="dateTime"
          :label="$t('global.date_time')"
          :minValue="minValue"
          v-test="'schedule-date'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="emit('close')"
        v-test="'cancelSchedule'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton @click="emit('submit', dateTime)" v-test="'confirmSchedule'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseDateTime from '@/components/BaseDateTime.vue';
import dayjs from 'dayjs';
import { ref } from 'vue';

const emit = defineEmits(['close', 'submit']);
const dateTime = ref(dayjs().add(1, 'hour').format());
const minValue = dayjs().format();
</script>
