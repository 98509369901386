<template>
  <Modal v-bind="{ parentRouteName, saving }" @submit="submit">
    {{ $t('integrations.mollie.settings.description') }}
    <a href="#" :class="$style.link">{{
      $t('integrations.mollie.settings.description_link')
    }}</a
    >.
    <BaseAlert :text="$t('integrations.mollie.settings.alert')" mt />
    <BaseDropdown
      v-model="profileId"
      :label="$t('integrations.mollie.settings.profile_id')"
      :options="
        profileIds.map((id) => ({
          value: id,
          label: id
        }))
      "
      required
      mt
      v-test="'mollie-profiles'"
    />
  </Modal>
</template>

<script setup lang="ts">
import Modal from './shared/Modal.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  parentRouteName: string;
}>();

const profileId = ref<string>();

const { result, onResult } = useQuery(gql`
  query getMollie {
    mollie {
      app {
        settings {
          profileId
        }
      }
      profileIds
    }
  }
`);

const profileIds = computed<string[]>(
  () => result.value?.mollie?.profileIds || []
);
onResult(
  ({
    data: {
      mollie: {
        app: { settings }
      }
    }
  }) => {
    profileId.value = settings.profileId;
  }
);

const router = useRouter();
const { t } = useI18n();

const { mutate, loading: saving } = useMutation(gql`
  mutation updateMollieApp($input: UpdateMollieAppInput!) {
    updateMollieApp(input: $input) {
      mollieApp {
        id
      }
    }
  }
`);

const submit = () => {
  mutate({
    input: {
      profileId: profileId.value
    }
  }).then(() => {
    flash(t('global.flash.integration_updated'));

    router.push({
      name: props.parentRouteName
    });
  });
};
</script>

<style lang="scss" module>
.link {
  color: $color-primary;

  @include hover {
    text-decoration: underline;
  }
}
</style>
