<template>
  <div :class="$style.base">
    <div :class="$style.table">
      <BaseCard>
        <BaseTableRow head>
          <BaseTableCell :width="35">
            {{ $t('global.items.product', 1) }}
          </BaseTableCell>
          <BaseTableCell>
            {{ $t('global.part_number') }}
          </BaseTableCell>
          <BaseTableCell>
            {{ $t('global.received') }}
          </BaseTableCell>
          <BaseTableCell>
            {{ $t('global.ordered') }}
          </BaseTableCell>
          <BaseTableCell>
            {{ $t('global.difference') }}
          </BaseTableCell>
        </BaseTableRow>
        <ProcessItem
          v-for="(item, index) in itemsToShow"
          :key="index"
          :item="item"
          @updateQuantityReceived="$emit('updateQuantityReceived', $event)"
          v-test="'processItems'"
        />
      </BaseCard>
    </div>
  </div>
</template>

<script lang="ts">
import ProcessItem from './ProcessItem.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ProcessItem,
    BaseTableRow,
    BaseTableCell
  },
  props: {
    status: {
      type: String
    },
    processItems: {
      type: Array,
      default: () => []
    }
  },
  emits: ['updateQuantityReceived'],
  watch: {
    status: {
      handler() {
        if (
          this.status &&
          this.status !== 'ordered' &&
          this.status !== 'backorder'
        ) {
          this.$router.push({ name: 'stock-order' });
        }
      },
      immediate: true
    }
  },
  computed: {
    backorderProcessItems() {
      return this.processItems.filter(
        (item) => (item.quantityReceived || 0) - (item.quantity || 0)
      );
    },
    itemsToShow() {
      if (this.status === 'backorder') {
        return this.backorderProcessItems;
      } else {
        return this.processItems;
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  overflow: auto;
  padding-bottom: $spacing * 7;
}

.table {
  min-width: 700px;
}
</style>
