const Employees = () => import('@/modules/admin/resources/pages/Employees.vue');
const Resources = () => import('@/modules/admin/resources/pages/Resources.vue');
const Employee = () => import('@/modules/admin/resources/modals/Employee.vue');
const Resource = () => import('@/modules/admin/resources/modals/Resource.vue');

export default [
  {
    path: 'employees',
    name: 'admin-employees',
    component: Employees,
    meta: {
      featureFlag: 'admin-employees'
    },
    children: [
      {
        path: 'new',
        name: 'admin-employees-new',
        component: Employee,
        props: {
          parentRouteName: 'admin-employees'
        }
      },
      {
        path: ':id',
        name: 'admin-employee',
        component: Employee,
        props: {
          parentRouteName: 'admin-employees'
        }
      }
    ]
  },
  {
    path: 'resources',
    name: 'admin-resources',
    component: Resources,
    meta: {
      featureFlag: 'admin-employees'
    },
    children: [
      {
        path: 'new',
        name: 'admin-resources-new',
        component: Resource,
        props: {
          parentRouteName: 'admin-resources'
        }
      },
      {
        path: ':id',
        name: 'admin-resource',
        component: Resource,
        props: {
          parentRouteName: 'admin-resources'
        }
      }
    ]
  }
];
