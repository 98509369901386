import gql from 'graphql-tag';

export const TREATWELL_UPDATE_PILOT_SUBSCRIPTION = gql`
  mutation treatwellUpdatePilotSubscription(
    $input: UpdateTreatwellPilotSubscriptionInput!
  ) {
    treatwellUpdatePilotSubscription(input: $input) {
      company {
        id
      }
      errors
    }
  }
`;
