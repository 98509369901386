<template>
  <BaseAlert
    v-if="
      !hasCancelled &&
      !loading &&
      activeSubscription &&
      activeSubscription.cancelled
    "
    :text="
      t('admin.cards.subscription_cancelled.message', {
        days: dayjs(activeSubscription.endAt).diff(dayjs(), 'day')
      })
    "
    :primaryAction="
      t('admin.cards.subscription_cancelled.reactivate_subscription')
    "
    mb
    color="warning"
    @primaryAction="handleReactivateSubscription"
    v-test="'billing-subscription-cancelled-alert'"
  />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable';
import dayjs from 'dayjs';
import getCompanySubscription from './get-company-subscription';
import { flash, modal } from '@/helpers/ui';
import { inject, ref } from 'vue';

const REACTIVATE_SUBSCRIPTION_MUTATION = gql`
  mutation reactivateSubscription($input: ReactivateSubscriptionInput!) {
    reactivateSubscription(input: $input) {
      errors {
        message
        type
      }
    }
  }
`;

const hasCancelled = ref(false);

const { t } = useI18n();
const { mutate: reactivateSubscription } = useMutation(
  REACTIVATE_SUBSCRIPTION_MUTATION,
  { variables: { input: {} } }
);

const { activeSubscription, loading } = getCompanySubscription();

const mixpanel = inject<any>('mixpanel');

const handleReactivateSubscription = () => {
  mixpanel.track('Reactivate subscription link clicked');

  modal('confirmation', {
    message: t(
      'admin.cards.subscription_cancelled.reactivate_subscription_confirmation'
    )
  }).then(() => {
    reactivateSubscription().then(
      ({
        data: {
          reactivateSubscription: { errors }
        }
      }) => {
        if (errors.length > 0) {
          return;
        }

        flash(t('admin.cards.subscription_cancelled.reactivation_message'));
        hasCancelled.value = true;

        mixpanel.track('Subscription reactivated');
      }
    );
  });
};
</script>
