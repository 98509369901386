<template>
  <div>
    <BaseText
      :routerLink="{
        name: 'cashups'
      }"
      iconBefore="arrow-left"
      mb
    >
      {{ $t('global.back') }}
    </BaseText>
    <BaseSpinner v-if="$apollo.loading" />
    <div v-else-if="cashup">
      <CashupInfoCards :cashup="cashup" v-test="'cashupsItemInfo'" />
      <StartingBalance :cashup="cashup" mb />
      <VatOverview :summary="cashup.vatSummary" mb />
      <PaymentTotals :cashup="cashup" mb />
      <CountedCash
        v-if="hasCashCount && cashup.cashCount"
        :cashCount="cashup.cashCount"
        mb
      />
      <CashTransactions :cashup="cashup" mb />
      <Invoices
        :cashupId="cashup.id"
        :transactionCount="cashup.transactionCount"
      />
    </div>
  </div>
</template>

<script lang="ts">
import CashupInfoCards from './CashupInfoCards.vue';
import StartingBalance from './StartingBalance.vue';
import VatOverview from './VatOverview.vue';
import PaymentTotals from './PaymentTotals.vue';
import CashTransactions from './CashTransactions.vue';
import CountedCash from './CountedCash.vue';
import Invoices from './Invoices.vue';
import gql from 'graphql-tag';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CashupInfoCards,
    StartingBalance,
    VatOverview,
    PaymentTotals,
    CashTransactions,
    CountedCash,
    Invoices
  },
  apollo: {
    cashup: {
      query: gql`
        query getCashup($id: ID!) {
          cashup(id: $id) {
            id
            location {
              name
              id
            }
            number
            openedOn
            comment
            cashCount {
              amount
              moneyType
              value
            }
            openedBy {
              fullName
            }
            closedBy {
              fullName
            }
            closedOn
            openingAmount
            earnings
            expenses
            vatSummary {
              percentage
              priceExcl
              unitVat
            }
            expectedTotalCash
            expectedTotal
            totalDifference
            countedTotal
            total
            transactionsTotal
            payments {
              countable
              counted
              difference
              expected
              type
            }
            orderCount
            transactionCount
          }
        }
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    }
  },
  watch: {
    locationId(locationId) {
      this.$router.replace({
        name: 'cashups',
        params: {
          locationId
        }
      });
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useLocationsStore, ['locationId']),
    hasCashCount() {
      return this.company.currency === 'EUR';
    }
  }
});
</script>
