<template>
  <BaseModalLarge
    :routes="routes"
    :details="details"
    :actions="actions"
    parentRoute="products"
    @action="onActionClick"
  >
    <CreateProduct v-if="showEditModal" @close="showEditModal = false" />
    <UpdateStock
      v-if="showUpdateStockModal"
      @close="showUpdateStockModal = false"
    />
    <div :class="$style.inner">
      <div :class="$style.minHeight">
        <BaseCard>
          <div v-show="product">
            <StockInfo
              v-if="product && $route.name === 'product-overview'"
              :product="product"
            />
            <Sales v-if="$route.name === 'product-sales'" />
            <StockHistory v-if="$route.name === 'product-history'" />
          </div>
          <BaseSpinner v-show="$apollo.loading" />
        </BaseCard>
      </div>
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import gql from 'graphql-tag';
import StockInfo from './StockInfo.vue';
import Sales from './Sales.vue';
import StockHistory from './StockHistory.vue';
import { productFragment } from '../graphql';
import CreateProduct from '../modals/CreateProduct.vue';
import UpdateStock from '../modals/UpdateStock.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    StockInfo,
    Sales,
    StockHistory,
    CreateProduct,
    UpdateStock
  },
  emits: ['delete'],
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      product: null,
      showEditModal: false,
      showUpdateStockModal: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useLocationsStore, ['locationId']),
    routes() {
      return [
        {
          name: 'product-overview',
          label: this.$t('products.stock_management'),
          heading: this.product ? this.product.name : ''
        },
        {
          name: 'product-sales',
          label: this.$t('global.sales'),
          heading: this.product ? this.product.name : ''
        },
        {
          name: 'product-history',
          label: this.$t('products.stock_history'),
          heading: this.product ? this.product.name : ''
        }
      ];
    },
    actions() {
      return this.product
        ? [
            {
              name: 'edit',
              icon: 'edit',
              label: this.$t('global.actions.edit_product')
            },
            {
              name: 'update-stock',
              icon: 'plus',
              label: this.$t('global.actions.update_stock'),
              hide: !this.product.stockEnabled
            },
            {
              name: 'delete',
              icon: 'delete',
              label: this.$t('global.actions.delete_product'),
              hide: !this.hasFeatureFlag('product-delete')
            }
          ].filter((item) => !item.hide)
        : [];
    },
    details() {
      const details = [];
      if (this.product) {
        const category = {
          label: this.$t('global.category'),
          value: this.product.category
            ? this.product.category.name
            : this.$t('products.no_category')
        };

        details.push(category);

        if (this.product.partNumber) {
          const partNumber = {
            label: this.$t('global.part_number'),
            value: this.product.partNumber
          };

          details.push(partNumber);
        }

        if (this.product.reference) {
          const reference = {
            label: this.$t('global.ean_code'),
            value: this.product.reference
          };

          details.push(reference);
        }

        details.push(
          {
            label: this.$t('global.price'),
            value: this.filters.currency(this.product.price)
          },
          {
            label: this.$t('global.cost_price'),
            value: this.filters.currency(this.product.costPrice)
          },
          {
            label: this.$t('global.vat'),
            value:
              this.product.vatRate.percentage !== null
                ? `${this.product.vatRate.name} [${this.product.vatRate.percentage}%]`
                : this.product.vatRate.name
          }
        );
      }

      return details;
    }
  },
  methods: {
    onActionClick(action) {
      switch (action) {
        case 'edit':
          this.showEditModal = true;
          break;
        case 'update-stock':
          this.showUpdateStockModal = true;
          break;
        case 'delete':
          this.onDelete();
          break;
      }
    },
    onDelete() {
      modal('confirmation', {
        type: 'delete',
        item: this.$t('global.items.product')
      }).then(() => {
        this.$emit('delete', this.product.id);
        this.$router.push({
          name: 'products'
        });
      });
    }
  },
  apollo: {
    product: {
      query: gql`
        query getProduct($locationId: ID, $id: Int!) {
          product(locationId: $locationId, id: $id) {
            ...fullProduct
          }
        }
        ${productFragment}
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          locationId: this.locationId
        };
      },
      fetchPolicy: 'cache-and-network'
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  position: relative;
  min-height: 200px;
  height: 100%;
}

.minHeight {
  position: relative;
  min-height: 200px;
  height: 100%;
}
</style>
