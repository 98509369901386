<template>
  <BaseModal
    small
    :heading="$t('register.salonized_pay.profile.success_modal.title')"
  >
    <div :class="$style.base" v-test="'sp-terminal-payment-success'">
      <img src="/img/salonized-pay/success_modal.svg" />
      <BaseText mt center>
        {{ $t('register.salonized_pay.profile.success_modal.description') }}
      </BaseText>
    </div>
  </BaseModal>
</template>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
