<template>
  <BaseModalSmall testId="request-refund" @close="closeModal">
    <BaseText size="l">
      {{ $t('appointment.refund.header') }}
    </BaseText>
    <BaseText v-if="inCancellationPeriod" mt v-test="'request-refund-details'">
      {{
        $t('appointment.refund.details', { amount: filters.currency(amount) })
      }}
    </BaseText>
    <div v-else>
      <BaseRadio
        v-model="selectedOption"
        mt
        :label="
          $t('appointment.refund.info', { amount: filters.currency(amount) })
        "
        :options="options"
        v-test="'request-refund-options'"
      />
      <BaseText
        v-show="showErrorMessage"
        mt
        color="error"
        v-test="'request-refund-error'"
      >
        {{ $t('appointment.refund.error') }}
      </BaseText>
    </div>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="closeModal"
        v-test="'request-refund-back'"
      >
        {{ $t('global.back') }}
      </BaseButton>
      <BaseButton
        class="ml"
        @click="onConfirmClick"
        v-test="'request-refund-confirm'"
      >
        {{ $t('appointment.refund.confirm') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import eventBus from '@/event-bus';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { deleteAppointment } from '@/modules/calendar/actions/appointments';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    appointmentId: {
      type: Number,
      required: true
    },
    appointmentStartAt: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    }
  },
  emits: ['onClose', 'onSubmit'],
  setup() {
    return {
      v$: useVuelidate(),
      filters
    };
  },
  data() {
    return {
      selectedOption: null,
      isSubmitted: false,
      showErrorMessage: false
    };
  },
  validations() {
    return {
      selectedOption: {
        required
      }
    };
  },
  watch: {
    selectedOption() {
      this.showErrorMessage = false;
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    options() {
      return [
        { value: true, label: this.$t('appointment.refund.yes') },
        { value: false, label: this.$t('appointment.refund.no') }
      ];
    },
    cancellationHours() {
      return this.companySettings.agenda?.cancellationHours;
    },
    inCancellationPeriod() {
      return dayjs().isBefore(
        dayjs(this.appointmentStartAt).subtract(this.cancellationHours, 'hours')
      );
    }
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
    },
    onConfirmClick() {
      this.isSubmitted = true;
      if (!this.inCancellationPeriod && this.v$.$invalid) {
        this.showErrorMessage = true;
        return;
      }
      const shouldRefund = this.inCancellationPeriod || this.selectedOption;

      deleteAppointment({ id: this.appointmentId, refund: shouldRefund }).then(
        () => {
          this.$emit('onSubmit');
          eventBus.$emit('refresh-appointments');
        }
      );
    }
  }
});
</script>
