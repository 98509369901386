<template>
  <BaseModal
    :heading="$t('customers.export_mailchimp_segment')"
    small
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('customers.export_mailchimp_results') }}
    </BaseText>
    <BaseInput
      v-model="segment"
      focus
      :label="$t('customers.segment_name')"
      mb
      v-test="'mailChimpSegment'"
    />
    <template #footer>
      <BaseButton color="inverted" @click="cancel">{{
        $t('global.actions.close')
      }}</BaseButton>
      <BaseButton @click="submit" v-test="'mailChimpSave'">{{
        $t('global.actions.save')
      }}</BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    searchQuery: {
      type: String,
      required: false
    },
    filters: {
      type: Array
    }
  },
  emits: ['close'],
  setup() {
    const selectedCustomers = inject<number[]>('selectedCustomers');

    return {
      selectedCustomers
    };
  },
  data() {
    return {
      segment: '',
      query: ''
    };
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    submit() {
      const { mutate } = useMutation(gql`
        mutation createMailchimpSegment($input: CreateMailchimpSegmentInput!) {
          createMailchimpSegment(input: $input) {
            status
          }
        }
      `);

      mutate({
        input: {
          name: this.segment,
          filters: this.filters,
          query: this.query,
          customerIds: this.selectedCustomers
        }
      }).then(() => {
        flash(this.$t('global.flash.mailchimp_segment'));
        this.$emit('close');
      });
    }
  },
  created() {
    if (this.searchQuery) {
      this.query = this.searchQuery;
    }
  }
});
</script>
