<template>
  <BaseText bold mb>
    {{ $t('onboarding.new_onboarding.service.title') }}
  </BaseText>

  <div :class="$style.base">
    <BaseCard gray fullWidth>
      <BaseText bold size="l" mb v-test="'input-onboarding-service'">
        {{
          $t(
            `onboarding.new_onboarding.services.${company.companyType}.${category}`
          )
        }}
      </BaseText>
      <BaseInput
        v-model="formData.name"
        focus
        :label="$t('onboarding.new_onboarding.service.service_name')"
        required
        v-test="'serviceNameInput'"
      />
      <div :class="$style.groupedInputs">
        <BaseInput
          v-model="formData.price"
          :label="$t('global.price')"
          :mr="1.5"
          type="currency"
        />
        <BaseInput
          v-model="formData.duration"
          :label="$t('global.duration')"
          unitLabel="minute"
          type="number"
          :minValue="0"
          required
        />
      </div>
    </BaseCard>
    <img
      v-if="$screen !== 's'"
      :src="`/img/onboarding/${companyImage}.svg`"
      :class="$style.img"
    />
  </div>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { companyImage } from './onboarding-utils';

const { company } = useCompanyStore();

defineProps<{
  formData: {
    name?: string;
    duration: number;
    price: number;
  };
  category: string;
}>();
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}

.groupedInputs {
  display: flex;
  margin-top: $spacing;
}

.img {
  width: 280px;
  height: 184px;
}
</style>
