<template>
  <div
    :class="[
      $style.base,
      {
        [$style.alignLeft]: alignLeft,
        [$style.fullHeight]: fullHeight,
        [$style.inline]: inline,
        [$style.small]: size === 's'
      }
    ]"
  >
    <div
      v-if="listOptions.length === 1 || inline"
      :class="$style.inlineActions"
    >
      <div
        v-for="(option, index) in listOptions"
        :key="`inline-${index}`"
        :class="$style.inlineAction"
        @click="onOptionClick(option, $event)"
        v-test="`_base-more-option-${option.name}`"
      >
        <BaseIcon
          :name="option.icon"
          :tooltip="option.label"
          color="primary"
          size="s"
        />
      </div>
    </div>
    <div
      v-else
      :class="$style.inner"
      @click="onClick"
      v-test="'_base-more-dots'"
    >
      <div :class="$style.dots">
        <div />
        <div />
        <div />
      </div>
    </div>
    <div
      v-show="isExpanded"
      :class="$style.content"
      @click="isExpanded = false"
      v-test="'_base-more-list'"
    >
      <div
        v-for="(option, index) in listOptions"
        :key="index"
        :class="$style.option"
        @click="onOptionClick(option, $event)"
        v-test="`_base-more-option-${option.name}`"
      >
        <BaseIcon :name="option.icon" /> {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
const actions = [
  {
    name: 'edit',
    icon: 'edit'
  },
  {
    name: 'delete',
    icon: 'delete'
  },
  {
    name: 'remove',
    icon: 'delete'
  },
  {
    name: 'merge',
    icon: 'person-add'
  },
  {
    name: 'view',
    icon: 'eye'
  },
  {
    name: 'restore',
    icon: 'undo'
  },
  {
    name: 'call',
    icon: 'phone'
  },
  {
    name: 'email',
    icon: 'email'
  },
  {
    name: 'complete',
    icon: 'check'
  },
  {
    name: 'duplicate',
    icon: 'copy'
  },
  {
    name: 'edit',
    icon: 'edit'
  },

  {
    name: 'create',
    icon: 'edit'
  },
  {
    name: 'expand',
    icon: 'edit'
  },
  {
    name: 'delete',
    icon: 'delete'
  },
  {
    name: 'pdf',
    icon: 'pdf'
  },
  {
    name: 'fill',
    icon: 'plus'
  },
  {
    name: 'resend',
    icon: 'arrow-corner-up-right'
  },
  {
    name: 'archive',
    icon: 'archive'
  },
  {
    name: 'unarchive',
    icon: 'archive'
  },
  {
    name: 'pay',
    icon: 'barcode'
  },
  {
    name: 'sort',
    icon: 'swap-vertical'
  }
];

import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => [],
      validator: (options) => {
        let isValid = !!options.length;
        options.forEach((option) => {
          if (
            !actions.find(
              (action) =>
                action.name === option ||
                action.name === option.name ||
                (option.name && option.icon && option.label)
            )
          ) {
            isValid = false;
          }
        });
        return isValid;
      }
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    fullHeight: Boolean,
    inline: Boolean,
    size: {
      type: String,
      default: 'm'
    }
  },
  emits: ['select'],
  data() {
    return {
      isExpanded: false
    };
  },
  watch: {
    isExpanded(value) {
      // Timeout is needed to prevent event bubbling, so that onDocumentClick doesn't get triggered on the initial click
      setTimeout(() => {
        value
          ? document.addEventListener('click', this.onDocumentClick)
          : document.removeEventListener('click', this.onDocumentClick);
      }, 0);
    }
  },
  computed: {
    listOptions() {
      return this.options.map((option) => {
        const action = actions.find(
          (action) => action.name === option || action.name === option.name
        );
        return {
          name: option.name || action.name,
          label: option.label || this.$t(`global.actions.${action.name}`),
          icon: option.icon || action.icon
        };
      });
    }
  },
  methods: {
    onClick(e) {
      e.preventDefault();
      this.isExpanded = !this.isExpanded;
    },
    onOptionClick(option, e) {
      e.preventDefault();
      e.stopPropagation();
      this.isExpanded = false;
      this.$emit('select', option.name);
    },
    onDocumentClick(e) {
      if (!e.target.isSameNode(this.$el) && !this.$el.contains(e.target)) {
        this.isExpanded = false;
      }
    }
  }
});
</script>

<style lang="scss" module>
$size: 20px;

.base {
  position: relative;
  flex-shrink: 0;

  &:not(.inline) {
    width: $size + $spacing * 2;

    &.small {
      width: 40px;
    }
  }

  &:not(.fullHeight) {
    height: $size + $spacing * 2;

    &.small {
      height: 40px;
    }
  }

  &.fullHeight {
    height: 100%;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: $spacing;
  min-height: $size;
  cursor: pointer;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20px;

  > * {
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: $color-primary;
  }
}

.content {
  position: absolute;
  top: calc(50% + #{$spacing * 0.5});
  background: $white;
  box-shadow: $shadow;
  border-radius: $radius;
  overflow: hidden;
  z-index: 10;

  .base.alignLeft & {
    left: calc(50% - #{$size * 0.5});
  }

  .base:not(.alignLeft) & {
    right: calc(50% - #{$size * 0.5});
  }

  > * {
    padding: 12px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #f1f1f1;
    }

    @include hover {
      &:first-child {
        border-radius: $radius $radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $radius $radius;
      }

      background: $color-highlight;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: -2px;
  font-weight: normal;
  white-space: nowrap;

  & > *:first-child {
    margin-right: $spacing * 0.25;
  }
}

.inlineActions {
  display: flex;
  height: 100%;
  padding: $spacing * 0.5;

  .base.small & {
    padding: 6px;
  }
}

.inlineAction {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  @include background-hover;

  .base.inline & {
    width: 24px;
  }

  .base:not(.inline) & {
    width: 36px;
  }
}
</style>
