import { useCompanyStore } from '@/stores/company';

//TODO: Change currency to enum in schema, and then change it to enum here

export const formatCurrency = (value: number, currency?: string): string => {
  if (!currency) {
    const { company } = useCompanyStore();
    currency = company?.currency || 'EUR';
  }
  return new Intl.NumberFormat('nl', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  }).format(value / 100);
};
