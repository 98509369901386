<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.bottomFavorites]:
          favorites.length > ($screen === 's' ? 3 : $screen === 'm' ? 4 : 6)
      }
    ]"
  >
    <Favorites :class="$style.favorites" />
    <div>
      <BrowserItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        arrow
        :borderTop="index === 0"
        @click="$router.push({ name: item.routeName })"
        v-test="`register-browser-${item.name}`"
      >
        <BaseIcon :name="item.icon" :mr="0.5" />
        {{ item.label }}
      </BrowserItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import BrowserItem from '../../BrowserItem.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import filters from '@/filters';
import type { IconName } from '@/components/base-icon/types';
import { useUserStore } from '@/stores/user';
import Favorites from './favorites/index.vue';
import { storeToRefs } from 'pinia';
import { useRegisterBrowserStore } from '../../store';

const { t } = useI18n();
const { hasFeatureFlag } = useUserStore();

const { favorites } = storeToRefs(useRegisterBrowserStore());

type BrowserItemType = {
  name: string;
  routeName: string;
  icon: IconName;
  label: string;
};

const items = computed<BrowserItemType[]>(() =>
  [
    {
      name: 'services',
      routeName: 'register-builder-services',
      icon: 'scissors',
      label: filters.capitalize(t('global.items.service', 2))
    },
    {
      name: 'products',
      routeName: 'register-builder-products',
      icon: 'pricetag',
      label: filters.capitalize(t('global.items.product', 2))
    },
    {
      name: 'appointments',
      routeName: 'register-builder-appointments',
      icon: 'calendar',
      label: filters.capitalize(t('global.items.appointment', 2))
    },
    {
      name: 'giftcard',
      routeName: 'register-builder-giftcard',
      icon: 'gift',
      label: filters.capitalize(t('global.items.giftcard', 1))
    },
    {
      name: 'prepaidcard',
      routeName: 'register-builder-prepaidcard',
      icon: 'credit-card',
      label: filters.capitalize(t('global.items.prepaid_card', 1))
    }
  ].filter(
    (item) =>
      hasFeatureFlag('module-appointments') || item.name !== 'appointments'
  )
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;

  &.bottomFavorites {
    flex-direction: column-reverse;
  }
}

.favorites {
  .base:not(.bottomFavorites) & {
    margin-bottom: $spacing;
  }

  .base.bottomFavorites & {
    margin-top: $spacing;
  }
}
</style>
