<template>
  <div>
    <BaseInputLabel v-if="label" :text="label" />
    <div :class="$style.inner">
      <div v-test="'_base-date-time-from'">
        <BaseDatePicker
          v-model="fromDate"
          :minValue="minValue"
          v-test="'_base-date-from'"
        />
        <BaseTimePicker
          v-show="!allDay"
          v-model="fromTime"
          :minValue="minTime"
          :ml="0.5"
          v-test="'_base-time-from'"
        />
      </div>
      <div v-if="to" v-test="'_base-date-time-to'">
        <div>
          {{ $t('global.until') }}
        </div>
        <BaseDatePicker
          v-model="toDate"
          :minValue="fromDate"
          ml
          v-test="'_base-date-to'"
        />
        <BaseTimePicker
          v-show="!allDay"
          v-model="toTime"
          :minValue="
            fromDate === toDate
              ? fromObj.add(5, 'minute').format('HH:mm')
              : null
          "
          :ml="0.5"
          v-test="'_base-time-to'"
        />
      </div>
      <div v-if="showAllDay">
        <BaseCheckbox
          :modelValue="allDay"
          :label="$t('global.all_day')"
          @update:modelValue="$emit('update:allDay', $event)"
          v-test="'_base-date-time-all-day'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { combineDateTime } from '@/helpers/dates';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    to: String,
    allDay: {
      type: Boolean,
      default: false
    },
    showAllDay: {
      type: Boolean,
      default: false
    },
    label: String,
    minValue: {
      type: String
    }
  },
  emits: ['update:modelValue', 'update:to', 'update:allDay'],
  setup() {
    return { dayjs };
  },
  data() {
    return {
      cachedTimeFrom: null,
      cachedTimeTo: null
    };
  },
  watch: {
    fromTime: {
      handler(value) {
        if (!this.allDay) {
          this.cachedTimeFrom = value;
        }
      },
      immediate: true
    },
    toTime: {
      handler(value) {
        if (!this.allDay) {
          this.cachedTimeTo = value;
        }
      },
      immediate: true
    },
    allDay: {
      handler(value, prevValue) {
        if (value || prevValue !== undefined) {
          this.fromTime = value ? '00:00' : this.cachedTimeFrom;

          if (this.toTime) {
            this.toTime = value ? '00:00' : this.cachedTimeTo;
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    fromObj() {
      return dayjs(this.modelValue).tz();
    },
    minTime() {
      if (!this.minValue) {
        return null;
      }
      const minValueObj = dayjs(this.minValue).tz();
      return this.fromObj.isSame(minValueObj, 'day')
        ? minValueObj.format('HH:mm')
        : null;
    },
    fromDate: {
      get() {
        return this.fromObj.format('YYYY-MM-DD');
      },
      set(date) {
        this.emitFromValue({ date });
      }
    },
    fromTime: {
      get() {
        return this.fromObj.format('HH:mm');
      },
      set(time) {
        this.emitFromValue({ time });
      }
    },
    toObj() {
      return this.to ? dayjs(this.to).tz() : null;
    },
    toDate: {
      get() {
        return this.to ? this.toObj.format('YYYY-MM-DD') : null;
      },
      set(date) {
        this.emitToValue({ date });
      }
    },
    toTime: {
      get() {
        return this.to ? this.toObj.format('HH:mm') : null;
      },
      set(time) {
        this.emitToValue({ time });
      }
    }
  },
  methods: {
    emitFromValue({ date, time }) {
      this.$emit(
        'update:modelValue',
        combineDateTime(date || this.fromDate, time || this.fromTime)
      );
    },
    emitToValue({ date, time }) {
      this.$emit(
        'update:to',
        combineDateTime(date || this.toDate, time || this.toTime)
      );
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing * -0.5;

  & > * {
    margin: $spacing * 0.5;
    display: flex;
    align-items: center;
  }
}
</style>
