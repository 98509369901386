<template>
  <component
    :is="elementType"
    v-bind="elementProps"
    tabindex="0"
    :class="$style.base"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    defaultElement: {
      type: String,
      default: 'span',
      validator: (value) =>
        ['div', 'span', 'button', 'label', 'a'].indexOf(value) !== -1
    },
    routerLink: {
      type: [Object, String]
    },
    href: {
      type: String
    },
    download: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    elementType() {
      let type = this.defaultElement;

      if (this.routerLink) {
        type = 'router-link';
      } else if (this.href) {
        type = 'a';
      }

      return type;
    },
    elementProps() {
      const props = {};

      if (this.routerLink) {
        props.to = this.routerLink;
      } else if (this.href) {
        props.href = this.href;
        props.target = '_blank';

        if (this.download) {
          props.download = true;
        }
      }

      return props;
    }
  },
  methods: {
    onClick(e) {
      if (this.href) {
        e.stopPropagation();
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  outline: none;
}
</style>
