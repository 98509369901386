<template>
  <BaseCard v-if="customerData" :heading="$t('dashboard.customers.heading')">
    <template #header>
      <div :class="$style.headerDropdown">
        <BaseDropdown
          v-if="customerData.totalCount > 0 || employeeId"
          v-model="employeeId"
          :options="[
            {
              value: null,
              label: $t('global.show_all')
            },
            ...employees.map((employee) => ({
              value: employee.id,
              label: employee.name
            }))
          ]"
          resources
          v-test="'customers-today-resource'"
        />
      </div>
    </template>
    <div :class="$style.totalText" v-test="'customers-today-total'">
      <BaseHeading center size="xl" mr>{{
        customerData.totalCount
      }}</BaseHeading>
      {{ $t('dashboard.customers.total') }}
    </div>
    <div :class="$style.progress">
      <div :class="$style.innerProgress">
        <BaseProgress
          :percentage="Math.round(customerData.returningPercentage * 100)"
          v-test="'returning-customers'"
        />
      </div>
      <BaseText ml>{{ $t('dashboard.customers.returning') }}</BaseText>
    </div>
    <div :class="$style.progress" class="mt-05">
      <div :class="$style.innerProgress">
        <BaseProgress
          :percentage="Math.round(customerData.newPercentage * 100)"
          v-test="'new-customers'"
        />
      </div>
      <BaseText ml>{{ $t('dashboard.customers.new') }}</BaseText>
    </div>
    <template
      v-if="
        (hasFeatureFlag('waiting-list') &&
          !hasFeatureFlag('waiting-list-pro')) ||
        (hasFeatureFlag('waiting-list-pro') &&
          companySettings.bookings.waitingListEnabled)
      "
      #footer
    >
      <BaseText v-test="'waitinglist-today'">
        {{
          companySettings.bookings.waitingListEnabled
            ? $t('dashboard.customers.waiting_list', {
                count: customerData.waitingListCount
              })
            : ''
        }}
        <BaseText
          :routerLink="{
            name: hasFeatureFlag('waiting-list-pro')
              ? 'marketing-waiting-list'
              : 'dashboard-waiting-list'
          }"
          v-test="'waitinglist-today-link'"
        >
          {{
            $t(
              companySettings.bookings.waitingListEnabled
                ? 'dashboard.customers.waiting_list_today'
                : 'dashboard.customers.enable_waiting_list'
            )
          }}
        </BaseText>
      </BaseText>
    </template>
  </BaseCard>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      employeeId: null
    };
  },
  apollo: {
    customerData: {
      query: gql`
        query getDashboard($locationId: ID, $resourceId: ID) {
          dashboard(locationId: $locationId, resourceId: $resourceId) {
            customersToday {
              totalCount
              newPercentage
              returningPercentage
              waitingListCount
            }
          }
        }
      `,
      pollInterval: 5 * 60 * 1000,
      update(data) {
        return data.dashboard.customersToday;
      },
      variables() {
        return {
          locationId: this.locationId,
          resourceId: this.employeeId
        };
      }
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['companySettings']),
    ...mapState(useResourcesStore, ['employees']),
    ...mapState(useLocationsStore, ['locationId'])
  }
});
</script>

<style lang="scss" module>
.headerDropdown {
  width: 150px;
}
.progress {
  display: flex;
  align-items: center;
}
.innerProgress {
  width: 60px;
}
.totalText {
  display: flex;
  margin-bottom: $spacing;
  align-items: center;
}
</style>
