<template>
  <BaseButton
    v-if="showComponent"
    color="inverted"
    icon="credit-card"
    :tooltip="$t('global.actions.quick_checkout')"
    :mr="0.5"
    v-test="'popover-direct-checkout'"
    v-intercom="'popover-direct-checkout'"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAdyenStore } from '@/stores/adyen';
import unleash from '@/unleash';

const showComponent = computed(() => {
  if (!unleash.isEnabled('SalonizedPay')) {
    return false;
  }

  const { locationTerminals } = useAdyenStore();
  return !!locationTerminals.length;
});
</script>
