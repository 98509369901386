<template>
  <BaseModal
    :heading="$t('boost.modal_title')"
    noPadding
    @close="$emit('close')"
  >
    <div v-if="showErrorMessage" :class="$style.alertWrap">
      <BaseAlert
        :text="$t('boost.error_message')"
        color="error"
        mb
        v-test="'boost-error-message'"
      />
    </div>

    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <div :class="$style.priceContainer">
        <BaseCard gray mb>
          <BaseText color="secondary">{{ $t('boost.price.label') }}</BaseText>
          <div :class="$style.price">
            <BaseText bold size="l">{{ $t('boost.price.item_name') }}</BaseText>
            <BaseSpinner v-if="loading" inline />
            <div v-else v-test="'boost-price'">
              <BaseText inline bold>{{
                filters.currency(price, result.extensionPrice.currency)
              }}</BaseText>
              /<BaseText inline>{{
                $t(
                  result.extensionPrice.period === 'YEAR'
                    ? 'global.items.year_short'
                    : 'global.items.month_short',
                  1
                )
              }}</BaseText>
            </div>
          </div>
        </BaseCard>
        <BaseText>{{ $t('boost.modal_description') }}</BaseText>
      </div>
      <div :class="$style.featuresContainer">
        <div :class="$style.featureWrapper">
          <img src="/img/boost/waitinglist.svg" alt="waitinglist" />
          <div :class="$style.featureTextContainer">
            <BaseText bold>{{ $t('boost.waiting_list.title') }}</BaseText>
            <BaseText>{{ $t('boost.waiting_list.description') }}</BaseText>
          </div>
        </div>

        <div :class="$style.featureWrapper">
          <img src="/img/boost/feedback.svg" alt="feedback" />
          <div :class="$style.featureTextContainer">
            <BaseText bold>{{ $t('boost.feedback.title') }}</BaseText>
            <BaseText>{{ $t('boost.feedback.description') }}</BaseText>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <BaseButton
        :loading="isSubmitting"
        @click="$emit('submit')"
        v-test="'btn-modal-get-boost'"
      >
        {{ $t('boost.modal_btn_text') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

defineProps<{
  isSubmitting: boolean;
  showErrorMessage: boolean;
}>();
defineEmits(['submit', 'close']);

const { result, loading } = useQuery(gql`
  query getExtensionPrice {
    extensionPrice {
      currency
      price
      period
    }
  }
`);

const price = computed(() => result.value?.extensionPrice?.price || 0);
</script>

<style lang="scss" module>
.base {
  display: flex;

  &.smallScreen {
    flex-direction: column;
  }
}

.priceContainer {
  flex-basis: 60%;
  padding: $spacing * 1.5;
}

.price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  padding: $spacing $spacing * 2;
  background: linear-gradient(90deg, rgba(225, 255, 249, 1) 0%, $white 100%);
  width: 100%;
}

.featureWrapper {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;

  .smallScreen & {
    flex-direction: column;
    margin-bottom: $spacing * 2;
  }
}

.featureTextContainer {
  margin-left: $spacing;
}

.alertWrap {
  padding: $spacing;
}
</style>
