import type { AppointmentPartAttributes } from '@/types';
import { useResourcesStore } from '@/stores/resources';

export const getPartDuration = (part: AppointmentPartAttributes): number => {
  if (part.duration) {
    return part.duration;
  } else if (
    typeof part.durationFinish === 'number' &&
    typeof part.durationProcessing === 'number' &&
    typeof part.durationSetup === 'number'
  ) {
    return part.durationFinish + part.durationProcessing + part.durationSetup;
  } else {
    return 0;
  }
};

export const getPartResources = (
  part: AppointmentPartAttributes,
  resourceType: 'employee' | 'room' | 'equipment'
): number[] => {
  const { resourceById } = useResourcesStore();
  const resourceIds =
    part.allocationsAttributes
      ?.reduce((acc, allocation) => acc.concat(allocation.resourceId), [])
      .filter((resourceId) => !!resourceId) || [];
  return resourceType
    ? resourceIds.filter((id) => resourceById(id)?.type === resourceType)
    : resourceIds;
};
