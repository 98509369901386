import gql from 'graphql-tag';

export const GET_CUSTOMER_TAGS = gql`
  query getCustomerTags {
    customerTags {
      companyTags {
        id
        name
        salonized
      }
      salonizedTags {
        id
        name
        salonized
      }
    }
  }
`;
