<template>
  <div v-if="modalName">
    <component :is="componentName" :data="modalData" @close="onModalClose" />
  </div>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import Confirmation from './Confirmation.vue';
import Error from './Error.vue';
import Warning from './Warning.vue';
import Welcome from './welcome/index.vue';
import CompanyType from './CompanyType.vue';
import CompanyDetails from './CompanyDetails.vue';
import MollieError from './MollieError.vue';
import Upgrade from './Upgrade.vue';
import RecurringConfirmation from './RecurringConfirmation.vue';
import RecurringEditOptions from './RecurringEditOptions.vue';
import TreatwellDirectDebit from './treatwell/DirectDebit.vue';
import TreatwellContract from './treatwell/Contract.vue';
import TreatwellStart from './treatwell/Start.vue';

import NewOnboarding from './onboarding/NewOnboarding.vue';
import CreateRoster from './onboarding/CreateRoster.vue';
import TryBookingWidget from './onboarding/TryBookingWidget.vue';
import ImportCustomers from './onboarding/ImportCustomers.vue';
import CompanyInfo from './onboarding/CompanyInfo.vue';

const allowedModalNames = [
  'confirmation',
  'error',
  'warning',
  'welcome',
  'company-type',
  'company-details',
  'mollie-error',
  'upgrade',
  'recurring-confirmation',
  'recurring-edit-options',
  'treatwell-contract',
  'treatwell-direct-debit',
  'treatwell-start',
  'new-onboarding',
  'create-roster',
  'try-booking-widget',
  'import-customers',
  'setup-company-info'
] as const;
export type AllowedModalNames = (typeof allowedModalNames)[number];

export interface GlobalModalData {
  message?: string;
  warning?: string;
}

interface GlobalModalState {
  modalName: AllowedModalNames | null;
  modalData: GlobalModalData;
}

const getDefaultState = (): GlobalModalState => ({
  modalName: null,
  modalData: {
    message: '',
    warning: ''
  }
});

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheGlobalModals',
  components: {
    Confirmation,
    Error,
    Warning,
    Welcome,
    CompanyType,
    MollieError,
    Upgrade,
    RecurringConfirmation,
    RecurringEditOptions,
    TreatwellContract,
    TreatwellDirectDebit,
    NewOnboarding,
    CreateRoster,
    TryBookingWidget,
    ImportCustomers,
    CompanyInfo,
    CompanyDetails,
    TreatwellStart
  },
  data() {
    return getDefaultState();
  },
  computed: {
    componentName() {
      const name = this.modalName;

      return name
        ? {
            confirmation: 'Confirmation',
            error: 'Error',
            warning: 'Warning',
            welcome: 'Welcome',
            'company-type': 'CompanyType',
            'company-details': 'CompanyDetails',
            upgrade: 'Upgrade',
            'mollie-error': 'MollieError',
            'recurring-confirmation': 'RecurringConfirmation',
            'recurring-edit-options': 'RecurringEditOptions',
            'treatwell-contract': 'TreatwellContract',
            'treatwell-direct-debit': 'TreatwellDirectDebit',
            'treatwell-start': 'TreatwellStart',
            'new-onboarding': 'NewOnboarding',
            'create-roster': 'CreateRoster',
            'try-booking-widget': 'TryBookingWidget',
            'import-customers': 'ImportCustomers',
            'setup-company-info': 'CompanyInfo',
            'salonized-assistant': 'Sally'
          }[name]
        : null;
    }
  },
  methods: {
    onModalClose() {
      this.reset();
    },
    onModalOpen({
      name,
      options
    }: {
      name: AllowedModalNames;
      options: unknown;
    }) {
      if (allowedModalNames.includes(name)) {
        this.modalName = name;
        this.modalData = options || {};
      }
    },
    reset() {
      Object.assign(this.$data, getDefaultState());
    }
  },
  created() {
    eventBus.$on('submit-confirmation-modal', this.onModalClose);
    eventBus.$on('open-modal', this.onModalOpen);
    eventBus.$on('after-modal-small-close', this.onModalClose);
  },
  beforeUnmount() {
    eventBus.$off('submit-confirmation-modal', this.onModalClose);
    eventBus.$off('open-modal', this.onModalOpen);
    eventBus.$off('after-modal-small-close', this.onModalClose);
  }
});
</script>
