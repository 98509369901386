<template>
  <BaseGrid container>
    <DisableWaitingList
      v-if="showDisableModal"
      @close="showDisableModal = false"
    />
    <BaseGrid :size="8">
      <div :class="[$style.flex, { [$style.isNotLarge]: $screen !== 'l' }]">
        <BaseButton
          color="inverted"
          @click="dateValue = null"
          v-test="'viewAll'"
        >
          {{ $t('global.show_all') }}
        </BaseButton>
        <BaseDatePicker v-model="dateValue" ml mr v-test="'dateFilter'" />
        <div :class="$style.align">
          <BaseButton
            :mr="0.5"
            color="inverted"
            icon="arrow-left"
            @click="incrementDate(-1)"
          />
          <BaseButton
            color="inverted"
            :disabled="showingToday"
            @click="showToday"
          >
            {{ $t('global.today') }}
          </BaseButton>
          <BaseButton
            :ml="0.5"
            color="inverted"
            icon="arrow-right"
            @click="incrementDate(1)"
          />
        </div>
      </div>
    </BaseGrid>
    <BaseGrid :size="4" alignRight alignTop>
      <BaseButton
        v-if="hasFeatureFlag('waiting-list-pro')"
        mr
        color="inverted"
        :disabled="!(date && items && items.length > 0)"
        icon="paper-plane"
        @click="sendAll"
      />
      <BaseButton
        v-if="!hasFeatureFlag('waiting-list-pro') || isMarketing"
        icon="settings"
        color="inverted"
        :options="[
          {
            label: $t('waiting_list.disable'),
            value: 'DISABLE'
          },
          {
            label: $t('waiting_list.configure_email'),
            routerLink: { name: 'waiting-list-template' }
          }
        ]"
        mr
        @select="onActionSelect"
        v-test="'waitinglistPopoverButton'"
      />
      <BaseButton
        :routerLink="{
          path: $route.path + '/new',
          query: date
            ? {
                date
              }
            : {}
        }"
        @click="track"
        v-test="'createWaitingListEntry'"
      >
        {{ $t('waiting_list.new_registration') }}
      </BaseButton>
    </BaseGrid>
  </BaseGrid>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import DisableWaitingList from './DisableWaitingList.vue';
import { emailCustomerAll } from './helpers';
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

export default defineComponent({
  components: {
    DisableWaitingList
  },
  inject: ['mixpanel'],
  props: {
    date: String,
    items: Array
  },
  emits: ['setDate'],
  data() {
    return {
      showDisableModal: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    dateValue: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit('setDate', value);
      }
    },
    showingToday() {
      return !!this.date && dayjs(this.date).isToday();
    },
    isMarketing() {
      return this.$route.name === 'marketing-waiting-list';
    }
  },
  methods: {
    track() {
      this.mixpanel.track('Waiting list new registeration clicked');
    },
    onActionSelect(value: 'DISABLE') {
      if (value === 'DISABLE') {
        this.showDisableModal = true;
      }
    },
    sendAll() {
      emailCustomerAll(
        this.date,
        this.items.map((item) => item.id)
      );
    },
    showToday() {
      this.dateValue = dayjs().format('YYYY-MM-DD');
    },
    incrementDate(value) {
      if (!this.date) {
        this.showToday();
      } else {
        this.dateValue = dayjs(this.date)
          .add(value, 'day')
          .format('YYYY-MM-DD');
      }
    }
  }
});
</script>

<style module lang="scss">
.flex {
  display: flex;
  flex-wrap: wrap;

  &.isNotLarge {
    > * {
      margin-bottom: $spacing * 0.5;
    }
  }
}

.align {
  display: flex;
}
</style>
