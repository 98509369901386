<template>
  <canvas id="confetti" :class="$style.confetti" />
</template>

<script lang="ts" setup>
import ConfettiGenerator from 'confetti-js';
import { nextTick } from 'vue';

nextTick(() => {
  const confettiSettings = { target: 'confetti' };
  const confetti = new ConfettiGenerator(confettiSettings);
  confetti.render();
});
</script>

<style lang="scss" module>
.confetti {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>
