<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <img :class="$style.visual" src="/img/salonized-pay/support.svg" />
    <div :class="$style.content">
      <BaseHeading mb :center="$screen === 's'" size="l">
        {{ $t('register.salonized_pay.landing.support.heading') }}
      </BaseHeading>
      <div class="mb-1" :class="$style.line">
        <img src="/img/salonized-pay/star2.svg" />
        <div>
          {{ $t('register.salonized_pay.landing.support.bullet1') }}
          <BaseText
            :href="$t('register.salonized_pay.landing.support.bullet1_link')"
          >
            {{ $t('register.salonized_pay.landing.support.bullet1_link_text') }}
          </BaseText>
        </div>
      </div>
      <div :class="$style.line">
        <img src="/img/salonized-pay/star2.svg" />
        {{
          replacePinapparaat(
            $t('register.salonized_pay.landing.support.bullet2'),
            company.country
          )
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { replacePinapparaat } from '@/helpers/formatting';
import { useCompanyStore } from '@/stores/company';

const { company } = useCompanyStore();
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  padding: 0 $spacing;

  &.smallScreen {
    flex-direction: column;
  }
}

.visual {
  width: 282px;
  max-width: 100%;
}

.content {
  .base:not(.smallScreen) & {
    padding: $spacing * 2;
  }

  .base.smallScreen & {
    margin-top: $spacing * 2;
    text-align: center;
  }
}

.line {
  display: flex;
  align-items: center;

  img {
    max-width: 18px;
    margin-right: $spacing * 0.5;

    .base.smallScreen & {
      display: none;
    }
  }
}
</style>
