<template>
  <router-link
    v-if="resource"
    :to="{ name: 'calendar' }"
    :class="$style.base"
    @click="onClick"
  >
    <div :class="$style.progressAvatar">
      <div :class="$style.progress">
        <BaseProgress
          :percentage="Math.round((data.occupied / data.timePresent) * 100)"
          hidePercentage
        />
      </div>
      <div :class="$style.avatar">
        <BaseAvatar :resource="resource" />
      </div>
    </div>
    <div :class="$style.details">
      <BaseText oneLine bold>
        {{ resource.name }}
      </BaseText>
      <BaseText oneLine size="s">
        {{ data.appointments }}
        {{ $t('global.items.appointment', data.appointments) }}
      </BaseText>
    </div>
    <div :class="$style.hours">
      <BaseText
        v-for="(roster, index) in data.roster"
        :key="`roster-${index}`"
        size="s"
      >
        {{ roster[0] }} - {{ roster[1] }}
      </BaseText>
    </div>
  </router-link>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useResourcesStore, ['resourceById']),
    resource() {
      return this.resourceById(this.data.resourceId);
    }
  },
  methods: {
    onClick() {
      const store = useCalendarFiltersStore();
      store.resourceId = this.resource.id;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  padding: $spacing * 0.5;
  border-radius: $radius;

  @include hover {
    background: $color-highlight;
  }
}

.progressAvatar {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: $spacing * 0.5;
  flex-shrink: 0;
}

.progress,
.avatar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  padding-right: $spacing * 0.5;
  padding-bottom: $spacing * 0.25;
  min-width: 0;
}

.hours {
  margin-left: auto;
  flex-shrink: 0;
  text-align: center;
}
</style>
