<template>
  <BaseModal
    :heading="$t('admin.treatwell.kyc_modal.title')"
    useForm
    small
    @submit="goToKYCFlow"
    @close="dismiss"
  >
    <BaseVisualBanner
      mb
      variant="small"
      icon="external_link"
      :heading="$t('admin.treatwell.kyc_modal.banner.heading')"
      :description="$t('admin.treatwell.kyc_modal.banner.description')"
      :imagePath="$screen !== 's' ? '/img/admin/verification-process.svg' : ''"
      :primaryAction="$t('admin.treatwell.kyc_modal.banner.primary_action')"
      @primaryAction="emit('learnMore')"
    />
    <BaseHeading mb icon="alert">
      {{ $t('admin.treatwell.kyc_modal.heading') }}
    </BaseHeading>
    <BaseText size="s">
      {{ $t('admin.treatwell.kyc_modal.text') }}
    </BaseText>
    <template #footer>
      <BaseButton
        v-if="$screen !== 's'"
        color="inverted"
        @click="dismiss"
        v-test="'tw-onboarding-dismiss-kyc'"
      >
        {{ $t('admin.treatwell.kyc_modal.do_this_later') }}
      </BaseButton>
      <BaseButton
        submitForm
        icon="external_link"
        v-test="'tw-onboarding-begin-kyc'"
        >{{ $t('admin.treatwell.kyc_modal.start_verification') }}</BaseButton
      >
      <BaseButton
        v-if="$screen === 's'"
        color="inverted"
        @click="dismiss"
        v-test="'tw-onboarding-dismiss-kyc'"
      >
        {{ $t('admin.treatwell.kyc_modal.do_this_later') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useTreatwellStore } from '@/stores/treatwell';
import { inject } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  parentRouteName?: string;
}>();
const emit = defineEmits(['submit', 'learnMore', 'close']);

const router = useRouter();
const mixpanel = inject<any>('mixpanel');

const dismiss = () => {
  mixpanel.track('kyc_start_modal_dismissed');
  close();
};

const close = () => {
  props.parentRouteName
    ? router.push({
        name: props.parentRouteName
      })
    : emit('close');
};

const { treatwellStatus, getTreatwellStatus } = useTreatwellStore();
const goToKYCFlow = () => {
  if (
    !treatwellStatus.hasVenue ||
    treatwellStatus.kycStatus?.kycCompleted ||
    !treatwellStatus.kycStatus?.onboardingUrl
  ) {
    return;
  }
  mixpanel.track('kyc_status_task_clicked');
  window.open(treatwellStatus.kycStatus.onboardingUrl);

  getTreatwellStatus();
  close();
};

mixpanel.track('kyc_start_modal_shown');
</script>
