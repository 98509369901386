<template>
  <div :class="$style.base">
    <svg
      width="256"
      height="222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.86 65.408C35.683 30.279 63.345 0 98.618 0h57.826c35.272 0 62.935 30.28 59.756 65.408l-8.788 97.098C205.08 188.269 183.483 208 157.615 208h-60.17c-25.869 0-47.466-19.731-49.797-45.494L38.86 65.408z"
        fill="#F5F9FF"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="38"
        y="0"
        width="179"
        height="212"
      >
        <path
          d="M38.747 65.312C35.627 30.217 63.277 0 98.511 0h58.037c35.234 0 62.884 30.217 59.764 65.312l-8.157 91.765c-2.751 30.956-28.686 54.688-59.764 54.688h-41.723c-31.078 0-57.013-23.732-59.764-54.688l-8.157-91.765z"
          fill="#F2F5FA"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M221.177 0l-33.412 41.412h-152L33.883 0h187.294z"
          fill="url(#paint0_linear)"
        />
      </g>
      <mask
        id="b"
        maskUnits="userSpaceOnUse"
        x="38"
        y="0"
        width="179"
        height="212"
      >
        <path
          d="M38.747 65.312C35.627 30.217 63.277 0 98.511 0h58.037c35.234 0 62.884 30.217 59.764 65.312l-8.157 91.765c-2.751 30.956-28.686 54.688-59.764 54.688h-41.723c-31.078 0-57.013-23.732-59.764-54.688l-8.157-91.765z"
          fill="#F2F5FA"
        />
      </mask>
      <g mask="url(#b)">
        <path
          d="M187.765 41.412L220.706.47l16 157.176-48.941 2.824V41.411z"
          fill="url(#paint1_linear)"
        />
      </g>
      <path
        d="M3.074 161.83c-1.317-1.994.113-4.653 2.504-4.653H250.64c2.345 0 3.784 2.569 2.557 4.568l-1.499 2.445a7 7 0 01-5.967 3.339H10.605a7 7 0 01-5.84-3.141l-1.69-2.558z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M89.882 167.529l.941-10.352-5.176 10.352h4.235z"
        fill="#161539"
      />
      <path
        d="M203.294 116.215h23.815s-2.679 29.947-4.763 31.079c-2.084 1.131-12.622 0-14.289 0-1.667 0-4.763-31.079-4.763-31.079z"
        fill="#494DBE"
      />
      <path
        d="M208.057 147.294h14.289v7.893a2.347 2.347 0 01-2.347 2.347h-9.594a2.347 2.347 0 01-2.348-2.347v-7.893z"
        fill="#FF7A88"
      />
      <path
        d="M203.294 113.366c0-1.188.964-2.152 2.152-2.152h19.512c1.188 0 2.151.964 2.151 2.152v2.849h-23.815v-2.849z"
        fill="#373A95"
      />
      <path d="M208.159 149.647h14.15v1.974h-14.15v-1.974z" fill="#D53A79" />
      <path
        d="M206.509 111.214h-.476v5.001h.476v-5.001zM205.319 111.214h-.477v5.001h.477v-5.001zM207.224 111.214h.476v5.001h-.476v-5.001zM208.891 111.214h-.476v5.001h.476v-5.001zM209.605 111.214h.477v5.001h-.477v-5.001zM211.272 111.214h-.476v5.001h.476v-5.001zM211.987 111.214h.476v5.001h-.476v-5.001zM213.654 111.214h-.477v5.001h.477v-5.001zM214.368 111.214h.477v5.001h-.477v-5.001zM216.035 111.214h-.476v5.001h.476v-5.001zM216.75 111.214h.476v5.001h-.476v-5.001zM218.417 111.214h-.477v5.001h.477v-5.001zM219.131 111.214h.477v5.001h-.477v-5.001zM220.798 111.214h-.476v5.001h.476v-5.001zM221.513 111.214h.476v5.001h-.476v-5.001zM223.18 111.214h-.477v5.001h.477v-5.001zM223.894 111.214h.477v5.001h-.477v-5.001zM225.561 111.214h-.476v5.001h.476v-5.001z"
        fill="#494DBE"
        fill-opacity=".24"
      />
      <path
        d="M119.059 186.353c-1.186-3.081-6.896-16.336-17.347-10.787-13.063 6.936-18.855-3.346-15.69-8.921 3.163-5.576 4.716-9.462 4.034-14.387-.842-4.635.108-14 10.649-14.377M47.512 51.003c-7.508 17.018 2.002 21.923 2.002 27.53M64.53 38.49C43.008 60.013 50.517 72.925 50.517 78.531M65.03 52.504C43.509 74.027 52.018 72.426 52.018 78.032"
        stroke="#03CFA5"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.518 73.251h-8.827l-1.761 27.304c-10.482 2.855-17.966 12.798-17.187 24.191l1.831 26.78a6 6 0 005.986 5.591H65.65a6 6 0 005.986-5.591l1.83-26.78c.78-11.393-6.704-21.336-17.185-24.191l-1.762-27.304z"
        fill="#D4DFFE"
      />
      <path
        d="M99.568 50.93c-3.738-3.372-10.456-4.067-13.348-3.993-8.676-7.764-30.503-5.048-30.7 17.08 4.45-4.437 7.342-5.102 8.231-4.88 10.323 1.065 16.908-1.627 18.91-3.106 6.585-8.163 14.015-6.802 16.907-5.102z"
        fill="#494DBE"
      />
      <path
        d="M19.902 40.37c-6.591-3.92-5.623-13.916-4.315-18.425 2.25-6.094 15.808 4.137 22.306 10.014l-.053 1.457c-7.032-2.662-14.889 3.527-17.938 6.955z"
        fill="#24235F"
      />
      <path
        d="M36.324 31.629c30.648-1.01 20.582 14.247 11.718 22.001-1.815.725-1.987-.462-1.847-1.146C41.263 28.55 26.362 34.283 19.528 40.14c4.593-6.576 13.111-8.415 16.796-8.512zM60.526 40.992C64.15 34.787 75.027 22.5 89.557 22.987c-5.855 5.442-19.86 16.662-29.03 18.005z"
        fill="#494DBE"
      />
      <path
        d="M118.588 13.963c-17.037.121-32.96 6.891-38.541 10.512 1.273-.731 7.58-1.737 20.622-1.913 13.043-.177 17.381-5.806 17.919-8.599z"
        fill="#24235F"
      />
      <path
        d="M55.02 62.015c2.548-4.936 12.085-14.856 28.613-15.056a20.726 20.726 0 012.42.04 42.97 42.97 0 00-2.42-.04c-6.814.275-17.463 3.803-28.112 17.558l-.501-2.502z"
        fill="#03CFA5"
      />
      <path
        d="M65.941 142.385c-.473-2.658 1.988-5.393 4.688-5.393h7.98c2.7 0 4.327 2.735 3.854 5.393l-2.43 13.66a1.445 1.445 0 01-1.423 1.192h-8.816c-.7 0-1.3-.502-1.423-1.192l-2.43-13.66z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M69.958 124.984c0-1.064.863-1.928 1.928-1.928h4.652c1.065 0 1.928.864 1.928 1.928v10.891c0 1.064-.863 1.117-1.928 1.117h-4.652c-1.065 0-1.928-.053-1.928-1.117v-10.891z"
        fill="#FF7A88"
      />
      <path
        d="M152.362 77.965c0-2.403 2.014-4.351 4.499-4.351h11.181c2.485 0 4.499 1.948 4.499 4.35v74.647c0 2.403.252 4.506-4.248 4.506h-11.536c-4.499 0-4.395-2.103-4.395-4.506V77.965z"
        fill="#24235F"
      />
      <path
        d="M152.362 77.975c0-2.409 2.014-4.361 4.499-4.361h11.181c2.484 0 4.499 1.952 4.499 4.36v17.574h-20.179V77.975z"
        fill="#FFD8E8"
      />
      <path
        d="M154.994 95.548h2.632v61.479h-2.632v-61.48z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M152.362 95.548c.675-2.268 2.488-5.634 4.918-5.634s2.604-4.237 2.314-5.772h5.714c-.289 1.535-.405 5.772 2.025 5.772 2.43 0 4.533 3.366 5.208 5.634h-20.179z"
        fill="#FFAECF"
      />
      <path
        d="M158.504 80.143c0-1.183.984-2.143 2.199-2.143h3.498c1.215 0 2.199.96 2.199 2.143v5.365c0 1.184-.984 2.143-2.199 2.143h-3.498c-1.215 0-2.199-.96-2.199-2.142v-5.366z"
        fill="#FF70AA"
      />
      <path
        d="M162.89 82.826c0 1.211-.785 2.193-1.754 2.193-.97 0-1.755-.982-1.755-2.193s.785-2.193 1.755-2.193c.969 0 1.754.982 1.754 2.193z"
        fill="#F7A6B4"
      />
      <path
        d="M160.877 82.76c0 .484-.196.877-.438.877-.243 0-.439-.392-.439-.877s.196-.877.439-.877c.242 0 .438.392.438.877z"
        fill="#DC929E"
      />
      <path
        d="M127.092 77.965c0-2.403 2.014-4.351 4.499-4.351h11.181c2.484 0 4.499 1.948 4.499 4.35v74.647c0 2.403-.952 4.506-4.797 4.506h-15.383c-4.395 0 .001-2.103.001-4.506V77.965z"
        fill="#24235F"
      />
      <path
        d="M129.723 95.548h2.632v17.345l-2.632-3.021V95.548z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M127.091 77.975c0-2.409 2.015-4.361 4.499-4.361h11.181c2.485 0 4.499 1.952 4.499 4.36v17.574h-20.179V77.975z"
        fill="#FFD8E8"
      />
      <path
        d="M127.091 95.548c.675-2.268 2.488-5.634 4.918-5.634 2.431 0 2.604-4.237 2.315-5.772h5.714c-.29 1.535-.405 5.772 2.025 5.772 2.43 0 4.532 3.366 5.207 5.634h-20.179z"
        fill="#FFAECF"
      />
      <path
        d="M133.233 80.143c0-1.183.984-2.143 2.199-2.143h3.498c1.215 0 2.199.96 2.199 2.143v5.365c0 1.184-.984 2.143-2.199 2.143h-3.498c-1.215 0-2.199-.96-2.199-2.142v-5.366z"
        fill="#FF70AA"
      />
      <path
        d="M137.62 82.826c0 1.211-.786 2.193-1.755 2.193-.969 0-1.755-.982-1.755-2.193s.786-2.193 1.755-2.193c.969 0 1.755.982 1.755 2.193z"
        fill="#F7A6B4"
      />
      <path
        d="M135.466 82.76c0 .484-.197.877-.439.877s-.439-.392-.439-.877.197-.877.439-.877.439.392.439.877z"
        fill="#DC929E"
      />
      <path
        d="M147.276 129.441l-20.179-22.504v45.432c0 2.537-5.291 4.748-.555 4.748h15.937c2.616 0 4.797-2.211 4.797-4.748v-22.928z"
        fill="#000"
        fill-opacity=".4"
      />
      <path
        d="M163.349 130.882c0-1.288.998-2.332 2.228-2.332h23.619c1.231 0 2.228 1.044 2.228 2.332v4.987c0 1.288-.997 2.332-2.228 2.332h-23.619c-1.23 0-2.228-1.044-2.228-2.332v-4.987z"
        fill="#878FEB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M119.95 112.685h-9.463l-.941 7.143h-.42a8.404 8.404 0 00-8.404 8.404v24.79a4.202 4.202 0 004.202 4.202h20.589a4.201 4.201 0 004.201-4.202v-24.79a8.403 8.403 0 00-8.403-8.404h-.42l-.941-7.143z"
        fill="url(#paint6_linear)"
      />
      <path d="M111.646 104.281h7.144v4.202h-7.144v-4.202z" fill="#4A4EBF" />
      <path
        d="M120.245 102.181h-15.853a1.26 1.26 0 00-.992.483l-2.344 2.99a1.26 1.26 0 00.184 1.745l.407.34a1.26 1.26 0 001.705-.081l2.043-2.068c.237-.24.56-.375.897-.375h14.121a1.26 1.26 0 001.198-1.652l-.168-.514a1.26 1.26 0 00-1.198-.868z"
        fill="#4A4EBF"
      />
      <path
        d="M109.966 108.903a.84.84 0 01.84-.84h8.824a.84.84 0 01.84.84v.421h-10.504v-.421z"
        fill="#373BA4"
      />
      <path d="M111.646 105.122h7.144v1.681h-7.144v-1.681z" fill="#4A4EBF" />
      <path
        d="M108.286 111.004c0-.928.752-1.68 1.68-1.68h10.505a1.68 1.68 0 011.681 1.68v.841a.84.84 0 01-.841.84h-12.185a.84.84 0 01-.84-.84v-.841z"
        fill="#FF6575"
      />
      <path
        d="M163.349 142.346c0-2.465 1.896-4.463 4.234-4.463h19.608c2.338 0 4.233 1.998 4.233 4.463v10.376c0 2.464-1.895 4.463-4.233 4.463h-19.608c-2.338 0-4.234-1.999-4.234-4.463v-10.376z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M163.349 135.142h28.017v1.099h-28.017v-1.099zM163.349 132.945h28.017v1.099h-28.017v-1.099zM163.349 130.747h28.017v1.099h-28.017v-1.099z"
        fill="#494DBE"
      />
      <path
        d="M70.588 136.471h7.53a.94.94 0 01-.941.941H71.53a.94.94 0 01-.942-.941z"
        fill="#D53A79"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M162.671 201.184a5 5 0 00-2.255-6.702l-24.909-12.369a5.003 5.003 0 00-3.871-.242l-11.533 4.024a5 5 0 00-3.109 6.262l3.764 11.621a5 5 0 002.533 2.937l24.909 12.369a5 5 0 006.702-2.254l7.769-15.646zm-40.54-9.765a3.297 3.297 0 105.906 2.93 3.297 3.297 0 00-5.906-2.93z"
        fill="#878FEB"
      />
      <path
        d="M117.654 187.522c-1.89 2.352-.374 6.036 0 7.15l4.228-1.731c1.72-5.894-3.841-5.901-4.228-5.419z"
        fill="#424242"
        fill-opacity=".4"
      />
      <path
        d="M134.583 198.776l-.818 1.648 1.977.982c-.474 3.437 1.447 6.291 4.063 7.591 1.648.818 3.366.926 4.806.562l-.294-1.943c-1.11.373-2.406.192-3.622-.412-1.812-.9-2.979-2.584-2.852-4.754l3.647 1.81 1.621-1.249-4.882-2.424c.061-.226.194-.546.378-.917.143-.288.307-.566.471-.793l5.624 2.793 1.601-1.208-6.036-2.997c1.673-1.455 3.812-1.523 5.646-.612 1.03.511 1.844 1.198 2.32 2.102l1.612-1.177c-.6-1.12-1.662-2.032-3.042-2.718-2.637-1.309-6.175-1.165-8.637 1.361l-1.936-.961-.798 1.607 1.627.808a11.1 11.1 0 00-.45.803c-.133.268-.307.67-.399.907l-1.627-.809z"
        fill="#B5C9FF"
      />
      <path
        d="M118.646 185.535c2.651 1.078 5.97 4.342 4.361 6.2-1.527 1.763-4.854-5.039-4.361-6.2z"
        stroke="#03CFA5"
        stroke-width="2"
      />
      <path
        d="M105 133.823a3 3 0 013-3h13.765a3 3 0 013 3v10a3 3 0 01-3 3H108a3 3 0 01-3-3v-10z"
        fill="#BECFFF"
        fill-opacity=".5"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="223.53"
          y1="32.941"
          x2="54.589"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9D8FF" />
          <stop offset="1" stop-color="#C9D8FF" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="197.647"
          y1="19.294"
          x2="209.883"
          y2="125.177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E2EDFF" />
          <stop offset="1" stop-color="#E6EDF9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="4.706"
          y1="157.176"
          x2="238.118"
          y2="178.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#494DBE" />
          <stop offset="1" stop-color="#5F5DD2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="74.286"
          y1="136.997"
          x2="74.286"
          y2="156.947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#494DBE" />
          <stop offset="1" stop-color="#848CEA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="156.31"
          y1="95.548"
          x2="156.31"
          y2="157.027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BB6BD9" />
          <stop offset="1" stop-color="#BB6BD9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="131.039"
          y1="95.548"
          x2="131.039"
          y2="157.027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BB6BD9" />
          <stop offset="1" stop-color="#BB6BD9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="115.218"
          y1="112.685"
          x2="115.218"
          y2="157.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9BA5F1" />
          <stop offset="1" stop-color="#838BE9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="177.387"
          y1="137.883"
          x2="177.387"
          y2="157.184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BFD0FF" />
          <stop offset="1" stop-color="#A2BAFF" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<style lang="scss" module>
.base {
  svg {
    margin: 0 auto;
    transform: translateY(50px);
    display: block;
  }
}
</style>
