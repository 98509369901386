<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <router-view />

    <BaseHeading mb>
      {{ $t('messages_sms.heading') }}
    </BaseHeading>
    <BaseAlert
      v-if="currentBalance <= 0"
      color="warning"
      :text="$t('messages_sms.no_credits_warning')"
      mb
    />
    <div>
      <router-link
        :to="{
          name: 'messages-sms-template',
          params: {
            id: 'appointment_reminder_sms'
          }
        }"
      >
        <TemplateLink
          :heading="
            $t('messages_sms.templates.appointment_reminder_sms.heading')
          "
          v-test="'sms-template'"
        />
      </router-link>
    </div>
    <div class="mt-2 mb-2">
      <BaseText inline>
        {{ $t('messages_sms.suggestions.text') }}
      </BaseText>
      &nbsp;
      <BaseText :href="$t('messages_sms.suggestions.link')">
        {{ $t('messages_sms.suggestions.link_text') }}
      </BaseText>
    </div>
    <SmsCredits />
  </div>
</template>

<script setup lang="ts">
import SmsCredits from './sms-credits/index.vue';
import { useCompanyStore } from '@/stores/company';
import TemplateLink from '../TemplateLink.vue';

const { company } = useCompanyStore();
const currentBalance = ref(company.smsCredits || 0);
</script>

<style lang="scss" module>
.base {
  &:not(.smallScreen) {
    max-width: 500px;
    margin: $spacing auto 0;
  }
}
</style>
