<template>
  <Card>
    <AbsenceForm />
  </Card>
</template>

<script lang="ts">
import Card from './_shared/Card.vue';
import AbsenceForm from '../forms/AbsenceForm.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Card,
    AbsenceForm
  }
});
</script>
