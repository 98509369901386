<template>
  <div
    :class="[
      $style.base,
      {
        [$style.disabled]: disableClick
      }
    ]"
  >
    <component
      :is="disableClick ? 'div' : 'router-link'"
      v-for="(item, index) in items"
      :key="index"
      :to="
        disableClick
          ? null
          : { name: 'feedback-item', params: { feedbackId: item.id } }
      "
      :class="$style.item"
      v-test="'feedback-listItem'"
    >
      <div :class="$style.itemHeader">
        <Rating :score="item.score" :mr="0.5" />
        <div>
          <BaseHeading v-test="'feedback-listItem-customer'">
            {{ item.customer ? item.customer.fullName : '-' }}
          </BaseHeading>
          <BaseText size="s" v-test="'feedback-listItem-date'">
            {{ filters.date(item.submittedAt) }}
          </BaseText>
        </div>
      </div>
      <div v-if="item.comment" v-test="'feedback-listItem-comment'">
        <BaseText>
          {{ item.comment }}
        </BaseText>
      </div>
      <div :class="$style.icon">
        <BaseIcon
          v-if="item.state || item.twPublishedAt"
          size="s"
          :tooltip="{
            position: 'left',
            text: iconInfo(item.state, item.source).text
          }"
          :name="iconInfo(item.state, item.source).name"
        />
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import Rating from '@/components/Rating.vue';

import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { Feedback } from '@/types';
import { FeedbackSource } from '@/types';
import type { IconName } from '@/components/base-icon/types';

export default defineComponent({
  components: {
    Rating
  },
  props: {
    items: {
      type: Array as PropType<Feedback[]>,
      required: true
    },
    disableClick: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      filters
    };
  },
  methods: {
    iconInfo(state: string, source: FeedbackSource) {
      const info: { name: IconName; text: string } = {
        name: 'info',
        text: ''
      };
      if (source === FeedbackSource.Treatwell) {
        (info.name = 'treatwell'),
          (info.text = this.$t('feedback.received_treatwell'));
        return info;
      }
      switch (state) {
        case 'published':
          info.name = 'globe';
          info.text = this.$t('feedback.published');
          break;
        case 'allowed':
          info.name = 'unlock';
          info.text = this.$t('feedback.allowed');
          break;
        case 'disallowed':
          info.name = 'slash';
          info.text = this.$t('feedback.disallowed');
          break;
        case 'personal':
          info.name = 'lock';
          info.text = this.$t('feedback.personal');
          break;
        case 'requested':
          info.name = 'clock';
          info.text = this.$t('feedback.requested');
          break;
      }

      return info;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  padding: 0 $spacing;
}

.icon {
  position: absolute;
  top: $spacing;
  right: $spacing * 0.5;
}

.item {
  display: block;
  position: relative;
  padding: $spacing * 1.5 $spacing * 0.5;
  border-bottom: 1px solid $color-border;

  .base:not(.disabled) & {
    @include hover {
      background-color: $color-highlight;
    }

    &:focus {
      background-color: $color-highlight;
    }
  }
}

.itemHeader {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;
  padding-right: $spacing * 1.5;
}
</style>
