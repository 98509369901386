<template>
  <OnboardingLinkGeneration
    v-if="generatingLink"
    @close="generatingLink = false"
  />
  <Dashboard v-if="!!adyenAppState.adyenLegalEntityId" />
  <div
    v-else
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.mediumScreen]: $screen === 'm'
      }
    ]"
  >
    <div :class="$style.headerContainer">
      <div
        v-if="!adyenAppState.terminalModel && !showOnboarding"
        :class="$style.widthContainer"
      >
        <div :class="$style.heading">
          <BaseHeading size="xl">
            {{
              replacePinapparaat(
                $t('register.salonized_pay.landing.header'),
                company.country
              )
            }}
          </BaseHeading>
          <BaseText mt size="l">
            {{
              replacePinapparaat(
                $t('register.salonized_pay.landing.content'),
                company.country
              )
            }}
          </BaseText>
          <div class="mt-1">
            <BaseButton color="inverted" :mr="0.5" mt @click="scrollToVideo">
              {{ $t('register.salonized_pay.landing.watch_video') }}
            </BaseButton>
            <BaseButton
              mt
              @click="updateInterest"
              v-intercom="'sp-interested'"
              v-test="'sp-interested-button'"
            >
              {{ $t('register.salonized_pay.landing.get_sp') }}
            </BaseButton>
          </div>
        </div>
        <img src="/img/salonized-pay/salonized_pay.svg" :class="$style.image" />
      </div>

      <div
        v-else
        ref="onboardingRef"
        :class="$style.widthContainer"
        v-test="'sp-onboarding-section-with-tasks'"
      >
        <div :class="[$style.heading, $style.onboarding]">
          <BaseHeading size="xl">
            {{
              replacePinapparaat(
                $t('register.salonized_pay.landing.onboarding_header'),
                company.country
              )
            }}
          </BaseHeading>
          <BaseText mt size="l">
            {{ $t('register.salonized_pay.landing.onboarding_subheader') }}
            <BaseText link inline @click="scrollToFaq">
              {{ $t('register.salonized_pay.landing.faq_section_link') }}
            </BaseText>
          </BaseText>
        </div>
        <div :class="$style.onboardingTasks">
          <OnboardingTasks
            ref="onboardingExpose"
            @generateLink="generatingLink = true"
          />
        </div>
      </div>

      <div :class="$style.firstWave" />
    </div>

    <div :class="$style.pageContent">
      <div :class="$style.videoContainer">
        <InformationCard
          :heading="
            $t('register.salonized_pay.landing.video_section.how_it_works')
          "
          :title="$t('register.salonized_pay.landing.video_section.heading_1')"
          :description="
            $t('register.salonized_pay.landing.video_section.description_1')
          "
          padding
          :mr="$screen === 'l'"
        >
          <div ref="videoRef" :class="$style.videoWrap">
            <div :class="$style.videoWrapInner">
              <iframe
                :class="$style.video"
                :src="vimeoLink"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </InformationCard>
        <div :class="$style.informationContainer">
          <InformationCard
            :heading="
              $t('register.salonized_pay.landing.video_section.daily_payouts')
            "
            :title="
              $t('register.salonized_pay.landing.video_section.heading_2')
            "
            :description="
              $t('register.salonized_pay.landing.video_section.description_2')
            "
            mb
          >
            <img
              src="/img/salonized-pay/checkout/awaiting-transaction.svg"
              :class="$style.cardImg"
            />
          </InformationCard>
          <InformationCard
            :heading="$t('register.salonized_pay.landing.video_section.fee')"
            :title="
              $t('register.salonized_pay.landing.video_section.heading_3')
            "
            :description="
              $t('register.salonized_pay.landing.video_section.description_3')
            "
            reverseBg
            mb
          >
            <img
              src="/img/salonized-pay/checkout/successful-payment.svg"
              :class="$style.cardImg"
            />
          </InformationCard>
        </div>
      </div>
    </div>
    <div :class="$style.pricing">
      <Testimonial />
      <BaseHeading :mb="2" center>
        {{ $t('register.salonized_pay.landing.pricing_section.heading') }}
      </BaseHeading>
      <Pricing />
    </div>
    <PaymentMethods />
    <div :class="$style.stepsContainer">
      <div :class="$style.secondWave" />
      <Steps @getStarted="getStarted" />
      <TerminalsComparison @select="selectTerminal" />
      <Support />
      <div ref="faqRef" :class="$style.faqWrap">
        <BaseHeading :mb="2" center>
          {{ $t('register.salonized_pay.landing.faq') }}
        </BaseHeading>
        <div :class="$style.faq">
          <FAQ />
        </div>
      </div>
    </div>
  </div>
  <TerminalsModal
    v-if="showTerminalsModal"
    :terminalId="selectedTerminal"
    @close="showTerminalsModal = false"
    @save="saveTerminal"
  />
</template>

<script lang="ts" setup>
import Testimonial from './Testimonial.vue';
import Steps from './Steps.vue';
import Pricing from './Pricing.vue';
import Dashboard from './dashboard/index.vue';
import InformationCard from './InformationCard.vue';
import PaymentMethods from './PaymentMethods.vue';
import Support from './Support.vue';
import OnboardingLinkGeneration from './onboarding/OnboardingLinkGeneration.vue';

import { useCompanyStore } from '@/stores/company';
import { inject, ref, computed } from 'vue';
import { useAdyenStore } from '@/stores/adyen';
import OnboardingTasks from './onboarding/index.vue';
import FAQ from './FAQ.vue';
import TerminalsComparison from './TerminalsComparison.vue';
import TerminalsModal from './TerminalsModal.vue';
import { replacePinapparaat } from '@/helpers/formatting';

const { company, updateCompany } = useCompanyStore();
const { adyenAppState } = useAdyenStore();
const showOnboarding = ref(false);
const videoRef = ref();
const faqRef = ref();
const showTerminalsModal = ref(false);
const selectedTerminal = ref<number | undefined>();
const onboardingRef = ref();
const onboardingExpose = ref();
const generatingLink = ref(false);

const mixpanel = inject<any>('mixpanel');
mixpanel.track('Salonized Pay - Visited');

watch(
  () => adyenAppState.terminalModel,
  (newVal) => {
    if (newVal) {
      showOnboarding.value = true;
    }
  }
);

const saveTerminal = () => {
  showOnboarding.value = true;
  scrollToTop();
};

const vimeoLink = computed(
  () =>
    `https://player.vimeo.com/video/${company.country === 'de' ? '964553526' : '924692632'}?h=5fe6fd26f8&title=0&byline=0&portrait=0`
);

const scrollToVideo = () => {
  videoRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' });
  mixpanel.track('Salonized Pay - Watch video clicked');
};

const scrollToFaq = () => {
  faqRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

const scrollToTop = () => {
  onboardingRef.value.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

const updateInterest = () => {
  updateCompany({
    settings: {
      general: {
        salonizedPayInterest: true
      }
    }
  }).then(() => {
    showOnboarding.value = true;
    showTerminalsModal.value = true;
    mixpanel.track('Salonized Pay - Get started clicked', { cta: 'header' });
  });
};

const selectTerminal = (terminalId: number) => {
  mixpanel.track('Salonized Pay - Get started clicked', {
    cta: terminalId === 1 ? 'AMS1' : 'V400c'
  });
  selectedTerminal.value = terminalId;
  updateInterest();
  showTerminalsModal.value = true;
};

const getStarted = () => {
  updateInterest();
  showTerminalsModal.value = true;
  mixpanel.track('Salonized Pay - Get started clicked', { cta: 'steps' });
  scrollToTop();
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
  width: 100%;
  background-color: white;
}

.widthContainer {
  max-width: 950px;
  width: 100%;
  display: flex;
  height: 80vh;
  justify-content: space-between;
  align-items: center;

  .smallScreen & {
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing * 12;
  }

  .mediumScreen & {
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: $spacing * 4;
  }
}

.videoContainer {
  max-width: 950px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .smallScreen & {
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing * 5;
  }

  .mediumScreen & {
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacing * 5;
    height: 100%;
  }
}

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing;
  position: relative;

  &:not(.smallScreen) {
    height: 100%;
  }

  .mediumScreen & {
    height: 100%;
  }

  background-size: 100% 100%;
  background-position:
    0px 0px,
    0px 0px,
    0px 0px;
  background-image: linear-gradient(0deg, #dcdcf9d1 1%, #ffffff00 28%),
    radial-gradient(75% 75% at 88% -21%, #03cfa569 1%, #ffffff00 62%),
    radial-gradient(75% 75% at 50% 50%, #ffffffff 0%, #ffffffff 99%);
}

.interested {
  display: flex;
}

.informationContainer {
  .smallScreen & {
    margin-top: $spacing;
    width: 100%;
  }

  .mediumScreen & {
    display: flex;
    margin-top: $spacing;

    width: 100%;
  }
}

.videoWrap {
  width: 570px;
  max-width: 570px;

  scroll-margin-top: $spacing * 5;
}

.videoWrapInner {
  position: relative;
  width: 100%;
  padding-bottom: 56.4%;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.heading {
  width: 550px;
  max-width: 100%;

  &.onboarding {
    width: 800px;
  }

  .smallScreen & {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: $spacing;
  }

  .mediumScreen & {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: $spacing * 5;
  }
}

.image {
  max-width: 532px;
  max-height: 410px;
  width: 100%;
  height: auto;
  margin-left: $spacing * 2;

  .smallScreen & {
    margin-top: $spacing * 4;
    margin-left: 0px;
  }

  .mediumScreen & {
    margin-top: $spacing * 4;
    margin-left: 0px;
    padding-bottom: 100px;
  }
}

.firstWave {
  position: absolute;
  bottom: 0;
  background-image: url('/img/salonized-pay/rectangle2.svg');
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.pageContent {
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
  width: 100%;
}

.cardImg {
  height: 140px;
  width: 100px;
}

.pricing {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px 100px 0px;
  text-align: start;

  .smallScreen &,
  .mediumScreen & {
    padding: 0px $spacing 70px;
    text-align: center;
  }
}

.stepsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $spacing * 3;
}

.secondWave {
  position: absolute;
  top: 30px;
  background-image: url('/img/salonized-pay/rectangle2.svg');
  transform: scaleY(-1);
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.faqWrap {
  width: 100%;
  padding-top: $spacing * 2;
}

.faq {
  width: 100%;
  display: flex;
  justify-content: center;
}

.onboardingTasks {
  width: 100%;
  margin-left: $spacing * 4;

  .smallScreen & {
    margin-left: 0px;
  }

  .mediumScreen & {
    margin: $spacing * 2;
    margin-bottom: $spacing * 4;
  }
}
</style>
