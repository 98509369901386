<template>
  <div :class="$style.base">
    <BaseCard :mb="1.5">
      <div :class="$style.column">
        <BaseGrid v-if="Object.keys(summary).length" container>
          <BaseGrid
            v-if="hasFeatureFlag('module-customers')"
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.appointmentCount"
              :text="$t('customers.appointments')"
              v-test="'statsAppointments'"
            />
          </BaseGrid>
          <BaseGrid
            v-if="hasFeatureFlag('module-register')"
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.orderCount"
              :text="$t('customers.invoices')"
              v-test="'statsInvoices'"
            />
          </BaseGrid>
          <BaseGrid
            v-if="
              hasFeatureFlag('module-register') &&
              hasFeatureFlag('customer-loyalty-points')
            "
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.loyaltyPoints"
              :text="$t('customers.loyalty_points')"
              v-test="'statsLoyaltyPoints'"
            />
          </BaseGrid>
          <BaseGrid
            v-if="hasFeatureFlag('module-appointments')"
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.cancellationCount"
              :text="$t('customers.cancellations')"
              v-test="'statsCancellations'"
            />
          </BaseGrid>
          <BaseGrid
            v-if="hasFeatureFlag('module-appointments')"
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.noShowCount"
              :text="$t('customers.no_shows')"
              v-test="'statsNoShowCount'"
            />
          </BaseGrid>
          <BaseGrid
            v-if="
              hasFeatureFlag('module-register') &&
              hasFeatureFlag('module-reports')
            "
            :size="$screen === 's' ? 12 : null"
          >
            <Stat
              :value="summary.totalSpent"
              :text="$t('customers.total_spent')"
              sensitive
              currency
              v-test="'statsTotalSpent'"
            />
          </BaseGrid>
          <BaseGrid v-if="summary.totalDue" :size="$screen === 's' ? 12 : null">
            <Stat
              :value="summary.totalDue"
              :text="$t('customers.total_due')"
              sensitive
              currency
              v-test="'statsTotalDue'"
            />
          </BaseGrid>
        </BaseGrid>
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import Stat from './Stat.vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Stat
  },
  props: {
    summary: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag'])
  }
});
</script>

<style lang="scss" module>
.base {
  min-height: 88px;
}

.column {
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
</style>
