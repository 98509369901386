<template>
  <ModuleBase
    :fullHeight="fullHeight"
    :navItems="links"
    :loading="cashupEnabled && !cashupLoaded"
    :gray="
      $route.name === 'drawer-close' ||
      $route.name === 'cashup' ||
      $route.name === 'salonized-pay'
    "
    :noPadding="
      fullHeight ||
      $route.name === 'register-payment' ||
      $route.name === 'salonized-pay'
    "
    :disableIntercomPadding="
      $route.name === 'register-builder' || $route.name === 'register-payment'
    "
  />
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';
import { useUserStore } from '@/stores/user';
import unleash from '@/unleash';
import { useRegisterStore } from '@/modules/register/stores/register';

export default defineComponent({
  name: 'Register',
  components: {
    ModuleBase
  },
  setup() {
    const { cashupEnabled } = useRegisterStore();
    const { getDrawerStatus } = useRegisterDrawerStore();

    return {
      cashupEnabled,
      getDrawerStatus,
      unleash
    };
  },
  watch: {
    locationId: {
      handler() {
        if (this.cashupEnabled) {
          this.getDrawerStatus();
        }
      },
      immediate: true
    },
    '$route.name': {
      handler(newValue, oldValue) {
        // Redirect to checkout page when visiting the payment page directly

        if (newValue === 'register-payment' && !oldValue) {
          this.$router.replace({
            name: 'register-builder'
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['companySettings', 'company']),
    ...mapState(useRegisterDrawerStore, {
      cashupLoaded: 'isLoaded'
    }),
    hasSalonizedPay() {
      return unleash.isEnabled('SalonizedPay');
    },
    ...mapState(useResourcesStore, ['employees']),
    ...mapState(useLocationsStore, ['locationId']),
    fullHeight() {
      return (
        !!this.$route.matched.find(
          (route) =>
            route.name === 'register-builder' ||
            (route.name === 'register-payment' && this.$screen !== 's')
        ) || !this.hasFeatureFlag('module-register')
      );
    },
    links() {
      let links: { text: string; name: string; highlighted?: boolean }[] = [
        {
          text: this.$t('global.pages.builder'),
          name: 'register-builder'
        },
        {
          text: this.$t('global.pages.invoices'),
          name: 'invoices'
        },
        {
          text: this.$t('global.pages.day_overview'),
          name: 'day-overview'
        },
        {
          text: this.$t('global.pages.drawer'),
          name: 'drawer'
        }
      ];
      if (this.cashupEnabled) {
        links.push({
          text: this.$t('global.pages.cashups'),
          name: 'cashups'
        });
      }
      if (this.hasSalonizedPay) {
        links.push({
          text: this.$t('global.pages.salonized_pay'),
          name: 'salonized-pay',
          highlighted: unleash.isEnabled('SalonizedPay') ? true : false
        });
      }
      links = [
        ...links,
        {
          text: this.$t('global.pages.giftcards'),
          name: 'gift-cards'
        },
        {
          text: this.$t('global.pages.prepaid_cards'),
          name: 'prepaid-cards'
        }
      ];

      return links;
    }
  }
});
</script>
