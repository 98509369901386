<template>
  <div>
    <BaseRadio
      v-model="selectedValue"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />
    <div v-if="selectedValue === 'last_appointment_days_ago'">
      <BaseGrid container>
        <BaseGrid :size="3" v-test="'inputFrom'">
          <BaseInput
            v-model="days"
            type="number"
            @update:modelValue="emitValue"
          />
        </BaseGrid>
        <BaseGrid alignCenter>
          <BaseText>{{ $t('customers.filters.days_ago') }}</BaseText>
        </BaseGrid>
      </BaseGrid>
    </div>
    <div v-else v-test="'filterDate'">
      <BaseDatePicker
        v-model="dateBefore"
        :label="$t('customers.filters.before')"
        mb
        @update:modelValue="emitValue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedValue: 'last_appointment_days_ago',
      days: 1,
      dateBefore: dayjs().format(),
      filters: [
        {
          label: this.$t('customers.filters.select_days_amount'),
          value: 'last_appointment_days_ago'
        },
        {
          label: this.$t('customers.filters.select_before_date'),
          value: 'last_appointment_before_date'
        }
      ]
    };
  },
  methods: {
    emitValue() {
      let values = null;

      if (this.selectedValue === 'last_appointment_days_ago') {
        values = { days: this.days };
      }

      if (this.selectedValue === 'last_appointment_before_date') {
        values = { date: this.dateBefore };
      }

      const obj = {
        filter_name: this.selectedValue,
        values
      };
      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue();
  }
});
</script>
