<template>
  <component
    :is="useModal ? 'BaseModal' : 'div'"
    v-if="feedbackItem"
    :heading="$t('global.feedback')"
    :parentRoute="parentRouteName"
  >
    <div :class="$style.base">
      <BaseSpinner v-if="loading" />
      <div v-else :class="$style.content">
        <MainContent />
        <Appointment v-if="feedbackItem.appointment" />
      </div>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: 'FeedbackItem'
};
</script>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { GET_FEEDBACK } from '../graphql';
import { computed, provide } from 'vue';
import { useRoute } from 'vue-router';
import MainContent from './main-content/index.vue';
import Appointment from './Appointment.vue';
import { usePageLayoutStore } from '@/stores/page-layout';

defineProps<{
  parentRouteName: string;
}>();

const route = useRoute();

const feedbackId = computed(() =>
  typeof route.params.feedbackId === 'string'
    ? parseInt(route.params.feedbackId)
    : 0
);

const { result, loading } = useQuery(GET_FEEDBACK, {
  id: feedbackId
});

const feedbackItem = computed(() => result.value?.feedback || null);
provide('selected', feedbackItem);

const useModal = computed(() => {
  const { screenSize } = usePageLayoutStore();
  return screenSize === 's';
});
</script>

<style lang="scss" module>
.content {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
