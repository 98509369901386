<template>
  <ModuleBase noBorder gray>
    <PageWrap
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
      :heading="$t('admin.nav.links.general')"
    >
      <BaseCard :mb="2">
        <div :class="[$style.splitRow, $style.mobileFull]">
          <BaseDropdown
            v-model="formData.language"
            :label="$t('admin.general_settings.form.language')"
            :options="languageOptions"
            mb
            v-test="'general-settings-language'"
          />
          <BaseDropdown
            v-model="formData.currency"
            :label="$t('admin.general_settings.form.currency')"
            :options="currencyOptions"
            mb
            v-test="'general-settings-currency'"
          />
        </div>

        <div :class="[$style.splitRow, $style.mobileFull]">
          <BaseDropdown
            v-model="formData.timeZone"
            :label="$t('admin.general_settings.form.time_zone')"
            :options="timezoneOptions"
            mb
            v-test="'general-settings-time-zone'"
          />
          <div :class="[$style.splitRow, $style.mobileFull]">
            <BaseRadio
              v-model="formData.settings.locale.firstDayOfWeek"
              :label="$t('admin.general_settings.form.week_start')"
              :options="[
                {
                  label: $t('global.day_names.mon'),
                  value: 'MONDAY'
                },
                {
                  label: $t('global.day_names.sun'),
                  value: 'SUNDAY'
                }
              ]"
              mb
              v-test="'general-settings-week-starts-on'"
            />
            <BaseRadio
              v-model="formData.settings.locale.clock"
              :label="$t('admin.general_settings.form.time_format')"
              :options="[
                {
                  label: '13:15',
                  value: 'MILITARY'
                },
                {
                  label: '1:15 PM',
                  value: 'REGULAR'
                }
              ]"
              mb
              v-test="'general-settings-time-format'"
            />
          </div>
        </div>
      </BaseCard>

      <BaseCard :heading="$t('admin.user_accounts.modal.permissions.title')">
        <BaseCard mb gray>
          <BaseCheckbox
            v-model="formData.settings.general.allowImpersonation"
            :label="$t('admin.general_settings.form.allow_impersonation')"
            :description="$t('admin.general_settings.form.impersonation_hint')"
            v-test="'general-settings-allow-impersonation'"
          />
        </BaseCard>

        <BaseCard gray>
          <BaseCheckbox
            v-model="hasAllowedIps"
            :label="$t('admin.general_settings.form.ip_restriction')"
            :description="$t('admin.general_settings.form.ip_restriction_hint')"
            v-test="'general-settings-restrict-ips'"
          />
          <div v-if="hasAllowedIps" :class="$style.ipContainer">
            <div
              v-for="(ip, index) in formData.settings.general.ipRestrictions"
              :key="`${index}-allowed-ip`"
            >
              <div :class="$style.ipInputContainer">
                <BaseInput
                  v-model="formData.settings.general.ipRestrictions[index]"
                  :label="`IP #${index + 1}`"
                />
                <div
                  v-if="formData.settings.general.ipRestrictions.length > 1"
                  :class="$style.delete"
                >
                  <BaseIcon
                    name="close"
                    clickable
                    @click="removeIp(index)"
                    v-test="`general-settings-ip-${index + 1}-remove`"
                  />
                </div>
              </div>
            </div>
          </div>
          <BaseText
            v-if="hasAllowedIps"
            iconBefore="plus"
            link
            inline
            :mt="1.5"
            @click="addIp"
          >
            {{ $t('admin.general_settings.form.add_ip') }}
          </BaseText>
        </BaseCard>
      </BaseCard>

      <div :class="$style.buttonsContainer">
        <BaseText
          color="error"
          iconBefore="delete"
          link
          inline
          @click="handleDeleteAccountClick"
          v-test="'general-settings-delete-account'"
        >
          {{ $t('admin.general_settings.delete_account') }}
        </BaseText>
        <BaseButton
          :loading="isSubmitting"
          @click="submitForm"
          v-test="'general-settings-submit-button'"
        >
          {{ $t('global.actions.save') }}
        </BaseButton>
      </div>
    </PageWrap>
    <CancelSubscriptionModal
      v-if="showCancelSubscriptionModal"
      @close="showCancelSubscriptionModal = false"
    />
    <DeleteAccountModal
      v-if="showDeleteAccountModal"
      @close="showDeleteAccountModal = false"
    />
  </ModuleBase>
</template>

<script lang="ts" setup>
import type { Clock, FirstDayOfWeek, LocaleOptions, Currency } from '@/types';
import { CompanyLanguage } from '@/types';
import { ref, reactive, computed, watch } from 'vue';
import gql from 'graphql-tag';
import { modal, flash } from '@/helpers/ui';
import ModuleBase from '@/modules/ModuleBase.vue';
import { useCompanyStore } from '@/stores/company';
import { useQuery } from '@vue/apollo-composable';
import PageWrap from '../PageWrap.vue';
import { useI18n } from 'vue-i18n';
import DeleteAccountModal from './DeleteAccountModal.vue';
import CancelSubscriptionModal from './CancelSubscriptionModal.vue';

const { t } = useI18n();

const { company, updateCompany: updateCompanyMutation } = useCompanyStore();

const localeOptionsQuery = gql`
  query getLocaleOptions {
    localeOptions {
      currency
      timeZones
    }
  }
`;

const { result: localeOptionsResult } = useQuery<{
  localeOptions: LocaleOptions;
}>(localeOptionsQuery);

const currencyOptions = computed(
  () => localeOptionsResult.value?.localeOptions.currency || []
);
const timezoneOptions = computed(
  () => localeOptionsResult.value?.localeOptions.timeZones || []
);

const languageOptions = computed(() =>
  Object.keys(CompanyLanguage).map((key: string) => ({
    value: key.toUpperCase(),
    label: t(`global.languages.${key.toLocaleLowerCase()}`)
  }))
);

const hasAllowedIps = ref(company.settings.general.ipRestrictions.length > 0);
const showDeleteAccountModal = ref(false);
const showCancelSubscriptionModal = ref(false);

interface FormData {
  language: CompanyLanguage;
  currency: Currency;
  timeZone: string;
  settings: {
    locale: {
      clock: Clock;
      firstDayOfWeek: FirstDayOfWeek;
    };
    general: {
      allowImpersonation: boolean;
      ipRestrictions: string[];
    };
  };
}

const formData = reactive<FormData>({
  language: company.language.toUpperCase() as CompanyLanguage,
  currency: company.currency,
  timeZone: company.timeZoneName,
  settings: {
    locale: {
      clock: company.settings.locale.clock,
      firstDayOfWeek: company.settings.locale.firstDayOfWeek
    },
    general: {
      allowImpersonation: company.settings.general.allowImpersonation,
      ipRestrictions: company.settings.general.ipRestrictions
    }
  }
});
const isSubmitting = ref(false);

watch(hasAllowedIps, () => {
  if (
    hasAllowedIps.value &&
    formData.settings.general.ipRestrictions.length === 0
  ) {
    formData.settings.general.ipRestrictions.push('');
  }
});

const handleDeleteAccountClick = () => {
  if (company.activeSubscription) {
    showCancelSubscriptionModal.value = true;
  } else {
    showDeleteAccountModal.value = true;
  }
};

const addIp = () => {
  formData.settings.general.ipRestrictions.push('');
};

const removeIp = (index: number) => {
  formData.settings.general.ipRestrictions.splice(index, 1);
};

const updateCompany = () => {
  isSubmitting.value = true;
  updateCompanyMutation(formData)
    .then(() => {
      flash(t('global.flash.company_updated'));
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};

const submitForm = () => {
  if (!hasAllowedIps.value) {
    formData.settings.general.ipRestrictions = [];
  }

  formData.settings.general.ipRestrictions =
    formData.settings.general.ipRestrictions.filter((ip) => !!ip.length);

  if (formData.settings.general.ipRestrictions.length > 0) {
    modal('confirmation', {
      message: t('global.confirmation.confirm_proceed'),
      subMessage: t('admin.general_settings.confirmation.ip_restriction')
    }).then(() => {
      updateCompany();
    });
  } else {
    hasAllowedIps.value = false;
    updateCompany();
  }
};
</script>

<style lang="scss" module>
.splitRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
  }

  &.mobileFull {
    .base.smallScreen & {
      margin-bottom: 0;

      & > * {
        padding-right: 0;
        width: 100%;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing * 2;
}

.formActions > *:first-child {
  margin-right: $spacing;
}

.ipContainer {
  display: flex;
  gap: $spacing;
  margin-top: $spacing;
  flex-wrap: wrap;
}

.ipInputContainer {
  display: flex;
  margin-top: $spacing;
  align-items: center;
}

.delete {
  margin-top: 25px; // Input label height + margin
  margin-left: $spacing * 0.5;
}
</style>
