import gql from 'graphql-tag';

export const COMPLETE_TERMINAL_CHECKOUT = gql`
  mutation completeTerminalCheckout($input: CompleteTerminalCheckoutInput!) {
    completeTerminalCheckout(input: $input) {
      adyenShippingLocation {
        id
      }
    }
  }
`;

export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
  mutation createPayTerminalCheckoutSession(
    $input: CreatePayTerminalCheckoutSessionInput!
  ) {
    createPayTerminalCheckoutSession(input: $input) {
      url
      errors {
        message
      }
    }
  }
`;
