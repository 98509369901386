<template>
  <div>
    <BaseRadio
      v-model="selectedAutomatedOption"
      :options="[
        {
          label: $t('marketing.email.automated_emails.waiting_list.manual'),
          value: DispatchTypeEnum.Manual
        },
        {
          label: $t('marketing.email.automated_emails.waiting_list.automated'),
          value: DispatchTypeEnum.Automatic
        }
      ]"
      mb
      v-test="'waiting-list-when-to-send-email'"
    />
    <BaseAlert
      v-if="showAutomatedWaitingListAlert"
      :text="
        $t(
          'marketing.email.automated_emails.waiting_list.automated_description'
        )
      "
    />
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { DispatchTypeEnum } from '@/types';

const store = useStore();

const initialState =
  store.getters['marketing/dispatchType'] ?? DispatchTypeEnum.Manual;

const selectedAutomatedOption = ref<DispatchTypeEnum>(initialState);
const showAutomatedWaitingListAlert = computed(
  () => selectedAutomatedOption.value === DispatchTypeEnum.Automatic
);

watch(selectedAutomatedOption, (value) => {
  store.commit('marketing/SET_DISPATCH_TYPE', value);
});
</script>
