import type { Ref } from 'vue';

export type TrialTask = {
  claimed?: boolean;
  claimable?: boolean;
  completed: boolean;
  label?: string;
  labelColor?: 'info' | 'success' | 'warning' | 'error' | 'default' | 'primary';
  name: string;
  requiredTask?: string;
  text: string;
  visual?: string;
};

export type TrialTasks = Ref<TrialTask[]>;

export type TrialData = Ref<{
  completed?: {
    description: string;
    visual: string;
  };
  heading: string;
  icon: {
    dark: string;
    light: string;
  };
  link?: {
    routeName: string;
    secondary?: boolean;
    text: string;
  };
  remainingDays: number;
}>;

export enum ListName {
  Marketing = 'MARKETING',
  Onboarding = 'ONBOARDING',
  Treatwell = 'TREATWELL'
}

export type ReturnData = {
  data: TrialData;
  tasks: TrialTasks;
  loading?: Ref<boolean>;
  claimTask?: (taskName: string) => void;
  onTaskClick?: (taskName: string) => void;
};
