<template>
  <BaseCard gray mb>
    <BaseCheckbox
      v-model="formData.settings.reminders.smsReminderEnabled"
      :label="$t('admin.calendar.text_message_reminder_checkbox_label')"
      :description="
        $t('admin.calendar.text_message_reminder_checkbox_description')
      "
      v-test="'sms-reminder-checkbox'"
    />
    <BaseDropdown
      v-if="formData.settings.reminders.smsReminderEnabled"
      v-model="formData.smsReminderHours"
      :label="$t('admin.calendar.text_message_reminder_dropdown_label')"
      :options="
        reminderFrequencyOptions.map((value) => ({
          label: `${value} ${$t(`global.items.hour_short`, value)}`,
          value
        }))
      "
      mt
      v-test="'sms-reminder-time-select'"
    />
  </BaseCard>
</template>

<script setup lang="ts">
defineProps<{
  formData: any;
}>();
const reminderFrequencyOptions = [1, 2, 4, 8, 12, 24, 48, 72];
</script>
