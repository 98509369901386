<template>
  <BaseModalSmall @close="$emit('noClick')">
    <BaseHeading>{{ $t('new_import.warning') }}</BaseHeading>
    <template #footer>
      <BaseButton color="inverted" @click="$emit('noClick')">
        {{ $t('global.no') }}
      </BaseButton>
      <BaseButton class="ml" @click="handleExit">
        {{ $t('global.yes') }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script setup lang="ts">
import { inject } from 'vue';

const mixpanel = inject<any>('mixpanel');

const emit = defineEmits(['noClick', 'yesClick']);

const handleExit = () => {
  mixpanel.track('Onboarding tasks - Imports - Close Table');
  emit('yesClick');
};
</script>
