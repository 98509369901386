import { createStore } from 'vuex';
import config from '@/config';

import marketing from './modules/marketing/store';

export default createStore({
  strict: !config.isProduction,
  modules: {
    marketing
  }
});
