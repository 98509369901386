import gql from 'graphql-tag';
import { ref } from 'vue';
import apolloClient from '@/apollo/client';

import type { FormData } from './index';
import type {
  QueryAvailabilityArgs,
  AppointmentPartAvailability
} from '@/types';

const query = gql`
  query checkAvailability(
    $appointmentId: Int
    $locationId: ID
    $parts: [AppointmentPartAttributes!]
    $startAt: DateTime!
    $action: String
  ) {
    availability(
      appointmentId: $appointmentId
      locationId: $locationId
      parts: $parts
      startAt: $startAt
      action: $action
    ) {
      available
      appointmentPartAvailabilities {
        partId
        availableResourceIds
      }
    }
  }
`;

export const canCheckAvailability = (formData: FormData) =>
  !!formData.partsAttributes.length &&
  !formData.partsAttributes.find(
    (part) =>
      // allocation.requirementId can be null when a serviceId was added directly on the part
      !part.serviceId || !part.allocationsAttributes?.[0].requirementId
  );

export const useAvailabilities = () => {
  const isAppointmentAvailable = ref(false);
  const isFetchingAvailabilities = ref(false);
  const partAvailabilities = ref<AppointmentPartAvailability[]>([]);

  let debounceTimeout: ReturnType<typeof setTimeout>;

  const fetchAvailabilities = (formData: FormData, debounce = 0) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    isFetchingAvailabilities.value = true;

    return new Promise<void>((resolve) => {
      debounceTimeout = setTimeout(() => {
        // If the user adds a part very quickly, when the timeout has not finished yet, the partAttributes data is not valid
        // So we need to add an extra check here
        if (!canCheckAvailability(formData)) {
          isFetchingAvailabilities.value = false;
          resolve();
          return;
        }

        const variables: QueryAvailabilityArgs = {
          appointmentId: formData.id,
          locationId: formData.locationId?.toString(),
          parts: formData.partsAttributes,
          startAt: formData.startAt
        };

        apolloClient
          .query({ query, variables })
          .then(({ data: { availability } }) => {
            isAppointmentAvailable.value = availability.available;
            partAvailabilities.value =
              availability.appointmentPartAvailabilities;

            isFetchingAvailabilities.value = false;

            resolve();
          });
      }, debounce);
    });
  };

  const resetAvailabilityData = () => {
    isAppointmentAvailable.value = false;
    partAvailabilities.value = [];
  };

  return {
    isAppointmentAvailable,
    isFetchingAvailabilities,
    partAvailabilities,
    resetAvailabilityData,
    fetchAvailabilities
  };
};
