const Feedback = () => import('@/modules/feedback/index.vue');
const FeedbackItem = () => import('@/modules/feedback/feedback-item/index.vue');
const PublishBatch = () => import('@/modules/feedback/publish-batch/index.vue');

export default {
  path: 'feedback',
  name: 'feedback',
  component: Feedback,
  meta: {
    moduleName: 'feedback',
    role: 'manager'
  },
  children: [
    {
      path: 'publish',
      name: 'feedback-publish',
      component: PublishBatch,
      props: {
        parentRouteName: 'feedback'
      }
    },
    {
      path: ':feedbackId',
      name: 'feedback-item',
      component: FeedbackItem,
      props: {
        parentRouteName: 'feedback'
      }
    }
  ]
};
