const Dashboard = () => import('@/modules/dashboard/index.vue');
const Subscription = () => import('@/modules/dashboard/subscription/index.vue');
const StripeCheckout = () =>
  import('@/modules/dashboard/subscription/stripe-checkout/index.vue');
const TreatwellCheckout = () =>
  import('@/modules/dashboard/treatwell-checkout/index.vue');
const DashboardWaitingList = () =>
  import('@/modules/dashboard/DashboardWaitingList.vue');
const CreateEntryVue = () =>
  import('@/modules/_shared/waiting-list/CreateEntry.vue');
const WaitingListEntry = () =>
  import('@/modules/_shared/waiting-list/Entry.vue');
import { useUserStore } from '@/stores/user';
import { redirectRoute } from '@/router/helpers';
import { useCompanyStore } from '@/stores/company';

export default {
  path: 'dashboard',
  name: 'dashboard',
  component: Dashboard,
  meta: {
    moduleName: 'dashboard'
  },
  children: [
    {
      path: 'waiting-list',
      name: 'dashboard-waiting-list',
      component: DashboardWaitingList,
      props: { parentRouteName: 'dashboard' },
      meta: {
        featureFlag: 'waiting-list'
      },
      children: [
        {
          path: 'new',
          name: 'dashboard-waiting-list-new',
          component: CreateEntryVue,
          props: { parentRouteName: 'dashboard-waiting-list' }
        },
        {
          path: ':waitingListEntryId',
          component: WaitingListEntry,
          props: { parentRouteName: 'dashboard-waiting-list' }
        }
      ]
    },
    {
      path: 'subscription',
      name: 'subscription',
      beforeEnter: (to) => {
        const { user } = useUserStore();
        const { isTreatwellUser } = useCompanyStore();

        if (user && !user.owner) {
          return redirectRoute({
            name: 'dashboard',
            query: to.query
          });
        }

        if (isTreatwellUser) {
          return redirectRoute({
            name: 'tw-checkout',
            query: to.query
          });
        }

        if (to.name === 'subscription') {
          return redirectRoute({
            name: 'stripe-checkout',
            query: to.query
          });
        }
      },
      component: Subscription,
      meta: {
        showIntercomButton: true
      },
      children: [
        {
          path: 'checkout',
          name: 'stripe-checkout',
          beforeEnter: async () => {
            const { getUser } = useUserStore();
            await getUser();
          },
          component: StripeCheckout,
          meta: {
            showIntercomButton: true
          }
        }
      ]
    },

    {
      path: 'subscribe',
      name: 'tw-checkout',
      component: TreatwellCheckout,
      meta: {
        showIntercomButton: true
      }
    }
  ]
};
