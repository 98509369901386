<template>
  <div
    v-if="data"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.completed]: allCompleted,
        [$style.success]: showSuccessState,
        [$style.mobileMenuActive]: pageLayout.showMobileMenu
      }
    ]"
  >
    <div
      ref="iconEl"
      :class="$style.headerButton"
      @click="showPopover = !showPopover"
      v-test="'trial-tasks-icon'"
    >
      <div :class="[$style.headerButtonInner, $style.headerButtonIcon]">
        <img v-if="allCompleted" :src="data.icon.dark" />
        <img v-else :src="data.icon.light" />
      </div>
      <div :class="[$style.headerButtonInner, $style.headerButtonSuccess]">
        <BaseIcon color="inverted" name="check" />
      </div>
      <BaseNotification
        v-if="uncompletedAmount"
        :position="{ top: -4, right: -4 }"
        v-test="'trial-tasks-count'"
      >
        {{ uncompletedAmount }}
      </BaseNotification>
    </div>
    <transition>
      <div
        v-show="showPopover"
        ref="popoverEl"
        :class="$style.popover"
        v-test="'trial-tasks-popover'"
      >
        <div :class="$style.header">
          <BaseHeading>
            {{ data.heading }}
          </BaseHeading>
          <BaseIcon name="close" clickable @click="showPopover = false" />
        </div>
        <BaseSpinner v-if="loading" inline />
        <div
          v-else-if="allCompleted && data.completed"
          :class="$style.completedScreen"
        >
          <div v-if="data.completed.visual" :class="$style.completedVisual">
            <img :src="data.completed.visual" />
          </div>
          <BaseText>
            {{ data.completed.description }}
          </BaseText>
          <BaseButton
            v-if="data.link"
            :routerLink="{
              name: data.link.routeName
            }"
            mt
            @click="onLinkClick"
            v-test="'trial-tasks-completed-button'"
          >
            {{ data.link.text }}
          </BaseButton>
        </div>
        <div v-else>
          <div :class="$style.tasks">
            <BaseTaskOld
              v-for="(task, key) in tasks"
              :key="key"
              v-bind="task"
              :buttonText="
                task.claimable && !task.claimed
                  ? $t('onboarding.new_onboarding.claim')
                  : null
              "
              :loading="claimingTask === task.name"
              @click="onTaskClick(task)"
              @buttonClick="claimTask ? claimTask(task.name) : null"
              v-test="`task-${task.name}`"
            />
          </div>
          <div v-if="!isTreatwellUser || !isSubscribed" :class="$style.footer">
            <div>
              <BaseHeading size="s" :mb="0.25">
                {{ $t('onboarding.trial_left') }}
              </BaseHeading>
              <BaseHeading size="l" v-test="'trial-tasks-remaining'">
                {{
                  $t(
                    'onboarding.amount_days',
                    { amount: data.remainingDays },
                    data.remainingDays
                  )
                }}
              </BaseHeading>
            </div>
            <div v-if="data.link" :class="$style.footerLink">
              <component
                :is="data.link.secondary ? 'BaseText' : 'BaseButton'"
                :routerLink="{
                  name: data.link.routeName
                }"
                @click="onLinkClick"
                v-test="'trial-tasks-link'"
              >
                {{ data.link.text }}
              </component>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, inject } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { storeToRefs } from 'pinia';

import onboardingData from './data/onboarding';
import treatwellData from './data/treatwell';
import marketingData from './data/marketing';

import type { ReturnData, TrialTask } from './types';
import { ListName } from './types';
import eventBus from '@/event-bus';
import { usePageLayoutStore } from '@/stores/page-layout';

import BaseTaskOld from '@/components/_deprecated/BaseTaskOld.vue';

import { useCompanyStore } from '@/stores/company';
const { isSubscribed, isTreatwellUser } = storeToRefs(useCompanyStore());

const mixpanel = inject<any>('mixpanel');

const props = defineProps<{
  listName: ListName;
}>();

const showPopover = ref(false);
const popoverEl = ref();
const iconEl = ref();

const pageLayout = usePageLayoutStore();

onClickOutside(
  popoverEl,
  () => {
    showPopover.value = false;
  },
  {
    ignore: [iconEl]
  }
);

const getData = (): ReturnData => {
  switch (props.listName) {
    case ListName.Onboarding:
      return onboardingData();
    case ListName.Treatwell:
      return treatwellData();
    case ListName.Marketing:
      return marketingData();
  }
};

const {
  data,
  tasks,
  loading,
  claimTask: _claimTask,
  onTaskClick: _onTaskClick
} = getData();

const onTaskClick = (task: TrialTask) => {
  if (task.requiredTask) {
    const requiredTask = tasks.value.find(
      (_task) => _task.name === task.requiredTask
    );
    if (requiredTask && !requiredTask.completed) {
      return;
    }
  }

  if (_onTaskClick) {
    mixpanel.track('Trial tasks - Click task', {
      task: task.name,
      type: props.listName
    });

    _onTaskClick(task.name);
    showPopover.value = false;
  }
};

const allCompleted = computed(
  () => !tasks.value.find((task) => !task.completed)
);
const completedAmount = computed(
  () => tasks.value.filter((task) => task.completed).length
);
const uncompletedAmount = computed(
  () => tasks.value.length - completedAmount.value
);
const claimedAmount = computed(
  () => tasks.value.filter((task) => task.claimed).length
);

const claimingTask = ref('');

const claimTask = (taskName: string) => {
  if (_claimTask) {
    mixpanel.track('Trial tasks - Reward claimed', {
      task: taskName,
      type: props.listName
    });

    _claimTask(taskName);
    claimingTask.value = taskName;
  }
};

watch(claimedAmount, () => {
  claimingTask.value = '';
});

const showSuccessState = ref(false);
const onTaskCompleted = () => {
  showSuccessState.value = true;

  setTimeout(() => {
    showSuccessState.value = false;
  }, 2000);

  sortTasks();
};

const sortTasks = () => {
  tasks.value = [...tasks.value].sort(
    (a, b) => (b.completed as any) - (a.completed as any)
  );
};

if (loading) {
  watch([loading, completedAmount], ([newLoading], [prevLoading]) => {
    if (newLoading !== prevLoading) {
      return;
    }

    onTaskCompleted();
  });

  watch(loading, (loading) => {
    if (!loading) {
      sortTasks();
    }
  });
} else {
  sortTasks();
  watch(completedAmount, () => onTaskCompleted());
}

const onLinkClick = () => {
  mixpanel.track('Trial tasks - Link clicked', {
    type: props.listName
  });

  showPopover.value = false;
};

eventBus.$on('update-task', (taskName: string) => {
  const task = tasks.value.find((task) => task.name === taskName);
  if (!task || task.completed) {
    return;
  }

  task.completed = true;
  task.labelColor = 'success';

  mixpanel.track('Trial tasks - Task completed', {
    task: taskName
  });
});

eventBus.$on('open-task-menu', () => {
  showPopover.value = true;
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popover {
  position: absolute;
  background-color: white;
  border-radius: $radius;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  width: 380px;
  max-width: calc(100vw - #{$spacing});

  .base:not(.smallScreen) & {
    right: -6px;
    top: 52px;
  }

  .base.smallScreen & {
    right: -50px;
    top: 60px;
  }

  .base.mobileMenuActive & {
    max-width: calc(100vw - #{$spacing + 50px});
  }

  &:global(.v-enter-active) {
    transition: transform 0.1s $easeOutBack;
  }

  &:global(.v-leave-active) {
    transition: opacity 0.06s;
  }

  &:global(.v-enter-from) {
    transform: scale(0.95);
  }

  &:global(.v-leave-to) {
    opacity: 0;
  }

  $arrowSize: 6px;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    right: $spacing;
    top: $arrowSize * -1;
    border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;
    border-bottom: $arrowSize solid white;
    z-index: 1;
  }

  &:after {
    right: $spacing - 1px;
    top: ($arrowSize + 1px) * -1;
    border-left: $arrowSize + 1px solid transparent;
    border-right: $arrowSize + 1px solid transparent;
    border-bottom: $arrowSize + 1px solid rgba(0, 0, 0, 0.05);
  }

  .base.smallScreen & {
    &:before {
      right: 59px;
    }

    &:after {
      right: 58px;
    }
  }
}

.headerButton {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;

  .base:not(.completed) & {
    background-color: $yellow;
  }

  .base.completed & {
    background-color: white;
    border: 1px solid $color-border-input;
  }

  .base.success & {
    background-color: $color-success;
  }
}

.headerButtonInner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition:
    opacity 0.2s ease-out,
    transform 0.2s ease-out;
}

.headerButtonIcon {
  .base.success & {
    transform: scale(0.5);
    opacity: 0;
  }
}

.headerButtonSuccess {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;

  .base:not(.success) & {
    transform: scale(0.5);
    opacity: 0;
  }

  .base.success & {
    transition-delay: 0.1s;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing $spacing $spacing * 0.5 $spacing;
}

.tasks {
  padding: $spacing * 0.5 $spacing $spacing $spacing;
  max-height: 302px;
  overflow-y: auto;

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.completedScreen {
  padding: $spacing $spacing $spacing * 2;
  text-align: center;
}

.completedVisual {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: $spacing;

  img {
    max-width: 50%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  border-top: 1px solid $color-border;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

.footerLink {
  flex-shrink: 0;
}
</style>
