<template>
  <RangedReports
    :reportData="reportData"
    :reportTotals="reportTotals"
    :chartData="chartData"
    :currentRange="currentRange"
    :startDate="startDate"
    :loading="$apollo.loading"
    day
    @change="onRangeChange"
  />
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import RangedReports from '../components/RangedReports.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    RangedReports
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      dailyReport: {},
      reportTotals: {},
      reportData: [],
      chartTotals: [],
      chartOrders: [],
      startDate: dayjs().format(),
      chartLabels: []
    };
  },
  apollo: {
    dailyReport: {
      query: gql`
        query dailyReport(
          $startDate: DateTime
          $locationId: ID
          $dataScope: DataScope
        ) {
          dailyReport(
            startDate: $startDate
            locationId: $locationId
            dataScope: $dataScope
          ) {
            data {
              date
              bank
              cash
              coupon
              creditcard
              date
              discount
              giftcard
              online
              orders
              pin
              prepaidCard
              total
              totalInc
              transactions
              treatwellOnlinePayment
              vat
              pos
              other
            }
            chartData {
              orders
              totals
            }
            totals {
              total
              totalBank
              totalCash
              totalCoupon
              totalCreditcard
              totalGiftcard
              totalInc
              totalOnline
              totalOrders
              totalPin
              totalPrepaidCard
              totalTransactions
              totalTreatwellOnlinePayment
              totalPos
              totalOther
              totalVat
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          locationId: this.locationId,
          dataScope: this.dataScope
        };
      },
      result() {
        this.reportTotals = this.dailyReport.totals;
        this.reportData = this.dailyReport.data;
        this.chartTotals = this.dailyReport.chartData?.totals || [];
        this.chartOrders = this.dailyReport.chartData?.orders || [];
        this.chartLabels =
          this.getLabels(this.dailyReport.chartData?.totals) || [];
      }
    }
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    currentRange() {
      const range = dayjs(this.startDate).format();
      return this.filters.date(range, { format: 'monthYear' });
    },
    chartData() {
      return {
        datasets: [
          {
            type: 'line',
            label: this.$t('reports.ranges.orders'),
            backgroundColor: '#10B796',
            borderColor: '#10B796',
            data: this.chartOrders,
            xAxisID: 'x0',
            yAxisID: 'y0'
          },
          {
            type: 'bar',
            label: this.$t('reports.ranges.turnover'),
            data: this.chartTotals,
            backgroundColor: '#262973',
            xAxisID: 'x0',
            yAxisID: 'y1',
            barPercentage: 0.5
          }
        ],
        labels: this.chartLabels
      };
    }
  },
  methods: {
    getLabels(totals) {
      return [...Array(totals.length)].map((_, index) => {
        const date = dayjs(this.startDate)
          .startOf('month')
          .add(index, 'days')
          .format();
        return this.filters.date(date, { format: 'monthDay' });
      });
    },
    onRangeChange(date) {
      this.startDate = date.format();
    }
  }
});
</script>
