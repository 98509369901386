<template>
  <div :class="{ [$style.isMedium]: $screen === 'm' }">
    <BaseGrid v-if="stockOrder" container>
      <BaseGrid :size="$screen === 'm' ? 12 : 7">
        <Notes v-if="stockOrder && stockOrder.notes" :stockOrder="stockOrder" />
      </BaseGrid>
      <BaseGrid
        :class="$style.actions"
        :size="$screen === 'm' ? 12 : 5"
        alignRight
      >
        <DetailsActions
          :hasDeletedItems="hasDeletedItems"
          :status="stockOrder.status"
          :stockOrderId="stockOrder.id"
          :supplierEmail="stockOrder.supplier ? stockOrder.supplier.email : ''"
        />
      </BaseGrid>
    </BaseGrid>
    <BaseAlert
      v-if="hasDeletedItems"
      :text="$t('products.deleted_products_info')"
      mt
      color="error"
    />
    <ProductsOrderedList
      v-if="stockOrder && stockOrder.orderItems"
      :stockOrder="stockOrder"
      :totalAmount="totalAmount"
    />
  </div>
</template>

<script lang="ts">
import ProductsOrderedList from './ProductsOrderedList.vue';
import Notes from './Notes.vue';
import DetailsActions from './DetailsActions.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ProductsOrderedList,
    Notes,
    DetailsActions
  },
  props: {
    stockOrder: {
      type: Object
    },
    totalAmount: {
      type: Number,
      required: true
    }
  },
  computed: {
    hasDeletedItems() {
      if (this.stockOrder && this.stockOrder.orderItems) {
        return !this.stockOrder.orderItems.every((item) => item.product);
      }

      return false;
    }
  }
});
</script>

<style lang="scss" module>
.actions {
  .isMedium & {
    order: 1 !important;
  }
}
</style>
