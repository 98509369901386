<template>
  <div v-if="!isLoading">
    <BaseForm @submit="submit">
      <BaseInput
        v-model="formData.text"
        required
        :label="$t('global.description')"
        type="textarea"
        :focus="!isEditing && !device.touch"
        mb
        v-test="'note-description'"
      />
      <BaseDatePicker
        v-model="formData.date"
        :label="$t('global.date')"
        mb
        v-test="'note-date'"
      />
      <AppliesTo
        v-model="appliesTo"
        v-model:id="formData.resourceId"
        :options="['company', 'employee']"
        :mb="2"
      />
      <Footer>
        <BaseButton submitForm :loading="isSaving" v-test="'note-submit'">
          {{ $t('global.actions.save') }}
        </BaseButton>
      </Footer>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import { noteFragment } from '@/graphql-fragments';
import { defineComponent, ref } from 'vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import AppliesTo from './_shared/AppliesTo.vue';
import Footer from './_shared/Footer.vue';
import { createNote, updateNote } from '@/modules/calendar/actions/notes';
import { device } from '@/user-context';
import { useQuery } from '@vue/apollo-composable';

export default defineComponent({
  components: {
    AppliesTo,
    Footer
  },
  setup() {
    const route = useRoute();
    const id =
      typeof route.params.id === 'string' ? parseInt(route.params.id) : null;
    const { noteData: formData } = useCreateEventStore();

    const isEditing = ref(!!id);
    const isLoading = ref(false);
    const isSaving = ref(false);

    if (id) {
      isLoading.value = true;
      const { onResult } = useQuery(
        gql`
          query getNote($id: Int!) {
            note(id: $id) {
              ...note
            }
          }
          ${noteFragment}
        `,
        { id }
      );

      onResult(({ data: { note } }) => {
        Object.keys(formData).forEach((key) => {
          formData[key] = note[key];
        });
        formData.id = note.id;
        isLoading.value = false;
      });
    }

    return {
      formData,
      isEditing,
      isLoading,
      isSaving,
      appliesTo: ref(formData.resourceId ? 'employee' : 'company'),
      v$: useVuelidate(),
      device
    };
  },
  validations() {
    return {
      formData: {
        text: {
          required
        },
        resourceId: {
          required: requiredIf(this.appliesTo === 'employee')
        }
      }
    };
  },
  watch: {
    isLoading(value) {
      if (!value && this.formData.resourceId) {
        this.appliesTo = 'employee';
      }
    }
  },
  methods: {
    submit() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.isSaving = true;

      (this.isEditing ? this.update : this.create)().then(() => {
        this.afterMutationFinish();
      });
    },
    update() {
      return updateNote(this.formData);
    },
    create() {
      return createNote(this.formData);
    },
    afterMutationFinish() {
      this.$router.push({ name: 'calendar' });
    }
  }
});
</script>
