<template>
  <div :class="$style.base">
    <BaseHeading border>
      {{ $t('products.stock_history') }}
    </BaseHeading>
    <BaseScroll
      v-if="stockMutations && stockMutations.length"
      :contentWidth="700"
    >
      <BaseTableRow head>
        <BaseTableCell :width="30">
          {{ $t('global.date') }}
        </BaseTableCell>
        <BaseTableCell :width="20">
          {{ $t('global.amount') }}
        </BaseTableCell>
        <BaseTableCell>
          {{ $t('global.reason') }}
        </BaseTableCell>
      </BaseTableRow>

      <BaseTableRow
        v-for="item in stockMutations"
        :key="item.id"
        v-test="'stockMutations'"
      >
        <BaseTableCell :width="30">
          {{ filters.dateTime(item.createdAt) }}
        </BaseTableCell>
        <BaseTableCell :width="20">
          <BaseText :color="item.amount < 0 ? 'error' : 'success'">
            {{ item.amount }}
          </BaseText>
        </BaseTableCell>
        <Reason :item="item" />
      </BaseTableRow>
    </BaseScroll>
    <NoResults v-if="hasNoResults" />
    <Pagination
      :items="stockMutations"
      :loading="$apollo.loading"
      fieldName="stockMutations"
      :apolloQuery="$apollo.queries.stockMutations"
      :extraVariables="stockMutationVariables"
      :mb="hasNoResults ? 2 : null"
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import NoResults from '../NoResults.vue';
import Pagination from '@/components/_deprecated/Pagination.vue';
import Reason from './Reason.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NoResults,
    Pagination,
    Reason,
    BaseTableRow,
    BaseTableCell
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      stockMutations: []
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
    hasNoResults() {
      return !this.$apollo.loading && !this.stockMutations.length;
    },
    stockMutationVariables() {
      return {
        locationId: this.locationId,
        productId: this.$route.params.id
      };
    }
  },
  apollo: {
    stockMutations() {
      return {
        query: gql`
          query getStockMutations(
            $locationId: ID
            $productId: ID!
            $pagination: PaginationAttributes
          ) {
            stockMutations(
              locationId: $locationId
              productId: $productId
              pagination: $pagination
            ) {
              id
              amount
              createdAt
              reason
              stockOrderId
              stockOrderStatus
              originalOrderId
              orderNumber
              orderId
              type
            }
          }
        `,
        variables: {
          pagination: {
            currentPage: 1,
            perPage: 25
          },
          ...this.stockMutationVariables
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
      };
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  min-height: 200px;
}
</style>
