<template>
  <div :class="[$style.base, { [$style.danger]: danger }]">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    danger: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" module>
.base {
  background: #f4f9fb;
  padding: $spacing;

  &.danger {
    background: #fbdae0;
  }
}
</style>
