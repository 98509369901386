<template>
  <Link
    ref="el"
    :defaultElement="submitForm ? 'button' : 'a'"
    :routerLink="routerLink"
    :href="href"
    :class="[
      $style.base,
      $style[size],
      $style[color],
      {
        [$style.isDisabled]: disabled,
        [$style.isSelected]: selected,
        [$style.isLoading]: loading,
        [$style.fullWidth]: fullWidth,
        [$style.mobile]: $screen === 's'
      }
    ]"
    @click="onClick"
  >
    <div :class="$style.inner">
      <BaseSpinner
        v-if="loading"
        :color="color === 'inverted' ? 'default' : 'inverted'"
      />
      <div v-if="icon || href" :class="$style.icon">
        <BaseIcon
          :name="href ? 'external_link' : icon"
          :color="color === 'inverted' ? 'default' : 'inverted'"
        />
      </div>
      <div :class="$style.content">
        <slot />
      </div>
      <transition name="pop-small">
        <div v-if="notification" :class="$style.notification">
          {{ notification }}
        </div>
      </transition>
    </div>
    <Popover
      v-if="options && options.length && mounted"
      :visible="showPopover"
      :options="options"
      :parentEl="el"
      @select="$emit('select', $event)"
      @close="showPopover = false"
    />
  </Link>
</template>

<script lang="ts">
export default {
  name: 'BaseButton',
  inheritAttrs: false
};
</script>

<script setup lang="ts">
import Link from '@/components/_shared/Link.vue';
import type { IconName } from '@/components/base-icon/types';
import type { LocationQueryRaw } from 'vue-router';
import Popover from './Popover.vue';
import { onMounted, ref, watch } from 'vue';
import type { Option } from './types';

interface BaseButtonProps {
  color?: 'primary' | 'warning' | 'inverted' | 'error';
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: IconName;
  loading?: boolean;
  notification?: number;
  options?: Option[];
  routerLink?: LocationQueryRaw | {} | string;
  selected?: boolean;
  size?: 'm' | 'l';
  submitForm?: boolean;
  autoWidth?: boolean;
}

const emit = defineEmits(['click', 'select']);
const props = withDefaults(defineProps<BaseButtonProps>(), {
  color: 'primary',
  size: 'm'
});

const el = ref();
const showPopover = ref(false);

const onClick = () => {
  emit('click');

  if (props.options?.length) {
    showPopover.value = !showPopover.value;
  }
};

watch(
  () => props.disabled,
  () => {
    showPopover.value = false;
  }
);

const mounted = ref(false);
onMounted(() => (mounted.value = true));
</script>

<style lang="scss" module>
.base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 42px;
  min-height: $input-height;
  border-radius: $radius;
  cursor: pointer;
  white-space: nowrap;
  transition:
    background-color 0.1s ease,
    border-color 0.1s ease;
  user-select: none;
  text-decoration: none;
  flex-shrink: 0;
  gap: $spacing * 0.25;

  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.primary {
    background-color: $color-primary;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @include hover {
      background-color: $color-primary-dark;
    }
  }

  &.m {
    padding: 10px $spacing;
  }

  &.l {
    padding: 16px 22px;
  }

  &.inverted {
    background-color: $white;
    border: 1px solid $color-border-input;

    @include hover {
      background-color: $color-highlight;
    }

    &.isDisabled {
      background-color: $white;
    }

    &.isSelected {
      background-color: $color-highlight;
      border-color: $color-primary;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.isLoading {
    pointer-events: none;
    color: transparent;
  }

  &.warning {
    background-color: $color-warning;
    border-color: $color-warning;
    color: $color-text-inverted;

    @include hover {
      background-color: $color-warning-dark;
      border-color: $color-warning-dark;
    }
  }

  &.error {
    background-color: $color-error;
    border-color: $color-error;
    color: $color-text-inverted;

    @include hover {
      background-color: $color-error-dark;
      border-color: $color-error-dark;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  text-align: center;

  .base.primary & {
    color: $color-text-inverted;

    @include hover {
      color: $color-text-inverted;
    }
  }

  .base:not(.inverted) & {
    font-weight: bold;
  }

  .base.inverted & {
    color: $color-text;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 -6px;

  & + .content:not(:empty) {
    margin-left: 12px;
  }
}

.notification {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: $spacing * 0.5;
  font-size: 12px;
  font-weight: bold;

  .base.primary & {
    color: $color-primary;
    background-color: white;
  }

  .base:not(.primary) & {
    color: white;
    background-color: $color-primary;
  }
}

.content:empty + .notification {
  margin-right: -6px;
}

.content {
  &:empty {
    display: none;
  }

  .base.l:not(.mobile):not(.fullWidth) & {
    min-width: 209px;
  }
}

.content,
.icon {
  .base.isLoading & {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
