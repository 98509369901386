<template>
  <BaseModal
    :heading="$t('drawer.calculate_total_cash')"
    small
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseInput
          v-for="(item, index) in coins"
          :key="`item-left-${index}`"
          v-model="item.amount"
          :label="filters.currency(item.value)"
          type="number"
          mb
          v-test="`count-cash-input-${item.value}`"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseInput
          v-for="(item, index) in notes"
          :key="`item-right-${index}`"
          v-model="item.amount"
          :label="filters.currency(item.value)"
          type="number"
          mb
          v-test="`count-cash-input-${item.value}`"
        />
      </BaseGrid>
    </BaseGrid>
    <div v-test="'count-cash-total'">
      {{ $t('drawer.total_counted') }}: {{ filters.currency(total) }}
    </div>
    <div>
      <BaseButton mt @click="submit" v-test="'count-cash-submit'">
        {{ $t('drawer.use_calculated_total') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    cashCount: {
      type: Array,
      required: true
    }
  },
  emits: ['close', 'submit'],
  setup() {
    return {
      filters
    };
  },
  computed: {
    coins() {
      return this.cashCount.filter((item) => item.moneyType === 'COIN');
    },
    notes() {
      return this.cashCount.filter((item) => item.moneyType === 'PAPER');
    },
    total() {
      return this.cashCount.reduce(
        (total, item) => total + item.value * item.amount,
        0
      );
    }
  },
  methods: {
    submit() {
      this.$emit('submit', {
        cashCount: this.cashCount,
        total: this.total
      });
      this.$emit('close');
    }
  }
});
</script>
