<template>
  <BaseCard gray mb>
    <BaseCheckbox
      v-model="formData.settings.reminders.emailReminderEnabled"
      :label="$t('admin.calendar.email_reminder_checkbox_label')"
      :description="$t('admin.calendar.email_reminder_checkbox_description')"
      v-test="'email-reminder-checkbox'"
    />
    <BaseDropdown
      v-if="formData.settings.reminders.emailReminderEnabled"
      v-model="formData.emailReminderHours"
      :label="$t('admin.calendar.email_reminder_dropdown_label')"
      :options="
        reminderFrequencyOptions.map((value) => ({
          label: `${value} ${$t(`global.items.hour_short`, value)}`,
          value
        }))
      "
      mt
      v-test="'email-reminder-time-select'"
    />
  </BaseCard>
</template>

<script setup lang="ts">
defineProps<{
  formData: any;
}>();
const reminderFrequencyOptions = [1, 2, 4, 8, 12, 24, 48, 72];
</script>
