<template>
  <div
    v-if="formData.plan !== 'appointments'"
    :class="[
      $style.base,
      {
        [$style.mediumScreen]: $screen === 'm',
        [$style.largeScreen]: $screen === 'l'
      }
    ]"
  >
    <BaseText bold mb>
      {{ $t('subscription.terminals.heading') }}
    </BaseText>
    <BaseAlert
      size="s"
      :text="$t('subscription.terminals.discount', { code: 'WELCOME30' })"
      mb
    />
    <BaseCard gray noPadding>
      <div :class="$style.fee">
        <BaseText>
          {{ $t('subscription.terminals.fee') }}
        </BaseText>
        <BaseHeading> 1% </BaseHeading>
      </div>
    </BaseCard>
    <div :class="$style.terminals">
      <div
        v-for="(terminal, index) in terminals"
        :key="`terminal-${index}`"
        :class="$style.terminal"
        @click="
          () => {
            $emit('select', terminal.key);
            mixpanel.track('Subscription flow - Terminal selected', {
              choice: terminal.key
            });
          }
        "
        v-test="'subscription-terminal'"
      >
        <div :class="$style.terminalInner">
          <div :class="$style.header">
            <BaseText>
              {{ terminal.key }}
            </BaseText>
            <BaseText bold>
              {{ filters.currency(terminal.price) }}
            </BaseText>
            <div :class="$style.icon">
              <BaseIcon name="plus" color="inverted" size="xs" />
            </div>
          </div>
          <div :class="$style.terminalContent">
            <div :class="$style.visual">
              <img :src="`/img/salonized-pay/terminal${index + 1}.png`" />
            </div>
            <div :class="$style.terminalInfo">
              <BaseText v-for="info in terminal.info" :key="info" size="s">
                {{
                  $t(
                    `register.salonized_pay.landing.terminals.modal.info.${info}`
                  )
                }}
              </BaseText>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { terminals } from '@/modules/register/salonized-pay/terminals';
import filters from '@/filters';
import { useSubscriptionStore } from './store';

defineEmits(['select']);

const mixpanel = inject<any>('mixpanel');

const { formData } = useSubscriptionStore();
</script>

<style lang="scss" module>
.base {
  margin-top: $spacing * 3;
}

.terminals {
  margin-top: $spacing;

  .base:not(.mediumScreen) & {
    display: flex;
    justify-content: space-between;
  }
}

.terminal {
  position: relative;
  padding: $spacing * 0.5;
  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.2s ease;

  .base:not(.mediumScreen) & {
    width: calc(50% - #{$spacing * 0.25});
  }

  .base.mediumScreen & {
    &:not(:last-child) {
      margin-bottom: $spacing * 0.5;
    }
  }

  @include hover {
    .icon,
    .visual {
      transform: scale(1.2);
    }

    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(#d4deff, #ffffff);
    transition: opacity 0.2s ease;
    opacity: 0.5;
  }
}

.terminalInner {
  position: relative;
  z-index: 1;
}

.icon,
.visual {
  transition: transform 0.2s ease;
}

.header {
  position: relative;

  .base:not(.largeScreen) & {
    margin-bottom: $spacing;
  }
}

.icon {
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: $color-primary;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visual {
  display: flex;
  justify-content: center;

  .base.largeScreen & {
    padding: $spacing;
  }

  .base:not(.largeScreen) & {
    padding: $spacing * 0.5;
  }

  img {
    width: auto;

    .base.largeScreen & {
      max-height: 75px;
    }

    .base:not(.largeScreen) & {
      max-height: 64px;
    }
  }
}

.terminalInfo {
  .base.largeScreen & {
    text-align: center;
  }

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.25;
  }
}

.terminalContent {
  .base:not(.largeScreen) & {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing;
  }
}

.fee {
  display: flex;
  justify-content: space-between;
  gap: $spacing * 0.5;
  padding: $spacing * 0.5;
}
</style>
