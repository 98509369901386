<template>
  <BaseModal
    :heading="$t('marketing.discounts.about.title')"
    small
    @close="$emit('close')"
  >
    <BaseText>
      {{ $t('marketing.discounts.enable-last-minute-discount-modal.info') }}
    </BaseText>
    <template #footer>
      <BaseButton @click="$emit('close')" v-test="'closeDiscountInfo'">
        {{ $t('global.ok') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
