import { ref, computed, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type { FormData } from '@/stores/calendar-create-appointment';
import { watchArray } from '@vueuse/core';
import { useServicesStore } from '@/stores/services';
import type { ServiceAvailabilityReason } from '@/types';

export const useServiceAvailability = (formData: FormData) => {
  const unavailableServices = ref<
    {
      id: number;
      reason: ServiceAvailabilityReason;
    }[]
  >([]);
  const formServiceIds = computed<number[]>(() =>
    formData.partsAttributes.map((part) => part.serviceId).filter((id) => !!id)
  );

  watchArray(formServiceIds, (newList, oldList, added) => {
    if (newList.length) {
      // When services are added, fetch the availabilities
      // When editing an existing appointment, multiple services are added at once
      added.forEach((id) => {
        getServiceAvailabilities(id);
      });
    } else {
      // When the services list is cleared, clear the local array
      unavailableServices.value = [];
    }
  });

  watch(
    () => formData.startAt,
    () => {
      // When the startAt changes, fetch availabilities for all services
      formServiceIds.value.forEach((id) => {
        getServiceAvailabilities(id);
      });
    }
  );

  const getServiceAvailabilities = (serviceId: number) => {
    if (!formData.startAt) {
      return;
    }

    // When the service doesn't have the offeringEnabled property, we shouldn't do anything
    const { serviceById } = useServicesStore();
    const service = serviceById(serviceId);

    if (!service?.offeringEnabled) {
      return;
    }

    const { onResult } = useQuery(
      gql`
        query getServiceAvailabilities($serviceId: ID!, $startAt: DateTime!) {
          serviceAvailabilities(serviceId: $serviceId, startAt: $startAt) {
            available
            reason
          }
        }
      `,
      {
        serviceId,
        startAt: formData.startAt
      }
    );

    onResult(({ data }) => {
      const available = data?.serviceAvailabilities?.available;
      const reason = data?.serviceAvailabilities?.reason;

      if (typeof available === 'boolean' && !available) {
        unavailableServices.value.push({
          id: serviceId,
          reason
        });
      } else {
        unavailableServices.value = unavailableServices.value.filter(
          (service) => service.id !== serviceId
        );
      }
    });
  };

  return {
    unavailableServices
  };
};
