import { computed, watch, watchEffect, nextTick } from 'vue';
import { useCreateAppointmentStore, type FormData } from './index';
import { storeToRefs } from 'pinia';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useResourcesStore } from '@/stores/resources';
import { useRouter } from 'vue-router';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { useLocationsStore } from '@/stores/locations';
import { DataScope } from '@/types';
import { useCompanyStore } from '@/stores/company';

export const useCreateEventSync = (formData: FormData) => {
  // This code syncs the data from the "create event" store with the "create appointment" store

  const { dateTimeFrom, resourceId } = storeToRefs(useCreateEventStore());

  const firstResourceId = computed({
    get() {
      const firstPart = formData.partsAttributes[0];
      const firstAllocation = firstPart?.allocationsAttributes?.[0] || null;
      return firstAllocation?.resourceId || null;
    },
    set(resourceId) {
      const firstPart = formData.partsAttributes[0];
      const firstAllocation = firstPart?.allocationsAttributes?.[0] || null;
      if (firstAllocation) {
        firstAllocation.resourceId = resourceId;
      }
    }
  });

  watchEffect(() => {
    nextTick(() => {
      // Nexttick is needed to make sure this happens after the store values have been updated
      const { setEventData } = useCreateEventStore();
      setEventData({
        startDate: formData.startAt,
        resourceId: firstResourceId.value
      });

      const store = useCreateEventStore();
      store.duration = firstPartDuration.value;
    });
  });

  const { duration } = storeToRefs(useCreateEventStore());
  const firstPartDuration = computed({
    get() {
      const firstPart = formData.partsAttributes[0];
      return firstPart?.duration || 0;
    },
    set(duration) {
      const firstPart = formData.partsAttributes[0];
      if (firstPart) {
        firstPart.duration = duration;
      }
    }
  });

  const router = useRouter();

  watch(
    () => formData.locationId,
    (locationId) => {
      const { previewModeActive } = useCalendarPreviewStore();
      const { dataScope } = useLocationsStore();
      const { multiLocation } = useCompanyStore();

      // When the user selects another location in the appointment form, change the location of the app
      // This will cause the calendar to show the correct events
      // Only do this for multilocation accounts, and only if the location data scope is set to local

      if (
        multiLocation &&
        previewModeActive &&
        dataScope === DataScope.Local &&
        router.currentRoute.value.name
      ) {
        router.replace({
          name: router.currentRoute.value.name,
          params: {
            locationId
          }
        });
      }
    }
  );

  watch(dateTimeFrom, (dateTimeFrom) => {
    const { isFetchingAppointment } = useCreateAppointmentStore();
    if (isFetchingAppointment) {
      return;
    }

    formData.startAt = dateTimeFrom;
  });
  watch(resourceId, (resourceId) => {
    const { isFetchingAppointment } = useCreateAppointmentStore();
    if (isFetchingAppointment) {
      return;
    }

    // Only set the resource id if it belongs to an employee
    const { resourceById } = useResourcesStore();
    const resource = resourceId ? resourceById(resourceId) : null;
    if (resource?.type === 'employee') {
      firstResourceId.value = resourceId;
    } else {
      firstResourceId.value = null;
    }
  });
  watch(duration, (duration) => {
    const { isFetchingAppointment } = useCreateAppointmentStore();
    if (isFetchingAppointment) {
      return;
    }

    firstPartDuration.value = duration;
  });
};
