import gql from 'graphql-tag';

export const SAVE_ADYEN_SELECTED_TERMINAL_MODEL = gql`
  mutation saveAdyenSelectedTerminalModel(
    $input: SaveSelectedTerminalModelInput!
  ) {
    saveAdyenSelectedTerminalModel(input: $input) {
      adyenAppState {
        terminalModel
      }
    }
  }
`;

export const GET_ADYEN_APP_STATE_TERMINAL = gql`
  query getAdyenAppState {
    adyenAppState {
      terminalModel
    }
  }
`;
