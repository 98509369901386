<template>
  <BaseChip
    :text="filterLabel"
    closeable
    @close="$emit('close')"
    v-test="'customersFilterItems'"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';

import { GET_CUSTOMER_TAGS } from '@/modules/customers/graphql';
const { result } = useQuery(GET_CUSTOMER_TAGS, {
  fetchPolicy: 'cache-first'
});
const tags = computed(() =>
  result.value?.customerTags
    ? [
        ...result.value.customerTags.salonizedTags,
        ...result.value.customerTags.companyTags
      ]
    : []
);

const props = defineProps<{
  filter: any;
}>();

defineEmits(['close']);

const { t } = useI18n();

const filterLabel = computed(() => {
  if (!props.filter) {
    return '';
  }

  let label = t(`customers.filters.${props.filter.filter_name.toLowerCase()}`);

  if (props.filter.values) {
    label += ': ' + formatValues();
  }
  return label;
});

const formatDate = (value) => {
  const date = new Date(value),
    year = date.getFullYear();

  let month = date.getMonth() + 1,
    dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }

  if (month < 10) {
    month = '0' + month;
  }

  return `${dt}/${month}/${year}`;
};

const formatValues = () => {
  const filter = props.filter;

  const filterName = filter.filter_name.toLowerCase();

  if (typeof filter.values.from === 'string') {
    return `${formatDate(filter.values.from)} - ${formatDate(filter.values.to)}`;
  } else if (filterName === 'tags') {
    return tags.value
      .filter((tag) => filter.values.includes(tag.id))
      .map((tag) => tag.name)
      .join(', ');
  } else if (filter.employee) {
    return filter.employee;
  } else if (filterName === 'gender') {
    return filter.values;
  } else if (filter.values.length) {
    return filter.values.length;
  } else if (filterName === 'last_appointment_before_date') {
    return `${t('customers.filters.before')} ${formatDate(filter.values.date)}`;
  } else if (filterName === 'last_appointment_days_ago') {
    return `${filter.values.days} ${t('customers.filters.days_ago')}`;
  } else if (filterName === 'spent_past_year' || filterName === 'total_spent') {
    return `${filter.values.from / 100}-${filter.values.to / 100}`;
  } else {
    return `${filter.values.from}-${filter.values.to}`;
  }
};
</script>
