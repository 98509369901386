import gql from 'graphql-tag';

export const GET_TRANSACTIONS = gql`
  query getTransactions(
    $pagination: PaginationAttributes
    $locationId: ID
    $direction: SortDirectionEnum
    $dataScope: DataScope
    $search: String
    $cashupId: ID
    $sortBy: String
    $onlyCash: Boolean
  ) {
    transactions(
      locationId: $locationId
      pagination: $pagination
      direction: $direction
      dataScope: $dataScope
      search: $search
      cashupId: $cashupId
      sortBy: $sortBy
      onlyCash: $onlyCash
    ) {
      amount
      description
      id
      orderId
      orderNumber
      transactionAt
      type
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($input: DeleteTransactionInput!) {
    deleteTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const GET_LOCATION_CASHUP = gql`
  query getLocation($id: Int!) {
    location(id: $id) {
      openCashup {
        expectedTotalCash
        id
        openedOn
        openingAmount
      }
    }
  }
`;

export const GET_DRAWER = gql`
  query getDrawer($locationId: ID, $dataScope: DataScope) {
    drawer(locationId: $locationId, dataScope: $dataScope) {
      creditCard
      pin
      today
      total
    }
  }
`;
