<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.disabled]: !notifications.length
      }
    ]"
  >
    <transition-group>
      <div
        v-for="notification in visibleNotifications"
        :key="notification.uid"
        :class="$style.notification"
        v-test="'global-notification'"
      >
        <div :class="$style.top">
          <BaseText>
            {{ notification.heading }}
          </BaseText>
          <div
            :class="$style.close"
            @click="closeNotification(notification.uid)"
          >
            <BaseIcon name="close" />
          </div>
        </div>
        <div :class="$style.bottom">
          <BaseText size="s">
            {{ notification.description }}
          </BaseText>
          <BaseIcon
            v-if="notification.icon && notification.icon.name"
            :name="notification.icon.name"
            :color="notification.icon.color"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import { datadogRum } from '@datadog/browser-rum';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';

let uid = 1;

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      notifications: []
    };
  },
  computed: {
    visibleNotifications() {
      // Only show 3 notifications in total
      return this.notifications.slice(0, 3);
    },
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    onNotification(data) {
      if (
        this.notifications.find(
          (notification) => notification.name === data.name
        )
      ) {
        // Don't add notification if one with the same name already exists
        return;
      }

      const notification = {
        ...data,
        uid
      };

      switch (data.name) {
        case 'action-unable':
          notification.heading = this.$t(
            'global.notifications.action_unable.heading'
          );
          notification.description = this.$t(
            'global.notifications.action_unable.description'
          );
          notification.icon = {
            name: 'alert',
            color: 'warning'
          };
          break;
        case 'no-connection':
          notification.heading = this.$t(
            'global.notifications.no_connection.heading'
          );
          notification.description = this.$t(
            'global.notifications.no_connection.description'
          );
          notification.icon = {
            name: 'no-wifi',
            color: 'error'
          };
          break;
        case 'session-expired':
          notification.heading = this.$t(
            'global.notifications.session_expired.heading'
          );
          notification.description = this.$t(
            'global.notifications.session_expired.description'
          );
          notification.icon = {
            name: 'alert',
            color: 'warning'
          };
      }

      uid++;

      this.notifications.push(notification);

      setTimeout(() => {
        this.closeNotification(notification.uid);
      }, 5000);

      if (data.name === 'no-connection') {
        datadogRum.addAction('no-connection-shown');
      }

      if (data.name === 'session-expired') {
        datadogRum.addAction('session-expired-shown', {
          autoSessionTimeout: this.user?.settings?.security?.autoSessionTimeout
        });
      }
    },
    closeNotification(uid) {
      this.notifications = this.notifications.filter(
        (item) => item.uid !== uid
      );
    }
  },
  created() {
    eventBus.$on('notification', this.onNotification);
  },
  beforeUnmount() {
    eventBus.$off('notification', this.onNotification);
  }
});
</script>

<style lang="scss" module>
.base {
  position: fixed;
  z-index: 899;

  &.smallScreen {
    top: $spacing * 0.5;
    right: $spacing * 0.5;
  }

  &:not(.smallScreen) {
    top: $spacing * 1.5;
    right: $spacing * 1.5;
  }

  &.disabled {
    pointer-events: none;
  }
}

.notification {
  max-width: 280px;
  background-color: $white;
  border-radius: $radius;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: $spacing;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  &:global(.v-enter-active) {
    transition:
      transform 0.2s $easeOutBack 0.1s,
      opacity 0.1s linear 0.1s;
  }

  &:global(.v-leave-active) {
    transition: opacity 0.1s linear;
  }

  &:global(.v-enter-from) {
    transform: scale(0.5);
  }

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing * 0.5;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

.close {
  padding: $spacing * 0.5;
  margin: $spacing * -0.5;
  cursor: pointer;
}
</style>
