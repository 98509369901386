<template>
  <EmptyPageContent
    :fullHeight="true"
    :text="buttonsText"
    :moreLink="moreLink"
    :imageName="imageName"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import onUpgradeButtonClick from './on-upgrade-button-click';
import trialRequest from './request-trial';
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const mixpanel = inject<any>('mixpanel');
const { t } = useI18n();
const { handleUpgradeButtonClick } = onUpgradeButtonClick();
const { requestTrial } = trialRequest();
const { company } = useCompanyStore();
const router = useRouter();

const props = defineProps<{
  text?: {
    title?: string;
    description?: string;
    btnPrimary?: string;
  };
  moreLink?: {
    text: string;
    url: string;
  };
  newRoute?: string;
  imageName: string;
}>();

const buttonsText = computed(() => {
  const text: any = { ...props.text } || {};

  if (!company?.marketingTrialEndsAt) {
    text.btnSecondary = t('global.actions.start_trial');
  }

  return text;
});

const handleClick = (button: 'primary' | 'secondary') => {
  if (button === 'primary') {
    handleUpgradeButtonClick();
  }

  if (button === 'secondary') {
    mixpanel.track('Start marketing trial button clicked');
    requestTrial().then(() => {
      if (props.newRoute) {
        router.push({ name: props.newRoute });
      }
    });
  }
};
</script>
