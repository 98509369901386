<template>
  <div>
    <BaseText :mb="2" center>
      {{ description }}
    </BaseText>
    <BaseForm @submit="sendCode">
      <div :class="$style.inputWrap">
        <BaseCodeInput
          v-model="token"
          :label="$t(`${baseName}.enter_code`)"
          type="text"
          name="token"
          required
          mb
          v-test="`${baseName}-token`"
        />
      </div>
      <BaseText v-if="tokenError" color="error" mt v-test="'tokenError'">
        {{ $t(`${baseName}.token_error`) }}
      </BaseText>
      <div :class="$style.bottom">
        <BaseButton
          :loading="isLoading"
          center
          mt
          submitForm
          v-test="`${baseName}-submit`"
        >
          {{ $t(`${baseName}.verify_code`) }}
        </BaseButton>
        <div>
          <BaseText mt link center :href="helpPageUrl" v-test="'token-info'">
            {{ $t('two_factor.info') }}
          </BaseText>
        </div>
      </div>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    tokenError: {
      type: Boolean,
      required: true
    },
    baseName: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  emits: ['sendCode'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      token: ''
    };
  },
  computed: {
    helpPageUrl() {
      const locale = ['en', 'nl'].includes(this.$i18n.locale)
        ? this.$i18n.locale
        : 'en';
      return `https://help.salonized.com/${locale}/articles/6115892`;
    }
  },
  methods: {
    sendCode() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      this.$emit('sendCode', this.token);
    }
  }
});
</script>

<style lang="scss" module>
.bottom {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputWrap {
  display: flex;
  justify-content: center;
}
</style>
