<template>
  <div
    :class="[
      $style.base,
      $style[size],
      {
        [$style.hasError]: hasError,
        [$style.isDisabled]: disabled,
        [$style.squareCorners]: squareCorners,
        [$style.centerText]: centerText
      }
    ]"
    v-test="'_base-input-element'"
  >
    <textarea
      v-if="isTextArea"
      :id="uid"
      ref="textarea"
      v-model="value"
      :class="[$style.input, $style.textarea]"
      :placeholder="placeholder"
      :disabled="!!disabled"
      :rows="rows"
      @blur="onBlur"
      v-test="'_base-input-input'"
    />
    <input
      v-else
      :id="uid"
      ref="input"
      v-model="value"
      :type="type"
      :class="$style.input"
      :inputmode="inputMode"
      :placeholder="placeholder"
      :disabled="!!disabled"
      @blur="onBlur"
      @input="onInput"
      @focus="onFocus"
      @keydown="$emit('keydown', $event)"
      v-test="'_base-input-input'"
    />
    <div v-if="hasError" :class="$style.alertIcon">
      <BaseIcon name="alert" color="error" />
    </div>
  </div>
</template>

<script lang="ts">
import props from './props';

import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: [Number, String],
    inputMode: String,
    squareCorners: Boolean,
    centerText: Boolean,
    labelText: String,
    type: String,
    ...props('input')
  },
  emits: ['update:modelValue', 'keydown', 'blur', 'input', 'focus'],
  data() {
    return {
      isFocused: false
    };
  },
  watch: {
    focus(value) {
      if (value) {
        this.focusInput();
      }
    }
  },
  computed: {
    value: {
      get() {
        if (this.labelText && !this.isFocused) {
          return `${this.modelValue}${this.labelText}`;
        } else if (this.prefix) {
          return `${this.prefix}${this.modelValue}`;
        }
        return this.modelValue;
      },
      set(value) {
        if (this.prefix) {
          if (value.length <= this.prefix.length) {
            value = '';
          } else {
            value = value.replace(this.prefix, '');
          }
        }
        this.$emit('update:modelValue', value);
      }
    },
    isTextArea() {
      return this.type === 'textarea';
    },
    ref() {
      return this.$refs[this.isTextArea ? 'textarea' : 'input'];
    }
  },
  methods: {
    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e.target.value);

      this.$nextTick(() => {
        e.target.value = this.value;
      });
    },
    onFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    onInput(e) {
      this.$emit('input', e.target.value);
    },
    focusInput() {
      if (!this.ref) {
        return;
      }

      this.ref.focus();

      const inputValue = this.ref.value;
      if (inputValue) {
        // set cursor behind text on focus
        this.$refs[this.isTextArea ? 'textarea' : 'input'].setSelectionRange(
          inputValue.length,
          inputValue.length
        );
      }
    }
  },
  mounted() {
    if (this.ref && this.value && this.value !== this.ref.value) {
      // It seems that somehow 2-way binding with the input element doesn't work on render when the element is hidden with a v-show
      // So we need to set it manually here    const ref = this.$refs[this.isTextArea ? 'textarea' : 'input'];

      this.ref.value = this.value;
    }

    if (this.focus && this.ref) {
      this.focusInput();
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.input {
  @include input;

  .base.hasError & {
    border-color: $color-error;
    padding-right: 30px;
  }

  .base.squareCorners & {
    border-radius: 0 !important;
  }

  .base.centerText & {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .base.s & {
    font-size: 12px;
    // line-height 0 for centering in safari
    line-height: 0;
    height: $input-height-small;
  }

  &.textarea {
    display: block;
    line-height: normal;
    padding-top: 11px;
    height: auto;
  }
}

.alertIcon {
  position: absolute;
  right: $spacing * 0.5;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  display: flex;
  align-items: center;
}
</style>
