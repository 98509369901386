<template>
  <FormElement v-bind="{ validation, label }">
    <div :class="$style.inner" v-test="'_base-button-list'">
      <BaseButton
        v-for="(option, index) in options"
        :key="index"
        color="inverted"
        :selected="
          Array.isArray(modelValue)
            ? modelValue.includes(option.value)
            : modelValue === option.value
        "
        :disabled="option.disable"
        :tooltip="option.tooltip"
        @click="onSelect(option.value)"
        v-test="'_base-button-list-button'"
      >
        {{ option.label }}
      </BaseButton>
    </div>
  </FormElement>
</template>

<script lang="ts">
import FormElement from '@/components/_shared/form-element/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FormElement
  },
  inheritAttrs: false,
  props: {
    validation: {},
    modelValue: {
      type: [Array, String],
      default: () => []
    },
    options: {
      type: Array,
      required: true,
      validator: (options: any) =>
        !options.find(
          (item: any) =>
            item.value === undefined || item.value === null || !item.label
        )
    },
    label: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  methods: {
    onSelect(option: any) {
      let newValue;
      if (typeof this.modelValue === 'string') {
        newValue = option;
      } else {
        newValue = [];
        if (this.modelValue.includes(option)) {
          newValue = this.modelValue.filter((value: any) => value !== option);
        } else {
          newValue = [...this.modelValue, option];
        }

        newValue.sort((a, b) => a - b);
      }
      this.$emit('update:modelValue', newValue);
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing * -0.5;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}
</style>
