import gql from 'graphql-tag';

export const GET_PRODUCTS = gql`
  query getProducts(
    $locationId: ID
    $search: String
    $pagination: PaginationAttributes
    $categoryIds: [Int!]
    $supplierIds: [Int!]
    $filter: String
  ) {
    products(
      locationId: $locationId
      search: $search
      pagination: $pagination
      categoryIds: $categoryIds
      supplierIds: $supplierIds
      filter: $filter
    ) {
      id
      name
      stockEnabled
      stockMinimum
      stockAmount
      stockValue
      category {
        name
      }
      price
      vatRate {
        name
        percentage
        selectedByDefault
      }
      outOfStock
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories(
    $search: String
    $pagination: PaginationAttributes
  ) {
    productCategories(search: $search, pagination: $pagination) {
      id
      name
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query getSuppliers(
    $locationId: ID
    $search: String
    $pagination: PaginationAttributes
  ) {
    suppliers(
      locationId: $locationId
      search: $search
      pagination: $pagination
    ) {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      product {
        id
      }
    }
  }
`;
