import gql from 'graphql-tag';

export const entryFragment = gql`
  fragment waitingListEntry on WaitingListEntry {
    createdAt
    customer {
      email
      firstName
      fullName
      id
      mobilePhone
      phone
    }
    date
    daysOfWeek
    id
    lastEmailSentAt
    location {
      id
      internalName
    }
    notes
    partsOfDay
    resource {
      id
      name
    }
    services {
      duration
      durationFinish
      durationProcessing
      durationSetup
      id
      name
      requiresProcessingTime
    }
  }
`;
