<template>
  <BaseModal
    :heading="$t('products.process_order')"
    small
    @close="$emit('close')"
  >
    <BaseText>
      {{ $t('products.not_all_received') }}
    </BaseText>
    <BaseText>
      {{ $t('products.close_or_open_backorder') }}
    </BaseText>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="submit('complete')"
        v-test="'createBackOrderClose'"
      >
        {{ $t('products.close_order') }}
      </BaseButton>
      <BaseButton @click="submit('backorder')" v-test="'createBackOrder'">
        {{ $t('products.leave_open_backorder') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['submit', 'close'],
  methods: {
    submit(status) {
      this.$emit('submit', status);
      this.$emit('close');
    }
  }
});
</script>
