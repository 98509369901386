import { useI18n } from 'vue-i18n';
import type { ReturnData, TrialTask } from '../types';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTreatwellStore } from '@/stores/treatwell';
import eventBus from '@/event-bus';

export default (): ReturnData => {
  const { t } = useI18n();
  const router = useRouter();
  const { treatwellStatus } = useTreatwellStore();

  const tasks = ref<TrialTask[]>([
    {
      completed: treatwellStatus.contractSigned,
      label: treatwellStatus.contractSigned ? '' : t('global.required'),
      name: 'sign_contract',
      text: t('treatwell.trial_tasks.sign_contract'),
      visual: '/img/treatwell/trial-tasks/contract.svg'
    },
    {
      completed: treatwellStatus.bankDetailsProvided,
      label: treatwellStatus.bankDetailsProvided
        ? ''
        : t('treatwell.trial_tasks.contract_required'),
      labelColor: 'default',
      name: 'provide_bank',
      requiredTask: treatwellStatus.contractSigned ? '' : 'sign_contract',
      text: t('treatwell.trial_tasks.provide_bank'),
      visual: '/img/treatwell/trial-tasks/bank.svg'
    },
    {
      completed: treatwellStatus.reviewsPublished,
      name: 'push_reviews',
      text: t('treatwell.trial_tasks.push_reviews'),
      visual: '/img/treatwell/trial-tasks/reviews.svg'
    }
  ]);

  eventBus.$on('update-task', (taskName: string) => {
    const task = tasks.value.find((task) => task.name === taskName);
    if (task) {
      task.label = '';
    }

    if (taskName === 'sign_contract') {
      const task = tasks.value.find((task) => task.name === 'provide_bank');
      if (task) {
        task.requiredTask = '';
        task.label = t('global.required');
        task.labelColor = 'primary';
      }
    }

    switch (taskName) {
      case 'sign_contract':
        treatwellStatus.contractSigned = true;
        break;
      case 'provide_bank':
        treatwellStatus.bankDetailsProvided = true;
        break;
      case 'push_reviews':
        treatwellStatus.reviewsPublished = true;
        break;
    }
  });

  return {
    data: computed(() => ({
      completed: {
        description: t('treatwell.trial_tasks.completed'),
        visual: '/img/treatwell/trial-tasks/completed.svg'
      },
      heading: t('treatwell.trial_tasks.heading'),
      icon: {
        dark: '/img/treatwell/trial-tasks/icon-dark.svg',
        light: '/img/treatwell/trial-tasks/icon-light.svg'
      },
      link: {
        routeName: 'treatwell-admin',
        secondary: true,
        text: t('treatwell.trial_tasks.treatwell_page_link')
      },
      remainingDays: treatwellStatus.freeCommissionRemainingDays
    })),
    tasks,
    onTaskClick: (taskName: string) => {
      switch (taskName) {
        case 'sign_contract':
          router.push({ name: 'treatwell-sign-contract' });
          break;
        case 'provide_bank': {
          const contractTask = tasks.value.find(
            (task) => task.name === 'sign_contract'
          );
          if (contractTask?.completed) {
            router.push({ name: 'treatwell-provide-iban' });
          }
          break;
        }
        case 'push_reviews':
          router.push({ name: 'treatwell-publish-batch' });
          break;
      }
    }
  };
};
