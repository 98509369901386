<template>
  <BaseModal
    :heading="$t('global.confirmation.confirm')"
    small
    @close="emits('close')"
  >
    <BaseText>
      {{ $t('products.no_going_back') }}
    </BaseText>
    <BaseCheckbox
      v-model="checkbox"
      :label="$t('products.understand_changes')"
      mt
      v-test="'confirmRevertCheckbox'"
    />

    <template #footer>
      <BaseButton color="inverted" @click="emits('close')">
        {{ $t('global.no') }}
      </BaseButton>
      <BaseButton
        :disabled="!checkbox"
        ml
        @click="emits('confirm')"
        v-test="'revert-prices-confirm'"
      >
        {{ $t('global.yes') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const emits = defineEmits(['close', 'confirm']);

const checkbox = ref(false);
</script>
