<template>
  <BaseCard noPadding>
    <div :class="$style.header" v-test="'user-accounts-card'">
      <div :class="$style.heading">
        <BaseHeading>{{ name }}</BaseHeading>
      </div>
      <BaseMore
        :options="canDelete ? ['edit', 'delete'] : ['edit']"
        @select="onMoreSelect"
      />
    </div>
    <div :class="$style.content">
      <BaseText mr :mb="0.5" oneLine iconBefore="lock">{{ role }}</BaseText>
      <BaseText oneLine iconBefore="email">{{ email }}</BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
const props = defineProps<{
  canDelete: boolean;
  name: string;
  role: string;
  email: string;
  id: number;
}>();

const emit = defineEmits(['edit', 'delete']);

const onMoreSelect = (option: string) => {
  if (option === 'edit') {
    emit('edit', props.id);
  } else if (option === 'delete') {
    emit('delete', { id: props.id, name: props.name });
  }
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
}

.heading {
  padding: $spacing;
}

.content {
  padding: 0 $spacing $spacing;
}
</style>
