<template>
  <div
    :class="[
      $style.base,
      {
        [$style.inline]: inline,
        [$style.disabled]: disabled
      }
    ]"
  >
    <BaseInputLabel v-if="label" :text="label" v-test="'_base-radio-label'" />
    <div :class="$style.options" v-test="'_base-radio-options'">
      <div
        v-for="(option, index) in options"
        :key="index"
        v-test="'_base-radio-option'"
      >
        <input
          :id="`${uniqueId}-${index}`"
          v-model="localValue"
          type="radio"
          :value="option.value"
          :name="uniqueId"
          :class="$style.input"
        />
        <label
          :for="`${uniqueId}-${index}`"
          :class="$style.label"
          v-test="'_base-radio-option-label'"
        >
          {{ filters.capitalize(option.label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: false
    },
    options: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.find(
          (item) => item.label === undefined || item.value === undefined
        )
    },
    label: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      filters
    };
  },
  computed: {
    uniqueId() {
      const uid = Math.round(Math.random() * 100000).toString();
      return `radio-${uid}`;
    },
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
});
</script>

<style lang="scss" module>
.input {
  position: absolute;
  opacity: 0;
  visibility: hidden;

  &:checked {
    & ~ .label:before {
      border: 2px solid white;

      .base:not(.disabled) & {
        background-color: $color-primary;
        box-shadow: 0 0 0 1px $color-primary;
      }

      .base.disabled & {
        background-color: #333;
        box-shadow: 0 0 0 1px #333;
      }
    }
  }
}

.options {
  .base.inline & {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: $spacing;
    }
  }

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  .base.disabled & {
    opacity: 0.4;
    pointer-events: none;
  }
}

.label {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: $spacing;
    height: $spacing;
    border-radius: 50%;
    border: 1px solid $color-border-input;
    flex-shrink: 0;
    margin-right: $spacing * 0.5;
    background-color: white;
  }
}
</style>
