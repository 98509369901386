<template>
  <BaseModal
    noPadding
    :heading="$t('account.auth.two_factor_heading')"
    parentRoute="account"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <TwoFactorConfirmationModal
        v-if="showConfirmation"
        @close="showConfirmation = false"
        @confirm="onConfirmed"
      />
      <BaseModalSmall v-if="showOtpCode" @close="showOtpCode = false">
        <BaseHeading size="l" :mt="0.5">
          {{ $t('account.auth.two_factor_settings.raw_otp_modal.heading') }}
        </BaseHeading>
        <BaseText :mt="1.5">
          {{ $t('account.auth.two_factor_settings.raw_otp_modal.text') }}
        </BaseText>
        <BaseHeading size="l" mt mb v-test="'two-factor-raw-otp'">
          {{ otpRaw }}
        </BaseHeading>
      </BaseModalSmall>
      <PageSplit :width="35">
        <template #left>
          <img src="/img/two-factor-auth/important.svg" />
          <BaseHeading :mt="2" mb>
            {{ $t('account.auth.two_factor_settings.important_info') }}
          </BaseHeading>
          <BaseText :mt="0.5">{{
            $t('account.auth.two_factor_settings.when_you_enable')
          }}</BaseText>
          <BaseText :mt="0.5">{{
            $t('account.auth.two_factor_settings.wisely_choose')
          }}</BaseText>
        </template>
        <template #right>
          <BaseHeading mb>
            {{ $t('account.auth.two_factor_settings.authentication_method') }}
          </BaseHeading>
          <BaseSwitch
            v-model="twoFactorSelectedMode"
            mb
            :options="twoFactorOptions"
            v-test="'two-factor-select-mode'"
          />
          <div v-if="twoFactorSelectedMode == 'SMS' && !smsSentAt">
            <BaseForm @submit="sendFirstSms">
              <BaseCard>
                <div :class="$style.inputWrap">
                  <BaseDropdown
                    v-model="country"
                    :label="
                      $t('account.auth.two_factor_settings.form.country_code')
                    "
                    :options="countriesWithPrefix"
                    :required="twoFactorSelectedMode === 'SMS'"
                    searchable
                    mb
                    v-test="'two-factor-country-code'"
                  />
                  <BaseInput
                    v-model="phoneNumber"
                    :label="
                      $t('account.auth.two_factor_settings.form.phone_number')
                    "
                    type="tel"
                    :prefix="`+${country} `"
                    name="phoneNumber"
                    :required="twoFactorSelectedMode === 'SMS'"
                    mb
                    v-test="'two-factor-phone-number'"
                  />
                </div>
                <BaseButton fullWidth submitForm v-test="'two-factor-send-sms'">
                  {{ $t('account.auth.two_factor_settings.send_code') }}
                </BaseButton>
                <BaseText
                  v-if="mobilePhoneErrors.invalid"
                  mt
                  color="error"
                  v-test="'two-factor-error-phone-invalid'"
                >
                  {{ $t('account.auth.two_factor_settings.errors.phone') }}
                </BaseText>
                <BaseText
                  v-if="mobilePhoneErrors.exceeded"
                  mt
                  color="error"
                  v-test="'two-factor-error-time-interval'"
                >
                  {{
                    $t(
                      'account.auth.two_factor_settings.errors.time_interval_exceeded'
                    )
                  }}
                </BaseText>
                <BaseText
                  v-if="smsSentAt !== null"
                  mt
                  v-test="'two-factor-sms-sent'"
                >
                  <BaseText color="success">
                    {{ $t('account.auth.two_factor_settings.info.code_sent') }}
                  </BaseText>
                  {{
                    $t('account.auth.two_factor_settings.info.didnt_receive')
                  }}
                </BaseText>
              </BaseCard>
            </BaseForm>
          </div>
          <BaseText
            v-if="smsSentAt"
            link
            iconBefore="arrow-left"
            :mb="0.5"
            @click="smsSentAt = null"
          >
            {{ $t('global.back') }}
          </BaseText>
          <BaseCard
            v-if="twoFactorSelectedMode == 'AUTHENTICATOR_APP' || smsSentAt"
          >
            <div v-if="twoFactorSelectedMode == 'AUTHENTICATOR_APP'">
              <BaseText mb>
                {{ $t('account.auth.two_factor_settings.auth_app') }}
                <BaseText href="https://googleauthenticator.net/">
                  {{ $t('account.auth.two_factor_settings.google_auth') }}
                </BaseText>
              </BaseText>
              <div
                :class="$style.qrCode"
                v-html="qrCodeSvg"
                v-test="'two-factor-qr'"
              />
              <BaseText mt mb>
                {{ $t('account.auth.two_factor_settings.or') }}
                <BaseText
                  link
                  inline
                  @click="openOtpModal"
                  v-test="'two-factor-check-raw-otp'"
                >
                  {{ $t('account.auth.two_factor_settings.get_code') }}
                </BaseText>
                {{ $t('account.auth.two_factor_settings.do_manually') }}
              </BaseText>
            </div>
            <BaseText v-if="smsSentAt" mb bold>
              {{ $t('account.auth.two_factor_settings.info.code_sent') }}
            </BaseText>
            <BaseForm @submit="openConfirmationModal">
              <BaseCodeInput
                v-model="twoFactorCode"
                :label="$t('account.auth.two_factor_settings.form.otp')"
                required
                mb
                v-test="'two-factor-otp'"
              />
              <BaseInput
                v-model="userPassword"
                :label="$t('account.auth.two_factor_settings.form.password')"
                type="password"
                name="userPassword"
                required
                mb
                v-test="'two-factor-user-password'"
              />
              <BaseButton
                fullWidth
                :loading="saving"
                submitForm
                v-test="'two-factor-enable-button'"
              >
                {{ $t('account.auth.two_factor_settings.activate') }}
              </BaseButton>
              <BaseText
                v-if="passwordFailed"
                mt
                color="error"
                v-test="'two-factor-error-password'"
              >
                {{ $t('account.auth.two_factor_settings.errors.password') }}
              </BaseText>
              <BaseText
                v-if="optCodeFailed"
                mt
                color="error"
                v-test="'two-factor-error-otp'"
              >
                {{ $t('account.auth.two_factor_settings.errors.otp') }}
              </BaseText>
            </BaseForm>
          </BaseCard>
          <BaseSpinner v-show="sendingSms" inline mt />
          <div v-if="smsSentAt" v-show="!sendingSms" :class="$style.resend">
            <BaseText>
              {{ $t('two_factor.no_code') }}
            </BaseText>
            <BaseText link @click="sendSms">
              {{ $t('two_factor.send_again') }}
            </BaseText>
          </div>
        </template>
      </PageSplit>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import prefixes from '@/phone-prefixes';
import TwoFactorConfirmationModal from './TwoFactorConfirmationModal.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import PageSplit from '@/modules/PageSplit.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Enable2FA',
  components: {
    TwoFactorConfirmationModal,
    PageSplit
  },
  inject: ['mixpanel'],
  data() {
    return {
      twoFactorOtpSecret: null,
      twoFactorSelectedMode: 'SMS',
      twoFactorCode: '',
      userPassword: '',
      country: null,
      phoneNumber: '',
      isSmsSubmitted: false,
      smsSentAt: null,
      optCodeFailed: false,
      passwordFailed: false,
      mobilePhoneErrors: {},
      showConfirmation: false,
      showOtpCode: false,
      twoFactorOptions: [
        {
          label: this.$t('account.auth.two_factor_settings.options.sms'),
          value: 'SMS'
        },
        {
          label: this.$t(
            'account.auth.two_factor_settings.options.authentication_app'
          ),
          value: 'AUTHENTICATOR_APP'
        }
      ],
      countries: prefixes,
      saving: false,
      sendingSms: false
    };
  },
  watch: {
    twoFactorSelectedMode() {
      this.isSmsSubmitted = false;
      this.twoFactorCode = '';
    }
  },
  apollo: {
    twoFactorOtpSecret: {
      query: gql`
        query getTwoFactorOtpSecret {
          twoFactorOtpSecret {
            otpQr
            otpRaw
          }
        }
      `
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    countriesWithPrefix() {
      return this.countries.map((country) => ({
        label: `+${country.prefix} ${country.name} (${country.code.toUpperCase()})`,
        value: country.prefix
      }));
    },
    defaultCountryPrefix() {
      return this.countries.find(
        (country) => country.code === this.company.country
      )?.prefix;
    },
    qrCodeSvg() {
      return this.twoFactorOtpSecret ? this.twoFactorOtpSecret.otpQr : '';
    },
    otpRaw() {
      return this.twoFactorOtpSecret ? this.twoFactorOtpSecret.otpRaw : '';
    },
    fullPhoneNumber() {
      return this.twoFactorSelectedMode === 'SMS'
        ? this.country + this.phoneNumber
        : null;
    }
  },
  methods: {
    onConfirmed() {
      this.isSmsSubmitted = true;
      this.optCodeFailed = false;
      this.passwordFailed = false;
      this.updateTwoFactorSettings();
    },
    updateTwoFactorSettings() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateTwoFactorSettings(
              $input: UpdateTwoFactorSettingsInput!
            ) {
              updateTwoFactorSettings(input: $input) {
                twoFactorSettings {
                  status
                  mode
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              twoFactorCode: this.twoFactorCode,
              userPassword: this.userPassword,
              mode: this.twoFactorSelectedMode,
              phoneNumber: this.fullPhoneNumber,
              status: 'ENABLED'
            }
          }
        })
        .then((response) => {
          response = response?.data?.updateTwoFactorSettings;
          if (response.errors) {
            if (response?.errors?.userPassword) {
              this.passwordFailed = true;
            }
            if (response?.errors?.twoFactorCode) {
              this.optCodeFailed = true;
            }
            if (response?.errors?.phoneNumber) {
              this.mobilePhoneErrors = { invalid: true };
            }
          } else {
            const { getUser } = useUserStore();
            getUser().then(() => {
              this.$router.push({ name: 'account' });
            });

            flash(
              this.$t(
                'account.auth.two_factor_settings.notifications.activated'
              )
            );
            this.mixpanel.track('2FA enabled', {
              method: response.twoFactorSettings?.mode
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    sendFirstSms() {
      this.isSmsSubmitted = true;
      this.mobilePhoneErrors = {};
      this.smsSentAt = null;

      this.sendSms();
    },
    sendSms() {
      this.sendingSms = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation sendTwoFactorSms($input: SendTwoFactorSmsInput!) {
              sendTwoFactorSms(input: $input) {
                smsSentAt
                errors
              }
            }
          `,
          variables: {
            input: {
              phoneNumber: this.fullPhoneNumber
            }
          }
        })
        .then((response) => {
          response = response?.data?.sendTwoFactorSms;
          if (response?.smsSentAt) {
            this.smsSentAt = response?.smsSentAt;
          } else {
            if (response?.errors[0]?.phoneNumber) {
              this.mobilePhoneErrors = { invalid: true };
            }
            if (response?.errors[0]?.timeInterval) {
              this.mobilePhoneErrors = { exceeded: true };
            }
          }
        })
        .finally(() => {
          this.sendingSms = false;
        });
    },
    openConfirmationModal() {
      this.showConfirmation = true;
    },
    openOtpModal() {
      this.showOtpCode = true;
    }
  },
  created() {
    this.country = this.defaultCountryPrefix;
  }
});
</script>

<style lang="scss" module>
.qrCode {
  svg {
    max-width: 100%;
    height: $spacing * 12;
  }
}

.inputWrap {
  .base:not(.smallScreen) & {
    display: flex;
    gap: $spacing;

    & > * {
      &:last-child {
        width: 100%;
      }
    }
  }
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing;
}
</style>
