<template>
  <div v-if="items.length" v-test="'invoice-items'">
    <BaseHeading>
      {{ filters.capitalize($t('global.items.item', 2)) }}
    </BaseHeading>

    <AppointmentDiscountCode
      v-for="discountCode in discountCodes"
      :key="discountCode.code"
      :discountCode="discountCode"
      mt
      mb
      v-test="'discountCode'"
    />

    <BaseTable
      :headers="[
        $t('global.amount'),
        $t('global.description'),
        {
          value: $t('global.price'),
          alignRight: true
        }
      ]"
      :rows="
        items.map((order) => ({
          cells: [
            order.quantity,
            order.description,
            {
              value: filters.currency(order.price),
              alignRight: true
            }
          ]
        }))
      "
      :footers="[
        {
          value: `${$t('global.total')}: ${filters.currency(totalAmount)}`,
          alignRight: true
        }
      ]"
      disableZebra
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import AppointmentDiscountCode from '@/components/AppointmentDiscountCode.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AppointmentDiscountCode
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    discountCodes: {
      type: Array,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
