<template>
  <BaseCard gray :mb="1.5">
    <div
      :class="[
        $style.row,
        {
          [$style.mediumScreen]: $screen !== 'l'
        }
      ]"
    >
      <BaseInput
        v-model="formData.name"
        :label="$t('service.name')"
        :required="true"
        :mb="$screen !== 'l'"
        :mr="$screen === 'l'"
        v-test="'service-name'"
      />
      <BaseDropdown
        v-model="formData.vatRateId"
        :options="[
          {
            value: null,
            label: $t('products.default_vat')
          },
          ...vatRates.map((rate) => ({
            value: rate.id,
            label: rate.label
          }))
        ]"
        :label="$t('global.items.vat_rate', 1)"
      />
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import type { ServiceVariationsFormData } from '../types';
import { storeToRefs } from 'pinia';
import { useVatRatesStore } from '@/stores/vat-rates';

const { vatRates } = storeToRefs(useVatRatesStore());

const formData = inject<ServiceVariationsFormData>('serviceFormData')!;
</script>

<style lang="scss" module>
.row {
  display: flex;
  justify-content: space-between;

  & > *:first-child {
    flex-grow: 1;
  }

  &.mediumScreen {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
