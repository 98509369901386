<template>
  <BasePopover closeButton alignRight @close="close">
    <BaseInput
      v-model="title"
      mb
      :label="$t('global.title')"
      required
      v-test="'labelTitle'"
    />
    <BaseHeading size="s" :mb="0.5">
      {{ $t('global.color') }}
    </BaseHeading>
    <div v-if="colors && colors.length" :class="$style.colors">
      <BaseColorLabel
        v-for="(c, index) in colors"
        :key="index"
        :modelValue="c"
        :mr="0.5"
        :selected="color === c && !customColorSelected"
        @click="color = c"
        v-test="'labelColors'"
      />
      <BaseColorLabel
        v-model="color"
        :mr="0.5"
        colorPicker
        :selected="customColorSelected"
      />
    </div>

    <div :class="$style.bottom">
      <div>
        <BaseButton color="inverted" :mr="0.5" @click="close">
          {{ $t('global.actions.cancel') }}
        </BaseButton>
        <BaseButton @click="onSaveLabel" v-test="'labelSave'">
          {{ $t('global.actions.save') }}
        </BaseButton>
      </div>
      <div>
        <BaseButton
          v-if="selectedLabel"
          color="inverted"
          icon="delete"
          :tooltip="$t('global.actions.delete')"
          @click="onDeleteLabel"
          v-test="'labelDelete'"
        />
      </div>
    </div>
  </BasePopover>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
const colors = ['#3662fb', '#03CFA5', '#EB4762', '#FD832D'];
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { query, fragment } from './graphql';

const defaultState = () => ({
  customColorSelected: false,
  labelId: null,
  title: '',
  color: colors[0]
});

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    selectedLabel: {
      type: Object
    }
  },
  emits: ['created', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return defaultState();
  },
  watch: {
    color: {
      handler() {
        this.customColorSelected = !this.colors.includes(this.color);
      },
      immediate: true
    },
    selectedLabel: {
      handler(label) {
        this.title = label?.title || '';
        this.color = label?.color || colors[0];
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    colors() {
      return colors;
    }
  },
  methods: {
    onDeleteLabel() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteCalendarLabel($input: DeleteCalendarLabelInput!) {
              deleteCalendarLabel(input: $input) {
                calendarLabel {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id: this.selectedLabel.id
            }
          },
          update: (store, { data: { deleteCalendarLabel } }) => {
            const data = store.readQuery({
              query
            });

            store.writeQuery({
              query,
              data: {
                calendarLabels: data.calendarLabels.filter(
                  (label) => label.id !== deleteCalendarLabel.calendarLabel.id
                )
              }
            });
          }
        })
        .then(() => {
          flash(this.$t('global.flash.label_deleted'));
          this.close();
        });
    },
    onSaveLabel() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      this.selectedLabel ? this.updateLabel() : this.createLabel();
    },
    createLabel() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createCalendarLabel($input: CreateCalendarLabelInput!) {
              createCalendarLabel(input: $input) {
                calendarLabel {
                  ...label
                }
                errors
              }
            }
            ${fragment}
          `,
          variables: {
            input: {
              color: this.color,
              title: this.title
            }
          },
          update: (store, { data: { createCalendarLabel } }) => {
            const data = store.readQuery({
              query
            });

            store.writeQuery({
              query,
              data: {
                calendarLabels: [
                  ...data.calendarLabels,
                  createCalendarLabel.calendarLabel
                ]
              }
            });
          }
        })
        .then((response) => {
          flash(this.$t('global.flash.label_created'));
          this.$emit(
            'created',
            response.data.createCalendarLabel.calendarLabel
          );
          this.close();
        });
    },

    updateLabel() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateCalendarLabel($input: UpdateCalendarLabelInput!) {
              updateCalendarLabel(input: $input) {
                calendarLabel {
                  ...label
                }
              }
            }
            ${fragment}
          `,
          variables: {
            input: {
              id: this.selectedLabel.id,
              color: this.color,
              title: this.title
            }
          }
        })
        .then(() => {
          flash(this.$t('global.flash.label_updated'));
          this.close();
        });
    },

    close() {
      Object.assign(this.$data, defaultState());
      this.$emit('close');
    }
  }
});
</script>

<style lang="scss" module>
.colors {
  display: flex;
  margin-bottom: $spacing;
}

.bottom {
  display: flex;
  justify-content: space-between;
}
</style>
