<template>
  <div>
    <BaseRadio
      v-if="options && options.length"
      v-model="selected"
      :options="options"
      mb
      @update:modelValue="emitValue"
    />
    <div v-if="!options.length && !$apollo.loading">
      {{ $t('global.no_results') }}
    </div>
    <BaseSpinner v-if="$apollo.loading" inline />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selected: null,
      customerFilterGroups: []
    };
  },
  computed: {
    options() {
      if (this.customerFilterGroups && this.customerFilterGroups.length) {
        return this.customerFilterGroups.map((option) => {
          const newObj = {
            label: option.name,
            value: option.id
          };
          return newObj;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    emitValue() {
      const group = this.customerFilterGroups.find(
        (group) => group.id === this.selected
      );
      this.$emit('selected', {
        filter_name: 'filter_group',
        group
      });
    }
  },
  apollo: {
    customerFilterGroups: {
      query: gql`
        query getCustomerFilterGroups {
          customerFilterGroups {
            name
            id
            filters {
              filterName
              values
            }
          }
        }
      `,
      result() {
        if (this.customerFilterGroups.length) {
          this.selected = this.customerFilterGroups[0].id;
          this.emitValue();
        }
      }
    }
  }
});
</script>
