import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { hideTooltip } from '@/helpers/ui';
import calendarInterface from '@/modules/calendar/calendar-interface';
import dayjs from '@/dayjs';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { useCompanyStore } from '@/stores/company';

export const useCalendarScroll = (calendarElement: HTMLElement) => {
  // When scrolling the calendar, hide any tooltips that might be active on the calendar events
  const scroller = calendarElement.querySelector(
    '.fc-scroller.fc-scroller-liquid-absolute'
  );
  if (scroller) {
    scroller.addEventListener('scroll', () => {
      hideTooltip();
    });
  }

  // When navigating to the current day, or the week that contains the current day, scroll the calendar to the current time, minus one hour
  const { isTodayVisible } = storeToRefs(useCalendarFiltersStore());
  watch(
    () => isTodayVisible.value,
    () => {
      if (calendarInterface.api && isTodayVisible.value) {
        const { previewModeActive } = useCalendarPreviewStore();
        if (!previewModeActive) {
          nextTick(() => {
            calendarInterface.api.scrollToTime(
              dayjs().tz().subtract(1, 'hour').format('HH:mm')
            );
          });
        }
      }
    },
    {
      immediate: true
    }
  );

  // When navigating to another date, reset the scroll position of the calendar
  const { date } = storeToRefs(useCalendarFiltersStore());
  watch(date, () => {
    const { companySettings } = useCompanyStore();
    const startTime = companySettings.agenda.startTime;

    if (calendarInterface.api && startTime) {
      const { previewModeActive } = useCalendarPreviewStore();
      if (!previewModeActive) {
        calendarInterface.api.scrollToTime(startTime);
      }
    }
  });
};
