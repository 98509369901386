<template>
  <BaseCard v-test="'feedback-appointment'">
    <BaseGrid container>
      <BaseGrid :size="12">
        <BaseHeading border mb>
          {{ $t('feedback.appointment_details') }}
        </BaseHeading>
        <div :class="$style.details">
          <div :class="$style.detail">
            <BaseIcon mr name="person" />
            <BaseText>
              {{ selected.appointment.employeeNames }}
            </BaseText>
          </div>
          <div :class="$style.detail">
            <BaseIcon mr name="clock" />
            <BaseText>
              {{ filters.dateTime(selected.appointment.startAt) }}
            </BaseText>
          </div>
          <div :class="$style.detail">
            <BaseIcon mr name="activity" />
            <BaseText>
              {{ selected.appointment.serviceNames }}
            </BaseText>
          </div>
        </div>
      </BaseGrid>
      <BaseGrid alignRight>
        <BaseButton
          :routerLink="{
            name: 'appointment',
            params: { appointmentId: selected.appointment.id }
          }"
          color="inverted"
          v-test="'feedback-btn-appointment'"
        >
          {{ $t('global.actions.view_appointment') }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </BaseCard>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { inject } from 'vue';

const selected = inject('selected');
</script>

<style lang="scss" module>
.details {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.detail {
  display: flex;
  align-items: center;
}
</style>
