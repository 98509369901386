import gql from 'graphql-tag';

const locationFragment = gql`
  fragment location on Location {
    address
    id
    internalName
    name
    sortOrder
    bookable
    postalcode
    city
    bankAccount
    picture
    phone
  }
`;

export const GET_LOCATION = gql`
  query getLocation($id: Int!) {
    location(id: $id) {
      ...location
    }
  }
  ${locationFragment}
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      location {
        ...location
      }
      errors
    }
  }
  ${locationFragment}
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      location {
        id
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      location {
        ...location
      }
      errors
    }
  }
  ${locationFragment}
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      clientMutationId
      errors {
        attribute
        message
        type
      }
      image {
        bytes
        filename
        id
        lastModified
        mimeType
        originalUrl
        publicId
        transformedUrl
        usage
      }
    }
  }
`;
