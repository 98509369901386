import { useLocationsStore } from '@/stores/locations';
import type { Entry } from './types';

type TimeRange = {
  open: string;
  closed: string;
};

const isPartiallyOutside = (
  firstRange: TimeRange,
  secondRange: TimeRange
): boolean => {
  if (!firstRange.open || !firstRange.closed) {
    return true;
  }

  // Convert the 'open' and 'closed' times to minutes
  const start1 = timeToMinutes(firstRange.open);
  const end1 = timeToMinutes(firstRange.closed);
  const start2 = timeToMinutes(secondRange.open);
  const end2 = timeToMinutes(secondRange.closed);

  // Check if second range starts before or ends after the first range
  return start2 < start1 || end2 > end1;
};

// Helper function to convert time in "HH:MM" format to minutes
const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const checkIsOutsideOpeningHours = (
  entry: Entry,
  times: Ref<Entry['times']>
) => {
  const entries = inject<Ref<Entry[]>>('entries');

  if (!entry.times) {
    return false;
  }

  const { locationId } = useLocationsStore();
  const locationEntry = entries?.value.find(
    (e) =>
      e.type === 'location' &&
      e.location_id === locationId &&
      e.date === entry.date
  );

  if (!locationEntry) {
    return false;
  }

  const entryTimes = times?.value || entry.times;

  return !!entryTimes.find((time) =>
    isPartiallyOutside(
      {
        open: locationEntry.times[0]?.open,
        closed: locationEntry.times[locationEntry.times.length - 1]?.closed
      },
      time
    )
  );
};
