import gql from 'graphql-tag';

export const GET_MICRO_SITE = gql`
  query getMicroSite {
    microSite {
      id
      url
    }
  }
`;

export const CREATE_CUSTOM_BOOKING_WIDGET = gql`
  mutation createCustomBookingWidget($input: CreateCustomBookingWidgetInput!) {
    createCustomBookingWidget(input: $input) {
      customBookingWidget {
        publicId
      }
    }
  }
`;
