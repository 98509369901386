import gql from 'graphql-tag';

export const GET_CUSTOMER_SUMMARY = gql`
  query getCustomerSummary($id: Int!) {
    customerSummary(id: $id) {
      appointmentCount
      cancellationCount
      loyaltyPoints
      noShowCount
      orderCount
      totalDue
      totalSpent
    }
  }
`;
