<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.drawer.title'),
      description: $t('upgrade_subscription_page.register.drawer.description'),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.drawer.learn_more'),
      url: $t('upgrade_subscription_page.register.drawer.learn_more_url')
    }"
    imageName="drawer"
  />

  <div v-else>
    <router-view />
    <OpenDrawerModal
      v-if="showOpenDrawerModal"
      @close="showOpenDrawerModal = false"
    />
    <EmptyPageContent
      v-if="cashupEnabled && !isDrawerOpen"
      imageName="drawer"
      :text="{
        description: $t('drawer.register_closed'),
        btnPrimary: filters.capitalize(
          $t('global.actions.open_item', {
            item: $t('global.register').toLowerCase()
          })
        )
      }"
      @click="showOpenDrawerModal = true"
    />
    <div v-else>
      <PageHeader
        v-if="drawer || cashup"
        v-model:search="variables.search"
        :drawer="drawer || cashup"
        :hideFilters="showEmptyPageContent"
      />
      <BaseTable
        v-if="transactions.length"
        :headers="[
          {
            value: $t('global.date_time'),
            sort: 'transaction_at'
          },
          $t('global.description'),
          {
            value: $t('global.type'),
            hide: !cashupEnabled
          },
          {
            value: $t('global.amount_currency'),
            sort: 'amount'
          }
        ]"
        :rows="
          transactions.map((transaction) => ({
            id: transaction.id,
            routerLink: transaction.orderId
              ? {
                  name: 'drawer-item',
                  params: {
                    id: transaction.orderId
                  }
                }
              : null,
            cells: [
              filters.dateTime(transaction.transactionAt),
              transaction.orderNumber || transaction.description,
              {
                value: $t(`global.payments.${transaction.type.toLowerCase()}`),
                hide: !cashupEnabled
              },
              {
                value: filters.currency(transaction.amount),
                color: transaction.amount < 0 ? 'error' : null
              }
            ],
            actions: !transaction.orderId && !cashupEnabled ? ['delete'] : null
          }))
        "
        :sortOrder="{
          sortBy: variables.sortBy,
          direction: variables.direction
        }"
        @sort="
          ($event) => {
            variables.sortBy = $event.sortBy;
            variables.direction = $event.direction;
          }
        "
        @action="deleteTransaction"
        v-test="'drawer-transactions'"
      />
      <EmptyPageContent
        v-show="!loading && !transactions.length"
        imageName="drawer"
        :text="
          showEmptyPageContent
            ? {
                title: $t('drawer.empty_state.title'),
                description: $t('drawer.empty_state.description'),
                btnPrimary: $t('drawer.new_transaction'),
                btnSecondary: cashupEnabled
                  ? filters.capitalize(
                      $t('global.actions.close_item', {
                        item: $t('global.register')
                      })
                    )
                  : null
              }
            : null
        "
        videoId="756794171"
        :moreLink="
          showEmptyPageContent
            ? {
                text: $t('drawer.empty_state.more_text'),
                url: $t('drawer.empty_state.more_url')
              }
            : null
        "
        @click="onEmptyPageClick"
      />
      <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePagination } from '@/apollo/pagination';
import { useMutation, useQuery } from '@vue/apollo-composable';
import {
  GET_TRANSACTIONS,
  DELETE_TRANSACTION,
  GET_LOCATION_CASHUP,
  GET_DRAWER
} from './graphql';
import { useUserStore } from '@/stores/user';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useLocationsStore } from '@/stores/locations';
import { useRegisterStore } from '@/modules/register/stores/register';
import filters from '@/filters';
import { useRouter } from 'vue-router';
import { modal, flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';
import PageHeader from './PageHeader.vue';
import OpenDrawerModal from '@/modules/register/modals/open-drawer/index.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import LoadMore from '@/components/LoadMore.vue';
import { storeToRefs } from 'pinia';

const { hasFeatureFlag } = useUserStore();
const { getDrawerStatus } = useRegisterDrawerStore();
const { currentCashupId, isOpen: isDrawerOpen } = storeToRefs(
  useRegisterDrawerStore()
);
const { cashupEnabled } = storeToRefs(useRegisterStore());
const { locationId, dataScope } = useLocationsStore();
const router = useRouter();
const { t } = useI18n();

const showOpenDrawerModal = ref(false);
const cashup = ref(null);
const drawer = ref(null);

const defaultVariables = ref({
  search: '',
  direction: 'DESC',
  cashupId: currentCashupId.value,
  sortBy: 'transaction_at',
  onlyCash: !cashupEnabled.value
});

const fetchTransactions =
  !cashupEnabled.value || (cashupEnabled.value && currentCashupId.value);
const { transactions, offset, loading, variables, allDataFetched, fetchMore } =
  fetchTransactions
    ? usePagination({
        fieldName: 'transactions',
        query: GET_TRANSACTIONS,
        options: {
          offset: true,
          multiLocation: true
        },
        variables: defaultVariables.value
      })
    : {
        transactions: ref([]),
        offset: ref(0),
        loading: ref(false),
        variables: defaultVariables,
        allDataFetched: ref(true),
        fetchMore: () => {}
      };

const onEmptyPageClick = (item: string) => {
  if (item === 'primary') {
    router.push({ name: 'drawer-transaction' });
  } else if (item === 'secondary') {
    router.push({ name: 'drawer-close' });
  }
};

const showEmptyPageContent = computed(
  () => !transactions.value?.length && !variables?.search && !loading?.value
);

const deleteTransaction = ({ id }: { id: string }) => {
  const { mutate: mutateTransaction } = useMutation(DELETE_TRANSACTION, {
    update(cache) {
      const cacheId = `Transaction:${id}`;
      cache.evict({ id: cacheId, broadcast: false });
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
      cache.gc();
    }
  });

  modal('confirmation', {
    type: 'delete',
    item: t('global.items.transaction')
  }).then(() => {
    mutateTransaction({ input: { id } }).then(() => {
      flash(t('register.transaction_deleted'));
      offset.value--;
    });
  });
};

const fetchCashup = () => {
  const { onResult } = useQuery(GET_LOCATION_CASHUP, { id: locationId });

  onResult(
    ({
      data: {
        location: { openCashup }
      }
    }) => {
      if (!openCashup) {
        getDrawerStatus();
      }
      cashup.value = openCashup;
    }
  );
};

if (cashupEnabled.value && isDrawerOpen.value) {
  fetchCashup();
}

if (!cashupEnabled.value && hasFeatureFlag('module-register')) {
  const { onResult } = useQuery(GET_DRAWER, { locationId, dataScope });

  onResult(({ data }) => {
    drawer.value = data.drawer;
  });
}

watch(
  () => currentCashupId.value,
  (value) => {
    if (cashupEnabled.value && isDrawerOpen.value) {
      fetchCashup();
    }

    if (value) {
      variables.cashupId = value;
    }
  }
);
</script>
