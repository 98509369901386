<template>
  <BaseCard :mb="2" v-test="`billing-upgrade-account-card`">
    <div :class="$style.row">
      <BaseHeading size="l">
        {{ title }}
      </BaseHeading>
      <BaseHeading
        v-if="difference"
        v-test="'billing-upgrade-account-card-difference'"
      >
        + {{ filters.currency(difference.value, difference.currency) }} /
        {{ t(`global.items.${difference.period.toLowerCase()}`) }}
      </BaseHeading>
    </div>

    <BaseText v-if="description">
      {{ description }}
    </BaseText>

    <div :class="$style.featuresList">
      <div
        v-for="feature in features"
        :key="feature"
        :class="$style.planFeature"
      >
        <BaseIcon name="check" color="success" size="l" :mr="0.5" />
        <BaseText>{{ t(`subscription.features.${feature}`) }}</BaseText>
      </div>
    </div>
    <img
      src="/img/billing/person-looking-at-computer.svg"
      :class="$style.illustration"
    />
    <BaseButton
      @click="handleUpgradeButtonClick"
      v-test="'billing-upgrade-account-button'"
    >
      {{ buttonLabel }}
    </BaseButton>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import filters from '@/filters';
import type { Currency, SubscriptionPeriod } from '@/types';
import onUpgradeButtonClick from '@/modules/_shared/subscription/on-upgrade-button-click';

const { handleUpgradeButtonClick } = onUpgradeButtonClick();

const acceptedFeatures = [
  'online_discount_codes',
  'marketing_newsletters',
  'last_minute_discounts',
  'waiting_list',
  'manage_bookings',
  'run_your_salon_tasks',
  'loyalty_program'
];

type feature = (typeof acceptedFeatures)[number];

type difference = {
  period: SubscriptionPeriod;
  currency: Currency;
  value: number;
};

defineProps<{
  features: feature[];
  title: string;
  buttonLabel: string;
  description?: string;
  difference?: difference;
}>();

const { t } = useI18n();
</script>

<style lang="scss" module>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;
}

.featuresList {
  max-width: 80%;
  margin-top: 2 * $spacing;
  margin-bottom: 2 * $spacing;
}

.planFeature {
  display: flex;
  align-items: center;
}

.illustration {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0 $radius 0;
  max-width: 60%;
}
</style>
