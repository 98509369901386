import type { RumEvent } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import config from '@/config';

function reviewEnvironmentName() {
  return location.hostname.split('.')[1];
}

function operationNameFromBody(body: any): string | null {
  if (!body) {
    return null;
  }
  try {
    return JSON.parse(body).operationName;
  } catch {
    return null;
  }
}

const DISCARD_EVENTS = [
  /load failed/i,
  /csp_violation/i,
  /failed to fetch/i,
  /Received status code 401/i,
  /Unexpected token '<'/i,
  /Unexpected token </i,
  /Unrecognized token '<'/i,
  /Unexpected identifier "Bad"/i,
  /Fetch is aborted/i,
  /JSON.parse: unexpected character/i,
  /Unexpected token B in JSON/i,
  /Unexpected end of JSON input/i,
  /JSON Parse error: Unexpected EOF/i,
  /Parsen der Antwort nicht möglich./i,
  /kan antwoord niet parsen/i,
  /^cancelled$/i,
  /^geannuleerd$/i,
  /^Abgebrochen$/i,
  /De internetverbinding is offline./i,
  /The Internet connection appears to be offline./i,
  /The network connection was lost./i,
  /Es besteht anscheinend keine Verbindung zum Internet./i,
  /De netwerkverbinding is verbroken./i,
  /NetworkError when attempting to fetch resource./i,
  /ResizeObserver loop completed with undelivered notifications./i,
  /Script error./i,
  /ResizeObserver loop limit exceeded/i,
  /Field 'adminMedical' doesn't exist on type 'User'/i
];

function shouldDiscardEvent(event: RumEvent) {
  if (import.meta.env.VITE_CI) {
    return false;
  }
  if (event.type !== 'error') {
    return false;
  }
  if (event.error?.source === 'report') {
    return true;
  }
  return !!DISCARD_EVENTS.find((regex) =>
    regex.test(String(event.error?.message))
  );
}

if (config.datadogApplicationId) {
  datadogRum.init({
    applicationId: config.datadogApplicationId,
    clientToken: config.datadogClientToken,
    site: 'datadoghq.eu',
    service: config.datadogServiceName,
    sessionSampleRate: config.datadogSampleRate,
    sessionReplaySampleRate: config.datadogReplaySampleRate,
    traceSampleRate: config.datadogTraceSampleRate,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask',
    env: config.isStaging ? `review-${reviewEnvironmentName()}` : config.env,
    version: import.meta.env.VITE_DEPLOY_RELEASE,
    allowedTracingUrls: [
      /https:\/\/.*\.salonized\.com\/(?!(g\/collect))/,
      /http(s?):\/\/.*\.salonized-staging\.com\/(?!(g\/collect))/,
      /http(s?):\/\/.*\.salonized\.fml/
    ],
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: config.isProduction ? true : false,
    useCrossSiteSessionCookie: config.isProduction ? true : false,
    beforeSend: (event, context) => {
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        event.context = {
          ...event.context,
          operationName:
            'requestInit' in context
              ? operationNameFromBody(context.requestInit?.body)
              : null,
          requestUrl: 'response' in context ? context.response?.url : null,
          responseStatus:
            'response' in context ? context.response?.status : null
        };
      }
      if (shouldDiscardEvent(event)) {
        return false;
      }
    }
  });

  datadogRum.startSessionReplayRecording();
}
