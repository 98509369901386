<template>
  <div v-test="'two-factor-send-sms'">
    <div :class="$style.row">
      <BaseText inline left>
        {{ $t('two_factor.no_code') }}
      </BaseText>
      <BaseText inline link @click="send">
        {{ $t('two_factor.send_again') }}
      </BaseText>
    </div>
    <div :class="$style.row">
      <BaseText
        v-if="intervalExceeded"
        color="error"
        v-test="'send-sms-interval-exceeded'"
      >
        {{
          $t('account.auth.two_factor_settings.errors.time_interval_exceeded')
        }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SendSMS',
  props: {
    email: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },
  emits: ['smsSent'],
  data() {
    return {
      currentStatus: null,
      errors: []
    };
  },
  computed: {
    intervalExceeded() {
      return this.errors?.[0]?.timeInterval !== undefined;
    }
  },
  methods: {
    send() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation sendTwoStepLoginSms($input: SendTwoStepLoginSmsInput!) {
              sendTwoStepLoginSms(input: $input) {
                loginSmsResponse {
                  smsSentAt
                  maskedPhoneNumber
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              userEmail: this.email,
              userPassword: this.password
            }
          }
        })
        .then((response) => {
          this.currentStatus =
            response.data.sendTwoStepLoginSms.loginSmsResponse;
          this.errors = response.data.sendTwoStepLoginSms.errors;
          this.$emit('smsSent', this.currentStatus.maskedPhoneNumber);
        });
    }
  },
  created() {
    this.send();
  }
});
</script>

<style lang="scss" module>
.row {
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 2px;
  }
}
</style>
