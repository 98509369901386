<template>
  <div
    :class="[
      $style.base,
      {
        [$style.selected]: isSelected,
        'cy-selected': isSelected
      }
    ]"
    v-test="'register-browser-appointment-item'"
  >
    <div :class="$style.top">
      <BaseText
        v-if="item.customerName"
        bold
        iconBefore="person"
        oneLine
        v-test="'register-browser-appointment-customer'"
      >
        {{ item.customerName }}
      </BaseText>
      <BaseText
        v-if="item.date"
        iconBefore="calendar"
        size="s"
        v-test="'register-browser-appointment-date'"
      >
        {{ filters.dateTime(item.date) }}
      </BaseText>
    </div>
    <div :class="$style.bottom">
      <BaseText v-test="'register-browser-appointment-label'">
        {{ item.label }}
      </BaseText>
      <div v-test="'register-browser-appointment-price'">
        {{ filters.currency(item.price) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const props = defineProps<{
  item: any;
}>();

const isSelected = computed(() => {
  const { orderAppointments } = useRegisterOrderStore();
  return !!orderAppointments.find(
    (appointment) => appointment.id === props.item.id
  );
});
</script>

<style lang="scss" module>
.base {
  padding: 12px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: $radius;
  background-color: white;
  border: 1px solid white;
  cursor: pointer;

  &.selected {
    border-color: $color-primary;
  }

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}

.top {
  margin-bottom: 4px;
}

.top,
.bottom {
  display: flex;
  gap: $spacing * 0.5;
  justify-content: space-between;
  align-items: center;

  & > *:last-child {
    flex-shrink: 0;
  }
}
</style>
