<template>
  <BaseModalLarge
    :heading="`${$t('calendar.waiting_list')} ${filters.date(date)}`"
    testId="calendar-waiting-list-overview"
    parentRoute="calendar"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.header">
        <BaseText>
          {{
            $t('waiting_list.customers_on_date', {
              count: entries.length,
              date: filters.date(date, { format: 'long' })
            })
          }}
        </BaseText>
        <div :class="$style.buttons">
          <BaseButton
            v-if="hasFeatureFlag('waiting-list-pro')"
            :mr="0.25"
            color="inverted"
            :disabled="!(date && entries && entries.length > 0)"
            icon="paper-plane"
            @click="sendAll"
            v-test="'calendar-waiting-list-btn-send-all'"
          />
          <BaseButton
            :routerLink="{
              name: 'calendar-waiting-list-new',
              query: routeQuery
            }"
            v-test="'calendar-waiting-list-btn-create'"
          >
            {{ $t('waiting_list.new_registration') }}
          </BaseButton>
        </div>
      </div>
      <BaseCard>
        <EntriesList :items="entries" :date="date" :loading="isLoading" />
      </BaseCard>
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import filters from '@/filters';
import EntriesList from '@/modules/_shared/waiting-list/EntriesList.vue';
import {
  useEntriesQuery,
  emailCustomerAll
} from '@/modules/_shared/waiting-list/helpers';
import { useRouteDate } from '@/helpers/routing';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import type { WaitingListEntry } from '@/types';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    EntriesList
  },
  setup() {
    const { date } = useRouteDate({
      updateRouteQuery: true
    });

    const { entries, isLoading } = useEntriesQuery(date);

    return {
      date,
      entries,
      isLoading,
      filters
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    routeQuery() {
      const { resourceId } = useCalendarFiltersStore();
      const query = {
        date: this.date
      };

      if (resourceId) {
        query.resourceId = resourceId;
      }

      return query;
    }
  },
  methods: {
    sendAll() {
      emailCustomerAll(
        this.date,
        this.entries.map((entry: WaitingListEntry) => entry.id)
      );
    }
  }
});
</script>

<style lang="scss" module>
.header {
  margin-bottom: $spacing;

  .base:not(.smallScreen) & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .base.smallScreen & {
    & > *:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.buttons {
  display: flex;

  & > *:not(:first-child) {
    margin-left: $spacing * 0.5;
  }
}
</style>
