import { showTooltip, hideTooltip } from '@/helpers/ui';
import { usePageLayoutStore } from '@/stores/page-layout';
import { device } from '@/user-context';

const ComponentBase = {};

const marginProp = {
  type: [Boolean, Number],
  default: false,
  validator: (value) =>
    [true, false, -0.5, -1, 0.25, 0.5, 0, 1, 1.5, 2].indexOf(value) !== -1
};

const marginProps = {
  ml: marginProp,
  mt: marginProp,
  mr: marginProp,
  mb: marginProp
};

ComponentBase.install = (Vue) => {
  Vue.mixin({
    props: {
      ...marginProps,
      tooltip: {
        type: [String, Object],
        default: ''
      }
    },
    data() {
      return {
        tooltipVisible_: false
      };
    },
    watch: {
      '$route.path'() {
        this._addMarginClasses();
      },
      ml(newValue, oldValue) {
        this._updateMarginClass('ml', newValue, oldValue);
      },
      mt(newValue, oldValue) {
        this._updateMarginClass('mt', newValue, oldValue);
      },
      mr(newValue, oldValue) {
        this._updateMarginClass('mr', newValue, oldValue);
      },
      mb(newValue, oldValue) {
        this._updateMarginClass('mb', newValue, oldValue);
      },
      tooltip(newValue, oldValue) {
        if (oldValue && !newValue) {
          this._hideTooltip();
          this._removeEventListeners();
        } else if (newValue && !oldValue) {
          this._addEventListeners();
        }
      }
    },
    computed: {
      $screen() {
        const { screenSize } = usePageLayoutStore();
        return screenSize;
      }
    },
    methods: {
      _updateMarginClass(propName, newValue, oldValue) {
        if (
          !this.$el ||
          !this.$el.classList ||
          !propName ||
          typeof newValue === 'undefined'
        ) {
          return;
        }

        const newAmount =
          typeof newValue === 'boolean' ? (newValue ? 1 : 0) : newValue;
        const oldAmount =
          typeof oldValue === 'boolean' ? (oldValue ? 1 : 0) : oldValue;

        if (oldAmount) {
          this.$el.classList.remove(
            `${propName}-${oldAmount}`.split('.').join('')
          );
        }
        if (newAmount) {
          this.$el.classList.add(
            `${propName}-${newAmount}`.split('.').join('')
          );
        }
      },

      _addMarginClasses() {
        this.$nextTick(() => {
          Object.keys(marginProps).forEach((margin) => {
            if (this[margin]) {
              this._updateMarginClass(margin, this[margin]);
            }
          });
        });
      },
      _onMouseEnter() {
        this._showTooltip();
      },
      _onMouseLeave() {
        this._hideTooltip();
      },
      _onTouchClick(e) {
        if (e.cancelable) {
          e.preventDefault();
        }

        e.stopPropagation();

        if (!this.tooltipVisible_) {
          this._showTooltip();
          document.addEventListener('touchstart', this._onTouchStart);
        } else {
          this._closeTooltip();
        }
      },
      _closeTooltip() {
        this._hideTooltip();
        document.removeEventListener('touchstart', this._onTouchStart);
      },
      _onTouchStart(e) {
        if (!this.$el.contains(e.target)) {
          this._closeTooltip();
        }
      },
      _showTooltip() {
        if (!this.tooltipVisible_) {
          this.tooltipVisible_ = true;
          showTooltip(this.tooltip, this.$el);
        }
      },
      _hideTooltip() {
        if (this.tooltipVisible_) {
          this.tooltipVisible_ = false;
          hideTooltip();
        }
      },
      _addEventListeners() {
        if (!device || !device.touch) {
          this.$el.addEventListener('mouseenter', this._onMouseEnter);
          this.$el.addEventListener('mouseleave', this._onMouseLeave);
        } else if (this.tooltip?.touch) {
          this.$el.addEventListener('click', this._onTouchClick);
        }
      },
      _removeEventListeners() {
        if (!device || !device.touch) {
          this.$el.removeEventListener('mouseenter', this._onMouseEnter);
          this.$el.removeEventListener('mouseleave', this._onMouseLeave);
        } else if (this.tooltip?.touch) {
          this.$el.removeEventListener('click', this._onTouchClick);
        }
      }
    },
    mounted() {
      this._addMarginClasses();
      if (this.tooltip) {
        this._addEventListeners();
      }
    },
    updated() {
      this._addMarginClasses();
    },
    beforeUnmount() {
      if (this.tooltip) {
        this._removeEventListeners();
      }
      if (this.tooltipVisible_) {
        this._hideTooltip();
      }
    }
  });
};

export default ComponentBase;
