<template>
  <div :class="$style.base" @click="copyCode">
    <input ref="input" :class="$style.invisibleInput" :value="snippet" />
    <div :class="$style.codeField">
      <BaseText
        v-if="snippet"
        color="secondary"
        :iconAfter="showCopiedMessage ? 'check' : 'copy'"
        :iconColor="showCopiedMessage ? 'success' : 'default'"
        v-test="'code-snippet'"
      >
        {{ snippet }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  snippet: string;
}>();

const showCopiedMessage = ref(false);
const input = ref<HTMLInputElement>();

const copyCode = () => {
  if (input.value) {
    showCopiedMessage.value = true;

    input.value.select();
    input.value.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setTimeout(() => {
      showCopiedMessage.value = false;
    }, 3000);
  }
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.codeField {
  display: flex;
  align-items: center;
  background: $grey-light;
  padding: $spacing * 0.25;
  border-radius: $radius;
  border: 1px dashed #00000050;
  overflow-x: auto;
  white-space: pre;
  word-break: break-all;
  white-space: initial;
}

.invisibleInput {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
</style>
