import gql from 'graphql-tag';

export const GET_CUSTOMER = gql`
  query getCustomer($id: Int!) {
    customer(id: $id) {
      address
      alertNotes
      blocked
      city
      custom1
      custom10
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      custom9
      customerNotes
      customerTags {
        id
        name
        salonized
      }
      dateOfBirth
      email
      firstName
      gender
      invoiceNotes
      lastName
      mobilePhone
      phone
      picture
      zipcode
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
      }
      errors
    }
  }
`;
