<template>
  <BaseCard :heading="filters.capitalize($t('global.items.invoice', 2))">
    <BaseTable
      v-if="orders && orders.length"
      :headers="[
        $t('global.date'),
        $t('global.number'),
        $t('global.total'),
        ...vatHeaders,
        $t('global.payments.cash')
      ]"
      :rows="
        rows.map((r) => ({
          id: r.id,
          actions: ['pdf'],
          cells: [r.invoicedAt, r.number, r.total, ...r.vat, r.cash]
        }))
      "
      @action="onActionClick"
      v-test="'invoices-rows'"
    />
    <BaseText v-else v-test="'invoices-no-results'">
      {{ $t('global.no_results') }}
    </BaseText>
    <BaseAlert
      v-if="transactionCount > 100"
      :text="
        $t('cashups.missing_cash_transactions', {
          amount: transactionCount - 100
        })
      "
      mt
      v-test="'cash-transactions-limit'"
    />
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { defineComponent } from 'vue';
import type { Order, VatSummary } from '@/types';
import { uniqueArray } from '@/helpers/formatting';
import config from '@/config';

interface Row {
  id: string;
  invoicedAt: string | any;
  number: any;
  total: string;
  vat: string[];
  cash: string;
}

interface ActionItem {
  id: string;
  option: string;
}

interface vatArr {
  value: number | undefined;
}

export default defineComponent({
  props: {
    cashupId: {
      type: String,
      required: true
    },
    transactionCount: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      orders: [] as Order[]
    };
  },
  computed: {
    vatSummaries() {
      const arr: vatArr[] = [];
      this.orders.forEach((item) => {
        item.vatSummary?.forEach((vat) => {
          if (vat.percentage !== null) {
            arr.push({ value: vat.percentage });
          }
        });
      });

      return uniqueArray(arr, 'value').map((item) => item.value);
    },
    vatSummariesObj(): VatSummary {
      return this.vatSummaries.reduce(
        (acc, value) => ({ ...acc, [value]: '' }),
        {}
      );
    },
    vatHeaders(): string[] {
      const arr: string[] = [];
      Object.keys(this.vatSummariesObj).forEach((item) =>
        arr.push(item as string)
      );
      return arr.map((item) => `${item}%`);
    },
    rows(): Row[] {
      return this.orders.map((o) => ({
        id: o.id,
        invoicedAt: this.filters.dateTime(o.invoicedAt),
        number: o.medical ? `${o.number} - M` : o.number,
        total: this.filters.currency(o.total),
        vat: o.vatSummary ? this.formatPercentages(o.vatSummary) : [],
        cash: this.filters.currency(this.payedWithCashSum(o))
      }));
    }
  },
  methods: {
    onActionClick(item: ActionItem): void {
      window.open(`${config.backendUrl}/orders/${item.id}.pdf`, '_blank');
    },
    formatPercentages(vatSummary: VatSummary[]): string[] {
      const arr: string[] = [];
      const obj = this.vatSummaries.reduce(
        (acc, value) => ({ ...acc, [value]: '' }),
        {}
      );

      vatSummary.forEach((item) => {
        Object.keys(obj).forEach((key) => {
          if (parseInt(key) === item.percentage) {
            obj[item.percentage] = this.filters.currency(item.unitVat);
          }
        });
      });

      Object.values(obj).forEach((item) => arr.push(item as string));
      return arr;
    },
    payedWithCashSum(order: Order): number {
      if (order.transactions && order.transactions.length) {
        const cash = order.transactions
          .filter((transaction) => transaction.type === 'CASH')
          .map((transaction) => transaction.amount);
        return cash.length ? cash.reduce((a, b) => a + b) : 0;
      } else {
        return 0;
      }
    }
  },
  apollo: {
    orders: {
      query: gql`
        query getOrders(
          $cashupId: ID!
          $withTransactions: Boolean
          $pagination: PaginationAttributes
        ) {
          orders(
            cashupId: $cashupId
            withTransactions: $withTransactions
            pagination: $pagination
          ) {
            id
            total
            number
            medical
            invoicedAt
            vatSummary {
              percentage
              unitVat
            }
            transactions {
              id
              type
              amount
            }
          }
        }
      `,
      variables() {
        return {
          cashupId: this.cashupId,
          withTransactions: true,
          pagination: {
            currentPage: 1,
            perPage: 100
          }
        };
      }
    }
  }
});
</script>
