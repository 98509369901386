<template>
  <BaseModal
    :heading="$t('global.appointment_report')"
    small
    :parentRoute="parentRouteName"
  >
    <div v-if="appointmentReport">
      <BaseText mb v-test="'report-report'">
        <span v-html="filters.formatEnters(appointmentReport.report)" />
      </BaseText>
      <div v-if="appointmentReport.attachments.length" :class="$style.border">
        <BaseHeading size="s" :mb="0.5">
          {{ $t('global.attachments') }}
        </BaseHeading>
        <div :class="$style.container">
          <BaseImage
            v-for="(img, index) in appointmentReport.attachments"
            :key="index"
            :cloudinaryId="img.publicId"
            :mr="index < appointmentReport.attachments.length - 1"
            width="160"
            openOnClick
            format="jpg"
            v-test="'report-attachments'"
          />
        </div>
      </div>
      <div
        v-if="appointmentReport && appointmentReport.appointment"
        class="mt-2"
      >
        <BaseHeading mt size="s" :mb="0.5">
          {{ $t('global.items.appointment', 1) }}
        </BaseHeading>
        <div :class="$style.align" v-test="'report-appointment-employees'">
          <BaseIcon :mr="0.5" name="person" />
          {{ employeeNames }}
        </div>
        <div :class="$style.align" v-test="'report-appointment-datetime'">
          <BaseIcon :mr="0.5" name="clock" />
          {{ filters.dateTime(appointmentReport.appointment.startAt) }}
        </div>
        <div :class="$style.align" v-test="'report-appointment-services'">
          <BaseIcon :mr="0.5" name="activity" />
          {{ appointmentReport.appointment.serviceNames }}
        </div>
      </div>
    </div>
    <template v-if="appointmentReport" #footer>
      <BaseButton
        color="inverted"
        :router-link="`${appointmentReport.id}/edit`"
        v-test="'edit-report'"
      >
        {{ $t('global.actions.edit') }}
      </BaseButton>
      <BaseButton
        v-if="appointmentReport.appointment"
        color="inverted"
        :router-link="{
          name: 'appointment',
          params: { appointmentId: appointmentReport.appointment.id }
        }"
        v-test="'view-appointment'"
      >
        {{ $t('global.actions.view_appointment') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import { reportFragment } from '@/graphql-fragments';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    parentRouteName: String
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      employeeNames: ''
    };
  },
  apollo: {
    appointmentReport: {
      query: gql`
        query getAppointmentReport($id: Int!) {
          appointmentReport(id: $id) {
            ...report
          }
        }
        ${reportFragment}
      `,
      skip() {
        return !this.$route.params.id;
      },
      variables() {
        return {
          id: parseInt(this.$route.params.id)
        };
      },
      result() {
        this.getEmployeeNames();
      }
    }
  },
  computed: {
    ...mapState(useResourcesStore, ['resources', 'employees'])
  },
  methods: {
    getEmployeeNames() {
      const appointmentResourceNames = (appointmentParts: any): string => {
        let resources: any[] = [];
        appointmentParts.forEach((part: any) => {
          resources = [...resources, ...part.resources];
        });
        const resourceNames = [...new Set(resources)].map(
          (resource) => resource.name
        );
        return resourceNames.join(', ');
      };

      if (this.appointmentReport?.appointment?.parts) {
        this.employeeNames = appointmentResourceNames(
          this.appointmentReport.appointment.parts
        );
      }
    }
  }
});
</script>

<style lang="scss" module>
.border {
  padding-top: $spacing;
  border-top: 1px solid $color-border;
}

.align {
  display: flex;
  align-items: center;
  margin-bottom: $spacing * 0.5;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.temp {
  display: flex;
  flex-direction: column;
}
</style>
