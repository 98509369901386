<template>
  <div :class="$style.reviewMapping">
    <div :class="[$style.ratingLabel]">
      <div :class="[$style.rating]" :style="{ background: getBackground() }" />
      <svg
        width="31"
        height="31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g v-if="props.score === 5">
          <path
            d="M15.096 19.388a3.434 3.434 0 003.434-3.434h-6.868a3.434 3.434 0 003.434 3.434zM13.38 13.38a.858.858 0 11-1.718 0 .858.858 0 011.717 0zM17.671 14.238a.858.858 0 100-1.717.858.858 0 000 1.717z"
            fill="#32C977"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.68 15.096a8.584 8.584 0 11-17.168 0 8.584 8.584 0 0117.168 0zm-1.717 0a6.867 6.867 0 11-13.734 0 6.867 6.867 0 0113.734 0z"
            fill="#32C977"
          />
        </g>
        <g v-if="props.score === 4">
          <path
            d="M18.53 15.954h-1.717a1.717 1.717 0 11-3.434 0h-1.717a3.434 3.434 0 006.868 0zM13.38 13.38a.858.858 0 11-1.718 0 .858.858 0 011.717 0zM17.671 14.238a.858.858 0 100-1.717.858.858 0 000 1.717z"
            fill="#03CFA5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.68 15.096a8.584 8.584 0 11-17.168 0 8.584 8.584 0 0117.168 0zm-1.717 0a6.867 6.867 0 11-13.734 0 6.867 6.867 0 0113.734 0z"
            fill="#03CFA5"
          />
        </g>
        <g v-if="props.score === 3">
          <path
            d="M13.737 13.738a.881.881 0 11-1.762 0 .881.881 0 011.762 0zM18.144 14.619a.881.881 0 100-1.763.881.881 0 000 1.763z"
            fill="#E9DC6B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 24.314a8.814 8.814 0 100-17.627 8.814 8.814 0 000 17.627zm0-1.763a7.05 7.05 0 100-14.102 7.05 7.05 0 000 14.102z"
            fill="#E9DC6B"
          />
        </g>
        <g v-if="props.score === 2">
          <path
            d="M12.856 14.619a.881.881 0 100-1.763.881.881 0 000 1.763zM12.856 18.144a.881.881 0 100 1.763h5.288a.881.881 0 100-1.763h-5.288zM19.026 13.738a.881.881 0 11-1.763 0 .881.881 0 011.762 0z"
            fill="#FF974E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.314 15.5a8.814 8.814 0 11-17.628 0 8.814 8.814 0 0117.628 0zm-1.763 0a7.05 7.05 0 11-14.102 0 7.05 7.05 0 0114.102 0z"
            fill="#FF974E"
          />
        </g>
        <g v-if="props.score === 1">
          <path
            d="M12.856 14.619a.881.881 0 100-1.763.881.881 0 000 1.763zM17.263 19.907a1.763 1.763 0 00-3.526 0h-1.762a3.525 3.525 0 117.05 0h-1.762zM19.026 13.738a.881.881 0 11-1.763 0 .881.881 0 011.762 0z"
            fill="#ED495A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.314 15.5a8.814 8.814 0 11-17.628 0 8.814 8.814 0 0117.628 0zm-1.763 0a7.05 7.05 0 11-14.102 0 7.05 7.05 0 0114.102 0z"
            fill="#ED495A"
          />
        </g>
      </svg>
      <BaseText mr>
        {{ props.count }}
      </BaseText>
    </div>
    <div>
      <BaseIcon name="arrow-forward" ml mr />
    </div>
    <div :class="$style.treatwellRating">
      <BaseIcon
        v-for="starIndex in props.score"
        :key="`star-${starIndex}`"
        name="star-filled"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  score: number;
  count: number;
}>();

const getBackground = () => {
  if (props.score === 5) {
    return '#32C977';
  } else if (props.score === 4) {
    return '#03CFA5';
  } else if (props.score === 3) {
    return '#E9DC6B';
  } else if (props.score === 2) {
    return '#FF974E';
  }
  return '#ED495A';
};
</script>

<style lang="scss" module>
.reviewMapping {
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.treatwellRating {
  white-space: nowrap;

  path {
    fill: #ffc75d !important;
  }
}

.ratingLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5 * $spacing 0;
  position: relative;
}

.rating {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 500px;
  opacity: 0.25;
}

.rating5 {
  background: #32c977;
}

.rating4 {
  background: #03cfa5;
}
</style>
