<template>
  <div>
    <BaseScroll :contentWidth="400">
      <BaseTableRow :title="title" head>
        <BaseTableCell />
        <BaseTableCell
          v-for="quarter in quarters"
          :key="`header_${quarter}`"
          currency
        >
          {{ $t('reports.vat_overview.quarter', { quarter: quarter + 1 }) }}
        </BaseTableCell>
      </BaseTableRow>

      <BaseTableRow v-for="(row, index) in rows" :key="`row_${index}`" zebra>
        <BaseTableCell>
          {{
            row.percentage === null
              ? noVat
              : $t(`reports.vat_overview.vat_percentage`, {
                  percentage: row.percentage
                })
          }}
        </BaseTableCell>
        <BaseTableCell
          v-for="quarter in quarters"
          :key="`row_${index}_col_${quarter}`"
          currency
        >
          {{ filters.currency(row[attribute][quarter]) }}
        </BaseTableCell>
      </BaseTableRow>

      <BaseTableRow footer border>
        <BaseTableCell>
          {{ $t('reports.vat_overview.total') }}
        </BaseTableCell>
        <BaseTableCell
          v-for="quarter in quarters"
          :key="`footer_${quarter}`"
          currency
        >
          {{ filters.currency(totals[quarter]) }}
        </BaseTableCell>
      </BaseTableRow>
    </BaseScroll>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  name: 'QuartersTable',
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    title: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    noVat: {
      type: String,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    totals: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      quarters: [0, 1, 2, 3]
    };
  }
});
</script>
