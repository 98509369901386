<template>
  <BaseModal
    :heading="
      isEditing ? $t('dynamic_pricing.update') : $t('dynamic_pricing.create')
    "
    parentRoute="calendar"
    :loading="isLoading"
    small
  >
    <BaseRadio
      v-model="dynamicPrice.adjustmentType"
      :label="$t('dynamic_pricing.adjustment_type')"
      :options="adjustmentOptions"
      mb
      @update:modelValue="track($event)"
      v-test="'dynamic-price-adjustment'"
    />
    <BaseInput
      v-model="dynamicPrice.amount"
      type="number"
      unitLabel="percentage"
      :label="$t('global.percentage')"
      :minValue="1"
      :maxValue="100"
      mb
      v-test="'dynamic-price-percentage'"
    />
    <BaseAlert mb :text="$t('dynamic_pricing.instructions')" />
    <BaseInputLabel :text="$t('global.date_time')" />
    <div :class="{ [$style.disabled]: rruleUneditable }">
      <BaseDateTime
        v-model="dynamicPrice.startAt"
        v-model:to="dynamicPrice.endAt"
        v-test="'dynamic-price-date'"
      />
    </div>
    <BaseCheckbox
      v-model="enableRrule"
      :disabled="rruleUneditable"
      :label="$t('dynamic_pricing.repeat')"
      mt
      v-test="'dynamic-price-rrule-toggle'"
    />
    <div v-if="enableRrule" :class="{ [$style.disabled]: rruleUneditable }">
      <RecurringRules
        v-model="dynamicPrice.rrule"
        :date="dynamicPrice.startAt"
        mt
        v-test="'dynamic-price-rrule'"
      />
    </div>
    <BaseAlert
      v-if="rruleUneditable"
      color="warning"
      :text="$t('dynamic_pricing.recurring_disabled')"
      mt
      v-test="'rrule-disabled-message'"
    />
    <template #footerSub>
      <BaseText
        v-if="isEditing"
        iconBefore="delete"
        link
        color="error"
        @click="onDeleteClick"
        v-test="'dynamic-price-delete'"
      >
        {{ $t('global.actions.delete') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        :loading="isSaving"
        @click="submit"
        v-test="'dynamic-price-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { modal, flash } from '@/helpers/ui';
import dayjs from '@/dayjs';
import { reactive, ref, defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useLocationsStore } from '@/stores/locations';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import calendarInterface from '@/modules/calendar/calendar-interface';
import RecurringRules from '@/modules/calendar/RecurringRules.vue';
import { useUserStore } from '@/stores/user';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';

export default defineComponent({
  components: {
    RecurringRules
  },
  inject: ['mixpanel'],
  setup() {
    const route = useRoute();
    const id =
      typeof route.params.dynamicPriceId === 'string'
        ? route.params.dynamicPriceId
        : null;

    const dynamicPrice = reactive({
      amount: 10,
      adjustmentType: 'PERCENTAGE_DECREASE',
      endAt: '',
      rrule: null,
      startAt: ''
    });

    const enableRrule = ref(false);
    const rruleUneditable = ref(false);

    watch(enableRrule, () => {
      if (!enableRrule.value) {
        dynamicPrice.rrule = null;
      }
    });

    const isEditing = !!id;
    let isLoading;

    if (isEditing) {
      const { onResult, loading } = useQuery(
        gql`
          query getDynamicPrice($id: ID!) {
            dynamicPrice(id: $id) {
              amount
              adjustmentType
              endAt
              id
              rrule
              startAt
            }
          }
        `,
        { id }
      );
      onResult(({ data }) => {
        Object.keys(dynamicPrice).forEach((key) => {
          dynamicPrice[key] = data.dynamicPrice[key];
        });

        dynamicPrice.id = data.dynamicPrice.id;
        if (dynamicPrice.rrule) {
          enableRrule.value = true;
          rruleUneditable.value = true;
        }
      });

      isLoading = loading;
    } else {
      isLoading = ref(false);
      const { dateTimeFrom, dateTimeTo } = useCreateEventStore();

      if (dateTimeFrom && dateTimeTo) {
        // The modal was opened by selecting a calendar slot
        // Set the start and end to the values of the selected slots

        dynamicPrice.startAt = dateTimeFrom;
        dynamicPrice.endAt = dateTimeTo;
      } else {
        // The modal was opened by clicking a button or visiting the url directly
        // Set the start and end based on the calendar date

        const { date } = useCalendarFiltersStore();

        const now = dayjs.tz();
        const startAt = dayjs(date)
          .minute(0)
          .hour(now.hour() + 1);

        dynamicPrice.startAt = startAt.format();
        dynamicPrice.endAt = startAt.add(1, 'hour').format();
      }
    }

    const { locationId } = useLocationsStore();
    const { hasFeatureFlag } = useUserStore();

    return {
      enableRrule,
      rruleUneditable,
      dynamicPrice,
      isLoading,
      locationId,
      isSaving: ref(false),
      isEditing,
      hasFeatureFlag
    };
  },
  computed: {
    adjustmentOptions() {
      return [
        {
          label: this.$t(
            'dynamic_pricing.adjustment_types.percentage_decrease'
          ),
          value: 'PERCENTAGE_DECREASE'
        },
        {
          label: this.$t(
            'dynamic_pricing.adjustment_types.percentage_increase'
          ),
          value: 'PERCENTAGE_INCREASE'
        }
      ];
    }
  },
  methods: {
    track(value) {
      this.mixpanel.track(`Dynamic pricing adjustment value: ${value}`);
    },
    submit() {
      this.mixpanel.track('Dynamic pricing created');
      this.isSaving = true;
      (this.isEditing ? this.update : this.create)()
        .then(({ data }) => {
          const response = data.createDynamicPrice || data.updateDynamicPrice;
          if (response.errors?.find((error) => error.type === 'no_overlap')) {
            modal('warning', {
              message: this.$t('dynamic_pricing.no_overlap')
            });
          } else {
            this.afterMutationFinish(this.isEditing ? 'updated' : 'created');
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    create() {
      return this.$apollo.mutate({
        mutation: gql`
          mutation createDynamicPrice($input: CreateDynamicPriceInput!) {
            createDynamicPrice(input: $input) {
              dynamicPrice {
                id
              }
              errors {
                type
              }
            }
          }
        `,
        variables: {
          input: {
            ...this.dynamicPrice,
            locationId: this.locationId
          }
        }
      });
    },
    update() {
      return this.$apollo.mutate({
        mutation: gql`
          mutation updateDynamicPrice($input: UpdateDynamicPriceInput!) {
            updateDynamicPrice(input: $input) {
              dynamicPrice {
                id
              }
              errors {
                type
              }
            }
          }
        `,
        variables: {
          input: {
            ...this.dynamicPrice
          }
        }
      });
    },
    onDeleteClick() {
      modal('confirmation', {
        type: 'delete'
      }).then(() => {
        this.delete();
      });
    },
    delete() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteDynamicPrice($input: DeleteDynamicPriceInput!) {
              deleteDynamicPrice(input: $input) {
                dynamicPrice {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id: this.dynamicPrice.id
            }
          }
        })
        .then(() => {
          this.afterMutationFinish('deleted');
        });
    },
    afterMutationFinish(key) {
      flash(this.$t(`dynamic_pricing.${key}`));
      calendarInterface.api?.refetchEvents();
      this.$router.push({ name: 'calendar' });
    }
  }
});
</script>

<style lang="scss" module>
.disabled {
  & > * {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
