<template>
  <div>
    <div v-if="item">
      <EmptyPageContent
        v-if="!item.isEnabled"
        :text="{
          title: $t(item.text.title),
          description: $t(item.text.description),
          btnPrimary: $t(item.text.btnPrimary)
        }"
        :moreLink="{
          text: $t(item.text.btnSecondary),
          url: $t(item.hrefLink)
        }"
        :imageName="item.imageName"
        @click="handleClick"
      />
      <component :is="item.component" v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import DiscountIndex from './discounts/index.vue';
import EmailIndex from './email/index.vue';
import WaitingListIndex from './waiting-list/index.vue';
import FilterIndex from './filter-groups/index.vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import onUpgradeButtonClick from '@/modules/_shared/subscription/on-upgrade-button-click';

const { hasFeatureFlag } = useUserStore();
const { handleUpgradeButtonClick } = onUpgradeButtonClick();
const props = defineProps<{ route: string }>();

const item = computed(() =>
  marketingArray.find((item: any) => item.routeName === props.route)
);

const { t } = useI18n();

const handleClick = (button: string) => {
  if (button === 'primary') {
    handleUpgradeButtonClick();
  }
};

const marketingArray = reactive([
  {
    routeName: 'discounts',
    isEnabled: hasFeatureFlag('module-marketing'),
    text: {
      title: 'marketing.emptyStates.discounts.title',
      description: 'marketing.emptyStates.discounts.body',
      btnPrimary: 'global.upgrade_now',
      btnSecondary: 'global.learn_more'
    },
    hrefLink: t('marketing.insights.tips.lmd.link'),
    imageName: 'discounts',
    component: DiscountIndex
  },
  {
    routeName: 'email',
    isEnabled: hasFeatureFlag('module-marketing'),
    text: {
      title: 'marketing.emptyStates.email.title',
      description: 'marketing.emptyStates.email.body',
      btnPrimary: 'global.upgrade_now',
      btnSecondary: 'global.learn_more'
    },
    hrefLink: t('marketing.insights.tips.email.link'),
    imageName: 'email',
    component: EmailIndex
  },
  {
    routeName: 'waiting-list',
    isEnabled: hasFeatureFlag('module-waiting-list'),
    text: {
      title: 'marketing.emptyStates.waitingList.title',
      description: 'marketing.emptyStates.waitingList.body',
      btnPrimary: 'global.upgrade_now',
      btnSecondary: 'global.learn_more'
    },
    hrefLink: t('marketing.insights.tips.waiting-list.link'),
    imageName: 'marketing-waitinglist',
    component: WaitingListIndex
  },
  {
    routeName: 'filter',
    isEnabled: hasFeatureFlag('module-marketing'),
    text: {
      title: 'marketing.emptyStates.filter.title',
      description: 'marketing.emptyStates.filter.body',
      btnPrimary: 'global.upgrade_now',
      btnSecondary: 'global.learn_more'
    },
    hrefLink: t('marketing.insights.tips.filter-groups.link'),
    imageName: 'filters',
    component: FilterIndex
  }
]);
</script>
