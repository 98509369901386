<template>
  <BaseModalLarge
    :heading="t('admin.assistant.modal_title')"
    testId="salonized-assistant"
    @close="onClose"
  >
    <BaseCard fullWidth>
      <div :class="$style.cardContent">
        <TransitionGroup
          :name="movingForward ? 'list-slide-left' : 'list-slide-right'"
        >
          <OnlineBookingSelection
            v-if="currentStep === 0 && localSettings.settings.bookings"
            v-model="
              localSettings.settings.bookings.appointmentRequiresConfirmation
            "
            v-test="'get-started-wizard-step-bookings'"
          />
          <NewCustomersSelection
            v-if="currentStep === 1 && localSettings.settings.bookings"
            v-model="localSettings.settings.bookings.allowNewCustomers"
            v-test="'get-started-wizard-step-existing-customers'"
          />
          <EmployeeSelection
            v-if="currentStep === 2 && localSettings.settings.bookings"
            v-model="localSettings.settings.bookings.resourceSelection"
            v-test="'get-started-wizard-step-employees'"
          />
          <BookingIntervalSelection
            v-if="currentStep === 3 && localSettings.settings.agenda"
            v-model="localSettings.settings.agenda.appointmentInterval"
            v-test="'get-started-wizard-step-interval'"
          />
          <MinimumWalkInTimeSelection
            v-if="currentStep === 4 && localSettings.settings.bookings"
            v-model="localSettings.settings.bookings.leadTime"
            v-test="'get-started-wizard-step-walkin'"
          />
          <WaitingListSelection
            v-if="currentStep === 5 && localSettings.settings.bookings"
            v-model="localSettings.settings.bookings.waitingListEnabled"
            v-test="'get-started-wizard-step-waiting-list'"
          />
          <ColorAndSharing
            v-if="currentStep === 6 && localSettings.settings.bookingWidget"
            v-model="localSettings.settings.bookingWidget.customColor"
            v-test="'get-started-wizard-step-sharing'"
          />
        </TransitionGroup>
      </div>
    </BaseCard>
    <template #aside>
      <div
        :class="[
          $style.sallyWrapper,
          { [$style.smallScreen]: $screen === 's' }
        ]"
      >
        <div :class="$style.sallyMessages">
          <SpeechBubble v-if="message" :message="message" />
        </div>
        <img
          src="/img/salonized-assistant/clippy.svg"
          :class="[
            $style.sallyAvatar,
            {
              [$style.talking]: sallyIsTalking
            }
          ]"
        />
      </div>
    </template>
    <template #footerSub>
      <BaseButton
        :disabled="currentStep === 0"
        color="inverted"
        @click="prevStep"
        v-test="'get-started-wizard-prev'"
      >
        {{ $t('global.previous') }}
      </BaseButton>
    </template>
    <template #footer>
      <BaseButton
        :loading="isSaving"
        @click="submit"
        v-test="'get-started-wizard-next'"
      >
        {{
          currentStep < 6
            ? $t('global.actions.next')
            : $t('global.actions.complete')
        }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script setup lang="ts">
import SpeechBubble from './SpeechBubble.vue';

import { useI18n } from 'vue-i18n';
import { useStorage } from '@vueuse/core';
import OnlineBookingSelection from './steps/OnlineBookingSelection.vue';
import EmployeeSelection from './steps/EmployeeSelection.vue';
import NewCustomersSelection from './steps/NewCustomersSelection.vue';
import BookingIntervalSelection from './steps/BookingIntervalSelection.vue';
import MinimumWalkInTimeSelection from './steps/MinimumWalkInTimeSelection.vue';
import WaitingListSelection from './steps/WaitingListSelection.vue';
import ColorAndSharing from './steps/ColorAndSharing.vue';

import type { SettingsAttributes } from '@/types';
import { useCompanyStore } from '@/stores/company';

const { t } = useI18n();

const mixpanel = inject<any>('mixpanel');

import assistantMessages from './assistantMessage';

const { updateCompany } = useCompanyStore();

const props = defineProps<{ modelValue: { settings: SettingsAttributes } }>();
const localSettings = ref(props.modelValue);
const emit = defineEmits(['close', 'update:modelValue']);

// Steps + data structure
const currentStep = useStorage('assistant-step-online-bookings', 0);

// Message box
const message = ref<string>();

const addMessage = (newMessage: string) => {
  message.value = newMessage;
};

const clearMessage = () => {
  message.value = '';
};

const prevStep = () => {
  mixpanel.track('assistant-step-back', { step: currentStep.value });
  currentStep.value -= 1;
};

const isSaving = ref(false);

const submit = () => {
  mixpanel.track('assistant-step-submitted', { step: currentStep.value });
  isSaving.value = true;
  updateCompany(localSettings.value)
    .then(() => {
      message.value = '';
      emit('update:modelValue', localSettings);
    })
    .finally(() => {
      isSaving.value = false;
      if (currentStep.value >= 6) {
        emit('close');
      } else {
        currentStep.value += 1;
      }
    });
};

const onClose = () => {
  emit('close');
  mixpanel.track('assistant-step-closed', { step: currentStep.value });
};

const movingForward = ref(true);
watch(currentStep, (currentValue, previousValue) => {
  if (currentValue > previousValue) {
    movingForward.value = true;
  } else {
    movingForward.value = false;
  }

  if (
    localSettings.value.settings.bookings?.appointmentRequiresConfirmation &&
    currentValue === 1
  ) {
    if (previousValue === 0) {
      currentStep.value += 1;
    } else {
      currentStep.value -= 1;
    }
  }
});

// Animations and helper functions
watch(
  [currentStep, localSettings],
  () => {
    const sallyMessages = assistantMessages.find(
      (messages) => messages.step === currentStep.value
    );
    clearMessage();
    let message = null;
    switch (currentStep.value) {
      case 0:
        message = sallyMessages?.messages.find(
          (msg) =>
            msg.value ===
            localSettings.value.settings.bookings
              ?.appointmentRequiresConfirmation
        );
        break;
      case 1:
        message = sallyMessages?.messages.find(
          (msg) =>
            msg.value ===
            localSettings.value.settings.bookings?.allowNewCustomers
        );
        break;
      case 2:
        message = sallyMessages?.messages.find(
          (msg) =>
            msg.value ===
            localSettings.value.settings.bookings?.resourceSelection
        );
        break;
      case 3:
        message = sallyMessages?.messages.find(
          (msg) =>
            msg.value ===
            localSettings.value.settings.agenda?.appointmentInterval
        );
        break;
      case 4:
        message = sallyMessages?.messages.find(
          (msg) => msg.value === localSettings.value.settings.bookings?.leadTime
        );
        break;
      case 5:
        message = sallyMessages?.messages.find(
          (msg) =>
            msg.value ===
            localSettings.value.settings.bookings?.waitingListEnabled
        );
        break;
      case 6:
        message = { text: 'admin.assistant.share.assistant' };
        break;
    }
    if (message) {
      addMessage(t(message.text, { ...message.vars }));
    }
  },
  {
    immediate: true,
    deep: true
  }
);

const sallyIsTalking = ref(false);

watch(message, () => {
  if (message.value) {
    sallyIsTalking.value = true;
    setTimeout(() => {
      sallyIsTalking.value = false;
    }, 1000);
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: $spacing * 2 $spacing;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.sallyWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;

  &.smallScreen {
    flex-direction: row;
    align-items: center;
  }
}
.sallyAvatar {
  align-self: flex-end;
  max-height: 30vh;
  overflow: hidden;
  padding: $spacing;
  &.talking {
    animation: tilt-shaking 0.2s infinite;
  }

  &.smallScreen {
    padding: 0.25 * $spacing;
  }
}
.sallyMessages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cardContent {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $spacing;
}
.content {
  min-width: 70%;
  flex-grow: 1;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
