<template>
  <BaseModal
    :heading="
      note ? $t('global.actions.edit_note') : $t('global.actions.create_note')
    "
    small
    useForm
    @submit="submit"
    @close="$emit('close', formData)"
  >
    <BaseGrid container>
      <BaseGrid :size="12">
        <BaseInput
          v-model="formData.text"
          :label="$t('global.items.note', 1)"
          type="textarea"
          required
          focus
          v-test="'createNoteInput'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="formData.resourceId"
          :options="
            employees.map((e) => ({
              value: e.id,
              label: e.name
            }))
          "
          resources
          required
          :label="$t('global.items.employee', 1)"
          v-test="'select-employee'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDatePicker
          v-model="formData.date"
          :label="$t('global.actions.select_follow_up')"
          mb
          v-test="'createNoteDate'"
        />
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton submitForm :loading="saving" v-test="'createNoteSave'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useResourcesStore } from '@/stores/resources';
import { inject, reactive, ref } from 'vue';
import { CREATE_NOTE, UPDATE_NOTE } from '../graphql';
import { useMutation } from '@vue/apollo-composable';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  note: any;
}>();

const customer = inject<any>('customer');

const { hasSingleEmployee, employees } = useResourcesStore();

const formData = reactive({
  customerId: props.note?.customerId || customer.value.id,
  date: props.note?.date || null,
  id: props.note?.id || undefined,
  resourceId:
    props.note?.resourceId || (hasSingleEmployee ? employees[0].id : null),
  text: props.note?.text || ''
});

const emit = defineEmits(['created', 'updated', 'close']);
const saving = ref(false);

const { t } = useI18n();

const submit = () => {
  saving.value = true;

  if (props.note?.id) {
    const { mutate } = useMutation(UPDATE_NOTE);
    mutate({
      input: formData
    }).then(
      ({
        data: {
          updateNote: { note }
        }
      }) => {
        flash(t('global.flash.note_updated'));
        emit('updated', note);
      }
    );
  } else {
    const { mutate } = useMutation(CREATE_NOTE);
    mutate({
      input: formData
    }).then(
      ({
        data: {
          createNote: { note }
        }
      }) => {
        flash(t('global.flash.note_created'));
        emit('created', note);
      }
    );
  }
};
</script>
