<template>
  <div>
    <BaseAlert
      v-if="customer.blocked"
      mb
      :text="$t('customers.blocked_customer')"
      color="error"
      v-test="'customerBlockedLabel'"
    />
    <BaseGrid container>
      <BaseGrid :size="$screen !== 'l' ? 6 : 3">
        <BaseText
          :routerLink="{
            name: 'customers'
          }"
          iconBefore="arrow-left"
          mb
          @click="mixpanel.track('Customers-BackToCustomers')"
          v-test="'btn-back'"
        >
          {{ $t('customers.back_to_customers') }}
        </BaseText>
        <Actions :summary="summary" />
        <Details :customer="customer" />
        <Settings :customer="customer" @refresh="getHistory" />
      </BaseGrid>
      <BaseGrid :size="$screen !== 'l' ? 6 : 9">
        <Stats v-if="summary" :summary="summary" />
        <BaseSpinner v-else inline mb />
        <BaseGrid container>
          <BaseGrid :size="$screen !== 'l' ? 12 : 6">
            <History :history="history" @refresh="getHistory" />
          </BaseGrid>
          <BaseGrid :top="$screen !== 'l'" :size="$screen !== 'l' ? 12 : 6">
            <CustomerTags v-model="customerTagIds" saveImmediately :mb="1.5" />
            <Notes @refresh="getHistory" />
          </BaseGrid>
        </BaseGrid>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CustomerOverview'
};
</script>

<script lang="ts" setup>
import Stats from './Stats.vue';
import History from './History.vue';
import Notes from './Notes.vue';
import Actions from './Actions.vue';
import Details from './Details.vue';
import Settings from './Settings.vue';
import { computed, ref, watch, inject } from 'vue';
import axios from '@/axios';
import { useQuery } from '@vue/apollo-composable';
import { GET_CUSTOMER_SUMMARY } from './graphql';
import CustomerTags from '../../CustomerTags.vue';

const mixpanel = inject<any>('mixpanel');
const customer = inject<any>('customer');
const customerId = computed(() => customer.value?.id || null);
const summary = ref();
const customerTagIds = ref(
  customer.value.customerTags.map((tag: any) => tag.id)
);

const getCustomerSummary = () => {
  const { onResult } = useQuery(GET_CUSTOMER_SUMMARY, {
    id: customerId.value
  });

  onResult(({ data: { customerSummary } }) => {
    summary.value = customerSummary;
  });
};

watch(
  customerId,
  (customerId) => {
    if (customerId) {
      getCustomerSummary();
    }
  },
  {
    immediate: true
  }
);

const history = ref([]);
const getHistory = () => {
  axios
    .get(`/customers/${customerId.value}/timeline_items`)
    .then((response) => {
      history.value = response.data.timeline_items;
    });
};

watch(
  customerId,
  (customerId) => {
    if (customerId) {
      getHistory();
    }
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" module>
.leftCards {
  min-width: 300px;
}

.responsive {
  @media (max-width: 1300px) {
    width: 100%;
  }
}
</style>
