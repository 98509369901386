<template>
  <div v-if="unleash.isEnabled('SalonizedPay') && !adyenAppState.industryCode">
    <BaseVisualBanner
      :heading="
        replacePinapparaat(
          $t('register.salonized_pay.in_app.description'),
          company.country
        )
      "
      imagePath="/img/salonized-pay/checkout/awaiting-payment.svg"
      variant="small"
      :primaryAction="{
        text: $t('global.learn_more'),
        routerLink: { name: 'salonized-pay' }
      }"
      @primaryAction="fireMixpanel"
      v-test="'salonized-pay-banner'"
    />
  </div>
</template>
<script lang="ts" setup>
import { useAdyenStore } from '@/stores/adyen';
import { useCompanyStore } from '@/stores/company';
import { replacePinapparaat } from '@/helpers/formatting';
import unleash from '@/unleash';
const mixpanel = inject<any>('mixpanel');

const { company } = useCompanyStore();
const { adyenAppState } = useAdyenStore();
const props = defineProps<{ step: string }>();

const fireMixpanel = () => {
  mixpanel.track('Salonized Pay ad', { step: props.step });
};
</script>
