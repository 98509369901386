<template>
  <BaseCard gray>
    <BaseHeading mb>
      {{ t('promote.booking_widget.snippet_code') }}
    </BaseHeading>
    <BaseText mb>
      {{ t('promote.booking_widget.snippet_explanation') }}
    </BaseText>
    <div class="mb-1">
      <BaseText :href="t('giftcard_widget.snippet_instructions_url')">
        {{ t('promote.booking_widget.snippet_instruction_link.label') }}
      </BaseText>
    </div>
    <BaseCodeField
      :code="codeSnippet"
      multiline
      v-test="'giftcard-settings-snippet'"
    />
  </BaseCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';

defineEmits(['generate']);

const { t } = useI18n();
const giftcardSettingsData = inject<any>('giftcardSettingsData');

const codeSnippet = computed(() => {
  const { company, isTreatwellUser } = useCompanyStore();
  const {
    settings: { bookingWidget },
    languagePublic
  } = giftcardSettingsData;

  let dataAttrs: string = `data-company="${company.publicId}" data-color="${bookingWidget.customColor}" data-language="${languagePublic.toLowerCase()}"`;
  if (!bookingWidget.autoWidth) {
    dataAttrs = `${dataAttrs} data-width="${bookingWidget.width}"`;
  }

  if (bookingWidget.inline) {
    dataAttrs = `${dataAttrs} data-height="${bookingWidget.height}"`;
    dataAttrs = `${dataAttrs} data-inline="true"`;
  } else {
    dataAttrs = `${dataAttrs} data-position="${bookingWidget.position.toLowerCase()}"`;
  }

  if (isTreatwellUser) {
    dataAttrs = `${dataAttrs} data-treatwell="true"`;
  }

  let script = `<div class="salonized-voucher" ${dataAttrs}></div><script src="https://static-widget.salonized.com/loader.js"></`;
  // needed because it will be parsed as closing the script tag of this file regardless of being inside a string
  script += 'script>';

  return script;
});
</script>
