<template>
  <BaseVisualBanner
    :heading="bannerText"
    imagePath="/img/messages/upgrade-banner.svg"
    :primaryAction="
      isPro
        ? {
            text: t('email_templates.banner.button_pro'),
            routerLink: {
              name: 'marketing'
            }
          }
        : t('email_templates.banner.button_upgrade')
    "
    :secondaryAction="
      isPro || !canActivateTrial ? '' : t('email_templates.banner.button')
    "
    :variant="isPro ? 'small' : 'light'"
    color="green"
    :mt="2"
    @primaryAction="isPro ? null : handleUpgradeButtonClick()"
    @secondaryAction="requestTrial"
  />
</template>

<script setup lang="ts">
import onUpgradeButtonClick from '@/modules/_shared/subscription/on-upgrade-button-click';
import trialRequest from '@/modules/_shared/subscription/request-trial';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

const { handleUpgradeButtonClick } = onUpgradeButtonClick();
const { requestTrial } = trialRequest();

const { t } = useI18n();
const { hasFeatureFlag } = useUserStore();
const { company } = useCompanyStore();

const isPro = computed(() => hasFeatureFlag('module-marketing'));
const canActivateTrial = computed(() => !company?.marketingTrialEndsAt);

const bannerText = computed(() => {
  if (!hasFeatureFlag('module-register')) {
    return t('email_templates.banner.heading_appointments');
  } else if (!isPro.value) {
    return t('email_templates.banner.heading');
  } else {
    return t('email_templates.banner.heading_pro');
  }
});
</script>
