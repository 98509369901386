<template>
  <div
    ref="containerEl"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.resizeable]: isResizable
      }
    ]"
  >
    <div
      :class="$style.left"
      :style="
        isResizable
          ? {
              width: leftWidth + '%'
            }
          : undefined
      "
    >
      <slot name="left" />
    </div>
    <div
      v-if="isResizable"
      ref="resizeEl"
      :class="$style.resizer"
      @mousedown="onMouseDown"
      v-test="'resizer'"
    />
    <div
      :class="$style.right"
      :style="
        isResizable
          ? {
              width: rightWidth + '%'
            }
          : undefined
      "
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { useStorage } from '@vueuse/core';
import { device } from '@/user-context';

const containerEl = ref();

const isResizable = computed(() => {
  const { screenSize } = usePageLayoutStore();
  return screenSize === 'l' && !device.touch;
});

const onMouseDown = () => {
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const onMouseUp = () => {
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
};

const leftWidth = useStorage('registerWidth', 40);
const rightWidth = computed(() => 100 - leftWidth.value);
const minLeftWidth = 300;
const minRightWidth = 500;

const onMouseMove = (e: any) => {
  const containerRect = containerEl.value.getBoundingClientRect();
  const maxLeftWidth = containerRect.width - minRightWidth;

  const leftPx = e.clientX - containerRect.left;
  const newLeftPx = Math.min(Math.max(leftPx, minLeftWidth), maxLeftWidth);
  leftWidth.value = (newLeftPx / containerRect.width) * 100;
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
  display: flex;

  &.smallScreen {
    flex-direction: column;
  }
}

$fixedBrowserWidth: 320px;

.left {
  background-color: white;

  .base:not(.resizeable):not(.smallScreen) & {
    width: $fixedBrowserWidth;
    border-right: 1px solid $color-border;
  }
}

.right {
  background-color: $color-body-bg;

  .base:not(.resizeable):not(.smallScreen) & {
    width: calc(100% - #{$fixedBrowserWidth});
  }
}

.left,
.right {
  height: 100%;

  &:empty {
    display: none;
  }
}

.resizer {
  position: relative;
  height: 100%;
  width: 8px;
  margin: 0 -2px;
  cursor: col-resize;
  user-select: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $color-border-input;
  }

  &:before {
    left: 2px;
  }

  &:after {
    left: 5px;
  }
}
</style>
