<template>
  <BaseModal small @close="onNoClick">
    <div :class="$style.base">
      <BaseText size="l">
        {{ data.message }}
      </BaseText>
      <div
        v-if="conflictingTimes && conflictingTimes.length"
        :class="$style.warningWrap"
      >
        <BaseAlert
          :text="$t('appointment.recurring.confirmation.warning')"
          color="warning"
          mr
          v-test="'recurring-confirmation-warning'"
        />
        <BaseButton
          color="inverted"
          @click="toggleConflictingTimes"
          v-test="'toggle-times-button'"
        >
          {{
            showConflictingTimes
              ? $t('appointment.recurring.confirmation.hide_double_bookings')
              : $t('appointment.recurring.confirmation.show_double_bookings')
          }}
        </BaseButton>
      </div>
      <BaseGrid v-if="showConflictingTimes" v-test="'conflicting-times-table'">
        <BaseTableRow head>
          <BaseTableCell>{{
            $t('appointment.recurring.confirmation.double_bookings')
          }}</BaseTableCell>
        </BaseTableRow>
        <BaseTableRow
          v-for="(time, index) in conflictingTimes"
          :key="`row-${index}`"
          zebra
        >
          <BaseTableCell>
            {{
              filters.date(time, {
                format: 'long',
                addTime: true,
                addYear: true
              })
            }}
          </BaseTableCell>
        </BaseTableRow>
      </BaseGrid>
    </div>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="onNoClick"
        v-test="'recurring-cancel'"
        >{{ $t('global.no') }}</BaseButton
      >
      <BaseButton class="ml" @click="onYesClick" v-test="'recurring-confirm'">{{
        $t('global.yes')
      }}</BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import eventBus from '@/event-bus';
import gql from 'graphql-tag';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      showConflictingTimes: false
    };
  },
  apollo: {
    conflictingTimes: {
      query: gql`
        query getConflictingTimes(
          $appointmentId: Int
          $id: Int
          $locationId: Int
          $partsAttributes: [AppointmentPartAttributes!]
          $rrule: RruleAttributes
          $startAt: DateTime
        ) {
          conflictingTimes(
            appointmentId: $appointmentId
            id: $id
            locationId: $locationId
            partsAttributes: $partsAttributes
            rrule: $rrule
            startAt: $startAt
          )
        }
      `,
      variables() {
        if (this.data.appointmentData) {
          const { id, locationId, partsAttributes, rrule, startAt } =
            this.data.appointmentData;
          return { id, startAt, locationId, partsAttributes, rrule };
        } else {
          const { formData, existingAppointmentData } =
            useCreateAppointmentStore();
          const { locationId, partsAttributes, rrule, startAt } = formData;

          return {
            appointmentId: existingAppointmentData.id,
            locationId,
            partsAttributes,
            rrule,
            startAt
          };
        }
      }
    }
  },
  methods: {
    toggleConflictingTimes() {
      this.showConflictingTimes = !this.showConflictingTimes;
    },
    onNoClick() {
      eventBus.$emit('submit-confirmation-modal', false);
    },
    onYesClick() {
      eventBus.$emit('submit-confirmation-modal', true);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  padding-right: 40px;
}

.warningWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacing;
}
</style>
