<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="6">
        <BaseHeading>
          {{ $t('marketing.insights.stats.title') }}
        </BaseHeading>
      </BaseGrid>
      <BaseGrid :size="6" :alignRight="$screen !== 's'">
        <BaseSwitch v-model="selectedFilter" buttons :options="options" />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseCard
          fullHeight
          :loading="$apollo.loading"
          :heading="$t('marketing.insights.stats.online_bookings.title')"
        >
          <MainStat
            :value="marketingStats.onlineBookings"
            text="online_bookings.total"
          />
          <SubStat
            :value="totalDiscountUsage"
            :mainValue="marketingStats.onlineBookings"
            :subValue="totalDiscountUsage"
            text="discount_usage.total"
          />
          <SubStat
            :mainValue="totalDiscountUsage"
            :subValue="marketingStats.discountCodesUsed"
            text="discount_usage.discount_codes"
          />
          <SubStat
            :mainValue="totalDiscountUsage"
            :subValue="marketingStats.lastMinuteDiscountUsed"
            text="discount_usage.lmd"
          />
          <SubStat
            :mainValue="totalDiscountUsage"
            :subValue="marketingStats.dynamicPriceUsed"
            text="discount_usage.dynamic_price"
          />
          <BaseButton
            v-if="noDiscountActivity"
            :routerLink="{ name: 'marketing-discounts' }"
            mt
            v-test="'create-discount-code'"
          >
            {{ $t('marketing.insights.stats.discount_usage.create') }}
          </BaseButton>
        </BaseCard>
      </BaseGrid>
      <BaseGrid :size="6">
        <RebookStats />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseCard
          :loading="$apollo.loading"
          :heading="$t('marketing.insights.stats.waiting_list.title')"
        >
          <MainStat
            :value="marketingStats.waitingListEntries"
            text="waiting_list.subscribers"
            :heading="$t('global.subscribers')"
          />
          <BaseButton
            v-if="!waitingListEnabled"
            :routerLink="{ name: 'marketing-waiting-list' }"
            mt
            v-test="'enable-waiting-list'"
          >
            {{ $t('waiting_list.enable_waiting_list') }}
          </BaseButton>
        </BaseCard>
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseCard
          :loading="$apollo.loading"
          :heading="$t('marketing.insights.stats.marketing_campaign.title')"
        >
          <template #header>
            <BaseText
              :routerLink="{
                name: 'messages',
                query: { filter: 'marketing_campaign' }
              }"
            >
              {{ $t('nav.messages') }}
            </BaseText>
          </template>
          <MainStat
            :value="marketingStats.marketingMailings"
            text="marketing_campaign.send"
            mb
          />
          <MainStat
            :value="marketingStats.marketingMessages"
            text="marketing_campaign.delivered"
            mb
          />
          <SubStat
            :mainValue="marketingStats.marketingMessages"
            :subValue="marketingStats.marketingMessagesOpened"
            text="marketing_campaign.opened"
          />
          <SubStat
            :mainValue="marketingStats.marketingMessages"
            :subValue="marketingStats.marketingMessagesButtonClicked"
            text="marketing_campaign.clicked"
          />
        </BaseCard>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import MainStat from './MainStat.vue';
import SubStat from './SubStat.vue';
import gql from 'graphql-tag';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import RebookStats from './RebookStats.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MainStat,
    SubStat,
    RebookStats
  },
  data() {
    return {
      selectedFilter: 'month',
      startDate: dayjs().startOf('month').format(),
      endDate: dayjs().format(),
      discountCodes: [],
      lastMinuteDiscounts: null
    };
  },
  watch: {
    selectedFilter(value) {
      const range = value === 'week' ? 'isoWeek' : value;
      this.startDate = dayjs().startOf(range).format();
      this.endDate = dayjs().format();
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    options() {
      return [
        {
          label: this.$t('global.this_week'),
          value: 'week'
        },
        {
          label: this.$t('global.this_month'),
          value: 'month'
        },
        {
          label: this.$t('global.this_year'),
          value: 'year'
        }
      ];
    },
    totalDiscountUsage() {
      return (
        this.marketingStats?.discountCodesUsed +
        this.marketingStats?.lastMinuteDiscountUsed +
        this.marketingStats?.dynamicPriceUsed
      );
    },
    waitingListEnabled() {
      return this.companySettings.bookings?.waitingListEnabled;
    },
    noDiscountActivity() {
      return !this.discountCodes.length && !this.lastMinuteDiscounts?.enabled;
    }
  },
  apollo: {
    marketingStats: {
      query: gql`
        query MarketingStats($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
          marketingStats(startDate: $startDate, endDate: $endDate) {
            discountCodesUsed
            lastMinuteDiscountUsed
            dynamicPriceUsed
            onlineBookings
            marketingMailings
            marketingMessages
            marketingMessagesOpened
            marketingMessagesButtonClicked
            waitingListEntries
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
    },
    lastMinuteDiscounts: {
      query: gql`
        query getLastMinuteDiscounts {
          lastMinuteDiscounts {
            enabled
          }
        }
      `
    },
    discountCodes: {
      query: gql`
        query getDiscountCodes(
          $search: String
          $pagination: PaginationAttributes
        ) {
          discountCodes(search: $search, pagination: $pagination) {
            id
          }
        }
      `,
      variables() {
        return {
          search: '',
          pagination: {
            currentPage: 1,
            perPage: 25
          }
        };
      }
    }
  }
});
</script>
