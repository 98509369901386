import { useRouter } from 'vue-router';
import { isRouteAllowed } from '@/router/helpers';
import { useCompanyStore } from '@/stores/company';
import { useLocationsStore } from '@/stores/locations';

export const filterRoutes = (pages) => {
  const router = useRouter();

  return pages.filter((page) => {
    const route = router.resolve(page.path);
    return isRouteAllowed(route);
  });
};

export const getRoutePrefix = () => {
  const { companyId } = useCompanyStore();
  const { routeLocationId } = useLocationsStore();
  return `/c/${companyId}/l/${routeLocationId}/`;
};
