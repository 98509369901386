import gql from 'graphql-tag';

export const GET_CUSTOMER = gql`
  query getCustomer($id: Int!) {
    customer(id: $id) {
      activity
      address
      alertNotes
      birthdayWishesEnabled
      blocked
      city
      createdAt
      custom1
      custom10
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      custom9
      customerNotes
      customerTags {
        id
        name
        salonized
      }
      dateOfBirth
      email
      feedbackEmailEnabled
      firstName
      fullName
      gender
      id
      lastName
      mobilePhone
      newsletterEnabled
      phone
      picture
      rebookEmailEnabled
      remindersEmailEnabled
      remindersSmsEnabled
      state
      zipcode
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;

const noteFragment = gql`
  fragment customerNote on Note {
    completedAt
    createdAt
    customerId
    date
    id
    resourceId
    text
  }
`;

export const GET_NOTES = gql`
  query getNotes($customerId: ID) {
    notes(customerId: $customerId) {
      ...customerNote
    }
  }
  ${noteFragment}
`;

export const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        ...customerNote
      }
    }
  }
  ${noteFragment}
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        ...customerNote
      }
    }
  }
  ${noteFragment}
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      note {
        id
      }
    }
  }
`;
