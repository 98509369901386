import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { useCompanyStore } from '@/stores/company';
import type { CreateSubscriptionInput, SubscriptionFlowData } from '@/types';

const paymentMethods: string[] = ['credit_card', 'incasso', 'bank_transfer'];

type Country = {
  value: string;
  label: string;
};

export const useSubscriptionStore = defineStore('subscription', () => {
  const { company } = useCompanyStore();
  const countriesData = ref<Country[]>([]);
  const plansData = ref<SubscriptionFlowData['plans']>([]);
  const isSubmitted = ref(false);
  const formData = reactive<CreateSubscriptionInput>({
    billingPeriod: 'monthly',
    companyAttributes: {
      phone: '',
      country: company.country,
      city: '',
      postalcode: '',
      address: '',
      billingInfoAttributes: {
        iban: '',
        ibanBic: '',
        paymentMethod: paymentMethods[1]
      },
      vatNumber: null
    },
    plan: 'pro'
  });
  const userDetailsValid = ref(false);
  const errors = reactive<Record<string, unknown>>({});

  const selectedPlanData = computed(() =>
    plansData.value.find((plan) => plan.type === formData.plan)
  );
  const cantUpgradeFromPlan = computed(() => plansData.value.length === 1);

  const getData = () => {
    const { isSubscribed } = useCompanyStore();

    apolloClient
      .query({
        query: gql`
          query getSubscriptionFlowData($upgrade: Boolean = false) {
            subscriptionFlowData(upgrade: $upgrade) {
              countries
              plans {
                chargeableEmployeeCount
                chargeableEquipmentCount
                chargeableLocationCount
                chargeableRoomCount
                features
                current
                pricings {
                  basePrice
                  currency
                  locationPrice
                  resourcePrice
                  referralReward
                }
                type
              }
            }
          }
        `,
        variables: {
          upgrade: isSubscribed
        }
      })
      .then(
        ({
          data: {
            subscriptionFlowData: { plans, countries }
          }
        }) => {
          const countriesArray = Object.keys(countries).map((key) => ({
            value: key,
            label: countries[key]
          }));

          countriesData.value = countriesArray.sort((a, b) =>
            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
          );
          plansData.value = plans;
        }
      );
  };

  const postSubscription = () =>
    new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation createSubscription($input: CreateSubscriptionInput!) {
              createSubscription(input: $input) {
                errors
                stripeSessionId
                subscription {
                  id
                  billingPeriod
                }
              }
            }
          `,
          variables: {
            input: formData
          }
        })
        .then(
          ({
            data: {
              createSubscription: { errors: validationErrors, stripeSessionId }
            }
          }) => {
            if (validationErrors) {
              // Reset object first to remove previous errors
              Object.keys(errors).forEach((key) => delete errors[key]);

              // Add new error keys
              Object.keys(validationErrors).forEach((key) => {
                errors[key] = validationErrors[key];
              });
              reject();
            } else {
              resolve(stripeSessionId);
            }
          }
        );
    });

  return {
    formData,
    countriesData,
    plansData,
    selectedPlanData,
    cantUpgradeFromPlan,
    isSubmitted,
    paymentMethods,
    userDetailsValid,
    errors,
    getData,
    postSubscription
  };
});
