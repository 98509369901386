import gql from 'graphql-tag';

export const GET_EMAIL_DOMAIN = gql`
  query getEmailDomain {
    emailDomain {
      cnames {
        key
        value
      }
      dkimRecordValue
      emailProvider
      id
      name
      status
    }
  }
`;

export const CREATE_SES_IDENTITY = gql`
  mutation createSesIdentity($input: CreateSesIdentityInput!) {
    createSesIdentity(input: $input) {
      emailDomain {
        status
      }
      errors {
        attribute
        message
        type
      }
    }
  }
`;

export const VERIFY_DOMAIN = gql`
  mutation verifyDomain($input: VerifyDomainInput!) {
    verifyDomain(input: $input) {
      domainStatus
      emailDomain {
        status
      }
      errors {
        attribute
        message
        type
      }
    }
  }
`;
