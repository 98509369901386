<template>
  <BaseModal
    :heading="$t('feedback.reply.edit_modal.title')"
    :loading="loading"
    testId="edit-reply"
    @close="$emit('close')"
  >
    <BaseHeading :mb="0.5">
      {{ $t('feedback.reply.edit_modal.original') }}
    </BaseHeading>
    <BaseCard mb gray v-test="'edit-reply-original'">
      <div v-html="originalMessage" />
    </BaseCard>
    <BaseHeading :mb="0.5">
      {{ $t('feedback.reply.edit_modal.new') }}
    </BaseHeading>
    <BaseInput
      v-if="editingMode"
      v-model="newMessage"
      type="textarea"
      v-test="'edit-reply-textarea'"
    />
    <BaseCard v-else gray v-test="'edit-reply-new'">
      <div v-html="newMessage" />
    </BaseCard>
    <BaseText
      link
      inline
      :mt="0.5"
      @click="editingMode = !editingMode"
      v-test="'edit-reply-toggle'"
    >
      {{
        editingMode
          ? $t('global.actions.cancel')
          : $t('feedback.reply.edit_modal.edit')
      }}
    </BaseText>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$emit('close')"
        v-test="'edit-reply-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="saving"
        @click="mutate"
        v-test="'edit-reply-submit'"
      >
        {{ $t('global.actions.apply_changes') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { inject, ref, watch } from 'vue';
import {
  HTML_TO_PLAIN_TEXT,
  UPDATE_FEEDBACK_RESPONSE
} from '@/modules/feedback/graphql';
import { useQuery, useMutation } from '@vue/apollo-composable';

const emit = defineEmits(['close', 'submit']);

const selected = inject<any>('selected');
const originalMessage = selected.value.messages[0]?.message || '';
const newMessage = ref('');
const newMessagePrevious = ref('');

const editingMode = ref(false);

watch(editingMode, (editingMode) => {
  if (editingMode) {
    newMessagePrevious.value = newMessage.value;
  } else {
    newMessage.value = newMessagePrevious.value;
  }
});

const { onResult, loading } = useQuery(HTML_TO_PLAIN_TEXT, {
  htmlText: originalMessage
});

onResult(
  ({
    data: {
      htmlToPlainText: { text }
    }
  }) => {
    newMessage.value = text || '';
    newMessagePrevious.value = text || '';
  }
);

const {
  mutate,
  onDone,
  loading: saving
} = useMutation(UPDATE_FEEDBACK_RESPONSE, () => ({
  variables: {
    input: {
      feedbackId: selected.value.id,
      id: selected.value.messages[0].id,
      text: newMessage.value
    }
  }
}));

onDone(() => {
  selected.value.messages[0].message = newMessage.value;
  emit('submit');
});
</script>
