<template>
  <BaseModal
    :heading="$t('email_templates.footer.heading')"
    useForm
    small
    @submit="submitForm"
    @close="emit('close')"
  >
    <BaseAlert :text="$t('email_templates.footer.description')" mb />
    <BaseEditor
      v-model="content"
      html
      :cloudinaryName="UploadedImageUsage.NewsletterContent"
      v-test="'footer-template-content'"
    />
    <BaseText
      link
      mt
      :iconAfter="showVariables ? 'chevron-up' : 'chevron-down'"
      @click="showVariables = !showVariables"
    >
      {{
        showVariables
          ? $t('email_templates.footer.hide_variables')
          : $t('email_templates.footer.show_variables')
      }}
    </BaseText>
    <Variables v-if="showVariables" :variables="variables" mt />
    <template #footer>
      <BaseButton
        color="inverted"
        @click="emit('close')"
        v-test="'footer-template-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="saving"
        submitForm
        v-test="'footer-template-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import Variables from '@/modules/_shared/Variables.vue';
import { UploadedImageUsage } from '@/types';

const props = defineProps<{
  content: string;
  variables: string[];
}>();

const emit = defineEmits(['close', 'update']);

const showVariables = ref(false);
const content = ref(props.content);

const { mutate, loading: saving } = useMutation(gql`
  mutation updateCompanyEmailTemplate(
    $input: UpdateCompanyEmailTemplateInput!
  ) {
    updateCompanyEmailTemplate(input: $input) {
      companyEmailTemplate {
        id
      }
    }
  }
`);

const submitForm = () => {
  mutate({
    input: {
      id: 'email_footer',
      content: content.value,
      subject: null
    }
  }).then(() => {
    emit('close');
    emit('update', content.value);
  });
};
</script>
