<template>
  <BaseCard :heading="$t('products.products_ordered')" :headerMargin="false" mt>
    <div :class="$style.base">
      <BaseScroll
        v-if="stockOrder && stockOrder.orderItems.length"
        :contentWidth="700"
      >
        <BaseTableRow head>
          <BaseTableCell :width="40">
            {{ $t('global.items.product', 1) }}
          </BaseTableCell>
          <BaseTableCell :width="20">
            {{ $t('global.part_number') }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ $t('global.amount') }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ $t('global.quantity') }}
          </BaseTableCell>
          <BaseTableCell
            v-if="stockOrder.status !== 'ordered'"
            right
            :width="20"
            v-test="'quantityReceivedHead'"
          >
            {{ $t('global.received') }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ $t('global.total') }}
          </BaseTableCell>
        </BaseTableRow>
        <BaseTableRow
          v-for="(item, index) in stockOrder.orderItems"
          :key="index"
          zebra
          v-test="'zoomOrderItems'"
        >
          <BaseTableCell :width="40">
            {{ item.product && item.product.name ? item.product.name : '-' }}
          </BaseTableCell>
          <BaseTableCell :width="20">
            {{
              item.product && item.product.partNumber
                ? item.product.partNumber
                : '-'
            }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ filters.currency(item.costPrice) }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ item.quantity }}
          </BaseTableCell>
          <BaseTableCell
            v-if="stockOrder.status !== 'ordered'"
            :width="20"
            right
            v-test="'quantityReceivedCell'"
          >
            <BaseLabel
              v-if="item.quantityReceived < item.quantity"
              color="error"
            >
              {{ item.quantityReceived }}
            </BaseLabel>
            <BaseText v-else>
              {{ item.quantityReceived }}
            </BaseText>
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ filters.currency(item.total) }}
          </BaseTableCell>
        </BaseTableRow>

        <BaseTableRow footer>
          <BaseTableCell :width="40" />
          <BaseTableCell :width="20" />
          <BaseTableCell :width="20" />
          <BaseTableCell v-if="stockOrder.status !== 'ordered'" :width="20" />
          <BaseTableCell :width="20" right>
            {{ $t('global.total') }}
          </BaseTableCell>
          <BaseTableCell right :width="20">
            {{ filters.currency(totalAmount) }}
          </BaseTableCell>
        </BaseTableRow>
      </BaseScroll>
      <BaseText v-else mt>
        {{ $t('global.no_results') }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    stockOrder: {
      type: Object
    },
    totalAmount: {
      type: Number,
      required: false
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  min-height: 200px;
}
</style>
