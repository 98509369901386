<template>
  <div>
    <BaseDropdown
      v-show="!isHidden"
      v-model="allocation.resourceId"
      :options="resourceListItems"
      :label="$t(`global.items.${resourceType}`, 1)"
      :loading="!isFirstAllocation && isFetchingRequirements"
      resources
      :required="!showAny"
      :disabled="disabled"
      :scrollContainer="scrollContainer"
      v-test="'part-resource'"
    />
  </div>
</template>

<script lang="ts">
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import type { Requirement } from '@/stores/calendar-create-appointment/requirements';
import { storeToRefs } from 'pinia';
import { requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { resourceIcon } from '@/helpers/formatting';
import type { PropType } from 'vue';
import { defineComponent, inject } from 'vue';
import type { AllocationAttributes, Resource } from '@/types';
import { useResourcesStore } from '@/stores/resources';

export default defineComponent({
  props: {
    allocation: {
      type: Object as PropType<AllocationAttributes>,
      required: true
    },
    partIndex: {
      type: Number as PropType<number>,
      required: true
    },
    allocationIndex: Number as PropType<number>,
    disabled: {
      type: Boolean as PropType<Boolean>,
      default: false
    },
    allAllocations: {
      type: Array as PropType<AllocationAttributes[]>,
      required: true
    }
  },
  setup() {
    const { isFetchingRequirements, isFetchingAvailabilities } = storeToRefs(
      useCreateAppointmentStore()
    );
    const scrollContainer = inject<HTMLElement>('scrollContainer');

    return {
      isFetchingRequirements,
      isFetchingAvailabilities,
      v$: useVuelidate(),
      scrollContainer
    };
  },
  validations() {
    return {
      allocation: {
        resourceId: {
          required: requiredIf(!this.showAny)
        }
      }
    };
  },
  watch: {
    validResourceIds: {
      handler: 'setDefaultResource',
      deep: true,
      immediate: true
    },
    isFetchingData: 'setDefaultResource',
    'resources.length': {
      handler(value) {
        // When the location only has one resource of the selected type, select that resource by default
        if (value === 1) {
          this.allocation.resourceId = this.resources[0]?.id || null;
        }
      },
      immediate: true
    }
  },
  computed: {
    isHidden() {
      const { hasSingleEmployee } = useResourcesStore();
      return (
        hasSingleEmployee &&
        this.allocationIndex === 0 &&
        this.resourceType === 'employee'
      );
    },
    isFetchingData() {
      return this.isFetchingRequirements || this.isFetchingAvailabilities;
    },
    requirement(): Requirement | null {
      const { requirementById } = useCreateAppointmentStore();
      return this.allocation.requirementId
        ? requirementById(this.allocation.requirementId) || null
        : null;
    },
    resourceType(): Resource['type'] {
      return this.requirement?.type.toLowerCase() || 'employee';
    },
    resources(): Resource[] {
      if (!this.resourceType) {
        return [];
      }

      const { resources } = useCreateAppointmentStore();
      // Use only resources that are of the same type as the requirement type, and which are not used for other requirements
      return resources.filter(
        (resource) =>
          resource.type === this.resourceType &&
          !this.disabledResourceIds.includes(resource.id)
      );
    },
    disabledResourceIds() {
      if (this.allAllocations?.length === 1) {
        return [];
      }

      // Resources that are selected for another requirement
      return this.allAllocations
        .filter(
          (allocation) =>
            allocation.resourceId &&
            allocation.requirementId &&
            allocation.requirementId !== this.allocation.requirementId
        )
        .map((allocation) => allocation.resourceId);
    },
    resourceListItems(): any[] {
      const resources = this.resources.map((resource) => ({
        value: resource.id,
        label: resource.name,
        warnings: [
          {
            icon: 'alert',
            color: 'error',
            tooltip: this.$t('appointment.modal.part.requirement_warning', {
              name: resource.name
            }),
            show: this.isNotInRequirements(resource.id)
          },
          {
            icon: 'calendar',
            color: 'warning',
            tooltip: this.$t('appointment.modal.part.availability_warning', {
              name: resource.name
            }),
            show: this.isNotAvailable(resource.id)
          }
        ].filter((warning) => warning.show)
      }));

      if (this.showAny) {
        resources.unshift({
          label: this.$t('appointment.create.any_resource'),
          value: null,
          icon: resourceIcon(this.resourceType)
        });
      }

      return resources;
    },
    validResourceIds(): Resource['id'][] {
      // The id's of all resources that are in the requirements and also are available
      return this.resources
        .filter(
          (r) => !this.isNotInRequirements(r.id) && !this.isNotAvailable(r.id)
        )
        .map((resource) => resource.id);
    },
    isFirstAllocation(): boolean {
      return this.allocationIndex === 0;
    },
    showAny(): boolean {
      // Wether or not to show the "Any" option in the resource dropdown
      // Only show when there are multiple valid resources, and not for the first allocation
      return !this.isFirstAllocation && this.validResourceIds.length > 1;
    }
  },
  methods: {
    isNotInRequirements(resourceId: Resource['id']): boolean {
      if (!this.requirement) {
        return false;
      }

      return !this.requirement.resourceIds.includes(resourceId);
    },
    isNotAvailable(resourceId: Resource['id']): boolean {
      const { partAvailabilities } = useCreateAppointmentStore();
      const availableResourceIds =
        partAvailabilities[this.partIndex]?.availableResourceIds;

      if (!availableResourceIds) {
        return false;
      }

      return !availableResourceIds.includes(resourceId);
    },
    setDefaultResource() {
      // Added a nextTick here because a test failed after adding a nextTick in the onAvailabilityDataChange method in the store.
      // I assumed this would not be needed, but it can't hurt.
      this.$nextTick(() => {
        // When there is only one valid resource, we need to set that as the selected resourceId
        // Don't do this if data is still being fetched (requirements or availabilities)
        // Or if its the first allocation, or it the allocation already has a resource selected
        if (
          this.isFetchingData ||
          this.isFirstAllocation ||
          this.allocation.resourceId
        ) {
          return;
        }

        if (this.validResourceIds.length === 1) {
          this.allocation.resourceId = this.validResourceIds[0];
        }
      });
    }
  }
});
</script>
