<template>
  <BaseModal
    :heading="$t('treatwell.direct_debit_modal.heading')"
    useForm
    @submit="submit"
    @close="close"
  >
    <BaseHeading mb>
      {{ $t('treatwell.direct_debit_modal.content.heading') }}
    </BaseHeading>
    <BaseText mb>
      {{ $t('treatwell.direct_debit_modal.content.paragraph_1') }}
    </BaseText>
    <BaseText mb>
      {{ $t('treatwell.direct_debit_modal.content.reason.heading') }}
    </BaseText>
    <ul>
      <li>
        {{ $t('treatwell.direct_debit_modal.content.reason.debit_invoice') }}
      </li>
      <li>
        {{ $t('treatwell.direct_debit_modal.content.reason.reverse_debit') }}
      </li>
      <li :class="$style.link" v-html="supportMessage" />
    </ul>
    <BaseCheckbox
      v-model="formData.mandateAccepted"
      required
      mt
      :label="$t('treatwell.direct_debit_modal.mandate')"
      v-test="'treatwell-contract-accept-checkbox'"
    />
    <template #footerSub>
      <BaseText link @click="close" v-test="'tw-direct-debit-dismiss'">
        {{ $t('treatwell.direct_debit_modal.content.remind_me_later') }}
      </BaseText>
    </template>
    <template #footer>
      <BaseButton
        :disabled="isLoadingIntegrationRequest || isLoadingVenue"
        :loading="isSaving"
        submitForm
        v-test="'tw-onboarding-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { computed, ref, reactive } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import type { TreatwellIntegrationRequest, TreatwellVenue } from '@/types';
import gql from 'graphql-tag';

const GET_TREATWELL_VENUE = gql`
  query getTreatwellVenue {
    treatwellVenue {
      ddEnabledDate
    }
  }
`;

const GET_TREATWELL_CURRENT_INTEGRATION_REQUEST = gql`
  query getTreatwellCurrentIntegrationRequest {
    treatwellCurrentIntegrationRequest {
      contractSigned
    }
  }
`;

const TREATWELL_UPDATE_DIRECT_DEBIT = gql`
  mutation treatwellUpdateDirectDebit($input: UpdateDirectDebitInput!) {
    treatwellUpdateDirectDebit(input: $input) {
      clientMutationId
    }
  }
`;

const { t } = useI18n();

const emit = defineEmits(['close']);

const isSaving = ref(false);

const formData = reactive({
  mandateAccepted: false
});

// Fetch the current integration request
const { loading: isLoadingIntegrationRequest, onResult } = useQuery(
  GET_TREATWELL_CURRENT_INTEGRATION_REQUEST
);
const currentIntegrationRequest = ref<TreatwellIntegrationRequest | null>(null);
onResult(({ data: { treatwellCurrentIntegrationRequest } }) => {
  currentIntegrationRequest.value = treatwellCurrentIntegrationRequest;
});

// Fetch the current venue
const { loading: isLoadingVenue, onResult: onVenueResult } =
  useQuery(GET_TREATWELL_VENUE);
const currentVenue = ref<TreatwellVenue | null>(null);
onVenueResult(({ data: { treatwellVenue } }) => {
  currentVenue.value = treatwellVenue;
});

const submit = () => {
  if (!currentVenue.value) {
    return;
  }

  const { mutate, onDone } = useMutation(TREATWELL_UPDATE_DIRECT_DEBIT, {
    variables: {
      input: {
        clientMutationId: currentVenue.value.id
      }
    }
  });
  mutate();
  isSaving.value = true;
  onDone(() => {
    isSaving.value = false;
    emit('close');
  });
};

const close = () => {
  emit('close');
};

const supportMessage = computed(() => {
  const url = t('treatwell.direct_debit_modal.content.reason.contact.url');
  const linkLabel = t(
    'treatwell.direct_debit_modal.content.reason.contact.link_label'
  );
  const link = `<a href="${url}" target="_blank">${linkLabel}</a>`;
  return t('treatwell.direct_debit_modal.content.reason.contact.text', {
    link
  });
});
</script>

<style lang="scss" module>
.link {
  a {
    color: $color-primary;
    white-space: no-wrap;
  }
}
</style>
