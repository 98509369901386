<template>
  <div>
    <BaseDropdown
      v-model="selectedCategoryId"
      :label="$t('global.category')"
      :options="
        serviceCategoriesWithDefault.map((c) => ({
          value: c.id,
          label: c.name
        }))
      "
      mb
      v-test="'select-service-categories'"
    />
    <BaseDropdown
      v-model="selectedServicesIds"
      :label="$t('global.items.service', 2)"
      :options="
        filteredServices.map((f) => ({
          value: f.id,
          label: f.name
        }))
      "
      searchable
      @update:modelValue="emitValue"
      v-test="'select-services'"
    />
  </div>
</template>

<script lang="ts">
import { useServicesStore } from '@/stores/services';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      selectedCategoryId: null,
      selectedServicesIds: []
    };
  },
  computed: {
    services() {
      const { services } = useServicesStore();
      return [...services].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
    serviceCategoriesWithDefault() {
      const { serviceCategories } = useServicesStore();
      const arr = [...serviceCategories].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      if (this.selectedCategoryId) {
        if (!arr.find((item) => item.default)) {
          arr.unshift({
            id: null,
            name: this.$t('global.show_all'),
            default: true
          });
        }
      }
      return arr;
    },
    filteredServices() {
      return this.selectedCategoryId
        ? this.services.filter(
            (service) => service.category.id === this.selectedCategoryId
          )
        : this.services;
    }
  },
  methods: {
    setCategory(category) {
      this.selectedCategoryId = category.id;
    },
    emitValue() {
      const values = this.selectedServicesIds;
      const obj = {
        filter_name: 'services',
        values
      };
      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue(null);
  }
});
</script>
