<template>
  <BaseModal
    :heading="$t('global.actions.create_note')"
    small
    useForm
    testId="create-note"
    @close="$emit('close', $event)"
    @submit="submit"
  >
    <BaseInput
      v-model="description"
      :label="$t('global.description')"
      type="textarea"
      required
      mb
      :focus="true"
      v-test="'note-description'"
    />
    <BaseRadio
      v-if="appliesToOptions.length"
      v-model="appliesTo"
      inline
      :label="$t('global.applies_to')"
      :options="appliesToOptions"
      mb
    />
    <BaseDropdown
      v-show="appliesTo !== 'company'"
      v-model="resourceId"
      :options="
        employees.map((e) => ({
          value: e.id,
          label: e.name
        }))
      "
      :label="filters.capitalize($t('global.items.employee', 1))"
    />

    <template #footer>
      <BaseButton :loading="isLoading" submitForm v-test="'note-submit'">
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import gql from 'graphql-tag';
import { noteFragment } from '@/graphql-fragments';

export default defineComponent({
  emits: ['close', 'created'],
  setup() {
    return { filters };
  },
  data() {
    return {
      description: '',
      date: dayjs().format(),
      appliesTo: 'company',
      isLoading: false,
      resourceId: null
    };
  },
  watch: {
    appliesTo(value) {
      if (value === 'company') {
        this.resourceId = null;
      }
      if (value === 'employee') {
        this.resourceId = this.employees[0].id;
      }
    }
  },
  computed: {
    ...mapState(useResourcesStore, ['employees']),
    appliesToOptions() {
      if (this.employees.length === 1) {
        return [];
      } else {
        return [
          {
            label: this.$t('global.entire_company'),
            value: 'company'
          },
          {
            label: this.filters.capitalize(this.$t('global.items.employee', 1)),
            value: 'employee'
          }
        ];
      }
    }
  },
  methods: {
    submit() {
      this.isLoading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createNote($input: CreateNoteInput!) {
              createNote(input: $input) {
                note {
                  ...note
                }
              }
            }
            ${noteFragment}
          `,
          variables: {
            input: {
              date: this.date,
              resourceId: this.resourceId,
              text: this.description
            }
          }
        })
        .then(
          ({
            data: {
              createNote: { note }
            }
          }) => {
            this.$emit('created', note);
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
