import gql from 'graphql-tag';

export const GET_MESSAGES = gql`
  query getMessages(
    $search: String
    $pagination: PaginationAttributes
    $filter: String
  ) {
    messages(search: $search, pagination: $pagination, filter: $filter) {
      id
      message
      messageType
      createdAt
      customer {
        id
        fullName
        email
        mobilePhone
      }
      destination
      gateway
      gatewayCode
      gatewayMessage
      status
      subject
      openedAt
      buttonClickAt
    }
  }
`;
