import createResourceAvatar from './CreateResourceAvatar';

export default (resource) =>
  `<div 
      class="calendar-resource"
      data-test="calendar-resource"
    >
    ${createResourceAvatar(resource)}
    <div class="calendar-resource__name">${resource.name}</div>
  </div>`;
