<template>
  <BaseModal
    small
    :heading="$t('marketing.discounts.delete-discount-code-modal.title')"
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseText bold>
      {{ $t('marketing.discounts.delete-discount-code-modal.subtitle') }}
    </BaseText>

    <BaseText :mb="2">
      {{ $t('marketing.discounts.delete-discount-code-modal.description') }}
    </BaseText>

    <BaseCheckbox
      v-model="accept"
      :label="$t('marketing.discounts.delete-discount-code-modal.accept')"
      mb
      v-test="'accept'"
    />

    <template #footer>
      <BaseButton color="inverted" @click="routeSwitch" v-test="'deleteCancel'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>

      <BaseButton
        :disabled="!accept"
        @click="onDeleteClick"
        v-test="'deleteConfirm'"
      >
        {{ $t('marketing.discounts.delete-discount-code-modal.delete_button') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    parentRouteName: String
  },
  emits: ['close'],
  data() {
    return {
      accept: false
    };
  },
  methods: {
    routeSwitch() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },
    onDeleteClick() {
      const id = parseInt(this.$route.params.discountId);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteDiscountCode($input: DeleteDiscountCodeInput!) {
              deleteDiscountCode(input: $input) {
                discountCode {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id
            }
          },
          update(cache) {
            const cacheId = `DiscountCode:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .then(() => {
          flash(this.$t('global.flash.discount_code_deleted'));
          this.$router.push({ name: 'marketing-discounts' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
