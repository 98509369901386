<template>
  <BaseModal
    small
    :heading="$t('onboarding.new_onboarding.appointment.header')"
    @close="handleClose"
  >
    <div :class="$style.base">
      <Confetti v-test="'confetti'" />
      <img src="/img/onboarding/appointment.svg" />
      <BaseText bold size="l" mt mb>
        {{ $t('onboarding.new_onboarding.appointment.title') }}
      </BaseText>
      <BaseButton @click="handleSubmit" v-test="'btn-onboarding-finish'">
        {{ $t('onboarding.new_onboarding.appointment.button') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import Confetti from '@/components/Confetti.vue';
import eventBus from '@/event-bus';
import { useOnboardingStore } from '@/stores/onboarding';
import { toRefs } from 'vue';

const { currentOnboardingFlow } = toRefs(useOnboardingStore());

const emit = defineEmits(['submit', 'close']);

const handleSubmit = () => {
  emit('submit');
  currentOnboardingFlow.value = 'appointment';
};

const handleClose = () => {
  emit('close');
  eventBus.$emit('open-task-menu');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
