<template>
  <BaseCard :heading="$t('cashups.starting_balance')">
    <BaseTable
      disableZebra
      :headers="[$t('global.description'), $t('global.amount')]"
      :rows="
        rows.map((r) => ({
          cells: [r.label, filters.currency(r.value)]
        }))
      "
      :footers="[
        $t('global.result'),
        filters.currency(cashup.expectedTotalCash)
      ]"
      v-test="'starting-balance-rows'"
    />
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

interface Row {
  label: string;
  value: number;
}

export default defineComponent({
  props: {
    cashup: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    rows(): Row[] {
      const { openingAmount, earnings, expenses } = this.cashup;
      return [
        {
          label: this.$t('cashups.starting_balance'),
          value: openingAmount
        },
        {
          label: `+ ${this.$t('cashups.earnings')}`,
          value: earnings
        },
        {
          label: `- ${this.$t('cashups.expenses')}`,
          value: expenses
        }
      ];
    }
  }
});
</script>
