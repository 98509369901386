<template>
  <div
    v-if="stripeErrors.length > 0"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <BaseAlert
      v-for="(error, index) in stripeErrors"
      :key="`stripe-subscription-error-${index}`"
      :text="$t(`subscription.stripe_error.${error.type}`)"
      color="error"
      v-test="'stripe-subscription-error-alert'"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useStripeStore } from './store';

const { stripeErrors } = storeToRefs(useStripeStore());
</script>

<style lang="scss" scoped>
.base {
  &:not(.smallScreen) {
    padding: $spacing $spacing * 1.5;
  }
}
</style>
