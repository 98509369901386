<template>
  <ModuleBase noBorder gray>
    <PageWrap
      wide
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's' || $screen === 'm'
        }
      ]"
      :heading="$t('admin.nav.links.roster')"
    >
      <router-view @updated="getEntries" />
      <Flex wrap :mb="2">
        <BaseDatePicker
          v-model="date"
          range="week"
          arrows
          v-test="'roster-select-date'"
        />
        <BaseButton
          color="inverted"
          @click="date = dayjs().format('YYYY-MM-DD')"
        >
          {{ $t('global.today') }}
        </BaseButton>
      </Flex>
      <BaseSpinner v-show="loading" />
      <div v-if="entries.length" v-show="!loading">
        <div :class="$style.rowHeader">
          <div :class="$style.rowHeaderLeft" v-test="'roster-date'">
            <BaseHeading size="l">
              {{ filters.capitalize($t('global.items.week', 1)) }}
              {{ weekNumber }}
            </BaseHeading>
            <BaseText>
              {{
                filters.date(weekStart, {
                  format: 'monthDay'
                })
              }}
              -
              {{
                filters.date(weekEnd, {
                  format: 'monthDay'
                })
              }}
            </BaseText>
          </div>
          <div :class="$style.rowHeaderRight">
            <div v-for="index in 7" :key="index" :class="$style.rowHeaderCell">
              <BaseHeading size="l">
                {{
                  dayjs(weekStart)
                    .add(index - (weekStartsSunday ? 2 : 1), 'day')
                    .format('DD')
                }}
              </BaseHeading>
              {{ filters.weekdayShort(index - (weekStartsSunday ? 1 : 0)) }}
            </div>
          </div>
        </div>
        <div :class="$style.rows">
          <Row :location="location" />
        </div>
        <BaseHeading mb>
          {{ filters.capitalize($t('global.items.employee', 2)) }}
        </BaseHeading>
        <div :class="$style.rows">
          <Row
            v-for="(employee, index) in employees"
            :key="index"
            :resource="employee"
          />
        </div>
        <BaseHeading v-if="rooms.length" mb>
          {{ filters.capitalize($t('global.items.room', 2)) }}
        </BaseHeading>
        <div :class="$style.rows">
          <Row v-for="(room, index) in rooms" :key="index" :resource="room" />
        </div>
        <BaseHeading v-if="equipment.length" mb>
          {{ filters.capitalize($t('global.items.equipment', 2)) }}
        </BaseHeading>
        <div :class="$style.rows">
          <Row v-for="(eq, index) in equipment" :key="index" :resource="eq" />
        </div>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import dayjs from '@/dayjs';
import filters from '@/filters';
import axios from '@/axios';
import { useLocationsStore } from '@/stores/locations';
import Row from './Row.vue';
import { useResourcesStore } from '@/stores/resources';
import type { Entry } from './types';
import { storeToRefs } from 'pinia';
import { useCompanyStore } from '@/stores/company';

const date = ref(dayjs().format('YYYY-MM-DD'));
const weekNumber = computed(() => dayjs(date.value).isoWeek());
const weekStart = computed(() => dayjs(date.value).startOf('isoWeek').format());
const weekEnd = computed(() => dayjs(date.value).endOf('isoWeek').format());

const { companySettings } = useCompanyStore();
const weekStartsSunday = companySettings.locale.firstDayOfWeek === 'SUNDAY';

provide('date', date);

const { locationId, location } = storeToRefs(useLocationsStore());
const { employees, rooms, equipment } = storeToRefs(useResourcesStore());

const entries = ref<Entry[]>([]);

provide('entries', entries);

const loading = ref(false);

const getEntries = () => {
  loading.value = true;

  axios
    .get(`/roster_entries?date=${date.value}&location_id=${locationId.value}`)
    .then(({ data }) => {
      entries.value = data.roster_entries;
      loading.value = false;
    });
};

watch(
  [date, locationId],
  () => {
    getEntries();
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" module>
.rows {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:not(:last-child) {
    margin-bottom: $spacing * 2;
  }

  &:empty {
    display: none;
  }
}

.rowHeader {
  margin-bottom: $spacing;

  .base:not(.smallScreen) & {
    display: flex;
  }
}

.rowHeaderRight {
  display: flex;
  gap: 4px;
  width: 100%;
}

.rowHeaderLeft {
  .base:not(.smallScreen) & {
    width: 180px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .base.smallScreen & {
    margin-bottom: $spacing;
  }
}

.rowHeaderCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7);
}
</style>
