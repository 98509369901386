import gql from 'graphql-tag';

export const getCompanyStats = gql`
  query getCompanyStats {
    companyStats {
      totalCustomers
      totalInvoices
      totalAppointments
    }
  }
`;
