<template>
  <svg width="222" height="121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M222 117.5c0 1.933-49.696 3.5-111 3.5S0 119.433 0 117.5s49.696-3.5 111-3.5 111 1.567 111 3.5z"
      fill="#F0F0F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M109.055 4.951c-8.792 2.432-17.215 10.91-22.304 18.2l-2.68-1.872C89.294 13.793 98.275 4.54 108.182 1.8c5.041-1.394 10.354-1.106 15.432 1.98 5.027 3.054 9.643 8.74 13.591 17.78l-2.996 1.309c-3.795-8.69-8.049-13.716-12.293-16.295-4.193-2.548-8.553-2.814-12.862-1.623z"
      fill="#03CFA5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M118.101 4.951c-8.791 2.432-17.215 10.91-22.303 18.2l-2.681-1.872C98.34 13.793 107.323 4.54 117.23 1.8c5.04-1.394 10.353-1.106 15.431 1.98 5.027 3.054 9.643 8.74 13.592 17.78l-2.997 1.309c-3.795-8.69-8.048-13.716-12.293-16.295-4.193-2.548-8.553-2.814-12.862-1.623z"
      fill="#03CFA5"
    />
    <path
      d="M70.575 26.285a6.242 6.242 0 016.242-6.241h90.644a6.242 6.242 0 016.242 6.241v84.131a6.242 6.242 0 01-6.242 6.242H76.817a6.242 6.242 0 01-6.242-6.242v-84.13z"
      fill="#A7C0F4"
    />
    <path
      d="M66.957 26.285a6.242 6.242 0 016.242-6.241h81.778a6.242 6.242 0 016.242 6.241v84.131a6.242 6.242 0 01-6.242 6.242H73.198a6.242 6.242 0 01-6.241-6.242v-84.13z"
      fill="#CDF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M171.943 4.321c0-2.386 1.922-4.321 4.294-4.321H198a4 4 0 014 4v21.206c0 2.387-1.922 4.321-4.294 4.321-2.371 0-4.294-1.934-4.294-4.32V14.89l-52.225 53.05a3 3 0 01-4.333-.06l-18.592-19.957a1 1 0 00-1.434-.03L73.206 91 67 85.026l48.425-48.236a3 3 0 014.312.08l18.684 20.056a1 1 0 001.444.02l47.608-48.304h-11.236c-2.372 0-4.294-1.934-4.294-4.32z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M131.5 116.658a163.544 163.544 0 01-47.698-62.074l-3.095-7.053-11.58 69.127H131.5z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M45.607 54.058h36.186s-4.071 45.502-7.238 47.221c-3.166 1.719-19.178 0-21.71 0-2.533 0-7.238-47.221-7.238-47.221z"
      fill="#494DBE"
    />
    <path
      d="M52.844 101.279h21.711v11.993a3.567 3.567 0 01-3.567 3.567H56.412a3.567 3.567 0 01-3.567-3.567v-11.993z"
      fill="#FF6575"
    />
    <path
      d="M45.607 49.728a3.27 3.27 0 013.27-3.27h29.646a3.27 3.27 0 013.27 3.27v4.33H45.607v-4.33z"
      fill="#6266DD"
    />
    <path d="M53 104h21.5v3H53v-3z" fill="#D53A79" />
    <path
      d="M156.696 97.88a5.647 5.647 0 015.647-5.647H188.5a5.647 5.647 0 015.647 5.647v13.13a5.648 5.648 0 01-5.647 5.648h-26.157a5.648 5.648 0 01-5.647-5.648V97.88z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M156.696 82.902a2.972 2.972 0 012.972-2.972h31.507a2.972 2.972 0 012.972 2.972v6.358a2.972 2.972 0 01-2.972 2.973h-31.507a2.972 2.972 0 01-2.972-2.973v-6.358z"
      fill="#D53A79"
    />
    <g opacity=".3" fill="#FF6575">
      <path
        d="M161.655 80h-.758v12h.758V80zM159.759 80H159v12h.759V80zM162.793 80h.759v12h-.759V80zM165.448 80h-.758v12h.758V80zM166.586 80h.759v12h-.759V80zM169.241 80h-.758v12h.758V80zM170.379 80h.759v12h-.759V80zM173.034 80h-.758v12h.758V80zM174.172 80h.759v12h-.759V80zM176.828 80h-.759v12h.759V80zM177.965 80h.759v12h-.759V80zM180.621 80h-.759v12h.759V80zM181.759 80h.758v12h-.758V80zM184.414 80h-.759v12h.759V80zM185.552 80h.758v12h-.758V80zM188.207 80h-.759v12h.759V80zM189.345 80h.758v12h-.758V80zM192 80h-.759v12H192V80z"
      />
    </g>
    <path
      opacity=".6"
      d="M190.466 92.695c3.397 1.404.379 4.955-3.247 4.347l-28.171-4.725 29.141-.07a5.91 5.91 0 012.277.448z"
      fill="#494DBE"
    />
    <path
      d="M159.103 96.561l-.2 13.211c-.01.692.905.822 1.049.145.375-1.764.489-3.106.946-6.291.412-2.862-.204-5.496-.897-7.27-.215-.548-.89-.384-.898.205z"
      fill="#FDB0CF"
      fill-opacity=".5"
    />
    <path
      d="M70.575 75.769c0 6.494-2.957 11.76-6.604 11.76-3.647 0-6.604-5.266-6.604-11.76 0-6.496 2.957-11.76 6.604-11.76 3.647 0 6.604 5.264 6.604 11.76z"
      fill="#2E3076"
    />
    <path
      d="M50.492 46.459h-.723v7.599h.723v-7.6zM48.683 46.459h-.724v7.599h.724v-7.6zM51.578 46.459h.724v7.599h-.724v-7.6zM54.11 46.459h-.723v7.599h.724v-7.6zM55.197 46.459h.723v7.599h-.724v-7.6zM57.73 46.459h-.724v7.599h.723v-7.6zM58.815 46.459h.724v7.599h-.724v-7.6zM61.348 46.459h-.724v7.599h.724v-7.6zM62.434 46.459h.723v7.599h-.723v-7.6zM64.966 46.459h-.723v7.599h.723v-7.6zM66.052 46.459h.724v7.599h-.724v-7.6zM68.585 46.459h-.724v7.599h.724v-7.6zM69.67 46.459h.724v7.599h-.724v-7.6zM72.204 46.459h-.724v7.599h.724v-7.6zM73.289 46.459h.724v7.599h-.724v-7.6zM75.822 46.459h-.724v7.599h.724v-7.6zM76.907 46.459h.724v7.599h-.724v-7.6zM79.44 46.459h-.723v7.599h.724v-7.6z"
      fill="#494DBE"
    />
    <path
      d="M22.084 98.294c-.583-3.278 1.938-6.287 5.268-6.287h9.843c3.33 0 5.851 3.01 5.268 6.287l-2.997 16.848a1.783 1.783 0 01-1.756 1.471H26.837c-.864 0-1.604-.62-1.756-1.471l-2.997-16.848z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M26.936 77.378A2.378 2.378 0 0129.314 75h5.738a2.378 2.378 0 012.378 2.378V89.81a2.378 2.378 0 01-2.378 2.378h-5.738a2.378 2.378 0 01-2.378-2.378V77.378z"
      fill="#FF6575"
    />
    <path
      d="M25.982 95.807c-.822 1.07-1.81 1.69-2.206 1.385-.396-.305-.05-1.419.772-2.488.823-1.07 1.81-1.689 2.207-1.384.396.304.05 1.418-.773 2.487z"
      fill="#fff"
      fill-opacity=".3"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="181.284"
        y1="24.321"
        x2="95.894"
        y2="68.531"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#494DBE" />
        <stop offset="1" stop-color="#B4B6EF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="100"
        y1="60"
        x2="100.314"
        y2="116.658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4C1262" />
        <stop offset="1" stop-color="#4C1262" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="163.5"
        y1="120"
        x2="198"
        y2="92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6266DD" />
        <stop offset="1" stop-color="#C4D2FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="32.274"
        y1="92.007"
        x2="32.274"
        y2="116.613"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CDF" />
        <stop offset="1" stop-color="#B7C0FF" />
      </linearGradient>
    </defs>
  </svg>
</template>
