<template>
  <div
    :class="[
      $style.base,
      {
        [$style.animate]: animate
      }
    ]"
    v-test="'whats-new-button'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      v-test="animate ? 'whats-new-stars-animated' : null"
    >
      <path
        :class="$style.star"
        d="M12.0585 0.322082C11.9701 -0.0995176 11.3681 -0.110285 11.2646 0.307883L8.88633 9.91335L0.330851 11.5343C-0.109279 11.6177 -0.110688 12.2433 0.329061 12.3287L8.88633 13.9894L11.2647 23.5537C11.3686 23.9714 11.9698 23.9606 12.0584 23.5395L14.0678 13.9894L22.9698 12.3292C23.4134 12.2465 23.412 11.6146 22.968 11.5337L14.0678 9.91335L12.0585 0.322082Z"
      />
      <path
        :class="[$style.star, $style.starSmall]"
        d="M21.8586 14.5996C21.7725 14.1742 21.1643 14.1637 21.0634 14.5859L19.9617 20.1942L15.2469 20.7556C14.8038 20.8227 14.7792 21.4481 15.2156 21.5494L19.9617 22.4175L21.0653 27.6962C21.1719 28.1095 21.7665 28.0985 21.8575 27.6815L22.788 22.4175L27.6821 21.5503C28.1226 21.4521 28.0986 20.8204 27.6519 20.7554L22.788 20.1942L21.8586 14.5996Z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isClicked: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    animate() {
      return !this.isClicked;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  width: 26px;
  height: 26px;
}

.star {
  .base.animate & {
    fill: #ecab28;
    animation: sparkle 10s linear infinite normal forwards;
  }

  .base:not(.animate) & {
    fill: #b7cbd2;
  }

  &.starSmall {
    .base.animate & {
      animation-duration: 7.5s;
    }
  }
}

@keyframes sparkle {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
