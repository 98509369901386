import { watch, reactive } from 'vue';
import type { Ref } from 'vue';
import { useStorage } from '@vueuse/core';

export const useWhatsNew = () => {
  interface Item {
    id: string;
    show: boolean;
  }

  interface ActiveItem {
    id: string;
    clicked: boolean;
  }

  const clickedItems: Ref<string[]> = useStorage('whats_new_clicked', []);

  const items: Item[] = [];

  const activeItems: ActiveItem[] = items
    .filter((item) => item.show)
    .map((item) => ({
      id: item.id,
      clicked: clickedItems.value.includes(item.id)
    }));

  const currentItem = activeItems[0] ? reactive(activeItems[0]) : null;

  watch(
    () => currentItem,
    (newValue) => {
      if (newValue?.clicked && !clickedItems.value.includes(newValue.id)) {
        clickedItems.value.push(newValue.id);
      }
    },
    {
      deep: true
    }
  );

  return {
    currentItem
  };
};
