<template>
  <BaseModal
    :heading="formTemplate ? formTemplate.name : ''"
    :loading="isLoading"
    :parentRoute="parentRouteName"
  >
    <div v-if="formTemplate">
      <BaseAlert
        v-if="formTemplate.corona"
        :text="$t('customers.form_submission_sensitive')"
        color="error"
        mb
        v-test="'formCoronaAlert'"
      />
      <div
        v-if="
          formData && formTemplate.questions && formTemplate.questions.length
        "
      >
        <div v-if="formData.formType === 'contract'" v-test="'formContract'">
          <div
            v-for="(question, index) in formTemplate.questions"
            :key="index"
            :class="$style.question"
          >
            <div v-test="'contract-intro'">
              <BaseText v-if="question.options">
                <span
                  v-html="
                    typeof question.options === 'string'
                      ? question.options
                      : question.options.join(' ')
                  "
                />
              </BaseText>
            </div>
          </div>
          <SignatureInput
            v-model="signature"
            :image="
              formSubmission ? formSubmission.answers[0].stringAnswer : null
            "
            v-test="'contract-signature'"
          />
        </div>

        <div v-if="formData.formType === 'consultation'">
          <div
            v-for="(question, index) in formData.questions"
            :key="index"
            :class="$style.question"
          >
            <BaseText :mb="0.5">
              <span v-html="filters.formatEnters(question.question)" />
            </BaseText>
            <BaseDropdown
              v-if="question.questionType === 'dropdown'"
              v-model="question.answer"
              :default="$t('forms.select')"
              :options="
                toArr(question.options).map((option) => ({
                  label: option,
                  value: option
                }))
              "
              :mb="1.5"
              v-test="'formDropdown'"
            />
            <BaseCheckboxes
              v-if="question.questionType === 'checkbox'"
              v-model="question.answer"
              :options="toArr(question.options)"
              :mb="1.5"
              v-test="'formCheckboxes'"
            />
            <BaseInput
              v-if="question.questionType === 'text'"
              v-model="question.answer"
              :inputId="`question-${index}`"
              :mb="1.5"
              type="textarea"
              v-test="'formBaseInput'"
            />
            <BaseRadio
              v-if="question.questionType === 'boolean'"
              v-model="question.answer"
              inline
              :options="[
                {
                  label: $t('global.yes'),
                  value: true
                },
                {
                  label: $t('global.no'),
                  value: false
                }
              ]"
              mb
              v-test="'formBaseRadio'"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <BaseButton
        v-if="formData"
        :disabled="
          formData && formData.formType === 'contract' && !signature.length
        "
        :loading="isSaving"
        @click="onSubmit"
        v-test="'saveForm'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import SignatureInput from './SignatureInput.vue';
import { formSubmissionFragment } from '@/graphql-fragments';
import { updateCalendarEvents } from '@/modules/calendar/actions/calendar-events';
import { defineComponent, inject } from 'vue';

export default defineComponent({
  components: {
    SignatureInput
  },
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup() {
    const forms = inject<any>('forms');

    return {
      filters,
      forms
    };
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      signature: '',
      formData: null,
      formTemplate: null
    };
  },
  apollo: {
    appointment: {
      query: gql`
        query getAppointment($id: Int!) {
          appointment(id: $id) {
            id
            customer {
              id
              email
            }

            customerMember {
              firstName
              lastName
            }
          }
        }
      `,
      variables() {
        return {
          id: this.appointmentId
        };
      },
      skip() {
        return !this.appointmentId;
      },
      fetchPolicy: 'cache-first'
    },
    formTemplate: {
      query: gql`
        query getFormTemplate($id: Int!) {
          formTemplate(id: $id) {
            id
            name
            corona
            formType
            questions {
              id
              options
              question
              questionType
              sortIndex
            }
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.templateId)
        };
      },
      skip() {
        return !this.templateId;
      },
      result() {
        if (!this.subId) {
          this.formTemplate.questions.map((question, index) => {
            if (question.questionType !== 'text') {
              this.formTemplate.questions[index].answer = null;
            }
            if (question.questionType === 'boolean') {
              this.formTemplate.questions[index].answer = undefined;
            }
            this.formTemplate.questions[index].answerId = null;
            return question;
          });

          this.formData = this.formTemplate;
          this.isLoading = false;
        }
      },
      operationName: 'getFormTemplate'
    },
    formSubmission: {
      query: gql`
        query getFormSubmission($id: Int!) {
          formSubmission(id: $id) {
            id
            answers {
              ... on StringAnswer {
                stringAnswer: answer
                id
                questionId
              }
              ... on BooleanAnswer {
                booleanAnswer: answer
                id
                questionId
              }
              ... on ArrayAnswer {
                arrayAnswer: answer
                id
                questionId
              }
            }
            template {
              id
              name
              corona
              formType
              questions {
                id
                options
                question
                questionType
                sortIndex
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.subId
        };
      },
      skip() {
        return !this.subId;
      },
      result() {
        this.formSubmission.template.questions.forEach((question, index) => {
          this.formSubmission.answers.forEach((answer) => {
            this.formatAlias(answer);
            if (answer.questionId === question.id) {
              this.formSubmission.template.questions[index].answer =
                answer.answer;
              this.formSubmission.template.questions[index].answerId =
                answer.id;
            }
          });
        });
        this.formData = this.formSubmission.template;
        this.isLoading = false;
      },
      operationName: 'getFormSubmission'
    }
  },
  computed: {
    appointmentId() {
      return parseInt(this.$route.params.appointmentId);
    },
    templateId() {
      return parseInt(this.$route.params.templateId);
    },
    customerId() {
      return (
        parseInt(this.$route.params.customerId) || this.appointment.customer.id
      );
    },
    subId() {
      return parseInt(this.$route.params.subId);
    }
  },
  methods: {
    formatAlias(answer) {
      // fix for cypress
      // I had to use an alias for every inline fragment because they all use the same query, I don't want an alias though.
      ['booleanAnswer', 'stringAnswer', 'arrayAnswer'].forEach((alias) => {
        if (alias in answer) {
          answer.answer = answer[alias];
          delete answer[alias];
        }
      });
    },
    createAndUpdateForms(action, formSubmission) {
      if (this.appointment) {
        updateCalendarEvents(formSubmission.appointment.calendarAppointments);
      } else {
        const filteredForms =
          action === 'edit'
            ? this.forms.filter((form) => form.id !== formSubmission.id)
            : this.forms;
        if (formSubmission) {
          this.forms = [formSubmission, ...filteredForms];
        }
      }

      flash(
        this.$t(
          `global.flash.${action === 'edit' ? 'form_edited' : 'form_created'}`
        )
      );
      this.$router.push({
        name: this.parentRouteName
      });
    },
    toArr(options) {
      if (options && typeof options === 'string') {
        return (options = options.split('\n'));
      } else {
        return options;
      }
    },
    onSubmit() {
      const questionsClone = this.formData.questions.map((question) =>
        Object.assign({}, question)
      );
      const answers = questionsClone
        .filter((question) => {
          if (
            question.answer ||
            (question.questionType === 'boolean' &&
              question.answer !== null &&
              typeof question.answer !== 'undefined') ||
            question.questionType === 'signature'
          ) {
            return question;
          }
        })
        .map((question) => {
          if (question.questionType === 'boolean') {
            if (typeof question.answer === 'boolean') {
              question.answer = question.answer.toString();
            } else {
              question.answer = question.answer.value.toString();
            }
          }

          if (question.questionType === 'checkbox') {
            return {
              multipleAnswers: question.answer,
              answer: null,
              id: question.answerId,
              questionId: question.id
            };
          }

          if (question.questionType === 'signature') {
            return {
              answer: this.signature,
              id: question.answerId,
              questionId: question.id
            };
          }

          return {
            answer: question.answer,
            id: question.answerId,
            questionId: question.id
          };
        });

      this.isSaving = true;

      if (this.formSubmission) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateFormSubmission(
                $input: UpdateFormSubmissionInput!
              ) {
                updateFormSubmission(input: $input) {
                  formSubmission {
                    ...formSubmission
                  }
                }
              }
              ${formSubmissionFragment}
            `,
            variables: {
              input: {
                id: this.formSubmission.id,
                answersAttributes: answers
              }
            }
          })
          .then((response) => {
            this.createAndUpdateForms(
              'edit',
              response.data.updateFormSubmission.formSubmission
            );
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createFormSubmission(
                $input: CreateFormSubmissionInput!
              ) {
                createFormSubmission(input: $input) {
                  formSubmission {
                    ...formSubmission
                  }
                  errors
                }
              }
              ${formSubmissionFragment}
            `,
            update: (store, { data: { createFormSubmission } }) => {
              if (this.appointment) {
                const query = gql`
                  query getFormSubmissions($appointmentId: Int) {
                    formSubmissions(appointmentId: $appointmentId) {
                      ...formSubmission
                    }
                  }
                  ${formSubmissionFragment}
                `;

                const data = store.readQuery({
                  query,
                  variables: {
                    appointmentId: this.appointment.id
                  }
                });

                if (data) {
                  store.writeQuery({
                    query,
                    data: {
                      formSubmissions: [
                        createFormSubmission.formSubmission,
                        ...data.formSubmissions
                      ]
                    },
                    variables: {
                      appointmentId: this.appointment.id
                    }
                  });
                }
              }
            },
            variables: {
              input: {
                customerId: this.customerId,
                templateId: this.formTemplate.id,
                answersAttributes: answers,
                appointmentId: this.appointment?.id || null
              }
            }
          })
          .then((response) => {
            this.createAndUpdateForms(
              'create',
              response.data.createFormSubmission.formSubmission
            );
          });
      }
    }
  }
});
</script>
<style lang="scss" module>
.question {
  &:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
