<template>
  <div v-if="showSetting">
    <BaseCheckbox
      v-model="formData.settings.bookings.stripeIdeal"
      :label="$t('giftcard_widget.ideal.label')"
      mb
      v-test="'ideal-payments'"
    />
    <BaseAlert :text="$t('giftcard_widget.ideal.alert')" color="warning" />
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const formData = inject<any>('giftcardSettingsData');

const { result } = useQuery(gql`
  query getCurrentUser {
    currentUser {
      company {
        integrations
      }
    }
  }
`);

const hasStripe = computed(
  () => !!result.value?.currentUser.company.integrations.stripe
);
const { company } = useCompanyStore();
const showSetting = computed(() => hasStripe.value && company.country === 'nl');
</script>
