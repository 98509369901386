<template>
  <BaseDropdown
    :placeholder="$t('global.actions.export')"
    :options="options"
    :mt="$screen === 's'"
    @update:modelValue="onSelect"
    v-test="'button'"
  />
</template>

<script lang="ts">
import filters from '@/filters';
import axios from '@/axios';
import { useLocationsStore } from '@/stores/locations';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseExport',
  inheritAttrs: false,

  props: {
    urlTemplate: {
      type: String,
      required: true,
      validator(value) {
        if (!value.includes('.extension')) {
          // eslint-disable-next-line
          throw "urlTemplate string should contain '.extension' in its value";
        }
        return true;
      }
    },
    csv: {
      type: Boolean,
      default: true
    },
    xls: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      extension: '.csv'
    };
  },

  computed: {
    options() {
      const options = [];
      if (this.csv) {
        options.push({
          label: this.filters.capitalize(this.$t('global.actions.export_csv')),
          value: '.csv'
        });
      }
      if (this.xls) {
        options.push({
          label: this.filters.capitalize(this.$t('global.actions.export_xls')),
          value: '.xls'
        });
      }
      return options;
    },

    url() {
      return this.urlTemplate.replace('.extension', this.extension);
    }
  },

  methods: {
    onSelect(extension) {
      this.extension = extension;
      this.download();
    },

    download() {
      const { locationId, dataScope } = useLocationsStore();

      axios
        .get(this.url, {
          responseType: 'blob',
          params: {
            location_id: locationId,
            parent_scope: dataScope === 'LOCAL' ? 'location' : 'company'
          }
        })
        .then(({ data, headers }) => {
          const fileName =
            headers['content-disposition'].match(/filename="([^;"]*)/)[1];
          const blob = new Blob([data], { type: headers['content-type'] });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          link.setAttribute('type', headers['content-type']);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  }
});
</script>
