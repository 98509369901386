<template>
  <div :class="$style.base">
    <Variables v-if="showVariablesModal" @close="showVariablesModal = false" />
    <MessageField v-show="showError" danger mb v-test="'bannerError'">
      <BaseText>
        {{ $t('global.image_upload_fail') }}
      </BaseText>
      <BaseText small>
        {{ $t('global.image_format_not_supported') }}
      </BaseText>
    </MessageField>
    <BaseInput
      v-model="subject"
      :label="$t('global.title')"
      required
      mb
      v-test="'messageTitle'"
    />
    <BaseEditor
      v-model="message"
      :cloudinaryName="UploadedImageUsage.NewsletterContent"
      :mb="0.25"
      required
      :autocompleteOptions="{
        name: 'email_variables_autocomplete',
        ch: AUTOCOMPLETE_CHARACTERS,
        fetch: handleAutocompleteFetch,
        onAction: handleAutocompleteAction
      }"
      @imageIconClick="mixpanel.track('Click - Newsletter image')"
      @imageUpload="mixpanel.track('Upload - Newsletter image')"
      @error="onError"
      v-test="'messageContent'"
    />
    <BaseText
      link
      :mt="0.5"
      @click="showVariablesModal = true"
      v-test="'openVariablesModal'"
    >
      {{ $t('marketing.email.sections.personalize_email') }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import Variables from '@/modules/marketing/modals/Variables.vue';
import MessageField from './MessageField.vue';
import gql from 'graphql-tag';
import { UploadedImageUsage } from '@/types';

export default {
  components: {
    Variables,
    MessageField
  },
  inject: ['mixpanel'],
  setup() {
    return { v$: useVuelidate(), UploadedImageUsage };
  },
  data() {
    return {
      showVariablesModal: false,
      showError: false,
      preset: '',
      AUTOCOMPLETE_CHARACTERS: '{{',
      templates: []
    };
  },
  watch: {
    'v$.$invalid': {
      handler() {
        this.$store.commit(
          'marketing/SET_MESSAGE_VALIDATION',
          this.v$.$invalid
        );
      },
      immediate: true
    },
    isSubmitted() {
      this.v$.$touch();
    }
  },
  computed: {
    emailType() {
      return this.$store.getters['marketing/builder/emailType'];
    },
    template() {
      return this.templates.find((template) => {
        if (this.emailType === 'REBOOK_REMINDER') {
          return template.id === 'rebook_reminder_email';
        } else if (this.emailType === 'BIRTHDAY') {
          return template.id === 'marketing_birthday_message';
        } else if (this.emailType === 'ONLINE_FORMS') {
          return template.id === 'online_forms_email';
        } else {
          return template.id === 'marketing_email';
        }
      });
    },
    isSubmitted() {
      return this.$store.getters['marketing/isSubmitted'];
    },
    allowImageUpload() {
      return this.$store.getters['marketing/builder/allowImageUpload'];
    },
    subject: {
      get() {
        return this.$store.getters['marketing/subject'];
      },
      set(value) {
        this.$store.commit('marketing/SET_SUBJECT', value);
      }
    },
    message: {
      get() {
        return this.$store.getters['marketing/message'];
      },
      set(value) {
        this.$store.commit('marketing/SET_MESSAGE', value);
      }
    }
  },
  methods: {
    onImageUploaded(value) {
      this.mixpanel.track(`Upload: ${value} - Newsletter image`);
    },
    onError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    },
    getMatchedChars(pattern: string) {
      if (this.template?.variables) {
        return this.template?.variables.filter(
          (char) => char.indexOf(pattern) !== -1
        );
      }
      return [];
    },
    handleAutocompleteFetch(pattern: string) {
      return new Promise((resolve) => {
        if (this.template?.variables) {
          return resolve(
            this.getMatchedChars(pattern).map((variable) => ({
              type: 'autocompleteitem',
              value: variable,
              text: variable
            }))
          );
        }
        resolve([]);
      });
    },
    handleAutocompleteAction(
      editor: any,
      autocompleteApi: any,
      rng: any,
      value: any
    ) {
      editor.selection.setRng(rng);
      editor.insertContent(`{{${value}}}`);
      autocompleteApi.hide();
    }
  },
  apollo: {
    templates: {
      query: gql`
        query templates {
          templates {
            id
            variables
          }
        }
      `
    }
  }
};
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
  padding: $spacing 0;
}
</style>
