<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="2" alignCenter>
        {{ $t('global.from') }}
      </BaseGrid>
      <BaseGrid :size="3" v-test="'inputFrom'">
        <BaseInput
          v-model="from"
          type="number"
          @update:modelValue="emitValue"
        />
      </BaseGrid>
      <BaseGrid :size="2" alignCenter>
        {{ $t('global.to') }}
      </BaseGrid>
      <BaseGrid :size="3" v-test="'inputTo'">
        <BaseInput v-model="to" type="number" @update:modelValue="emitValue" />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      from: 1,
      to: 100
    };
  },
  methods: {
    emitValue() {
      const obj = {
        filter_name: 'loyalty_points',
        values: {
          from: this.from,
          to: this.to
        }
      };

      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue();
  }
});
</script>
