<template>
  <BaseModal
    small
    :heading="$t('register.salonized_pay.dashboard.terminal.heading')"
  >
    <BaseText mb v-test="'sp-dashboard-terminal-passcode'">
      {{ $t('register.salonized_pay.dashboard.terminal.description') }}
    </BaseText>
    <BaseInput v-model="passcode" type="password" disabled />
  </BaseModal>
</template>
<script setup lang="ts">
const passcode = ref('3254');
</script>
