import gql from 'graphql-tag';

export const productFragment = gql`
  fragment fullProduct on Product {
    id
    name
    price
    costPrice
    reference
    partNumber
    medical
    stockMinimum
    stockMaximum
    stockEnabled
    supplier {
      name
      id
    }
    vatRate {
      name
      id
      percentage
      selectedByDefault
    }
    category {
      name
      id
    }
    stockAmount
    stockValue
    outOfStock
  }
`;

export const supplierFragment = gql`
  fragment supplier on Supplier {
    id
    name
    email
    address
    postalcode
    city
  }
`;
