<template>
  <div>
    <BaseHeading mb size="xl">{{
      type === 'customers'
        ? $t('nav.import_customers')
        : $t('nav.import_products')
    }}</BaseHeading>
    <div v-if="importSuccess">
      <BaseAlert
        color="success"
        :text="$t('imports.successful')"
        v-test="'import-success'"
      />
      <BaseButton
        mt
        @click="
          () => {
            importSuccess = false;
            uploadId = null;
          }
        "
        v-test="'import-again'"
      >
        {{ $t('imports.import_again') }}
      </BaseButton>
    </div>
    <ChooseFile
      v-else-if="!uploadId"
      :type="type"
      @success="uploadId = $event"
    />
    <MapFields
      v-else
      :uploadId="uploadId"
      :type="type"
      @success="importSuccess = true"
    />
  </div>
</template>

<script setup lang="ts">
import ChooseFile from './ChooseFile.vue';
import MapFields from './MapFields.vue';

defineProps<{
  type: 'customers' | 'products';
}>();

const uploadId = ref<number | null>(null);
const importSuccess = ref(false);
</script>
