<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isExpanded]: isExpanded
      }
    ]"
    :style="
      color
        ? {
            'background-color': color,
            'border-left-color': data.category.color
          }
        : undefined
    "
  >
    <div :class="$style.category">
      <div :class="$style.categoryName">
        {{ data.category.name }}
      </div>
      <div v-if="isExpanded" :class="$style.actions">
        <div :class="$style.treatmentDropdown">
          <BaseDropdown
            v-model="formData.selectedTreatment"
            :options="
              props.twTreatments.map((t) => ({
                value: t.id,
                label: t.name,
                groupValue: t.group.id
              }))
            "
            :groups="props.twGroups"
            searchable
            searchAutoFocus
            :loading="!props.twTreatments.length"
            v-test="'tw-sm-category-treatments-dropdown'"
          />
        </div>
        <BaseText
          link
          ml
          oneLine
          :color="!formData.selectedTreatment ? 'secondary' : 'default'"
          @click="applyToAll"
          v-test="'tw-sm-category-treatments-apply'"
          >{{ $t('treatwell.services.apply_to_all') }}</BaseText
        >
        <BaseIcon ml name="close" @click="removeAll" />
      </div>
      <div v-else :class="$style.actions">
        <BaseText
          link
          ml
          oneLine
          iconBefore="eye"
          @click="expand"
          v-test="'tw-sm-category-toggle'"
        >
          {{ $t('treatwell.services.show') }}
        </BaseText>
      </div>
    </div>
    <div
      v-if="isExpanded"
      :class="$style.serviceList"
      v-test="'tw-sm-category-service-list'"
    >
      <ServiceItem
        v-for="(service, index) in data.services"
        :key="index"
        :color="data.category.color"
        :service="service"
        :popularTreatmentCount="props.popularTreatmentCount"
        :treatwellTreatments="twTreatments"
        :twGroups="props.twGroups"
        @service:updated="serviceUpdated"
        v-test="`tw-sm-service-item`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { lightColor } from '@/helpers/formatting';
import type { TreatwellTreatment } from '@/types';
import { computed, ref, reactive } from 'vue';
import ServiceItem from './Service.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  twTreatments: {
    type: Array,
    required: true
  },
  twGroups: {
    type: Array,
    required: true
  },
  popularTreatmentCount: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['category:updated']);
const serviceUpdated = () => emit('category:updated');

const color = computed(() =>
  props.data.category.color ? lightColor(props.data.category.color) : null
);

const isExpanded = ref(
  props.data.services.some((service) => service.twTreatmentIds?.length)
);
const expand = () => {
  isExpanded.value = true;
};

const formData = reactive({
  selectedTreatment: null as TreatwellTreatment | null
});

const applyToAll = () => {
  if (formData.selectedTreatment) {
    props.data.services.forEach((service) => {
      if (!service.twTreatmentIds?.includes(formData.selectedTreatment)) {
        service.twTreatmentIds = [formData.selectedTreatment];
      }
    });
  }
};

const removeAll = () => {
  formData.selectedTreatment = null;
  props.data.services?.forEach((service) => {
    service.twTreatmentIds = [];
    service.popular = false;
  });
  isExpanded.value = false;
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left-width: 3px;
  border-left-style: solid;
  border-radius: $radius;
}

.category {
  padding: $spacing;
  display: flex;
  align-items: center;
}

.categoryName {
  width: 40%;
}

.actions {
  flex-grow: 1;
  margin-left: 0.5 * $spacing;
  display: flex;
  align-items: center;
}

.treatmentDropdown {
  flex-grow: 1;
}

.serviceList {
  width: 100%;
}
</style>
