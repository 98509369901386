<template>
  <div>
    <div :class="$style.alertWrap">
      <BaseAlert
        :text="
          type === 'customers'
            ? $t('imports.description_customers')
            : $t('imports.description_products')
        "
        :primaryAction="{
          text: $t('global.learn_more'),
          href:
            type === 'customers'
              ? $t('imports.learnmore_customers')
              : $t('imports.learnmore_products')
        }"
      />
    </div>
    <Flex :mt="2" align="center" wrap>
      <input
        type="file"
        @change="selectedFile = $event.target ? $event.target.files[0] : null"
        v-test="'import-input'"
      />
      <BaseButton
        :disabled="!selectedFile"
        @click="submit"
        v-test="'import-submit'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </Flex>
    <BaseAlert v-if="showError" color="error" :text="$t('imports.error')" mt />
  </div>
</template>

<script setup lang="ts">
import config from '@/config';

const emit = defineEmits(['success']);
const props = defineProps<{
  type: 'customers' | 'products';
}>();

const selectedFile = ref(null);
const showError = ref(false);

const submit = () => {
  if (!selectedFile.value) {
    return;
  }

  const xhr = new XMLHttpRequest();
  const actionUrl = `${config.backendUrl}/api/imports?resource=${props.type}`;
  const formData = new FormData();
  formData.append('data', selectedFile.value);

  xhr.open('POST', actionUrl, true);
  xhr.withCredentials = true;

  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200 || xhr.status === 201) {
        const result = JSON.parse(xhr.responseText);
        emit('success', result.import.id);
      } else {
        showError.value = true;
      }
    }
  };

  xhr.send(formData);
};
</script>

<style lang="scss" module>
.alertWrap {
  max-width: 650px;
}
</style>
