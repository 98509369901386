<template>
  <BaseModal
    small
    :heading="$t('admin.general_settings.cancel_subscription_title')"
    @close="$emit('close')"
  >
    <BaseText>{{
      $t('admin.general_settings.cancel_subscription_body')
    }}</BaseText>

    <template #footer>
      <BaseButton
        :routerLink="{ name: 'admin-billing' }"
        v-test="'general-settings-cancel-subscription-button'"
      >
        {{ $t('admin.general_settings.manage_subscription_btn') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
