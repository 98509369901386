<template>
  <ModuleBase noBorder gray :loading="loading">
    <PageWrap :heading="$t('promote.nav.links.micro_site')">
      <BaseCard :mb="2" :heading="$t('admin.micro_site.share.heading')">
        <BaseText mb>
          {{ $t('admin.micro_site.share.link_heading') }}
        </BaseText>
        <BaseCodeField :code="url" v-test="'microsite-url'" />
      </BaseCard>

      <BaseForm @submit="submitForm">
        <BaseCard
          :mb="2"
          :heading="$t('admin.micro_site.custom_domain.heading')"
        >
          <BaseText mb>
            {{ $t('admin.micro_site.custom_domain.description') }}
          </BaseText>
          <BaseCheckbox
            v-model="showCustomDomainContent"
            :label="$t('admin.micro_site.custom_domain.checkbox')"
            v-test="'microsite-showCustomDomain'"
          />
          <div
            v-show="showCustomDomainContent"
            :class="$style.customDomainContent"
          >
            <BaseHeading :mb="0.5">
              1. {{ $t('admin.micro_site.custom_domain.domain_heading') }}
            </BaseHeading>
            <BaseText mb>
              {{ $t('admin.micro_site.custom_domain.domain_description') }}
            </BaseText>
            <BaseInput
              v-model="formData.customDomain"
              :label="$t('admin.micro_site.custom_domain.domain_label')"
              :mb="2"
              v-test="'microsite-customDomain'"
            />
            <BaseHeading :mb="0.5">
              2. {{ $t('admin.micro_site.custom_domain.steps_heading') }}
            </BaseHeading>
            <BaseText mb>
              {{ $t('admin.micro_site.custom_domain.steps_description') }}
            </BaseText>
            <ul :class="$style.steps">
              <li>{{ $t('admin.micro_site.custom_domain.steps_1') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_2') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_3') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_4') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_5') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_6') }}</li>
              <li>{{ $t('admin.micro_site.custom_domain.steps_7') }}</li>
            </ul>
            <BaseText
              :href="$t('admin.micro_site.custom_domain.steps_more_link.url')"
            >
              {{ $t('admin.micro_site.custom_domain.steps_more_link.text') }}
            </BaseText>
          </div>
        </BaseCard>
        <BaseCard :mb="2" :heading="$t('admin.micro_site.content.heading')">
          <BaseInput
            v-model="formData.headerTitle"
            :label="$t('admin.micro_site.content.title')"
            mb
            v-test="'microsite-headerTitle'"
          />
          <BaseInput
            v-model="formData.headerSubtitle"
            :label="$t('admin.micro_site.content.title_sub')"
            mb
            v-test="'microsite-headerSubtitle'"
          />
          <BaseCheckbox
            v-model="formData.showVoucherWidget"
            :disabled="!canEnableVoucherWidget"
            :label="$t('admin.micro_site.content.show_giftcard')"
            mb
            v-test="'microsite-showVoucherWidget'"
          />
          <EnablePayment
            v-model="canEnableVoucherWidget"
            :description="$t('admin.micro_site.content.show_giftcard_payment')"
            mb
          />
          <BaseInputLabel :text="$t('admin.micro_site.content.color')" />
          <BaseColorLabel
            v-model="formData.baseColor"
            colorPicker
            isSelected
            large
            mb
            v-test="'microsite-baseColor'"
          />
          <div :class="$style.headerImage">
            <BaseInputLabel :text="$t('admin.micro_site.content.image')" />
            <BaseImage
              v-if="formData.headerImage"
              :cloudinaryId="formData.headerImage"
              mb
              v-test="'microsite-headerImage'"
            />
            <div :class="$style.headerImageButtons">
              <BaseUpload
                preset="ms-header"
                :hasImage="false"
                @uploadResults="onUploadFinish"
              />
              <BaseButton
                v-if="formData.headerImage"
                color="inverted"
                @click="formData.headerImage = ''"
              >
                {{ t('global.actions.delete') }}
              </BaseButton>
            </div>
          </div>
          <BaseEditor
            v-model="formData.introText"
            :label="$t('admin.micro_site.content.text_content')"
            mb
            v-test="'microsite-introText'"
          />
        </BaseCard>

        <BaseCard :mb="2" :heading="t('admin.micro_site.impressum.heading')">
          <BaseText :mb="0.5">
            {{ $t('admin.micro_site.impressum.text_content') }}
          </BaseText>
          <BaseText :href="$t('admin.micro_site.impressum.learn_more.url')" mb>
            {{ $t('admin.micro_site.impressum.learn_more.label') }}
          </BaseText>
          <BaseEditor
            v-model="formData.impressum"
            mb
            v-test="'microsite-impressum'"
          />
        </BaseCard>

        <BaseCard :mb="2" :heading="$t('admin.micro_site.seo.heading')">
          <BaseInput
            v-model="formData.metaTitle"
            :label="$t('admin.micro_site.seo.meta_title')"
            :info="$t('admin.micro_site.seo.meta_title_info')"
            mb
            v-test="'microsite-metaTitle'"
          />
          <BaseInput
            v-model="formData.metaDescription"
            :label="$t('admin.micro_site.seo.meta_description')"
            :info="$t('admin.micro_site.seo.meta_description_info')"
            mb
            v-test="'microsite-metaDescription'"
          />
          <BaseInput
            v-model="formData.gaId"
            :label="$t('admin.micro_site.seo.analytics_id')"
            :info="$t('admin.micro_site.seo.analytics_id_info')"
            prefix="G-"
            v-test="'microsite-gaId'"
          />
          <BaseAlert
            :text="$t('admin.micro_site.seo.analytics_id_alert')"
            :primaryAction="{
              text: $t('promote.nav.links.booking_settings'),
              routerLink: {
                name: 'admin-online-bookings'
              }
            }"
            mt
          />
        </BaseCard>

        <div :class="$style.footer">
          <BaseButton :loading="saving" submitForm v-test="'microsite-submit'">
            {{ $t('global.actions.save') }}
          </BaseButton>
        </div>
      </BaseForm>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import { reactive, ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useI18n } from 'vue-i18n';
import { flash } from '@/helpers/ui';
import type { CloudinaryResponse } from '@/components/base-upload/index.vue';
import EnablePayment from '../_shared/EnablePayment.vue';

const formData = reactive({
  baseColor: '',
  customDomain: '',
  gaId: '',
  headerImage: '',
  headerTitle: '',
  headerSubtitle: '',
  impressum: '',
  introText: '',
  metaTitle: '',
  metaDescription: '',
  showVoucherWidget: false
});

const showCustomDomainContent = ref(false);
const canEnableVoucherWidget = ref(false);
const url = ref('');

const { onResult, loading } = useQuery(gql`
  query getMicroSite {
    microSite {
      baseColor
      customDomain
      gaId
      headerImage
      headerSubtitle
      headerTitle
      id
      impressum
      introText
      metaDescription
      metaTitle
      showVoucherWidget
      url
    }
  }
`);

onResult(({ data: { microSite } }) => {
  Object.keys(formData).forEach((key) => {
    if (typeof formData[key] === 'string') {
      formData[key] = microSite[key] || '';
    } else if (typeof formData[key] === 'boolean') {
      formData[key] = microSite[key] || false;
    }
  });

  url.value = microSite.url || '';
  showCustomDomainContent.value = !!microSite.customDomain;
  canEnableVoucherWidget.value = !!microSite.showVoucherWidget;
});

const {
  mutate,
  loading: saving,
  onDone
} = useMutation(gql`
  mutation updateMicroSite($input: UpdateMicroSiteInput!) {
    updateMicroSite(input: $input) {
      microSite {
        id
      }
    }
  }
`);

const submitForm = () => {
  // Remove the G- prefix from the value if the user entered it
  formData.gaId = formData.gaId.replace('G-', '');
  formData.customDomain = showCustomDomainContent.value
    ? formData.customDomain
    : '';

  mutate({ input: formData });
};

const { t } = useI18n();

onDone(() => {
  flash(t('global.flash.settings_saved'));
});

const onUploadFinish = (response: CloudinaryResponse) => {
  formData.headerImage = response.public_id;
};
</script>

<style lang="scss" module>
.customDomainContent {
  margin-top: $spacing;
  padding-top: $spacing;
  border-top: 1px solid $color-border;
}

.headerImage {
  margin-bottom: $spacing;
}

.headerImageButtons {
  display: flex;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.steps {
  margin-bottom: $spacing;

  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}
</style>
