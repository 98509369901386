<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-feedback')"
    :text="{
      title: t('upgrade_subscription_page.feedback.main.title'),
      description: t('upgrade_subscription_page.feedback.main.description'),
      btnPrimary: t('upgrade_subscription_page.upgrade_btn')
    }"
    imageName="feedback"
  />
  <EmptyPageContent
    v-else
    fullHeight
    image-name="feedback"
    :text="emptyPageMessage"
    :moreLink="emptyPageLink"
    @click="onEmptyPageClick"
  />
</template>

<script setup lang="ts">
import EmptySubscriptionPage from '../_shared/subscription/EmptySubscriptionPage.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import axios from '@/axios';
import onUpgradeButtonClick from '../_shared/subscription/on-upgrade-button-click';

const { handleUpgradeButtonClick } = onUpgradeButtonClick();

const router = useRouter();

const { t } = useI18n();
const { hasFeatureFlag } = useUserStore();

const feedbackEnabled = computed(() => {
  const { companySettings } = useCompanyStore();
  return companySettings.customers.appointmentFeedback;
});

const emptyPageMessage = computed(() => {
  if (!feedbackEnabled.value) {
    return {
      title: t('feedback.overlay.title'),
      description: t('feedback.overlay.sub_title'),
      btnPrimary: t('feedback.overlay.turn_on')
    };
  }

  return {
    title: t('feedback.empty_state.title'),
    description: t('feedback.empty_state.description'),
    btnPrimary: t('feedback.empty_state.when_to_request'),
    btnSecondary: t('feedback.empty_state.personalize_message')
  };
});

const emptyPageLink = computed(() => {
  if (!hasFeatureFlag('module-feedback')) {
    return {
      text: t('upgrade_subscription_page.feedback.main.learn_more'),
      url: t('upgrade_subscription_page.feedback.main.learn_more_url')
    };
  }

  return {
    text: t('feedback.empty_state.more_text'),
    url: t('feedback.empty_state.more_url')
  };
});

const onEmptyPageClick = (item: string) => {
  if (item === 'primary') {
    if (!hasFeatureFlag('module-feedback')) {
      handleUpgradeButtonClick();
      return;
    }

    if (!feedbackEnabled.value) {
      const { companyId } = useCompanyStore();

      axios.patch(`/companies/${companyId}/enable_feedback`).then(() => {
        flash(t('global.flash.feedback_enabled'));

        // TODO: This is broken so there should be a test missing
        // this.$store.commit('UPDATE_COMPANY', {
        //   path: 'settings.customers.appointmentFeedback',
        //   value: true
        // });
      });
    } else {
      router.push({ name: 'admin-customers' });
    }
  } else if (item === 'secondary') {
    router.push({ name: 'message-templates' });
  }
};
</script>
