<template>
  <div :class="[$style.base, { [$style.isNotLarge]: $screen !== 'l' }]">
    <BaseText v-show="!tableIsLoading">
      {{ $t('customers.results_found') }}:
      <BaseText bold inline v-test="'customers-total-count'">
        {{ totalCount }}
      </BaseText>
    </BaseText>
    <FilterLabels :filters="modelValue" @removeFilter="onRemoveFilter" />
    <BaseText
      v-show="modelValue && modelValue.length"
      link
      @click="clearFilters"
      v-test="'customersClearFilters'"
    >
      {{ $t('customers.clear_all_filters') }}
    </BaseText>
  </div>
</template>

<script lang="ts">
import FilterLabels from '@/components/customer-filters/FilterLabels.vue';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';
import { addLabelIndex } from '@/helpers/formatting';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FilterLabels
  },
  inject: ['mixpanel'],
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    tableIsLoading: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const selectedCustomers = inject<number[]>('selectedCustomers');
    return {
      selectedCustomers
    };
  },
  computed: {
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    setFilter(filter: any) {
      let filters: any[] = [];

      if (filter) {
        filters = [...this.modelValue, addLabelIndex(this.modelValue, filter)];
      }

      this.updateModelValue(filters);
    },
    formatDate(value) {
      const date = new Date(value),
        year = date.getFullYear();

      let month = date.getMonth() + 1,
        dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }

      if (month < 10) {
        month = '0' + month;
      }

      return `${dt}/${month}/${year}`;
    },
    formatValues(filter) {
      if (typeof filter.values.from === 'string') {
        return `${this.formatDate(filter.values.from)} - ${this.formatDate(filter.values.to)}`;
      } else if (filter.employee) {
        return filter.employee;
      } else if (filter.filter_name === 'gender') {
        return filter.values;
      } else if (filter.values.length) {
        return filter.values.length;
      } else if (filter.filter_name === 'last_appointment_before_date') {
        return `${this.$t('customers.filters.before')} ${this.formatDate(filter.values.date)}`;
      } else if (filter.filter_name === 'last_appointment_days_ago') {
        return `${filter.values.days} ${this.$t('customers.filters.days_ago')}`;
      } else if (
        filter.filter_name === 'spent_past_year' ||
        filter.filterName === 'total_spent'
      ) {
        return `${filter.values.from / 100}-${filter.values.to / 100}`;
      } else {
        return `${filter.values.from}-${filter.values.to}`;
      }
    },
    onRemoveFilter(filter) {
      this.mixpanel.track('customer_filter_removed', {
        filter_name: filter.filter_name,
        filter_values: filter.values,
        filter_value: filter.value
      });
      this.updateModelValue(
        this.modelValue.filter((f) => f.labelIndex !== filter.labelIndex)
      );
    },
    clearFilters() {
      this.mixpanel.track('filters_cleared');
      this.updateModelValue([]);
    },
    updateModelValue(filters) {
      this.$emit('update:modelValue', filters);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing;
}

.clear {
  cursor: pointer;
}

.alertIcon {
  transform: translateY(3px);
  margin-right: $spacing * 0.25;
}
</style>
