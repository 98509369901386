<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div v-if="!previewModeActive" :class="$style.top">
      <Customer />
      <div :class="$style.topBorder" />
      <CustomerMember :customerMember="customerMember" />
    </div>
    <div :class="$style.bottom">
      <div :class="$style.summary">
        <div :class="$style.date">
          <BaseHeading size="s" :mb="0.25">
            {{ $t('global.date_time') }}
          </BaseHeading>
          <BaseHeading size="l" v-test="'appointment-summary-date'">
            {{ filters.dateTime(formData.startAt) }}
          </BaseHeading>
        </div>
        <div :class="$style.price">
          <BaseHeading size="s" :mb="0.25">
            {{ $t('global.price') }}
          </BaseHeading>
          <BaseHeading size="l" v-test="'appointment-price'">
            {{ filters.currency(totalPrice) }}
          </BaseHeading>
        </div>
      </div>
      <BaseCheckbox
        v-if="showNotificationsToggle"
        v-model="sendConfirmationEmail"
        :label="$t('appointment.create.email_notifications')"
        mt
        v-test="'appointment-notifications'"
      />
      <div :class="$style.buttonWrap">
        <BaseButton
          fullWidth
          size="l"
          :loading="isSaving"
          :disabled="
            isFetchingRequirements ||
            isFetchingAvailabilities ||
            !services.length
          "
          @click="$emit('submit')"
          v-test="'appointment-save'"
        >
          {{
            existingAppointmentData.id
              ? $t('global.actions.save')
              : $t('global.actions.create_appointment')
          }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { computed, defineComponent } from 'vue';
import Customer from './Customer.vue';
import CustomerMember from '@/components/customer-details/CustomerMember.vue';

import { storeToRefs } from 'pinia';
import { getPartResources } from '@/modules/calendar/appointment/helpers';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { useServicesStore } from '@/stores/services';

export default defineComponent({
  components: {
    Customer,
    CustomerMember
  },
  props: {
    isSaving: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup() {
    const { formData, existingAppointmentData, customerData, customerMember } =
      useCreateAppointmentStore();
    const {
      isFetchingRequirements,
      isFetchingAvailabilities,
      showNotificationsToggle,
      sendConfirmationEmail
    } = storeToRefs(useCreateAppointmentStore());
    const { services } = storeToRefs(useServicesStore());

    const totalPrice = computed(() => {
      const { services } = useCreateAppointmentStore();
      let price = 0;
      formData.partsAttributes.forEach((part) => {
        const service = services.find(
          (service) => service.id === part.serviceId
        );
        if (service) {
          let servicePrice = service.price;

          if (service.resourceAdjustments?.length) {
            // Check which employees are selected for this part
            const selectedEmployees = getPartResources(part, 'employee');

            // Check which adjustments to use based on the selected employees
            const activeAdjustments = service.resourceAdjustments.filter(
              (adj) => selectedEmployees.includes(adj.resourceId)
            );

            if (activeAdjustments.length) {
              // Get the highest price amongst all the price adjustments and use that as the actual price

              const prices = activeAdjustments.map((adj) => adj.price);

              if (selectedEmployees.length > activeAdjustments.length) {
                // In this case, there is at least one employee selected which does not have its own price
                // So we need to add the normal service price as an option, because the existing adjustments could be lower than the normal price
                // And we always need to show the highest price

                prices.push(service.price);
              }

              servicePrice = Math.max(...prices);
            }
          }

          price += servicePrice;
        }
      });
      return price;
    });

    const { previewModeActive } = storeToRefs(useCalendarPreviewStore());

    return {
      formData,
      customerData,
      customerMember,
      totalPrice,
      isFetchingRequirements,
      isFetchingAvailabilities,
      existingAppointmentData,
      showNotificationsToggle,
      sendConfirmationEmail,
      filters,
      previewModeActive,
      services
    };
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-right-radius: $radius;
}

.buttonWrap {
  margin-top: $spacing;
  padding-top: $spacing;
  border-top: 1px solid $color-border;
}

.top,
.bottom {
  .base:not(.smallScreen) & {
    padding: $spacing;
  }

  .base.smallScreen & {
    padding: $spacing $spacing * 0.5;
  }
}

.topBorder {
  padding-top: $spacing;
  border-bottom: 1px solid $color-border;
}

.summary {
  display: flex;
}

.date,
.price {
  width: 50%;
}

.price {
  & > * {
    text-align: right;
    justify-content: flex-end;
  }
}
</style>
