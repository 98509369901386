<template>
  <div>
    <div>
      <BaseHeading border>{{
        $t('reports.employees.sales_report')
      }}</BaseHeading>
      <BaseScroll v-if="report.data.length" :contentWidth="1700">
        <BaseTableRow head>
          <BaseTableCell :size="10" wordBreak>
            {{ $t('reports.employees.employee') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ $t('reports.employees.appointments') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ $t('reports.employees.invoices') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ $t('reports.employees.average_bill') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.products_sold') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.products_total') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.products_hourly') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.services_sold') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.services_total') }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right" wordBreak>
            {{ $t('reports.employees.services_hourly') }}
          </BaseTableCell>
        </BaseTableRow>
        <BaseTableRow
          v-for="(employee, index) in report.data"
          :key="index"
          v-test="'dataRow'"
        >
          <BaseTableCell wordBreak :size="10">
            {{ employee.name }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ employee.appointments }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ employee.orders }}
          </BaseTableCell>
          <BaseTableCell currency :class="$style.textAlign" textAlign="right">
            <div :class="$style.block">
              {{ filters.currency(employee.averageOrderValueExcl) }}
              <BaseText
                v-if="employee.averageOrderValueIncl"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(employee.averageOrderValueIncl) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ employee.productCount }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            <div :class="$style.block">
              {{ filters.currency(employee.productTotalExcl) }}
              <BaseText v-if="employee.productTotal" size="s" color="secondary">
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(employee.productTotal) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            <div :class="$style.block">
              {{ filters.currency(employee.productsPerHourExcl) }}
              <BaseText
                v-if="employee.productsPerHour"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(employee.productsPerHour) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            {{ employee.serviceCount }}
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            <div :class="$style.block">
              {{ filters.currency(employee.serviceTotalExcl) }}
              <BaseText v-if="employee.serviceTotal" size="s" color="secondary">
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(employee.serviceTotal) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right textAlign="right">
            <div :class="$style.block">
              {{ filters.currency(employee.servicesPerHourExcl) }}
              <BaseText
                v-if="employee.servicesPerHour"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(employee.servicesPerHour) }}
              </BaseText>
            </div>
          </BaseTableCell>
        </BaseTableRow>
        <BaseTableRow v-if="report.totals" footer v-test="'totalsRow'">
          <BaseTableCell>
            {{ $t('reports.ranges.total') }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.appointments }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.orders }}
          </BaseTableCell>
          <BaseTableCell right :class="$style.textAlign">
            <div :class="$style.block">
              {{ filters.currency(report.totals.averageOrderValueExcl) }}
              <BaseText
                v-if="report.totals.averageOrderValueIncl"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(report.totals.averageOrderValueIncl) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right :class="$style.textAlign">
            {{ report.totals.productCount }}
          </BaseTableCell>
          <BaseTableCell right>
            <div :class="$style.block">
              {{ filters.currency(report.totals.productTotalExcl) }}
              <BaseText
                v-if="report.totals.productTotal"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(report.totals.productTotal) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right>
            <div :class="$style.block">
              {{ filters.currency(report.totals.productsPerHour) }}
              <BaseText
                v-if="report.totals.productsPerHour"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(report.totals.productsPerHour) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell right>
            {{ report.totals.serviceCount }}
          </BaseTableCell>
          <BaseTableCell currency>
            <div :class="$style.block">
              {{ filters.currency(report.totals.serviceTotalExcl) }}
              <BaseText
                v-if="report.totals.serviceTotal"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(report.totals.serviceTotal) }}
              </BaseText>
            </div>
          </BaseTableCell>
          <BaseTableCell currency>
            <div :class="$style.block">
              {{ filters.currency(report.totals.servicesPerHourExcl) }}
              <BaseText
                v-if="report.totals.servicesPerHour"
                size="s"
                color="secondary"
              >
                {{ $t('reports.ranges.total_incl') }}
                {{ filters.currency(report.totals.servicesPerHour) }}
              </BaseText>
            </div>
          </BaseTableCell>
        </BaseTableRow>
      </BaseScroll>
    </div>
    <NoResultsFound v-if="!report.data.length" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import NoResultsFound from '@/modules/reports/NoResultsFound.vue';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    NoResultsFound,
    BaseTableRow,
    BaseTableCell
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
<style lang="scss" module>
.block {
  display: block;
  text-align: right;
}
</style>
