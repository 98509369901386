<template>
  <BaseCard
    :heading="$t('global.details')"
    :class="$style.base"
    :mb="1.5"
    :mr="$screen !== 's' ? 0.5 : null"
  >
    <BaseGrid container>
      <BaseGrid v-if="customer.email" :size="12" v-test="'customer-email'">
        <BaseText bold>
          {{ $t('global.email') }}
        </BaseText>
        <a
          :class="[$style.link, $style.email]"
          :href="`mailto:${customer.email}`"
          >{{ customer.email }}</a
        >
      </BaseGrid>

      <BaseGrid
        v-if="customer.mobilePhone"
        :size="12"
        v-test="'customer-mobilePhone'"
      >
        <BaseText bold>
          {{ $t('global.mobile') }}
        </BaseText>
        <a :class="$style.link" :href="`tel:${customer.mobilePhone}`">{{
          customer.mobilePhone
        }}</a>
      </BaseGrid>

      <BaseGrid v-if="customer.phone" :size="12" v-test="'customer-phone'">
        <BaseText bold>
          {{ $t('global.phone') }}
        </BaseText>
        <a :class="$style.link" :href="`tel:${customer.phone}`">{{
          customer.phone
        }}</a>
      </BaseGrid>

      <BaseGrid v-if="customer.address" :size="12" v-test="'customer-address'">
        <BaseText bold>
          {{ $t('global.address') }}
        </BaseText>
        <BaseText>
          {{ customer.address }}
        </BaseText>
        <BaseText v-if="customer.zipcode && customer.city">
          {{ customer.zipcode }} {{ customer.city }}
        </BaseText>
        <BaseText size="s">
          <a
            :class="$style.link"
            target="_blank"
            :href="`http://maps.google.com/?q=${customer.address}`"
            >{{ $t('global.actions.view_on_map') }}</a
          >
        </BaseText>
      </BaseGrid>

      <BaseGrid v-if="customer.gender" :size="12" v-test="'customer-gender'">
        <BaseText bold>
          {{ $t('global.gender') }}
        </BaseText>
        <BaseText>
          <template v-if="customer.gender === 'MALE'">
            {{ $t('global.male') }}
          </template>
          <template v-else-if="customer.gender === 'FEMALE'">
            {{ $t('global.female') }}
          </template>
          <template v-else-if="customer.gender === 'NONBINARY'">
            {{ $t('global.nonbinary') }}
          </template>
          <template v-else>
            {{ $t('global.unknown') }}
          </template>
        </BaseText>
      </BaseGrid>

      <BaseGrid
        v-if="customer.dateOfBirth"
        :size="12"
        v-test="'customer-dateOfBirth'"
      >
        <BaseText bold>
          {{ $t('global.date_of_birth') }}
        </BaseText>
        <div :class="$style.align">
          <BaseText>
            {{ filters.date(customer.dateOfBirth) }}
          </BaseText>
          <BaseText color="secondary" :ml="0.5">
            ({{ age }} {{ $t('customers.years') }})
          </BaseText>
          <BaseIcon
            v-if="isBirthday"
            :tooltip="$t('customers.birthday_today')"
            :ml="0.5"
            name="gift"
            color="success"
          />
        </div>
      </BaseGrid>

      <BaseGrid :size="12" v-test="'customer-createdAt'">
        <BaseText bold>
          {{ $t('global.created') }}
        </BaseText>
        <BaseText>
          {{ filters.date(customer.createdAt) }}
        </BaseText>
      </BaseGrid>

      <BaseGrid :size="12" v-test="'customer-id'">
        <BaseText bold>
          {{ $t('global.salonized_id') }}
        </BaseText>
        <BaseText>
          {{ customer.id }}
        </BaseText>
      </BaseGrid>

      <BaseGrid
        v-for="(field, index) in customFields"
        :key="index"
        :size="12"
        v-test="'customer-customFields'"
      >
        <BaseText bold>
          {{ field.label }}
        </BaseText>
        <BaseText>
          {{ field.value }}
        </BaseText>
      </BaseGrid>

      <BaseGrid
        v-if="customer.customerNotes"
        :size="12"
        v-test="'customer-customerNotes'"
      >
        <BaseText bold>
          {{ $t('customers.additional_customer_info') }}
        </BaseText>
        <BaseText>
          {{ customer.customerNotes }}
        </BaseText>
      </BaseGrid>

      <BaseGrid
        v-if="customer.alertNotes"
        :size="12"
        v-test="'customer-alertNotes'"
      >
        <BaseText bold>
          {{ $t('customers.warning_saving_appointments') }}
        </BaseText>
        <BaseText>
          {{ customer.alertNotes }}
        </BaseText>
      </BaseGrid>
    </BaseGrid>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    age() {
      const dateOfBirth = this.customer.dateOfBirth;
      return dateOfBirth
        ? Math.trunc(dayjs().diff(dateOfBirth, 'y', true))
        : null;
    },
    isBirthday() {
      const birthday = dayjs(this.customer.dateOfBirth).format('DDMM');
      const now = dayjs().format('DDMM');
      return birthday === now;
    },
    customFields() {
      const { companySettings } = useCompanyStore();
      const fields = [];
      for (let i = 0; i < 10; i++) {
        const label = companySettings.customers[`custom${i + 1}Label`];
        const value = this.customer[`custom${i + 1}`];

        if (label && value) {
          fields.push({
            label,
            value
          });
        }
      }
      return fields;
    }
  }
});
</script>

<style lang="scss" module>
.link {
  // because ember
  color: $color-primary !important;
  text-decoration: none;
}

.email {
  word-break: break-all;
}

.align {
  display: flex;
  align-items: center;
}
</style>
