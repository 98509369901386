import gql from 'graphql-tag';

export const CREATE_STRIPE_CHECKOUT_SESSION_MUTATION = gql`
  mutation createStripeCheckoutSession(
    $input: CreateStripeCheckoutSessionInput!
  ) {
    createStripeCheckoutSession(input: $input) {
      url
      errors {
        message
        type
      }
    }
  }
`;

export const CHANGE_STRIPE_SUBSCRIPTION_MUTATION = gql`
  mutation changeStripeSubscription($input: ChangeStripeSubscriptionInput!) {
    changeStripeSubscription(input: $input) {
      status
      errors {
        message
        type
      }
    }
  }
`;

export const GET_STRIPE_PRICES_QUERY = gql`
  query getStripePrices {
    stripePrices {
      plans {
        plan
        prices {
          base {
            unitAmount
            upTo
            currency
          }
          locations {
            unitAmount
            upTo
            currency
          }
          resources {
            unitAmount
            upTo
            currency
          }
        }
      }
      period
    }
  }
`;

export const STRIPE_QUOTE_MUTATION = gql`
  mutation createStripeQuote($input: CreateStripeQuoteInput!) {
    createStripeQuote(input: $input) {
      errors {
        type
        message
      }
      quotes {
        amountSubtotal
        amountTotal
        id
        percentageDiscount
        referralReward
        period
        plan
        lineItems {
          id
          quantity
          amountSubtotal
          amountTotal
          description
          itemType
          currency
        }
      }
    }
  }
`;
