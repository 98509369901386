<template>
  <ItemWrap :id="data.recordId" :data="data">
    <TimelineItem :item="data" :class="$style.timelineItem" />
  </ItemWrap>
</template>

<script lang="ts">
import ItemWrap from './ItemWrap.vue';
import TimelineItem from '@/components/TimelineItem.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ItemWrap,
    TimelineItem
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
});
</script>

<style lang="scss" module>
.timelineItem {
  width: 100%;
}
</style>
