import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { handledException } from '@/helpers/datadog';
import { ApolloLink, HttpLink } from '@apollo/client/core';
import type { ServerParseError, ServerError } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createLazyLoadableLaikaLink } from '@zendesk/laika';
import config from '@/config';
import { modal, notification } from '@/helpers/ui';
import { GraphqlExecutionError, GraphqlNetworkError } from './errors';
import { useVersionMismatchStore } from '@/stores/version-mismatch';
import { logout } from '@/helpers/session';

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    if (context.response?.headers) {
      const { checkVersionMismatch } = useVersionMismatchStore();
      checkVersionMismatch(context.response.headers.get('version'));
    }
    return response;
  })
);

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    let statusCode: string;
    if (networkError && 'statusCode' in networkError) {
      statusCode = String(networkError.statusCode);
    } else if (response) {
      statusCode = '200';
    } else {
      statusCode = 'NO_RESPONSE';
    }

    let extensionStatusCode: string;
    if (response?.errors) {
      extensionStatusCode = String(response.errors[0]?.extensions?.code);
    } else {
      extensionStatusCode = '';
    }

    if (statusCode === 'NO_RESPONSE' && !graphQLErrors) {
      notification({ name: 'no-connection' });
      return;
    }

    const operationName = operation?.operationName;

    if (
      operation?.query?.definitions?.some(
        ({ kind, operation }: any) =>
          kind === 'OperationDefinition' && operation === 'subscription'
      )
    ) {
      // Network errors on subscriptions we flatout ignore
      return;
    }

    if (operationName === 'deleteAbsence') {
      // Deleting absence can sometimes trigger a 404 (for an unknown reason), but in this case we don't want to show the error modal
      return;
    }

    if (networkError && !graphQLErrors) {
      let userSignedIn;

      if ('result' in networkError) {
        userSignedIn = networkError?.result?.user_signed_in;
      }

      if (!userSignedIn && statusCode === '401') {
        notification({ name: 'session-expired' });
        logout();
        return;
      }

      let bodyText: any;
      if ('bodyText' in networkError) {
        bodyText = networkError.bodyText;
      }
      handledException(
        new GraphqlNetworkError({ error: networkError, operationName }),
        {
          operationName,
          responseBody: bodyText,
          responseStatus: statusCode,
          originalError: { ...networkError }
        }
      );
    }

    let modalErrors: (Error | ServerParseError | ServerError)[] = [];

    if (graphQLErrors) {
      modalErrors = [...graphQLErrors];
    }

    if (networkError) {
      modalErrors.push(networkError);
    }

    const modalData = {
      operationName,
      statusCode,
      extensionStatusCode,
      modalErrors
    };

    if (
      graphQLErrors?.find((error) => String(error.extensions?.code) === '404')
    ) {
      modalData.statusCode = '404';
    }

    if (
      graphQLErrors?.find(
        (error) =>
          error.extensions?.authorization_module === 'marketing' ||
          (error.extensions?.authorization_module === 'waiting-list' &&
            error.extensions?.code === '401')
      )
    ) {
      modal('upgrade', { data: modalData });
      return;
    }

    // Exceptions do not rethrow, they ask the user to refresh instead
    if (graphQLErrors) {
      graphQLErrors.map((gqlerror) => {
        handledException(new GraphqlExecutionError(gqlerror, operationName), {
          operationName,
          responseBody: response?.data,
          responseStatus: statusCode,
          locations: gqlerror.locations,
          query: operation?.query?.loc?.source?.body
        });
      });
    }

    modal('error', { ...modalData });
  }
);

const httpLink = new HttpLink({
  uri: ({ operationName }): string => {
    const authOps = ['signup', 'sendTwoStepLoginSms'];
    return `${config.backendUrl}/api/${authOps.includes(operationName) ? 'graphql_authentication' : 'graphql'}?op=${operationName}`;
  },
  credentials: 'include'
});

let actualLinks;

if (!config.isTest) {
  const cable = createConsumer(config.wsUrl);
  const wsLink = new ActionCableLink({ cable });

  const isSubscription = ({ query: { definitions } }: any): boolean =>
    definitions.some(
      ({ kind, operation }: any) =>
        kind === 'OperationDefinition' && operation === 'subscription'
    );

  const splitLink = ApolloLink.split(isSubscription, wsLink as any, httpLink);

  actualLinks = ApolloLink.from([errorLink as any, afterwareLink, splitLink]);
} else {
  const laikaLazyLink = createLazyLoadableLaikaLink({
    clientName: 'test-ws-client'
  });
  actualLinks = ApolloLink.from([
    errorLink as any,
    laikaLazyLink,
    afterwareLink,
    httpLink
  ]);
}

export const links = actualLinks;
