<template>
  <BaseCard :mb="2" v-test="'billing-remaining-trial-card'">
    <div :class="$style.base">
      <BaseText>
        <span :class="$style.emoji" role="img" aria-label="Clock"> ⏰ </span>

        {{
          isTrialLastDay
            ? t('admin.cards.remaining_trial.last_day')
            : t('admin.cards.remaining_trial.remaining_days', {
                days: trialDaysRemaining
              })
        }}
      </BaseText>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';

const { isTrialLastDay, trialDaysRemaining } = useCompanyStore();

const { t } = useI18n();
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
}

.emoji {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}
</style>
