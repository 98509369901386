<template>
  <div :class="$style.base">
    <CreateNote
      v-if="showCreateNoteModal"
      :currentNote="order.note || undefined"
      @submit="order.note = $event"
      @close="showCreateNoteModal = false"
    />

    <BaseText
      v-if="!order.note"
      link
      iconBefore="file-add"
      @click="showCreateNoteModal = true"
      v-test="'register-add-note'"
    >
      {{ $t('global.actions.add_note') }}
    </BaseText>

    <BaseCard v-else noPadding v-test="'register-order-note-item'">
      <div :class="$style.note">
        <div :class="$style.noteInner">
          <BaseIcon name="file" size="s" :mr="0.5" />
          <BaseText v-test="'register-order-note'">
            {{ order.note }}
          </BaseText>
        </div>
        <BaseMore
          v-if="order.note"
          :options="['edit', 'delete']"
          inline
          size="s"
          @select="onOptionSelect"
          v-test="'register-order-item-options'"
        />
      </div>
    </BaseCard>
  </div>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import CreateNote from '@/modals/CreateNote.vue';
import { ref } from 'vue';

const { order } = useRegisterOrderStore();
const showCreateNoteModal = ref(false);

const onOptionSelect = (option: string) => {
  switch (option) {
    case 'edit':
      showCreateNoteModal.value = true;
      break;
    case 'delete':
      order.note = null;
      break;
  }
};
</script>
<style lang="scss" module>
.note {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noteInner {
  display: flex;
  align-items: center;
  padding: $spacing 0 $spacing 12px;
}
</style>
