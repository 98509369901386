<template>
  <div
    v-if="!disabled"
    :class="[$style.base, { [$style.disabled]: disabled }]"
    @click="$emit('select')"
    v-test="`stripe-plan-${plan.plan.toLowerCase()}`"
  >
    <BaseCard :greenBorder="selected" mb>
      <PlanLabel v-if="highlighted">
        {{ $t('subscription.stripe.highlighted') }}
      </PlanLabel>
      <div :class="$style.flexCentered">
        <BaseHeading :mb="0.5">{{
          $t(`subscription.stripe.types.${plan.plan.toLowerCase()}.name`)
        }}</BaseHeading>
        <div :class="$style.flexCentered">
          <BaseText
            bold
            mr
            v-test="`stripe-plan-${plan.plan.toLowerCase()}-price`"
          >
            {{
              filters.currency(
                plan.prices.base[0].unitAmount,
                plan.prices.base[0].currency
              )
            }}
            /
            {{
              selectedPeriod === SubscriptionPeriod.Month
                ? $t('global.items.month', 1)
                : $t('global.items.year', 1)
            }}
          </BaseText>
          <BaseCheck :checked="selected" />
        </div>
      </div>
      <BaseText mb>{{
        $t(`subscription.stripe.types.${plan.plan.toLowerCase()}.description`)
      }}</BaseText>
      <div :class="$style.featuresContainer">
        <PlanFeatureList
          v-if="cashFeatures.length > 0"
          :limitHeight="!showAllFeatures"
          :featureList="cashFeatures"
          :title="$t('subscription.stripe.feature_group.cash')"
          iconName="calendar"
        />
        <PlanFeatureList
          v-if="scheduleFeatures.length > 0"
          :limitHeight="!showAllFeatures"
          :featureList="scheduleFeatures"
          :title="$t('subscription.stripe.feature_group.schedule')"
          iconName="calendar"
        />
        <PlanFeatureList
          v-if="overallFeatures.length > 0"
          :limitHeight="!showAllFeatures"
          :featureList="overallFeatures"
          :title="$t('subscription.stripe.feature_group.overall')"
          iconName="briefcase"
        />
        <PlanFeatureList
          v-if="marketingFeatures.length > 0"
          :limitHeight="!showAllFeatures"
          :featureList="marketingFeatures"
          :title="$t('subscription.stripe.feature_group.marketing')"
          iconName="marketing"
        />
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts" setup>
import type { SubscriptionPlanItem } from '@/types';
import { SubscriptionPeriod } from '@/types';
import { computed } from 'vue';
import filters from '@/filters';
import { plansFeaturesKeys } from '../subscription/data';
import PlanFeatureList from '../subscription/stripe-checkout/PlanFeatureList.vue';
import PlanLabel from '../subscription/PlanLabel.vue';
import { useStripeStore } from '../subscription/stripe/store';
import { storeToRefs } from 'pinia';

defineEmits(['select']);

const props = defineProps<{
  plan: SubscriptionPlanItem;
  disabled?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  showAllFeatures?: boolean;
}>();

const { selectedPeriod } = storeToRefs(useStripeStore());
const planFeatures = plansFeaturesKeys[props.plan.plan];

const cashFeatures = computed(() => planFeatures.cash);
const scheduleFeatures = computed(() => planFeatures.scheduleManagement);
const overallFeatures = computed(() => planFeatures.overallTools);
const marketingFeatures = computed(() => planFeatures.marketing);
</script>

<style lang="scss" module>
.base {
  position: relative;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.flexCentered {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featuresContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: $spacing;
}
</style>
