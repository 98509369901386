<template>
  <div v-if="appointments.length" v-test="'invoice-appointments'">
    <BaseHeading>
      {{ filters.capitalize($t('global.items.appointment', 2)) }}
    </BaseHeading>
    <BaseTable
      :headers="[$t('global.items.service', 2), $t('global.date_time')]"
      :rows="
        appointments.map((appointment) => ({
          cells: [
            appointment.serviceNames,
            filters.dateTime(appointment.startAt)
          ]
        }))
      "
      disableZebra
    />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    appointments: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
