import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { absenceFragment, calendarAbsenceFragment } from '@/graphql-fragments';
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import { formatRrule, showRecurringModalAndResolve } from './recurring-helpers';
import { fetchBusinessHours } from '../calendar/calendar-business-hours';
import type { CreateAbsenceInput, CalendarAbsence } from '@/types';
import {
  createCalendarEvent,
  deleteCalendarEvent,
  updateCalendarEvents
} from './calendar-events';
import { logValidationError } from '@/helpers/datadog';
import { isCalendarSaving } from '../calendar-state';

export const createAbsence = (input: CreateAbsenceInput) => {
  const mutationVars = {
    ...input,
    rrule: input.rrule ? formatRrule(input.rrule) : null
  };

  const createAbsence = () =>
    new Promise<void>((resolve) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation createAbsence($input: CreateAbsenceInput!) {
              createAbsence(input: $input) {
                absence {
                  ...absence
                }
                calendarAbsence {
                  ...calendarAbsence
                }
                errors {
                  attribute
                  message
                  type
                }
              }
            }
            ${absenceFragment}
            ${calendarAbsenceFragment}
          `,
          variables: {
            input: mutationVars
          }
        })
        .then(
          ({
            data: {
              createAbsence: { absence, calendarAbsence, errors }
            }
          }) => {
            if (calendarAbsence) {
              flash(i18n.t('global.flash.absence_created'));

              createCalendarEvent(calendarAbsence);

              // Refetch business hours when all day absence is made
              if (absence.allDay) {
                fetchBusinessHours();
              }
            }

            if (errors) {
              logValidationError('createAbsence', errors, mutationVars);
            }

            resolve();
          }
        );
    });

  const payload = {
    input,
    original: true,
    type: input.chore ? 'chore' : 'absence',
    action: 'create'
  };

  return showRecurringModalAndResolve({ payload, method: createAbsence });
};

export const updateAbsence = ({
  input,
  original,
  isChore,
  rrule,
  createNewRrule,
  disableRecurringOriginalOption
}: any) => {
  const updateAbsence = (options: any) => {
    const data = options ? { ...input, ...options } : input;
    const mutationVars = {
      ...data,
      rrule: data.rrule ? formatRrule(data.rrule) : null
    };

    return new Promise<void>((resolve) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation updateAbsence($input: UpdateAbsenceInput!) {
              updateAbsence(input: $input) {
                absence {
                  ...absence
                }
                calendarAbsences {
                  ...calendarAbsence
                }
                errors {
                  attribute
                  message
                  type
                }
              }
            }
            ${absenceFragment}
            ${calendarAbsenceFragment}
          `,
          variables: {
            input: mutationVars
          }
        })
        .then(
          ({
            data: {
              updateAbsence: { absence, calendarAbsences, errors }
            }
          }) => {
            flash(i18n.t('global.flash.absence_updated'));
            if (
              options?.recurrenceUpdateState === 'original' &&
              data?.deleteEntry
            ) {
              calendarAbsences.forEach((absence: CalendarAbsence) => {
                deleteCalendarEvent(absence.id);
              });
            } else {
              updateCalendarEvents(calendarAbsences);
            }

            // Refetch business hours when all day absences change
            if (absence.allDay) {
              fetchBusinessHours();
            }

            if (errors) {
              logValidationError('updateAbsence', errors, mutationVars);
            }

            resolve();
          }
        );
    });
  };

  const action = input.deleteEntry
    ? 'delete'
    : createNewRrule
      ? 'create'
      : 'update';
  const payload = {
    input,
    rrule,
    action,
    original,
    disableRecurringOriginalOption,
    type: isChore ? 'chore' : 'absence'
  };

  return showRecurringModalAndResolve({ payload, method: updateAbsence });
};

export const deleteAbsence = (id: number) => {
  isCalendarSaving.value = true;
  apolloClient
    .mutate({
      mutation: gql`
        mutation deleteAbsence($input: DeleteAbsenceInput!) {
          deleteAbsence(input: $input) {
            absence {
              allDay
            }
            calendarAbsence {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          id
        }
      }
    })
    .then(
      ({
        data: {
          deleteAbsence: { absence, calendarAbsence }
        }
      }) => {
        flash(i18n.t('global.flash.absence_deleted'));

        deleteCalendarEvent(calendarAbsence.id);

        // Refetch business hours when all day absence is deleted
        if (absence.allDay) {
          fetchBusinessHours();
        }
      }
    )
    .finally(() => {
      isCalendarSaving.value = false;
    });
};
