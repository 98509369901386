import type { UseAccountContext } from './types';

export const injectUserAccounts = () => {
  const userAccountsContext = inject<UseAccountContext>('userAccounts');

  if (!userAccountsContext) {
    throw new Error(
      'make sure to use provideUserAccounts() on a parent component before injectUserAccounts()'
    );
  }

  return userAccountsContext;
};
