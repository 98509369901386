<template>
  <div :class="$style.notFound">
    <BaseText>
      {{ $t('global.no_results') }}
    </BaseText>
  </div>
</template>

<style lang="scss" module>
.notFound {
  padding: $spacing;
  text-align: center;
}
</style>
