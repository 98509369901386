<template>
  <div :class="$style.base">
    <BaseButton color="inverted" :notification="orderCount" @click="close">
      {{ $t('global.actions.proceed') }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRegisterBrowserStore } from './store';
import { useRouter } from 'vue-router';

const { showMobileBrowser } = storeToRefs(useRegisterBrowserStore());
const { order } = useRegisterOrderStore();

const orderCount = computed(() =>
  order.items.reduce((sum, item) => sum + (item.quantity || 0), 0)
);

const router = useRouter();
const close = () => {
  showMobileBrowser.value = false;
  router.push({
    name: 'register-builder'
  });
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding: $spacing;
  width: 100%;
  background-color: $white;
  box-shadow: $shadow;
}
</style>
