<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <BaseHeading size="xl" :mb="2" center>
      {{ $t('reset_password.heading') }}
    </BaseHeading>
    <div v-if="!passwordResetSuccess">
      <BaseForm @submit="onSubmit">
        <BaseInput
          v-model="password"
          :label="$t('global.password')"
          type="password"
          name="password"
          required
          newPassword
          :mb="2"
          v-test="'passwordInput'"
        />
        <BaseInput
          v-model="passwordConfirmation"
          :label="$t('global.password_confirmation')"
          type="password"
          name="passwordConfirmation"
          required
          :sameAs="password"
          :mb="2"
          v-test="'passwordConfirmationInput'"
        />
        <div :class="$style.bottom">
          <BaseButton submitForm v-test="'submit'">
            {{ $t('global.actions.submit') }}
          </BaseButton>
        </div>
        <BaseText v-if="tokenExpiredError" center color="error" mt>
          {{ $t('reset_password.token_expired') }}
        </BaseText>
      </BaseForm>
    </div>
    <div v-else>
      <BaseText :mb="2" center v-test="'emailSentInfo'">
        {{ $t('reset_password.success_info') }}
      </BaseText>
      <div :class="$style.bottom">
        <BaseButton
          :routerLink="{ name: 'login' }"
          v-test="'backToLoginButton'"
        >
          {{ $t('forgot_password.back_to_login') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import axios from '@/axios';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      token: '',
      tokenExpiredError: false,
      passwordResetSuccess: false
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.resetPassword();
      }
    },
    resetPassword() {
      axios
        .put('/passwords/reset_password', {
          reset_password_token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        })
        .then(() => {
          this.passwordResetSuccess = true;
        })
        .catch(() => {
          this.tokenExpiredError = true;
        });
    }
  },
  created() {
    this.token = this.$route.query.token;
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.bottom {
  display: flex;
  justify-content: center;
}
</style>
