import type { Calendar } from '@fullcalendar/core';

interface CalendarApiInterface {
  api: Calendar | null;
  setApi(api: Calendar): void;
}

const Interface = (): CalendarApiInterface => ({
  api: null,
  setApi(api: Calendar) {
    this.api = api;
  }
});

export default Interface();
