<template>
  <BaseCard v-if="isSubscribed && !isTreatwellUser" mb>
    <div :class="[$style.cardContent, $style.smsCreditsContent]">
      <div :class="$style.smsCredits">
        <BaseHeading size="xl" center v-test="'sms-credits'">{{
          company.smsCredits
        }}</BaseHeading>
        <BaseText color="secondary">{{
          t('admin.dashboard.credits.sms_credits').toUpperCase()
        }}</BaseText>
      </div>
      <BaseButton
        color="inverted"
        :routerLink="{ name: 'messages-purchase-credits' }"
        @click="mixpanel.track('Add credits button clicked')"
        v-test="'credits-link'"
      >
        {{ t('admin.dashboard.credits.add_credits') }}
      </BaseButton>
    </div>
    <BaseAlert
      v-if="showPaymentAlert"
      color="warning"
      :text="t('admin.dashboard.credits.payment_processing')"
      mt
      v-test="'sms-payment-success-alert'"
    />
  </BaseCard>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCompanyStore } from '@/stores/company';

withDefaults(
  defineProps<{
    showPaymentAlert?: boolean;
  }>(),
  {
    showPaymentAlert: false
  }
);

const { t } = useI18n();
const { company, isSubscribed, isTreatwellUser } = useCompanyStore();
const mixpanel = inject<any>('mixpanel');
</script>

<style lang="scss" module>
.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.smsCreditsContent {
  justify-content: space-between;
}

.smsCredits {
  text-align: center;
}
</style>
