<template>
  <BaseModal
    small
    :heading="$t('onboarding.new_tasks.roster.heading')"
    @close="close"
    v-test="'task-setup_roster'"
  >
    <BaseAlert
      v-if="showAlert"
      :text="$t('onboarding.new_tasks.roster.days_error')"
      color="error"
      mb
      v-test="'rosterError'"
    />
    <div
      v-for="day in days"
      :key="day.name"
      :class="[
        $style.card,
        {
          [$style.cardMobile]: $screen === 's'
        }
      ]"
    >
      <BaseCard gray>
        <div
          :class="[
            $style.base,
            {
              [$style.baseMobile]: $screen === 's'
            }
          ]"
        >
          <div :class="$style.date">
            <BaseCheckbox
              v-model="day.checked"
              :mr="0.5"
              v-test="'rosterCheckbox'"
            />
            <BaseText @click="day.checked = !day.checked">
              <div :class="$style.day">
                {{ $t(`onboarding.new_tasks.roster.${day.name}`) }}
              </div>
            </BaseText>
          </div>
          <div v-if="day.checked" :class="$style.time">
            <BaseText mr>
              {{ $t('onboarding.new_tasks.roster.from') }}
            </BaseText>
            <BaseTimePicker
              v-model="day.from"
              :maxValue="day.to"
              v-test="'rosterFrom'"
            />
            <BaseText mr ml>
              {{ $t('onboarding.new_tasks.roster.to') }}
            </BaseText>
            <BaseTimePicker
              v-model="day.to"
              :minValue="day.from"
              v-test="'rosterTo'"
            />
          </div>
          <BaseText v-if="day.checked" link @click="handleApplyAll(day)">
            <div :class="$style.apply" v-test="'rosterApplyAll'">
              {{ $t('onboarding.new_tasks.roster.apply') }}
            </div>
          </BaseText>
        </div>
      </BaseCard>
    </div>
    <template #footer>
      <BaseButton
        :loading="loading"
        @click="handleMutate"
        v-test="'createRosterNext'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import eventBus from '@/event-bus';
import gql from 'graphql-tag';
import { inject, ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { fetchBusinessHours } from '@/modules/calendar/calendar/calendar-business-hours';

const showAlert = ref(false);
const mixpanel = inject<any>('mixpanel');

type Day = {
  name: string;
  from: string;
  to: string;
  checked: boolean;
};

const days = ref<Day[]>([
  { name: 'monday', from: '09:00', to: '19:00', checked: true },
  { name: 'tuesday', from: '09:00', to: '19:00', checked: true },
  { name: 'wednesday', from: '09:00', to: '19:00', checked: true },
  { name: 'thursday', from: '09:00', to: '19:00', checked: true },
  { name: 'friday', from: '09:00', to: '19:00', checked: true },
  { name: 'saturday', from: '09:00', to: '19:00', checked: true },
  { name: 'sunday', from: '09:00', to: '19:00', checked: false }
]);

const handleApplyAll = (value: Day) => {
  days.value.forEach((day: Day) => {
    day.from = value.from;
    day.to = value.to;
  });
};

const { mutate: createRoster, loading } = useMutation(gql`
  mutation CreateOnboardingRoster($input: CreateOnboardingRosterInput!) {
    createOnboardingRoster(input: $input) {
      errors
    }
  }
`);

const handleMutate = () => {
  mixpanel.track('Onboarding tasks - Schedule saved');
  showAlert.value = false;
  const checkValidation = days.value.filter((day: Day) => day.to === day.from);

  if (checkValidation.length < 1) {
    createRoster({
      input: {
        forceDelete: true,
        roster: days.value
          .filter((day: Day) => day.checked)
          .reduce(
            (a, v) => ({
              ...a,
              [v.name]: {
                open: v.from,
                closed: v.to
              }
            }),
            {}
          )
      }
    }).then(() => {
      fetchBusinessHours();
      handleCompletion();
      eventBus.$emit('reload-ember', true);
    });
  } else {
    showAlert.value = true;
  }
};

const { mutate: updateTask } = useMutation(gql`
  mutation updateOnboardingTask($input: UpdateOnboardingTaskInput!) {
    updateOnboardingTask(input: $input) {
      onboardingTask {
        id
        task
        completedAt
        claimedAt
      }
    }
  }
`);

const handleCompletion = () => {
  updateTask({
    input: {
      task: 'SETUP_ROSTER',
      taskStatus: 'COMPLETE'
    }
  }).then(() => {
    eventBus.$emit('update-task', 'setup_roster');
    close();
  });
};

const close = () => {
  eventBus.$emit('after-modal-small-close');
  eventBus.$emit('open-task-menu');
};
</script>

<style lang="scss" module>
.card {
  margin-bottom: $spacing;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &.cardMobile {
    :hover {
      .apply {
        opacity: 0;
      }
    }
  }

  :hover {
    .apply {
      opacity: 1;
    }
  }
}

.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  &.baseMobile {
    flex-direction: column;
    align-items: flex-start;
    height: 65px;

    > .time {
      margin-top: 10px;
    }
  }
}

.day {
  cursor: pointer;
  user-select: none;
}

.date {
  display: flex;
  align-items: center;
  width: 100px;
}

.time {
  display: flex;
  align-items: center;
}

.apply {
  opacity: 0;
}
</style>
