<template>
  <div>
    <div>
      <BaseInputLabel :text="$t(`global.items.${resource.type}`, 1)" />
    </div>
    <div :class="$style.disabledResource">
      <BaseAvatar :resource="resource" :mr="0.5" />
      <BaseText>
        {{ resource.name }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import { useResourcesStore } from '@/stores/resources';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    allocation: {
      type: Object,
      required: true
    }
  },
  computed: {
    resource() {
      const { resourceById } = useResourcesStore();
      return resourceById(this.allocation.resourceId);
    }
  }
});
</script>

<style lang="scss" module>
.disabledResource {
  display: flex;
  align-items: center;
  height: $input-height;
}
</style>
