import gql from 'graphql-tag';

export const GET_STRIPE_INVOICES = gql`
  query getStripeInvoices($limit: Int!) {
    stripeInvoices(limit: $limit) {
      amountPaid
      amountRemaining
      realAmountRemaining
      createdAt
      currency
      id
      realStatus
      sealedAt
      status
      hostedInvoiceUrl
      invoicePdf
      subscription
      total
      invoiceNumber
    }
  }
`;

export const GET_INVOICES_QUERY = gql`
  query getInvoices($pagination: PaginationAttributes!) {
    invoices(pagination: $pagination) {
      currency
      date
      dueDate
      id
      invoiceNumber
      state
      total
    }
  }
`;
