import gql from 'graphql-tag';

export const GET_SERVICE_VARIATION_GROUPS = gql`
  query getServiceVariationGroups {
    serviceVariationGroups {
      category {
        color
      }
      bookable
      id
      name
      top
    }
  }
`;

export const UPDATE_TOP_SERVICE_VARIATION_GROUPS = gql`
  mutation updateTopServiceVariationGroups(
    $input: UpdateTopServiceVariationGroupsInput!
  ) {
    updateTopServiceVariationGroups(input: $input) {
      serviceVariationGroups {
        id
      }
    }
  }
`;
