<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isCompleted]: completed
      }
    ]"
  >
    <BaseCheck
      :checked="completed"
      :tooltip="
        completed ? $t('notes.mark_incomplete') : $t('notes.mark_completed')
      "
      mr
      @click="toggleCompleted"
      v-test="'dashboard-note-toggle'"
    />
    <div :class="$style.text">
      <BaseText>
        {{ data.text }}
      </BaseText>
    </div>
    <div :class="$style.right">
      <BaseAvatar
        v-if="resource"
        :resource="resource"
        :tooltip="resource.name"
        v-test="'dashboard-note-avatar'"
      />
      <BaseIcon
        v-else
        name="people"
        :tooltip="$t('global.entire_company')"
        v-test="'dashboard-note-icon'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { flash, hideTooltip } from '@/helpers/ui';
import dayjs from '@/dayjs';
import { useResourcesStore } from '@/stores/resources';
import { mapState } from 'pinia';
import gql from 'graphql-tag';
import { noteFragment } from '@/graphql-fragments';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      completed: !!this.data.completedAt
    };
  },
  computed: {
    ...mapState(useResourcesStore, ['resourceById']),
    resource() {
      return this.resourceById(this.data.resourceId);
    }
  },
  methods: {
    toggleCompleted() {
      this.completed = !this.completed;
      hideTooltip();

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateNote($input: UpdateNoteInput!) {
              updateNote(input: $input) {
                note {
                  ...note
                }
              }
            }
            ${noteFragment}
          `,
          variables: {
            input: {
              id: this.data.id,
              completedAt: this.completed ? dayjs().format() : null
            }
          }
        })
        .then(() => {
          flash(this.$t('global.flash.note_updated'));
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  padding: $spacing * 0.5 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &:last-child {
    margin-bottom: $spacing * -0.5;
  }
}

.text {
  padding-right: $spacing;
}

.right {
  width: 24px;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.text,
.right {
  .base.isCompleted & {
    opacity: 0.6;
  }
}
</style>
