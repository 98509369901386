<template>
  <div>
    <BaseRadio
      v-model="option"
      :options="filters"
      mb
      @update:modelValue="emitValue"
    />

    <BaseGrid container>
      <BaseGrid :size="2" alignCenter>
        {{ $t('global.from') }}
      </BaseGrid>
      <BaseGrid :size="3" v-test="'inputFrom'">
        <BaseInput
          v-model="from"
          type="number"
          @update:modelValue="emitValue"
        />
      </BaseGrid>
      <BaseGrid :size="2" alignCenter>
        {{ $t('global.to') }}
      </BaseGrid>
      <BaseGrid :size="3" v-test="'inputTo'">
        <BaseInput v-model="to" type="number" @update:modelValue="emitValue" />
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['selected'],
  data() {
    return {
      option: 'spent_past_year',
      filters: [
        {
          label: this.$t('customers.filters.spent_past_year'),
          value: 'spent_past_year'
        },
        {
          label: this.$t('customers.filters.total_spent'),
          value: 'total_spent'
        }
      ],
      from: 1,
      to: 100
    };
  },
  methods: {
    emitValue() {
      const values = {
        from: this.from,
        to: this.to
      };

      Object.keys(values).forEach((key) => (values[key] = values[key] * 100));

      const obj = {
        filter_name: this.option,
        values
      };

      this.$emit('selected', obj);
    }
  },
  created() {
    this.emitValue();
  }
});
</script>
