<template>
  <transition appear :duration="1000">
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.lightBackground]: company.companyType === 'BARBERSHOP'
        }
      ]"
    >
      <div :class="$style.inner">
        <div :class="$style.main">
          <div :class="$style.heading">
            <BaseHeading size="xl">
              {{ $t('treatwell.landing.heading') }}
            </BaseHeading>
          </div>
          <div :class="$style.content">
            <div :class="$style.visual">
              <img
                v-if="$screen === 's'"
                :src="`/img/treatwell/${salonSpecificFolder}landing-mobile-${imageLanguage}.jpg`"
              />
              <img
                v-else
                :src="`/img/treatwell/${salonSpecificFolder}landing-${imageLanguage}.jpg`"
              />
            </div>
            <div :class="$style.side">
              <div :class="$style.sideInner">
                <BaseCard mb>
                  <BaseHeading size="s" :mb="0.5">
                    {{ bookingHeader }}
                  </BaseHeading>
                  <BaseHeading size="l">
                    {{ averageBookingsPerMonth }}
                  </BaseHeading>
                </BaseCard>
                <div :class="$style.links">
                  <BaseText
                    :href="$t('treatwell.landing.link_video.url')"
                    iconBefore="play-circle"
                    @click="mixpanel.track('TWLandingPage-MoreInfo')"
                  >
                    {{ $t('treatwell.landing.link_video.text') }}
                  </BaseText>
                  <BaseText
                    :href="$t('treatwell.landing.link_conditions.url')"
                    iconBefore="book-open"
                    @click="mixpanel.track('TWLandingPage-Costs')"
                  >
                    {{ $t('treatwell.landing.link_conditions.text') }}
                  </BaseText>
                </div>
              </div>
              <Button :class="$style.button" />
              <BaseHeading mt mb size="m">
                {{ $t('treatwell.landing.reasons.header') }}
              </BaseHeading>
              <ul>
                <li>
                  {{ $t('treatwell.landing.reasons.reason_1') }}
                </li>
                <li>
                  {{ $t('treatwell.landing.reasons.reason_2') }}
                </li>
                <li>
                  {{ $t('treatwell.landing.reasons.reason_3') }}
                </li>
                <li>
                  {{ $t('treatwell.landing.reasons.reason_4') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import Button from './Button.vue';
import { computed, inject } from 'vue';
const mixpanel = inject<any>('mixpanel');

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const { company } = useCompanyStore();
const imageLanguage = computed(() =>
  company.language === 'de' || company.language === 'nl'
    ? company.language
    : 'en'
);
const salonSpecificFolder = computed(() => {
  if (company.language !== 'nl') {
    return '';
  }
  switch (company.companyType) {
    case 'BARBERSHOP':
      return 'barbershop/';
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return 'nail_studio/';
    default:
      return '';
  }
});

const bookingHeader = computed(() => {
  switch (company.companyType) {
    case 'BARBERSHOP':
      return t('treatwell.landing.salon_specific.barbershop.more_bookings_1');
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return t('treatwell.landing.salon_specific.nail_studio.more_bookings_1');
    default:
      return t('treatwell.landing.more_bookings_1');
  }
});

const averageBookingsPerMonth = computed(() => {
  switch (company.companyType) {
    case 'BARBERSHOP':
      return t('treatwell.landing.salon_specific.barbershop.more_bookings_2');
    case 'NAIL_STUDIO':
    case 'MANI_PEDICURE':
      return t('treatwell.landing.salon_specific.nail_studio.more_bookings_2');
    default:
      return t('treatwell.landing.more_bookings_2');
  }
});
</script>

<style lang="scss" module>
.base {
  min-height: 100%;
  background: url('/img/treatwell/landing-bg.svg') 100px -50px no-repeat;
  background-size: cover;
  background-attachment: fixed;

  &.lightBackground {
    background:
      linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
      no-repeat 100px -50px/100% url('/img/treatwell/landing-bg.svg');
  }

  .base.smallScreen & {
    background-position: -50px -60px;
  }

  &:global(.v-enter-active) {
    transition: opacity 0.1s;
  }

  &:global(.v-enter-from) {
    opacity: 0;
  }
}

.inner {
  position: relative;
  z-index: 1;

  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.main {
  max-width: 940px;
  margin: 0 auto;
}

.heading {
  max-width: 500px;
  margin-bottom: $spacing * 2;
}

.content {
  display: flex;

  .base.smallScreen & {
    flex-direction: column;
  }
}

.visual {
  img {
    display: block;
    max-width: 100%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .base:not(.smallScreen) & {
    width: 63%;
    max-width: 592px;
  }

  .base.smallScreen & {
    order: 1;
    margin-top: $spacing * 2;
  }

  .base:global(.v-enter-active) & {
    transition: transform 0.2s $easeOutCirc;
  }

  .base:global(.v-enter-from) & {
    transform: scale(0.8);
  }
}

.side {
  .base:not(.smallScreen) & {
    width: 37%;
    padding: 60px 0 $spacing * 1.5 $spacing * 1.5;
  }
}

.sideInner {
  & > * {
    .base:global(.v-enter-active) & {
      transition:
        transform 0.5s $easeOutCirc,
        opacity 0.2s linear;

      &:nth-child(1) {
        transition-delay: 0.1s;
      }
      &:nth-child(2) {
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        transition-delay: 0.3s;
      }
    }

    .base:global(.v-enter-from) & {
      transform: translateX(-50px);
      opacity: 0;
    }
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing * 2;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
}

.button {
  .base:global(.v-enter-active) & {
    transition:
      transform 0.5s $easeOutCirc,
      opacity 0.2s linear;
  }

  .base:global(.v-enter-from) & {
    transform: translateY(50px);
    opacity: 0;
  }
}
</style>
