<template>
  <Builder v-if="!loading" v-model="toggleValue" :settings="settings">
    <template #actions>
      <TemplateActions :loading="isLoading" @save="onSave" />
    </template>
  </Builder>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import gql from 'graphql-tag';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';

import { DispatchTypeEnum } from '@/types';
import { modal, flash } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import Builder from '@/modules/marketing/email/builder/index.vue';
import { emailTemplateFragment } from '@/graphql-fragments';

import TemplateActions from '../../TemplateActions.vue';
import { useNavBack } from '../../useNavBack';

const store = useStore();
const { companySettings, updateCompany } = useCompanyStore();
const { t } = useI18n();

const isInvalid = computed(() => store.getters['marketing/isInvalid']);

const isLoading = ref(false);
const settings = reactive({
  emailType: 'WAITING_LIST',
  title: t('marketing.email.automated_emails.waiting_list.title'),
  description: t('marketing.email.automated_emails.waiting_list.intro'),
  sentDescription: t('marketing.email.automated_emails.waiting_list.when'),
  receiveDescription: t(
    `marketing.email.automated_emails.waiting_list.${store.getters['marketing/dispatchType'] === DispatchTypeEnum.Automatic ? 'automated_receive_info' : 'receive_info'}`
  ),
  showToggle: true,
  allowImageUpload: false
});

watch(
  () => store.getters['marketing/dispatchType'],
  (value: DispatchTypeEnum) => {
    settings.receiveDescription = t(
      `marketing.email.automated_emails.waiting_list.${value === DispatchTypeEnum.Automatic ? 'automated_receive_info' : 'receive_info'}`
    );
    store.commit('marketing/builder/SET_SETTINGS', settings);
  }
);

const { loading, result, onResult } = useQuery(
  gql`
    query getEmailTemplate($emailType: EmailTemplateEnum!) {
      emailTemplate(emailType: $emailType) {
        ...emailTemplate
      }
      emailTemplateDefault: emailTemplate(
        emailType: $emailType
        defaultValues: true
      ) {
        ...emailTemplate
      }
    }
    ${emailTemplateFragment}
  `,
  {
    emailType: 'WAITING_LIST'
  }
);

onResult(() => {
  if (result.value.emailTemplate) {
    result.value.emailTemplate.emailTemplateDefault =
      result.value.emailTemplateDefault;
    store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
  }
});

const toggleValue = computed({
  get() {
    return companySettings.bookings?.waitingListEnabled || false;
  },
  set(value) {
    const input = {
      settings: {
        bookings: {
          waitingListEnabled: value
        }
      }
    };

    updateCompany(input).then(() => {
      flash(t(`global.flash.waiting_list_${value ? 'enabled' : 'disabled'}`));
    });
  }
});

const { navBack } = useNavBack();

const onSave = () => {
  store.commit('marketing/SET_SUBMITTED', true);

  if (isInvalid.value) {
    return;
  }

  modal('confirmation').then(() => {
    isLoading.value = true;
    store
      .dispatch('marketing/updateEmailTemplate', 'WAITING_LIST')
      .finally(() => {
        navBack();
        flash(t('global.flash.email_saved'));
        store.commit('marketing/SET_SUBMITTED', false);
        isLoading.value = false;
      });
  });
};
</script>
