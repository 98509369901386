<template>
  <ModuleBase noBorder>
    <PageWrap>
      <div
        :class="[
          $style.base,
          {
            [$style.smallScreen]: $screen === 's'
          }
        ]"
      >
        <BaseHeading mb>{{ $t('email_domain.title') }}</BaseHeading>
        <BaseText mb>{{
          emailDomain
            ? $t('email_domain.description')
            : $t('email_domain.not_verifiable')
        }}</BaseText>

        <BaseCard :mt="2" gray>
          <BaseHeading mb>{{ $t('email_domain.how_to') }}</BaseHeading>
          <BaseText mb>{{ $t('email_domain.instructions') }}</BaseText>
          <BaseText mb>{{ $t('email_domain.instructions_help') }}</BaseText>

          <BaseCard :mb="2">
            <div :class="$style.grid">
              <div :class="$style.gridItem">
                <BaseText bold>{{ $t('email_domain.dns_name') }}</BaseText>
              </div>
              <div :class="$style.gridItem">
                <BaseText v-test="'domain-dns-name'"
                  >salonized._domainkey.{{ emailDomain?.name || '' }}</BaseText
                >
              </div>
              <div :class="$style.gridItem">
                <BaseText bold>{{ $t('email_domain.dns_type') }}</BaseText>
              </div>
              <div :class="$style.gridItem">
                <BaseText>TXT</BaseText>
              </div>
              <div :class="$style.gridItem">
                <BaseText bold>{{ $t('email_domain.dns_value') }}</BaseText>
              </div>
              <div :class="$style.gridItem">
                <BaseCodeField
                  multiline
                  buttonColor="inverted"
                  :code="emailDomain?.dkimRecordValue || ''"
                  v-test="'domain-dkim-record-name'"
                />
              </div>
            </div>
          </BaseCard>

          <BaseText mb>{{ $t('email_domain.verify_description') }}</BaseText>

          <BaseCard>
            <div :class="$style.emailDomainContainer">
              <div>
                {{ emailDomain?.name }}
                <BaseLabel :color="labelColor">{{ labelText }}</BaseLabel>
              </div>
              <BaseButton color="inverted" :loading="loading" @click="mutate">{{
                $t('email_domain.verify_domain')
              }}</BaseButton>
            </div>
          </BaseCard>
        </BaseCard>
      </div>
    </PageWrap>
  </ModuleBase>
</template>

<script setup lang="ts">
import type { EmailDomain } from '@/types';
import type { BaseLabelProps } from '@/components/BaseLabel.vue';
import { computed } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import ModuleBase from '@/modules/ModuleBase.vue';
import PageWrap from '../PageWrap.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type ResultType = { emailDomain: EmailDomain & { status: EmailDomainStatus } };
import { EmailDomainStatus } from '@/types';

const { result, loading: loadingQuery } = useQuery(gql`
  query getCurrentUser {
    currentUser {
      company {
        emailDomain {
          dkimRecordValue
          id
          name
          status
        }
      }
    }
  }
`);

const emailDomain = computed(
  () => result.value?.currentUser.company.emailDomain
);

const { mutate, loading: loadingMutation } = useMutation<ResultType>(
  gql`
    mutation VerifyDomain($input: VerifyDomainInput!) {
      verifyDomain(input: $input) {
        emailDomain {
          createdAt
          dkimRecordValue
          id
          name
          status
        }
      }
    }
  `,
  () => ({
    variables: {
      input: {}
    }
  })
);

mutate();

const loading = computed<boolean>(
  () => loadingQuery.value || loadingMutation.value
);

const labelColor = computed<BaseLabelProps['color']>(() => {
  switch (emailDomain.value?.status) {
    case EmailDomainStatus.Active:
      return 'success';
    case EmailDomainStatus.Pending:
      return 'warning';
    default:
      return 'error';
  }
});

const labelText = computed(() => {
  switch (emailDomain.value?.status) {
    case EmailDomainStatus.Active:
      return t('email_domain.verified');
    case EmailDomainStatus.Pending:
      return t('email_domain.pending');
    default:
      return t('email_domain.not_verified');
  }
});
</script>

<style lang="scss" module>
.grid {
  .base:not(.smallScreen) & {
    display: grid;
    grid-template-columns: 100px auto;
  }
}

.gridItem {
  .base:not(.smallScreen) & {
    padding: $spacing;
  }

  .base.smallScreen & {
    &:nth-child(even):not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.emailDomainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
