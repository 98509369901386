<template>
  <BaseModalLarge
    :heading="$t('new_import.heading')"
    autoHeight
    @close="$emit('close')"
  >
    <BaseHeading size="s" mb> {{ $t('new_import.match') }} </BaseHeading>
    <BaseTimeline :stepsCompleted="step" :items="timelineSteps" />
    <BaseText bold size="l" :mt="1.5">
      {{ $t(`new_import.columnChoices.${columns[step]}`) }}
    </BaseText>

    <div
      v-if="!loading"
      :class="[
        $style.columns,
        {
          [$style.isSmall]: $screen === 's'
        }
      ]"
    >
      <ImportColumn
        v-for="(fields, index) in columnData?.slice(
          0,
          showAll ? columnData.length + 1 : 4
        )"
        :key="fields.field"
        :options="fields.options"
        :field="fields.field"
        :selected="selected"
        :index="index"
        :columnIndex="columnIndexSelection"
        :completed="completed.includes(fields.field)"
        @selection="handleSelection($event, index)"
        v-test="'importColumn'"
      />
    </div>
    <BaseText
      v-if="!showAll && columnData?.length > 4"
      link
      mt
      @click="showAll = true"
    >
      {{ $t('new_import.show_all') }} +
    </BaseText>
    <template #footer>
      <BaseButton
        v-if="completed.length > 1"
        color="inverted"
        @click="handleSkip"
        v-test="'importSkipButton'"
      >
        {{ $t('global.actions.skip') }}
      </BaseButton>
      <BaseButton class="ml" @click="handleNext" v-test="'importNextButton'">
        {{ $t('global.actions.next') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable';
import '@/components/BaseTimeline.vue';
import gql from 'graphql-tag';
import { ref, inject } from 'vue';
import ImportColumn from './ImportColumn.vue';
import { useI18n } from 'vue-i18n';

const mixpanel = inject<any>('mixpanel');
const emit = defineEmits(['close', 'columnMapping', 'columnData']);
const props = defineProps<{
  importId: number;
}>();

const { t } = useI18n();

const step = ref(0);
const completed = ref([]);
const columnData = ref<[{ field: string; options: [] }]>();
const selected = ref('');
const showAll = ref(false);

const columns = ['firstName', 'lastName', 'email', 'phone'];
const timelineSteps = [
  t('new_import.columns.firstName'),
  t('new_import.columns.lastName'),
  t('new_import.columns.email'),
  t('new_import.columns.phone')
];
const columnMapping = ref();
const columnIndexSelection = ref();

const handleSelection = (value: string, index: number) => {
  selected.value = value;
  columnIndexSelection.value = index;
};

const handleNext = () => {
  mixpanel.track('Onboarding tasks - Imports - Select');
  completed.value = [...completed.value, selected.value];

  columnMapping.value = {
    ...columnMapping.value,
    [columns[step.value]]: columnIndexSelection.value
  };

  step.value += 1;

  columnData.value?.some((item: any, idx: number) => {
    if (!completed.value.includes(item.field)) {
      selected.value = item.field;
      columnIndexSelection.value = idx;
      return true;
    }
  });

  if (step.value >= 4) {
    mixpanel.track('Onboarding tasks - Imports - Selection Complete');
    emit('columnMapping', columnMapping.value);
    emit('columnData', columnData.value);
  }
};

const handleSkip = () => {
  step.value += 1;
  if (step.value >= 4) {
    emit('columnMapping', columnMapping.value);
    emit('columnData', columnData.value);
  }
};

const { result, onResult, loading } = useQuery(
  gql`
    query getImports($id: ID!) {
      import(id: $id) {
        id
        state
        headers
        dataPreview
      }
    }
  `,
  {
    id: props.importId
  }
);

onResult(() => {
  columnData.value = result.value.import.headers.map(
    (item: any, idx: number) => ({
      field: item,
      options: result.value.import.dataPreview[idx].slice(0, 3).concat(['...'])
    })
  );

  selected.value = result.value.import.headers[0];
  columnIndexSelection.value = 0;
});
</script>

<style lang="scss" module>
.columns {
  display: flex;
  flex-wrap: wrap;

  &.isSmall {
    justify-content: center;
  }
}
</style>
