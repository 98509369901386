import dayjs from '@/dayjs';
import i18n from '@/i18n';
import { useResourcesStore } from '@/stores/resources';

export const setRruleBymonthday = (dateString) => [dayjs(dateString).date()];

export const setRruleMonthlyByday = (dateString) => {
  const dateObj = dayjs(dateString);
  return [[Math.ceil(dateObj.date() / 7), dateObj.day() || 7]];
};

export const rruleFrequency = (rrule) =>
  i18n.t(
    `calendar.recurring_confirmation.day.${rrule.freq}.${rrule.interval === 1 ? 'one' : 'other'}`,
    { count: rrule.interval }
  );

export const getCalendarIcons = ({ features }) => {
  if (!features) {
    return [];
  }

  const icons = [
    {
      name: 'globe',
      show: features.online && !features.google_reserve,
      tooltip: {
        name: 'online'
      }
    },
    {
      name: 'google',
      show: features.google_reserve,
      tooltip: {
        name: 'google_reserve'
      }
    },
    {
      name: 'smiley_happy',
      show: features.feedback_score && features.feedback_score >= 3,
      tooltip: {
        name: 'feedback_positive'
      }
    },
    {
      name: 'smiley_sad',
      show: features.feedback_score && features.feedback_score < 3,
      tooltip: {
        name: 'feedback_negative'
      }
    },
    {
      name: 'gift',
      show: features.birthday,
      tooltip: {
        name: 'birthday'
      }
    },
    {
      name: 'repeat',
      show: features.recurs,
      tooltip: {
        name: 'recurring_with_frequency'
      }
    },
    {
      name: 'pricetag',
      show: features.label,
      tooltip: {
        text: features.label
      }
    },
    {
      name: 'file',
      show: features.form_submissions,
      tooltip: {
        name: 'form_submissions'
      }
    },
    {
      name: 'lock',
      show: features.resource_preference === 'present',
      tooltip: {
        name: 'resource_preference'
      }
    },
    {
      name: 'message-circle',
      show: features.notes,
      tooltip: {
        text: features.notes
      }
    },
    {
      name: 'alert-triangle',
      show: features.alert_notes,
      tooltip: {
        text: features.alert_notes
      }
    },
    {
      name: 'clipboard',
      show: features.report,
      tooltip: {
        name: 'report'
      }
    },
    {
      name: 'close-square',
      show: features.no_show,
      tooltip: {
        name: 'no_show'
      }
    },
    {
      name: 'checkmark',
      show: features.order,
      tooltip: {
        name: 'order'
      }
    },
    {
      name: 'treatwell',
      show: features.treatwell,
      tooltip: {
        name: 'treatwell'
      }
    }
  ];

  return icons.filter((icon) => icon.show);
};

export const getDateRangeWeek = (date) => {
  // Based on a date string, return the first and last dates of the week of that date
  const dayjsDate = dayjs(date);
  const dayOfWeek = dayjsDate.day();
  return {
    start: dayjsDate.subtract(dayOfWeek - 1, 'day').format('YYYY-MM-DD'),
    end: dayjsDate.add(7 - dayOfWeek + 1, 'day').format('YYYY-MM-DD')
  };
};

export const getFullCalendarViewType = (viewType) => {
  const viewTypes = [
    {
      name: 'WEEK',
      fullCalendarName: 'timeGridWeek'
    },
    {
      name: 'DAY',
      fullCalendarName: 'resourceTimeGridDay'
    }
  ];

  return (
    viewTypes.find((type) => type.name === viewType)?.fullCalendarName ||
    'timeGridWeek'
  );
};

export const formatEvent = (event: any) => {
  let newEvent;

  // Add all resources to absences, notes & waiting_lists that need to be displayed for the entire company
  if (
    !event.resourceId &&
    ['absence', 'note', 'waiting_list'].includes(event.extendedProps.type)
  ) {
    if (!newEvent) {
      newEvent = { ...event };
    }

    const { resources } = useResourcesStore();

    newEvent.resourceId = null;
    newEvent.resourceIds = resources.map((resource) => resource.id);
  }

  // Set title for waiting list items
  if (event.extendedProps.type === 'waiting_list') {
    if (!newEvent) {
      newEvent = { ...event };
    }

    newEvent.title = i18n.t('calendar.waiting_list');
  }

  return newEvent || event;
};
