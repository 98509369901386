<template>
  <div v-test="`logged-out-page`">
    <BaseHeading size="xl" mb center>
      {{ $t('logged_out.heading') }}
    </BaseHeading>
    <BaseText mb center>
      {{ $t('logged_out.description') }}
    </BaseText>
    <div :class="$style.buttonWrap">
      <BaseButton :routerLink="{ name: 'login' }" v-test="'btn-login'">
        {{ $t('logged_out.button') }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" module>
.buttonWrap {
  display: flex;
  justify-content: center;
}
</style>
