<template>
  <BaseCard gray mb v-test="'google-calendar-to-salonized-sync'">
    <BaseHeading mb> GCal → Salonized </BaseHeading>

    <BaseText mb>{{
      $t('integrations.google_calendar.description_incoming')
    }}</BaseText>

    <BaseInputLabel
      :text="$t('integrations.google_calendar.settings.target_calendars')"
      mb
    />
    <Calendar
      v-for="(calendar, index) in incomingCalendars"
      :key="index"
      :calendar="calendar"
    />
  </BaseCard>
</template>

<script lang="ts">
export default {
  name: 'GoogleToSalonized'
};
</script>

<script setup lang="ts">
import type { SalonizedGoogleCalendarData } from '../types';
import Calendar from './Calendar.vue';

const { incomingCalendars } = inject<SalonizedGoogleCalendarData>(
  'salonized-google-calendar'
)!;
</script>
