<template>
  <div v-if="favorites.length && !loadingFavorites">
    <BaseHeading size="s" mb>
      {{ $t('register.favorites') }}
    </BaseHeading>
    <div :class="$style.list">
      <TransitionGroup name="list">
        <FavoriteItem v-for="item in favorites" :key="item.id" :item="item" />
      </TransitionGroup>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Favorites'
};
</script>

<script setup lang="ts">
import { useRegisterBrowserStore } from '@/modules/register/builder/browser/store';
import { storeToRefs } from 'pinia';
import FavoriteItem from './FavoriteItem.vue';

const { favorites, loadingFavorites } = storeToRefs(useRegisterBrowserStore());
</script>

<style lang="scss" module>
.list {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 0.5;
  position: relative;
}
</style>

<style lang="scss" scoped>
.list-move,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-leave-to {
  opacity: 0;
  transform: scale(0.4);
}

.list-leave-active {
  position: absolute;
}
</style>
