import { useI18n } from 'vue-i18n';
import type { ReturnData, TrialTask } from '../types';
import { computed, ref } from 'vue';

export default (): ReturnData => {
  const { t } = useI18n();

  const data = computed(() => ({
    completed: {
      description: t('onboarding.new_onboarding.marketing_trial.description'),
      visual: '/img/onboarding/upgrade.svg'
    },
    heading: t('onboarding.new_onboarding.marketing_trial.header'),
    icon: {
      dark: '/img/onboarding/icon-dark.svg',
      light: '/img/onboarding/icon-light.svg'
    },
    link: {
      routeName: 'subscription',
      text: t('onboarding.new_onboarding.tasks.upgrade')
    },
    remainingDays: 0
  }));

  const tasks = ref<TrialTask[]>([]);

  return {
    data,
    tasks
  };
};
