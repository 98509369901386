<template>
  <div>
    <BaseGrid container>
      <BaseGrid>
        <BaseHeading size="s" :mb="0.5">
          {{ $t('products.overview_on') }}
        </BaseHeading>
        <BaseSwitch
          v-model="selectedFilter"
          :options="options"
          :vertical="$screen === 's'"
          v-test="'products-filter'"
        />
      </BaseGrid>
      <BaseGrid v-if="productCategories && productCategories.length" :size="2">
        <BaseDropdown
          v-model="selectedCategories"
          :label="$t('global.category')"
          :options="
            productCategories.map((p) => ({
              value: p.id,
              label: p.name
            }))
          "
          searchable
          v-test="'select-category'"
        />
      </BaseGrid>
      <BaseGrid v-if="suppliers && suppliers.length" :size="2">
        <BaseDropdown
          v-model="selectedSuppliers"
          :label="$t('global.supplier')"
          :options="
            suppliers.map((s) => ({
              value: s.id,
              label: s.name
            }))
          "
          searchable
          v-test="'select-supplier'"
        />
      </BaseGrid>
      <BaseGrid :size="$screen !== 'l' ? 4 : 3" alignBottom>
        <BaseSearch v-model="searchQuery" debounce />
      </BaseGrid>
      <BaseGrid alignRight alignBottom>
        <BaseButton
          :routerLink="{ name: 'update-product-prices' }"
          color="inverted"
          mr
          @click="mixpanel.track('Click - Update Prices')"
          v-test="'updatePricesButton'"
        >
          {{ $t('products.update_prices') }}
        </BaseButton>
        <BaseButton
          v-if="$screen !== 's'"
          color="inverted"
          mr
          :routerLink="{ name: 'import-products' }"
          @click="mixpanel.track('Import - Products', { from: 'main' })"
        >
          {{ $t('global.actions.import') }}
        </BaseButton>
        <BaseButton
          :routerLink="{ name: 'create-product' }"
          v-test="'createProduct'"
        >
          {{ $t('global.actions.new_product') }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { GET_PRODUCT_CATEGORIES, GET_SUPPLIERS } from './graphql';
import { defineComponent, computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    categoryIds: {
      type: Array,
      required: true
    },
    supplierIds: {
      type: Array,
      required: true
    }
  },
  emits: [
    'update:categoryIds',
    'update:supplierIds',
    'search',
    'selectedFilter'
  ],
  setup() {
    const { result: categoriesResult } = useQuery(GET_PRODUCT_CATEGORIES, {
      pagination: {
        currentPage: 1,
        perPage: 1000
      }
    });

    const productCategories = computed(
      () => categoriesResult.value?.productCategories || []
    );

    const { locationId } = useLocationsStore();

    const { result: suppliersResult } = useQuery(GET_SUPPLIERS, {
      pagination: {
        currentPage: 1,
        perPage: 1000
      },
      locationId
    });

    const suppliers = computed(() => suppliersResult.value?.suppliers || []);

    return {
      productCategories,
      suppliers
    };
  },
  data() {
    return {
      selectedFilter: 'all',
      searchQuery: ''
    };
  },
  watch: {
    searchQuery(value) {
      this.$emit('search', value);
    },
    selectedFilter() {
      const filter = this.selectedFilter !== 'all' ? this.selectedFilter : null;
      this.$emit('selectedFilter', filter);
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    selectedCategories: {
      get() {
        return this.categoryIds;
      },
      set(value) {
        this.$emit('update:categoryIds', value);
      }
    },
    selectedSuppliers: {
      get() {
        return this.supplierIds;
      },
      set(value) {
        this.$emit('update:supplierIds', value);
      }
    },
    options() {
      return [
        {
          label: this.$t('products.all_products'),
          value: 'all'
        },
        {
          label: this.$t('products.stock_warnings'),
          value: 'out_of_stock',
          notification: this.stockWarningCount
        },
        {
          label: this.$t('global.inventory'),
          value: 'stock_enabled'
        }
      ];
    },
    stockWarningCount() {
      if (this.company?.stockWarnings) {
        const warning = this.company.stockWarnings.find(
          (war) => war.location_id === this.locationId
        );
        if (warning) {
          return warning.count;
        } else {
          return 0;
        }
      }
      return 0;
    }
  }
});
</script>
