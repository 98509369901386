<template>
  <div v-if="hasFeatureFlag('module-messages')">
    <router-view />
    <div v-if="!showEmptyPageContent" :class="$style.actions">
      <Filter v-model="variables.filter" />
      <BaseSearch v-model="variables.search" debounce />
    </div>
    <MessagesList
      v-if="messages && messages.length"
      :messages="messages"
      :filter="variables.filter"
    />
    <LoadMore
      v-show="messages && messages.length && !allDataFetched"
      @shown="fetchMore"
    />
    <BaseSpinner
      v-if="
        !showEmptyPageContent &&
        (!messages || !messages.length) &&
        !allDataFetched
      "
    />
    <div :class="$style.flex">
      <EmptyPageContent
        v-if="showEmptyPageContent || showNoResults"
        imageName="messages"
        :text="
          showNoResults
            ? {
                description: $t('global.no_results')
              }
            : {
                title: $t('messages.empty_state.title'),
                description: $t('messages.empty_state.description')
              }
        "
        :moreLink="
          showEmptyPageContent
            ? {
                text: $t('messages.empty_state.more_text'),
                url: $t('messages.empty_state.more_url')
              }
            : null
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'MessageLogs'
};
</script>

<script setup lang="ts">
import Filter from './Filter.vue';
import MessagesList from './MessagesList.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { GET_MESSAGES } from './graphql';
import { usePagination } from '@/apollo/pagination';
import { computed, watchEffect } from 'vue';
import LoadMore from '@/components/LoadMore.vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';

const { hasFeatureFlag } = useUserStore();

const route = useRoute();
const router = useRouter();

const { messages, allDataFetched, fetchMore, loading, variables } =
  usePagination({
    fieldName: 'messages',
    query: GET_MESSAGES,
    variables: {
      filter: (route.query.filter as string) || '',
      search: ''
    }
  });

watchEffect(() => {
  if (route.name) {
    router.replace({
      name: route.name,
      query: variables.filter ? { filter: variables.filter } : {}
    });
  }
});

const showNoResults = computed(() => !messages.value.length && !loading.value);
const showEmptyPageContent = computed(
  () => showNoResults.value && !variables.search && !variables.filter
);
</script>

<style lang="scss" module>
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacing;

  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}
</style>
