<template>
  <div
    v-if="isVisited"
    :class="[
      $style.base,
      {
        [$style.isLoading]: $apollo.loading
      }
    ]"
  >
    <BaseSpinner v-if="$apollo.loading" />
    <div v-else-if="timelineItems.length">
      <ActivityItem
        v-for="(data, index) in timelineItems"
        :key="index"
        :data="data"
        v-test="'activity-item'"
      />
    </div>
    <div v-else v-test="'empty-activity'">
      <EmptyPageContent
        imageName="no-activity"
        :text="{
          description: $t('dashboard.activity.empty')
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import ActivityItem from './ActivityItem.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ActivityItem,
    EmptyPageContent
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisited: false,
      timelineItems: []
    };
  },
  watch: {
    isVisible: {
      handler(value) {
        if (value) {
          this.isVisited = true;
        }
      },
      immediate: true
    }
  },
  apollo: {
    timelineItems: {
      query: gql`
        query getTimelineItems($dashboard: Boolean, $limit: Int) {
          timelineItems(dashboard: $dashboard, limit: $limit) {
            customer {
              id
              firstName
            }
            createdAt
            data
            id
            key
            meta
            recordId
            recordType
            user {
              id
              fullName
            }
          }
        }
      `,
      skip() {
        return !this.isVisible;
      },
      pollInterval: 5 * 60 * 1000,
      variables: {
        dashboard: true,
        limit: 15
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  &.isLoading {
    min-height: 55px;
  }
}
</style>
