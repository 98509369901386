<template>
  <BaseModal
    :heading="
      user && user.firstName
        ? `${$t('company_type.heading')}, ${user.firstName}`
        : $t('company_type.heading')
    "
    small
    testId="company-type"
    unclosable
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseText mb>
        {{ $t('company_type.description') }}
      </BaseText>
      <div :class="$style.companyTypes">
        <div
          v-for="(companyType, index) in companyTypes"
          :key="index"
          :class="$style.companyType"
          @click="selectType(companyType.key)"
          v-test="'btn-companytype'"
        >
          <div :class="$style.companyTypeInner">
            <BaseText bold :size="$screen === 's' ? 's' : 'm'">
              {{ $t(`company_type.types.${companyType.name}`) }}
            </BaseText>
          </div>
          <img
            :class="[
              $style.companyTypeVisual,
              {
                [$style.visualSmall]:
                  companyType.name === 'hair_salon' ||
                  companyType.name === 'other'
              }
            ]"
            :src="`/img/company-types/${companyType.name}.svg`"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { modal } from '@/helpers/ui';
const companyTypes = [
  'HAIR_SALON',
  'BARBERSHOP',
  'MANI_PEDICURE',
  'BEAUTY_SALON',
  'THERAPIST',
  'OTHER'
];
import gql from 'graphql-tag';
import { updateDataLayer } from '@/helpers/gtm';
import { useServicesStore } from '@/stores/services';
import { mapActions, mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  setup() {
    const { user } = useUserStore();
    return {
      user
    };
  },
  data() {
    return {
      selectedType: null
    };
  },
  computed: {
    companyTypes() {
      return companyTypes.map((key) => ({
        key,
        name: key.toLowerCase()
      }));
    },
    ...mapState(useCompanyStore, ['isInsideTestBucket'])
  },
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    selectType(companyType: any) {
      this.updateCompany({ companyType }).then(() => {
        this.mixpanel.track('Onboarding journey - Visited', {
          claim_days: this.isInsideTestBucket('trial_rewards_enabled')
        });
        modal('new-onboarding');

        this.$apollo
          .mutate({
            mutation: gql`
              mutation createWelcomeServices(
                $input: CreateWelcomeServicesInput!
              ) {
                createWelcomeServices(input: $input) {
                  services {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {}
            }
          })
          .then(() => {
            const { getServices, getServiceCategories } = useServicesStore();
            getServices();
            getServiceCategories();

            // IMPORTANT! Track trial conversion
            updateDataLayer().then(() => {
              if (this.$gtm) {
                this.$gtm.trackEvent({ event: 'trial' });
              }
            });
          });
      });
    }
  },
  created() {
    this.mixpanel.track('Onboarding journey - Industry modal shown');
  }
});
</script>

<style lang="scss" module>
.companyTypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: $spacing * -1;
}

.companyType {
  position: relative;
  width: calc(50% - #{$spacing * 0.5});
  word-break: break-word;
  cursor: pointer;
  margin-bottom: $spacing;
  background-color: #f5f6fa;
  border-radius: 4px;
  overflow: hidden;

  @include hover {
    background-color: $color-highlight;
  }
}

.companyTypeVisual {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;

  .base.smallScreen & {
    right: -20px;

    &.visualSmall {
      right: 0;
    }
  }
}

.companyTypeInner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: $spacing;
  z-index: 1;

  .base:not(.smallScreen) & {
    height: 72px;
  }

  .base.smallScreen & {
    max-width: 70%;
    height: 100%;
  }
}
</style>
