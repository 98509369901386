<template>
  <BaseModal
    v-if="formData"
    :loading="isLoading"
    :parentRoute="parentRouteName"
    :heading="formData ? formData.name : ''"
  >
    <div
      v-for="(question, index) in formData.questions"
      :key="index"
      :class="$style.question"
    >
      <div v-if="formData.formType === 'contract'">
        <BaseText v-if="question.options">
          <div
            v-html="
              typeof question.options === 'string'
                ? question.options
                : question.options.join(' ')
            "
          />
        </BaseText>
        <SignatureInput
          v-if="question.answer && question.questionType === 'signature'"
          :image="question.answer"
          :createdAt="formSubmission.createdAt"
          :submittedAt="formSubmission.submittedAt"
        />
      </div>

      <div v-if="formData.formType === 'consultation'">
        <div v-if="answerExists(question)" :class="$style.lineHeight">
          <BaseText bold>{{ question.question }}</BaseText>
          <BaseText>
            <div
              v-if="question.questionType === 'boolean'"
              v-test="'booleanAnswer'"
            >
              {{ question.answer ? $t('global.yes') : $t('global.no') }}
            </div>
            <div v-else-if="question.questionType === 'checkbox'">
              <div v-for="(item, index) in question.answer" :key="index">
                {{ item }}
              </div>
            </div>
            <div v-else v-html="filters.formatEnters(question.answer)" />
          </BaseText>
        </div>
      </div>
    </div>
    <BaseText
      v-if="formSubmission.answers && !formSubmission.answers.length"
      v-test="'noResults'"
      >{{ $t('global.no_results') }}</BaseText
    >
    <template v-if="formData.formType !== 'contract'" #footer>
      <BaseButton @click="onEdit" v-test="'formPreviewEdit'">
        {{ $t('global.actions.edit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import SignatureInput from './SignatureInput.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    SignatureInput
  },
  props: {
    parentRouteName: String
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      isLoading: true,
      signature: [],
      formData: null,
      formSubmission: null
    };
  },
  apollo: {
    formSubmission: {
      query: gql`
        query getFormSubmission($id: Int!, $scopes: [String!]) {
          formSubmission(id: $id) {
            id
            createdAt
            submittedAt
            answers {
              ... on StringAnswer {
                stringAnswer: answer
                id
                questionId
              }
              ... on BooleanAnswer {
                booleanAnswer: answer
                id
                questionId
              }
              ... on ArrayAnswer {
                arrayAnswer: answer
                id
                questionId
              }
            }
            template {
              id
              name
              corona
              formType
              questions(scopes: $scopes) {
                id
                options
                question
                questionType
                sortIndex
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          scopes: ['with_deleted']
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result() {
        this.formSubmission.template.questions.forEach((question, index) => {
          this.formSubmission.answers.forEach((answer) => {
            this.formatAlias(answer);
            if (answer.questionId === question.id) {
              this.formSubmission.template.questions[index].answer =
                answer.answer;
            }
          });
        });
        this.formData = this.formSubmission.template;
        this.isLoading = false;
      },
      operationName: 'getFormSubmission'
    }
  },
  methods: {
    formatAlias(answer) {
      // fix for cypress
      // I had to use an alias for every inline fragment because they all use the same query, I don't want an alias though.
      ['booleanAnswer', 'stringAnswer', 'arrayAnswer'].forEach((alias) => {
        if (alias in answer) {
          answer.answer = answer[alias];
          delete answer[alias];
        }
      });
    },
    onEdit() {
      const sub = this.formSubmission;
      this.$router.push({
        name: 'customer-form-edit',
        params: { templateId: sub.template.id, subId: sub.id }
      });
    },
    answerExists(question) {
      return (
        question.answer ||
        (question.questionType === 'boolean' &&
          question.answer !== null &&
          typeof question.answer !== 'undefined')
      );
    }
  }
});
</script>
<style lang="scss" module>
.question {
  &:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
