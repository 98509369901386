<template>
  <div>
    <WaitingList v-if="waitingListEnabled" />
    <EmptyPageContent
      v-else
      imageName="marketing-waitinglist"
      :text="{
        title: $t('waiting_list.disabled.heading'),
        description: $t('waiting_list.disabled.info'),
        btnPrimary: $t('waiting_list.enable_waiting_list')
      }"
      @click="enable"
    />
  </div>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import WaitingList from './WaitingList.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useCompanyStore } from '@/stores/company';
import { useOnboardingStore } from '@/stores/onboarding';
import { mapState, mapActions, storeToRefs } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    WaitingList,
    EmptyPageContent
  },
  inject: ['mixpanel'],
  setup() {
    const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());

    return {
      currentOnboardingFlow
    };
  },
  computed: {
    ...mapState(useCompanyStore, ['companySettings']),
    waitingListEnabled() {
      return this.companySettings.bookings?.waitingListEnabled;
    }
  },
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    enable() {
      const input = {
        settings: {
          bookings: {
            waitingListEnabled: true
          }
        }
      };

      this.updateCompany(input).then(() => {
        if (this.currentOnboardingFlow === 'marketing') {
          eventBus.$emit('open-task-menu');
          this.currentOnboardingFlow = '';
        }
        this.mixpanel.track('Waiting list enabled');
      });
    }
  },
  mounted() {
    this.mixpanel.track('Waiting list page in marketing area loaded');
  }
});
</script>
