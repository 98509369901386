<template>
  <component
    :is="routerLink ? 'router-link' : 'div'"
    :to="routerLink || null"
    :class="[
      $style.base,
      {
        [$style.disabled]: disabled,
        [$style.zebra]: zebra,
        [$style.hasBorder]: border,
        [$style.isMobile]: $screen === 's',
        [$style.fill]: fill,
        [$style.link]: !!routerLink || link,
        [$style.grayedOut]: grayedOut
      }
    ]"
    @click="$emit('click')"
  >
    <BaseHeading v-if="title" border>{{ title }}</BaseHeading>
    <div
      :class="[
        $style.row,
        {
          [$style.isExpandable]: expandsOnEdit,
          [$style.isHead]: head,
          [$style.isFooter]: footer,
          [$style.isSmall]: small
        }
      ]"
      :style="summaryColor ? { borderBottom: `4px solid ${summaryColor}` } : {}"
    >
      <BaseSpinner v-if="loading" />
      <div
        :class="[$style.inner, { [$style.isLoading]: loading }]"
        @click="expand"
      >
        <slot />
      </div>
      <div v-if="options && options.length" :class="$style.options">
        <BaseMore :options="options" @select="onOptionClick" />
      </div>
      <BaseTableCell v-else-if="emptyOptionsCell">
        <div :class="$style.emptyOptions" />
      </BaseTableCell>
    </div>
    <div v-if="expandsOnEdit" v-show="isExpanded" :class="$style.row">
      <slot name="expanded" />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseTableCell from './BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableCell
  },
  inheritAttrs: false,
  props: {
    head: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    isExpandable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    startExpanded: {
      type: Boolean,
      default: false
    },
    expandsOnEdit: {
      type: Boolean,
      default: false
    },
    routerLink: {
      type: Object,
      default: null
    },
    zebra: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    emptyOptionsCell: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [],
      validator: (options) =>
        !options.find((option) => typeof option !== 'string' && !option.name)
    },
    wordBreak: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    grayedOut: {
      type: Boolean,
      default: false
    },
    summaryColor: {
      type: String,
      required: false
    }
  },
  emits: ['click', 'expand', 'optionClick'],
  data() {
    return {
      isExpanded: false
    };
  },
  watch: {
    startExpanded: {
      handler() {
        this.isExpanded = this.startExpanded;
      },
      immediate: true
    },
    isExpanded: {
      handler() {
        this.$emit('expand', this.isExpanded);
      },
      immediate: true
    }
  },
  methods: {
    expand() {
      if (this.expandsOnEdit) {
        this.isExpanded = !this.isExpanded;
      }
    },
    onOptionClick(option) {
      if ((option === 'edit' || option === 'view') && this.expandsOnEdit) {
        this.expand();
      } else {
        this.$emit('optionClick', option);
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  width: 100%;

  &.disabled {
    pointer-events: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &.zebra {
    &:nth-child(even) {
      background-color: $grey-light;
    }
  }
}

.fill {
  background-color: $grey-light;
}

.link {
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  @include hover {
    background-color: $color-highlight !important;
  }
}

.hasBorder {
  border-bottom: 1px solid $color-border;
}

.row {
  display: flex;
  align-items: center;

  &.isSmall {
    font-size: 10px;

    .inner > * {
      padding: $spacing * 0.5;
    }
  }
}

.inner {
  display: flex;
  width: 100%;
  align-items: center;

  .disabled & {
    opacity: 0.3;
  }
}

.isExpandable {
  cursor: pointer;
}

.isHead {
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(56, 53, 64, 0.6);
  letter-spacing: 0.05em;

  .base.isMobile & {
    font-size: 10px;
  }
}

.isFooter {
  background: $grey-light;
  font-weight: bold;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-left: $spacing;
}

.option {
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;

  & > div:first-child {
    margin-right: $spacing * 0.25;
  }
}

.options {
  padding: 0;

  .disabled & {
    pointer-events: auto;
  }
}

.isLoading {
  opacity: 0;
}

.emptyOptions {
  width: 20px;
}

.grayedOut {
  & > *:first-child {
    & > *:not(:last-child) {
      opacity: 0.5;
    }
  }
}
</style>
