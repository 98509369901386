<template>
  <BaseCard mb v-test="'billing-demo-booking-card'">
    <div :class="$style.container">
      <div>
        <BaseText>{{ t('admin.cards.demo.description') }}</BaseText>
        <BaseText
          >{{ t('admin.cards.demo.call_to_action') }}
          <span role="img" arial-label="sparkle">✨</span></BaseText
        >
      </div>
      <BaseButton color="inverted" @click="onBook">{{
        t('admin.cards.demo.button_label')
      }}</BaseButton>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const onBook = () => {
  window.open(t('dashboard.trial.demo_url'), '_blank');
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
