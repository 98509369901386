<template>
  <BaseScroll :contentWidth="1400">
    <BaseTableRow :title="title" head>
      <BaseTableCell />
      <BaseTableCell
        v-for="month in months"
        :key="`header_${month}`"
        currency
        wordBreak
      >
        <span :class="$style.printTableHead">
          {{ month }}
        </span>
      </BaseTableCell>
    </BaseTableRow>

    <BaseTableRow
      v-for="(row, index) in rows"
      :key="`$(type}_row_${index}`"
      zebra
      v-test="'reportRow'"
    >
      <BaseTableCell>
        {{
          row.percentage === null
            ? noVat
            : $t(`reports.vat_overview.vat_percentage`, {
                percentage: row.percentage
              })
        }}
      </BaseTableCell>
      <BaseTableCell
        v-for="(amount, colIndex) in row[attribute]"
        :key="`row_${index}_col_${colIndex}`"
        currency
      >
        {{ filters.currency(amount) }}
      </BaseTableCell>
    </BaseTableRow>

    <BaseTableRow footer border>
      <BaseTableCell>
        {{ $t('reports.vat_overview.total') }}
      </BaseTableCell>
      <BaseTableCell
        v-for="(total, index) in totals"
        :key="`footer_${index}`"
        currency
      >
        {{ filters.currency(total) }}
      </BaseTableCell>
    </BaseTableRow>
  </BaseScroll>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  name: 'MonthsTable',
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    title: {
      type: String,
      required: true
    },
    noVat: {
      type: String,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    totals: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  computed: {
    months() {
      return [...Array(12)].map((_, index) =>
        this.filters.monthLong(
          dayjs().startOf('year').add(index, 'months').format()
        )
      );
    }
  }
});
</script>

<style lang="scss" module>
@media print {
  .printTableHead {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    max-width: 28px;
  }
}
</style>
