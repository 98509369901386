<template>
  <BaseDropdown
    v-model="filterValue"
    :options="filterOptions"
    :label="$t('global.items.filter', 2)"
    v-test="'messages-filter'"
  />
</template>

<script lang="ts">
import filters from '@/filters';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      filters
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    filterValue: {
      get() {
        return this.modelValue || 'all';
      },
      set(value) {
        this.$emit('update:modelValue', value === 'all' ? '' : value);
      }
    },
    filterOptions() {
      if (this.hasFeatureFlag('module-marketing')) {
        return [...this.defaultFilterOptions, ...this.marketingFilterOptions];
      } else {
        return this.defaultFilterOptions;
      }
    },
    defaultFilterOptions() {
      return [
        {
          value: 'all',
          label: this.$t('global.all')
        },
        {
          value: 'email',
          label: this.$t('global.email')
        },
        {
          value: 'order_invoice',
          label: this.$t('messages.types.order_invoice')
        },
        {
          value: 'sms',
          label: this.$t('global.sms_message')
        },
        {
          value: 'failed',
          label: this.filters.capitalize(this.$t('messages.status.failed'))
        }
      ];
    },
    marketingFilterOptions() {
      return [
        {
          value: 'rebook_reminder',
          label: this.filters.capitalize(
            this.$t('messages.status.rebook_reminder')
          )
        },
        {
          value: 'marketing_campaign',
          label: this.filters.capitalize(
            this.$t('messages.status.marketing_campaign')
          )
        }
      ];
    }
  }
});
</script>
