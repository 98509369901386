<template>
  <div>
    <RejectAppointmentRequest
      v-if="showRejectionModal"
      :appointmentId="appointmentId"
      @close="showRejectionModal = false"
      @submit="handleReject"
    />

    <BaseHeading mb>
      {{ $t('appointment.confirmation.title') }}
    </BaseHeading>
    <BaseCard mb>
      <BaseText mb>{{ $t('appointment.confirmation.header') }}</BaseText>
      <BaseSpinner v-if="loading" inline />
      <div v-else>
        <BaseButton
          mr
          color="inverted"
          @click="showRejectionModal = true"
          v-test="'pending-appointment-reject'"
        >
          {{ $t('appointment.confirmation.reject') }}
        </BaseButton>
        <BaseButton
          @click="handleConfirm"
          v-test="'pending-appointment-confirm'"
        >
          {{ $t('appointment.confirmation.confirm') }}
        </BaseButton>
      </div>
    </BaseCard>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useAppointmentConfirmation } from '@/modules/calendar/actions/appointments';
import RejectAppointmentRequest from '@/modules/calendar/modals/RejectAppointmentRequest.vue';
import { ref } from 'vue';

const emit = defineEmits(['confirmed']);

const props = defineProps<{
  appointmentId: number;
}>();

const { confirm, loading } = useAppointmentConfirmation();
const router = useRouter();

const showRejectionModal = ref(false);

const handleConfirm = () => {
  confirm(props.appointmentId).then(() => {
    emit('confirmed');
  });
};

const handleReject = () => {
  router.push({
    name: 'calendar'
  });
};
</script>
