<template>
  <BaseModal
    :heading="$t('admin.treatwell.feedback_modal.heading')"
    useForm
    :parentRoute="{ name: 'treatwell-admin' }"
    @submit="submit"
  >
    <BaseText mb>
      {{ $t('admin.treatwell.feedback_modal.description') }}
    </BaseText>
    <BaseCard gray mb>
      <BaseCheckbox
        v-model="formData.customers.appointmentFeedback"
        :label="$t('customers_settings.request_feedback.heading')"
        :description="$t('customers_settings.request_feedback.description')"
        v-test="'customers-feedback'"
      />
      <div :class="$style.settingsCardInner">
        <BaseDropdown
          v-model="formData.customers.appointmentFeedbackDelay"
          :label="$t('customers_settings.request_feedback.interval.label')"
          :options="[
            {
              value: 0,
              label: $t(
                'customers_settings.request_feedback.interval.send_always'
              )
            },
            {
              value: 1,
              label: `${1} ${$t('global.items.month', 1)}`
            },
            {
              value: 2,
              label: `${2} ${$t('global.items.month', 2)}`
            },
            {
              value: 3,
              label: `${3} ${$t('global.items.month', 2)}`
            },
            {
              value: 6,
              label: `${6} ${$t('global.items.month', 2)}`
            },
            {
              value: 12,
              label: `${12} ${$t('global.items.month', 2)}`
            }
          ]"
          mt
          v-test="'customers-feedback-interval'"
        />
        <BaseText :routerLink="{ name: 'message-templates' }">
          {{ $t('customers_settings.edit_message') }}
        </BaseText>
      </div>
    </BaseCard>

    <template #footer>
      <BaseButton
        :loading="isSaving"
        submitForm
        v-test="'customers-settings-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { flash } from '@/helpers/ui';
import i18n from '@/i18n';
import { useCompanyStore } from '@/stores/company';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
const { companySettings, updateCompany } = useCompanyStore();
const router = useRouter();
const formData = reactive({
  customers: {
    appointmentFeedback: companySettings.customers.appointmentFeedback || true,
    appointmentFeedbackDelay:
      companySettings.customers.appointmentFeedbackDelay || 0
  }
});

const isSaving = ref(false);

const submit = () => {
  isSaving.value = true;

  updateCompany({
    settings: formData
  }).then(() => {
    isSaving.value = false;
    flash(i18n.t('global.flash.settings_updated'));
    router.push({
      name: 'treatwell-admin'
    });
  });
};
</script>

<style lang="scss" module>
.settingsCardInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: $spacing;
}
</style>
