import gql from 'graphql-tag';

export const GET_ADYEN_DASHBOARD_DATA = gql`
  query getAdyenDashboardData {
    adyenDashboardData {
      availableBalance
      processingBalance
      terminals {
        description
        id
        name
        status
        model
        location {
          id
          internalName
        }
      }
      hasPosTransaction
      lastTerminalOrderAt
    }
  }
`;

export const GET_ADYEN_DASHBOARD_DATA_WITHOUT_BALANCE = gql`
  query getAdyenDashboardData {
    adyenDashboardData {
      terminals {
        description
        id
        name
        status
        model
      }
      hasPosTransaction
      lastTerminalOrderAt
    }
  }
`;
