import { GET_APPOINTMENT, GET_CUSTOMER } from './graphql';
import { useQuery } from '@vue/apollo-composable';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Appointment, AppointmentPart, RegisterItem } from '@/types';
import { storeToRefs } from 'pinia';
import { setTwTransaction, setCustomer, setSelectedResource } from './shared';
import { ref } from 'vue';
import { useLocationsStore } from '@/stores/locations';
import { useServicesStore } from '@/stores/services';

export const setAppointmentData = () => {
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();

  const loadData = () =>
    new Promise<void>((resolve) => {
      const { onResult } = useQuery(GET_APPOINTMENT, {
        id: Number(route.query.appointmentId)
      });
      const { order, reset, addItem } = useRegisterOrderStore();
      const { t } = useI18n();
      const { orderAppointments, selectedEmployeeId } = storeToRefs(
        useRegisterOrderStore()
      );
      const { locationId } = useLocationsStore();

      loading.value = true;

      onResult((result) => {
        const appointment = result.data.appointment;

        const resetRegister = () => {
          reset();
          setLocation();
        };

        const setLocation = () => {
          if (appointment.locationId && locationId !== appointment.locationId) {
            router.replace({
              name: route.name as string,
              params: {
                locationId: appointment.locationId
              }
            });
          }
        };

        const setStoreData = () => {
          if (
            orderAppointments.value
              .map((orderAppointment: Appointment) => orderAppointment.id)
              .includes(appointment.id)
          ) {
            loading.value = false;
            return;
          }

          if (!selectedEmployeeId.value) {
            setSelectedResource(
              appointment.parts.map((part: AppointmentPart) => part.resourceId)
            );
          }
          setCustomer(appointment.customer);
          orderAppointments.value.push(appointment);
          appointment.parts.forEach((part: AppointmentPart) => {
            addItem({
              name: part.service.name,
              price: part.service.price,
              serviceId: part.service.id,
              employeeId: part.resourceId || undefined,
              appointmentPartId: part.id,
              appointmentId: appointment.id,
              discount:
                appointment.discountCode?.discountPercentage ||
                appointment.discountPercentage ||
                0,
              discountCodeId: appointment.discountCode?.id,
              medical: !!part.service.medical
            });
          });
          setTwTransaction();

          router.replace({ query: {} });
          loading.value = false;
          resolve();
        };

        if (order.id && !order.permissions?.editOrderItems) {
          // when checking out an appointment, if there are already items in the register from an existing order than cannot be edited, reset the register
          resetRegister();
          setStoreData();
        } else if (order.items.length) {
          modal('confirmation', {
            message: t('appointment.add_to_existing_invoice_confirmation'),
            catch: true
          })
            .catch(() => {
              resetRegister();
            })
            .finally(() => {
              setStoreData();
            });
        } else {
          setStoreData();
        }
      });
    });

  return {
    loadData,
    loading
  };
};

export const setRegisterAppointment = (
  appointment: RegisterItem & Appointment
) => {
  const { addItem } = useRegisterOrderStore();
  const { orderAppointments, selectedEmployeeId } = storeToRefs(
    useRegisterOrderStore()
  );
  const { services } = useServicesStore();

  const getCustomer = (id: number) => {
    const { onResult } = useQuery(GET_CUSTOMER, { id });

    onResult(({ data: { customer } }: any) => {
      setCustomer(customer);
    });
  };

  const existingOrderAppointment = orderAppointments.value.find(
    (orderAppointment: Appointment) => orderAppointment.id === appointment.id
  );
  if (appointment && !existingOrderAppointment) {
    if (appointment.customerId) {
      getCustomer(appointment.customerId);
    }
    if (!selectedEmployeeId.value) {
      setSelectedResource(
        appointment.parts.map((part: any) => part.resourceId)
      );
    }
    orderAppointments.value.push(appointment);

    appointment.parts.forEach((part) => {
      const service = services.find((service) => service.id === part.serviceId);

      if (service) {
        addItem({
          name: service.name,
          price: service.price,
          serviceId: service.id,
          appointmentPartId: part.id,
          appointmentId: appointment.id,
          discount: appointment.discountPercentage || 0,
          discountCodeId: appointment.discountCode?.id,
          medical: !!service.medical
        });
      }
    });

    if (appointment.paidThroughTreatwell) {
      setTwTransaction();
    }
  }
};
