<template>
  <BaseModal
    :heading="$t('messages.details')"
    :parentRoute="{
      name: 'messages',
      query: filter ? { filter } : {}
    }"
  >
    <BaseLabel v-if="message && message.status" :color="labelColor" mb>
      {{ $t(`messages.status.${message.status}`) }}
    </BaseLabel>
    <BaseText v-if="message" inline :ml="0.5">
      {{ $t(`messages.status.helpers.${message.status}`) }}
    </BaseText>
    <BaseGrid v-if="message" container>
      <BaseGrid :size="12">
        <MarketingMail
          v-if="message.messageType == 'marketing_campaign'"
          :emailTemplate="message.emailTemplate"
        />
        <div
          v-else
          :class="$style.message"
          v-html="message.message"
          v-test="'messageMessage'"
        />
        <BaseText
          v-if="
            message.gateway &&
            message.gateway === 'email' &&
            user.salonizedAdmin
          "
          mt
          size="s"
          v-test="'gatewayText'"
        >
          {{ message.gatewayMessage }} ({{ message.gatewayCode }})
        </BaseText>
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <div v-if="message && message.gateway" :class="$style.footer">
        <BaseText
          v-if="message && message.customer && message.gateway"
          link
          :mr="0.25"
          @click="goToCustomer(message.customer.id)"
        >
          {{ message.customer.fullName }}
        </BaseText>
        <BaseText
          v-if="
            message.gateway === 'email' &&
            message.customer &&
            message.customer.email
          "
          inline
        >
          - {{ message.customer.email }}
        </BaseText>
        <BaseText
          v-if="
            message.gateway === 'sms' &&
            message.customer &&
            message.customer.mobilePhone
          "
          inline
        >
          - {{ message.customer.mobilePhone }}
        </BaseText>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';
import MarketingMail from './MarketingMail.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MarketingMail
  },
  data() {
    return {
      message: null
    };
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    filter() {
      return this.$route.query?.filter;
    },
    labelColor() {
      if (!this.message || !this.message.status) {
        return;
      }

      if (
        this.message.status === 'sent' ||
        this.message.status === 'delivered'
      ) {
        return 'success';
      }

      if (
        this.message.status !== 'sent' &&
        this.message.status !== 'delivered' &&
        this.message.status !== 'pending'
      ) {
        return 'warning';
      }

      return 'default';
    }
  },
  methods: {
    goToCustomer(customerId) {
      this.$router.push({ name: 'customer', params: { customerId } });
    }
  },
  apollo: {
    message: {
      query: gql`
        query getMessage($id: Int!) {
          message(id: $id) {
            id
            message
            messageType
            gateway
            gatewayCode
            gatewayMessage
            customer {
              id
              fullName
              mobilePhone
              email
            }
            emailTemplate {
              id
              hasLogo
              hasImage
              hasButton
              hasFooter
              subject
              message
              buttonUrl
              imageUrl
              discountMessage
              hasDiscount
              buttonTitle
              color
              discountCodeId
              discountCode {
                id
                code
              }
            }
            status
          }
        }
      `,
      skip() {
        return !this.$route.params.messageId;
      },
      variables() {
        return {
          id: parseInt(this.$route.params.messageId)
        };
      },
      fetchPolicy: 'cache-and-network'
    }
  }
});
</script>

<style lang="scss" module>
.message {
  overflow-x: auto;

  *,
  *:before,
  *:after {
    box-sizing: content-box;
  }

  p {
    margin-bottom: $spacing;
  }
}

.footer {
  display: flex;
  align-items: center;
}
</style>
