<template>
  <transition>
    <div
      v-if="show && historyToShow && historyToShow.length"
      :class="$style.base"
    >
      <div :class="$style.scrollarea">
        <div
          v-for="historyItem in historyToShow"
          :key="historyItem"
          :class="$style.item"
        >
          <div
            :class="$style.label"
            @click="emit('select', historyItem)"
            v-test="'search-history'"
          >
            <BaseText iconBefore="clock">
              {{ limitLength(historyItem) }}
            </BaseText>
          </div>
          <div :class="$style.delete">
            <BaseIcon
              name="close"
              clickable
              @click="onDeleteClick(historyItem)"
              v-test="'history-delete'"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
const emit = defineEmits(['select', 'delete']);

const props = defineProps<{
  show: boolean;
  searchHistory: string[];
}>();

const historyToShow = computed(() => props.searchHistory.slice(0, 3));

const limitLength = (value: string) =>
  value.length < 18 ? value : `${value.substring(0, 15)}...`;

const onDeleteClick = (historyItem: string) => {
  emit('delete', historyItem);
};
</script>

<style lang="scss" module>
.base {
  position: absolute;
  top: calc(100% + #{$spacing * 0.5});
  left: 0;
  width: 100%;
  border-radius: $radius;
  background: $white;
  box-shadow: $shadow;
  z-index: 10;

  opacity: 1;

  &:global(.v-enter-active) {
    transition:
      transform 0.15s $easeOutBack,
      opacity 0.05s linear;
  }
  &:global(.v-enter-from) {
    transform: translateY(-20%);
    opacity: 0;
  }
}
.scrollarea {
  max-height: 180px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &:focus {
    outline: none;
  }
}

$deleteWidth: 40px;

.label {
  min-height: 40px;
  padding: ($spacing * 0.25) $spacing;
  word-break: break-all;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: $deleteWidth;

  @include hover {
    background: $color-highlight;
  }
}

.delete {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: $deleteWidth;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
