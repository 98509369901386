<template>
  <div>
    <div
      v-for="(item, index) in data"
      :key="index"
      :class="$style.listItem"
      @click="onSelect(item)"
      v-test="`filterList-${index}`"
    >
      <BaseIcon :name="item.icon" size="s" :mr="0.5" />
      {{ item.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  emits: ['select'],
  methods: {
    onSelect(item) {
      this.$emit('select', item);
    }
  }
});
</script>

<style lang="scss" module>
.listItem {
  @include floating-list-item-inner;
  @include hover {
    background-color: $color-highlight;
  }
  cursor: pointer;
  border-bottom: 1px solid $color-border;
}

.icon {
  margin-right: $spacing * 0.5;
  margin-left: $spacing * -0.25;
}
</style>
