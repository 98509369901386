<template>
  <BaseCard :mb="2" :heading="$t('customers.tags.heading')">
    <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
      <BaseText mb>
        {{ $t('customers.tags.description') }}
      </BaseText>
      <BaseText :mb="1.5" :href="$t('customers.tags.help_article_url')">
        {{ $t('global.learn_more') }}
      </BaseText>
      <div :class="$style.tags">
        <BaseCard
          v-for="(tag, index) in modelValue"
          :key="index"
          gray
          :closable="!tag.salonized"
          @close="removeTag(index)"
        >
          <BaseInput
            v-model="tag.name"
            :disabled="tag.salonized"
            :label="$t('customers.tags.tag_name')"
            :info="tag.salonized ? $t('customers.tags.undeletable') : undefined"
            v-test="'tag'"
          />
        </BaseCard>
      </div>
      <BaseText
        v-if="modelValue.length < 6"
        link
        iconBefore="plus"
        @click="addTag"
        v-test="'tag-add'"
      >
        {{ $t('customers.tags.create_tag') }}
      </BaseText>
      <BaseAlert :text="$t('customers.tags.description_warning')" :mt="1.5" />
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable';
import { GET_CUSTOMER_TAGS } from './graphql';

const mixpanel = inject<any>('mixpanel');

type Tag = {
  id: number | null;
  name: string;
  salonized?: boolean;
};

const props = defineProps<{
  modelValue: Tag[];
}>();

const emit = defineEmits(['update:modelValue']);

const { onResult } = useQuery(GET_CUSTOMER_TAGS, {
  fetchPolicy: 'cache-first'
});

onResult(({ data }) => {
  if (data.customerTags) {
    updateTags([
      ...data.customerTags.salonizedTags,
      ...data.customerTags.companyTags
    ]);
  }
});

const addTag = () => {
  mixpanel.track('customer_label_created');
  updateTags([
    ...props.modelValue,
    {
      id: null,
      name: ''
    }
  ]);
};

const removeTag = (index: number) => {
  mixpanel.track('customer_label_deleted');
  updateTags(props.modelValue.filter((tag, tagIndex) => tagIndex !== index));
};

const updateTags = (value: Tag[]) => {
  emit('update:modelValue', value);
};
</script>

<style lang="scss" module>
.tags {
  margin-bottom: $spacing;

  .base:not(.smallScreen) & {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing;

    & > * {
      width: calc(50% - #{$spacing * 0.5});
    }
  }

  .base.smallScreen & {
    & > *:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}
</style>
