<template>
  <BaseCard v-if="hasFeatureFlag('feedback-enabled')" gray mb>
    <BaseCheckbox
      v-model="formData.settings.customers.appointmentFeedback"
      :label="$t('customers_settings.request_feedback.heading')"
      :description="$t('customers_settings.request_feedback.description')"
      v-test="'customers-feedback'"
    />
    <div v-show="formData.settings.customers.appointmentFeedback">
      <BaseDropdown
        v-model="formData.settings.customers.appointmentFeedbackDelay"
        :label="$t('customers_settings.request_feedback.interval.label')"
        :options="[
          {
            value: 0,
            label: $t(
              'customers_settings.request_feedback.interval.send_always'
            )
          },
          {
            value: 1,
            label: `${1} ${$t('global.items.month', 1)}`
          },
          {
            value: 2,
            label: `${2} ${$t('global.items.month', 2)}`
          },
          {
            value: 3,
            label: `${3} ${$t('global.items.month', 2)}`
          },
          {
            value: 6,
            label: `${6} ${$t('global.items.month', 2)}`
          },
          {
            value: 12,
            label: `${12} ${$t('global.items.month', 2)}`
          }
        ]"
        mt
        v-test="'customers-feedback-interval'"
      />
    </div>
  </BaseCard>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';

defineProps<{
  formData: any;
}>();

const { hasFeatureFlag } = useUserStore();
</script>
