import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import {
  appointmentFragment,
  calendarAppointmentFragment
} from '@/graphql-fragments';
import { flash, modal } from '@/helpers/ui';
import i18n from '@/i18n';
import filters from '@/filters';
import dayjs from '@/dayjs';
import { showRecurringModalAndResolve } from './recurring-helpers';
import calendarInterface from '@/modules/calendar/calendar-interface';
import { useCompanyStore } from '@/stores/company';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import {
  createCalendarEvent,
  deleteCalendarEvent,
  updateCalendarEvents
} from './calendar-events';
import type {
  CreateAppointmentInput,
  UpdateAppointmentInput,
  ActivateAppointmentInput,
  CalendarAppointment,
  Appointment
} from '@/types';
import { isCalendarSaving } from '../calendar-state';
import { logValidationError, handledException } from '@/helpers/datadog';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import { nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';

export const createAppointment = ({
  input,
  sendConfirmationEmail
}: {
  input: CreateAppointmentInput;
  sendConfirmationEmail: boolean;
}) => {
  const { resourceType } = useCalendarFiltersStore();
  const mutationVars = {
    ...input,
    viewType: resourceType.toUpperCase()
  };

  const createAppointmentMutation = () =>
    new Promise((resolve, reject) => {
      isCalendarSaving.value = true;

      apolloClient
        .mutate({
          mutation: gql`
            mutation createAppointment($input: CreateAppointmentInput!) {
              createAppointment(input: $input) {
                appointment {
                  ...appointment
                }
                calendarAppointments {
                  ...calendarAppointment
                }
                errors
              }
            }
            ${appointmentFragment}
            ${calendarAppointmentFragment}
          `,
          variables: {
            input: mutationVars
          }
        })
        .then(
          ({
            data: {
              createAppointment: { appointment, calendarAppointments, errors }
            }
          }) => {
            isCalendarSaving.value = false;

            if (errors && Object.keys(errors).length) {
              logValidationError('createAppointment', errors, mutationVars);
              reject(errors);
            } else {
              flash(i18n.t('global.flash.appointment_created'));
              if (appointment.customer?.email && sendConfirmationEmail) {
                sendConfirmation({
                  appointmentId: appointment.id,
                  action: 'create'
                });
              }

              changeDate(appointment.startAt);

              calendarAppointments.forEach((event: CalendarAppointment) => {
                createCalendarEvent(event);
              });

              resolve(appointment);
            }
          }
        );
    });

  const payload = {
    input,
    original: true,
    action: 'create',
    type: 'appointment'
  };

  return showRecurringModalAndResolve({
    payload,
    method: createAppointmentMutation
  });
};

export const activateAppointment = ({
  input
}: {
  input: ActivateAppointmentInput;
}) => {
  const { resourceType } = useCalendarFiltersStore();
  isCalendarSaving.value = true;

  return new Promise((resolve) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation activateAppointment($input: ActivateAppointmentInput!) {
            activateAppointment(input: $input) {
              appointment {
                ...appointment
              }
              calendarAppointments {
                ...calendarAppointment
              }
            }
          }
          ${appointmentFragment}
          ${calendarAppointmentFragment}
        `,
        variables: {
          input: {
            ...input,
            viewType: resourceType.toUpperCase()
          }
        }
      })
      .then(
        ({
          data: {
            activateAppointment: { appointment, calendarAppointments }
          }
        }) => {
          isCalendarSaving.value = false;

          updateCalendarEvents(calendarAppointments);
          resolve(appointment);
        }
      );
  });
};

export const updateAppointment = ({
  input,
  previousTime,
  original,
  rrule,
  createNewRrule
}: {
  input: UpdateAppointmentInput;
  previousTime: string;
  original: boolean;
  rrule: any;
  createNewRrule: boolean;
}) => {
  const action = input.deleteEntry
    ? 'delete'
    : createNewRrule
      ? 'create'
      : 'update';
  const { resourceType } = useCalendarFiltersStore();

  const updateAppointment = (options: any) => {
    const data = options ? { ...input, ...options } : input;
    data.viewType = resourceType.toUpperCase();

    if (isCalendarSaving.value) {
      handledException(
        new Error('updateAppointment mutation triggered too often.'),
        {
          input: data
        }
      );
    }

    isCalendarSaving.value = true;

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation updateAppointment($input: UpdateAppointmentInput!) {
              updateAppointment(input: $input) {
                appointment {
                  ...appointment
                }
                calendarAppointments {
                  ...calendarAppointment
                }
                errors
              }
            }
            ${appointmentFragment}
            ${calendarAppointmentFragment}
          `,
          variables: {
            input: data
          }
        })
        .then(
          ({
            data: {
              updateAppointment: { errors, appointment, calendarAppointments }
            }
          }) => {
            isCalendarSaving.value = false;

            if (errors && Object.keys(errors).length) {
              logValidationError('updateAppointment', errors, data);
              reject(errors);
            } else {
              afterAppointmentUpdate({
                appointment,
                previousTime,
                noShow: input.state === 'no_show',
                action
              });
              // Refresh the agenda when a part is deleted
              if (
                input.partsAttributes &&
                input.partsAttributes.map((p) => p.destroy).includes(true)
              ) {
                calendarInterface.api?.refetchEvents();
              } else if (
                options?.recurrenceUpdateState === 'original' &&
                data?.deleteEntry
              ) {
                calendarAppointments.forEach((event: CalendarAppointment) => {
                  deleteCalendarEvent(event.id);
                });
              } else {
                updateCalendarEvents(calendarAppointments);
              }
              resolve(appointment);
            }
          }
        );
    });
  };

  const payload = {
    input,
    rrule,
    action,
    original,
    type: 'appointment'
  };

  return showRecurringModalAndResolve({ payload, method: updateAppointment });
};

export const deleteAppointment = (payload: any) =>
  new Promise<void>((resolve) => {
    isCalendarSaving.value = true;

    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteAppointment($input: DeleteAppointmentInput!) {
            deleteAppointment(input: $input) {
              appointment {
                id
                customer {
                  id
                  firstName
                  email
                }
                treatwell
              }
              calendarAppointments {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            id: payload.id,
            requestRefund: payload.refund
          }
        }
      })
      .then(
        ({
          data: {
            deleteAppointment: { appointment, calendarAppointments }
          }
        }) => {
          isCalendarSaving.value = false;

          flash(i18n.t('global.flash.appointment_deleted'));

          calendarAppointments.forEach((event: CalendarAppointment) => {
            deleteCalendarEvent(event.id);
          });

          if (appointment.customer?.email && !appointment.treatwell) {
            modal('confirmation', {
              message: i18n.t(
                'appointment.edit_appointment_confirmation_email',
                { name: appointment.customer.firstName }
              ),
              catch: true
            })
              .then(() => {
                sendConfirmation({
                  appointmentId: appointment.id,
                  action: 'cancel'
                });

                resolve();
              })
              .catch(() => {
                resolve();
              });
          } else {
            resolve();
          }
        }
      );
  });

export const resizeAppointment = ({
  input,
  previousTime,
  original,
  rrule
}: any) => {
  const { resourceType } = useCalendarFiltersStore();
  const action = 'update';
  isCalendarSaving.value = true;

  const resizeAppointment = (options: any) => {
    const data = options ? { ...input, ...options } : input;
    data.viewType = resourceType.toUpperCase();

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation resizeAppointment($input: ResizeAppointmentInput!) {
              resizeAppointment(input: $input) {
                appointment {
                  ...appointment
                }
                calendarAppointments {
                  ...calendarAppointment
                }
                errors
              }
            }
            ${appointmentFragment}
            ${calendarAppointmentFragment}
          `,
          variables: {
            input: data
          }
        })
        .then(
          ({
            data: {
              resizeAppointment: { errors, appointment, calendarAppointments }
            }
          }) => {
            isCalendarSaving.value = false;

            if (
              errors &&
              errors.constructor === Object &&
              Object.keys(errors).length !== 0
            ) {
              // TODO: Refactor when API is updated
              logValidationError('resizeAppointment', errors, data);
              reject(errors);
              return;
            }
            afterAppointmentUpdate({ appointment, previousTime, action });
            updateCalendarEvents(calendarAppointments);

            resolve(appointment);
          }
        );
    });
  };

  const payload = {
    input,
    rrule,
    action,
    original,
    type: 'appointment'
  };

  return showRecurringModalAndResolve({ payload, method: resizeAppointment });
};

export const rescheduleAppointment = ({
  input,
  previousTime,
  original,
  rrule
}: any) => {
  const { resourceType } = useCalendarFiltersStore();
  const action = 'update';
  isCalendarSaving.value = true;

  const rescheduleAppointment = (options: any) => {
    const data = options ? { ...input, ...options } : input;
    data.viewType = resourceType.toUpperCase();

    return new Promise((resolve) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation rescheduleAppointment(
              $input: RescheduleAppointmentInput!
            ) {
              rescheduleAppointment(input: $input) {
                appointment {
                  ...appointment
                }
                calendarAppointments {
                  ...calendarAppointment
                }
                errors
              }
            }
            ${appointmentFragment}
            ${calendarAppointmentFragment}
          `,
          variables: {
            input: data
          }
        })
        .then((response) => {
          isCalendarSaving.value = false;

          const appointment = response.data.rescheduleAppointment.appointment;
          const events =
            response.data.rescheduleAppointment.calendarAppointments;

          if (!previousTime) {
            const { appointmentAttributes } = usePreCreateStore();
            previousTime = appointmentAttributes.startAt;
          }

          afterAppointmentUpdate({
            appointment,
            previousTime,
            disableEmailConfirmation: dayjs(appointment.startAt).isSame(
              previousTime
            ),
            action
          });

          updateCalendarEvents(events);

          if (response.errors) {
            logValidationError('rescheduleAppointment', response.errors, data);
          }

          resolve(appointment);
        });
    });
  };

  const payload = {
    input,
    rrule,
    action,
    original,
    type: 'appointment'
  };

  return showRecurringModalAndResolve({
    payload,
    method: rescheduleAppointment
  });
};

const afterAppointmentUpdate = ({
  appointment,
  previousTime,
  noShow,
  disableEmailConfirmation,
  action
}: {
  appointment: Appointment;
  previousTime?: string;
  noShow?: boolean;
  disableEmailConfirmation?: boolean;
  action: string;
}) => {
  const { companySettings } = useCompanyStore();

  flash(i18n.t(`global.flash.appointment_${action}d`));

  const preCreateStore = usePreCreateStore();
  const { appointmentId } = usePreCreateStore();

  if (appointmentId) {
    preCreateStore.$reset();
  }

  isCalendarSaving.value = false;

  if (
    appointment.customer?.email &&
    !appointment.treatwell &&
    (appointmentId ||
      noShow ||
      (previousTime && !dayjs(previousTime).isSame(dayjs(appointment.startAt))))
  ) {
    let message;
    const name = appointment.customer.firstName;

    if (noShow) {
      if (!companySettings.communication.appointmentNoShow) {
        return;
      }

      message = i18n.t('appointment.no_show.confirm_email', { name });
    } else {
      const date = filters.date(appointment.startAt, { format: 'long' });
      const time = filters.time(appointment.startAt);
      message = i18n.t('appointment.move_appointment_confirmation_email', {
        name,
        date,
        time
      });
    }

    if (!disableEmailConfirmation) {
      modal('confirmation', {
        message
      }).then(() => {
        sendConfirmation({
          appointmentId: appointment.id,
          action: noShow ? 'noshow' : 'update',
          previousTime
        });
      });
    }
  }
};

export const duplicateAppointment = ({ appointment, newResourceId }: any) => {
  const { resourceType } = useCalendarFiltersStore();
  isCalendarSaving.value = true;

  const variables: any = {
    id: appointment.appointmentId,
    startAt: appointment.startAt
  };
  if (newResourceId) {
    variables.resourceId = newResourceId;
  }
  return new Promise<void>((resolve) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation duplicateAppointment($input: DuplicateAppointmentInput!) {
            duplicateAppointment(input: $input) {
              appointment {
                ...appointment
              }
              calendarAppointments {
                ...calendarAppointment
              }
              errors
            }
          }
          ${appointmentFragment}
          ${calendarAppointmentFragment}
        `,
        variables: {
          input: {
            ...variables,
            viewType: resourceType.toUpperCase()
          }
        }
      })
      .then(
        ({
          data: {
            duplicateAppointment: { appointment, calendarAppointments, errors }
          }
        }) => {
          isCalendarSaving.value = false;

          if (errors?.['parts.allocations.requirement']) {
            modal('warning', {
              message: i18n.t('appointment.copy_appointment_warning')
            });
          } else {
            flash(i18n.t('global.flash.appointment_copied'));
            calendarAppointments.forEach((event: CalendarAppointment) => {
              createCalendarEvent(event);
            });

            if (appointment.customer?.email) {
              modal('confirmation', {
                message: i18n.t(
                  'appointment.copy_appointment_confirmation_email',
                  {
                    date: filters.date(appointment.startAt, { format: 'long' }),
                    time: filters.time(appointment.startAt),
                    name: appointment.customer.firstName
                  }
                )
              }).then(() => {
                sendConfirmation({
                  appointmentId: appointment.id,
                  action: 'create'
                });
              });
            }

            const preCreateStore = usePreCreateStore();
            preCreateStore.$reset();

            if (errors) {
              logValidationError('duplicateAppointment', errors);
            }

            resolve();
          }
        }
      );
  });
};

const changeDate = (date: string) => {
  const store = useCalendarFiltersStore();
  store.date = dayjs(date).tz().format('YYYY-MM-DD');

  nextTick(() => {
    const { previewModeActive } = useCalendarPreviewStore();
    if (!previewModeActive) {
      calendarInterface.api?.scrollToTime(
        dayjs(date).subtract(1, 'hour').format('HH:mm')
      );
    }
  });
};

const sendConfirmation = ({
  appointmentId,
  action,
  previousTime
}: {
  appointmentId: Appointment['id'];
  action: string;
  previousTime?: string;
}) => {
  const input: any = {
    appointmentId
  };

  const mutationMapping: any = {
    update: {
      mutationName: 'sendAppointmentMovedEmail',
      inputType: 'SendAppointmentMovedEmailInput'
    },
    create: {
      mutationName: 'sendAppointmentConfirmationEmail',
      inputType: 'SendAppointmentConfirmationEmailInput'
    },
    noshow: {
      mutationName: 'sendAppointmentNoShowEmail',
      inputType: 'SendAppointmentNoShowEmailInput'
    },
    cancel: {
      mutationName: 'sendAppointmentCancellationEmail',
      inputType: 'SendAppointmentCancellationEmailInput'
    }
  };

  if (action === 'update') {
    input.previousTime = previousTime;
  }

  const mutation = mutationMapping[action];
  if (!mutation) {
    return;
  }

  apolloClient
    .mutate({
      mutation: gql`
      mutation ${mutation.mutationName}($input: ${mutation.inputType}!) {
        ${mutation.mutationName}(input: $input) {
          status
        }
      }
    `,
      variables: {
        input
      }
    })
    .then(() => {
      if (action !== 'create') {
        flash(i18n.t('global.flash.email_sent'));
      }
    });
};

export const useAppointmentConfirmation = () => {
  const { t } = useI18n();

  const { mutate: confirmAppointment, loading } = useMutation(gql`
    mutation confirmAppointment($input: ConfirmAppointmentInput!) {
      confirmAppointment(input: $input) {
        appointment {
          id
        }
        calendarAppointments {
          ...calendarAppointment
        }
      }
    }
    ${calendarAppointmentFragment}
  `);

  const confirm = (appointmentId: number) =>
    new Promise<void>((resolve) => {
      modal('confirmation', {
        message: t('appointment.confirmation.confirm_message')
      }).then(() => {
        confirmAppointment({
          input: {
            appointmentId
          }
        }).then(
          ({
            data: {
              confirmAppointment: { calendarAppointments }
            }
          }) => {
            if (calendarAppointments?.length) {
              updateCalendarEvents(calendarAppointments);
            }

            flash(t('global.flash.appointment_confirmed'));
            resolve();
          }
        );
      });
    });

  return {
    confirm,
    loading
  };
};
