<template>
  <BaseModal
    useForm
    :heading="$t('prepaid_card.use_prepaid_card')"
    small
    @submit="savePrepaidCard"
    @close="$emit('close')"
    v-test="'register-prepaid-card-modal'"
  >
    <BaseText :mb="1.5">
      {{ $t('prepaid_card.description') }}
    </BaseText>
    <BaseDropdown
      v-model="selectedCard"
      :label="
        $t('global.actions.select_item', {
          item: $t('global.items.prepaid_card', 1)
        })
      "
      :options="prepaidCards"
      v-test="'register-prepaid-card-select'"
    />
    <BaseDropdown
      v-model="selectedService"
      mt
      :label="
        $t('global.actions.select_item', {
          item: $t('global.items.service', 1)
        })
      "
      :options="services"
      v-test="'register-service-select'"
    />
    <BaseText
      v-if="insufficientCard"
      mt
      color="error"
      v-test="'prepaid-card-error'"
    >
      {{ $t('prepaid_card.insufficient') }}
    </BaseText>
    <template #footer>
      <BaseButton color="inverted" @click="$emit('close')">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :disabled="!selectedCard || !selectedService || insufficientCard"
        submitForm
        v-test="'select-prepaid-card-submit'"
      >
        {{ $t('global.actions.submit') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script lang="ts" setup>
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { computed, ref } from 'vue';
import { PrepaidCardStatus } from '@/types';
import type { PrepaidCard } from '@/types';
import type { OrderItem } from '@/modules/register/stores/order';

const props = defineProps<{ prepaidCards: PrepaidCard[] }>();

const emit = defineEmits(['close', 'submit']);

const { order } = useRegisterOrderStore();

const selectedCard = ref();
const selectedService = ref();

const prepaidCards = computed(() =>
  props.prepaidCards
    .filter(
      (card: PrepaidCard) =>
        card.remaining > 0 && card.status !== PrepaidCardStatus.Credited
    )
    .map((card: PrepaidCard) => ({
      value: card.id,
      label: `${card.description} (${card.remaining})`
    }))
);

const services = computed(() =>
  order.items
    .filter((item: OrderItem) => item.serviceId && !item.usedPrepaidCardId)
    .map((item: OrderItem) => ({
      label: item.name,
      value: item._uid
    }))
);

const selectedCardData = computed(() =>
  props.prepaidCards.find((card: PrepaidCard) => card.id === selectedCard.value)
);

const selectedServiceData = computed(() =>
  order.items.find((item: OrderItem) => item._uid === selectedService.value)
);

const insufficientCard = computed(
  () =>
    selectedServiceData.value &&
    selectedCardData.value &&
    (selectedServiceData.value.quantity || 0) >
      (selectedCardData.value.remaining || 0)
);

const savePrepaidCard = () => {
  if (selectedServiceData.value && selectedCardData.value) {
    selectedServiceData.value.price = selectedCardData.value?.pricePerUse;
    selectedServiceData.value.usedPrepaidCardId = Number(
      selectedCardData.value?.id
    );
    selectedCardData.value.remaining -= selectedServiceData.value.quantity || 1;

    emit('submit', {
      type: 'prepaid_card',
      amount:
        selectedCardData.value?.pricePerUse *
        selectedServiceData.value.quantity,
      id: selectedCardData.value?.id,
      name: selectedCardData.value?.description,
      card: selectedCardData.value
    });
  }
};
</script>
