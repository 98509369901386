<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-register')"
    :text="{
      title: $t('upgrade_subscription_page.register.prepaid_cards.title'),
      description: $t(
        'upgrade_subscription_page.register.prepaid_cards.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    :moreLink="{
      text: $t('upgrade_subscription_page.register.prepaid_cards.learn_more'),
      url: $t('upgrade_subscription_page.register.prepaid_cards.learn_more_url')
    }"
    imageName="prepaid-cards"
  />

  <div v-else>
    <router-view />
    <PageHeader>
      <template #left>
        <BaseDropdown
          v-model="variables.filterScope"
          :label="$t('global.state')"
          :options="stateOptions"
          v-test="'prepaid-cards-state'"
        />
        <BaseSearch
          v-model="variables.search"
          debounce
          v-test="'prepaid-cards-search'"
        />
      </template>
      <template #right>
        <BaseDropdown
          :options="[
            {
              label: '.csv',
              value: 'csv'
            },
            {
              label: '.xls',
              value: 'xls'
            }
          ]"
          :placeholder="$t('global.actions.export')"
          @update:modelValue="exportData"
          v-test="'prepaidcards-export'"
        />
      </template>
    </PageHeader>
    <BaseTable
      v-if="prepaidCards && prepaidCards.length"
      :headers="[
        {
          value: $t('global.date'),
          sort: 'created_at'
        },
        $t('global.items.customer', 1),
        $t('global.description'),
        {
          value: $t('global.quantity'),
          sort: 'quantity'
        },
        $t('global.used'),
        $t('global.remaining'),
        {
          value: $t('global.price'),
          alignRight: true,
          sort: 'price'
        },
        $t('global.status')
      ]"
      :rows="
        prepaidCards.map((p) => ({
          id: p.id,
          routerLink: { name: 'prepaid-card', params: { id: p.id } },
          cells: [
            filters.date(p.createdAt),
            p.customer?.fullName,
            p.description,
            p.quantity,
            p.used,
            p.remaining,
            {
              value: filters.currency(p.price),
              alignRight: true
            },
            {
              label: p.status.toLowerCase()
            }
          ]
        }))
      "
      :sortOrder="{
        sortBy: variables.sortBy,
        direction: variables.direction
      }"
      @sort="
        ($event) => {
          variables.sortBy = $event.sortBy;
          variables.direction = $event.direction;
        }
      "
      v-test="'register-prepaid-cards'"
    />
    <EmptyPageContent
      v-show="!loading && !prepaidCards.length"
      imageName="prepaid-cards"
      :text="
        showEmptyPageContent
          ? {
              title: $t('prepaid_cards.empty_state.title'),
              description: $t('prepaid_cards.empty_state.description'),
              btnPrimary: filters.capitalize(
                $t('global.actions.create_item', {
                  item: $t('global.items.prepaid_card', 1)
                })
              )
            }
          : null
      "
      :moreLink="
        showEmptyPageContent
          ? {
              text: $t('prepaid_cards.empty_state.more_text'),
              url: $t('prepaid_cards.empty_state.more_url')
            }
          : null
      "
      @click="createPrepaidCard"
    />
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import gql from 'graphql-tag';
import PageHeader from '@/modules/PageHeader.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import type { Component } from 'vue';
import { defineComponent } from 'vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';
import config from '@/config';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

interface State {
  value: string | null;
  label: string;
}

export default defineComponent({
  name: 'PrepaidCards',
  components: {
    PageHeader: PageHeader as Component,
    EmptyPageContent: EmptyPageContent as Component,
    LoadMore: LoadMore as Component,
    EmptySubscriptionPage
  },
  setup() {
    const { hasFeatureFlag } = useUserStore();
    const { prepaidCards, loading, variables, allDataFetched, fetchMore } =
      usePagination({
        fieldName: 'prepaidCards',
        query: gql`
          query getPrepaidCards(
            $search: String
            $pagination: PaginationAttributes
            $filterScope: PrepaidCardFilterScope
            $direction: SortDirectionEnum
            $sortBy: String
          ) {
            prepaidCards(
              search: $search
              pagination: $pagination
              filterScope: $filterScope
              direction: $direction
              sortBy: $sortBy
            ) {
              id
              createdAt
              customer {
                fullName
              }
              description
              quantity
              used
              remaining
              price
              status
            }
          }
        `,
        variables: {
          filterScope: null,
          search: '',
          direction: 'DESC',
          sortBy: 'created_at'
        }
      });
    return {
      prepaidCards,
      loading,
      variables,
      fetchMore,
      allDataFetched,
      filters,
      hasFeatureFlag
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    showEmptyPageContent(): boolean {
      return (
        !this.variables.filterScope &&
        !this.variables.search &&
        !this.prepaidCards.length
      );
    },
    stateOptions(): State[] {
      return [
        {
          value: null,
          label: this.$t('global.all')
        },
        {
          value: 'USABLE',
          label: this.$t('global.usable')
        },
        {
          value: 'EMPTY',
          label: this.$t('global.empty')
        }
      ];
    }
  },
  methods: {
    exportData(type: string) {
      window.open(
        `${config.backendUrl}/api/prepaid_cards/download.${type}`,
        '_blank'
      );
    },
    createPrepaidCard(): void {
      this.$router.push({ name: 'register-builder-prepaidcard' });
    }
  }
});
</script>
