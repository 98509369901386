<template>
  <div
    :class="[$style.base, { [$style.sensitive]: sensitive }]"
    @click="toggle"
  >
    <div v-if="sensitive && hide" :class="$style.align">
      <BaseIcon
        name="invisible"
        color="primary"
        size="l"
        v-test="'statsSensitiveContent'"
      />
    </div>
    <BaseHeading v-else size="xl">
      <div v-if="currency">{{ filters.currency(value) }}</div>
      <div v-else>{{ value }}</div>
    </BaseHeading>
    <BaseHeading :mt="0.5" size="s" :mb="0.5" noWrap>
      {{ text }}
    </BaseHeading>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    sensitive: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      hide: true
    };
  },
  methods: {
    toggle() {
      this.hide = !this.hide;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.align {
  margin-bottom: 2px;
}

.sensitive {
  cursor: pointer;
}
</style>
