<template>
  <div
    :class="[
      $style.base,
      { [$style.isSmall]: $screen === 's' || $screen === 'm' }
    ]"
  >
    <BaseHeading :mb="1.5">
      {{ $t('products.select_the_products', { supplier: supplier.name }) }}
    </BaseHeading>
    <BaseGrid container :mb="0.5">
      <BaseGrid :size="12">
        <BaseSwitch
          v-model="variables.filter"
          :label="$t('global.actions.filter')"
          :options="options"
          :vertical="$screen === 's'"
          v-test="'products-filter'"
        />
      </BaseGrid>
      <BaseGrid v-if="productCategories && productCategories.length" :size="6">
        <BaseDropdown
          v-model="variables.categoryIds"
          :label="$t('global.category')"
          :options="
            productCategories.map((c) => ({
              value: c.id,
              label: c.name
            }))
          "
          searchable
          v-test="'select-category'"
        />
      </BaseGrid>
      <BaseGrid :size="6" alignBottom>
        <BaseSearch
          v-model="variables.search"
          debounce
          v-test="'product-list-search'"
        />
      </BaseGrid>
    </BaseGrid>
    <div :class="$style.table">
      <div :class="$style.inner">
        <BaseTableRow head>
          <BaseTableCell :width="40">{{ $t('global.name') }}</BaseTableCell>
          <BaseTableCell :width="20" right>{{
            $t('global.stock')
          }}</BaseTableCell>
          <BaseTableCell right>{{ $t('products.min') }}</BaseTableCell>
          <BaseTableCell right>{{ $t('products.max') }}</BaseTableCell>
          <BaseTableCell right />
        </BaseTableRow>
        <BaseTableRow
          v-for="(item, index) in productsWithState"
          :key="index"
          zebra
          :disabled="$screen === 's' && item.selected"
          :link="!item.selected"
          @click="!item.selected ? addProduct(item) : null"
          v-test="'onAddProduct'"
        >
          <BaseTableCell :width="40" wordBreak>
            {{ item.name }}
          </BaseTableCell>
          <BaseTableCell
            v-if="item.outOfStock"
            right
            :width="20"
            v-test="'isOutOfStock'"
          >
            <BaseLabel color="error">
              {{ item.stockAmount }}
            </BaseLabel>
          </BaseTableCell>
          <BaseTableCell v-else right :width="20">
            {{ item.stockAmount }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ item.stockMinimum }}
          </BaseTableCell>
          <BaseTableCell right>
            {{ item.stockMaximum }}
          </BaseTableCell>
          <BaseTableCell right>
            <BaseIcon
              :name="item.selected ? 'check' : 'plus'"
              :color="!item.selected ? 'primary' : 'default'"
              :green="item.selected"
            />
          </BaseTableCell>
        </BaseTableRow>
        <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { GET_PRODUCTS, GET_PRODUCT_CATEGORIES } from './graphql';
import { usePagination } from '@/apollo/pagination';
import { useLocationsStore } from '@/stores/locations';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import type { ProductWithState } from './types';
import LoadMore from '@/components/LoadMore.vue';

const { locationId } = useLocationsStore();
const { t } = useI18n();

const props = defineProps({
  supplier: {
    type: Object,
    required: true
  },
  selectedProducts: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['addSelectedProduct']);

const { products, variables, allDataFetched, fetchMore } = usePagination({
  fieldName: 'products',
  query: GET_PRODUCTS,
  variables: {
    locationId,
    search: '',
    categoryIds: [],
    supplierIds: [props.supplier.id],
    filter: 'stock_enabled'
  }
});

const { result } = useQuery(GET_PRODUCT_CATEGORIES, {
  filter: 'HAVING_PRODUCTS',
  supplierIds: [props.supplier.id]
});

const productCategories = computed(() => result.value?.productCategories);

const productsWithState = computed(() =>
  products.value.map((product: ProductWithState): any => {
    const changed = { ...product };
    props.selectedProducts.forEach((selected: any) => {
      if (selected.productId === product.id) {
        changed.selected = true;
      }
    });
    return changed;
  })
);

const options = [
  {
    label: t('products.all_products'),
    value: 'stock_enabled'
  },
  {
    label: t('products.stock_warnings'),
    value: 'out_of_stock'
  }
];

const addProduct = (product: ProductWithState) => {
  emit('addSelectedProduct', {
    name: product.name,
    productId: product.id,
    costPrice: product.costPrice,
    quantity: 1
  });
};
</script>

<style lang="scss" module>
.base {
  background: $white;
  border-right: 1px solid $color-border;
  padding: $spacing;
  border-bottom-left-radius: $modal-radius;
  height: 100%;
  width: 50%;
  overflow-y: auto;

  &.isSmall {
    width: 100%;
    // height of MobileControls.vue
    height: calc(100% - 74px);
    position: absolute;
    z-index: 20;
  }
}

.table {
  overflow-x: auto;
}

.inner {
  min-width: 400px;
}
</style>
