<template>
  <FormElement
    :modelValue="modelValue"
    :required="required"
    @error="hasError = $event"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.hasError]: hasError,
          [$style.isChecked]: !!modelValue,
          [$style.hasDescription]: !!description,
          [$style.isDisabled]: !!disabled
        }
      ]"
      v-test="'_base-checkbox'"
    >
      <div :class="$style.checkbox" v-test="'_base-checkbox-content'">
        <div
          :class="$style.customCheckbox"
          :data-checked="modelValue"
          @click.stop.prevent="toggleCheckbox"
          v-test="'_checkbox'"
        >
          <BaseIcon name="check" color="inverted" size="s" />
        </div>
        <div
          v-show="label"
          :class="$style.label"
          @click.stop.prevent="toggleCheckbox"
          v-test="'_checkbox-label'"
        >
          {{ label }}
        </div>
        <BaseIcon
          v-if="info"
          name="info"
          size="s"
          :tooltip="{
            text: info,
            touch: true
          }"
          :ml="0.25"
        />
      </div>
      <div v-if="description" :class="$style.description">
        {{ description }}
      </div>
    </div>
  </FormElement>
</template>

<script lang="ts">
import FormElement from '@/components/_shared/form-element/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FormElement
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    info: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      hasError: false
    };
  },
  methods: {
    toggleCheckbox() {
      this.$emit('update:modelValue', !this.modelValue);
    }
  }
});
</script>

<style lang="scss" module>
.input {
  display: none;
}

.checkbox {
  display: flex;

  .base.isDisabled & {
    opacity: 0.5;
    pointer-events: none;
  }
}

.customCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: 1px solid $color-border-input;
  height: $spacing;
  width: $spacing;
  border-radius: 5px;
  cursor: default;

  .base.isChecked & {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .base.hasError & {
    border-color: $color-error;
  }
}

.label {
  padding-left: 5px;
  cursor: default;

  .base.hasDescription & {
    font-weight: bold;
  }
}

.description {
  margin-top: $spacing * 0.5;
  font-size: 12px;
}
</style>
