<template>
  <BaseModal
    :heading="$t(`global.actions.edit_${isChore ? 'chore' : 'absence'}`)"
    parentRoute="calendar"
  >
    <AbsenceForm />
  </BaseModal>
</template>

<script lang="ts">
import AbsenceForm from '../forms/AbsenceForm.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AbsenceForm
  },
  computed: {
    isChore() {
      return this.$route.name === 'chore';
    }
  }
});
</script>
