<template>
  <div>
    <BaseTableRow :class="$style.customRow">
      <BaseTableCell>
        {{
          $t('reports.totals.total_revenue_base', {
            percentage: vatRate.percentage
          })
        }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(vatRate.revenue) }}
      </BaseTableCell>
    </BaseTableRow>
    <BaseTableRow :class="$style.customRow">
      <BaseTableCell>
        {{
          $t('reports.totals.total_revenue_vat', {
            percentage: vatRate.percentage
          })
        }}
      </BaseTableCell>
      <BaseTableCell currency>
        {{ filters.currency(vatRate.vat) }}
      </BaseTableCell>
    </BaseTableRow>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    vatRate: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
<style lang="scss" module>
@include printed-table;
</style>
