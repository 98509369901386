<template>
  <div>
    <EmptyResults
      v-if="items.length < 1 && appointmentItems.length < 1 && !loading"
    />
    <div>
      <Appointment
        v-for="(item, index) in appointmentItems"
        :key="index"
        :item="item"
        @click="select(item)"
      />
      <BrowserItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :showFavorite="item.type === 'product' || item.type === 'service'"
        @refetch="refetch"
        @click="select(item)"
      />
    </div>
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script setup lang="ts">
import Appointment from './appointments/Appointment.vue';
import BrowserItem from '../BrowserItem.vue';
import { usePagination } from '@/apollo/pagination';
import { REGISTER_SEARCH } from '../graphql';
import LoadMore from '@/components/LoadMore.vue';
import { computed, watch } from 'vue';
import EmptyResults from '../EmptyResults.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { setRegisterAppointment } from '../../actions/appointment';

defineEmits(['close']);

const { addItem } = useRegisterOrderStore();

const props = defineProps<{
  searchQuery: string;
  category?: string | null;
}>();

const {
  registerSearch,
  allDataFetched,
  variables,
  fetchMore,
  loading,
  refetch
} = usePagination({
  fieldName: 'registerSearch',
  query: REGISTER_SEARCH,
  variables: {
    search: props.searchQuery,
    type:
      props.category?.toUpperCase() === 'BARCODE'
        ? 'PRODUCTS'
        : props.category
          ? props.category.toUpperCase()
          : 'ALL'
  }
});

watch(
  () => props.searchQuery,
  (searchQuery: any) => {
    if (searchQuery) {
      variables.search = searchQuery;
    }
  }
);

const items = computed(() =>
  registerSearch.value
    .filter((item: any) => item.type !== 'appointment')
    .map((item: any) => ({
      label: item.name,
      id: item.id,
      type: item.type,
      price: item.price,
      customerName: item.customerName,
      date: item.startAt,
      favorite: item.favorite,
      ...item
    }))
);

const appointmentItems = computed(() =>
  registerSearch.value
    .filter((item: any) => item.type === 'appointment')
    .map((item: any) => ({
      ...item,
      label: item.name,
      date: item.startAt
    }))
);

const select = (item: any) => {
  const searchItem = registerSearch.value.find(
    (searchItem: any) =>
      searchItem.type === item.type && searchItem.id === item.id
  );
  if (searchItem) {
    if (item.type === 'appointment') {
      setRegisterAppointment(item);
    } else {
      addItem({
        ...item,
        id: undefined
      });
    }
  }
};
</script>
