import { defineStore } from 'pinia';
import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { useLocationsStore } from '@/stores/locations';

interface State {
  currentCashupId: string | null;
  previousCashFloatAmount: number;
  isLoaded: boolean;
}

export const useRegisterDrawerStore = defineStore('register/drawer', {
  state: (): State => ({
    currentCashupId: null,
    previousCashFloatAmount: 0,
    isLoaded: false
  }),
  getters: {
    isOpen: (state) => !!state.currentCashupId
  },
  actions: {
    getDrawerStatus() {
      const { locationId } = useLocationsStore();

      apolloClient
        .query({
          query: gql`
            query getLocation($id: Int!) {
              location(id: $id) {
                openCashup {
                  id
                }
                previousCashFloatAmount
              }
            }
          `,
          variables: {
            id: locationId
          }
        })
        .then(({ data: { location } }) => {
          this.currentCashupId = location.openCashup?.id || null;
          this.previousCashFloatAmount = location.previousCashFloatAmount || 0;
          this.isLoaded = true;
        });
    },
    openCashup(cashFloatAmount: number) {
      const { locationId } = useLocationsStore();

      return new Promise<void>((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation openCashup($input: OpenCashupInput!) {
                openCashup(input: $input) {
                  cashup {
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                cashFloatAmount,
                locationId
              }
            }
          })
          .then(
            ({
              data: {
                openCashup: { cashup }
              }
            }) => {
              this.currentCashupId = cashup.id;
              resolve();
            }
          );
      });
    },
    closeCashup(formData: any) {
      const { locationId } = useLocationsStore();

      return new Promise<void>((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation closeCashup($input: CloseCashupInput!) {
                closeCashup(input: $input) {
                  cashup {
                    cashFloatAmount
                    id
                  }
                }
              }
            `,
            variables: {
              input: {
                ...formData,
                locationId
              }
            }
          })
          .then(
            ({
              data: {
                closeCashup: { cashup }
              }
            }) => {
              this.currentCashupId = null;
              this.previousCashFloatAmount = cashup.cashFloatAmount || 0;
              resolve();
            }
          );
      });
    },
    isClosedCashup(id: number) {
      return (
        (this.currentCashupId && Number(this.currentCashupId) !== id) ||
        !this.isOpen
      );
    }
  }
});
