<template>
  <BaseModalLarge
    :heading="$t('feedback.publication.publish_to_treatwell')"
    noPadding
    :parentRoute="{
      name: parentRouteName
    }"
  >
    <PublishBatchConfirmation
      v-if="showPublishBatchConfirmation"
      @close="showPublishBatchConfirmation = false"
      @submit="publishFeedbackBatch"
    />
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.inner">
        <div :class="$style.listWrapper">
          <div :class="$style.header">
            <BaseText>
              {{ $t('feedback.publication.explanation') }}
            </BaseText>
          </div>
          <PublishBatchListActions
            v-model:fromDateFilter="fromDateFilter"
            v-model:orderFilter="orderFilter"
          />
          <BaseText v-if="!$apollo.loading && $screen !== 's'" ml>
            {{
              $t('feedback.publication.stats.x_reviews_found', {
                value: feedbackAggregate.total
              })
            }}
          </BaseText>
          <div
            v-if="$screen !== 's'"
            :class="$style.scrollContainer"
            v-test="'feedback-batch-list'"
          >
            <ListContainer
              :items="feedbacks || []"
              :disableClick="true"
              @onSelect="() => {}"
            />
            <LoadMore
              v-show="!allDataFetched && feedbacks.length > 0"
              @shown="fetchMore"
            />
          </div>
        </div>
        <div :class="$style.summary">
          <BaseSpinner v-if="$apollo.loading" />
          <div v-else>
            <BaseCard fullWidth mb>
              <BaseHeading mb>
                {{
                  $t('feedback.publication.stats.x_reviews_to_be_published', {
                    value: feedbackAggregate.total
                  })
                }}
              </BaseHeading>
              <div :class="$style.stats">
                <div :class="$style.statsColumn">
                  <BaseHeading size="s" inline mr :mb="0.5">
                    {{ $t('feedback.publication.stats.salonized') }}
                  </BaseHeading>
                  <BaseHeading size="s" inline ml :mb="0.5">
                    {{ $t('feedback.publication.stats.treatwell') }}
                  </BaseHeading>
                  <ReviewMapping
                    :score="5"
                    :count="feedbackAggregate.starsCount5"
                  />
                  <ReviewMapping
                    :score="4"
                    :count="feedbackAggregate.starsCount4"
                  />
                  <ReviewMapping
                    :score="3"
                    :count="feedbackAggregate.starsCount3"
                  />
                  <ReviewMapping
                    :score="2"
                    :count="feedbackAggregate.starsCount2"
                  />
                  <ReviewMapping
                    :score="1"
                    :count="feedbackAggregate.starsCount1"
                  />
                </div>
                <div :class="$style.statsColumn">
                  <BaseHeading size="s" mb>
                    {{ $t('feedback.publication.stats.average_score') }}
                  </BaseHeading>
                  <BaseCard gray>
                    <BaseHeading size="xl">
                      {{ feedbackAggregate.batchAvg }}
                    </BaseHeading>
                    <div
                      :class="$style.average"
                      :style="{
                        clipPath: getStarClip(feedbackAggregate.batchAvg)
                      }"
                    >
                      <BaseIcon name="star-filled" />
                      <BaseIcon name="star-filled" />
                      <BaseIcon name="star-filled" />
                      <BaseIcon name="star-filled" />
                      <BaseIcon name="star-filled" />
                    </div>
                  </BaseCard>
                </div>
              </div>
            </BaseCard>
            <div :class="$style.actionButtons">
              <BaseButton
                :disabled="feedbackAggregate.total <= 0"
                mt
                :loading="saving"
                @click="openPublishModal"
                v-test="'publish-batch-open-modal'"
              >
                {{ $t('feedback.publication.publish') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import ListContainer from '../list/ListContainer.vue';
import PublishBatchListActions from './PublishBatchListActions.vue';
import ReviewMapping from './ReviewMapping.vue';
import PublishBatchConfirmation from './modals/PublishBatchConfirmation.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState, mapActions } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import dayjs from '@/dayjs';
import { defineComponent, ref, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { SortDirectionEnum } from '@/types';
import {
  GET_FEEDBACKS,
  GET_FEEDBACK_AGGREGATE,
  PUBLISH_FEEDBACK_BATCH_MUTATION
} from '../graphql';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';

import eventBus from '@/event-bus';

export default defineComponent({
  name: 'FeedbackPublishBatch',
  components: {
    ListContainer,
    PublishBatchListActions,
    ReviewMapping,
    PublishBatchConfirmation,
    LoadMore
  },
  inject: ['mixpanel'],
  props: {
    parentRouteName: {
      type: String,
      required: true
    }
  },
  setup() {
    const fromDateFilter = ref<number | null>(30);

    const orderFilter = ref('NEWER_FIRST');

    const { feedbacks, allDataFetched, variables, fetchMore } = usePagination({
      fieldName: 'feedbacks',
      query: GET_FEEDBACKS,
      variables: {
        fromDate: fromDateFilter.value
          ? dayjs().subtract(fromDateFilter.value, 'day').format()
          : null,
        direction: SortDirectionEnum.Desc,
        sortBy: 'submitted_at',
        publishMode: true
      },
      options: {
        multiLocation: true
      }
    });

    watch(orderFilter, (orderFilter) => {
      variables.sortBy =
        orderFilter === 'NEWER_FIRST' || orderFilter === 'OLDER_FIRST'
          ? 'submitted_at'
          : 'score';
      variables.direction =
        orderFilter === 'NEWER_FIRST' || orderFilter === 'POSITIVE_FIRST'
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc;
    });

    watch(fromDateFilter, (fromDateFilter) => {
      variables.fromDate = fromDateFilter
        ? dayjs().subtract(fromDateFilter, 'day').format()
        : null;
    });

    return {
      fromDateFilter,
      orderFilter,
      feedbacks,
      allDataFetched,
      variables,
      fetchMore
    };
  },
  data() {
    return {
      showModal: false,
      showPublishBatchConfirmation: false,
      saving: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['companyId', 'companySettings']),
    ...mapState(useLocationsStore, ['locationId', 'dataScope']),
    feedbackEnabled() {
      return this.companySettings.customers.appointmentFeedback;
    }
  },
  methods: {
    ...mapActions(useCompanyStore, ['updateCompany']),
    getStarClip(average: number) {
      const clipPercentage = 1 - average / 5;
      return `inset(0 ${100 * clipPercentage}px 0 0)`;
    },
    openPublishModal() {
      this.mixpanel.track('Publish Batch feedback - Open Publish Modal');
      this.showPublishBatchConfirmation = true;
    },
    publishFeedbackBatch() {
      this.mixpanel.track('Publish Batch feedback - Publish');
      const input = {
        publishToTreatwell: true,
        locationId: this.locationId,
        dataScope: this.dataScope,
        fromDate: this.variables.fromDate
      };

      this.saving = true;
      this.showPublishBatchConfirmation = false;

      this.$apollo
        .mutate({
          mutation: PUBLISH_FEEDBACK_BATCH_MUTATION,
          variables: { input }
        })
        .then(() => {
          eventBus.$emit('update-task', 'push_reviews');
          this.$router.push({ name: this.parentRouteName });
        });
    }
  },
  apollo: {
    feedbackAggregate: {
      query: GET_FEEDBACK_AGGREGATE,
      variables() {
        return {
          publishMode: true,
          locationId: this.locationId,
          dataScope: this.dataScope,
          fromDate: this.fromDateFilter
            ? dayjs().subtract(this.fromDateFilter, 'day').format()
            : null
        };
      }
    }
  },
  created() {
    this.mixpanel.track('Publish Batch feedback - Entered publish batch');
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .base:not(.smallScreen) & {
    height: 100%;
    width: 33.33333%;
    min-width: 33.33333%;
    padding: $spacing * 0.5;
    border-right: 1px solid $color-border;
  }
}

.scrollContainer {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.inner {
  display: flex;
  overflow-y: hidden;
  height: 100%;

  .base.smallScreen & {
    flex-direction: column;
    overflow-x: hidden;
  }
}

.feedbackHolder {
  display: flex;
  flex-direction: column;
  background: $grey-light;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
}

.treatwellCard {
  padding: $spacing;
}

.feedbackContainer {
  height: 80%;
}

.actionButtons {
  text-align: right;
}
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.smallCard {
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.cardImage {
  .smallCard & {
    height: 124px;
  }
}

.header {
  padding: $spacing;
}

.summary {
  position: relative;
  width: 100%;
  z-index: 0;

  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}
.stats {
  display: flex;

  .base:not(.smallScreen) & {
    align-items: start;
    justify-content: flex-start;
  }

  .base.smallScreen & {
    flex-direction: column-reverse;
    gap: $spacing;
  }
}

.statsColumn {
  .base:not(.smallScreen) & {
    flex-basis: 50%;

    &:last-child {
      padding-left: $spacing;
    }
  }
}

.reviewMapping {
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.average {
  display: inline-block;
  white-space: nowrap;
  path {
    fill: #ffc75d !important;
  }
}

.treatwellRating {
  white-space: nowrap;
  path {
    fill: #ffc75d !important;
  }
}

.ratingLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5 * $spacing 0;
  position: relative;
}
.rating {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 500px;
  opacity: 0.25;
}
.rating5 {
  background: #32c977;
}

.rating4 {
  background: #03cfa5;
}
</style>
