<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isNotLarge]: $screen !== 'l' && !small,
        [$style.isSmall]: small
      }
    ]"
  >
    <div :class="$style.inner">
      <BaseText color="secondary" oneLine size="s">
        {{
          $t('onboarding.amount_done', { amount: overallStatus }).toUpperCase()
        }}
      </BaseText>
    </div>
    <div
      v-for="(task, index) in tasksByProcess"
      :key="index"
      :class="[$style.line, { [$style.completed]: task }]"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    tasks: {
      type: Array,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tasksByProcess() {
      return this.tasks.map((task) => task.status).sort((a, b) => b - a);
    },
    amountDone() {
      return this.tasksByProcess.filter((done) => done).length;
    },
    overallStatus() {
      return `${this.amountDone}/${this.tasks.length}`;
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;

  &.isSmall {
    width: 280px;
  }

  &.isNotLarge {
    width: 40vw;
  }
}

.line {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: $color-border-input;

  &:not(:first-child) {
    margin-left: $spacing * 0.5;
  }

  &.completed {
    background: $color-success;
  }
}

.inner {
  margin-top: 2px;
}
</style>
