<template>
  <BaseAlert
    v-if="showAlreadySubscribedAlert"
    mb
    :text="t('admin.cards.already_subscribed')"
    v-test="'billig-already-subscribed-alert'"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const showAlreadySubscribedAlert = ref(false);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

if (route.query.info === 'subscribed-user') {
  showAlreadySubscribedAlert.value = true;
  router.replace({
    name: 'admin-billing'
  });
}
</script>
