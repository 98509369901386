<template>
  <EmptySubscriptionPage
    v-if="!hasFeatureFlag('module-products')"
    :text="{
      title: $t('upgrade_subscription_page.products.categories.title'),
      description: $t(
        'upgrade_subscription_page.products.categories.description'
      ),
      btnPrimary: $t('upgrade_subscription_page.upgrade_btn')
    }"
    imageName="products"
  />

  <div v-else>
    <router-view />
    <Actions
      v-show="!showEmptyPageContent && firstFetchFinished"
      mb
      @search="search = $event"
    />
    <CategoriesList
      v-if="productCategories && productCategories.length"
      :categories="productCategories"
    />
    <Pagination
      :items="productCategories"
      :loading="$apollo.loading"
      fieldName="productCategories"
      :apolloQuery="$apollo.queries.productCategories"
      :search="search"
      :showNoResults="false"
    />
    <EmptyPageContent
      v-show="showEmptyPageContent || noResults"
      imageName="products"
      :text="
        showEmptyPageContent
          ? {
              title: $t('products.empty_state.categories.title'),
              description: $t('products.empty_state.categories.description'),
              btnPrimary: $t('global.actions.create_category')
            }
          : null
      "
      @click="onEmptyPageClick"
    />
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import CategoriesList from './CategoriesList.vue';
import Actions from './Actions.vue';
import Pagination from '@/components/_deprecated/Pagination.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';

import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import EmptySubscriptionPage from '@/modules/_shared/subscription/EmptySubscriptionPage.vue';

export default defineComponent({
  components: {
    CategoriesList,
    Actions,
    Pagination,
    EmptyPageContent,
    EmptySubscriptionPage
  },
  data() {
    return {
      productCategories: [],
      search: '',
      firstFetchFinished: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    noResults() {
      return !this.$apollo.loading && !this.productCategories.length;
    },
    showEmptyPageContent() {
      return this.noResults && this.firstFetchFinished && !this.search;
    }
  },
  methods: {
    onEmptyPageClick() {
      this.$router.push({ name: 'products-create-category' });
    }
  },
  apollo: {
    productCategories: {
      query: gql`
        query getProductCategories(
          $search: String
          $pagination: PaginationAttributes
        ) {
          productCategories(search: $search, pagination: $pagination) {
            id
            name
            amountProducts
          }
        }
      `,
      variables() {
        return {
          search: '',
          pagination: {
            currentPage: 1,
            perPage: 25
          }
        };
      },
      result() {
        this.firstFetchFinished = true;
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>
