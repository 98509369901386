<template>
  <BaseModal
    small
    :heading="$t('onboarding.new_onboarding.try_booking_widget.header')"
    @close="close"
  >
    <div :class="$style.base">
      <img src="/img/onboarding/try_booking_widget.svg" />
      <BaseText bold size="l" mt mb center>
        {{ $t('onboarding.new_onboarding.try_booking_widget.title') }}
      </BaseText>
      <BaseButton
        :routerLink="{ name: 'admin-online-bookings' }"
        @click="handlePush"
      >
        {{ $t('onboarding.new_onboarding.try_booking_widget.button') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import eventBus from '@/event-bus';

const mixpanel = inject<any>('mixpanel');
const handlePush = () => {
  eventBus.$emit('after-modal-small-close');
  mixpanel.track('Onboarding tasks - Widget link clicked');
};

const close = () => {
  eventBus.$emit('open-task-menu');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
