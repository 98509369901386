<template>
  <BaseModal
    :heading="
      $t(
        'marketing.discounts.disable-last-minute-discount-modal.disable_last_minute'
      )
    "
    small
    parentRoute="marketing-discounts"
  >
    <BaseText bold>
      {{ $t('marketing.discounts.disable-last-minute-discount-modal.title') }}
    </BaseText>
    <BaseText>
      {{ $t('marketing.discounts.disable-last-minute-discount-modal.info') }}
    </BaseText>
    <template #footer>
      <BaseButton color="inverted" @click="close" v-test="'discountCodeCancel'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>

      <BaseButton :loading="isLoading" @click="onSubmit" v-test="'saveButton'">
        {{ $t('global.apply') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
import gql from 'graphql-tag';

import { defineComponent } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
const { companySettings } = storeToRefs(useCompanyStore());
export default defineComponent({
  props: {
    parentRouteName: String
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$router.push({
        name: this.parentRouteName
      });
    },
    onSubmit() {
      this.isLoading = true;

      const input = {
        enabled: false
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateLastMinuteDiscounts(
              $input: UpdateLastMinuteDiscountsInput!
            ) {
              updateLastMinuteDiscounts(input: $input) {
                lastMinuteDiscounts {
                  enabled
                }
              }
            }
          `,
          variables: {
            input
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'lastMinuteDiscounts' });
            cache.gc();
          }
        })
        .then(() => {
          companySettings.value.bookings.lastMinuteDiscountEnabled = false;
          flash(this.$t('global.flash.last_minute_discount_disabled'));
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>
