<template>
  <div
    ref="addFilter"
    :class="[$style.addFilter, { [$style.showAsList]: showAsList }]"
    v-test="'customersAddFilter'"
  >
    <BaseText v-if="!showAsList" @click="onAddFilter">
      +{{ $t('global.actions.add_filter') }}
    </BaseText>
    <component
      :is="showAsList ? 'FilterList' : 'BaseFloatingList'"
      v-show="showAsList || isExpanded"
      :class="$style.floatingList"
      :data="filterGroups"
      medium
      fullHeight
      @select="onFilterSelect"
      v-test="'AddFilterListingComponent'"
    />
    <FilterShell
      v-if="selected"
      :selected="selected"
      :shiftPosition="shiftPosition"
      :switchOnClick="switchOnClick"
      @setFilter="setFilter"
      @closeFilter="closeFilter"
    />
  </div>
</template>

<script lang="ts">
import FilterShell from './FilterShell.vue';
import FilterList from './FilterList.vue';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FilterShell,
    FilterList
  },
  inject: ['mixpanel'],
  props: {
    switchOnClick: {
      type: Boolean,
      required: false
    },
    showAsList: {
      type: Boolean,
      default: false
    },
    savedFilters: {
      type: Boolean,
      default: false
    }
  },
  emits: ['setFilter'],
  data() {
    return {
      selected: null,
      isExpanded: false,
      filterIsSelected: false,
      shiftPosition: false
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    filterGroups() {
      let options = [];

      const defaultOptions = [
        {
          name: this.$t('customers.filter_groups.age'),
          value: 'age',
          icon: 'clock'
        },
        {
          name: this.$t('customers.filter_groups.birthdays'),
          value: 'birthdays',
          icon: 'gift'
        },
        {
          name: this.$t('customers.filter_groups.customers'),
          value: 'customers',
          icon: 'people'
        },
        {
          name: this.$t('customers.filter_groups.gender'),
          value: 'gender',
          icon: 'gender'
        }
      ];

      options = [...options, ...defaultOptions];

      if (this.hasFeatureFlag('module-appointments')) {
        const moduleAppointmentOptions = [
          {
            name: this.$t('customers.filter_groups.appointments'),
            value: 'appointments',
            icon: 'calendar'
          },
          {
            name: this.$t('customers.filter_groups.last_appointment'),
            value: 'last_appointment',
            icon: 'calendar'
          },
          {
            name: this.$t('customers.filter_groups.no_shows'),
            value: 'no_shows',
            icon: 'invisible'
          },
          {
            name: this.$t('customers.filter_groups.treated_by'),
            value: 'treated_by',
            icon: 'person'
          }
        ];

        options = [...options, ...moduleAppointmentOptions];
      }

      if (this.hasFeatureFlag('module-register')) {
        const moduleRegisterOptions = [
          {
            name: this.$t('customers.filter_groups.loyalty_points'),
            value: 'loyalty_points',
            icon: 'heart'
          },
          {
            name: this.$t('customers.filter_groups.money_spent'),
            value: 'money_spent',
            icon: 'shopping-cart'
          },
          {
            name: this.$t('customers.filter_groups.products'),
            value: 'products',
            icon: 'pricetag'
          },
          {
            name: this.$t('customers.filter_groups.services'),
            value: 'services',
            icon: 'briefcase'
          }
        ];
        options = [...options, ...moduleRegisterOptions];
      }

      options.push({
        name: this.$t('customers.tags.tags'),
        value: 'tags',
        icon: 'pricetag'
      });

      const sorted = options.sort((a, b) => {
        const aKey = a.name;
        const bKey = b.name;
        if (aKey > bKey) {
          return 1;
        } else if (aKey < bKey) {
          return -1;
        }
        return 0;
      });

      if (this.savedFilters) {
        const savedFilters = {
          name: this.$t('customers.filter_groups.saved_filter_groups'),
          value: 'saved_filter_groups',
          icon: 'star'
        };

        return [savedFilters, ...sorted];
      }

      return sorted;
    }
  },
  methods: {
    setFilter(filter) {
      this.mixpanel.track('customer_filter_added', {
        filter_name: filter.filter_name,
        filter_values: filter.values,
        filter_value: filter.value
      });
      this.$emit('setFilter', filter);
    },
    onAddFilter() {
      this.isExpanded = true;
      const positionX = this.$refs.addFilter.getBoundingClientRect().x;
      this.shiftPosition =
        Math.abs(document.documentElement.clientWidth - positionX) < 550;
    },
    onFilterSelect(filter) {
      this.mixpanel.track('customer_filter_opened', {
        filter_name: filter.name
      });
      if (this.switchOnClick || this.$screen === 's') {
        this.isExpanded = false;
      }
      this.selected = filter;
      this.filterIsSelected = true;
    },
    closeFilter() {
      this.isExpanded = false;
      this.selected = null;
    },
    onDocumentClick(e) {
      if (!this.$refs.addFilter.contains(e.target)) {
        this.isExpanded = false;
        this.selected = null;
        this.filterIsSelected = false;
      }
    }
  },
  mounted() {
    document.addEventListener('mousedown', this.onDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick);
  }
});
</script>

<style lang="scss" module>
.addFilter {
  position: relative;
  color: $color-primary;
  cursor: pointer;

  .isNotLarge & {
    order: -1;
    margin-right: $spacing * 0.5;
  }

  &:not(.showAsList) {
    margin-left: $spacing * 0.5;
  }
}

.floatingList {
  cursor: default;
  color: $color-text;
  width: 220px !important;
  top: $spacing * 1.5 !important;
}
</style>
