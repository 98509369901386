<template>
  <div :class="$style.base">
    <div :class="$style.top">
      <OrderItems />
      <Transactions v-bind="{ editable }" />
    </div>
    <div :class="$style.bottom" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'InvoicePreview'
};
</script>

<script setup lang="ts">
import Transactions from './Transactions.vue';
import OrderItems from './OrderItems.vue';

defineProps<{
  editable?: boolean;
}>();
</script>

<style lang="scss" module>
.base {
  border: 1px solid $color-border;
  border-bottom: none;
  border-radius: 9px 9px 0 0;
}

.top {
  background-color: white;
  border-radius: inherit;
}

.bottom {
  height: 24px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    --mask: radial-gradient(10px at bottom, #0000 97%, #000) 0% / 32px 100%;
    -webkit-mask: var(--mask);
    mask: var(--mask);
  }

  &:before {
    background-color: #ddd;
  }

  &:after {
    top: -2px;
    background-color: white;
  }
}
</style>
