<template>
  <BaseModal
    :heading="$t('company_blocked.title')"
    small
    testId="blocked-company-modal"
  >
    <BaseText mb>
      {{ $t('company_blocked.description') }}
    </BaseText>
  </BaseModal>
</template>
