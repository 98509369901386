<template>
  <BaseCard :heading="$t('dashboard.quick_start.heading')">
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseButton
        v-if="hasFeatureFlag('module-appointments')"
        :routerLink="{ name: 'create-appointment' }"
        color="inverted"
        v-test="'dashboard-create-appointment'"
      >
        {{ filters.capitalize($t('global.items.appointment', 1)) }}
      </BaseButton>
      <BaseButton
        v-if="hasFeatureFlag('module-register')"
        :routerLink="{ name: 'register' }"
        color="inverted"
        v-test="'dashboard-create-invoice'"
      >
        {{ filters.capitalize($t('global.items.invoice', 1)) }}
      </BaseButton>
      <BaseButton
        v-if="hasFeatureFlag('module-customers')"
        :routerLink="{ name: 'customers-new' }"
        color="inverted"
        v-test="'dashboard-create-customer'"
      >
        {{ filters.capitalize($t('global.items.customer', 1)) }}
      </BaseButton>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      filters
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag'])
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing * -0.5;

  & > * {
    margin-bottom: $spacing * 0.5;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }

  &.smallScreen {
    & > *:not(:last-child) {
      margin-right: $spacing * 0.25;
    }
  }
}
</style>
