<template>
  <div>
    <BrowserItem
      :item="{
        label: category.name
      }"
      arrowLeft
      :expanded="expanded"
      :borderTop="index === 0"
      @click="expanded = !expanded"
    />
    <div v-if="expanded">
      <BrowserItem
        v-for="(service, index) in services"
        :key="index"
        :item="service"
        showFavorite
        indented
        @click="selectItem(service.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useServicesStore } from '@/stores/services';
import BrowserItem from '../../BrowserItem.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';

const props = defineProps<{
  category: any;
  index: number;
}>();

const expanded = ref(false);

const services = computed(() => {
  const { services } = useServicesStore();
  return services
    .filter((service) => service.category.id === props.category.id)
    .map((item: any) => ({
      id: item.id,
      label: item.name,
      price: item.price,
      favorite: item.favorite,
      type: 'service'
    }));
});

const selectItem = (id: number) => {
  const { services } = useServicesStore();
  const service = services.find((service: any) => service.id === id);
  if (service) {
    const { addItem } = useRegisterOrderStore();

    addItem({
      name: service.name,
      price: service.price,
      serviceId: service.id,
      medical: service.medical!
    });
  }
};
</script>
