import {
  formatDate,
  formatWeekDay,
  ordinalDate,
  fromNow
} from '@/helpers/dates';
import type { DateValue, DateOptions, WeekDayValue } from '@/helpers/dates';
import { formatCurrency } from '@/helpers/currency';
import { formatEnters, giftCardCode, capitalize } from '@/helpers/formatting';

export default {
  currency: (value: number, currency?: string) =>
    formatCurrency(value, currency),

  date: (value: DateValue, options?: DateOptions) => formatDate(value, options),
  time: (value: DateValue) => formatDate(value, { format: 'time' }),
  dateTime: (value: DateValue) =>
    formatDate(value, { format: 'numeric', addTime: true }),

  monthLong: (value: DateValue) => formatDate(value, { format: 'monthLong' }),
  monthShort: (value: DateValue) => formatDate(value, { format: 'monthShort' }),

  weekdayNarrow: (dayOfWeek: WeekDayValue) =>
    formatWeekDay(dayOfWeek, 'weekdayNarrow'),
  weekdayShort: (dayOfWeek: WeekDayValue) =>
    formatWeekDay(dayOfWeek, 'weekdayShort'),
  weekdayLong: (dayOfWeek: WeekDayValue) =>
    formatWeekDay(dayOfWeek, 'weekdayLong'),

  ordinalDate: (number: number) => ordinalDate(number),
  fromNow: (value: DateValue) => fromNow(value),

  formatEnters: (value: string) => formatEnters(value),
  giftCardCode: (value: string) => giftCardCode(value),
  capitalize: (value: string) => capitalize(value)
};
