<template>
  <div
    ref="scrollContainer"
    :class="[$style.base, { [$style.isSmall]: $screen === 's' }]"
  >
    <BaseForm :scrollContainer="scrollContainer">
      <div :class="$style.inner">
        <Intro mb />
        <Sections mb />
        <slot />
      </div>
    </BaseForm>
  </div>
</template>

<script lang="ts">
import Intro from './Intro.vue';
import Sections from './Sections.vue';

import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    Intro,
    Sections
  },
  setup() {
    const scrollContainer = ref();
    return {
      scrollContainer
    };
  }
});
</script>

<style lang="scss" module>
.base {
  height: calc(100vh - 100px);
  border-right: 1px solid $color-border;
  padding: $spacing;
  overflow-y: auto;
  background: $white;

  &.isSmall {
    height: 100%;
    border-bottom: 1px solid $color-border;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
