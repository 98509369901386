<template>
  <Teleport to="#modals">
    <div
      :class="[
        $style.base,
        $style[size],
        {
          [$style.smallScreen]: $screen === 's',
          [$style.previewMode]: previewMode
        }
      ]"
      :data-size="size"
      @mousedown="onDocumentDown"
      @touchstart="onDocumentDown"
      @mouseup="onDocumentUp"
      @touchend="onDocumentUp"
    >
      <div
        ref="scrollContainer"
        :class="$style.scrollContainer"
        @scroll="onScroll"
      >
        <div :class="$style.inner">
          <transition appear>
            <div :class="$style.content" class="js-content">
              <slot />
            </div>
          </transition>
        </div>
      </div>
      <transition appear>
        <div :class="$style.background" v-test="`modal-${size}-background`" />
      </transition>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { usePageScroll } from '@/helpers/scroll';
import { device } from '@/user-context';

const scrollContainer = ref();
usePageScroll(scrollContainer);
</script>

<script lang="ts">
import { hideTooltip } from '@/helpers/ui';

import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: String
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clickBackground', 'pressEscape'],
  data() {
    return {
      mouseDownTarget: null
    };
  },
  methods: {
    onDocumentDown(e) {
      this.mouseDownTarget = e.target;
    },
    onDocumentUp(e) {
      if (
        this.mouseDownTarget &&
        !this.mouseDownTarget.isSameNode(this.$refs.scrollContainer) &&
        e.target === this.mouseDownTarget &&
        !e.target.closest('.js-content')
      ) {
        if (device.touch) {
          // Add a timeout for touch devices to prevent a click from triggering when clicking on an element underneath the background layer of the modal
          setTimeout(() => {
            this.$emit('clickBackground');
          }, 50);
        } else {
          this.$emit('clickBackground');
        }
      }
    },
    onScroll() {
      hideTooltip();
    },
    onKeyPress(e) {
      if (e.key === 'Escape') {
        // Only trigger this when the current modal is the top layer
        const modalContainer = document.querySelector('#modals');
        const smallModal = modalContainer.querySelector('[data-size="small"]');
        const mediumModal = modalContainer.querySelector(
          '[data-size="medium"]'
        );

        let closeThisModal = false;

        switch (this.size) {
          case 'small':
            closeThisModal = true;
            break;
          case 'medium':
            closeThisModal = !smallModal;
            break;
          case 'large':
            closeThisModal = !smallModal && !mediumModal;
            break;
        }

        if (closeThisModal) {
          this.$emit('pressEscape');
        }
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyPress);
  }
});
</script>

<style lang="scss" module>
.base {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: width 0.5s $easeOutExpo;

  &.small {
    z-index: 902;
  }

  &.medium {
    z-index: 901;
  }

  &.large {
    z-index: 900; // Google maps autocomplete uses z-index: 1000, this value needs to be lower than that
  }

  &.previewMode {
    width: 38%;
  }
}

.scrollContainer {
  height: 100%;
  position: relative;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  .base.small.smallScreen & {
    overflow-y: hidden;
  }
}

.inner {
  min-height: 100%;
  display: flex;
  justify-content: center;

  .base.large & {
    height: 100%;
  }

  .base.small:not(.smallScreen) & {
    align-items: center;
  }

  .base.small.smallScreen & {
    align-items: flex-end;
  }

  .base.medium & {
    align-items: flex-start;
  }

  .base:not(.smallScreen).medium & {
    padding: $spacing * 2 $spacing $spacing;
  }

  .base:not(.smallScreen):not(.medium) & {
    padding: $spacing;
  }

  .base.smallScreen:not(.small) & {
    padding: $spacing * 0.5;
  }
}

.content {
  position: relative;
  max-width: 100%;

  .base.small.smallScreen & {
    width: 100%;
  }

  &:global(.v-enter-active) {
    transition: transform 0.15s $easeOutBack;
  }

  &:global(.v-enter-from) {
    .base.small:not(.smallScreen) & {
      transform: scale(0.92);
    }

    .base.small.smallScreen & {
      transform: translateY(50px);
    }

    .base.medium & {
      transform: scale(0.95);
    }

    .base.large & {
      transform: scale(0.98);
    }
  }
}

.background {
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  transition: opacity 0.15s;

  &:global(.v-enter-from) {
    opacity: 0;
  }

  .base.previewMode & {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
