<template>
  <div>
    <BrowserItem
      :item="{
        label: name
      }"
      arrowLeft
      :expanded="expanded"
      :borderTop="index === 0"
      @click="expanded = !expanded"
    />
    <div v-if="expanded">
      <Products :categoryId="categoryId" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Products from './Products.vue';
import BrowserItem from '../../BrowserItem.vue';
defineProps<{
  categoryId: number;
  name: string;
  index: number;
}>();

const expanded = ref(false);
</script>
