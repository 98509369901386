<template>
  <ResourceForm
    :type="type"
    @close="$emit('close')"
    @created="$emit('created', $event)"
  />
</template>

<script lang="ts">
import ResourceForm from './resource-form/index.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ResourceForm
  },
  props: {
    type: {
      type: String,
      validator: (value) => !value || ['room', 'equipment'].includes(value)
    }
  },
  emits: ['close', 'created']
});
</script>
