<template>
  <SubscriptionCard
    :title="t('admin.cards.subscribe.title')"
    :features="features"
    :buttonLabel="t('admin.cards.subscribe.upgrade_button')"
  />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SubscriptionCard from './SubscriptionCard.vue';

const { t } = useI18n();

const features = ['manage_bookings', 'run_your_salon_tasks', 'loyalty_program'];
</script>
