<template>
  <BaseModalLarge
    :routes="[]"
    :heading="$t('global.actions.create_stock_order')"
    noPadding
    parentRoute="products-stock-orders"
  >
    <div :class="$style.inner">
      <ChooseSupplier
        v-if="$route.name === 'products-order-new'"
        :suppliers="suppliers"
        :loading="$apollo.queries.suppliers.loading"
      />
      <BuildStockOrder v-if="$route.name === 'products-order-build'" />
    </div>
  </BaseModalLarge>
</template>

<script lang="ts">
import ChooseSupplier from './ChooseSupplier.vue';
import BuildStockOrder from './BuildStockOrder.vue';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ChooseSupplier,
    BuildStockOrder
  },
  data() {
    return {
      suppliers: []
    };
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId'])
  },
  apollo: {
    suppliers: {
      query: gql`
        query getSuppliers(
          $locationId: ID
          $search: String
          $pagination: PaginationAttributes
        ) {
          suppliers(
            locationId: $locationId
            search: $search
            pagination: $pagination
          ) {
            id
            name
            totalInventory
            amountProductsWithStock
          }
        }
      `,
      variables() {
        return {
          locationId: this.locationId,
          search: '',
          pagination: {
            currentPage: 1,
            perPage: 1000
          }
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  position: relative;
  height: 100%;
}
</style>
