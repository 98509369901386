// It is possible to create a calendar event from other places of the app, for example from the customers page.
// In that case, the customer data will get saved here so it can be shown in the calendar and be sent to the "create appointment" store afterwards.
// Also when copying or moving an appointment, the state will be saved here.

import { defineStore } from 'pinia';
import type { Service, Customer, Resource, Appointment } from '@/types';
import { useResourcesStore } from '@/stores/resources';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';

export enum Action {
  Copy = 'COPY',
  Create = 'CREATE',
  Reschedule = 'RESCHEDULE'
}

interface State {
  action: Action | null;
  appointmentAttributes: any;
  customer: Customer | null;
  serviceIds: Service['id'][];
}

export const usePreCreateStore = defineStore('calendar/preCreate', {
  state: (): State => ({
    action: null,
    appointmentAttributes: null,
    customer: null,
    serviceIds: []
  }),
  getters: {
    appointmentId(): Appointment['id'] | null {
      return this.appointmentAttributes?.appointmentId || null;
    },
    oldResourceId(): Resource['id'] | null {
      const resourceIds: Resource['id'][] =
        this.appointmentAttributes?.parts[0]?.resourceIds;
      const { resourceType } = useCalendarFiltersStore();
      const { resourceById } = useResourcesStore();

      if (!resourceIds) {
        return null;
      }

      return (
        resourceIds.find((id: any) => {
          const resource = resourceById(id);

          return !!resource && resource.type === resourceType;
        }) || null
      );
    }
  },
  actions: {
    setState({
      action,
      appointment,
      customer,
      serviceIds
    }: {
      action: Action;
      appointment?: any;
      customer?: Customer;
      serviceIds?: State['serviceIds'];
    }) {
      this.action = action;
      this.serviceIds = serviceIds || [];
      this.customer = customer || null;

      // Disable dynamic pricing mode
      const filterStore = useCalendarFiltersStore();
      filterStore.dynamicPricingMode = false;

      if (appointment) {
        this.appointmentAttributes = {
          appointmentId: appointment.id,
          startAt: appointment.startAt,
          rrule: appointment.rrule,
          original: appointment.original,
          originalStartAt: appointment.originalStartAt,
          parts: appointment.parts?.map((part: any) => ({
            serviceId: part.service.id,
            resourceIds: part.resources.map((r: any) => r.id),
            id: part.id,
            duration: part.duration,
            durationSetup: part.duration_setup,
            durationProcessing: part.duration_processing,
            durationFinish: part.duration_finish
          }))
        };
      }
    }
  }
});
