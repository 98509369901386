<template>
  <div>
    <BaseHeading mb size="s">
      {{ filters.capitalize($t('global.items.service', 2)) }}
    </BaseHeading>
    <EmptyResults v-if="serviceCategories.length < 1" />
    <div v-else>
      <Category
        v-for="(category, index) in serviceCategories"
        :key="index"
        :category="category"
        :index="index"
      >
        {{ category.name }}
      </Category>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServicesList'
};
</script>

<script setup lang="ts">
import EmptyResults from '../../EmptyResults.vue';
import { useServicesStore } from '@/stores/services';
import { storeToRefs } from 'pinia';
import filters from '@/filters';
import Category from './Category.vue';

const { serviceCategories } = storeToRefs(useServicesStore());
</script>
