<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div :class="$style.container">
      <BaseHeading :mb="2" :center="$screen === 's'" size="l">
        {{
          replacePinapparaat(
            $t('register.salonized_pay.landing.terminals.heading'),
            company.country
          )
        }}
      </BaseHeading>
      <div :class="$style.data">
        <div v-if="$screen !== 's'">
          <div :class="$style.emptySpace" />
          <BaseTable
            :rows="
              rows.map((row) => ({
                cells: [
                  $t(`register.salonized_pay.landing.terminals.table.${row}`)
                ]
              }))
            "
          />
        </div>
        <div
          v-for="(terminal, index) in terminals"
          :key="`terminal-${index}`"
          :class="[
            $style.terminalContainer,
            { [$style.lastTerminal]: index === 1 }
          ]"
        >
          <div :class="$style.terminal" @click="emit('select', terminal.id)">
            <BaseHeading :mb="0.5">
              <div :class="$style.name">
                {{ terminal.name }}
              </div>
            </BaseHeading>
            <div :class="$style.image">
              <img :src="`/img/salonized-pay/terminal${index + 1}.png`" />
            </div>
          </div>
          <BaseTable
            :rows="
              rows.map((row) => ({
                cells: [
                  {
                    value: $t(
                      `register.salonized_pay.landing.terminals.table.${row}`
                    ),
                    hide: $screen !== 's'
                  },
                  {
                    ...(row === 'price' && {
                      value: filters.currency(
                        terminal[row as keyof typeof terminal]
                      )
                    }),
                    ...(row !== 'price' && {
                      icon: terminal[row as keyof typeof terminal]
                        ? 'circle-check'
                        : 'circle-x',
                      color: terminal[row as keyof typeof terminal]
                        ? 'success'
                        : 'error'
                    }),
                    center: true
                  }
                ]
              }))
            "
          />
          <div :class="$style.button">
            <BaseButton
              :color="$screen === 's' ? 'primary' : 'inverted'"
              @click="emit('select', terminal.id)"
              v-test="'salonized-pay-terminal-select'"
            >
              {{
                replacePinapparaat(
                  $t('register.salonized_pay.landing.terminals.pick'),
                  company.country
                )
              }}
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { terminals } from './terminals';
import { replacePinapparaat } from '@/helpers/formatting';
import { useCompanyStore } from '@/stores/company';
import filters from '@/filters';

const { company } = useCompanyStore();
const emit = defineEmits(['select']);

const rows = ['price', 'printer', 'touchscreen', 'wifi', 'portable', '4g'];
</script>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 950px;
  width: 100%;

  margin-top: $spacing * 8;
  margin-bottom: $spacing * 4;
}

.container {
  width: 100%;
  padding: $spacing;
}

.data {
  width: 100%;
  display: flex;

  .base.smallScreen & {
    flex-direction: column;
  }
}

.emptySpace {
  height: 250px;
}

.terminalContainer {
  width: 100%;

  .base.smallScreen & {
    &:first-child {
      margin-bottom: $spacing * 2;
    }
  }
}

.terminal {
  height: 250px;
  border-radius: 8px;
  padding: $spacing * 1.5 $spacing * 2;
  margin: 0px calc($spacing / 2);
  position: relative;
  background-image: linear-gradient(to right bottom, #e7f5f6, #f3f4fa, #dfe3f7);
  z-index: 1;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    background-image: linear-gradient(to right, #d7f7f1, #f3f4fa, #b3b2e7);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s $easeOutCubic;
    border-radius: 8px;
  }

  .lastTerminal & {
    background-image: linear-gradient(
      to right bottom,
      #dfe3f7,
      #f3f4fa,
      #e7f5f6
    );
  }

  @include hover {
    &::before {
      opacity: 1;
    }

    .image {
      transform: scale(1.1);
    }

    .name {
      transform: scale(1.2);
    }
  }
}

.image {
  display: flex;
  justify-content: center;
  z-index: 2;
  transition: transform 0.3s $easeOutCubic;
  height: 175px;
}

.name {
  z-index: 2;
  transition: transform 0.3s $easeOutCubic;
}

.button {
  display: flex;
  justify-content: center;
  margin: $spacing * 2 0;

  .base.smallScreen & {
    & > * {
      margin: $spacing;
      flex-grow: 1;
    }
  }
}
</style>
