<template>
  <div>
    <div v-if="!$apollo.loading" :class="$style.content">
      <div :class="$style.left">
        <BaseText
          :href="phoneNumber && device.touch ? `tel:${phoneNumber}` : null"
          v-test="`customer-details-phone${!phoneNumber ? '-empty' : ''}`"
        >
          {{ phoneNumber ? phoneNumber : $t('customer_details.no_phone') }}
        </BaseText>
      </div>
      <div
        v-if="showLastAppointmentAt"
        :class="$style.right"
        v-test="'customer-details-lastAppointment'"
      >
        <BaseIcon
          name="refresh"
          size="s"
          :mr="0.25"
          :tooltip="{
            text: $t('customer_details.last_appointment'),
            touch: true
          }"
        />
        {{ filters.fromNow(lastAppointmentAt) }}
      </div>
      <div
        v-else-if="!lastAppointmentAt && showAppointmentState"
        :class="$style.right"
        v-test="'customer-details-new'"
      >
        🎉 {{ $t('customer_details.new_customer') }}
      </div>
    </div>
    <BaseSpinner v-else inline />
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import dayjs from '@/dayjs';
import { defineComponent } from 'vue';
import { device } from '@/user-context';

export default defineComponent({
  props: {
    customer: {
      type: Object,
      required: true
    },
    appointmentStart: {
      type: String,
      required: true
    },
    showAppointmentState: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      device,
      filters
    };
  },
  computed: {
    phoneNumber() {
      return this.customer.mobilePhone || this.customer.phone;
    },
    lastAppointmentAt() {
      return this.customer.lastAppointmentAt;
    },
    showLastAppointmentAt() {
      return (
        this.lastAppointmentAt &&
        !dayjs(this.lastAppointmentAt).isSameOrAfter(
          dayjs(this.appointmentStart),
          'day'
        )
      );
    }
  }
});
</script>

<style lang="scss" module>
.content {
  display: flex;
  justify-content: space-between;
}

.right {
  display: flex;
  align-items: center;
  text-align: right;
}
</style>
