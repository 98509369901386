<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <BaseCard>
      <slot />
    </BaseCard>
  </div>
</template>

<style lang="scss" module>
.base {
  width: 100%;

  &:not(.smallScreen) {
    max-width: 650px + $spacing * 2;
    margin: $spacing auto;
    padding: $spacing;
  }

  &.smallScreen {
    padding: $spacing * 0.5;
  }
}
</style>
