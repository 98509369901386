<template>
  <BaseGrid container>
    <BaseGrid :size="$screen !== 'l' ? 12 : 8">
      <RequestTrialBanner
        v-if="!loading"
        :showRequestTrial="showRequestTrial"
        :mb="2"
      />
      <Stats
        v-if="hasFeatureFlag('module-marketing')"
        :mt="2"
        v-test="'marketing-insights-stats'"
      />
    </BaseGrid>
    <BaseGrid :size="$screen !== 'l' ? 12 : 4">
      <Tips />
    </BaseGrid>
  </BaseGrid>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import Tips from './Tips.vue';
import Stats from './Stats.vue';
import RequestTrialBanner from './RequestTrialBanner.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useQuery } from '@vue/apollo-composable';
import { defineComponent, computed } from 'vue';
import gql from 'graphql-tag';

export default defineComponent({
  components: {
    Tips,
    Stats,
    RequestTrialBanner
  },
  setup() {
    const { result, loading } = useQuery(
      gql`
        query getCurrentUser {
          currentUser {
            company {
              activeSubscription {
                plan
              }
            }
          }
        }
      `,
      null,
      {
        fetchPolicy: 'cache-first'
      }
    );

    const planIsPro = computed(
      () => result.value?.currentUser.company.activeSubscription?.plan === 'pro'
    );

    return {
      planIsPro,
      loading
    };
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag']),
    ...mapState(useCompanyStore, ['company']),
    trialHasEnded() {
      return this.company.marketingTrialEndsAt < dayjs().format();
    },
    showRequestTrial() {
      const noTrial = !this.hasFeatureFlag('module-marketing');
      const inTrial =
        this.hasFeatureFlag('module-marketing') &&
        this.company.marketingTrialEndsAt &&
        !this.trialHasEnded;
      const { isTreatwellUser } = useCompanyStore();

      return !this.planIsPro && (noTrial || inTrial) && !isTreatwellUser;
    }
  }
});
</script>
