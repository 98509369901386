<template>
  <div :class="$style.base" v-test="'blocked-header'">
    <div :class="$style.inner">
      <BaseHeading size="l">{{ t('nav.billing') }}</BaseHeading>
      <div :class="$style.container">
        <Analyze
          v-if="user && user.salonizedAdmin"
          :class="$style.item"
          v-test="'blocked-header-analyze'"
        />
        <div
          v-if="user"
          ref="userElement"
          :class="[$style.item, $style.avatar]"
          @click="showUserNavPopover = !showUserNavPopover"
          v-test="'blocked-header-user-avatar'"
        >
          <BaseAvatar :name="user.fullName" size="l" />
        </div>
        <BasePopoverNav
          v-show="showUserNavPopover"
          :style="{
            top: '60px',
            right: '17px'
          }"
          arrow="topRight"
          :items="userItems"
          :keepVisibleOn="userElement"
          @close="showUserNavPopover = false"
          @select="logout"
          v-test="'blocked-header-user-popover'"
        >
          <BaseText v-if="user" bold>
            {{ user.fullName }}
          </BaseText>
          <BaseText v-if="user" size="s" :style="{ marginTop: '4px' }">
            {{ user.email }}
          </BaseText>
        </BasePopoverNav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Analyze from './Analyze.vue';

import { useUserStore } from '@/stores/user';
import { logout } from '@/helpers/session';

const { t } = useI18n();
const { user } = useUserStore();

const userElement = ref<Element>();
const showUserNavPopover = ref(false);

const userItems = [
  {
    name: 'logout',
    icon: 'logout',
    label: t('nav.logout')
  }
];
</script>

<style lang="scss" module>
.base {
  padding: 0 $spacing;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height - 1px;
}

.container {
  display: flex;
  align-items: center;
}

.item {
  @include header-item;
}
.avatar {
  cursor: pointer;
  margin-left: $spacing;
}
</style>
