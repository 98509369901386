<template>
  <BaseModalLarge
    :heading="
      !isSubscribed
        ? $t('subscription.heading')
        : $t('subscription.heading_subscribed')
    "
    testId="tw-subscription"
    noPadding
    parentRoute="dashboard"
    :unclosable="isTrialExpired"
    @close="mixpanel.track('Subscription flow - Closed')"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's',
          [$style.mediumScreen]: $screen === 'm'
        }
      ]"
    >
      <div :class="$style.contentWrapper">
        <div :class="$style.content">
          <div
            v-if="trialExpiredDaysAgo >= 0 && !isSubscribed"
            :class="$style.alertHolder"
          >
            <BaseAlert
              :text="
                $t(
                  'subscription.trial_expired',
                  { days: trialExpiredDaysAgo },
                  trialExpiredDaysAgo
                )
              "
              color="warning"
              v-test="'trial-expired-alert'"
            />
          </div>
          <div :class="$style.planInfo">
            <StripeCheckoutPlan
              :plan="plan"
              :selected="true"
              :showAllFeatures="showAllFeatures"
            />
            <BaseText
              v-if="$screen === 's'"
              :iconAfter="showAllFeatures ? 'minus' : 'plus'"
              link
              @click="showAllFeatures = !showAllFeatures"
              v-test="'subscription-show-all-features'"
            >
              {{
                $t(
                  showAllFeatures
                    ? 'subscription.show_main_features'
                    : 'subscription.show_all_features'
                )
              }}
            </BaseText>
          </div>
        </div>
      </div>
      <div :class="$style.side">
        <div :class="$style.summary">
          <BaseHeading size="l" mb>
            {{ $t('subscription.payment.your_order') }}
          </BaseHeading>

          <div :class="$style.summaryItem">
            <BaseText size="m" bold>
              {{
                $t('subscription.payment.total_per_period', {
                  period: $t('global.items.month', 1)
                })
              }}
            </BaseText>
            <div>
              <BaseText
                size="m"
                bold
                iconAfter="info"
                :tooltip="{
                  position: 'bottom',
                  text: $t('subscription.payment.ex_vat')
                }"
                v-test="'subscription-summary-price-total'"
              >
                {{ filters.currency(getUnitAmount()) }}
              </BaseText>
            </div>
          </div>

          <BaseForm v-if="!isSubscribed" @submit="submit">
            <BaseHeading :mt="2" mb>
              {{ $t('onboarding.new_tasks.company_info.heading') }}
            </BaseHeading>
            <div :class="$style.fieldsWrapper">
              <div :class="$style.fieldsContainer">
                <BaseInput
                  v-model="formData.name"
                  :label="$t('signup.company_name')"
                  required
                  :minLength="2"
                  mb
                  v-test="'tw-checkout-company'"
                />
                <BaseInput
                  v-model="formData.phone"
                  :label="$t('signup.phone')"
                  required
                  type="tel"
                  :minLength="9"
                  :maxLength="16"
                  mb
                  v-test="`tw-checkout-phone`"
                />
                <BaseInput
                  v-model="formData.postalcode"
                  :label="$t('onboarding.new_tasks.company_info.postal')"
                  required
                  mb
                  v-test="'tw-checkout-postal'"
                />
                <BaseInput
                  v-model="formData.address"
                  required
                  :label="$t('onboarding.new_tasks.company_info.address')"
                  mb
                  v-test="'tw-checkout-address'"
                />
                <BaseInput
                  v-model="formData.city"
                  required
                  :label="$t('onboarding.new_tasks.company_info.city')"
                  mb
                  v-test="'tw-checkout-city'"
                />
              </div>
            </div>
            <div :class="$style.subscribeHolder">
              <BaseButton
                fullWidth
                :mb="0.5"
                submitForm
                v-test="'tw-subscribe-button'"
              >
                {{ $t('subscription.treatwell_flow.subscription_button') }}
              </BaseButton>
              <BaseText inline>
                {{ $t('subscription.treatwell_flow.privacy.text') }}
              </BaseText>
              <BaseText
                :href="$t('subscription.treatwell_flow.privacy.url')"
                inline
              >
                {{ $t('subscription.treatwell_flow.privacy.label') }}
              </BaseText>
            </div>
          </BaseForm>
        </div>
      </div>
    </div>
  </BaseModalLarge>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { Currency, SubscriptionPlan } from '@/types';
import type { SubscriptionPlanItem } from '@/types';
import StripeCheckoutPlan from '@/modules/dashboard/_shared/CheckoutPlan.vue';
import { useCompanyStore } from '@/stores/company';
import { usePageLayoutStore } from '@/stores/page-layout';
import { useMutation } from '@vue/apollo-composable';
import { TREATWELL_UPDATE_PILOT_SUBSCRIPTION } from './graphql';
import { modal } from '@/helpers/ui';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';

const router = useRouter();

const { company, isTrialExpired, trialExpiredDaysAgo, isSubscribed } =
  useCompanyStore();
const { screenSize } = usePageLayoutStore();

const formData = reactive({
  name: company.name,
  postalcode: company.postalcode,
  city: company.city,
  phone: company.phone,
  address: company.address
});

const { mutate } = useMutation(TREATWELL_UPDATE_PILOT_SUBSCRIPTION);

const getUnitAmount = () => {
  if (company.country === 'fr') {
    return 3900;
  } else if (company.country === 'de') {
    return 4900;
  }
  return 5250;
};

const plan: SubscriptionPlanItem = {
  plan: SubscriptionPlan.Pro,
  prices: {
    base: [
      {
        unitAmount: getUnitAmount(),
        upTo: 1,
        currency: Currency.Eur
      }
    ],
    locations: null,
    resources: [
      {
        unitAmount: 0,
        upTo: null,
        currency: Currency.Eur
      }
    ]
  }
};

const showAllFeatures = ref(screenSize !== 's');
const showPhoneError = ref(false);

const submit = () => {
  mutate({
    input: formData
  })
    .then(() => {
      const { getUser } = useUserStore();
      getUser().then(() => {
        router.push({ name: 'dashboard' });

        setTimeout(() => {
          modal('welcome');
        }, 0);
      });
    })
    .catch((errors) => {
      if (
        errors?.length &&
        errors.find((error: any) => error.attribute === 'phone')
      ) {
        showPhoneError.value = true;
      }
    });
};
watch(
  () => formData.phone,
  () => {
    showPhoneError.value = false;
  }
);
</script>

<style lang="scss" module>
.base {
  height: 100%;

  &:not(.smallScreen) {
    display: flex;
    justify-content: space-between;
  }
}

$sidebarWidth: 350px;
$sidebarWidthMedium: 294px;

.contentWrapper {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .base:not(.smallScreen) & {
    min-width: 60%;
  }

  .base:not(.mediumScreen):not(.smallScreen) & {
    width: calc(100% - #{$sidebarWidth});
  }

  .base.mediumScreen & {
    width: calc(100% - #{$sidebarWidthMedium});
  }
}

.content {
  .base:not(.smallScreen) & {
    display: flex;
    flex-direction: column;
  }
}

.side {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .base:not(.smallScreen) & {
    max-width: 40%;
    border-radius: 0 0 $radius 0;
    border-left: 1px solid $color-border;
  }

  .base:not(.mediumScreen):not(.smallScreen) & {
    width: $sidebarWidth;
  }

  .base.mediumScreen & {
    width: $sidebarWidthMedium;
  }

  .base.smallScreen & {
    border-radius: 0 0 $radius $radius;
    max-height: 100%;
    overflow-y: auto;
  }
}

.footer {
  background: white;
  border-radius: 0 0 $radius $radius;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .base:not(.smallScreen) & {
    padding: $spacing * 1.5;
    align-items: center;
    border-top: 1px solid $color-border;
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.side,
.footer {
  .base.smallScreen & {
    width: 100%;
    box-shadow: $shadow;
  }
}
.logout {
  margin-right: $spacing;
}

.alertHolder {
  .base:not(.smallScreen) & {
    padding: $spacing $spacing * 1.5;
  }

  .base.smallScreen & {
    padding: $spacing * 0.5 $spacing;
  }
}

.nextStepHolder {
  .base.smallScreen & {
    flex-grow: 1;
  }
}
.summary {
  padding: $spacing;
  width: 100%;
}
.planInfo {
  padding: $spacing * 1.5;

  .base.smallScreen & {
    padding: $spacing;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: $spacing;
}

.formRow {
  display: flex;
  width: 100%;
  gap: $spacing * 0.5;
  & > * {
    flex-grow: 1;
  }
}

.summaryItem {
  display: flex;
  gap: $spacing * 0.5;
  margin-bottom: $spacing;
}
</style>
