<template>
  <BaseGrid container>
    <BaseGrid v-if="$route.name !== 'rebook-reminder-template'">
      <BaseButton
        :routerLink="{ name: 'marketing-email' }"
        color="inverted"
        mr
        fullWidth
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
    </BaseGrid>
    <BaseGrid>
      <BaseButton
        :loading="isLoading"
        fullWidth
        @click="$emit('save')"
        v-test="'save-automated-email'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </BaseGrid>
  </BaseGrid>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['save']
});
</script>
