<template>
  <div :class="$style.base">
    <BaseText color="secondary" size="s">
      <div :class="$style.tipWrap">
        {{ $t('search.tips.heading') }}

        <div :class="$style.tips">
          <transition-group>
            <div v-if="currentTip === 0" :class="$style.tip">
              {{ $t('search.tips.tip1.first') }}
              <div :class="$style.button">
                <BaseIcon name="arrow-down" size="xs" />
              </div>
              <div :class="$style.button">
                <BaseIcon name="arrow-up" size="xs" />
              </div>
              {{ $t('search.tips.tip1.second') }}
              <div :class="$style.button">
                <BaseIcon name="arrow-corner-down-left" size="xs" />
              </div>
              {{ $t('search.tips.tip1.third') }}
            </div>

            <div v-if="currentTip === 1" :class="$style.tip">
              {{ $t('search.tips.tip2.first') }}
              <div v-if="OS === 'OSX'" :class="$style.button">CMD</div>
              <div v-else :class="$style.button">CTRL</div>
              +
              <div :class="$style.button">K</div>
              {{ $t('search.tips.tip2.second') }}
              <div :class="$style.button">ESC</div>
              {{ $t('search.tips.tip2.third') }}
            </div>

            <div v-if="currentTip === 2" :class="$style.tip">
              {{ $t('search.tips.tip3') }}
            </div>

            <div v-if="currentTip === 3" :class="$style.tip">
              {{ $t('search.tips.tip4') }}
            </div>
          </transition-group>
        </div>
      </div>
    </BaseText>
  </div>
</template>

<script setup lang="ts">
import { OS } from '@/user-context';

const totalTips = 4;
const currentTip = ref(Math.floor(Math.random() * 4));

setInterval(() => {
  if (currentTip.value === totalTips - 1) {
    currentTip.value = 0;
  } else {
    currentTip.value++;
  }
}, 7000);
</script>

<style lang="scss" module>
.base {
  border-top: 1px solid $color-border;
  padding: $spacing $spacing * 0.5 $spacing * 0.5;
  margin-top: $spacing * 0.5;
}

.button {
  border: 1px solid $color-border;
  border-radius: 4px;
  display: flex;
  padding: 1px 4px;
  font-size: 10px;

  & > * {
    margin: 0 -3px;
  }
}

.tip,
.tipWrap {
  display: flex;
  align-items: center;
  gap: $spacing * 0.25;
}

.tip {
  min-height: 18px;
  transition: opacity 0.5s;

  &:global(.v-leave-active) {
    position: absolute;
  }

  &:global(.v-leave-to),
  &:global(.v-enter-from) {
    opacity: 0;
  }
}
</style>
