<template>
  <FormElement
    v-bind="{ modelValue, label, required }"
    :minLength="length"
    @error="hasError = $event"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.hasError]: hasError
        }
      ]"
      v-test="'_base-code-input'"
    >
      <input
        v-for="(input, index) in inputValues"
        :key="index"
        :ref="skipUnwrap.inputElements"
        v-model="input.value"
        :class="$style.input"
        inputmode="numeric"
        type="text"
        @input="onInput(index)"
        @keydown="onKeydown(index, $event)"
        v-test="'_base-code-input-input'"
      />
    </div>
  </FormElement>
</template>

<script setup lang="ts">
import FormElement from '@/components/_shared/form-element/index.vue';
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    length?: number;
    modelValue: string;
    required?: boolean;
  }>(),
  {
    length: 6
  }
);

const emit = defineEmits(['update:modelValue']);

const inputElements = ref<HTMLInputElement[]>([]);
const skipUnwrap = { inputElements };

const inputValues = ref(
  Array.from({ length: props.length }, (x, i) => ({
    value: props.modelValue[i] || ''
  }))
);

const formattedInput = computed(() =>
  inputValues.value.map((input) => input.value).join('')
);
watch(formattedInput, (value) => {
  emit('update:modelValue', value);
});

const hasError = ref(false);

const onInput = (index) => {
  const inputValue = inputValues.value[index];

  // Make sure the input length stays at one
  if (inputValue.value.length > 1) {
    inputValue.value = inputValue.value.substring(0, 1);
  }

  // Automatically focus on the next field after an input
  // Also automatically select it, so that if it has a value the user can type a new value immediately
  if (index < inputElements.value.length - 1 && inputValue.value.length > 0) {
    inputElements.value[index + 1].focus();
    inputElements.value[index + 1].select();
  }
};

const onKeydown = (index, e) => {
  const inputValue = inputValues.value[index];

  // When pressing backspace on an empty field, focus on the previous field
  if (inputValue.value.length === 0 && e.key === 'Backspace' && index > 0) {
    inputElements.value[index - 1].focus();
  }
};

const paste = (e: any) => {
  const pastedText = e.clipboardData?.getData('Text');
  if (pastedText) {
    const text = pastedText.slice(0, props.length);

    inputValues.value.forEach((input, index) => {
      input.value = text[index];
    });

    nextTick(() => {
      inputElements.value.forEach((element) => element.blur());
    });
  }
};

onMounted(() => {
  window.addEventListener('paste', paste);
});

onUnmounted(() => {
  window.removeEventListener('paste', paste);
});
</script>

<style lang="scss" module>
.base {
  display: flex;
}

.input {
  @include input;
  width: 42px;
  text-align: center;
  font-size: 22px;

  &:not(:last-child) {
    margin-right: $spacing * 0.5;
  }

  .base.hasError & {
    border-color: $color-error;
  }
}
</style>
