import router from '@/router';
import config from '@/config';

import ComponentBase from './plugins/ComponentBase';
import Intercom from './plugins/Intercom';
import Mixpanel from './plugins/Mixpanel';
import { createGtm } from '@gtm-support/vue-gtm';
import VueSignaturePad from 'vue-signature-pad';
import VueSafeTeleport from 'vue-safe-teleport';

export default (app: any) => {
  app.use(ComponentBase);
  app.use(VueSignaturePad);
  app.use(VueSafeTeleport);

  app.use(Intercom, {
    intercomId: config.intercomId
  });

  if (config.gtmId) {
    app.use(
      createGtm({
        id: config.gtmId,
        source: config.gtmUrl,
        vueRouter: router
      })
    );
  }

  app.use(Mixpanel, {
    token: config.mixpanelToken
  });
};
