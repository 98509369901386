import config from '@/config';

export default {
  test:
    config.isDevelopment || config.isTest
      ? {
          beforeMount(el: HTMLElement, binding: any) {
            el.dataset.test = binding.value;
          },
          updated(el: HTMLElement, binding: any) {
            el.dataset.test = binding.value;
          }
        }
      : {},
  intercom: {
    beforeMount(el: HTMLElement, binding: any) {
      el.dataset.intercomId = binding.value;
    },
    updated(el: HTMLElement, binding: any) {
      el.dataset.intercomId = binding.value;
    }
  }
};
