import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { injectUserAccounts } from './injectUserAccounts';

const GET_USERS_QUERY = gql`
  query getUsers {
    users {
      id
      firstName
      lastName
      owner
      admin
      manager
      reports
      basic
      email
    }
  }
`;

export const useUserAccounts = () => {
  const { updateUserAccounts } = injectUserAccounts();
  const {
    loading,
    error,
    onResult,
    refetch: refetchQuery
  } = useQuery(GET_USERS_QUERY);

  const handleResult = ({ data: { users } }) => {
    updateUserAccounts(users);
  };

  onResult(handleResult);

  const refetch = () => {
    refetchQuery()?.then(handleResult);
  };

  return {
    loading,
    error,
    refetch
  };
};
