<template>
  <BaseModal
    v-if="showModal"
    :heading="$t('treatwell.ultimatum.header_days', { days }, days)"
    testId="treatwell-ultimatum"
    @close="dismiss"
  >
    <div :class="$style.description">
      <div>
        <BaseHeading mb>
          {{ $t('treatwell.ultimatum.description') }}
        </BaseHeading>
        <BaseText mb>
          {{ $t('treatwell.ultimatum.disclaimer') }}
        </BaseText>
      </div>
      <img
        v-if="$screen !== 's'"
        :class="$style.cardImage"
        src="/img/treatwell/costs.svg"
      />
    </div>
    <BaseHeading size="s" mb>
      {{ $t('admin.treatwell.stats.generic_heading') }}
    </BaseHeading>
    <Stats :dateRange="DateRange.Year" hideLabels gray mb />
    <template #footer>
      <BaseButton
        :routerLink="{
          name: 'treatwell-sign-contract'
        }"
        @click="dismiss"
        v-test="'ultimatum-submit'"
      >
        {{ $t('treatwell.ultimatum.button') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { DateRange } from '@/types';
import Stats from '../Stats.vue';
import { computed } from 'vue';
import { useTreatwellStore } from '@/stores/treatwell';
import dayjs from '@/dayjs';
import { useStorage } from '@vueuse/core';

const days = computed(() => {
  const { treatwellStatus } = useTreatwellStore();
  return treatwellStatus.freeCommissionRemainingDays;
});

const dismissedAt = useStorage('twUltimatumDismissedAt', '');

const showModal = computed(() => {
  const { showTreatwell, treatwellStatus } = useTreatwellStore();

  return (
    showTreatwell &&
    treatwellStatus.optOutCampaign &&
    !treatwellStatus.isPastFreeCommissionPeriod &&
    !treatwellStatus.contractSigned &&
    days.value <= 2 &&
    days.value >= 0 &&
    (!dismissedAt.value ||
      dayjs().subtract(3, 'hour').isAfter(dismissedAt.value))
  );
});

const dismiss = () => (dismissedAt.value = dayjs().format());
</script>

<style lang="scss" module>
.description {
  display: flex;
}

.cardImage {
  width: 160px;
  padding: $spacing 0;
}
</style>
