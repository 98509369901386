<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <TemplateVariables
      v-if="showVariablesModal"
      @close="showVariablesModal = false"
    />
    <PageSplit>
      <template #left>
        <BaseForm
          fullHeight
          :scrollContainer="scrollContainer"
          @submit="submitForm"
          @validationError="onValidationError"
        >
          <div :class="$style.formContainer">
            <div ref="scrollContainer" :class="$style.formContent">
              <BaseHeading size="l" mb>{{
                $t('admin.invoices.title')
              }}</BaseHeading>
              <BaseAccordion
                v-model="activeAccordionItems"
                :titles="[
                  $t('admin.invoices.permissions.title'),
                  $t('admin.invoices.content.title'),
                  $t('admin.invoices.datev.title')
                ]"
                :routeQueries="[
                  'permissions',
                  'invoice-content',
                  'date-settings'
                ]"
                mb
                @toggle="onSectionToggle"
              >
                <template #1>
                  <BaseCheckbox
                    v-model="formData.settings.sales.showVatOnInvoice"
                    :label="$t('admin.invoices.permissions.show_vat_amount')"
                    :mb="1.5"
                    v-test="'showVatOnInvoice'"
                  />
                  <BaseCheckbox
                    v-model="formData.settings.sales.showTreatmentDateOnInvoice"
                    :label="
                      $t('admin.invoices.permissions.show_treatment_date')
                    "
                    :mb="1.5"
                    v-test="'showTreatmentDateOnInvoice'"
                  />
                  <BaseCheckbox
                    v-model="formData.settings.sales.thermalSupport"
                    :label="
                      $t('admin.invoices.permissions.support_thermal_printer')
                    "
                    :mb="1.5"
                    v-test="'thermalSupport'"
                  />
                  <BaseCheckbox
                    v-model="formData.settings.sales.printNextAppointment"
                    :disabled="!formData.settings.sales.thermalSupport"
                    :label="
                      $t(
                        'admin.invoices.permissions.print_date_of_next_apppintment'
                      )
                    "
                    :mb="1.5"
                    v-test="'printNextAppointment'"
                  />
                  <BaseAlert
                    v-if="!hasPaymentProvider"
                    :text="$t('admin.invoices.permissions.payment_link_alert')"
                    color="warning"
                    mb
                    :primaryAction="{
                      text: $t('nav.integrations'),
                      routerLink: {
                        name: 'integrations'
                      }
                    }"
                    v-test="'payment-provider-alert'"
                  />
                  <BaseCheckbox
                    v-model="formData.settings.sales.paymentLink"
                    :disabled="!hasPaymentProvider"
                    :label="$t('admin.invoices.permissions.payment_link')"
                    :mb="1.5"
                    v-test="'paymentLink'"
                  />
                </template>
                <template #2>
                  <div>
                    <BaseText inline>{{
                      $t('admin.invoices.content.logo_description')
                    }}</BaseText>
                    <BaseText link mb :routerLink="{ name: 'admin-company' }">
                      {{ $t('admin.invoices.content.logo_description_link') }}
                    </BaseText>
                  </div>

                  <BaseInputLabel
                    :text="$t('admin.invoices.image')"
                    :info="$t('admin.invoices.image_description')"
                  />
                  <BaseUpload
                    preset="company_logo"
                    :mb="1.5"
                    :text="$t('global.actions.upload')"
                    :image="currentImageUrl"
                    withImagePreview
                    withDelete
                    @uploadResults="handleImageUpload"
                    @delete="handleImageDelete"
                  />

                  <div>
                    <BaseInput
                      v-model="formData.invoiceHeader"
                      type="textarea"
                      mb
                      :info="$t('admin.invoices.content.header_description')"
                      :label="$t('admin.invoices.content.header_title')"
                      v-test="'invoiceHeader'"
                    />
                    <BaseInput
                      v-model="formData.invoiceRecipientTemplate"
                      type="textarea"
                      mb
                      :label="
                        $t('admin.invoices.content.customer_information_title')
                      "
                      v-test="'invoiceRecipientTemplate'"
                    />
                    <BaseInput
                      v-model="formData.invoiceFooter"
                      type="textarea"
                      mb
                      :label="$t('admin.invoices.content.footer_title')"
                      v-test="'invoiceFooter'"
                    />
                    <BaseInput
                      v-if="formData.settings.sales.thermalSupport"
                      v-model="formData.invoiceFooterThermal"
                      type="textarea"
                      mb
                      :label="$t('admin.invoices.content.footer_thermal_title')"
                      v-test="'invoiceFooterThermal'"
                    />
                  </div>
                  <BaseText link @click="showVariablesModal = true">
                    {{ $t('admin.invoices.content.help_link') }}
                  </BaseText>
                </template>
                <template v-if="company.country === 'de'" #3>
                  <BaseText mb>{{
                    $t('admin.invoices.datev.description')
                  }}</BaseText>
                  <div :class="$style.formRow">
                    <BaseInput
                      v-model="formData.settings.datev.billsReceivableAccount"
                      type="number"
                      :label="
                        $t('admin.invoices.datev.bills_receivable_account')
                      "
                      v-test="'billsReceivableAccount'"
                    />
                    <BaseInput
                      v-model="formData.settings.datev.deferredRevenueAccount"
                      type="number"
                      :label="
                        $t('admin.invoices.datev.deferred_revenue_account')
                      "
                      v-test="'deferredRevenueAccount'"
                    />
                  </div>
                  <div :class="$style.formRow">
                    <BaseInput
                      v-model="formData.settings.datev.cashAccount"
                      type="number"
                      :label="$t('admin.invoices.datev.cash_account')"
                      v-test="'cashAccount'"
                    />
                    <BaseInput
                      v-model="formData.settings.datev.bankAccount"
                      type="number"
                      :label="$t('admin.invoices.datev.bank_account')"
                      v-test="'bankAccount'"
                    />
                  </div>
                  <div :class="$style.formRow">
                    <BaseInput
                      v-model="formData.settings.datev.revenueAccount"
                      type="number"
                      :label="$t('admin.invoices.datev.revenue_account')"
                      v-test="'revenueAccount'"
                    />
                  </div>
                </template>
              </BaseAccordion>
            </div>
            <div :class="$style.formControls">
              <BaseButton
                :fullWidth="$screen === 's'"
                :loading="isLoading"
                submitForm
                v-test="'submitForm'"
              >
                {{ $t('global.actions.save') }}
              </BaseButton>
            </div>
          </div>
        </BaseForm>
      </template>
      <template #right>
        <Preview :formData="formData" />
      </template>
    </PageSplit>
  </div>
</template>

<script lang="ts" setup>
import type { CloudinaryResponse } from '@/components/base-upload/index.vue';
import { useI18n } from 'vue-i18n';
import { useInvoices } from './useInvoices';
import { setScrollOnElement } from '@/helpers/scroll';
import Preview from './Preview.vue';
import { useCompanyStore } from '@/stores/company';
import { flash } from '@/helpers/ui';
import PageSplit from '@/modules/PageSplit.vue';
import TemplateVariables from './TemplateVariables.vue';

const { formData, currentImageUrl, hasPaymentProvider } = useInvoices();
const { company, updateCompany } = useCompanyStore();
const { t } = useI18n();

const scrollContainer = ref();
const activeAccordionItems = ref<string[]>(['1']);
const isLoading = ref(false);
const showVariablesModal = ref(false);

const handleImageUpload = (uploadResults: CloudinaryResponse) => {
  formData.invoiceImage = `${uploadResults.resource_type}/${uploadResults.type}/${uploadResults.public_id}.${uploadResults.format}`;
  currentImageUrl.value = uploadResults.secure_url;
};

const handleImageDelete = () => {
  formData.invoiceImage = '';
  currentImageUrl.value = '';
};

const onSectionToggle = (element: HTMLElement) => {
  nextTick(() => {
    if (activeAccordionItems.value.length && element) {
      setScrollOnElement(element);
    }
  });
};

const submitForm = () => {
  isLoading.value = true;
  updateCompany(formData)
    .then(() => {
      flash(t('global.flash.company_updated'));
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const onValidationError = () => {
  activeAccordionItems.value = ['1', '2', '3'];
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.formContent {
  flex-grow: 1;
  overflow-y: scroll;
}

.formControls {
  display: flex;
  justify-content: flex-end;
  padding: $spacing;
  background-color: white;
  border-top: 1px solid $color-border;

  .base.smallScreen & {
    padding: $spacing 0;
  }
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
    margin-bottom: $spacing;
  }

  .base.smallScreen & {
    & > * {
      width: 100%;
    }
  }
}
</style>
