<template>
  <BaseModal
    :heading="$t('register.salonized_pay.onboarding.heading')"
    @close="$emit('close')"
    v-test="'sp-onboarding-modal'"
  >
    <div v-test="'sp-generating-link'">
      <div v-if="!generationFailed" :class="$style.base">
        <BaseSpinner inline />
        <BaseHeading :mb="0.5" mt>
          {{
            $t(
              'register.salonized_pay.onboarding.link_modal.generation_heading'
            )
          }}
        </BaseHeading>
        <BaseText mb>
          {{
            $t(
              'register.salonized_pay.onboarding.link_modal.generation_description'
            )
          }}
        </BaseText>
        <BaseButton color="inverted" @click="$emit('close')">
          {{ $t('global.actions.cancel') }}
        </BaseButton>
      </div>

      <div
        v-if="generationFailed"
        :class="$style.base"
        v-test="'sp-generation-failed'"
      >
        <img src="/img/salonized-pay/onboarding/link_generation_failed.svg" />
        <BaseHeading :mb="0.5" mt>
          {{ $t('register.salonized_pay.onboarding.link_modal.error_heading') }}
        </BaseHeading>
        <BaseText mb>
          {{
            $t('register.salonized_pay.onboarding.link_modal.error_description')
          }}
        </BaseText>
        <BaseText mb color="error">
          {{ generationErrorMessage }}
        </BaseText>
        <div :class="$style.buttons">
          <BaseButton @click="generateLink" v-test="'sp-retry-generating-link'">
            {{ $t('register.salonized_pay.onboarding.link_modal.error_retry') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import { GENERATE_ADYEN_ONBOARDING_LINK } from './graphql';
import { useMutation } from '@vue/apollo-composable';
import { useAdyenStore } from '@/stores/adyen';
import { AdyenAppVerificationStatus } from '@/types';
import config from '@/config';

const emit = defineEmits(['close']);

const generationFailed = ref(false);
const generationErrorMessage = ref('');
const { adyenAppState } = useAdyenStore();

const { mutate } = useMutation(GENERATE_ADYEN_ONBOARDING_LINK);

const generateLink = () => {
  mutate({ input: {} }).then((result: any) => {
    const url = result.data.generateAdyenOnboardingLink?.onboardingLink.url;
    if (url) {
      adyenAppState.verificationStatus = AdyenAppVerificationStatus.Pending;

      if (!config.isTest) {
        window.location.href = url;
      }

      emit('close');
    } else {
      generationFailed.value = true;
      generationErrorMessage.value =
        result.data.generateAdyenOnboardingLink?.errors?.message || '';
    }
  });
};

generateLink();
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
