<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.accounts')">
      <template #header>
        <BaseButton
          :routerLink="{ name: 'admin-account-new' }"
          v-test="'user-accounts-btn-add'"
        >
          {{ $t('admin.user_accounts.add_account_button_text') }}
        </BaseButton>
      </template>
      <UserAccounts :mb="2" />
      <UserLogs />
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts" setup>
import ModuleBase from '@/modules/ModuleBase.vue';
import UserAccounts from './UserAccounts.vue';
import UserLogs from './UserLogs.vue';
import { provideUserAccounts } from './useUserAccounts';
import PageWrap from '../PageWrap.vue';

provideUserAccounts();
</script>
