<template>
  <BaseCard :heading="$t('cashups.vat_overview')">
    <BaseTable
      v-if="summary && summary.length"
      :headers="[
        $t('cashups.vat_rate'),
        $t('cashups.turnover'),
        $t('cashups.vat_amount')
      ]"
      :rows="
        summary.map((v) => ({
          cells: [
            v.percentage !== null
              ? `${v.percentage.toFixed(1)}%`
              : $t('cashups.no_vat_relieved'),
            filters.currency(v.priceExcl),
            filters.currency(v.unitVat)
          ]
        }))
      "
      v-test="'vat-overview-rows'"
    />
    <BaseText v-else v-test="'no-vat'">
      {{ $t('cashups.no_vat') }}
    </BaseText>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    summary: {
      type: Array
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>
