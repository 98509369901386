<template>
  <div>
    <div :class="$style.mainCard">
      <BaseCard>
        <BaseHeading size="xl" mb>
          {{ $t('treatwell.title') }}
        </BaseHeading>
        <BaseText size="l" :mb="2">
          {{ $t('treatwell.info') }}
        </BaseText>
        <div v-if="treatwellVenueTypes" :class="$style.infoTop">
          <div>
            <BaseHeading size="s" mb>
              {{ $t('treatwell.type.salonized') }}
            </BaseHeading>
            <BaseText v-test="'tw-salon-type'">
              {{ company.companyType }}
            </BaseText>
          </div>
          <div>
            <BaseHeading size="s" mb>
              {{ $t('treatwell.type.treatwell') }}
            </BaseHeading>
            <BaseDropdown
              v-model="venueType"
              :options="
                treatwellVenueTypes.map((t: string) => ({
                  value: t,
                  label: t
                }))
              "
              v-test="'tw-venue-types'"
            />
          </div>
          <div v-if="shouldEnable && !freeCommissionEndDate">
            <BaseHeading size="s" mb :mr="2">
              {{ $t('treatwell.free_period.heading') }}
            </BaseHeading>
            <BaseRadio
              v-model="freeCommissionPeriod"
              :options="
                [0, 1, 2, 3].map((value) => ({
                  label: `${value} ${$t('global.items.month', value)}`,
                  value
                }))
              "
            />
          </div>
          <div v-else>
            <BaseHeading size="s" mb :mr="2">
              {{ $t('treatwell.free_end_date.heading') }}
            </BaseHeading>
            <BaseText>
              <div v-if="freeCommissionEndDate">
                {{ filters.date(freeCommissionEndDate) }}
              </div>
              <div v-else>Not specified</div>
            </BaseText>
          </div>
        </div>
        <BaseGrid mt :size="gridSize" :mSize="12">
          <BaseHeading mb>
            {{ $t('treatwell.bank_details.header') }}
          </BaseHeading>
          <BaseCard>
            <BaseText mb>{{
              $t('treatwell.bank_details.description')
            }}</BaseText>
            <BaseGrid container>
              <BaseGrid :size="3">
                <BaseInput
                  v-model="accountHolder"
                  :label="$t('treatwell.bank_details.account_holder')"
                  size="s"
                  v-test="'treatwell-account-holder-input'"
                />
              </BaseGrid>
              <BaseGrid :size="3">
                <BaseInput
                  v-model="bankName"
                  :label="$t('treatwell.bank_details.bank_name')"
                  size="s"
                  v-test="'treatwell-bank-name-input'"
                />
              </BaseGrid>
              <BaseGrid :size="4">
                <BaseInput
                  v-model="accountNumber"
                  :label="$t('treatwell.bank_details.account_number')"
                  size="s"
                  v-test="'treatwell-account-number-input'"
                />
              </BaseGrid>
              <BaseGrid :size="2">
                <BaseInput
                  v-model="bankCode"
                  :label="$t('treatwell.bank_details.bank_code')"
                  size="s"
                  v-test="'treatwell-bank-code-input'"
                />
              </BaseGrid>
            </BaseGrid>
          </BaseCard>
        </BaseGrid>
        <BaseHeading size="s" :mt="2" mb>
          {{ $t('treatwell.images.title') }}
        </BaseHeading>
        <BaseText mb>
          {{ $t('treatwell.images.description') }}
        </BaseText>
        <BaseUpload
          preset="tw-venue-images"
          multiupload
          showSupport
          dropfield
          @uploadResults="onUpload"
        />
        <BaseImageGallery v-model="images" remove />
        <BaseInput
          v-model="description"
          :mt="2"
          :mb="2"
          :label="$t('treatwell.description')"
          type="textarea"
          v-test="'tw-description'"
        />
        <div :class="$style.footer">
          <BaseAlert
            v-if="showError"
            color="warning"
            :text="$t('treatwell.bank_details.error')"
            mb
          />
          <div>
            <BaseButton
              color="inverted"
              mr
              @click="$emit('backToIntegrations')"
            >
              {{ $t('global.back') }}
            </BaseButton>
            <BaseButton
              :loading="isLoading"
              @click="$emit('submit', submitParameters)"
              v-test="'tw-save'"
            >
              {{
                shouldEnable
                  ? $t('global.actions.enable')
                  : $t('global.actions.save')
              }}
            </BaseButton>
          </div>
        </div>
      </BaseCard>
      <BaseCard mt>
        <ServiceMapping />
      </BaseCard>
    </div>
  </div>
</template>

<script lang="ts">
import gql from 'graphql-tag';
import ServiceMapping from './service-mapping/index.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import type {
  VenueData,
  TreatwellBankingDetails,
  VenueFormData
} from './types';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import filters from '@/filters';

export default defineComponent({
  components: {
    ServiceMapping
  },
  props: {
    showError: Boolean,
    formData: {
      type: Object as PropType<VenueData>,
      required: true
    },
    shouldEnable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    gridSize: {
      type: Number,
      required: false,
      default: 12
    },
    freeCommissionEndDate: {
      type: String,
      required: false
    }
  },
  emits: ['backToIntegrations', 'submit'],
  setup() {
    return { filters };
  },
  data() {
    return {
      description: this.formData.description,
      images: this.formData.images,
      venueType: this.formData.venueType,
      accountHolder: this.formData.accountHolder,
      bankName: this.formData.bankName,
      accountNumber: this.formData.accountNumber,
      bankCode: this.formData.bankCode,
      freeCommissionPeriod: this.formData.freeCommissionPeriod
    };
  },
  apollo: {
    treatwellVenueTypes: {
      query: gql`
        query getTreatwellVenueTypes {
          treatwellVenueTypes
        }
      `
    }
  },
  computed: {
    ...mapState(useCompanyStore, ['company']),
    submitParameters(): VenueFormData {
      return {
        description: this.description,
        images: this.images,
        venueType: this.venueType,
        bankingDetails: this.bankingDetails(),
        freeCommissionPeriod: this.freeCommissionPeriod
      };
    }
  },
  methods: {
    onUpload(file: any) {
      this.images.push(file.public_id);
    },

    bankingDetails(): TreatwellBankingDetails | null {
      if (
        this.accountHolder &&
        this.accountNumber &&
        this.bankName &&
        this.bankCode
      ) {
        return {
          accountHolderName: this.accountHolder,
          accountNumber: this.accountNumber,
          bankName: this.bankName,
          bankCode: this.bankCode
        };
      } else {
        return null;
      }
    }
  }
});
</script>
<style lang="scss" module>
.infoTop {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 2;
}

.mainCard {
  margin: auto;
}
</style>
