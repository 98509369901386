<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="3">
        <BaseDropdown
          v-model="selectedStatus"
          :options="filterOptions"
          v-test="'stock-order-filter'"
        />
      </BaseGrid>
      <BaseGrid :size="3">
        <BaseSearch debounce @update:modelValue="$emit('search', $event)" />
      </BaseGrid>
      <BaseGrid alignRight>
        <BaseButton
          :routerLink="{ name: 'products-order-new' }"
          v-test="'createStockOrder'"
        >
          {{ $t('global.actions.new_stock_order') }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ['search', 'update:modelValue', 'filter'],
  computed: {
    selectedStatus: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    filterOptions() {
      return [
        {
          value: 'all',
          label: this.$t('global.all')
        },
        {
          value: 'open',
          label: this.$t('global.open')
        },
        {
          value: 'ordered',
          label: this.$t('global.ordered')
        },
        {
          value: 'complete',
          label: this.$t('global.completed')
        },
        {
          value: 'paid',
          label: this.$t('global.paid')
        },
        {
          value: 'backorder',
          label: this.$t('global.backorder')
        }
      ];
    }
  },
  methods: {
    onFilter(value) {
      const status = value.id !== 'all' ? value.id : null;
      this.$emit('filter', status);
    }
  }
});
</script>
