<template>
  <input
    ref="inputRef"
    :class="[
      $style.input,
      $style[size],
      {
        [$style.isDisabled]: disabled
      }
    ]"
    type="text"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    @onChange="$emit('change', $event)"
    v-test="'_base-input-input'"
  />
</template>

<script lang="ts">
import { useCurrencyInput } from 'vue-currency-input';
import { watch, defineComponent, onMounted, nextTick } from 'vue';
import { useCompanyStore } from '@/stores/company';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: Number,
    inputModeText: {
      Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'm'
    }
  },
  emits: ['update:modelValue', 'change', 'blur', 'focus'],
  setup(props) {
    const { company } = useCompanyStore();

    const { inputRef, setValue } = useCurrencyInput({
      currency: company?.currency || 'EUR',
      locale: 'nl',
      precision: 2,
      valueScaling: 'precision'
    });

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    onMounted(() => {
      nextTick(() => {
        if (props.inputModeText && inputRef.value) {
          inputRef.value.setAttribute('inputmode', 'text');
        }
      });
    });

    const onBlur = () => {
      // When clearing the input field, the value should be set to 0
      if (!inputRef.value.value) {
        setValue(0);
      }
    };

    return { inputRef, onBlur };
  }
});
</script>

<style lang="scss" module>
.input {
  @include input;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.l {
    font-size: 26px;
    height: 52px;
  }
}
</style>
