<template>
  <BaseModalLarge
    :heading="$t('products.update_product_prices')"
    :parentRoute="parentRouteName"
    testId="update-prices"
  >
    <Schedule
      v-if="showScheduleModal"
      @close="showScheduleModal = false"
      @submit="submitSchedule"
    />
    <BaseCard>
      <BaseTable
        v-if="productData.length > 0"
        :headers="[
          $t('products.product_name'),
          $t('products.current_cost'),
          $t('products.new_cost'),
          $t('products.current_sell'),
          $t('products.new_sell')
        ]"
        :rows="
          productData.map((product: any) => ({
            id: product.id,
            cells: [
              product.name,
              product.costPrice,
              product.newCostPrice,
              product.price,
              product.newSellPrice
            ]
          }))
        "
        @valueChange="valueChange"
        v-test="'updatePricesTable'"
      />
      <div
        v-if="productData.length < 1 && !loading"
        :class="$style.alert"
        v-test="'noProductsAlert'"
      >
        <img :class="$style.image" src="/img/empty-states/products.svg" />
        <BaseText mt>
          {{ $t('global.no_results') }}
        </BaseText>
      </div>
      <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
    </BaseCard>

    <template #footer>
      <BaseButton
        color="inverted"
        :disabled="isSaving || !productDataChanged"
        @click="onScheduleButtonClick"
        v-test="'schedulePricesButton'"
      >
        {{ $t('products.schedule_prices') }}
      </BaseButton>
      <BaseButton
        :loading="isSaving"
        :disabled="!productDataChanged"
        @click="onSubmitButtonClick"
        v-test="'updatePricesNowButton'"
      >
        {{ $t('products.update_now') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
import { flash } from '@/helpers/ui';
export default {
  name: 'UpdateProducts'
};
</script>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ref, inject } from 'vue';
import type { Ref } from 'vue';
import filters from '@/filters';
import { useRoute, useRouter } from 'vue-router';
import Schedule from './Schedule.vue';
import { useCompanyStore } from '@/stores/company';
import { useI18n } from 'vue-i18n';
import { usePagination } from '@/apollo/pagination';
import LoadMore from '@/components/LoadMore.vue';

defineProps<{
  parentRouteName: string;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const mixpanel = inject<any>('mixpanel');

const supplierId = parseInt(route.params.supplierId as string);
const productData: Ref<{}[]> = ref([]);
const productDataChanged = ref(false);
const showScheduleModal = ref(false);
let scheduledAt: string | null = null;

const { loading, onResult, allDataFetched, fetchMore } = usePagination({
  fieldName: 'products',
  query: gql`
    query getProducts(
      $supplierIds: [Int!]
      $pagination: PaginationAttributes!
      $showNullSupplier: Boolean
    ) {
      products(
        supplierIds: $supplierIds
        pagination: $pagination
        showNullSupplier: $showNullSupplier
      ) {
        category {
          id
          amountProducts
          color
          name
        }
        name
        price
        costPrice
        id
        productId
      }
    }
  `,
  variables: {
    supplierIds: supplierId === 0 ? [] : [supplierId],
    showNullSupplier: supplierId === 0 ? true : false
  }
});

const valueChange = () => {
  productDataChanged.value = true;
};

onResult(({ data: { products } }) => {
  productData.value = products.map((p: any) => {
    const existing = productData.value.find(
      (product: any) => product.id === p.id
    );

    return (
      existing || {
        ...p,
        costPrice: filters.currency(p.costPrice),
        originalCostPrice: p.costPrice,
        newCostPrice: {
          editable: true,
          value: p.costPrice ? p.costPrice : 0,
          type: 'currency'
        },
        price: filters.currency(p.price),
        originalSellPrice: p.price,
        newSellPrice: {
          editable: true,
          value: p.price ? p.price : 0,
          type: 'currency'
        }
      }
    );
  });
});

const { companyId } = useCompanyStore();

const {
  mutate,
  onDone,
  loading: isSaving
} = useMutation(
  gql`
    mutation createProductChangeSchedule(
      $input: CreateProductChangeScheduleInput!
    ) {
      createProductChangeSchedule(input: $input) {
        clientMutationId
        errors
        productChangeSchedule {
          changeAppliedAt
          changeRevertedAt
          id
          scheduledAt
        }
      }
    }
  `,
  () => ({
    variables: {
      input: {
        supplierId: supplierId || null,
        companyId,
        scheduledAt,
        productChangesAttributes: productData.value.map((p: any) => ({
          productId: p.id,
          originalCostPrice: parseInt(
            p.costPrice.substring(2).split(',').join('')
          ),
          originalPrice: parseInt(p.price.substring(2).split(',').join('')),
          newCostPrice: p.newCostPrice.value,
          newPrice: p.newSellPrice.value
        }))
      }
    },
    update: (cache) => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'products' });
      cache.gc();
    }
  })
);

const onScheduleButtonClick = () => {
  showScheduleModal.value = true;
  mixpanel.track('Click - Product Prices Schedule Modal');
};

const onSubmitButtonClick = () => {
  mutate();
  mixpanel.track('Click - Product Prices Update');
};

const submitSchedule = (dateTime: string) => {
  scheduledAt = dateTime;
  mutate();
  mixpanel.track('Click - Product Prices Set Schedule');
};

onDone(() => {
  router.push({ name: scheduledAt ? 'update-product-prices' : 'products' });
  flash(
    `${t(
      `${
        scheduledAt
          ? 'global.flash.changes_scheduled'
          : 'products.products_updated'
      }`
    )}`
  );
});
</script>

<style lang="scss" module>
.base {
  width: 100%;
}
.alert {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: $spacing;
  margin-bottom: $spacing;
}

.image {
  width: 250px;
  max-width: 50%;
  max-height: 250px;
}
</style>
