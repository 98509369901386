<template>
  <div
    :class="[
      $style.base,
      {
        [$style.customerColor]: !!color
      }
    ]"
  >
    <div :class="$style.inner">
      <div :class="$style.circle">
        <svg :class="$style.svg" width="100" height="100" viewBox="0 0 100 100">
          <circle
            :class="$style.background"
            cx="50"
            cy="50"
            r="45"
            stroke-width="6"
          />
          <circle
            :class="$style.progress"
            cx="50"
            cy="50"
            r="45"
            :stroke="color"
            stroke-width="10"
            :stroke-dasharray="dashArray"
            :stroke-dashoffset="dashOffset"
          />
        </svg>
      </div>
      <div v-if="!hidePercentage" :class="$style.percentage">
        {{ percentage }}%
      </div>
    </div>
    <div v-if="label" :class="$style.label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    label: {
      type: String
    },
    color: {
      type: String
    },
    hidePercentage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dashArray: Math.round(Math.PI * 90)
    };
  },
  computed: {
    dashOffset() {
      return this.dashArray * (1 - this.percentage / 100);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  width: 100%;
}

.inner {
  position: relative;
  padding-bottom: 100%;
}

.percentage,
.circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.percentage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  fill: none;
  transform: rotate(-90deg);
}

.svg {
  width: 100%;
  height: 100%;
}

.background {
  stroke: #e8e8e8;
}

.progress {
  stroke-linecap: round;

  .base:not(.customerColor) & {
    stroke: $color-primary;
  }
}

.label {
  margin-top: $spacing;
  text-align: center;
}
</style>
