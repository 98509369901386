<template>
  <div :class="$style.base">
    <div v-if="!emailSent">
      <BaseHeading size="xl" mb center>
        {{ $t('login.forgot_password') }}
      </BaseHeading>
      <BaseText :mb="2" center>
        {{ $t('forgot_password.description') }}
      </BaseText>
      <BaseForm :mb="2" @submit="onSubmit">
        <BaseInput
          v-model="email"
          type="email"
          name="email"
          required
          mb
          v-test="'emailInput'"
        />
        <BaseButton
          :loading="isLoading"
          mr
          size="l"
          fullWidth
          submitForm
          v-test="'submit'"
        >
          {{ $t('forgot_password.reset_password') }}
        </BaseButton>
      </BaseForm>
      <BaseText :routerLink="{ name: 'login' }" v-test="'backToLoginButton'">
        {{ $t('forgot_password.back_to_login') }}
      </BaseText>
    </div>
    <div v-else>
      <BaseHeading size="xl" center mb>
        {{ $t('forgot_password.check_inbox') }}
      </BaseHeading>
      <BaseText :mb="2" center v-test="'emailSentInfo'">
        {{ $t('forgot_password.email_info', { email }) }}
      </BaseText>
      <BaseButton :routerLink="{ name: 'login' }" v-test="'backToLoginButton'">
        {{ $t('forgot_password.back_to_login') }}
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import axios from '@/axios';

import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      emailSent: false,
      isLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.sendResetPasswordEmail();
      }
    },
    sendResetPasswordEmail() {
      this.isLoading = true;
      axios
        .get('/passwords/lost_password', { params: { email: this.email } })
        .then(() => {
          this.emailSent = true;
          this.isLoading = false;
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  text-align: center;
}
</style>
