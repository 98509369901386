<template>
  <BaseCard :heading="filters.capitalize($t('global.items.note', 2))">
    <CreateNoteModal
      v-if="showNoteModal"
      :currentNote="stockOrder.notes"
      @submit="updateNote($event, 'edit')"
      @close="showNoteModal = false"
    />
    <div :class="$style.inner">
      <BaseText mb>
        {{ stockOrder.notes }}
      </BaseText>
      <BaseMore :options="['edit', 'delete']" @select="onOptionSelect" />
    </div>
  </BaseCard>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import UpdateNoteMixin from './UpdateNoteMixin';
import CreateNoteModal from '@/modals/CreateNote.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CreateNoteModal
  },
  mixins: [UpdateNoteMixin],
  props: {
    stockOrder: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      showNoteModal: false
    };
  },
  methods: {
    onOptionSelect(option) {
      if (option === 'edit') {
        this.showNoteModal = true;
      }

      if (option === 'delete') {
        modal('confirmation').then(() => {
          this.updateNote('');
        });
      }
    }
  }
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: space-between;
}

.width {
  width: 90%;
}
</style>
