<template>
  <div
    :class="[
      $style.base,
      $style[size],
      [$style['color-' + color]],
      {
        [$style.border]: border,
        [$style.noWrap]: noWrap,
        [$style.center]: center,
        [$style.inline]: inline
      }
    ]"
  >
    <BaseIcon v-if="icon" :name="icon" :mr="0.25" />
    <slot />
    <div v-if="info" :class="$style.info">
      <BaseIcon
        name="info"
        size="s"
        :tooltip="{
          text: info,
          touch: true
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
import type { IconName } from '@/components/base-icon/types';

withDefaults(
  defineProps<{
    color?: 'default' | 'inverted';
    border?: boolean;
    center?: boolean;
    icon?: IconName;
    info?: string;
    inline?: boolean;
    noWrap?: boolean;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  }>(),
  {
    color: 'default',
    size: 'm'
  }
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;

  &.xs,
  &.s {
    text-transform: uppercase;
    letter-spacing: 0.05em;

    &.color-default {
      color: rgba(56, 53, 64, 0.6);
    }
  }

  &.xs {
    font-size: 10px;
  }

  &.s {
    font-size: 12px;
  }

  &.m,
  &.l,
  &.xl {
    font-weight: bold;
  }

  &.m {
    font-size: 16px;
  }

  &.l {
    font-size: 20px;
  }

  &.xl {
    font-size: 24px;
  }

  &.color-inverted {
    color: white;
  }

  &.inline {
    display: inline-block;
  }

  &.border {
    padding-bottom: $spacing;
    border-bottom: 1px solid $color-border;
  }

  &.noWrap {
    white-space: nowrap;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }
}

.info {
  display: inline-block;
  margin-top: -5px;
  position: relative;
  top: 3px;
  margin-left: $spacing * 0.25;
}
</style>
