<template>
  <div :class="$style.base">
    <FilterLabel
      v-for="(filter, index) in filters"
      :key="index"
      :filter="filter"
      @close="onRemoveFilter(filter)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FilterLabel from './FilterLabel.vue';

export default defineComponent({
  components: {
    FilterLabel
  },
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  emits: ['removeFilter'],
  methods: {
    onRemoveFilter(filter) {
      this.$emit('removeFilter', filter);
    }
  }
});
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-right: $spacing * 0.5;
  }
}
</style>
