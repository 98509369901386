<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'register-order-no-items'"
  >
    <div :class="$style.inner">
      <div :class="$style.visual">
        <svg
          :class="$style.paper"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 159 110"
        >
          <path
            fill="url(#a)"
            d="M158.299 23.502h-44.053c.001-1.798 5.394-22.476 23.375-23.375 14.384-.72 19.779 15.283 20.678 23.375Z"
          />
          <path
            fill="url(#b)"
            d="M114.246 39.684c0-30.927 17.381-39.258 26.072-39.557H63.733c-4.058 0-8.248.428-11.612 2.698-18.505 12.488-16.99 51.076-16.99 69.225 0 15.823-7.193 29.368-10.79 31.466 5.035 5.754 51.246 6.593 56.64 6.293h.9c35.062 0 32.365-31.466 32.365-70.125Z"
          />
          <path
            fill="url(#c)"
            d="M4.313 92.727h58.856c2.528 0 4.709 1.773 5.301 4.231 2.444 10.142 4.013 12.423 7.937 12.851h7.271c-3.14 0-5.457.198-7.271 0H17.15c-8.83-.019-13.845-6.631-16.033-12.974-.725-2.1.974-4.108 3.196-4.108Z"
          />
          <defs>
            <linearGradient
              id="a"
              x1="159.198"
              x2="119.64"
              y1="13.589"
              y2=".103"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D1D8FB" />
              <stop offset="1" stop-color="#7773DA" />
            </linearGradient>
            <linearGradient
              id="b"
              x1="71"
              x2="52.5"
              y1="-12"
              y2="109.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F4F1FF" />
              <stop offset=".26" stop-color="#fff" />
              <stop offset="1" stop-color="#C7D6FD" />
            </linearGradient>
            <linearGradient
              id="c"
              x1="41.424"
              x2="39.626"
              y1="119.675"
              y2="91.805"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CDCFFB" />
              <stop offset="1" stop-color="#6965D2" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          :class="$style.circle"
          xmlns="http://www.w3.org/2000/svg"
          width="98"
          height="84"
          viewBox="0 0 98 84"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8011 30.1175C19.0823 19.5555 26.549 10.3163 37.2933 5.09332C57.9285 -4.93798 82.802 3.6314 92.8492 24.2335C102.897 44.8358 94.3137 69.6692 73.6784 79.7005C53.043 89.732 28.1697 81.1626 18.1223 60.5605C14.8504 53.8514 13.5657 46.6953 14.018 39.7462L0.194214 31.4586L15.8011 30.1175Z"
            fill="url(#paint0_linear_1413_7449)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1413_7449"
              x1="91.1942"
              y1="67.397"
              x2="18.1942"
              y2="6.39697"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CB5672" />
              <stop offset="1" stop-color="#FED2E9" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          :class="$style.line1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 55 6"
        >
          <path
            fill="#F08EBE"
            d="M.89 3.277C1.29 1.843 2.822.68 4.312.68H52.86c1.49 0 2.372 1.163 1.97 2.597-.401 1.435-1.934 2.598-3.423 2.598H2.859C1.37 5.875.488 4.712.89 3.277Z"
          />
        </svg>
        <svg
          :class="$style.line2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 55 6"
        >
          <path
            fill="#CBCAFA"
            d="M.091 3.066C.493 1.63 2.025.468 3.515.468h48.548c1.49 0 2.372 1.163 1.97 2.598-.4 1.434-1.934 2.597-3.423 2.597H2.062C.572 5.663-.31 4.5.092 3.066Z"
          />
        </svg>
        <svg
          :class="$style.line3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 31 6"
        >
          <path
            fill="#FACAE5"
            d="M.091 2.854C.493 1.42 2.025.257 3.515.257h25.173c1.49 0 2.372 1.163 1.97 2.597-.4 1.435-1.934 2.598-3.423 2.598H2.062c-1.49 0-2.372-1.163-1.97-2.598Z"
          />
        </svg>
        <svg
          :class="$style.euro"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 22"
        >
          <path
            fill="#6E6CBC"
            d="M13.275 18.335c-2.003 0-3.669-1.009-4.682-2.602-.312-.49.172-1.137.8-1.137h5.481c.465 0 .891-.369.952-.823l.193-1.454c.06-.454-.267-.822-.732-.822H8.314c-.438 0-.764-.33-.707-.758.057-.428.47-.756.907-.756h6.974c.465 0 .891-.368.952-.823l.193-1.454c.06-.454-.267-.822-.732-.822H10.42c-.629 0-.94-.647-.497-1.138 1.437-1.593 3.37-2.6 5.373-2.6 2.099 0 3.934 1.151 4.938 3.05.194.368.648.537 1.08.358l1.611-.664c.467-.193.723-.709.523-1.11-1.466-2.942-4.363-4.735-7.74-4.735-2.748 0-5.467 1.147-7.654 3.233A12.912 12.912 0 0 0 5.682 6.34c-.18.322-.524.543-.88.543H2.547c-.465 0-.891.368-.952.822L1.403 9.16c-.06.455.267.823.733.823h1.427c.42 0 .704.346.65.755-.055.412-.433.759-.854.759H1.934c-.465 0-.891.368-.952.822L.79 13.773c-.06.454.268.823.733.823h2.253a.75.75 0 0 1 .736.543 9.023 9.023 0 0 0 1.557 3.06c1.633 2.085 4.045 3.235 6.794 3.235 3.377 0 6.75-1.793 9-4.735.307-.401.188-.917-.226-1.11l-1.434-.665c-.386-.18-.885-.01-1.177.357-1.51 1.902-3.652 3.054-5.75 3.054Z"
          />
        </svg>
        <svg
          :class="$style.circle"
          width="98"
          height="84"
          viewBox="0 0 98 84"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.6842 49.1012L22.2782 55.8057C26.9232 55.8057 30.6886 53.7384 30.6886 51.1882V43.2982L16.8595 31.3728L0.232849 31.3728"
            fill="#6061BA"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8011 30.1175C19.0823 19.5555 26.549 10.3163 37.2933 5.09332C57.9285 -4.93798 82.802 3.6314 92.8492 24.2335C102.897 44.8358 94.3137 69.6692 73.6784 79.7005C53.043 89.732 28.1697 81.1626 18.1223 60.5605C14.8504 53.8514 13.5657 46.6953 14.018 39.7462L0.194214 31.4586L15.8011 30.1175Z"
            fill="url(#paint0_linear_1413_7446)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1413_7446"
              x1="91.1942"
              y1="67.397"
              x2="18.1942"
              y2="6.39697"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CB5672" />
              <stop offset="1" stop-color="#FED2E9" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          :class="$style.plus"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 21 21"
        >
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M18.944 9.647h-7.5v-7.5a1.25 1.25 0 1 0-2.5 0v7.5h-7.5a1.25 1.25 0 1 0 0 2.5h7.5v7.5a1.25 1.25 0 1 0 2.5 0v-7.5h7.5a1.25 1.25 0 1 0 0-2.5"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <BaseHeading center size="l">
        {{ $t('register.invoice_empty') }}
      </BaseHeading>
      <div v-if="$screen === 's'" :class="$style.buttons">
        <BaseButton fullWidth mb @click="showMobileBrowser = true">
          {{
            filters.capitalize(
              $t('global.actions.add_item', {
                item: $t('global.items.item', 2)
              })
            )
          }}
        </BaseButton>
        <BaseButton
          fullWidth
          color="inverted"
          :routerLink="{
            name: 'register-builder-appointments'
          }"
        >
          {{ $t('register.add_appointment') }}
        </BaseButton>
      </div>
      <div v-else :class="$style.chips">
        <BaseChip
          clickable
          :text="$t('register.add_appointment')"
          icon="calendar"
          @click="$router.push({ name: 'register-builder-appointments' })"
        />
        <BaseChip
          clickable
          :text="$t('register.add_service')"
          icon="scissors"
          @click="$router.push({ name: 'register-builder-services' })"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRegisterBrowserStore } from '@/modules/register/builder/browser/store';
import { storeToRefs } from 'pinia';
import filters from '@/filters';

const { showMobileBrowser } = storeToRefs(useRegisterBrowserStore());
</script>

<style lang="scss" module>
.base {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ddd' stroke-width='6' stroke-dasharray='10%2c 20' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");

  &:not(.smallScreen) {
    margin: $spacing * 2;
    padding: $spacing * 2;
  }

  &.smallScreen {
    margin-top: $spacing;
    padding: $spacing * 2 $spacing * 1.5;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.buttons {
  width: 100%;
  margin-top: $spacing;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $spacing;
  gap: $spacing;
}

$easing: $easeOutBack;
$duration: 5s;

.visual {
  position: relative;
  width: 157px;
  height: 124px;

  .base.smallScreen & {
    transform: scale(0.7);
    transform-origin: center 35%;
  }

  .base:not(.smallScreen) & {
    margin-bottom: $spacing;
  }

  & > * {
    position: absolute;
    animation-duration: $duration;
    animation-timing-function: $easing;
    animation-delay: 0s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }
}

.paper {
  left: 0;
  top: 0;
  width: 100%;
}

.line1,
.line2,
.line3 {
  transform-origin: left center;
}

.line1 {
  width: 54px;
  top: 20px;
  left: 50px;
  animation-name: line1;
}

.line2 {
  width: 54px;
  top: 32px;
  left: 48px;
  animation-name: line2;
}

.line3 {
  width: 30px;
  top: 44px;
  left: 48px;
  animation-name: line3;
}

.euro {
  width: 21px;
  left: 48px;
  top: 56px;
  animation-name: euro;
}

.circle {
  width: 97px;
  left: 92px;
  top: 40px;
  animation-name: circle;
  animation-timing-function: $easeOutQuint;
}

.plus {
  width: 20px;
  top: 72px;
  left: 136px;
  animation-name: plus;
}

@keyframes line1 {
  0% {
    transform: scaleX(0.2);
    opacity: 0;
  }
  20%,
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes line2 {
  5% {
    transform: scaleX(0.2);
    opacity: 0;
  }
  0%,
  25%,
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes line3 {
  10% {
    transform: scaleX(0.2);
    opacity: 0;
  }
  0%,
  30%,
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes euro {
  0%,
  15% {
    opacity: 0;
    transform: scale(0.8);
  }
  25%,
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes circle {
  0%,
  20% {
    opacity: 0;
    transform: scale(0.5) translateX(-50%);
  }
  30%,
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes plus {
  0%,
  25% {
    opacity: 0;
    transform: scale(0.5);
  }
  30%,
  100% {
    transform: none;
    opacity: 1;
  }
}
</style>
