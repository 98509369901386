<template>
  <BaseModal
    :heading="$t('company_name_and_phone.heading', { name: user?.firstName })"
    useForm
    small
    testId="company-details"
    unclosable
    @submit="onSubmit"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseText mb>
        {{ $t('company_name_and_phone.description') }}
      </BaseText>
      <div :class="$style.fieldsWrapper">
        <div :class="$style.fieldsContainer">
          <BaseInput
            v-model="formData.name"
            :label="$t('signup.company_name')"
            required
            :minLength="2"
            mb
            v-test="'signup-company'"
          />
          <BaseInput
            v-model="formData.phone"
            :label="$t('signup.phone')"
            :info="$t('signup.phone_info')"
            type="tel"
            :minLength="9"
            :maxLength="16"
            mb
            v-test="`signup-phone`"
          />
        </div>
        <img v-if="$screen !== 's'" src="/img/onboarding/saloon.svg" />
      </div>
    </div>
    <template #footer>
      <BaseButton
        submitForm
        :loading="isSubmitting"
        v-test="'btn-company-name-save'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref, reactive, inject } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { modal } from '@/helpers/ui';

const { user } = useUserStore();
const { updateCompany } = useCompanyStore();

const isSubmitting = ref(false);
const mixpanel = inject('mixpanel');
const formData = reactive({
  name: '',
  phone: '',
  updateNameRelatedFields: true
});

const onSubmit = () => {
  isSubmitting.value = true;

  updateCompany(formData)
    .then(() => {
      mixpanel.track('Onboarding journey - Next clicked', {
        step: 'Company name and phone'
      });

      modal('new-onboarding');
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>

<style lang="scss" module>
.fieldsWrapper {
  display: flex;
}

.fieldsContainer {
  flex-grow: 1;
  margin-right: 2 * $spacing;

  .base.smallScreen & {
    margin-right: 0;
  }
}
</style>
