<template>
  <div :class="$style.label">
    <BaseLabel color="success" v-test="'most-popular'">
      <slot />
    </BaseLabel>
  </div>
</template>

<style lang="scss" module>
.label {
  position: absolute;
  left: $spacing;
  top: -10px;
  z-index: 2;
}
</style>
