<template>
  <component
    :is="routerLink ? 'router-link' : 'div'"
    :to="routerLink || null"
    :class="[
      $style.base,
      {
        [$style.zebra]: zebra,
        [$style.isLink]: !!routerLink || clickable,
        [$style.isFooter]: isFooter,
        [$style.disabled]: disabled,
        [$style.smallScreen]: $screen === 's',
        [$style.breakLines]: breakLines
      }
    ]"
    @click="$emit('click')"
  >
    <td
      v-if="selectable"
      :class="[$style.cell, $style.checkboxCell]"
      @click="isHeader ? undefined : onCheckboxClick($event)"
      v-test="isHeader ? undefined : '_base-table-checkbox'"
    >
      <div v-if="!isHeader" :class="$style.cellContent">
        <div :class="$style.checkbox">
          <BaseCheckbox :modelValue="selectedValue" />
        </div>
      </div>
    </td>
    <component
      :is="isFooter ? 'td' : 'div'"
      v-for="(cell, index) in formattedCells"
      v-show="!cell.hide"
      :key="`cell-${index}`"
      :class="[
        $style.cell,
        {
          [$style.alignRight]: cell.alignRight,
          [$style.center]: cell.center,
          [$style.iconOnly]: typeof cell.value === 'boolean' || !cell.value,
          [$style.hasIcon]: !!cell.icon,
          [$style.clickable]: cell.clickable
        }
      ]"
      :colspan="colspan"
      v-test="'_base-table-cell'"
    >
      <div :class="$style.cellContent">
        <BaseLabel
          v-if="cell.label"
          :state="cell.label"
          v-test="'_base-table-label'"
        />
        <BaseIcon
          v-else-if="cell.value === true"
          name="check"
          :tooltip="
            cell.tooltip
              ? {
                  text: cell.tooltip,
                  touch: true
                }
              : null
          "
          v-test="'_base-table-checkmark'"
        />
        <BaseInput
          v-else-if="cell.editable"
          v-model="cell.value"
          :type="cell.type"
          :required="cell.required"
          :disabled="disabled"
          @update:modelValue="(value: any) => $emit('valueChange', value)"
        />
        <BaseHeading
          v-else-if="isHeader"
          inline
          noWrap
          :size="$screen === 's' ? 'xs' : 's'"
          v-test="'_base-table-text'"
        >
          {{ cell.value || '' }}
        </BaseHeading>
        <BaseText
          v-else-if="!cell.icon || typeof cell.value === 'string'"
          :bold="isFooter"
          :oneLine="!breakLines"
          :color="cell.color || 'default'"
          v-test="'_base-table-text'"
        >
          {{ cell.value || '-' }}
        </BaseText>
        <div v-if="cell.icon" :class="$style.icon" @click="$emit('clickCell')">
          <BaseIcon
            :color="cell.color || (cell.clickable ? 'primary' : 'default')"
            :name="cell.icon"
            :size="cell.clickable ? 'm' : 's'"
          />
        </div>
        <div v-if="sortOrder && cell.sort" :class="$style.sorting">
          <div
            :class="[
              $style.sortingInner,
              {
                [$style.ascending]:
                  sortOrder.sortBy === cell.sort &&
                  sortOrder.direction === 'ASC',
                [$style.descending]:
                  sortOrder.sortBy === cell.sort &&
                  sortOrder.direction === 'DESC'
              }
            ]"
            @click="onSortClick(cell.sort)"
            v-test="'_base-table-sort'"
          >
            <BaseIcon
              name="chevron-up"
              :color="
                sortOrder.sortBy === cell.sort && sortOrder.direction === 'ASC'
                  ? 'primary'
                  : 'secondary'
              "
            />
            <BaseIcon
              name="chevron-down"
              :color="
                sortOrder.sortBy === cell.sort && sortOrder.direction === 'DESC'
                  ? 'primary'
                  : 'secondary'
              "
            />
          </div>
        </div>
      </div>
    </component>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    cells: {
      type: Array,
      required: true,
      validator: (value) =>
        !value.find((cell) => cell === '-' || cell?.value === '-')
    },
    routerLink: {
      type: [Object, String]
    },
    isHeader: {
      type: Boolean,
      default: false
    },
    isFooter: {
      type: Boolean,
      default: false
    },
    zebra: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    breakLines: {
      type: Boolean,
      default: false
    },
    sortOrder: Object,
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'valueChange', 'clickCell', 'sort', 'select'],
  computed: {
    formattedCells() {
      return this.cells.map((cell) =>
        cell && typeof cell === 'object' ? cell : { value: cell }
      );
    },
    colspan() {
      // When the footer has only one item, we want to display the content over the entire width of the table.
      // The only way to do this inside a table layout, is to set the colspan to a very high value, since there is no such thing as "100%", and also the amount of columns is variable.
      return this.isFooter && this.cells.length === 1 ? 100 : null;
    },
    selectedValue: {
      get() {
        return this.selected;
      },
      set(value) {
        this.$emit('select', value);
      }
    }
  },
  methods: {
    onSortClick(sortBy: string) {
      let direction = this.sortOrder.direction;

      if (this.sortOrder.sortBy === sortBy) {
        direction = direction === 'ASC' ? 'DESC' : 'ASC';
      }

      this.$emit('sort', {
        sortBy,
        direction
      });
    },
    onCheckboxClick(e) {
      if (this.disabled) {
        return;
      }

      this.selectedValue = !this.selectedValue;
      e.preventDefault();
    }
  }
});
</script>

<style lang="scss" module>
$cellHeight: 55px;
.base {
  display: table-row;
  background-color: white;

  &.isLink {
    cursor: pointer;

    @include hover {
      background-color: $color-highlight !important;
    }
  }
  &.zebra {
    &:nth-child(even) {
      background-color: $grey-light;
    }
  }
  &.isFooter {
    background-color: $grey-light;
  }

  &.disabled {
    color: black;
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }
}

.cell {
  display: table-cell;
  padding: $spacing;
  height: $cellHeight;
  max-width: 300px;

  &.alignRight {
    text-align: right;
  }

  &.iconOnly {
    vertical-align: middle;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .base:not(:last-child) & {
    border-bottom: 1px solid $color-border;
  }
}

.cellContent {
  display: flex;
  align-items: center;

  .cell.center & {
    justify-content: center;
  }

  .cell.alignRight & {
    justify-content: flex-end;
  }

  & > * {
    &:not(:first-child) {
      margin-left: $spacing;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .cell.clickable & {
    padding: 4px;
    cursor: pointer;
  }
}

.sorting {
  @include background-hover;
  padding: 6px 0;
}

.sortingInner {
  position: absolute;
  top: -2px;
  left: -10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  & > * {
    margin: -6px 0;
  }

  &.ascending > *:last-child,
  &.descending > *:first-child {
    opacity: 0.5;
  }
}

.checkbox {
  position: relative;
  top: 4px;
  pointer-events: none; // Disabling the checkbox itself because toggling happens when clicking on the cell, to give the user a large area to click on
  margin: -2px 0; // Needed to prevent the cell from growning in Safari and causing misalignment with the actions column
}

.checkboxCell {
  width: 48px;
}
</style>
