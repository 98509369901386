<template>
  <div :class="$style.base" v-test="'sp-testimonial'">
    <BaseText italic size="l">
      {{ $t('register.salonized_pay.dashboard.testimonial.description') }}
    </BaseText>
    <div :class="$style.user">
      <img src="/img/salonized-pay/testimonial.png" />
      <div :class="$style.name">
        <BaseText bold size="l" :mb="0.25">
          {{ $t('register.salonized_pay.dashboard.testimonial.name') }}
        </BaseText>
        <BaseText>
          {{ $t('register.salonized_pay.dashboard.testimonial.studio') }}
        </BaseText>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.base {
  position: relative;
  border-radius: 8px;
  padding: $spacing * 2;
  max-width: 700px;
  width: 100%;
  margin-bottom: $spacing * 6;
  background: linear-gradient(109deg, #e7fffa 16.06%, #be95ff 199.25%);
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.12);
}

.user {
  display: flex;
  align-items: center;

  margin-top: $spacing * 1.5;
}

.name {
  margin-left: $spacing;
}
</style>
