<template>
  <div>
    <ResultGroup :heading="$t('search.jump_to')">
      <ResultItem
        v-for="(page, index) in popularPages"
        :key="index"
        :label="page.name"
        icon="settings"
        :routerLink="{
          path: getRoutePrefix() + page.path
        }"
        :index="index"
        @select="
          () => {
            emit('select');
            mixpanel.track('search_popular_selected');
          }
        "
      />
    </ResultGroup>
    <ResultGroup
      v-if="history && history.length"
      :heading="$t('search.recents')"
    >
      <ResultItem
        v-for="(historyItem, index) in history"
        :key="`history-${index}`"
        :label="historyItem.label"
        :icon="historyItem.icon"
        :routerLink="historyItem.routerLink"
        :subLabel="historyItem.subLabel"
        :index="popularPages.length + index"
        history
        @select="
          () => {
            emit('select');
            mixpanel.track('search_history_selected');
          }
        "
      />
    </ResultGroup>
    <Tips v-if="!device.touch" />
  </div>
</template>

<script setup lang="ts">
import ResultItem from './ResultItem.vue';
import ResultGroup from './ResultGroup.vue';
import Tips from './Tips.vue';
import { device } from '@/user-context';
import { getRoutePrefix } from './helpers';

const emit = defineEmits(['select', 'selectIndex']);

const history = inject<any>('history');
const popularPages = inject<any>('popularPages');
const selectedIndex = inject<any>('selectedIndex');
const mixpanel = inject<any>('mixpanel');

const onKeyPress = (e) => {
  if (e.key === 'ArrowDown') {
    if (
      selectedIndex.value <
      popularPages.value.length + history.value.length - 1
    ) {
      emit('selectIndex', selectedIndex.value + 1);
    }
  } else if (e.key === 'ArrowUp') {
    if (selectedIndex.value > 0) {
      emit('selectIndex', selectedIndex.value - 1);
    }
  }
};

window.addEventListener('keydown', onKeyPress);

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyPress);
});
</script>
