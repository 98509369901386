import { defineStore, acceptHMRUpdate } from 'pinia';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { reactive, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from './company';
import unleash from '@/unleash';
import { useStorage } from '@vueuse/core';
import dayjs from '@/dayjs';
import { useRoute } from 'vue-router';
export const useTreatwellStore = defineStore('treatwell', () => {
  const { hasFeatureFlag } = useUserStore();
  const { isTreatwellUser, company } = useCompanyStore();

  const treatwellStatus = reactive({
    bankDetailsProvided: false,
    contractSigned: false,
    contractType: null,
    descriptionProvided: false,
    freeCommissionRemainingDays: 0,
    isPastFreeCommissionPeriod: false,
    subscriptionIntention: false,
    hasRequest: false,
    hasVenue: false,
    venuePending: true,
    optOutCampaign: false,
    reviewsPublished: false,
    twHardNo: false,
    kycStatus: {
      onboardingUrl: '',
      kycCompleted: false
    }
  });

  const showTreatwell = computed(
    () => hasFeatureFlag('module-treatwell') && !treatwellStatus.twHardNo
  );

  const hasDashboard = computed(
    () =>
      treatwellStatus.contractSigned ||
      treatwellStatus.optOutCampaign ||
      treatwellStatus.hasRequest
  );

  const completedOnboardingTasks = computed(() => {
    if (isTreatwellUser) {
      return treatwellStatus.kycStatus?.kycCompleted;
    } else {
      return (
        treatwellStatus.contractSigned &&
        providedCompanyInfo.value &&
        treatwellStatus.bankDetailsProvided &&
        treatwellStatus.kycStatus?.kycCompleted
      );
    }
  });

  const showTWOnboardingNotification = computed(() => {
    const tw_linked_account_at = useStorage('tw_linked_account_at', 0);
    const tw_linked_account_at_16_days_ago =
      dayjs().diff(dayjs(tw_linked_account_at.value), 'day') >= 31;

    return (
      hasDashboard.value &&
      !completedOnboardingTasks.value &&
      !tw_linked_account_at_16_days_ago
    );
  });

  const providedCompanyInfo = computed(
    () =>
      !!company.address &&
      !!company.city &&
      !!company.postalcode &&
      !!company.phone
  );

  const getTreatwellStatus = () =>
    new Promise<void>((resolve) => {
      const { onResult } = useQuery(gql`
        query getTreatwellStatus {
          treatwellStatus {
            bankDetailsProvided
            contractSigned
            contractType
            descriptionProvided
            freeCommissionRemainingDays
            subscriptionIntention
            hasVenue
            venuePending
            hasRequest
            optOutCampaign
            reviewsPublished
            twHardNo
            isPastFreeCommissionPeriod
            kycStatus {
              kycCompleted
              onboardingUrl
            }
          }
        }
      `);

      onResult(({ data: { treatwellStatus: _treatwellStatus } }) => {
        Object.keys(_treatwellStatus).forEach((key) => {
          if (
            treatwellStatus[key as keyof typeof treatwellStatus] !== undefined
          ) {
            treatwellStatus[key] = _treatwellStatus[key];
          }
        });

        if (treatwellStatus.kycStatus) {
          const { mutate } = useMutation(
            gql`
              mutation treatwellUpdateKycStatus($input: UpdateKycStatusInput!) {
                treatwellUpdateKycStatus(input: $input) {
                  errors {
                    attribute
                    message
                  }
                }
              }
            `,
            {
              variables: {
                input: {
                  kycCompleted: treatwellStatus.kycStatus?.kycCompleted
                }
              }
            }
          );
          mutate();
        }

        resolve();
      });
    });

  const needsToCompleteKYC = computed(
    () =>
      unleash.isEnabled('TW_KYC_FLOW') &&
      showTreatwell.value &&
      treatwellStatus.hasVenue &&
      treatwellStatus.kycStatus?.onboardingUrl &&
      !treatwellStatus.kycStatus?.kycCompleted
  );

  const hasActiveVenue = computed(
    () => treatwellStatus.hasVenue && !treatwellStatus.venuePending
  );

  // Path blocking logic for KYC modals
  const pathBlocked = computed<boolean>(() => {
    const route = useRoute();

    if (route.name === 'treatwell-admin') {
      return true;
    }

    // Do not show the popup when the path includes any of the excludePaths keywords
    const path = route.path.split('/');
    const excludePaths = [
      'new',
      'customers',
      'create-event',
      'checkout',
      'invoices'
    ];
    let pathExcluded = false;
    path.forEach((subPath: string) => {
      if (excludePaths.includes(subPath)) {
        pathExcluded = true;
      }
    });
    return pathExcluded;
  });

  // KYC modal logic
  const kycModalDismissedAt = useStorage('tw_kyc_modal_dismissed_at', 0);

  const kycUtimatumActive = computed(
    () =>
      needsToCompleteKYC.value &&
      unleash.isEnabled('TW_KYC_ULTIMATUM') &&
      !treatwellStatus.venuePending
  );

  const shouldShowKYCUltimatum = computed(
    () =>
      kycUtimatumActive.value &&
      dayjs().diff(kycModalDismissedAt.value, 'day', true) > 5 &&
      !pathBlocked.value
  );

  const dismissKYCModal = () => {
    kycModalDismissedAt.value = Date.now();
  };

  return {
    getTreatwellStatus,
    treatwellStatus,
    showTreatwell,
    hasDashboard,
    completedOnboardingTasks,
    providedCompanyInfo,
    needsToCompleteKYC,
    kycUtimatumActive,
    shouldShowKYCUltimatum,
    dismissKYCModal,
    hasActiveVenue,
    showTWOnboardingNotification
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTreatwellStore, import.meta.hot));
}
