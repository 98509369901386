<template>
  <div>
    <BaseCard v-if="selectedShareOption === 'link'" gray>
      <BaseHeading mb>
        {{ $t('custom_booking_widget.online_booking_link') }}
      </BaseHeading>
      <BaseText mb>
        {{ $t('custom_booking_widget.online_booking_link_info') }}
      </BaseText>
      <BaseCodeField
        v-if="widgetLink"
        :code="widgetLink"
        v-test="'custom-widget-link'"
      />
      <BaseButton
        v-else
        :loading="loading"
        @click="mutate"
        v-test="'custom-widget-generate'"
      >
        {{ $t('custom_booking_widget.generate_link') }}
      </BaseButton>
    </BaseCard>
    <Snippet
      v-else
      :widgetId="widgetId"
      custom
      :loading="loading"
      @generate="mutate"
      v-test="'custom-widget-snippet'"
    />
  </div>
</template>

<script setup lang="ts">
import Snippet from '../Snippet.vue';
import { ref, computed, watch } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { GET_MICRO_SITE, CREATE_CUSTOM_BOOKING_WIDGET } from './graphql';
import options from './options';

const {
  selectedLocationId,
  selectedServiceIds,
  selectedEmployeeIds,
  selectedShareOption
} = options;
const widgetId = ref('');
const widgetLink = ref('');
const loadingQuery = ref(false);
const loading = computed(() => loadingMutation.value || loadingQuery.value);

const {
  mutate,
  onDone,
  loading: loadingMutation
} = useMutation(CREATE_CUSTOM_BOOKING_WIDGET, () => ({
  variables: {
    input: {
      locationId: selectedLocationId.value,
      resourceIds: selectedEmployeeIds.value,
      serviceIds: selectedServiceIds.value
    }
  }
}));

onDone(
  ({
    data: {
      createCustomBookingWidget: { customBookingWidget }
    }
  }) => {
    widgetId.value = customBookingWidget.publicId;

    loadingQuery.value = true;
    const { onResult } = useQuery(GET_MICRO_SITE);
    onResult(({ data: { microSite } }) => {
      loadingQuery.value = false;
      if (microSite?.url) {
        widgetLink.value = `${microSite.url}/widget_bookings/new?widget_id=${widgetId.value}`;
      }
    });
  }
);

watch([selectedLocationId, selectedServiceIds, selectedEmployeeIds], () => {
  widgetId.value = '';
  widgetLink.value = '';
});
</script>
