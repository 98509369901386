<template>
  <div
    :class="[
      $style.base,
      {
        [$style.scrolledToEnd]: scrolledToEnd,
        [$style.minHeight]: setMinHeight
      }
    ]"
    @scroll="checkScrollPosition"
  >
    <div
      ref="content"
      :class="$style.content"
      :style="
        contentWidth
          ? {
              'min-width': contentWidth + 'px'
            }
          : null
      "
      v-test="'_scroll-container'"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    contentWidth: {
      type: Number
    },
    setMinHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrolledToEnd: false
    };
  },
  methods: {
    checkScrollPosition() {
      if (this.$refs.content) {
        const containerWidth = this.$el.clientWidth;
        const contentWidth = this.$refs.content.clientWidth;
        const scrollLeft = this.$el.scrollLeft;

        this.scrolledToEnd = containerWidth + scrollLeft >= contentWidth;
      }
    }
  },
  mounted() {
    this.checkScrollPosition();
  }
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;

  &:not(.scrolledToEnd) {
    mask-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      0,
      #000 calc(100% - 40px),
      rgba(255, 255, 255, 0)
    );
  }
}

.minHeight {
  min-height: 500px;
}

@media print {
  .content {
    width: 100% !important;
    min-width: unset !important;
  }
}
</style>
