<template>
  <BaseModal
    :heading="heading"
    :loading="isLoading"
    small
    :parentRoute="parentRouteName"
    @close="$emit('close')"
  >
    <BaseGrid container>
      <BaseGrid :size="8">
        <BaseText :mb="2">
          {{ $t('marketing.discounts.discount-code-modal.description') }}
        </BaseText>
      </BaseGrid>
      <BaseGrid :size="4" alignRight>
        <BaseText v-if="!newsletterRoute" :mb="2" v-test="'enableWrapper'">
          <BaseToggle
            :modelValue="enabled"
            :label="
              $t(`marketing.discounts.discount-code-modal.state.${state}`)
            "
            ml
            @update:modelValue="onToggleState"
            v-test="'enabled'"
          />
        </BaseText>
      </BaseGrid>
      <BaseGrid :size="6" :class="$style.discountCode">
        <BaseInput
          v-model="code"
          :label="$t('global.title')"
          :placeholder="
            $t('marketing.discounts.discount-code-modal.title_placeholder')
          "
          required
          :error="
            isCodeTaken
              ? $t('global.validations.in_use', {
                  value: $t('marketing.discounts.discount-code-modal.code')
                })
              : null
          "
          :disabled="isEditMode"
          v-test="'code'"
        />
        <div :class="$style.generateRandomCode">
          <BaseText
            v-if="!isEditMode"
            mr
            link
            @click="generateRandomCode"
            v-test="'generateRandomCode'"
          >
            {{ $t('marketing.discounts.discount-code-modal.generate_code') }}
          </BaseText>
        </div>
        <BaseText :mb="2" color="secondary">
          {{
            $t(
              'marketing.discounts.discount-code-modal.discount_code_description'
            )
          }}
        </BaseText>
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="discountPercentage"
          :label="
            $t('marketing.discounts.discount-code-modal.discount_percentage')
          "
          :options="percentageOptions"
          :disabled="isEditMode"
          v-test="'discount-percentage'"
        />
        <BaseText :mb="2" color="secondary">
          {{
            $t(
              'marketing.discounts.discount-code-modal.discount_percentage_description'
            )
          }}
        </BaseText>
      </BaseGrid>
    </BaseGrid>

    <BaseGrid container :class="$style.section">
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="category"
          :options="categoryOptions"
          :label="$t('marketing.discounts.discount-code-modal.category_title')"
          v-test="'category'"
        />
      </BaseGrid>
      <BaseGrid :size="6">
        <BaseDropdown
          v-model="expiresIn"
          :options="expirationOptions"
          :label="
            $t('marketing.discounts.discount-code-modal.expiration_time_title')
          "
          v-test="'expires-in'"
        />
      </BaseGrid>
      <BaseGrid :size="12">
        <BaseInputLabel
          :text="$t('marketing.discounts.discount-code-modal.usage_limits')"
          mb
        />
        <BaseCheckbox
          v-model="oncePerCustomer"
          :label="
            $t('marketing.discounts.discount-code-modal.once_per_customer')
          "
          :mb="0.5"
          v-test="'once'"
        />
        <BaseCheckbox
          v-model="hasLimit"
          :label="$t('marketing.discounts.discount-code-modal.limit')"
          v-test="'hasLimit'"
        />
      </BaseGrid>
      <BaseGrid v-show="hasLimit" :size="6" :class="$style.times">
        <BaseInput
          v-model="usageLimit"
          :label="$t('marketing.discounts.discount-code-modal.usage_limit')"
          type="number"
          :minValue="0"
          :required="hasLimit"
          controls
          mb
          v-test="'usageLimit'"
        />
        <BaseText ml color="secondary">
          {{ $t('marketing.discounts.discount-code-modal.times') }}
        </BaseText>
      </BaseGrid>
    </BaseGrid>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="switchRoute"
        v-test="'discountCodeCancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>

      <BaseButton :loading="isLoading" @click="onSubmit" v-test="'saveButton'">
        {{ submitText }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import eventBus from '@/event-bus';
import { flash } from '@/helpers/ui';
import { useOnboardingStore } from '@/stores/onboarding';
import useVuelidate from '@vuelidate/core';
import gql from 'graphql-tag';
import { storeToRefs } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    parentRouteName: String
  },
  emits: ['close', 'discountCode'],
  setup() {
    const { currentOnboardingFlow } = storeToRefs(useOnboardingStore());

    return { v$: useVuelidate(), currentOnboardingFlow };
  },
  data() {
    return {
      isLoading: false,
      isCodeTaken: false,
      code: '',
      category: 'holidays',
      enabled: true,
      state: 'enabled',
      discountPercentage: 5,
      hasLimit: false,
      oncePerCustomer: false,
      usageLimit: 0,
      expiresIn: 'never',
      expirationOptions: [
        {
          label: this.$t(
            'marketing.discounts.discount-code-modal.expiration.never'
          ),
          value: 'never'
        },
        {
          label: this.$t(
            'marketing.discounts.discount-code-modal.expiration.one_month'
          ),
          value: 'one_month'
        },
        {
          label: this.$t(
            'marketing.discounts.discount-code-modal.expiration.three_months'
          ),
          value: 'three_months'
        },
        {
          label: this.$t(
            'marketing.discounts.discount-code-modal.expiration.six_months'
          ),
          value: 'six_months'
        },
        {
          label: this.$t(
            'marketing.discounts.discount-code-modal.expiration.one_year'
          ),
          value: 'one_year'
        }
      ],
      categoryOptions: [
        {
          label: this.$t('marketing.discounts.category.holidays'),
          value: 'holidays'
        },
        {
          label: this.$t('marketing.discounts.category.new_customers'),
          value: 'new_customers'
        },
        {
          label: this.$t('marketing.discounts.category.rebook_coupons'),
          value: 'rebook_coupons'
        },
        {
          label: this.$t('marketing.discounts.category.special_events'),
          value: 'special_events'
        },
        {
          label: this.$t('marketing.discounts.category.other'),
          value: 'other'
        }
      ]
    };
  },
  apollo: {
    discountCode: {
      query: gql`
        query getDiscountCode($id: Int!) {
          discountCode(id: $id) {
            id
            code
            category
            state
            discountPercentage
            hasLimit
            oncePerCustomer
            usageLimit
            expiresIn
          }
        }
      `,
      skip() {
        return !this.isEditMode;
      },
      variables() {
        return {
          id: parseInt(this.$route.params.discountId)
        };
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      result() {
        this.code = this.discountCode.code;
        this.category = this.discountCode.category;
        this.state = this.discountCode.state.toLowerCase();
        this.enabled = this.state === 'enabled';
        this.discountPercentage = this.discountCode.discountPercentage;
        this.hasLimit = this.discountCode.hasLimit;
        this.oncePerCustomer = this.discountCode.oncePerCustomer;
        this.usageLimit = this.discountCode.usageLimit;
        this.expiresIn = this.discountCode.expiresIn.toLowerCase();
      }
    }
  },

  watch: {
    code() {
      this.isCodeTaken = false;
    }
  },

  computed: {
    newsletterRoute() {
      return this.$route.name === 'newsletter-template';
    },
    percentageOptions() {
      return [
        5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
        95
      ].map((percentage) => ({
        value: percentage,
        label: `${percentage}%`
      }));
    },

    mode() {
      return this.isEditMode ? 'update' : 'create';
    },

    heading() {
      return this.$t(
        `marketing.discounts.discount-code-modal.${this.mode}_title`
      );
    },

    submitText() {
      return this.$t(
        `marketing.discounts.discount-code-modal.${this.mode}_button`
      );
    },

    isEditMode() {
      return !!this.$route.params.discountId;
    }
  },

  methods: {
    generateRandomCode() {
      // Skip the 0 and O, 1 and I characters because they look too similar
      const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'.split('');
      const pickRandomChar = () =>
        chars[Math.floor(Math.random() * chars.length)];
      this.code = Array.from(new Array(8), pickRandomChar).join('');
    },

    onToggleState(value) {
      this.enabled = value;

      if (this.enabled) {
        this.state = 'enabled';
      } else {
        this.state = 'disabled';
      }
    },

    switchRoute() {
      if (this.parentRouteName) {
        this.$router.push({ name: this.parentRouteName });
      } else {
        this.$emit('close');
      }
    },

    onSubmit() {
      this.v$.$touch();

      if (this.v$.$invalid || this.isCodeTaken) {
        return;
      }

      this.isLoading = true;

      const input = {
        category: this.category,
        state: this.state.toUpperCase(),
        hasLimit: this.hasLimit,
        oncePerCustomer: this.oncePerCustomer,
        usageLimit: this.usageLimit,
        expiresIn: this.expiresIn.toUpperCase()
      };

      if (this.isEditMode) {
        input.id = parseInt(this.$route.params.discountId);

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateDiscountCode($input: UpdateDiscountCodeInput!) {
                updateDiscountCode(input: $input) {
                  discountCode {
                    id
                    category
                    state
                    hasLimit
                    oncePerCustomer
                    usageLimit
                    expiresIn
                  }
                }
              }
            `,
            variables: {
              input
            }
          })
          .then(() => {
            this.callback();
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        input.code = this.code;
        input.discountPercentage = this.discountPercentage;

        this.mixpanel.track('Discount saved');

        this.$apollo
          .mutate({
            mutation: gql`
              mutation createDiscountCode($input: CreateDiscountCodeInput!) {
                createDiscountCode(input: $input) {
                  discountCode {
                    id
                    code
                  }
                  errors
                }
              }
            `,
            variables: {
              input
            },
            update(cache) {
              cache.evict({ id: 'ROOT_QUERY', fieldName: 'discountCodes' });
              cache.gc();
            }
          })
          .then((response) => {
            if (response.data.createDiscountCode.errors) {
              this.isCodeTaken = true;
            } else {
              const discountCode =
                response.data.createDiscountCode.discountCode;
              if (discountCode) {
                this.$emit('discountCode', discountCode);
              }
              this.callback();
            }
          })
          .finally(() => {
            if (this.currentOnboardingFlow === 'marketing') {
              eventBus.$emit('open-task-menu');
              this.currentOnboardingFlow = '';
            }
            this.isLoading = false;
          });
      }
    },
    callback() {
      this.switchRoute();
      flash(
        this.$t(
          `global.flash.${this.$route.params.discountId ? 'discount_code_updated' : 'discount_code_created'}`
        )
      );
    }
  }
});
</script>

<style lang="scss" module>
.section {
  border-top: 1px solid $color-border;
  padding-top: $spacing * 2;
}

.discountCode {
  position: relative;
}

.generateRandomCode {
  position: absolute;
  top: 4px;
  right: 0;
}

.times {
  display: flex;
  align-items: center;
}
</style>
