<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.header">
      <BaseHeading mb>
        {{ $t('admin.treatwell.stats.generic_heading') }}
      </BaseHeading>
      <BaseSwitch
        v-model="selectedDateRange"
        :options="dateRangeOptions"
        v-test="'date-range-toggle'"
      />
    </div>
    <div :class="$style.stats">
      <BaseCard
        :heading="$t('admin.treatwell.stats.online_bookings')"
        :loading="loading"
        fullWidth
      >
        <div :class="$style.cardContent">
          <span
            :class="$style.number"
            v-test="'treatwell-statistics-bookings'"
            >{{ result.treatwellDashboardStatistics.appointmentCount }}</span
          >
          {{ $t('admin.treatwell.stats.bookings_treatwell') }}
        </div>
      </BaseCard>
      <BaseCard
        :heading="$t('admin.treatwell.stats.gross_revenue')"
        :loading="loading"
        fullWidth
      >
        <template #header>
          <BaseText size="s">
            {{ $t('admin.treatwell.stats.excluding') }}
          </BaseText>
        </template>
        <div :class="$style.cardContent">
          <span
            :class="$style.number"
            v-test="'treatwell-statistics-revenue'"
            >{{
              filters.currency(result.treatwellDashboardStatistics.grossRevenue)
            }}</span
          >
          {{ $t('admin.treatwell.stats.from_treatwell_bookings') }}
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import filters from '@/filters';
import { useQuery } from '@vue/apollo-composable';
import { GET_TREATWELL_DASHBOARD_STATISTICS } from './graphql';
import { DateRange } from '@/types';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const selectedDateRange = ref(DateRange.Year);

const { loading, result } = useQuery(
  GET_TREATWELL_DASHBOARD_STATISTICS,
  () => ({ range: selectedDateRange.value })
);

const dateRangeOptions = [
  {
    value: DateRange.Month,
    label: t('admin.treatwell.stats.last_month')
  },
  {
    value: DateRange.Year,
    label: t('admin.treatwell.stats.last_year')
  }
];
</script>

<style lang="scss" module>
.stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing;

  .base.smallScreen & {
    flex-direction: column;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing;

  .base.smallScreen & {
    flex-direction: column;
  }
}

.smallScreen {
  flex-direction: column;
}
.number {
  font-weight: bold;
  font-size: 24px;
  padding-right: $spacing;
}

.cardContent {
  display: flex;
  align-items: center;
}
</style>
