<template>
  <div :class="$style.base">
    <BaseHeading center>
      {{ $t('register.salonized_pay.landing.payment_methods.heading') }}
    </BaseHeading>
    <div :class="$style.methods">
      <img src="/img/salonized-pay/payment-methods/maestro.png" />
      <img src="/img/salonized-pay/payment-methods/mastercard.png" />
      <img src="/img/salonized-pay/payment-methods/visa.png" />
      <img src="/img/salonized-pay/payment-methods/americanexpress.png" />
      <img src="/img/salonized-pay/payment-methods/bancontact.png" />
      <img src="/img/salonized-pay/payment-methods/diners.png" />
      <img src="/img/salonized-pay/payment-methods/discover.png" />
      <img src="/img/salonized-pay/payment-methods/applepay.svg" />
      <img src="/img/salonized-pay/payment-methods/googlepay.svg" />
      <img src="/img/salonized-pay/payment-methods/samsungpay.svg" />
      <img
        v-if="company.country === 'de'"
        src="/img/salonized-pay/payment-methods/girocard.png"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';

const { company } = useCompanyStore();
</script>

<style lang="scss" module>
.base {
  padding: $spacing * 2 $spacing;
  background-color: $grey-light;
}

.methods {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $spacing * 2;
  margin-top: $spacing * 1.5;

  img {
    height: 50px;
    width: auto;
  }
}
</style>
