import axios from '@/axios';
import { device } from '@/user-context';

const trackingQueryParams = {
  a: 'aid',
  aid: 'aid',
  discount_code: 'discount_code',
  r: 'referral_id',
  utm_campaign: 'utm_campaign',
  utm_content: 'utm_content',
  utm_medium: 'utm_medium',
  utm_source: 'utm_source',
  utm_term: 'utm_term'
};

const trackingData = () => {
  const searchString = document.location.search;
  return searchString
    .substring(1)
    .split('&')
    .reduce((result, query) => {
      const [param, value] = query.split('=');
      const alias = trackingQueryParams[param.toLowerCase()];
      if (alias) {
        result[alias] = value;
      }
      return result;
    }, {});
};

export const updateDataLayer = () =>
  axios
    .post('/sessions/visit', trackingData())
    .then(({ data: { dataLayer } }) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          ...dataLayer,
          host: window.location.hostname,
          device: ['mobile', 'tablet', 'desktop'].find((d) => device[d]),
          user_agent: navigator.userAgent
        });
      }
    });
