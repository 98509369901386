<template>
  <div :class="$style.base">
    <BaseCard>
      <BaseGrid container>
        <BaseGrid>
          <BaseText oneLine iconBefore="email" bold :mb="$screen !== 's'">
            {{ title }}
          </BaseText>
        </BaseGrid>
        <BaseGrid :alignRight="$screen !== 's'" alignTop>
          <div :class="{ [$style.isSmall]: $screen === 's' }">
            <BaseLabel
              v-if="enabled"
              color="success"
              v-test="'enabledStateLabel'"
            >
              {{ $t('global.enabled') }}
            </BaseLabel>
            <BaseLabel v-else v-test="'disabledStateLabel'">
              {{ $t('global.disabled') }}
            </BaseLabel>
          </div>
        </BaseGrid>
      </BaseGrid>
      <BaseText>
        {{ description }}
      </BaseText>
      <BaseButton
        :routerLink="routerLink"
        mt
        fullWidth
        color="inverted"
        @click="mixpanel.track(mixpanelEvent)"
        v-test="`enable-${routerLink.name}`"
      >
        {{ $t('global.settings') }}
      </BaseButton>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    routerLink: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    mixpanelEvent: {
      type: String,
      default: 'Edit template clicked'
    }
  }
});
</script>

<style lang="scss" module>
.base {
  max-width: 400px;
}

.isSmall {
  margin-bottom: $spacing * 0.5;
}
</style>
