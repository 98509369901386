<template>
  <BasePopoverNav
    :style="{
      top: '60px'
    }"
    arrow="topLeft"
    :keepVisibleOn="keepVisibleOn"
    autoPositioning
    :positionOn="positionOn"
    @close="$emit('close')"
  >
    <DynamicPriceMode />
  </BasePopoverNav>
</template>

<script lang="ts">
import DynamicPriceMode from '@/modules/calendar/DynamicPriceMode.vue';
import { useCalendarFiltersStore } from '@/stores/calendar-filters';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DynamicPriceMode
  },
  props: {
    keepVisibleOn: {
      type: Element
    },
    positionOn: {
      type: Element
    }
  },
  emits: ['close'],
  watch: {
    dynamicPricingEnabled() {
      this.$emit('close');
    }
  },
  computed: {
    dynamicPricingEnabled() {
      const { dynamicPricingMode } = useCalendarFiltersStore();
      return dynamicPricingMode;
    }
  }
});
</script>
