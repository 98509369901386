import gql from 'graphql-tag';

export const ADYEN_APP_STATE = gql`
  query getAdyenAppState {
    adyenAppState {
      industryCode
      terminalModel
    }
  }
`;

export const GET_ADYEN_ONBOARDING_DATA = gql`
  query getAdyenOnboardingData {
    adyenOnboardingData {
      industryCodes {
        id
        name
      }
      legalEntityTypes
    }
  }
`;

export const GENERATE_ADYEN_ONBOARDING_LINK = gql`
  mutation generateAdyenOnboardingLink($input: GenerateOnboardingLinkInput!) {
    generateAdyenOnboardingLink(input: $input) {
      onboardingLink {
        url
      }
      errors {
        message
      }
    }
  }
`;

export const GET_MICRO_SITE = gql`
  query getMicroSite {
    microSite {
      url
      customDomain
    }
  }
`;

export const CREATE_ADYEN_ONBOARDING = gql`
  mutation saveAdyenOnboardingData($input: SaveOnboardingDataInput!) {
    saveAdyenOnboardingData(input: $input) {
      adyenAppState {
        verificationStatus
      }
    }
  }
`;
