<template>
  <div :class="$style.feature">
    <BaseIcon name="check" color="success" size="l" :mr="0.5" />
    <BaseText>
      <slot />
    </BaseText>
  </div>
</template>

<style lang="scss" module>
.feature {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}
</style>
