<template>
  <BaseModal
    :heading="$t('drawer.edit_cash_heading')"
    small
    :parentRoute="{
      name: 'drawer'
    }"
    :loading="$apollo.loading"
  >
    <BaseText mb>
      {{ $t('drawer.edit_cash_description') }}
    </BaseText>
    <BaseInput
      v-model="total"
      :label="$t('drawer.new_total')"
      type="currency"
      mb
      v-test="'drawer-edit-cash-amount'"
    />
    <BaseDatePicker
      v-model="date"
      :label="$t('global.date')"
      onlyPastDates
      v-test="'drawer-edit-cash-date'"
    />
    <template #footer>
      <BaseButton
        :loading="isSaving"
        @click="submit"
        v-test="'drawer-edit-cash-submit'"
        >{{ $t('global.actions.submit') }}</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script lang="ts">
import dayjs from '@/dayjs';
import gql from 'graphql-tag';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isSaving: false,
      total: 0,
      date: dayjs()
    };
  },
  apollo: {
    drawer: {
      query: gql`
        query getDrawer($locationId: ID) {
          drawer(locationId: $locationId) {
            creditCard
            pin
            today
            total
          }
        }
      `,
      variables() {
        return {
          locationId: this.locationId
        };
      },
      result({ data: { drawer } }) {
        this.total = drawer.total;
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    ...mapState(useLocationsStore, ['locationId']),
    ...mapState(useCompanyStore, ['companyId'])
  },
  methods: {
    submit() {
      this.isSaving = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateDrawer($input: UpdateDrawerInput!) {
              updateDrawer(input: $input) {
                drawer {
                  creditCard
                  pin
                  today
                  total
                }
              }
            }
          `,
          variables: {
            input: {
              amount: this.total,
              transactionAt: this.date,
              locationId: this.locationId
            }
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'drawer' });
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
            cache.gc();
          }
        })
        .then(() => {
          this.$router.push({ name: 'drawer' });
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }
});
</script>
