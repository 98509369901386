const Services = () => import('@/modules/admin/services/index.vue');
const Service = () => import('@/modules/admin/services/service/index.vue');
const Category = () => import('@/modules/admin/services/Category.vue');

export default [
  {
    path: 'services',
    name: 'admin-services',
    component: Services,
    meta: {
      featureFlag: 'admin-services'
    },
    children: [
      {
        path: 'new/:categoryId?',
        name: 'admin-service-new',
        component: Service
      },
      {
        path: ':serviceId',
        name: 'admin-service',
        component: Service
      },
      {
        path: 'category/new',
        name: 'admin-service-category-new',
        component: Category,
        props: {
          parentRouteName: 'admin-services'
        }
      },
      {
        path: 'category/:categoryId',
        name: 'admin-service-category',
        component: Category,
        props: {
          parentRouteName: 'admin-services'
        }
      }
    ]
  }
];
