<template>
  <FooterLayout :fullWidth="$screen === 's'">
    <template #left>
      <BaseButton
        v-if="$screen === 's'"
        color="inverted"
        icon="more-horizontal"
        :options="buttonOptions"
        @select="onOptionSelect"
        v-test="'register-footer-options'"
      />
      <BaseButton
        v-else
        icon="delete"
        color="inverted"
        @click="reset"
        v-test="'order-reset'"
      />
    </template>
    <template #right>
      <BaseButton
        v-if="$screen !== 's' && (!order.id || order.draft)"
        color="inverted"
        submitForm
        :loading="savingDraft"
        :disabled="!order.items.length"
        @click="saveDraft"
        v-test="'order-draft'"
      >
        {{ $t('register.save_draft') }}
      </BaseButton>
      <BaseButton
        submitForm
        :disabled="!order.items.length"
        v-test="'order-checkout'"
      >
        {{ $t('global.actions.continue') }}
      </BaseButton>
    </template>
  </FooterLayout>
</template>

<script setup lang="ts">
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { useResourcesStore } from '@/stores/resources';
import { storeToRefs } from 'pinia';
import FooterLayout from '../FooterLayout.vue';
import { useI18n } from 'vue-i18n';

const { order, reset } = useRegisterOrderStore();
const { selectedEmployeeId } = storeToRefs(useRegisterOrderStore());
const { hasSingleEmployee } = storeToRefs(useResourcesStore());

defineProps<{
  savingDraft: boolean;
}>();
const emit = defineEmits(['setSavingDraft']);

const saveDraft = () => {
  if (
    order.items.length &&
    (hasSingleEmployee.value || selectedEmployeeId.value)
  ) {
    emit('setSavingDraft');
  }
};

const { t } = useI18n();

const buttonOptions = computed<any[]>(() => {
  const options: any[] = [
    {
      label: t('global.actions.reset'),
      value: 'reset'
    }
  ];

  if ((!order.id || order.draft) && order.items.length) {
    options.unshift({
      label: t('register.save_draft'),
      value: 'draft',
      submitForm: true
    });
  }

  return options;
});

const onOptionSelect = (option: string) => {
  if (option === 'reset') {
    reset();
  } else if (option === 'draft') {
    saveDraft();
  }
};
</script>
