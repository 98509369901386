import gql from 'graphql-tag';

export const DELETE_CUSTOMERS = gql`
  mutation deleteCustomers($input: DeleteCustomersInput!) {
    deleteCustomers(input: $input) {
      status
    }
  }
`;

export const RESTORE_CUSTOMERS = gql`
  mutation restoreCustomers($input: RestoreCustomersInput!) {
    restoreCustomers(input: $input) {
      status
    }
  }
`;

export const MASSIVE_ADD_CUSTOMER_TAGS = gql`
  mutation massiveAddCustomerTags($input: MassiveAddCustomerTagsInput!) {
    massiveAddCustomerTags(input: $input) {
      status
    }
  }
`;

export const CREATE_CUSTOMERS_EXPORT = gql`
  mutation createCustomersExport($input: CreateCustomersExportInput!) {
    createCustomersExport(input: $input) {
      status
    }
  }
`;
