export default (propGroup) => {
  const parentProps = {
    error: String,
    info: String,
    label: String,
    modelValue: {
      type: [Number, String],
      required: false
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        [
          'text',
          'email',
          'tel',
          'textarea',
          'number',
          'currency',
          'password',
          'date',
          'search'
        ].indexOf(value) !== -1
    },
    inputModeText: {
      type: Boolean,
      default: false
    }
  };

  const childProps = {
    hasError: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String,
      required: true
    }
  };

  const inputProps = {
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    regex: {
      type: RegExp
    },
    placeholder: String,
    rows: {
      type: Number,
      default: 4
    },
    size: {
      type: String,
      default: 'm'
    },
    hasError: {
      type: Boolean,
      default: false
    },
    prefix: String
  };

  const numberProps = {
    controls: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: Number,
      default: 0,
      validator: (value) => !value || value > 0
    },
    minValue: Number,
    maxValue: Number,
    unitLabel: {
      type: String,
      default: '',
      validator: (value) =>
        !value || ['minute', 'percentage'].indexOf(value) !== -1
    }
  };

  switch (propGroup) {
    case 'parent':
      return {
        ...parentProps,
        ...numberProps,
        ...inputProps
      };
    case 'number':
      return {
        ...numberProps,
        ...childProps,
        ...inputProps
      };
    default:
      return {
        ...childProps,
        ...inputProps
      };
  }
};
