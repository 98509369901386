<template>
  <div>
    <CustomerSearch
      v-if="!order.customerId"
      :selectedCustomer="selectedCustomer"
      :required="!!order.items.find((item) => item.prepaidCard)"
      showCreate
      @select="selectCustomer"
      v-test="'register-order-customer-search'"
    />
    <BaseCard v-if="order.customerId && selectedCustomer" gray>
      <div :class="$style.selectedCustomer">
        <div
          :class="$style.customer"
          v-test="'register-order-customer-selected'"
        >
          <BaseAvatar
            :name="selectedCustomer.fullName"
            :url="selectedCustomer.picture"
            :mr="0.5"
          />
          {{ selectedCustomer.fullName }}
        </div>
        <div
          :class="$style.close"
          @click="selectedCustomer = null"
          v-test="'register-order-customer-selected-reset'"
        >
          <BaseIcon name="close" />
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script lang="ts" setup>
import CustomerSearch from '@/components/CustomerSearch.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';

const { order } = useRegisterOrderStore();
const { selectedCustomer } = storeToRefs(useRegisterOrderStore());
const mixpanel = inject<any>('mixpanel');

const selectCustomer = (customer: any) => {
  mixpanel.track('Register - Customer changed');
  selectedCustomer.value = customer;
};
</script>

<style lang="css" module>
.selectedCustomer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8px;
}

.customer {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.close {
  cursor: pointer;
  margin-top: 2px;
  margin-right: -8px;
}
</style>
