<template>
  <div
    ref="el"
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
    v-test="'service-day'"
  >
    <BaseCard gray mt :selected="selected">
      <div :class="$style.day">
        <div :class="$style.toggle">
          <BaseCheckbox
            v-model="option.available"
            :label="t(`global.days.${option.dayOfWeek.toLowerCase()}`)"
            v-test="'service-day-available'"
          />
        </div>
        <div v-if="option.available" :class="$style.settings">
          <div>
            <div v-if="option.startTime && option.endTime">
              <BaseInputLabel
                v-show="selected"
                :text="t('service.availability.specific_times_label')"
              />
              <div :class="$style.timepicker">
                <BaseTimePicker
                  v-model="option.startTime"
                  v-test="'service-day-startTime'"
                />
                <div>-</div>
                <BaseTimePicker
                  v-model="option.endTime"
                  :minValue="option.startTime"
                  v-test="'service-day-endTime'"
                />
                <div
                  :class="$style.close"
                  @click="
                    () => {
                      option.startTime = '';
                      option.endTime = '';
                    }
                  "
                  v-test="'service-day-times-disable'"
                >
                  <BaseIcon name="close" />
                </div>
              </div>
            </div>
            <BaseText
              v-else
              link
              @click="
                () => {
                  option.startTime = '9:00';
                  option.endTime = '17:00';
                }
              "
              v-test="'service-day-times-enable'"
            >
              {{ t('service.availability.specific_times') }}
            </BaseText>
          </div>
          <div v-if="unleash.isEnabled('ServiceAvailabilityDayMax')">
            <BaseText
              v-if="!option.max"
              link
              @click="
                () => {
                  option.max = 1;
                }
              "
              v-test="'service-day-max-enable'"
            >
              {{ t('service.availability.max_bookings') }}
            </BaseText>
            <div v-else>
              <BaseInputLabel
                v-show="selected"
                :text="t('service.availability.max_bookings_label')"
              />
              <div :class="$style.amount">
                <BaseInput
                  v-model="option.max"
                  type="number"
                  controls
                  v-test="'service-day-max'"
                />
                <div
                  :class="$style.close"
                  @click="
                    () => {
                      option.max = 0;
                    }
                  "
                  v-test="'service-day-max-disable'"
                >
                  <BaseIcon name="close" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="$style.unavailable">
          <BaseText>
            {{ t('service.availability.not_available') }}
          </BaseText>
        </div>
      </div>
    </BaseCard>
    <BaseText
      v-show="selected"
      link
      :mt="0.5"
      @click="applyToAll"
      v-test="'service-day-apply-all'"
    >
      {{ t('service.availability.apply_to_all') }}
    </BaseText>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import unleash from '@/unleash';
import type { DayOptions } from '../index';

const props = defineProps<{
  option: DayOptions;
  selected: boolean;
}>();

const emit = defineEmits(['deselect', 'apply']);

const el = ref();
onClickOutside(el, () => {
  emit('deselect');
});

const { t } = useI18n();

const applyToAll = () => {
  emit('apply', props.option);
};
</script>

<style lang="scss" module>
.day {
  display: flex;
  align-items: center;
}

.toggle {
  .base:not(.smallScreen) & {
    min-width: 130px;
  }
}

.settings,
.unavailable {
  padding-left: 10%;
}

.settings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .base:not(.smallScreen) & {
    justify-content: space-between;
  }

  .base.smallScreen & {
    flex-direction: column;
    align-items: flex-end;
  }

  & > * {
    display: flex;

    &:last-child {
      justify-content: flex-end;

      .base.smallScreen & {
        margin-top: $spacing;
      }
    }
  }
}

.close {
  padding: $spacing * 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-left: $spacing * -0.5;
  margin-right: $spacing * -0.5;
}

.timepicker,
.amount {
  display: flex;
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}

.amount {
  max-width: 150px;
}

.unavailable {
  width: 100%;
}
</style>
