<template>
  <BaseCard
    :mb="1.5"
    :mr="$screen !== 's' ? 0.5 : null"
    :heading="$t('global.settings')"
  >
    <div :class="$style.base">
      <BaseToggle
        :modelValue="customer.blocked"
        :label="$t('global.actions.block_customer')"
        @update:modelValue="updateCustomer('blocked', $event)"
        v-test="'customerSettingsBlock'"
      />

      <BaseToggle
        :modelValue="customer.remindersEmailEnabled"
        :label="$t('customers.email_appointment_reminders')"
        @update:modelValue="updateCustomer('remindersEmailEnabled', $event)"
      />

      <BaseToggle
        :modelValue="customer.remindersSmsEnabled"
        :label="$t('customers.sms_appointment_reminders')"
        @update:modelValue="updateCustomer('remindersSmsEnabled', $event)"
      />

      <BaseToggle
        :modelValue="customer.newsletterEnabled"
        :label="$t('global.newsletters')"
        @update:modelValue="updateCustomer('newsletterEnabled', $event)"
        v-test="'customerSettingsNewsletter'"
      />

      <BaseToggle
        :modelValue="customer.birthdayWishesEnabled"
        :label="$t('customers.birthday_wishes')"
        @update:modelValue="updateCustomer('birthdayWishesEnabled', $event)"
      />

      <BaseToggle
        v-if="hasFeatureFlag('module-feedback')"
        :modelValue="customer.feedbackEmailEnabled"
        :label="$t('customers.feedback_requests')"
        @update:modelValue="updateCustomer('feedbackEmailEnabled', $event)"
      />

      <BaseToggle
        v-if="hasFeatureFlag('module-marketing')"
        :modelValue="customer.rebookEmailEnabled"
        :label="$t('customers.rebook_reminders')"
        @update:modelValue="updateCustomer('rebookEmailEnabled', $event)"
      />
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import { flash } from '@/helpers/ui';
import { useUserStore } from '@/stores/user';
import { UPDATE_CUSTOMER } from '../graphql';
import { useMutation } from '@vue/apollo-composable';
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['refresh']);
const customer = inject<any>('customer');

const { hasFeatureFlag } = useUserStore();
const { t } = useI18n();

const { mutate } = useMutation(UPDATE_CUSTOMER);

const updateCustomer = (key: string, value: any) => {
  customer.value[key] = value;

  mutate({
    input: {
      id: customer.value.id,
      customerAttributes: {
        remindersEmailEnabled: customer.value.remindersEmailEnabled,
        remindersSmsEnabled: customer.value.remindersSmsEnabled,
        //newsletterEnabled: customer.value.newsletterEnabled,
        birthdayWishesEnabled: customer.value.birthdayWishesEnabled,
        rebookEmailEnabled: customer.value.rebookEmailEnabled,
        feedbackEmailEnabled: customer.value.feedbackEmailEnabled,
        firstName: customer.value.firstName,
        lastName: customer.value.lastName,
        [key]: value
      }
    }
  }).then(() => {
    flash(t('global.flash.settings_updated'));
    emit('refresh');
  });
};
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
