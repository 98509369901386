<template>
  <SettingsBase v-if="!isBlocked" :navItems="navItems" />
  <router-view v-else />
</template>

<script lang="ts">
import SettingsBase from '@/modules/base-settings/index.vue';
import { useCompanyStore } from '@/stores/company';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Admin',
  components: {
    SettingsBase
  },
  computed: {
    ...mapState(useCompanyStore, ['isBlocked', 'isTreatwellUser']),
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    navItems() {
      return this.isBlocked
        ? {
            groups: [],
            items: []
          }
        : {
            groups: this.navGroups,
            items: this.navGroupItems
          };
    },
    navGroups() {
      return [
        {
          name: 'general',
          label: this.$t('admin.nav.headings.general')
        },
        {
          name: 'company',
          label: this.$t('admin.nav.headings.company')
        },
        {
          name: 'calendar',
          label: this.$t('admin.nav.headings.calendar')
        },
        {
          name: 'customers',
          label: this.$t('admin.nav.headings.customers')
        },
        {
          name: 'sales',
          label: this.$t('admin.nav.headings.sales')
        },
        {
          name: 'promote',
          label: this.$t('nav.promote')
        }
      ];
    },
    navGroupItems() {
      return [
        {
          name: 'admin-general',
          label: this.$t('admin.nav.links.general'),
          group: 'general',
          show: this.hasFeatureFlag('admin-settings-general')
        },
        {
          name: 'admin-export',
          label: this.$t('admin.nav.links.export'),
          group: 'general',
          show: this.hasFeatureFlag('admin-export')
        },
        {
          name: 'admin-accounts',
          label: this.$t('admin.nav.links.accounts'),
          group: 'general',
          show: this.hasFeatureFlag('admin-accounts')
        },
        {
          name: 'integrations',
          label: this.$t('nav.integrations'),
          group: 'general',
          show: this.hasFeatureFlag('module-integrations')
        },
        {
          name: 'admin-billing',
          label: this.$t('nav.billing'),
          group: 'general',
          show: this.hasFeatureFlag('module-billing') && !this.isTreatwellUser
        },
        {
          name: 'admin-company',
          label: this.$t('admin.nav.links.company'),
          group: 'company',
          show: this.hasFeatureFlag('admin-company-info')
        },
        {
          name: 'admin-absences',
          label: this.$t('admin.nav.links.absences'),
          group: 'company',
          show: this.hasFeatureFlag('admin-absence')
        },
        {
          name: 'admin-locations',
          label: this.$t('admin.nav.links.locations'),
          group: 'company',
          show: this.hasFeatureFlag('admin-locations')
        },
        {
          name: 'admin-employees',
          label: this.$t('admin.nav.links.employees'),
          group: 'company',
          show: this.hasFeatureFlag('admin-employees'),
          event: 'Employee tab clicked'
        },
        {
          name: 'admin-resources',
          label: this.$t('admin.nav.links.resources'),
          group: 'company',
          show: this.hasFeatureFlag('admin-employees'),
          event: 'Resources tab clicked'
        },
        {
          name: 'admin-roster',
          label: this.$t('admin.nav.links.roster'),
          group: 'company',
          show: this.hasFeatureFlag('admin-roster')
        },
        {
          name: 'admin-calendar',
          label: this.$t('calendar.nav.calendar_settings'),
          group: 'calendar',
          show: this.hasFeatureFlag('admin-settings-appointments')
        },
        {
          name: 'admin-services',
          label: this.$t('admin.nav.links.services'),
          group: 'calendar',
          show: this.hasFeatureFlag('admin-services'),
          event: 'Services tab clicked'
        },
        {
          name: 'admin-online-bookings',
          label: this.$t('admin.nav.links.online_bookings'),
          group: 'calendar',
          show: true
        },
        {
          name: 'admin-customers',
          label: this.$t('admin.nav.links.customers'),
          group: 'customers',
          show: this.hasFeatureFlag('admin-settings-customers')
        },
        {
          name: 'admin-forms',
          label: this.$t('admin.nav.links.forms'),
          group: 'customers',
          show: this.hasFeatureFlag('admin-forms') && !this.user.admin_medical
        },
        {
          name: 'admin-invoices',
          label: this.$t('admin.nav.links.invoices'),
          group: 'sales',
          show: this.hasFeatureFlag('admin-settings-invoice')
        },
        {
          name: 'admin-vat-rates',
          label: this.$t('admin.nav.links.vat_rates'),
          group: 'sales',
          show: this.hasFeatureFlag('admin-taxes')
        },
        {
          name: 'admin-loyalty-points',
          label: this.$t('admin.nav.links.loyalty_points'),
          group: 'sales',
          show: this.hasFeatureFlag('admin-settings-loyalty-points')
        },
        {
          name: 'admin-micro-site',
          label: this.$t('promote.nav.links.micro_site'),
          group: 'promote',
          show: true
        },
        {
          name: 'admin-feedback-widget',
          label: this.$t('promote.nav.links.feedback_widget'),
          group: 'promote',
          show: this.hasFeatureFlag('module-feedback')
        },
        {
          name: 'admin-pricing-widget',
          group: 'promote',
          label: this.$t('promote.nav.links.pricing_widget'),
          show: true
        },
        {
          name: 'admin-giftcard-widget',
          label: this.$t('promote.nav.links.voucher_widget'),
          group: 'promote',
          show: this.hasFeatureFlag('module-register')
        }
      ];
    }
  }
});
</script>
