<template>
  <router-link :to="route" :class="$style.base">
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    isAppointment() {
      return (
        this.data.__typename === 'Appointment' ||
        this.data.recordType === 'Appointment'
      );
    },
    isAbsence() {
      return this.data.__typename === 'Absence';
    },
    isChore() {
      return this.isAbsence && this.data.chore;
    },
    isFeedback() {
      return this.data.recordType === 'Feedback';
    },
    isFormSubmission() {
      return this.data.recordType === 'FormSubmission';
    },
    isImported() {
      return this.data.calendarAbsence?.extendedProps?.imported;
    },
    route() {
      if (this.isImported) {
        return { name: 'calendar' };
      } else if (this.isAppointment) {
        return { name: 'appointment', params: { appointmentId: this.id } };
      } else if (this.isAbsence && !this.isChore) {
        return { name: 'absence', params: { id: this.id } };
      } else if (this.isChore) {
        return { name: 'chore', params: { id: this.id } };
      } else if (this.isFeedback) {
        return { name: 'feedback-item', params: { feedbackId: this.id } };
      } else if (this.isFormSubmission && this.data.customer) {
        return {
          name: 'customer-form-preview',
          params: { customerId: this.data.customer.id, id: this.id }
        };
      } else {
        return {};
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  padding: $spacing;
  margin: 0 $spacing * -1 0;

  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &:last-child {
    margin-bottom: $spacing * -1;
    border-radius: 0 0 $radius $radius;
  }

  @include hover {
    background-color: $color-highlight;
  }
}
</style>
