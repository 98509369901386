<template>
  <PageHeader>
    <template #left>
      <div>
        <BaseDropdown
          v-model="filterValue"
          :options="filterOptions"
          v-test="'resource-header-filter'"
        />
      </div>
      <div>
        <BaseSearch v-model="searchValue" v-test="'resource-header-search'" />
      </div>
    </template>
  </PageHeader>
</template>

<script lang="ts">
import PageHeader from '@/modules/PageHeader.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    PageHeader
  },
  props: {
    filterOptions: {
      type: Array
    },
    filter: {
      type: String,
      required: true
    },
    searchQuery: {
      type: String
    }
  },
  emits: ['onSearch', 'update:filter', 'update:searchQuery'],
  computed: {
    filterValue: {
      get() {
        return this.filter;
      },
      set(value) {
        this.$emit('update:filter', value);
      }
    },
    searchValue: {
      get() {
        return this.searchQuery;
      },
      set(value) {
        this.$emit('update:searchQuery', value);
      }
    }
  }
});
</script>
