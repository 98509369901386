<template>
  <BaseCard
    :heading="$t('dashboard.online_bookings.title')"
    v-test="'dashboard-online-bookings'"
  >
    <div :class="$style.inner">
      <img src="/img/dashboard/online-bookings.svg" />
      <BaseButton
        :routerLink="{ name: 'admin-online-bookings' }"
        color="inverted"
        mt
        @click="mixpanel.track('Onboarding - Booking widget card clicked')"
        v-test="'dashboard-online-bookings-link'"
      >
        {{ $t('dashboard.online_bookings.link') }}
      </BaseButton>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  inject: ['mixpanel']
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
