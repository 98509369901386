import gql from 'graphql-tag';

export const GET_LOYALTY_TRANSACTIONS = gql`
  query getLoyaltyTransactions(
    $customerId: ID
    $pagination: PaginationAttributes!
  ) {
    loyaltyTransactions(customerId: $customerId, pagination: $pagination) {
      loyaltyTransactions {
        id
        amount
        description
        transactionAt
        orderId
        orderNumber
      }
      metadata
    }
  }
`;

export const CREATE_LOYALTY_TRANSACTION = gql`
  mutation createLoyaltyTransaction($input: CreateLoyaltyTransactionInput!) {
    createLoyaltyTransaction(input: $input) {
      loyaltyTransaction {
        id
      }
    }
  }
`;

export const DELETE_LOYALTY_TRANSACTION = gql`
  mutation deleteLoyaltyTransaction($input: DeleteLoyaltyTransactionInput!) {
    deleteLoyaltyTransaction(input: $input) {
      loyaltyTransaction {
        id
      }
    }
  }
`;
