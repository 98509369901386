<template>
  <BaseTableRow>
    <BaseTableCell wordBreak :width="35">
      {{ product.name }}
    </BaseTableCell>
    <BaseTableCell :width="30">
      <BaseInput
        v-model="selectedAmount"
        type="number"
        controls
        :minValue="1"
        v-test="'selectedAmountInput'"
      />
    </BaseTableCell>
    <BaseTableCell :width="25">
      <BaseInput
        v-model="costPrice"
        type="currency"
        v-test="'changeCostPrice'"
      />
    </BaseTableCell>
    <BaseTableCell :width="25">
      {{ filters.currency(product.costPrice * product.quantity) }}
    </BaseTableCell>
    <BaseTableCell
      :class="$style.clickable"
      :width="5"
      right
      @click="onRemove(product)"
      v-test="'orderRemoveItem'"
    >
      <BaseIcon name="delete" color="primary" />
    </BaseTableCell>
  </BaseTableRow>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import type { SelectedProduct } from './types';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  emits: ['updateSelectedProduct', 'deleteSelectedProduct'],
  setup() {
    return {
      filters
    };
  },
  computed: {
    selectedAmount: {
      get() {
        return this.product.quantity;
      },
      set(value: number) {
        this.$emit('updateSelectedProduct', {
          productId: this.product.productId,
          quantity: value
        });
      }
    },
    costPrice: {
      get() {
        return this.product.costPrice;
      },
      set(value: number) {
        this.$emit('updateSelectedProduct', {
          productId: this.product.productId,
          costPrice: value || 0
        });
      }
    }
  },
  methods: {
    onRemove(item: SelectedProduct) {
      this.$emit('deleteSelectedProduct', item);
    }
  }
});
</script>

<style lang="scss" module>
.clickable {
  cursor: pointer;
}
</style>
