<template>
  <div :class="$style.base">
    <Reply
      v-for="(message, index) in selected.messages"
      :key="index"
      :message="message"
      v-test="'feedback-reply'"
    />
  </div>
</template>

<script setup lang="ts">
import Reply from './Reply.vue';
import { inject } from 'vue';
const selected = inject<any>('selected');
</script>

<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }
}
</style>
