<template>
  <div :class="$style.base" v-test="'deletedOverlay'">
    <div :class="$style.inner">
      <BaseHeading size="xl" :mb="0.5">
        {{ $t('customers.deleted_customer') }}
      </BaseHeading>
      <BaseText>
        {{ $t('customers.restore_a_customer') }}
      </BaseText>
    </div>
  </div>
</template>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.9;
  z-index: 99999;
  width: 100%;
  height: 100%;
}

.inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 100px;
}
</style>
