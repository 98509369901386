<template>
  <BaseModal
    :heading="$t('register.pos.heading.send')"
    small
    @close="$emit('close')"
  >
    <div>
      <BaseHeading size="s" center :mb="0.5">
        {{ $t('register.pos.amount') }}
      </BaseHeading>
      <BaseHeading size="xl" center :mb="2" v-test="'pos-terminal-amount'">
        {{ filters.currency(amount) }}
      </BaseHeading>
      <BaseText center>
        {{ $t('register.pos.select') }}
      </BaseText>
      <BaseDropdown
        v-model="selectedPosTerminal"
        mt
        :mb="2"
        :label="$t('global.items.pos_terminal', 1)"
        :options="
          terminals.map((t: any) => ({
            value: t.terminalUid,
            label: t.terminalUid
          }))
        "
        v-test="'pos-terminal-select'"
      />
    </div>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="$emit('close')"
        v-test="'pos-terminal-cancel'"
      >
        {{ $t('register.pos.cancel') }}
      </BaseButton>
      <BaseButton
        @click="$emit('submit', selectedPosTerminal)"
        v-test="'pos-terminal-confirm'"
      >
        {{ $t('register.pos.send') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { ref } from 'vue';

const props = defineProps<{
  amount: number;
  selectedTerminal: string;
  terminals: any[];
}>();

defineEmits(['close', 'submit']);

const selectedPosTerminal = ref<string | null>(props.selectedTerminal);
if (
  !props.terminals.find(
    (terminal) => terminal.terminalUid === props.selectedTerminal
  )
) {
  selectedPosTerminal.value = null;
}
</script>
