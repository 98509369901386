import dayjs from '@/dayjs';
import eventBus from '@/event-bus';
import i18n from '@/i18n';
import { showTooltip } from '@/helpers/ui';
import { useRouter } from 'vue-router';

import { useCalendarFiltersStore } from '@/stores/calendar-filters';
import { useResourcesStore } from '@/stores/resources';
import { usePreCreateStore } from '@/stores/calendar-pre-create';
import { useCreateEventStore } from '@/stores/calendar-create-event';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';

import calendarInterface from '@/modules/calendar/calendar-interface';
import { calendarOptions as dragResizeOptions } from './drag-resize';

import { checkAvailability } from '../../actions/availabilities';
import {
  duplicateAppointment,
  rescheduleAppointment
} from '../../actions/appointments';

import { isCalendarSaving } from '../../calendar-state';
import unleash from '@/unleash';

export const getCalendarInteractionOptions = () => {
  const router = useRouter();

  const selectDate = (data: any) => {
    const { action, appointmentAttributes } = usePreCreateStore();
    if (action && action !== 'CREATE') {
      const appointment = { ...appointmentAttributes, startAt: data.start };

      const newResourceId = data.resource ? parseInt(data.resource.id) : null;
      const { oldResourceId } = usePreCreateStore();

      if (newResourceId && newResourceId !== oldResourceId) {
        appointment.parts.forEach((p: any) => {
          p.resourceIds = [newResourceId];
        });
      }

      isCalendarSaving.value = true;

      if (action === 'COPY') {
        checkAvailability({ ...appointment, appointmentId: null }).then(
          (continueWithAction) => {
            if (continueWithAction) {
              duplicateAppointment({ appointment, newResourceId });
            } else {
              isCalendarSaving.value = false;
            }
          }
        );
      } else if (action === 'RESCHEDULE') {
        checkAvailability(appointment).then((continueWithAction) => {
          if (continueWithAction) {
            const input: any = {
              id: appointment.appointmentId,
              startAt: appointment.startAt
            };
            if (newResourceId) {
              input.newResourceId = newResourceId;
              input.oldResourceId = oldResourceId;
            }
            if (appointment.rrule) {
              input.recurrenceStartAt = appointment.originalStartAt;
            }
            rescheduleAppointment({
              input,
              rrule: appointment.rrule,
              original: appointment.original
            });
          } else {
            isCalendarSaving.value = false;
          }
        });
      }
    } else {
      const { setEventData } = useCreateEventStore();
      const { start, end, resource } = data;
      const { previewModeActive } = useCalendarPreviewStore();

      let resourceId = resource?.id ? parseInt(resource.id) : null;

      if (previewModeActive) {
        // When selecting a date/time while in preview mode, set the resource to the selected resource in the calendar filters
        const { resourceId: calendarFiltersResource } =
          useCalendarFiltersStore();
        if (calendarFiltersResource) {
          resourceId = calendarFiltersResource;
        }
      }

      setEventData({
        startDate: start,
        endDate: end,
        resourceId
      });

      if (!previewModeActive) {
        const { dynamicPricingMode } = useCalendarFiltersStore();

        if (dynamicPricingMode) {
          router.push({ name: 'dynamic-price' });
        } else {
          router.push({ name: 'create-appointment' });
        }
      }
    }

    calendarInterface.api?.unselect();
  };

  const showPopover = (name: string, data: any) => {
    eventBus.$emit('showPopover', { name, data });
  };

  let clicked = false;

  return {
    ...dragResizeOptions,
    dateClick: (info: any) => {
      if (isCalendarSaving.value) {
        return;
      }

      clicked = true;
      setTimeout(() => {
        clicked = false;
      }, 100);

      // Clicking on a timeslot
      if (info.allDay) {
        showPopover('create', info);
      } else {
        const start = dayjs(info.dateStr);

        selectDate({
          start: start.format(),
          resource: info.resource
        });
      }
    },

    select: (info: any) => {
      if (isCalendarSaving.value) {
        return;
      }

      // Selecting a timeslot, either by clicking or selecting a range
      // When a user clicks a date, both dateClick and select get triggered, so adding an extra check here to prevent both functions from running
      // We need both select and dateClick for touch devices, because on touch devices you need to tap and hold for a second for the select event to trigger.
      setTimeout(() => {
        if (clicked) {
          return;
        }

        const { action } = usePreCreateStore();

        if (!clicked && !info.allDay && !action) {
          selectDate({
            start: info.startStr,
            end: info.endStr,
            resource: info.resource
          });
        }
      }, 0);
    },
    moreLinkClick: (info: any) => {
      showPopover('more', info);
      // expects a return value, else fc will throw a getBoundingClientRect error
      return {};
    },
    eventClick: (info: any) => {
      const { previewModeActive } = useCalendarPreviewStore();
      if (previewModeActive) {
        return;
      }

      const { action } = usePreCreateStore();
      if (action === 'RESCHEDULE' || action === 'COPY') {
        return;
      }

      const { event } = info;
      const { extendedProps } = event;
      const { hasSingleEmployee } = useResourcesStore();

      if (extendedProps.pending) {
        showTooltip(i18n.t('calendar.appointment_being_booked'), info.el);
      } else if (extendedProps.type === 'dynamic_price') {
        router.push({
          name: 'dynamic-price',
          params: { dynamicPriceId: extendedProps.entryId }
        });
      } else if (extendedProps.type === 'waiting_list') {
        router.push({
          name: 'calendar-waiting-list',
          query: { date: event.startStr }
        });
      } else if (
        hasSingleEmployee &&
        extendedProps.type === 'appointment' &&
        dayjs(event.startStr)
          .tz()
          .isSame(extendedProps.originalStartAt, 'day') &&
        !unleash.isEnabled('ShowCalendarPopoverSingleEmployee')
      ) {
        router.push({
          name: 'appointment',
          params: { appointmentId: extendedProps.entryId }
        });
      } else if (!isCalendarSaving.value) {
        showPopover('event', info);
      }
    },
    navLinkDayClick: (date: Date) => {
      // This is triggered when clicking on a day of the week in the calendar header, while in week view
      const filtersStore = useCalendarFiltersStore();

      // Switch to day view
      filtersStore.viewType = 'DAY';

      // Navigate fullcalendar to the correct date
      if (calendarInterface.api) {
        calendarInterface.api.gotoDate(date);
      }

      // Update the date in the store
      const newDate = dayjs(date).tz().format('YYYY-MM-DD');
      filtersStore.date = newDate;
    },
    navLinkWeekClick: () => {
      // This is triggered when clicking on the weeknumber in the calendar header, when in day view
      // It should switch to week view, and the date can stay the same
      const filtersStore = useCalendarFiltersStore();
      filtersStore.viewType = 'WEEK';
    }
  };
};
