<template>
  <div
    :class="[
      $style.base,
      {
        [$style.empty]: isEmpty,
        [$style.smallScreen]: $screen === 's' || $screen === 'm',
        [$style.override]: isOverride,
        [$style.overrideBlank]: isOverrideBlank,
        [$style.differentLocation]: isDifferentLocation,
        [$style.absence]: isAbsence
      }
    ]"
    v-test="'roster-entry'"
  >
    <EditRosterModal
      v-if="showModal"
      :entry="entry"
      @close="showModal = false"
    />
    <div :class="$style.inner" @click="onClick">
      <div v-if="isOverride || isOverrideBlank" :class="$style.originalTimes">
        <div v-if="entry.original_times.length">
          {{ entry.original_times[0].open }} -
          {{ entry.original_times[entry.original_times.length - 1].closed }}
        </div>
      </div>

      <div :class="$style.times">
        <div v-if="entry.times.length">
          {{ entry.times[0].open }} -
          {{ entry.times[entry.times.length - 1].closed }}
        </div>
        <BaseIcon
          v-if="isEmpty && !isAbsence"
          name="plus"
          size="s"
          color="success"
        />
        <BaseText v-if="isAbsence">
          {{ $t('global.unavailable') }}
        </BaseText>
        <div :class="$style.icons">
          <BaseIcon
            v-if="entry.state === 'recurring'"
            name="repeat"
            size="xs"
            :color="isDifferentLocation ? 'success' : 'inverted'"
            :tooltip="{
              text: $t('roster.recurring_entry_tooltip'),
              touch: true
            }"
          />
          <BaseIcon
            v-if="isOutsideOpeningHours && !isDifferentLocation"
            name="alert"
            size="xs"
            color="inverted"
            :tooltip="{
              text: $t('roster.outside_company_hours'),
              touch: true
            }"
          />
          <BaseIcon
            v-if="isOverride || isOverrideBlank"
            name="edit"
            size="xs"
            :color="isDifferentLocation ? 'success' : 'inverted'"
            :tooltip="{
              text: $t('roster.override_tooltip'),
              touch: true
            }"
          />
        </div>
      </div>
      <BaseText v-if="isDifferentLocation && location" oneLine>
        {{ location.internalName }}
      </BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLocationsStore } from '@/stores/locations';
import EditRosterModal from './EditRosterModal.vue';
import type { Entry } from './types';
import { modal } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import axios from '@/axios';
import { checkIsOutsideOpeningHours } from './helpers';

const props = defineProps<{
  entry: Entry;
}>();

const showModal = ref(false);
const isOverride = computed(() => props.entry.state === 'override_single');
const isOverrideBlank = computed(() => props.entry.state === 'override_blank');
const isEmpty = computed(
  () => !props.entry.times.length && !isOverrideBlank.value
);
const isDifferentLocation = computed(() => {
  const { locationId } = useLocationsStore();
  return props.entry.location_id && props.entry.location_id !== locationId;
});
const isAbsence = computed(() => props.entry.state === 'absent');

const location = computed(() => {
  const { locationById } = useLocationsStore();
  return props.entry.location_id ? locationById(props.entry.location_id) : null;
});

const entries = inject<Ref<Entry[]>>('entries');

const { t } = useI18n();

const onClick = () => {
  if (isAbsence.value) {
    return;
  }

  if (isOverrideBlank.value) {
    modal('confirmation', {
      message: t('roster.recurring_restore')
    }).then(() => {
      axios
        .put(`/roster_entries/${props.entry.id}`, {
          ...props.entry,
          state: 'recurring'
        })
        .then(({ data }) => {
          const existingEntry = entries?.value.find(
            (entry) => entry.id === props.entry.id
          );
          if (existingEntry) {
            Object.keys(data.roster_entry).forEach((key) => {
              existingEntry[key] = data.roster_entry[key];
            });
          }
        });
    });
  } else {
    showModal.value = true;
  }
};

const isOutsideOpeningHours = computed(() =>
  checkIsOutsideOpeningHours(props.entry)
);
</script>

<style lang="scss" module>
.base {
  width: calc(100% / 7);
}

.inner {
  color: white;
  padding: 4px;
  border-radius: $radius;

  .base.smallScreen & {
    height: 65px;
  }

  .base:not(.smallScreen) & {
    height: 50px;
  }

  .base:not(.smallScreen) &,
  .base.empty & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .base:not(.base.absence) & {
    cursor: pointer;
  }

  .base.smallScreen & {
    font-size: 11px;
  }

  .base:not(.empty) & {
    background-color: $color-success;
  }

  .base.empty & {
    background-color: $color-highlight;
  }

  .base.override & {
    background-color: $color-primary;
  }

  .base.overrideBlank & {
    background-color: #aaa;
  }

  .base.differentLocation & {
    background-color: white;
    color: $color-success;
  }

  .base.absence & {
    background-color: $color-error;
  }
}

.times {
  .base:not(.smallScreen) &,
  .base.empty & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icons {
  display: flex;

  .base:not(.smallScreen) & {
    margin-left: $spacing * 0.5;
  }

  .base.smallScreen & {
    justify-content: center;
    margin-top: 2px;
  }

  &:empty {
    display: none;
  }
}

.originalTimes {
  text-decoration: line-through;

  .base.override & {
    font-size: 10px;
  }

  .base.override.smallScreen & {
    display: none;
  }
}
</style>
