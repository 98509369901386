<template>
  <div :class="$style.base">
    <slot />
  </div>
</template>

<style lang="scss" module>
.base {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $color-border;
  padding: $spacing;
  margin: $spacing * -1;
  width: calc(100% + #{$spacing * 2});
}
</style>
