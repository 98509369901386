<template>
  <div>
    <BaseHeading mb size="s">
      {{ filters.capitalize($t('global.items.product', 2)) }}
    </BaseHeading>

    <BaseSpinner v-if="loading" inline />
    <div v-else>
      <Category
        v-for="(category, index) in productCategories"
        :key="index"
        :categoryId="category.id"
        :name="category.name"
        :index="index"
      />
    </div>
    <Uncategorized />
    <LoadMore
      v-show="!allDataFetched && firstFetchFinished"
      @shown="fetchMore"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ProductsList'
};
</script>

<script setup lang="ts">
import filters from '@/filters';
import { usePagination } from '@/apollo/pagination';
import { GET_PRODUCT_CATEGORIES } from '../../graphql';
import Category from './Category.vue';
import LoadMore from '@/components/LoadMore.vue';
import Uncategorized from './Uncategorized.vue';

const {
  productCategories,
  allDataFetched,
  fetchMore,
  firstFetchFinished,
  loading
} = usePagination({
  fieldName: 'productCategories',
  query: GET_PRODUCT_CATEGORIES
});
</script>
