<template>
  <div :class="$style.base">
    <div :class="$style.inner">
      <BaseTableRow head>
        <BaseTableCell :width="10">{{ $t('global.id') }}</BaseTableCell>
        <BaseTableCell :width="30">{{ $t('global.supplier') }}</BaseTableCell>
        <BaseTableCell :width="30">{{ $t('global.created') }}</BaseTableCell>
        <BaseTableCell :width="30">{{ $t('global.quantity') }}</BaseTableCell>
        <BaseTableCell :width="20">{{ $t('global.status') }}</BaseTableCell>
        <div :class="$style.showMore">
          <BaseTableCell />
        </div>
      </BaseTableRow>
      <BaseTableRow
        v-for="(item, index) in stockOrders"
        :key="`row-${index}`"
        link
        :options="['delete']"
        zebra
        :routerLink="{
          name: item.status === 'open' ? 'products-order-build' : 'stock-order',
          params: {
            stockOrderId: item.id
          }
        }"
        @optionClick="onOptionClick(item, $event)"
        v-test="'baseTableRow'"
      >
        <BaseTableCell :width="10">{{ item.cid }}</BaseTableCell>
        <BaseTableCell :width="30">{{
          item && item.supplier && item.supplier.name ? item.supplier.name : '-'
        }}</BaseTableCell>
        <BaseTableCell :width="30">{{
          filters.dateTime(item.createdAt)
        }}</BaseTableCell>
        <BaseTableCell :width="30">
          {{ $t('products.products_amount', { amount: item.quantity }) }}
        </BaseTableCell>
        <BaseTableCell :width="20">
          <BaseLabel :state="item.status">
            {{ item.status }}
          </BaseLabel>
        </BaseTableCell>
      </BaseTableRow>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal, flash } from '@/helpers/ui';
import gql from 'graphql-tag';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    stockOrders: {
      type: Array,
      required: true
    }
  },
  setup() {
    return {
      filters
    };
  },
  methods: {
    deleteStockOrder(id) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteStockOrder($input: DeleteStockOrderInput!) {
              deleteStockOrder(input: $input) {
                stockOrder {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              id
            }
          },
          update(cache) {
            const cacheId = `StockOrder:${id}`;
            cache.evict({ id: cacheId, broadcast: false });
            cache.gc();
          }
        })
        .then(() => {
          flash(this.$t('global.flash.stock_order_deleted'));
        });
    },
    onOptionClick(item, option) {
      if (option === 'delete') {
        modal('confirmation', { type: 'delete' }).then(() => {
          this.deleteStockOrder(item.id);
        });
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  overflow-x: auto;
  min-height: 70vh;
}

.inner {
  min-width: 900px;
}

.showMore {
  width: 52px !important;
  flex: none !important;
}
</style>
