<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="3">
        <BaseSearch debounce @update:modelValue="$emit('search', $event)" />
      </BaseGrid>
      <BaseGrid alignRight>
        <BaseButton
          :routerLink="{ name: 'products-create-category' }"
          v-test="'createCategory'"
        >
          {{ $t('global.actions.create_category') }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['search']
});
</script>
