import gql from 'graphql-tag';

export const GET_FEEDBACKS = gql`
  query getFeedbacks(
    $locationId: ID
    $dataScope: DataScope
    $pagination: PaginationAttributes
    $publishMode: Boolean
    $fromDate: ISO8601Date
    $sortBy: String
    $direction: SortDirectionEnum
    $reactionFilter: FeedbackReactionFilterEnum
    $publishingStatusFilter: FeedbackPublishingStatusFilterEnum
  ) {
    feedbacks(
      locationId: $locationId
      dataScope: $dataScope
      pagination: $pagination
      publishMode: $publishMode
      fromDate: $fromDate
      sortBy: $sortBy
      direction: $direction
      reactionFilter: $reactionFilter
      publishingStatusFilter: $publishingStatusFilter
    ) {
      comment
      customer {
        id
        fullName
      }
      id
      score
      source
      state
      submittedAt
      twPublishedAt
    }
  }
`;

export const GET_FEEDBACK = gql`
  query getFeedback($id: Int!) {
    feedback(id: $id) {
      appointment {
        id
        startAt
        serviceNames
        employeeNames
      }
      comment
      customer {
        id
        fullName
      }
      deletedAt
      id
      messages {
        id
        message
        subject
        createdAt
      }
      messagesPublished
      score
      source
      state
      submittedAt
      twPublishedAt
    }
  }
`;

export const GET_FEEDBACK_AGGREGATE = gql`
  query getFeedbackAggregate(
    $locationId: ID
    $dataScope: DataScope
    $publishMode: Boolean
    $fromDate: ISO8601Date
  ) {
    feedbackAggregate(
      locationId: $locationId
      dataScope: $dataScope
      publishMode: $publishMode
      fromDate: $fromDate
    ) {
      batchAvg
      starsCount1
      starsCount2
      starsCount3
      starsCount4
      starsCount5
      total
    }
  }
`;

export const TREATWELL_LAST_FEEDBACK_PUBLISHED = gql`
  query getTreatwellLastFeedbackPublished(
    $locationId: ID
    $dataScope: DataScope
  ) {
    treatwellLastFeedbackPublished(
      locationId: $locationId
      dataScope: $dataScope
    ) {
      twPublishedAt
    }
  }
`;

export const PUBLISH_FEEDBACK_BATCH_MUTATION = gql`
  mutation publishFeedbackBatch($input: PublishFeedbackBatchInput!) {
    publishFeedbackBatch(input: $input) {
      errors
      success
    }
  }
`;

export const SEND_FEEDBACK_RESPONSE = gql`
  mutation sendFeedbackResponse($input: SendFeedbackResponseInput!) {
    sendFeedbackResponse(input: $input) {
      errors
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation updateFeedback($input: UpdateFeedbackInput!) {
    updateFeedback(input: $input) {
      feedback {
        id
        state
        twPublishedAt
        messagesPublished
      }
    }
  }
`;

export const REQUEST_FEEDBACK_PUBLICATION = gql`
  mutation requestFeedbackPublication(
    $input: RequestFeedbackPublicationInput!
  ) {
    requestFeedbackPublication(input: $input) {
      feedback {
        id
      }
    }
  }
`;

export const UPDATE_FEEDBACK_RESPONSE = gql`
  mutation updateFeedbackResponse($input: UpdateFeedbackResponseInput!) {
    updateFeedbackResponse(input: $input) {
      response {
        message
      }
    }
  }
`;

export const UPDATE_LAST_VISIT = gql`
  mutation updateLastVisit($input: UpdateLastVisitInput!) {
    updateLastVisit(input: $input) {
      result
      errors
    }
  }
`;

export const HTML_TO_PLAIN_TEXT = gql`
  query htmlToPlainText($htmlText: String!) {
    htmlToPlainText(htmlText: $htmlText) {
      text
    }
  }
`;
