<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.previewMode]: previewModeActive
      }
    ]"
  >
    <BaseSpinner v-if="isFetchingAppointment" />
    <div v-else :class="$style.main">
      <TimeslotPicker
        v-if="showTimeslotPicker"
        @close="showTimeslotPicker = false"
      />
      <div ref="scrollContainer" :class="$style.content">
        <BaseForm :scrollContainer="$screen !== 's' ? scrollContainer : null">
          <div :class="$style.section">
            <AppointmentParts mb />
          </div>
          <div v-if="previewModeActive" :class="$style.section">
            <Customer />
            <CustomerMember
              :customerMember="existingAppointmentData.customerMember"
              :mt="0.5"
            />
          </div>
          <div :class="$style.section">
            <BaseHeading mb>
              {{ $t('global.date_time') }}
            </BaseHeading>
            <BaseCard mb horizontalContent>
              <BaseDropdown
                v-if="multiLocation"
                v-model="formData.locationId"
                :options="
                  locations.map((location) => ({
                    value: location.id,
                    label: location.internalName
                  }))
                "
                :label="filters.capitalize($t('global.items.location', 1))"
                v-test="'appointment-location'"
              />
              <BaseDateTime
                v-model="formData.startAt"
                :label="$t('global.date_time')"
                v-test="'appointment-date'"
              />
              <BaseButton
                color="inverted"
                :disabled="
                  !canCheckAvailability ||
                  !!existingAppointmentData.deletedServices.length
                "
                @click="showTimeslotPicker = true"
                v-test="'appointment-timeslots'"
              >
                {{ $t('appointment.create.find_timeslots') }}
              </BaseButton>
            </BaseCard>

            <BaseCheckbox
              v-model="showRrule"
              :disabled="disableRrule"
              :label="$t('appointment.recurring.repeat_appointment')"
              :tooltip="
                existingAppointmentData.treatwell
                  ? {
                      position: 'bottom',
                      text: $t('appointment.recurring_treatwell_disabled')
                    }
                  : null
              "
              mb
              v-test="'appointment-recurring'"
            />
            <div v-if="showRrule" :class="{ [$style.disabled]: disableRrule }">
              <RecurringRules
                v-model="formData.rrule"
                :date="formData.startAt"
                v-test="'appointment-recurring-rules'"
              />
            </div>
            <BaseAlert
              v-if="disableRrule && !existingAppointmentData.treatwell"
              color="warning"
              :text="$t('appointment.recurring_disabled')"
              mt
              v-test="'rrule-disabled-message'"
            />
          </div>
          <div :class="$style.section">
            <BaseHeading :info="$t('appointment.create.note_info')" mb>
              {{ filters.capitalize($t('global.items.note', 1)) }}
            </BaseHeading>
            <BaseCard>
              <BaseInput
                v-model="formData.notes"
                :placeholder="$t('appointment.create.note_placeholder')"
                type="textarea"
                v-test="'appointment-note'"
              />
            </BaseCard>
          </div>
        </BaseForm>
      </div>
      <Sidebar
        :class="$style.sidebar"
        :isSaving="isSaving"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { modal } from '@/helpers/ui';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useLocationsStore } from '@/stores/locations';
import { useCompanyStore } from '@/stores/company';
import { defineComponent, ref, computed, watchEffect, provide } from 'vue';
import AppointmentParts from './appointment-parts/index.vue';
import RecurringRules from '../RecurringRules.vue';
import Sidebar from './Sidebar.vue';
import TimeslotPicker from './TimeslotPicker.vue';
import { storeToRefs } from 'pinia';
import useVuelidate from '@vuelidate/core';
import { getPartDuration } from './helpers';
import { createAppointment, updateAppointment } from '../actions/appointments';
import { useCalendarPreviewStore } from '@/stores/calendar-preview';
import Customer from './Customer.vue';
import CustomerMember from '@/components/customer-details/CustomerMember.vue';
import { useOnboardingStore } from '@/stores/onboarding';
import eventBus from '@/event-bus';

export default defineComponent({
  name: 'CreateEditAppointment',
  components: {
    TimeslotPicker,
    AppointmentParts,
    RecurringRules,
    Sidebar,
    Customer,
    CustomerMember
  },
  inject: ['mixpanel'],
  setup() {
    const store = useCreateAppointmentStore();
    const {
      formData,
      existingAppointmentData,
      checkAvailability,
      reloadAllAllocations
    } = store;
    const {
      isFetchingAppointment,
      canCheckAvailability,
      isFormSubmitted,
      availabilityDataChanged,
      sendConfirmationEmail
    } = storeToRefs(store);
    const { currentOnboardingFlow } = useOnboardingStore();

    const { locations } = useLocationsStore();
    const { multiLocation, isInsideTestBucket } = useCompanyStore();
    const scrollContainer = ref();

    provide('scrollContainer', scrollContainer);

    const totalDuration = computed(() =>
      formData.partsAttributes.reduce(
        (sum, part) => sum + getPartDuration(part),
        0
      )
    );

    const disableRrule = computed(
      () =>
        // If the existing appointment already had an rrule, or if it's a Treatwell appointment, the user should not be able to add or edit the rrule
        existingAppointmentData.hasRrule || existingAppointmentData.treatwell
    );

    const showRrule = ref(false);

    watchEffect(() => {
      if (existingAppointmentData.hasRrule) {
        showRrule.value = true;
      }
    });

    const isSaving = ref(false);

    const { previewModeActive } = storeToRefs(useCalendarPreviewStore());
    const { previewModeEnabled } = useCalendarPreviewStore();
    const { unavailableServices } = storeToRefs(useCreateAppointmentStore());

    return {
      formData,
      unavailableServices,
      isSaving,
      existingAppointmentData,
      availabilityDataChanged,
      totalDuration,
      canCheckAvailability,
      sendConfirmationEmail,
      checkAvailability,
      reloadAllAllocations,
      locations,
      isFetchingAppointment,
      isFormSubmitted,
      multiLocation,
      disableRrule,
      showRrule,
      showTimeslotPicker: ref(false),
      v$: useVuelidate(),
      scrollContainer,
      filters,
      previewModeActive,
      previewModeEnabled,
      currentOnboardingFlow,
      isInsideTestBucket
    };
  },
  watch: {
    showRrule(value) {
      if (!value) {
        this.formData.rrule = null;
      }
    }
  },
  methods: {
    onSubmit() {
      this.isFormSubmitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      const maxDuration = 959;

      if (this.totalDuration <= 0) {
        modal('warning', {
          message: this.$t('appointment.create.warning_no_duration')
        });
      } else if (this.totalDuration > maxDuration) {
        modal('warning', {
          message: this.$t('appointment.create.warning_max_duration', {
            max: maxDuration
          })
        });
      } else if (!this.availabilityDataChanged) {
        this.save();
      } else {
        this.checkAvailability().then((isAvailable) => {
          if (isAvailable) {
            if (this.unavailableServices.length) {
              modal('confirmation', {
                message: this.$t('service.availability.calendar_confirmation')
              }).then(() => {
                this.save();
              });
            } else {
              this.save();
            }
          } else {
            modal('confirmation', {
              message: this.$t('appointment.not_available')
            }).then(() => {
              this.save();
            });
          }
        });
      }
    },
    save() {
      this.isSaving = true;

      // The backend crashes when sending input.rrule: null
      // So we need to spread the data into a new object and delete the key if it's null
      // Because why would we take the time to fix the backend..

      const input = {
        ...this.formData
      };

      if (!input.rrule) {
        delete input.rrule;
      }

      const saveAppointment = () => {
        if (this.existingAppointmentData.id) {
          return updateAppointment({
            input,
            original: true,
            previousTime: this.existingAppointmentData.initialStartAt
          });
        } else {
          // id: null is not allowed by the schema for creating appointments
          delete input.id;

          return createAppointment({
            input,
            sendConfirmationEmail: this.sendConfirmationEmail
          });
        }
      };

      saveAppointment()
        .then((appointment) => {
          this.mixpanel.track(
            this.existingAppointmentData?.id
              ? 'Appointment updated'
              : 'Appointment created',
            {
              preview_mode: this.previewModeEnabled
            }
          );

          if (this.$route.name === 'customer-edit-appointment') {
            this.$router.push({
              name: 'customer-appointment',
              params: { appointmentId: appointment.id }
            });
          } else {
            this.$router.push({
              name: 'appointment',
              params: { appointmentId: appointment.id }
            });
          }
        })
        .catch(() => {
          // The promise will reject when the backend returns an error response for the create or update mutation
          // This might happen when the availabilities or requirements have changed while the user has the form open
          // So here we refetch the requirements and load new allocations, which will also trigger the availabilties query because of the watcher

          this.reloadAllAllocations();
        })
        .finally(() => {
          this.isSaving = false;
          if (this.currentOnboardingFlow === 'appointment') {
            eventBus.$emit('open-task-menu');
            eventBus.$emit('update-task', 'create_appointment');
            this.currentOnboardingFlow = '';
          }
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  width: 100%;
  height: 100%;
}

.main {
  .base:not(.smallScreen) & {
    display: flex;
    height: 100%;
  }

  .base:not(.smallScreen):not(.previewMode) & {
    align-items: stretch;
  }

  .base.smallScreen & {
    padding: $spacing * 0.5;
  }

  .base.previewMode & {
    flex-direction: column;
  }
}

.content {
  .base:not(.smallScreen):not(.previewMode) & {
    width: 72.5%;
    padding: $spacing * 1.5;
  }

  .base:not(.smallScreen) & {
    overflow-y: auto;
  }

  .base.smallScreen & {
    padding: $spacing * 0.5;
  }

  .base.previewMode & {
    height: 100%;
    padding: $spacing;
  }
}

.sidebar {
  .base:not(.smallScreen):not(.previewMode) & {
    width: 27.5%;
    border-left: 1px solid $color-border;
    background-color: white;
  }

  .base.previewMode & {
    border-top: 1px solid $color-border;
    background-color: white;
    flex-shrink: 0;
    border-radius: 0 0 $modal-radius $modal-radius;
  }
}
.disabled {
  & > * {
    pointer-events: none;
    opacity: 0.5;
  }
}
.section {
  &:not(:last-child) {
    margin-bottom: $spacing * 1.5;
  }
}
</style>
