import gql from 'graphql-tag';

export const GET_CUSTOMER_TAGS = gql`
  query getCustomerTags {
    customerTags {
      companyTags {
        id
        name
        salonized
      }
      salonizedTags {
        id
        name
        salonized
      }
    }
  }
`;

export const ASSIGN_CUSTOMER_TAGS = gql`
  mutation assignCustomerTags($input: AssignCustomerTagsInput!) {
    assignCustomerTags(input: $input) {
      customerTags {
        id
        name
        salonized
      }
    }
  }
`;
