<template>
  <div :class="[$style.base, { [$style.isSmall]: $screen === 's' }]">
    <BaseCard :heading="supplier.name">
      <BaseGrid container>
        <BaseGrid :size="5">
          <BaseText
            v-if="
              useTotalProducts
                ? supplier.amountProducts === 1
                : supplier.amountProductsWithStock === 1
            "
          >
            {{
              `${useTotalProducts ? supplier.amountProducts : supplier.amountProductsWithStock} ${$t('global.items.product', 1)}`
            }}
          </BaseText>
          <BaseText v-else>
            {{
              $t('products.products_amount', {
                amount: useTotalProducts
                  ? supplier.amountProducts
                  : supplier.amountProductsWithStock
              })
            }}
          </BaseText>
          <BaseText>
            {{ $t('global.total_inventory') }}:
            {{ filters.currency(supplier.totalInventory) }}
          </BaseText>
        </BaseGrid>
        <BaseGrid :size="7" alignBottom alignRight>
          <BaseButton
            :loading="isLoading"
            :disabled="isDisabled"
            color="inverted"
            @click="$emit('selectSupplier')"
            v-test="'chooseSupplierButton'"
          >
            {{ $t('global.actions.select') }}
          </BaseButton>
        </BaseGrid>
      </BaseGrid>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    supplier: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    useTotalProducts: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selectSupplier'],
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.base {
  flex: 1;
  margin: $spacing * 0.5;
  min-width: 300px;
  max-width: 338px;

  &.isSmall {
    min-width: calc(100% - #{$spacing * 0.5});
  }
}
</style>
