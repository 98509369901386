<template>
  <div
    :class="[
      $style.base,
      {
        [$style.primary]: color === 'primary',
        [$style.warning]: color === 'warning',
        [$style.default]: color === 'default'
      }
    ]"
    @click="$emit('click')"
  >
    <transition>
      <div v-show="checked" :class="$style.inner" v-test="'checkmark-checked'">
        <BaseIcon name="checkmark-circle" size="l" color="success" />
      </div>
    </transition>
    <transition>
      <div
        v-show="!checked"
        :class="[$style.inner, $style.empty]"
        v-test="'checkmark-empty'"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false
});
</script>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    checked?: boolean;
    color?: 'primary' | 'warning' | 'default';
  }>(),
  {
    checked: false,
    color: 'primary'
  }
);

defineEmits(['click']);
</script>

<style lang="scss" module>
.base {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition:
    opacity 0.2s,
    transform 0.2s;

  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    opacity: 0;
    transform: scale(0.6);
  }
}

.empty {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;

  .base.primary & {
    border-color: $color-primary;
  }

  .base.warning & {
    border-color: $color-warning;
  }

  .base.default & {
    border-color: #aaa;
  }
}
</style>
