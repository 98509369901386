<template>
  <BaseModal :heading="$t('global.message')" small @close="$emit('close')">
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <BaseText mb>
        {{ $t('customers.message_type_question') }}
      </BaseText>
      <div :class="$style.wrap">
        <div
          :class="$style.field"
          @click="$emit('openMessageModal')"
          v-test="'simpleTextMessage'"
        >
          {{ $t('customers.simple_text_message') }}
        </div>
        <div
          :class="[
            $style.field,
            {
              [$style.disabled]: disableNewsletter
            }
          ]"
          @click="toNewsletter"
          v-test="'goToNewsletterBuilder'"
        >
          {{ $t('customers.use_newsletter_template') }}
          <BaseAlert
            v-if="disableNewsletter"
            :text="$t('customers.newsletter_unavailable')"
            size="s"
            color="warning"
            mt
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  emits: ['openMessageModal', 'close'],
  setup() {
    const selectedCustomers = inject<any>('selectedCustomers');
    const disableNewsletter = computed(() => selectedCustomers?.value?.length);

    return {
      disableNewsletter
    };
  },
  methods: {
    toNewsletter() {
      this.mixpanel.track('batch_action_chosen', { action_name: 'newsletter' });
      if (this.filters.length) {
        this.$store.commit('marketing/SET_ALL_FILTERS', this.filters);
      }
      this.$router.push({ name: 'newsletter-template' });
      this.$emit('close');
    }
  }
});
</script>

<style lang="scss" module>
.wrap {
  .base:not(.smallScreen) & {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

.field {
  .base:not(.smallScreen) & {
    width: calc(50% - #{$spacing * 0.5});
  }

  .base.smallScreen & {
    &:not(:first-child) {
      margin-top: $spacing;
    }
  }

  text-align: center;
  border: 1px solid $color-border;
  border-radius: $radius;
  padding: $spacing * 4 $spacing;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $color-highlight;
  }

  &.disabled {
    pointer-events: none;
    color: $color-text-secondary;
  }
}
</style>
