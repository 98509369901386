import type { User } from '@/types';

export const getUserRoleFromPermissions = (user: User) => {
  if (user.owner) {
    return 'owner';
  }

  if (user.admin) {
    return 'admin';
  }

  if (user.manager && user.reports) {
    return 'manager_reports';
  }

  if (user.manager) {
    return 'manager';
  }

  if (user.reports) {
    return 'reports';
  }

  if (user.basic) {
    return 'basic';
  }

  return 'calendar';
};
