let googleMapsScriptLoaded = false;

export const loadGoogleMaps = (apiKey) =>
  new Promise((resolve, reject) => {
    if (googleMapsScriptLoaded) {
      resolve(window.google);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      googleMapsScriptLoaded = true;
      resolve(window.google);
    };

    script.onerror = () => {
      reject(new Error('Google Maps API failed to load.'));
    };

    document.head.appendChild(script);
  });
