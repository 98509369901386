<template>
  <div>
    <BaseHeading border>
      {{ $t('products.stock_info') }}
      <div
        v-if="product.medical"
        :class="$style.medical"
        v-test="'stockInfoMedical'"
      >
        M
      </div>
    </BaseHeading>
    <BaseText mt bold>
      {{ $t('global.supplier') }}:
      <BaseText
        inline
        :color="
          !product.supplier || !product.supplier.name ? 'secondary' : 'default'
        "
        v-test="'stockInfoSupplier'"
      >
        {{
          product.supplier ? product.supplier.name : $t('products.no_supplier')
        }}
      </BaseText>
    </BaseText>

    <BaseText mt bold>
      {{ $t('global.stock') }}:
      <BaseText v-if="product.stockAmount || product.stockAmount === 0" inline>
        <BaseText
          v-if="product.stockEnabled && product.outOfStock"
          inline
          v-test="'isOutOfStock'"
        >
          <BaseLabel color="error">
            <div :class="$style.lineHeight">
              {{
                formattedStockValue(product.stockEnabled, product.stockAmount)
              }}
            </div>
          </BaseLabel>
        </BaseText>
        <BaseText v-else inline>
          {{ formattedStockValue(product.stockEnabled, product.stockAmount) }}
        </BaseText>
      </BaseText>
      <BaseText v-else inline> 0 </BaseText>
    </BaseText>

    <BaseText mt bold>
      {{ $t('products.minimum_stock') }}:
      <BaseText inline v-test="'stockInfoMin'">
        {{ formattedStockValue(product.stockEnabled, product.stockMinimum) }}
      </BaseText>
    </BaseText>

    <BaseText mt bold>
      {{ $t('products.maximum_stock') }}:
      <BaseText inline v-test="'stockInfoMax'">
        {{ formattedStockValue(product.stockEnabled, product.stockMaximum) }}
      </BaseText>
    </BaseText>

    <BaseText mt bold>
      {{ $t('products.stock_value') }}:
      <BaseText inline v-test="'stockInfoValue'">
        {{
          formattedStockValue(
            product.stockEnabled,
            filters.currency(product.stockValue)
          )
        }}
      </BaseText>
    </BaseText>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';

import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  setup() {
    const formattedStockValue = (
      stockEnabled: boolean,
      value: number
    ): number | string => (stockEnabled ? value : '-');

    return {
      formattedStockValue,
      filters
    };
  }
});
</script>

<style lang="scss" module>
.lineHeight {
  line-height: 8px;
  transform: translateY(-1px);
}

.heading {
  display: flex;
  justify-content: space-between;
}

.medical {
  color: #2d9cdb;
  margin-left: auto;
}
</style>
