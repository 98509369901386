import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const GET_USER_QUERY = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      settings {
        security {
          autoSessionTimeout
        }
      }
      owner
      admin
      manager
      reports
      basic
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
      }
      errors
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
      }
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      errors {
        type
        message
        attribute
      }
    }
  }
`;

export const useUserAccount = () => {
  const getUser = (id: string) => useQuery(GET_USER_QUERY, { id });
  const { mutate: createUser, loading: createUserLoading } =
    useMutation(CREATE_USER_MUTATION);
  const { mutate: updateUser, loading: updateUserLoading } =
    useMutation(UPDATE_USER_MUTATION);
  const { mutate: deleteUser, loading: deleteUserLoading } =
    useMutation(DELETE_USER_MUTATION);

  return {
    createUser,
    getUser,
    updateUser,
    deleteUser,
    loading: createUserLoading || updateUserLoading || deleteUserLoading
  };
};
