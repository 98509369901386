import i18n from '@/i18n';
import filters from '@/filters';
import { modal } from '@/helpers/ui';

export const formatRrule = (rrule: any) => {
  const formattedRrule = {
    ...rrule
  };

  // When sending the rrule to the api, the byday value has to be an array with integers, so this needs to be a separate key
  if (rrule.byday?.length && Array.isArray(rrule.byday[0])) {
    formattedRrule.monthlyByday = rrule.byday;
    formattedRrule.byday = null;
  }

  return formattedRrule;
};

export const generateRecurringMessage = ({
  action,
  type,
  input: { startAt, endAt },
  rrule
}: any) => {
  let until;
  if (rrule.count) {
    until = i18n.t('calendar.recurring_confirmation.count', {
      count: rrule.count
    });
  } else if (rrule.until) {
    until = i18n.t('calendar.recurring_confirmation.until', {
      date: filters.date(rrule.until, { format: 'long' })
    });
  } else {
    until = i18n.t('calendar.recurring_confirmation.until_forever');
  }

  let day = i18n.t(
    `calendar.recurring_confirmation.day.${rrule.freq}.${rrule.interval === 1 ? 'one' : 'other'}`,
    { count: rrule.interval }
  );

  if (rrule.byday?.length) {
    let days = '';
    const first = rrule.byday[0];
    if (Array.isArray(first)) {
      day = i18n.t(
        `calendar.recurring_confirmation.day.monthly_on.${rrule.interval === 1 ? 'one' : 'other'}`,
        {
          dates: `${filters.ordinalDate(first[0])} ${filters.weekdayLong(first[1])}`,
          count: rrule.interval
        }
      );
    } else {
      rrule.byday.forEach((day: number, index: number) => {
        days += `${filters.weekdayLong(day)}${index !== rrule.byday.length - 1 ? ', ' : ''}`;
      });

      day = i18n.t(
        `calendar.recurring_confirmation.day.weekly_on.${rrule.interval === 1 ? 'one' : 'other'}`,
        { days, count: rrule.interval }
      );
    }
  } else if (rrule.bymonthday?.length) {
    day = i18n.t(
      `calendar.recurring_confirmation.day.monthly_on.${rrule.interval === 1 ? 'one' : 'other'}`,
      {
        dates: `${filters.ordinalDate(rrule.bymonthday[0])}`,
        count: rrule.interval
      }
    );
  }

  const actions: any = {
    create: 'creating',
    update: 'editing',
    delete: 'deleting'
  };

  return `${i18n.t(
    `calendar.recurring_confirmation.${type}.${action === 'update' ? 'original' : 'single'}`,
    {
      action: i18n.t(`calendar.recurring_confirmation.${actions[action]}`),
      frequency: i18n.t(`calendar.recurring_confirmation.${rrule.freq}`),
      day,
      startAt: filters.time(startAt),
      endAt: filters.time(endAt),
      until
    }
  )} ${i18n.t('calendar.recurring_confirmation.continue')}`;
};

export const showRecurringConfirmation = (payload: any) =>
  new Promise<void>((resolve, reject) => {
    if (payload.type === 'appointment' && payload.action !== 'delete') {
      modal('recurring-confirmation', {
        message: generateRecurringMessage(payload),
        appointmentData: payload.input,
        catch: true
      })
        .then(() => resolve())
        .catch(() => reject());
    } else {
      modal('confirmation', {
        message: generateRecurringMessage(payload),
        catch: true
      })
        .then(() => resolve())
        .catch(() => reject());
    }
  });

export const showRecurringModalAndResolve = ({
  payload: {
    type,
    action,
    original,
    input,
    rrule,
    disableRecurringOriginalOption
  },
  method
}: any) =>
  new Promise((resolve, reject) => {
    const recurring = input.rrule || rrule;
    const { deleteEntry, recurrenceStartAt } = input;

    if (recurring && !original) {
      showRecurringOptions({ action, type, disableRecurringOriginalOption })
        .then((option) => {
          method({
            deleteEntry,
            recurrenceStartAt,
            recurrenceUpdateState: option
          }).then((response: any) => resolve(response));
        })
        .catch(() => {
          if (!deleteEntry) {
            reject();
          }
        });
    } else if (recurring) {
      showRecurringConfirmation({ action, type, input, rrule: recurring })
        .then(() => {
          method({
            recurrenceUpdateState: 'original'
          }).then((response: any) => resolve(response));
        })
        .catch(() => {
          if (!deleteEntry) {
            reject();
          }
        });
    } else {
      method()
        .then((response: any) => resolve(response))
        .catch((response: any) => reject(response));
    }
  });

export const showRecurringOptions = ({
  type,
  action,
  rrule,
  disableRecurringOriginalOption
}: any) =>
  new Promise((resolve, reject) => {
    modal('recurring-edit-options', {
      type,
      action,
      rrule,
      disableRecurringOriginalOption,
      catch: true
    })
      .then((option) => {
        option ? resolve(option) : reject();
      })
      .catch(() => reject());
  });
