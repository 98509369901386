<template>
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.49996 6.83341C6.03996 6.83341 5.66663 6.46008 5.66663 6.00008C5.66663 5.54008 6.03996 5.16675 6.49996 5.16675C6.95996 5.16675 7.33329 5.54008 7.33329 6.00008C7.33329 6.46008 6.95996 6.83341 6.49996 6.83341M6.49996 3.50008C5.12163 3.50008 3.99996 4.62175 3.99996 6.00008C3.99996 7.37841 5.12163 8.50008 6.49996 8.50008C7.87829 8.50008 8.99996 7.37841 8.99996 6.00008C8.99996 4.62175 7.87829 3.50008 6.49996 3.50008M11.5 10.1667H6.49996C4.20246 10.1667 2.33329 8.29758 2.33329 6.00008C2.33329 3.70258 4.20246 1.83342 6.49996 1.83342H11.5C13.7975 1.83342 15.6666 3.70258 15.6666 6.00008C15.6666 8.29758 13.7975 10.1667 11.5 10.1667M11.5 0.166748H6.49996C3.28413 0.166748 0.666626 2.78425 0.666626 6.00008C0.666626 9.21591 3.28413 11.8334 6.49996 11.8334H11.5C14.7158 11.8334 17.3333 9.21591 17.3333 6.00008C17.3333 2.78425 14.7158 0.166748 11.5 0.166748"
      fill="#EB4762"
    />
  </svg>
</template>
