import type { CompanySubscription } from '@/types';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed } from 'vue';

const GET_ACTIVE_SUBSCRIPTION_CANCELLED_QUERY = gql`
  query getCurrentUser {
    currentUser {
      company {
        activeSubscription {
          boostable
          cancelled
          endAt
        }
      }
    }
  }
`;

const getCompanySubscription = () => {
  const { result, loading, refetch } = useQuery(
    GET_ACTIVE_SUBSCRIPTION_CANCELLED_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const activeSubscription = computed<CompanySubscription | null>(
    () => result.value?.currentUser?.company?.activeSubscription
  );

  return {
    loading,
    refetch,
    activeSubscription
  };
};

export default getCompanySubscription;
