<template>
  <div v-if="typeof localValue === 'string'" :class="$style.base">
    <BaseHeading size="l" :mb="2">
      {{ $t('admin.assistant.employee_preference.heading') }}
    </BaseHeading>
    <div :class="$style.form">
      <RadioCards
        v-model="localValue"
        :options="[
          {
            label: $t('admin.assistant.employee_preference.option_1.heading'),
            image: `/img/salonized-assistant/${getImageLanguage}/employee-no.png`,
            value: ResourceSelection.Automatic
          },
          {
            label: $t('admin.assistant.employee_preference.option_2.heading'),
            image: `/img/salonized-assistant/${getImageLanguage}/employee-yes.png`,
            value: ResourceSelection.Manual
          }
        ]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import RadioCards from './_shared/RadioCards.vue';
import getImageLanguage from '../imageLanguage';
import { ResourceSelection } from '@/types';

const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue?: string | null;
}>();

const localValue = ref(props.modelValue);

watch(
  localValue,
  () => {
    emit('update:modelValue', localValue.value);
  },
  {
    deep: true
  }
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  flex-grow: 1;
}
</style>
