import dayjs from '@/dayjs';
import filters from '@/filters';
import createResourceAvatar from './CreateResourceAvatar';

export default ({ data, view, resource }) => {
  const dayjsDate = dayjs.tz(data.date);

  return `<div class="calendar-header-item">
    <div class="calendar-header-item__small">
      ${filters.weekdayNarrow(dayjsDate.day())} ${dayjsDate.date()}
    </div>
    <div class="calendar-header-item__inner">
      ${resource && view === 'WEEK' ? createResourceAvatar(resource) : ''}
      <div class="calendar-header-item__day">
        ${filters.weekdayShort(dayjsDate.day())}
      </div>
      ${
        view !== 'month'
          ? `
        ${
          data.isToday
            ? `
          <div class="calendar-header-item__date">
            ${dayjsDate.date()}
          </div>
        `
            : `
          <div class="calendar-header-item__month">
            ${dayjsDate.format('D-M')}
          </div>
        `
        }
      `
          : ''
      }
    </div>
  </div>`;
};
