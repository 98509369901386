<template>
  <div>
    <BaseSpinner v-if="!services.length && fetchingServices" inline />
    <div v-else-if="services.length">
      <BaseHeading mb>
        {{ filters.capitalize($t('global.items.service', 2)) }}
      </BaseHeading>

      <Draggable
        :list="formData.partsAttributes"
        item-key="index"
        :class="$style.partsWrap"
        handle=".js-drag-handle"
        @change="onChange"
      >
        <template #item="{ element, index }">
          <div>
            <Part
              :key="index"
              :part="element"
              :partIndex="index"
              :isExpanded="expandedIndex === index"
              @expand="expandedIndex = index"
              @delete="deletePart(index)"
              v-test="'appointment-part'"
            />
          </div>
        </template>
      </Draggable>

      <BaseAlert
        v-if="existingAppointmentData.treatwell"
        color="warning"
        :text="$t('appointment.edit_warning')"
        v-test="'appointment-edit-warning'"
      />
      <BaseText
        v-else
        link
        inline
        @click="onAddClick"
        v-test="'appointment-add-part'"
      >
        + {{ $t('appointment.create.add_service') }}
      </BaseText>
    </div>
    <div v-else :class="$style.noServices">
      <BaseText>
        {{ $t('appointment.no_services_found.text') }}
      </BaseText>
      <BaseText
        :routerLink="{
          name: 'admin-services'
        }"
        v-test="'no-services-link'"
      >
        {{ $t('appointment.no_services_found.link') }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';
import { useServicesStore } from '@/stores/services';
import { ref, defineComponent } from 'vue';
import Part from './part/index.vue';
import { mapState } from 'pinia';
import Draggable from 'vuedraggable';

export default defineComponent({
  name: 'AppointmentParts',
  components: {
    Part,
    Draggable
  },
  setup() {
    const { formData, addPart, resetParts, existingAppointmentData } =
      useCreateAppointmentStore();

    return {
      formData,
      addPart,
      resetParts,
      existingAppointmentData,
      expandedIndex: ref(existingAppointmentData.id ? null : 0),
      filters
    };
  },
  computed: {
    ...mapState(useServicesStore, ['services', 'fetchingServices'])
  },
  methods: {
    onChange({ moved }) {
      // This method will trigger when the order of the list changes.
      // Because we are using the index to show the expanded part, we need to update the expandedIndex so the correct part stays expanded.
      // The moved object contains the new and old index of the part that was moved.

      if (moved.oldIndex === this.expandedIndex) {
        // Expanded part was moved, set the expandedIndex to the new index.
        this.expandedIndex = moved.newIndex;
      } else if (
        moved.oldIndex < this.expandedIndex &&
        moved.newIndex >= this.expandedIndex
      ) {
        // A part that was "above" the expanded part has been moved to be below the expanded part.
        this.expandedIndex--;
      } else if (
        moved.oldIndex > this.expandedIndex &&
        moved.newIndex <= this.expandedIndex
      ) {
        // A part that was "below" the expanded part has been moved to be above the expanded part.
        this.expandedIndex++;
      }

      // Update the sortOrder prop. This is not being used in the frontend, but it is needed when sending the parts to the backend
      // It's basically only needed for updating opponents, because sending a re-sorted array to the backend won't cause the parts to be re-sorted in the backend
      this.formData.partsAttributes.forEach((part, index) => {
        part.sortOrder = index;
      });
    },
    deletePart(index) {
      if (this.formData.partsAttributes.length === 1) {
        this.resetParts();
      } else {
        this.formData.partsAttributes.splice(index, 1);

        if (index === this.expandedIndex) {
          this.expandLastPart();
        }
      }
    },
    onAddClick() {
      this.addPart();
      this.expandLastPart();
    },
    expandLastPart() {
      // Expand the newly added part by setting the expandedIndex to the index of the last item in the array.
      this.expandedIndex = this.formData.partsAttributes.length - 1;
    }
  }
});
</script>

<style lang="scss" module>
.partsWrap {
  & > * {
    margin-bottom: $spacing * 0.5;
  }
}
</style>
