<template>
  <ModuleBase
    :navItems="[
      {
        text: $t('global.pages.products'),
        name: 'products-all'
      },
      {
        text: $t('global.pages.categories'),
        name: 'products-categories'
      },
      {
        text: $t('global.pages.suppliers'),
        name: 'products-suppliers'
      },
      {
        text: $t('global.pages.stock_orders'),
        name: 'products-stock-orders'
      },
      {
        text: $t('global.pages.product_imports'),
        name: 'product-imports'
      }
    ]"
    :fullHeight="!hasFeatureFlag('module-products')"
  />
</template>

<script lang="ts">
import ModuleBase from '@/modules/ModuleBase.vue';
import { useUserStore } from '@/stores/user';
import { mapState } from 'pinia';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Products',
  components: {
    ModuleBase
  },
  computed: {
    ...mapState(useUserStore, ['hasFeatureFlag'])
  }
});
</script>
