<template>
  <div
    :class="[
      $style.base,
      {
        [$style.unclickable]: existingAppointmentData.treatwell,
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.main">
      <div :class="$style.service">
        <BaseText
          :color="
            selectedService
              ? 'default'
              : isFormSubmitted
                ? 'error'
                : 'secondary'
          "
          :bold="$screen === 's'"
        >
          {{
            selectedService
              ? selectedService.name
              : $t('appointment.create.select_service')
          }}
        </BaseText>
        <BaseIcon
          v-if="unavailableService"
          name="calendar"
          color="warning"
          :ml="0.5"
          size="s"
          v-test="'icon-warning-service'"
        />
      </div>
      <div>
        <BaseText>
          {{ `${totalDuration} ${$t('global.minute_short')}` }}
        </BaseText>
      </div>
      <div :class="$style.resources">
        <div v-if="missingResourceAmount">
          <BaseText :color="isFormSubmitted ? 'error' : 'secondary'" :mr="0.5">
            {{
              missingResourceAmount === 1
                ? $t('appointment.create.select_resource')
                : $t('appointment.create.select_resources', {
                    amount: missingResourceAmount
                  })
            }}
          </BaseText>
        </div>
        <div
          v-else-if="selectedResourceIds.length === 1 && primaryResource"
          :class="$style.resource"
        >
          <BaseAvatar :resource="primaryResource" :mr="0.5" />
          <BaseText>
            {{ primaryResource.name }}
          </BaseText>
        </div>
        <div v-else-if="selectedResourceIds.length > 1">
          <BaseText>
            {{
              `${selectedResourceIds.length} ${$t('global.items.resource', 2)}`
            }}
          </BaseText>
        </div>
        <div
          v-if="showRequirementWarning || showAvailabilityWarning"
          :class="$style.warningIcons"
        >
          <BaseIcon
            v-if="showRequirementWarning"
            name="alert"
            color="error"
            size="s"
            v-test="'icon-warning-requirement'"
          />
          <BaseIcon
            v-if="showAvailabilityWarning"
            name="calendar"
            color="warning"
            size="s"
            v-test="'icon-warning-availability'"
          />
        </div>
      </div>
    </div>
    <div :class="$style.side">
      <BaseIcon
        :name="existingAppointmentData.treatwell ? 'treatwell' : 'edit'"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import {
  getPartDuration,
  getPartResources
} from '@/modules/calendar/appointment/helpers';
import { useResourcesStore } from '@/stores/resources';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { AppointmentPartAttributes, Resource } from '@/types';
import { useCreateAppointmentStore } from '@/stores/calendar-create-appointment';

export default defineComponent({
  props: {
    part: {
      type: Object as PropType<AppointmentPartAttributes>,
      required: true
    },
    partIndex: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props) {
    const { resourceById } = useResourcesStore();
    const { existingAppointmentData, services } = useCreateAppointmentStore();
    const { isFormSubmitted } = storeToRefs(useCreateAppointmentStore());
    const { unavailableServices } = storeToRefs(useCreateAppointmentStore());
    const unavailableService = computed(() =>
      unavailableServices.value.find((s) => s.id === props.part.serviceId)
    );

    return {
      resourceById,
      services,
      isFormSubmitted,
      existingAppointmentData,
      unavailableService
    };
  },
  computed: {
    selectedService() {
      return this.services.find(
        (service) => service.id === this.part.serviceId
      );
    },
    totalDuration() {
      return getPartDuration(this.part);
    },
    selectedResourceIds(): Resource['id'][] {
      return getPartResources(this.part);
    },
    missingResourceAmount(): number {
      return (
        this.part.allocationsAttributes.length - this.selectedResourceIds.length
      );
    },
    primaryResource() {
      const resourceId = this.selectedResourceIds[0];
      return resourceId ? this.resourceById(resourceId) : null;
    },
    showRequirementWarning() {
      if (!this.part.serviceId || this.missingResourceAmount) {
        return false;
      }

      // All requirements for the entire appointment
      const { allRequirements } = useCreateAppointmentStore();

      // All requirements for the service in this part
      const serviceRequirements = allRequirements.filter(
        (req) => req.serviceId === this.part.serviceId
      );

      // All allowed resources for the service requirements
      const requirementResources = serviceRequirements
        .map((p) => p.resourceIds)
        .reduce((acc, val) => acc.concat(val), []);

      // If any of the selected resources does not exist in the allowed resources, return true
      return !!this.selectedResourceIds.find(
        (id) => !requirementResources.includes(id)
      );
    },
    showAvailabilityWarning() {
      if (!this.part.serviceId || this.missingResourceAmount) {
        return false;
      }

      const { partAvailabilities } = useCreateAppointmentStore();
      const availabileResourceIds =
        partAvailabilities[this.partIndex]?.availableResourceIds;

      if (!availabileResourceIds) {
        return false;
      }

      return !!this.selectedResourceIds.find(
        (id) => !availabileResourceIds.includes(id)
      );
    }
  }
});
</script>

<style lang="scss" module>
$iconWidth: 20px;

.base {
  display: flex;
  align-items: center;
  padding: $spacing * 0.5;
  cursor: pointer;
}

.main {
  display: flex;
  align-items: center;
  width: calc(100% - #{$iconWidth});
  flex-wrap: wrap;

  & > * {
    padding: $spacing * 0.5;
    .base:not(.smallScreen) & {
      &:nth-child(1),
      &:nth-child(3) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 20%;
      }
    }

    .base.smallScreen & {
      &:nth-child(1) {
        width: 100%;
        margin-bottom: $spacing * -0.5;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 70%;
      }
    }
  }
}

.side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $iconWidth;
}

.resource,
.service,
.resources {
  display: flex;
  align-items: center;
}

.warningIcons {
  display: flex;
  margin-left: $spacing * 0.5;

  & > *:not(:last-child) {
    margin-right: $spacing * 0.25;
  }
}
</style>
