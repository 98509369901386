import { useMutation, useQuery } from '@vue/apollo-composable';
import { DELETE_ABSENCE_MUTATION, GET_ABSENCES_QUERY } from './graphql';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export const useAbsence = () => {
  const { t } = useI18n();

  const { result, refetch, loading } = useQuery(GET_ABSENCES_QUERY);
  const { mutate } = useMutation(DELETE_ABSENCE_MUTATION);

  const absencesData = computed(() => result.value?.absences || []);

  const deleteAbsence = (id: string) =>
    mutate({
      input: {
        id
      }
    }).then(() => {
      flash(t('global.flash.absence_deleted'));
      refetch();
    });

  return {
    absencesData,
    deleteAbsence,
    loading
  };
};
