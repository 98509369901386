import gql from 'graphql-tag';

export const GET_PREPAID_CARDS = gql`
  query getPrepaidCards($customerId: ID!, $pagination: PaginationAttributes) {
    prepaidCards(customerId: $customerId, pagination: $pagination) {
      createdAt
      description
      id
      price
      quantity
      remaining
      status
      used
    }
  }
`;
