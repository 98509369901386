<template>
  <div
    :class="[
      $style.base,
      {
        [$style.disabled]: disabled
      }
    ]"
  >
    <div :class="$style.top">
      <label :class="$style.label">
        <input
          :class="$style.input"
          type="checkbox"
          @change="toggle"
          v-test="'_base-toggle-input'"
        />
        <span
          :class="[$style.slider, { [$style.isChecked]: modelValue }]"
          v-test="'_base-toggle-slider'"
        />
      </label>
      <BaseText
        v-if="label"
        :bold="!!description"
        v-test="'_base-toggle-label'"
      >
        {{ label }}
      </BaseText>
    </div>
    <BaseText v-if="description" :mt="0.5" v-test="'_base-toggle-description'">
      {{ description }}
    </BaseText>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
const props = defineProps<{
  modelValue: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const toggle = () => {
  if (!props.disabled) {
    emit('update:modelValue', !props.modelValue);
  }
};
</script>

<style lang="scss" module>
.top {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 12px;
  }
}

.label {
  flex-shrink: 0;
  position: relative;
  width: 42px;
  height: 26px;

  .base.disabled & {
    pointer-events: none;
    opacity: 0.5;
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-border-input;
  transition: 0.3s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: $white;
    transition: 0.3s;
    border-radius: 50%;
  }
}

.isChecked {
  background-color: $color-primary;

  &::before {
    transform: translateX(16px);
  }
}
</style>
