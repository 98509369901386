import gql from 'graphql-tag';

const customerFragment = gql`
  fragment registerCustomer on Customer {
    id
    fullName
    picture
    loyaltyPoints
  }
`;

const discountCodeFragment = gql`
  fragment discountCode on DiscountCode {
    id
    code
    discountPercentage
  }
`;

export const GET_APPOINTMENT = gql`
  query getAppointment($id: Int!) {
    appointment(id: $id) {
      id
      startAt
      serviceNames
      customer {
        ...registerCustomer
      }
      discountCode {
        ...discountCode
      }
      parts {
        id
        resourceId
        service {
          id
          medical
          name
          price
        }
      }
      paidThroughTreatwell
      pricePaidThroughTreatwell
      priceAfterDiscount
      discountPercentage
      locationId
    }
  }
  ${customerFragment}
  ${discountCodeFragment}
`;

export const orderItemFragment = gql`
  fragment orderItem on OrderItem {
    id
    price
    quantity
    medical
    serviceId
    productId
    description
    loyaltyPointsAmount
    giftcard {
      id
      code
    }
    soldPrepaidCard {
      id
      description
      quantity
    }
    originalPrice
    appointmentPart {
      id
      appointmentId
    }
    discountCode {
      ...discountCode
    }
    employeeId
    transactionCost
    usedPrepaidCardId
  }
  ${discountCodeFragment}
`;

export const transactionFragment = gql`
  fragment transaction on Transaction {
    id
    type
    amount
    description
    giftcard {
      id
      code
    }
    prepaidCard {
      id
      description
    }
    cashupId
    deletable
    locationId
    transactionAt
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      invoicedAt
      comments
      cashupId
      draft
      fiscalizationFinished
      customer {
        ...registerCustomer
      }
      items {
        ...orderItem
      }
      appointments {
        id
        startAt
        serviceIds
        serviceNames
        customer {
          ...registerCustomer
        }
        parts {
          id
        }
        discountCode {
          ...discountCode
        }
        paidThroughTreatwell
        pricePaidThroughTreatwell
        priceAfterDiscount
      }
      locationId
      transactions {
        ...transaction
      }
      permissions {
        editOrderItems
      }
      state
    }
  }
  ${customerFragment}
  ${discountCodeFragment}
  ${orderItemFragment}
  ${transactionFragment}
`;

export const GET_CUSTOMER = gql`
  query getCustomer($id: Int!) {
    customer(id: $id) {
      ...registerCustomer
    }
  }
  ${customerFragment}
`;

export const orderCustomerFragment = gql`
  fragment orderCustomer on Customer {
    id
    firstName
    fullName
    mobilePhone
    phone
    email
    loyaltyPoints
    totalDue
    address
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($input: CreateOrderInput!, $withCustomer: Boolean!) {
    createOrder(input: $input) {
      order {
        id
        fiscalizationFinished
        customer @include(if: $withCustomer) {
          ...orderCustomer
        }
        number
      }
      errors
    }
  }
  ${orderCustomerFragment}
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!, $withCustomer: Boolean!) {
    updateOrder(input: $input) {
      order {
        id
        fiscalizationFinished
        customer @include(if: $withCustomer) {
          ...orderCustomer
        }
        number
      }
      errors
    }
  }
  ${orderCustomerFragment}
`;
