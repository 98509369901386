import gql from 'graphql-tag';

export const GET_COMPANY_APPS = gql`
  query getCurrentUser {
    currentUser {
      company {
        apps {
          id
          key
          state
        }
      }
    }
  }
`;

export const GET_APPS = gql`
  query getApps {
    apps {
      enablePath
      key
    }
  }
`;

export const DISABLE_APP = gql`
  mutation deleteApp($input: DeleteAppInput!) {
    deleteApp(input: $input) {
      app {
        id
      }
    }
  }
`;

export const ENABLE_TREATWELL_MARKETPLACE = gql`
  mutation enableTreatwellMarketplace(
    $input: EnableTreatwellMarketplaceInput!
  ) {
    enableTreatwellMarketplace(input: $input) {
      treatwellVenue {
        id
      }
    }
  }
`;

export const ENABLE_FISKALY = gql`
  mutation createFiscalizationApp($input: CreateFiscalizationAppInput!) {
    createFiscalizationApp(input: $input) {
      fiscalizationApp {
        id
      }
    }
  }
`;
