import { useCompanyStore } from '@/stores/company';

export const useSettingsData = () => {
  const { company, updateCompany: _updateCompany } = useCompanyStore();
  const savingCompany = ref(false);
  const dataChanged = ref(false);

  const companySettingsData = reactive({
    emailReminderHours: company.settings.reminders?.emailReminderHours || null,
    settings: {
      communication: {
        appointmentNoShow:
          company.settings.communication?.appointmentNoShow || false
      },
      customers: {
        appointmentFeedback:
          company.settings.customers?.appointmentFeedback || false,
        appointmentFeedbackDelay:
          company.settings.customers?.appointmentFeedbackDelay || 0
      },
      reminders: {
        emailReminderEnabled:
          company.settings.reminders?.emailReminderEnabled || false,
        smsReminderEnabled:
          company.settings.reminders?.smsReminderEnabled || false
      }
    },
    smsReminderHours: company.settings.reminders?.smsReminderHours
  });

  watch(
    () => companySettingsData,
    () => {
      dataChanged.value = true;
    },
    {
      deep: true
    }
  );

  const updateCompany = () =>
    new Promise<void>((resolve) => {
      savingCompany.value = true;
      _updateCompany(companySettingsData).then(() => {
        savingCompany.value = false;
        resolve();
      });
    });

  return {
    companySettingsData,
    updateCompany,
    savingCompany,
    dataChanged
  };
};
