<template>
  <BaseModal
    testId="upgrade-plan"
    small
    :heading="$t('upgrade_modal.heading')"
    unclosable
    @close="$emit('close')"
  >
    <div :class="$style.base">
      <img src="/img/insights/tips/newsletter.svg" />
      <BaseText mt>
        {{ $t('upgrade_modal.title') }}
      </BaseText>
      <BaseText>
        {{ $t('upgrade_modal.description') }}
      </BaseText>
    </div>
    <template #footer>
      <BaseButton @click="onClick" v-test="'upgrade-continue'">
        {{ $t('global.ok') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import { datadogRum } from '@datadog/browser-rum';
import { useUserStore } from '@/stores/user';

import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['close'],
  methods: {
    onClick() {
      const { getUser } = useUserStore();
      getUser().finally(() => {
        this.$emit('close');
        this.$router.push({ name: 'subscription' });
      });
    }
  },
  created() {
    datadogRum.addAction('upgrade-modal-shown');
  }
});
</script>

<style lang="scss" module>
.base {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
