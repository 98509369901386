<template>
  <div>
    <TimelineItem
      v-for="(item, index) in data"
      :key="`item-${index}`"
      :canDelete="canDelete"
      :item="item"
      @delete="onDelete"
      v-test="'timelineItem'"
    />
  </div>
</template>

<script lang="ts">
import TimelineItem from '@/components/TimelineItem.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    TimelineItem
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  emits: ['delete'],
  methods: {
    onDelete(id) {
      this.$emit('delete', id);
    }
  }
});
</script>
