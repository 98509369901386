<template>
  <div v-if="treatwellSignup" :class="$style.treatwell" />
  <div v-else :class="$style.base">
    <div :class="[$style.blob, $style.blobTopLeft]">
      <svg viewBox="0 0 506 586" fill="none">
        <g filter="url(#filter0_d)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M110.214-394.461c173.84 78.784 775.275 308.837-265.52 831.405-229.868 90.148-167.766-407.361-144.972-609.288 22.794-201.927 236.651-300.901 410.492-222.117z"
            fill="#0DCDA6"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="-457"
            y="-487"
            width="962.458"
            height="1072.92"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
    <div :class="[$style.blob, $style.blobBottomLeft]">
      <svg viewBox="0 0 797 322" fill="none">
        <path
          d="M352.155 214.2c117.385 48.594 295.398 50.394 381.309 125.985 85.653 75.849 79.203 225.746 10.578 330.649-68.626 104.902-199.169 164.81-313.2 178.951-114.29 14.141-211.81-17.741-318.618-4.885-107.065 12.856-223.161 70.192-312.425 48.08-89.007-22.112-151.182-123.929-167.952-227.803-16.511-103.874 12.126-210.062 41.279-321.135 29.152-111.073 59.079-227.032 133.896-289.253C-118.161-7.69 1.805-16.431 90.037 27.02 178.012 70.216 234.77 165.862 352.155 214.2z"
          fill="#FD832D"
        />
      </svg>
    </div>
    <div :class="[$style.blob, $style.blobCenter]">
      <svg viewBox="0 0 698 628" fill="none">
        <path
          d="M335 528C232.454 570.181 12.9 454.943 58.308 326.437c20.707-58.871 98.843-106.04 145.826-142.732C601-60 856.808 314.751 335 528z"
          fill="#8742A0"
        />
      </svg>
    </div>
    <div :class="[$style.blob, $style.blobRight]">
      <svg viewBox="0 0 1188 1198" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M337.947 88.324C237.962-189.928 651.329-261.979 807.65 22.227c156.321 284.207 343.35 266.48 444.26 524.081 100.91 257.601 175.53 822.812-27.5 697.882-187-115.07-241.369-519.187-378.004-674.005C677.409 378.7 376.882 196.678 337.947 88.325z"
          fill="#D53A79"
        />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="-230"
            width="1712.8"
            height="1518.16"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company';
import { storeToRefs } from 'pinia';
const { treatwellSignup } = storeToRefs(useCompanyStore());
</script>

<style lang="scss" module>
.base {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgb(34, 31, 79);
}

.treatwell {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url('/img/treatwell/bg.png') center center no-repeat;
  background-size: cover;
}

.blob {
  position: absolute;

  svg {
    width: 100%;
  }
}

.blobTopLeft {
  top: 0;
  left: 0;
  width: 30.48%;
}

.blobBottomLeft {
  bottom: -6px;
  left: 0;
  width: 48.01%;
}

.blobCenter {
  top: 7.92%;
  left: 9.94%;
  width: 54.04%;
}

.blobRight {
  top: 0;
  right: 0;
  width: 71.57%;
}
</style>
