<template>
  <div :class="$style.item" v-test="'register-payment-order-item'">
    <div :class="$style.number">
      {{ item.quantity }}
    </div>
    <div :class="$style.name">
      <span>
        {{ item.name }}
      </span>
      <div
        v-if="
          item.discount ||
          (item.loyaltyPointsAmount && item.loyaltyPointsAmount > 0)
        "
        :class="$style.discount"
      >
        (
        <div v-if="item.discount">
          <div v-if="item.discount > 0" :class="$style.prices">
            {{ `${Math.round(item.discount)}% ${$t('global.discount')}` }}
          </div>
          <div v-if="item.discount < 0">
            {{
              `${Math.round(item.discount).toString().replace('-', '')}% ${$t(
                'dynamic_pricing.adjustment_types.percentage_increase'
              )}`
            }}
          </div>
        </div>
        <div
          v-if="item.loyaltyPointsAmount && item.loyaltyPointsAmount > 0"
          :class="$style.loyaltyPoints"
        >
          <div v-if="item.discount" :class="$style.comma">,</div>
          {{
            `${$t('register.loyalty_points_used', {
              points: item.loyaltyPointsAmount
            })}`
          }}
        </div>
        )
      </div>
      <div :class="$style.prepaid">
        <BaseText
          v-if="item.usedPrepaidCardId"
          :ml="0.5"
          color="secondary"
          size="s"
          v-test="'register-order-item-usedprepaidcard'"
        >
          {{
            `${$t('register.prepaid_card_applied', {
              card: prepaidCardTransaction?.name
            })}`.toUpperCase()
          }}
        </BaseText>
      </div>
    </div>
    <div :class="$style.price">
      <div v-if="item.discount !== 0">
        <BaseText
          lineThrough
          inline
          :mr="0.5"
          v-test="'register-order-item-top-price-original'"
        >
          {{ filters.currency(item.quantity! * item.originalPrice!) }}
        </BaseText>
      </div>
      <div v-test="'register-order-item-top-price'">
        {{ filters.currency(item.price! * item.quantity!) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import filters from '@/filters';
import {
  useRegisterOrderStore,
  type OrderItem,
  type OrderTransaction
} from '@/modules/register/stores/order';
import { computed } from 'vue';

const { order } = useRegisterOrderStore();

const props = defineProps<{ item: OrderItem }>();

const prepaidCardTransaction = computed(() =>
  order.transactions.find(
    (transaction: OrderTransaction) =>
      parseInt(transaction.prepaidCardId as string) ===
      Number(props.item.usedPrepaidCardId)
  )
);
</script>
<style lang="scss" module>
.item {
  display: flex;
  margin-bottom: $spacing * 0.5;
}

.number {
  min-width: 30px;
}

.loyaltyPoints {
  display: flex;
  align-items: flex-start;
}

.comma {
  margin-right: $spacing * 0.2;
}

.name {
  flex: 1;

  display: flex;
  flex-wrap: wrap;
}

.price {
  display: flex;
  align-items: center;
}

.discount {
  display: flex;
  align-items: flex-start;
  margin-left: $spacing * 0.5;
  color: #9c9aa0;
}

.prices {
  display: flex;
  align-items: flex-start;
}

.prepaid {
  display: flex;
  align-items: center;
  margin-top: $spacing * 0.1;
}
</style>
