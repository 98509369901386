<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's' || $screen === 'm'
      }
    ]"
  >
    <div :class="$style.left">
      <BaseVisualBanner
        v-if="!loadingTwoFactorSettings && !isTwoFactorEnabled"
        :heading="$t('dashboard.2fa-announcement.title')"
        :description="$t('dashboard.2fa-announcement.description')"
        :primaryAction="{
          text: $t('dashboard.2fa-announcement.link'),
          routerLink: {
            name: 'enable-two-factor-auth-settings'
          }
        }"
        color="green"
        variant="small"
        imagePath="/img/admin/2fa.svg"
        mb
        @primaryAction="mixpanel.track('2FA - Banner clicked')"
        v-test="'2fa-banner'"
      />
      <BaseHeading mb>
        {{ t('admin.dashboard.popular_settings') }}
      </BaseHeading>
      <router-link
        v-for="card in cards"
        :key="card.key"
        :to="{ name: card.link }"
      >
        <BaseCard mb v-test="`${card.key}-link`">
          <div :class="$style.cardContent">
            <img :src="card.image" :class="$style.image" />
            <div>
              <BaseHeading>
                {{ card.title }}
              </BaseHeading>
              <BaseText>
                {{ card.paragraph }}
              </BaseText>
            </div>
          </div>
          <ServiceVariationsBanner v-if="card.key === 'services'" mt />
        </BaseCard>
      </router-link>
    </div>
    <div :class="$style.right">
      <BaseCard
        mb
        :heading="t('admin.dashboard.your_stats')"
        :loading="loading"
      >
        <div :class="$style.stats">
          <BaseHeading size="xl" center :mb="0.25" v-test="'total-customers'">{{
            localeString(result.companyStats.totalCustomers)
          }}</BaseHeading>
          <BaseText color="secondary" :mb="0.5">{{
            t('admin.dashboard.stats.customers').toUpperCase()
          }}</BaseText>
          <BaseHeading size="xl" center :mb="0.25" v-test="'total-invoices'">{{
            localeString(result.companyStats.totalInvoices)
          }}</BaseHeading>
          <BaseText color="secondary" :mb="0.5">{{
            t('admin.dashboard.stats.invoices').toUpperCase()
          }}</BaseText>
          <BaseHeading
            size="xl"
            center
            :mb="0.25"
            v-test="'total-appointments'"
            >{{
              localeString(result.companyStats.totalAppointments)
            }}</BaseHeading
          >
          <BaseText color="secondary" :mb="0.5">{{
            t('admin.dashboard.stats.appointments').toUpperCase()
          }}</BaseText>
        </div>
      </BaseCard>
      <SMSCard size="l" />
      <ReferralCard />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable';
import { getCompanyStats } from './graphql';
import { useI18n } from 'vue-i18n';
import SMSCard from '../_shared/SMSCard.vue';
import ReferralCard from '@/modules/_shared/ReferralCard.vue';
import ServiceVariationsBanner from '@/modules/admin/_shared/ServiceVariationsBanner.vue';
import { localeString } from '@/helpers/formatting';
import gql from 'graphql-tag';

const { t } = useI18n();
const mixpanel = inject<any>('mixpanel');

const { result, loading } = useQuery(getCompanyStats);

const cards = [
  {
    key: 'services',
    link: 'admin-services',
    title: t('admin.dashboard.services.title'),
    paragraph: t('admin.dashboard.services.paragraph'),
    image: '/img/admin/dashboard/services.svg'
  },
  {
    key: 'timetable',
    link: 'admin-roster',
    title: t('admin.dashboard.timetable.title'),
    paragraph: t('admin.dashboard.timetable.paragraph'),
    image: '/img/admin/dashboard/timetable.svg'
  },
  {
    key: 'bookings',
    link: 'admin-online-bookings',
    title: t('admin.dashboard.bookings.title'),
    paragraph: t('admin.dashboard.bookings.paragraph'),
    image: '/img/admin/dashboard/online-bookings.svg'
  }
];

const { result: twoFactorSettings, loading: loadingTwoFactorSettings } =
  useQuery(
    gql`
      query getCurrentUser {
        currentUser {
          twoFactorSettings {
            status
          }
        }
      }
    `,
    null,
    {
      fetchPolicy: 'cache-first'
    }
  );

const isTwoFactorEnabled = computed(
  () =>
    twoFactorSettings.value?.currentUser.twoFactorSettings?.status === 'ENABLED'
);
</script>

<style lang="scss" module>
.base {
  display: flex;

  &.smallScreen {
    flex-direction: column;
  }
}

.left {
  .base:not(.smallScreen) & {
    width: 60%;
  }
}

.left,
.right {
  flex-grow: 1;
  padding: $spacing;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.image {
  max-height: 128px;
  max-width: 128px;
  margin-right: $spacing;
}

.stats {
  text-align: center;
}

.number {
  font-weight: bold;
  margin-bottom: $spacing * 0.5;
}
</style>
