<template>
  <div :class="$style.base">
    <BaseCard v-if="logs" v-test="'sync-card'">
      <BaseHeading bold>
        {{ $t('treatwell.sync_stats') }}
      </BaseHeading>
      <BaseTableRow head>
        <BaseTableCell>Entity</BaseTableCell>
        <BaseTableCell>Last synced at</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow v-for="value in syncStatValues" :key="value">
        <BaseTableCell>{{ value }}</BaseTableCell>
        <BaseTableCell>
          {{
            companyApp?.settings.logs[value]
              ? filters.dateTime(companyApp.settings.logs[value])
              : ''
          }}
        </BaseTableCell>
      </BaseTableRow>
    </BaseCard>
    <BaseCard v-test="'venue-company-iban'">
      <BaseSpinner v-if="loading" inline />
      <div v-else :class="$style.ibanCard">
        <BaseText bold>Company IBAN</BaseText>
        <BaseText>{{ companyIban ? companyIban : 'No IBAN' }}</BaseText>
      </div>
    </BaseCard>
    <BaseCard v-if="venueUrl" v-test="'venue-card'">
      <div :class="$style.urlCard">
        <BaseText bold>{{ $t('treatwell.venue_page') }}</BaseText>
        <BaseText mt :href="venueUrl" target="_blank" v-test="'venue-url'">
          {{ venueUrl }}
        </BaseText>
      </div>
    </BaseCard>
    <BaseCard v-if="logs && logs.status" v-test="'purchasability-card'">
      <BaseHeading bold>
        {{ $t('treatwell.purchasability_stats') }}
      </BaseHeading>
      <BaseTableRow head>
        <BaseTableCell>Field</BaseTableCell>
        <BaseTableCell>Value</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow v-for="value in purchasabilityStatValues" :key="value">
        <BaseTableCell>{{ value }}</BaseTableCell>
        <BaseTableCell v-test="`purchasability-${value}`">
          {{ logs.status.venue[value] }}
        </BaseTableCell>
      </BaseTableRow>
    </BaseCard>
    <BaseCard v-if="changeRequest" v-test="'change-request-card'">
      <BaseHeading bold> Change request </BaseHeading>
      <BaseTableRow>
        <BaseTableCell>Description Atmosphere</BaseTableCell>
        <BaseTableCell>{{ changeRequest.descriptionAtmosphere }}</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow>
        <BaseTableCell>Description Brands and Products</BaseTableCell>
        <BaseTableCell>{{
          changeRequest.descriptionBrandsProducts
        }}</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow>
        <BaseTableCell>Description Experience</BaseTableCell>
        <BaseTableCell>{{ changeRequest.descriptionExperience }}</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow>
        <BaseTableCell>Description Specialities</BaseTableCell>
        <BaseTableCell>{{
          changeRequest.descriptionSpecialities
        }}</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow>
        <BaseTableCell>Description Transports</BaseTableCell>
        <BaseTableCell>{{ changeRequest.descriptionTransports }}</BaseTableCell>
      </BaseTableRow>
      <BaseTableRow>
        <BaseTableCell>Description Extras</BaseTableCell>
        <BaseTableCell>{{ changeRequest.descriptionExtras }}</BaseTableCell>
      </BaseTableRow>
    </BaseCard>
  </div>
</template>
<script lang="ts">
import filters from '@/filters';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { TreatwellChangeRequest } from '@/types';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    logs: {
      type: Object,
      required: false
    },
    companyApp: {
      type: Object,
      required: false
    },
    changeRequest: {
      type: Object as PropType<TreatwellChangeRequest>,
      required: false
    },
    venueUrl: {
      type: String,
      required: false
    }
  },
  setup() {
    const { result, loading } = useQuery(gql`
      query getCurrentUser {
        currentUser {
          company {
            billingInfo {
              iban
            }
          }
        }
      }
    `);

    const companyIban = computed(
      () => result.value?.currentUser.company.billingInfo?.iban
    );

    return {
      companyIban,
      loading,
      filters
    };
  },
  data() {
    return {
      syncStatValues: [
        'venue',
        'supplier',
        'menu',
        'service',
        'review',
        'employee',
        'availability'
      ],
      purchasabilityStatValues: [
        'marketplaceVenueId',
        'service',
        'purchasableOnMarketplace',
        'menu',
        'availability'
      ]
    };
  }
});
</script>
<style lang="scss" module>
.base {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.ibanCard {
  display: flex;
  justify-content: space-between;
  gap: $spacing;
}

.urlCard {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}
</style>
