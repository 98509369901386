import { ref, watch } from 'vue';

// This indicates wether or not a network request to update a calendar event is in progress
// Can be used to disable calendar interaction, for example to prevent a double click when rescheduling an appointment

export const isCalendarSaving = ref(false);

// Automatically reset it after two seconds to prevent the user from being unable to interact with the calendar,
// in case the value is not properly reset in the callback of the network request

watch(
  () => isCalendarSaving.value,
  (value) => {
    if (value) {
      setTimeout(() => {
        isCalendarSaving.value = false;
      }, 6000);
    }
  }
);
