import './styles/index.scss';
import './setup/datadog';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import apolloClient from '@/apollo/client';

import router from './router';
import App from './app/index.vue';
import store from './vuex';
import { i18n } from './i18n';
import apolloProvider from './apollo/provider';
import directives from './setup/directives';
import components from './setup/base-components';
import addPlugins from './setup/plugins';

import './setup/polyfills';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(store);
app.use(apolloProvider);
app.use(i18n);

provideApolloClient(apolloClient);

Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key]);
});

components.forEach((component) => {
  app.component(component.name, component.config);
});

addPlugins(app);

app.mount('#app');
