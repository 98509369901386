<template>
  <div :class="$style.base" v-test="'page-verification'">
    <BaseHeading size="xl" mb center>
      {{ $t('verification.header') }}
    </BaseHeading>
    <SendText v-if="!textSent" @textSent="textSent = true" />
    <EnterToken
      v-else
      baseName="verification"
      :description="$t('verification.enter_code_description')"
      @sendCode="verify"
      @isLoading="isLoading"
      @tokenError="tokenError"
    />
  </div>
</template>

<script lang="ts">
import SendText from './SendText.vue';
import EnterToken from './EnterToken.vue';
import { redirectRoute } from '@/router/helpers';
import { useSessionStore } from '@/stores/session';
import axios from '@/axios';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Verification',
  components: {
    SendText,
    EnterToken
  },
  data() {
    return {
      textSent: false,
      isLoading: false,
      tokenError: false
    };
  },
  methods: {
    verify(token) {
      this.isLoading = true;
      axios
        .patch('/verifications', {
          params: { token }
        })
        .then(() => {
          const { fetchAllData } = useSessionStore();
          fetchAllData().then(() => {
            this.$router.push(redirectRoute({ query: this.$route.query }));
          });
        })
        .catch(() => {
          this.tokenError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
}
.bottom {
  text-align: center;
}
.error {
  font-size: 12px;
}
</style>
