<template>
  <div v-test="'drawer-header'">
    <div v-if="cashupEnabled">
      <BaseGrid container>
        <BaseGrid :size="4">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.opened_on') }}
          </BaseHeading>
          <BaseText bold v-test="'drawer-opened-on'">
            {{ filters.dateTime(drawer.openedOn) }}
          </BaseText>
        </BaseGrid>
        <BaseGrid :size="4">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.opening_amount') }}
          </BaseHeading>
          <BaseHeading size="xl" v-test="'drawer-opening-amount'">
            {{ filters.currency(drawer.openingAmount) }}
          </BaseHeading>
        </BaseGrid>
        <BaseGrid :size="4">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.current_cash') }}
          </BaseHeading>
          <BaseHeading size="xl" v-test="'drawer-current-cash'">
            {{ filters.currency(drawer.expectedTotalCash) }}
          </BaseHeading>
        </BaseGrid>
      </BaseGrid>
      <PageHeader v-if="!hideFilters">
        <template #left>
          <BaseSearch v-model="searchValue" debounce />
        </template>
        <template #right>
          <BaseButton
            :router-link="{ name: 'drawer-transaction' }"
            v-test="'btn-new-transaction'"
          >
            {{ $t('drawer.new_transaction') }}
          </BaseButton>
          <BaseButton
            ml
            :router-link="{ name: 'drawer-close' }"
            v-test="'btn-close-drawer'"
          >
            {{
              filters.capitalize(
                $t('global.actions.close_item', { item: $t('global.register') })
              )
            }}
          </BaseButton>
        </template>
      </PageHeader>
    </div>
    <div v-else>
      <BaseGrid container mb>
        <BaseGrid :size="3" v-test="'drawer-amount-total'">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.current_cash') }}
          </BaseHeading>
          <BaseHeading size="xl">
            {{ filters.currency(drawer.total) }}
          </BaseHeading>
        </BaseGrid>
        <BaseGrid :size="3" v-test="'drawer-amount-today'">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.balance_today') }}
          </BaseHeading>
          <BaseHeading size="xl">
            {{ filters.currency(drawer.today) }}
          </BaseHeading>
        </BaseGrid>
        <BaseGrid :size="3" v-test="'drawer-amount-pin'">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.debit_today') }}
          </BaseHeading>
          <BaseHeading size="xl">
            {{ filters.currency(drawer.pin) }}
          </BaseHeading>
        </BaseGrid>
        <BaseGrid :size="3" v-test="'drawer-amount-credit'">
          <BaseHeading size="s" :mb="0.5">
            {{ $t('drawer.credit_today') }}
          </BaseHeading>
          <BaseHeading size="xl">
            {{ filters.currency(drawer.creditCard) }}
          </BaseHeading>
        </BaseGrid>
      </BaseGrid>
      <PageHeader v-if="!hideFilters">
        <template #left>
          <BaseSearch v-model="searchValue" debounce />
        </template>
        <template #right>
          <BaseButton
            :router-link="{ name: 'drawer-current-cash' }"
            v-test="'drawer-edit-cash'"
          >
            {{ $t('drawer.edit_cash') }}
          </BaseButton>
          <BaseButton
            :router-link="{ name: 'drawer-transaction' }"
            v-test="'drawer-new-transaction'"
          >
            {{ $t('drawer.new_transaction') }}
          </BaseButton>
        </template>
      </PageHeader>
    </div>
  </div>
</template>

<script lang="ts">
import filters from '@/filters';
import PageHeader from '@/modules/PageHeader.vue';
import { useRegisterStore } from '@/modules/register/stores/register';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    PageHeader
  },
  props: {
    search: {
      type: String,
      required: true
    },
    drawer: {
      type: Object,
      required: true
    },
    hideFilters: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:search'],
  setup() {
    const { cashupEnabled } = useRegisterStore();
    return {
      filters,
      cashupEnabled
    };
  },
  computed: {
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit('update:search', value);
      }
    }
  }
});
</script>
