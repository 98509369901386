import gql from 'graphql-tag';

export const integrationRequestQuery = gql`
  query getTreatwellCurrentIntegrationRequest {
    treatwellCurrentIntegrationRequest {
      fee
      state
      requested
      contractSigned
      informationSent
      salonDescription
      accountHolderName
      accountNumber
      bankName
      bankCode
      freeCommissionEndDate
      changeRequest {
        id
        createdAt
        descriptionAtmosphere
        descriptionExperience
        descriptionSpecialities
        descriptionBrandsProducts
        descriptionTransports
        descriptionExtras
      }
    }
  }
`;

export const venueQuery = gql`
  query getTreatwellVenue {
    treatwellVenue {
      url
      images
      venueType
      description
      bankAccountHolder
      bankAccountNumber
      bankName
      bankCode
    }
  }
`;

export const companyAppQuery = gql`
  query getCompanyApp($key: String!) {
    companyApp(key: $key) {
      settings {
        logs
      }
      state
    }
  }
`;
