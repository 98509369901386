import gql from 'graphql-tag';

export const CREATE_STOCK_ORDER = gql`
  mutation createStockOrder($input: CreateStockOrderInput!) {
    createStockOrder(input: $input) {
      stockOrder {
        id
      }
      errors
    }
  }
`;

export const GET_STOCK_ORDER = gql`
  query stockOrder($id: Int!) {
    stockOrder(id: $id) {
      id
      supplier {
        id
        name
      }
      orderItems {
        id
        costPrice
        quantity
        total
        product {
          id
          partNumber
          name
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts(
    $filter: String
    $locationId: ID
    $search: String
    $pagination: PaginationAttributes
    $categoryIds: [Int!]
    $supplierIds: [Int!]
  ) {
    products(
      filter: $filter
      locationId: $locationId
      search: $search
      pagination: $pagination
      categoryIds: $categoryIds
      supplierIds: $supplierIds
    ) {
      id
      name
      costPrice
      outOfStock
      stockAmount
      stockMinimum
      stockMaximum
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories(
    $search: String
    $filter: ProductCategoriesFilterEnum
    $supplierIds: [Int!]
    $pagination: PaginationAttributes
  ) {
    productCategories(
      search: $search
      filter: $filter
      supplierIds: $supplierIds
      pagination: $pagination
    ) {
      id
      name
    }
  }
`;
