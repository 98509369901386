const Appointment = () => import('@/modals/appointment/index.vue');
const CreateCustomerReport = () => import('@/modals/CreateCustomerReport.vue');
const Forms = () => import('@/modals/forms/Forms.vue');
const Form = () => import('@/modals/forms/Form.vue');
const AppointmentReport = () => import('@/modals/AppointmentReport.vue');

export default (baseName) => {
  const parentRouteName = baseName;
  return {
    component: Appointment,
    children: [
      {
        name: `${baseName}-report-new`,
        path: 'report',
        component: CreateCustomerReport,
        props: {
          parentRouteName
        },
        meta: {
          featureFlag: 'appointment-reports'
        }
      },
      {
        name: `${baseName}-report`,
        path: 'report/:id',
        component: AppointmentReport,
        props: {
          parentRouteName
        },
        meta: {
          featureFlag: 'appointment-reports'
        }
      },
      {
        name: `${baseName}-report-edit`,
        path: 'report/:id/edit',
        component: CreateCustomerReport,
        props: {
          parentRouteName
        },
        meta: {
          featureFlag: 'appointment-reports'
        }
      },
      {
        path: 'forms',
        component: Forms,
        props: {
          parentRouteName
        }
      },
      {
        path: 'forms/new/:templateId',
        component: Form,
        props: {
          parentRouteName
        }
      },
      {
        path: 'forms/:subId/edit/:templateId',
        component: Form,
        props: {
          parentRouteName
        }
      }
    ]
  };
};
