interface Sortable {
  id: number;
  sortOrder: number;
}

// Used when sorting items in a list using the buttons
// Looks at the item that it is switching with based on index, and then switches the sort order values
export const sortByClick = (
  newIndex: number,
  item: any,
  listItems: any[]
): Sortable[] => {
  const otherItem = listItems[newIndex];

  return [
    {
      id: item.id,
      sortOrder: otherItem.sortOrder
    },
    {
      id: otherItem.id,
      sortOrder: item.sortOrder
    }
  ];
};

// Used when sorting items in a list using drag and drop
// The vue-draggable component returns an array where items have a different index
// This method updates the sortOrder values based on those indexes
export const sortByDrag = (newList: any[], oldList: any[]): Sortable[] =>
  newList.map((item, index) => ({
    id: item.id,
    sortOrder: oldList[index].sortOrder
  }));
