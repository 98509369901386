<template>
  <BaseModal
    :heading="$t('onboarding.new_tasks.company_info.heading')"
    useForm
    noPadding
    @submit="handleSubmit"
    @close="handleClose"
  >
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <div :class="$style.content">
        <BaseInput
          v-model="companyInfo.name"
          :label="$t('onboarding.new_tasks.company_info.company_name')"
          required
          :minLength="2"
          v-test="'companyName'"
        />
        <div :class="$style.formRow">
          <BaseInput
            v-model="companyInfo.postalcode"
            :label="$t('onboarding.new_tasks.company_info.postal')"
            required
            v-test="'companyPostal'"
          />
          <BaseInput
            v-model="companyInfo.houseNumber"
            :label="$t('onboarding.new_tasks.company_info.house')"
            v-test="'companyHouse'"
          />
        </div>
        <BaseInput
          v-model="companyInfo.address"
          required
          :label="$t('onboarding.new_tasks.company_info.address')"
          v-test="'companyAddress'"
        />
        <div :class="[$style.formRow, $style.formRowLarge]">
          <BaseInput
            v-model="companyInfo.city"
            required
            :label="$t('onboarding.new_tasks.company_info.city')"
            v-test="'companyCity'"
          />
          <BaseInput
            v-model="companyInfo.phone"
            required
            type="tel"
            :minLength="9"
            :maxLength="16"
            :error="
              isSubmitted && showPhoneError
                ? `*${filters.capitalize($t('global.validations.invalid', { value: $t('global.phone_number') }))}`
                : null
            "
            :label="$t('onboarding.new_tasks.company_info.phone_number')"
            v-test="'companyPhone'"
          />
        </div>
      </div>
      <div :class="$style.visual">
        <img src="/img/onboarding/salon.svg" :class="$style.img" />
      </div>
    </div>
    <template #footer>
      <BaseButton color="inverted" @click="handleClose" v-test="'labelClose'">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton submitForm :loading="loading" v-test="'labelSave'">
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { reactive, ref, watch, inject } from 'vue';
import eventBus from '@/event-bus';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import filters from '@/filters';

const emit = defineEmits(['close']);
const mixpanel = inject<any>('mixpanel');

const { company, updateCompany } = useCompanyStore();
const loading = ref(false);
const isSubmitted = ref(false);
const showPhoneError = ref(false);

const companyInfo = reactive({
  name: company.name,
  postalcode: company.postalcode,
  city: company.city,
  phone: company.phone,
  houseNumber: '',
  address: ''
});

watch(
  () => companyInfo.phone,
  () => {
    showPhoneError.value = false;
  }
);

const handleClose = () => {
  eventBus.$emit('open-task-menu');
  mixpanel.track('Onboarding tasks - Company Info Close');
  emit('close');
};

const { mutate: updateTask } = useMutation(gql`
  mutation updateOnboardingTask($input: UpdateOnboardingTaskInput!) {
    updateOnboardingTask(input: $input) {
      onboardingTask {
        id
        task
        completedAt
        claimedAt
      }
    }
  }
`);

const handleSubmit = () => {
  loading.value = true;
  isSubmitted.value = true;

  updateCompany({
    address: `${companyInfo.address} ${companyInfo.houseNumber}`,
    postalcode: companyInfo.postalcode,
    name: companyInfo.name,
    internalName: companyInfo.name,
    phone: companyInfo.phone,
    city: companyInfo.city
  })
    .then(() => {
      updateTask({
        input: {
          task: 'SETUP_COMPANY_DETAILS',
          taskStatus: 'COMPLETE'
        }
      }).then(() => {
        loading.value = false;
        mixpanel.track('Onboarding tasks - Company Info Complete');
        eventBus.$emit('update-task', 'setup_company_details');
        handleClose();
      });
    })
    .catch((errors) => {
      if (
        errors?.length &&
        errors.find((error) => error.attribute === 'phone')
      ) {
        showPhoneError.value = true;
      }
    });

  loading.value = false;
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  height: 100%;

  &:not(.smallScreen) {
    align-items: stretch;
  }

  &.smallScreen {
    flex-direction: column-reverse;
  }
}

.content {
  .base:not(.smallScreen) & {
    width: 67%;
    padding: $spacing * 1.5;
  }

  .base.smallScreen & {
    padding: $spacing;
  }

  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.visual {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(41deg, #b8c8ff -79.6%, #fff 93.38%);

  .base:not(.smallScreen) & {
    width: 33%;

    img {
      position: relative;
      left: 15%;
      width: 100%;
    }
  }

  .base.smallScreen & {
    padding: $spacing;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;

  & > * {
    width: calc(50% - #{$spacing * 0.5});
  }
}

.formRowLarge {
  .base.smallScreen & {
    display: block;

    & > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: $spacing;
      }
    }
  }
}
</style>
