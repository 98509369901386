<template>
  <Card>
    <NoteForm />
  </Card>
</template>

<script lang="ts">
import NoteForm from '../forms/NoteForm.vue';
import Card from './_shared/Card.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Card,
    NoteForm
  }
});
</script>
