<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <BaseSpinner v-if="!firstFetchFinished" />
    <EmptyPageContent v-else-if="showEmptyPageContent" />
    <div v-else :class="$style.main">
      <div :class="$style.side">
        <div
          v-if="$screen === 's' && showTreatwellContent"
          :class="$style.mobileHeader"
        >
          <TreatwellBatch />
        </div>
        <div :class="$style.list">
          <ListActions
            v-model:reactionFilter="variables.reactionFilter"
            v-model:publishingStatusFilter="variables.publishingStatusFilter"
          />

          <div :class="$style.listItems" v-test="'feedback-list'">
            <ListContainer :items="feedbacks" />
            <LoadMore
              v-show="!allDataFetched && feedbacks.length > 0"
              @shown="fetchMore"
            />
          </div>
        </div>
      </div>
      <div :class="$style.content">
        <TreatwellBatch v-if="$screen !== 's' && showTreatwellContent" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FeedbackPage'
};
</script>

<script setup lang="ts">
import EmptyPageContent from './EmptyPageContent.vue';
import { useUserStore } from '@/stores/user';
import { useCompanyStore } from '@/stores/company';
import { computed, ref, watch } from 'vue';
import TreatwellBatch from './TreatwellBatch.vue';
import { usePagination } from '@/apollo/pagination';
import { GET_FEEDBACKS, UPDATE_LAST_VISIT } from './graphql';
import ListContainer from './list/ListContainer.vue';
import ListActions from './list/ListActions.vue';
import LoadMore from '@/components/LoadMore.vue';
import { useTreatwellStore } from '@/stores/treatwell';
import { useRouter } from 'vue-router';
import { useMutation } from '@vue/apollo-composable';
import { usePageLayoutStore } from '@/stores/page-layout';

const router = useRouter();
const hasFeedback = ref(false);

const { mutate: updateLastVisit, onDone } = useMutation(UPDATE_LAST_VISIT, {
  variables: {
    input: {
      keyword: 'feedback'
    }
  }
});

updateLastVisit();

onDone(() => {
  const userStore = useUserStore();
  if (userStore.user) {
    userStore.user.feedbacks = 0;
  }
});

const {
  feedbacks,
  allDataFetched,
  loading,
  variables,
  fetchMore,
  onResult,
  refetch
} = usePagination({
  fieldName: 'feedbacks',
  query: GET_FEEDBACKS,
  variables: {
    reactionFilter: null,
    publishingStatusFilter: null
  },
  options: {
    multiLocation: true
  }
});

const firstFetchFinished = ref(false);
watch(loading, () => (firstFetchFinished.value = true));

const showEmptyPageContent = computed(() => {
  const { companySettings } = useCompanyStore();
  const { hasFeatureFlag } = useUserStore();

  return (
    !hasFeatureFlag('module-feedback') ||
    !companySettings.customers.appointmentFeedback ||
    (!loading.value && !hasFeedback.value)
  );
});

onResult(({ data }) => {
  if (data?.feedbacks.length) {
    hasFeedback.value = true;
  }

  const firstFeedbackItemId = data.feedbacks[0]?.id;

  const { screenSize } = usePageLayoutStore();
  if (
    !showEmptyPageContent.value &&
    firstFeedbackItemId &&
    !showTreatwellContent.value &&
    router.currentRoute.value.name !== 'feedback-item' &&
    screenSize !== 's'
  ) {
    router.replace({
      name: 'feedback-item',
      params: { feedbackId: firstFeedbackItemId }
    });
  }
});

const showTreatwellContent = computed(() => {
  const { treatwellStatus } = useTreatwellStore();
  return treatwellStatus.hasVenue;
});

watch(router.currentRoute, (curRoute, prevRoute) => {
  // Refetch is needed because the batch publish list uses the same cache as the query here
  if (prevRoute.name === 'feedback-publish') {
    refetch();
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  border-top: 1px solid $color-border;
}

.main {
  height: 100%;

  .base:not(.smallScreen) & {
    display: flex;
  }
}

.side {
  background-color: white;

  .base:not(.smallScreen) & {
    height: 100%;
    width: 33%;
    border-right: 1px solid $color-border;
  }
}

.list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listItems {
  overflow-y: auto;
}

.content {
  .base:not(.smallScreen) & {
    position: relative;
    padding: $spacing;
    height: 100%;
    width: 67%;
    overflow-y: auto;
    padding-bottom: 80px;

    & > *:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}

.mobileHeader {
  padding: $spacing;

  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}
</style>
