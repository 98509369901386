<template>
  <div>
    <div v-for="(option, index) in options" :key="`option-${index}`">
      <BaseCheckbox
        :label="option"
        :modelValue="!!multipleAnswers.find((answer) => answer === option)"
        :mb="0.5"
        @update:modelValue="addValue(option)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Array, String],
      required: false
    },
    options: {
      type: Array,
      required: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      multipleAnswers: []
    };
  },
  methods: {
    addValue(option) {
      this.multipleAnswers.includes(option)
        ? (this.multipleAnswers = this.multipleAnswers.filter(
            (answer) => answer !== option
          ))
        : this.multipleAnswers.push(option);
      this.$emit('update:modelValue', this.multipleAnswers);
    }
  },
  created() {
    if (this.modelValue?.length) {
      this.multipleAnswers = this.modelValue;
      this.$emit('update:modelValue', this.multipleAnswers);
    }
  }
});
</script>
