<template>
  <div v-if="typeof localValue === 'number'" :class="$style.base">
    <BaseHeading size="l" :mb="2">
      {{ $t('admin.assistant.booking_interval.heading') }}
    </BaseHeading>
    <div :class="$style.form">
      <BaseDropdown
        v-model="localValue"
        :options="
          onlineBookingIntervalOptions.map((value) => ({
            label: `${value} ${$t('global.items.minute_short', value)}`,
            value
          }))
        "
        v-test="'wizard-appointment-interval-select'"
      />
      <BaseText mt>
        {{ $t('admin.assistant.booking_interval.explanation') }}
      </BaseText>
      <TimeSlotPreview :timeSlots="timeSlots" />
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from '@/dayjs';
import TimeSlotPreview from './_shared/TimeSlotPreview.vue';
const onlineBookingIntervalOptions = [5, 10, 15, 20, 30, 45, 60, 75, 90, 120];
const today = dayjs().hour(10).minute(0).second(0);
const timeSlots = computed(() => {
  if (!localValue.value) {
    return [];
  }
  const options = [];
  for (let i = 0; i < 128; i++) {
    if (
      today
        .add(localValue.value * i, 'minute')
        .isAfter(dayjs().hour(17).minute(0).second(0))
    ) {
      break;
    }
    options.push(today.add(localValue.value * i, 'minute').format('HH:mm'));
  }
  return options;
});
const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue?: number | null;
}>();

const localValue = ref(props.modelValue);

watch(
  localValue,
  () => {
    emit('update:modelValue', localValue.value);
  },
  {
    deep: true
  }
);
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  flex-grow: 1;
}
</style>
