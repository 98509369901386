<template>
  <div>
    <BaseHeading mb size="s">
      {{ filters.capitalize($t('global.items.appointment', 2)) }}
    </BaseHeading>

    <EmptyResults v-if="items.length < 1 && !loading" />

    <div>
      <Appointment
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        @click="select(item)"
      />
    </div>
    <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
  </div>
</template>

<script setup lang="ts">
import EmptyResults from '../../EmptyResults.vue';
import { usePagination } from '@/apollo/pagination';
import { REGISTER_SEARCH } from '../../graphql';
import LoadMore from '@/components/LoadMore.vue';
import { computed } from 'vue';
import { setRegisterAppointment } from '../../../actions/appointment';
import dayjs from '@/dayjs';
import filters from '@/filters';
import Appointment from './Appointment.vue';

const { registerSearch, allDataFetched, fetchMore, loading } = usePagination({
  fieldName: 'registerSearch',
  query: REGISTER_SEARCH,
  variables: {
    type: 'APPOINTMENTS',
    before: dayjs().add(1, 'day').format('YYYY-MM-DD')
  }
});

const items = computed(() =>
  registerSearch.value.map((item: any) => ({
    label: item.name,
    id: item.id,
    price: item.price,
    customerName: item.customerName,
    date: item.startAt
  }))
);

const select = (item: any) => {
  const appointment = registerSearch.value.find(
    (searchItem: any) => searchItem.id === item.id
  );
  setRegisterAppointment(appointment);
};
</script>
