<template>
  <ModuleBase noBorder gray>
    <PageWrap wide :heading="$t('admin.nav.links.forms')">
      <template #header>
        <BaseButton
          :options="[
            {
              routerLink: { name: 'admin-forms-new' },
              label: $t('forms.consultation')
            },
            {
              routerLink: { name: 'admin-forms-new-contract' },
              label: $t('forms.contract')
            }
          ]"
          v-test="'create-form'"
          v-intercom="'admin-forms-new-form'"
        >
          {{ $t('global.actions.create_new') }}
        </BaseButton>
      </template>
      <BaseSpinner v-if="!dataFetched" />
      <BaseCard v-else>
        <div v-if="!showEmptyPageContent">
          <PageHeader>
            <template #left>
              <BaseDropdown
                v-model="filter"
                :options="[
                  {
                    value: 'all',
                    label: $t('form_templates.filters.all')
                  },
                  {
                    value: 'active',
                    label: $t('form_templates.filters.active')
                  },
                  {
                    value: 'contract',
                    label: $t('form_templates.filters.contract')
                  },
                  {
                    value: 'consultation',
                    label: $t('form_templates.filters.consultation')
                  },
                  {
                    value: 'archived',
                    label: $t('form_templates.filters.archived')
                  }
                ]"
                v-test="'form-templates-filter'"
              />
              <BaseSearch
                v-model="searchQuery"
                v-test="'form-templates-search'"
              />
            </template>
          </PageHeader>
          <div v-if="listItems.length">
            <BaseTableRow head emptyOptionsCell>
              <BaseTableCell :width="50">
                {{ $t('form_templates.table_headings.name') }}
              </BaseTableCell>
              <BaseTableCell :width="36">
                {{ $t('form_templates.table_headings.type') }}
              </BaseTableCell>
              <BaseTableCell :width="14">
                {{ $t('form_templates.table_headings.submissions') }}
              </BaseTableCell>
            </BaseTableRow>
            <ListItem
              v-for="(template, index) in listItems"
              :key="index"
              :template="template"
              v-test="'form-templates-item'"
            />
          </div>
        </div>
        <EmptyPageContent
          v-show="!listItems.length"
          imageName="forms"
          :text="
            showEmptyPageContent
              ? {
                  title: $t('form_templates.empty_state.title'),
                  description: $t('form_templates.empty_state.description'),
                  btnPrimary: $t('form_templates.new_consultation'),
                  btnSecondary: $t('form_templates.new_contract')
                }
              : null
          "
          :moreLink="{
            text: $t('form_templates.empty_state.more_text'),
            url: $t('form_templates.empty_state.more_url')
          }"
          @click="onEmptyPageClick"
          v-test="'form-templates-noresults'"
        />
      </BaseCard>
    </PageWrap>
  </ModuleBase>
</template>

<script lang="ts">
import filters from '@/filters';
import PageHeader from '@/modules/PageHeader.vue';
import ModuleBase from '@/modules/ModuleBase.vue';
import ListItem from './ListItem.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import { useFormTemplatesStore } from '@/stores/form-templates';
import { mapState } from 'pinia';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';
import PageWrap from '../PageWrap.vue';

export default defineComponent({
  components: {
    PageHeader,
    ModuleBase,
    ListItem,
    EmptyPageContent,
    BaseTableRow,
    BaseTableCell,
    PageWrap
  },
  setup() {
    return {
      filters
    };
  },
  data() {
    return {
      filter: 'all',
      searchQuery: ''
    };
  },
  computed: {
    ...mapState(useFormTemplatesStore, [
      'dataFetched',
      'all',
      'active',
      'contract',
      'consultation',
      'archived'
    ]),
    listItems() {
      const templates = this[this.filter];
      return this.searchQuery
        ? templates.filter((template) =>
            template.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        : templates;
    },
    showEmptyPageContent() {
      return (
        this.filter === 'all' && !this.searchQuery && !this.listItems.length
      );
    }
  },
  methods: {
    onEmptyPageClick(button) {
      if (button === 'primary') {
        this.$router.push({ name: 'admin-forms-new' });
      } else if (button === 'secondary') {
        this.$router.push({ name: 'admin-forms-new-contract' });
      }
    }
  },
  created() {
    const { fetchData } = useFormTemplatesStore();
    fetchData();
  }
});
</script>
