<template>
  <BaseTableRow
    :options="options"
    zebra
    :routerLink="routerLink"
    @optionClick="optionClick($event, template)"
  >
    <BaseTableCell :width="50">
      {{ template.name }}
    </BaseTableCell>
    <BaseTableCell :width="36">
      <div>
        {{
          $t(isContract ? 'forms.contract' : 'forms.consultation').toLowerCase()
        }}
        <BaseText v-if="isArchived" inline italic>
          ({{ $t('form_templates.archived').toLowerCase() }})
        </BaseText>
      </div>
    </BaseTableCell>
    <BaseTableCell :width="14">
      {{ template.submissionsCount }}
    </BaseTableCell>
  </BaseTableRow>
</template>

<script lang="ts">
import { useFormTemplatesStore } from '@/stores/form-templates';
import { mapActions } from 'pinia';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseTableRow,
    BaseTableCell
  },
  props: {
    template: {
      type: Object,
      required: true
    }
  },
  computed: {
    routerLink() {
      return {
        name: 'admin-forms-form',
        params: {
          id: this.template.id
        }
      };
    },
    isArchived() {
      return !!this.template.archivedAt;
    },
    isContract() {
      return this.template.formType === 'contract';
    },
    options() {
      const editable =
        !this.template.corona &&
        (!this.isContract || !this.template.submissionsCount);

      const options = [
        {
          name: 'edit',
          show: editable
        },
        {
          name: 'archive',
          show: !this.isArchived
        },
        {
          name: 'unarchive',
          show: this.isArchived
        },
        {
          name: 'delete',
          show: editable
        }
      ];

      return options
        .filter((option) => option.show)
        .map((option) => option.name);
    }
  },
  methods: {
    ...mapActions(useFormTemplatesStore, ['archive', 'delete']),
    optionClick(action) {
      switch (action) {
        case 'edit':
          this.$router.push(this.routerLink);
          break;
        case 'archive':
        case 'unarchive':
          this.archive(this.template.id);
          break;
        case 'delete':
          this.delete(this.template.id);
          break;
      }
    }
  }
});
</script>
