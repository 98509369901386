<template>
  <div :class="$style.base">
    <div :class="$style.items">
      <OrderItem v-for="item in order.items" :key="item._uid" :item="item" />
    </div>
    <div :class="$style.total">
      <BaseHeading size="s">
        {{ $t('global.total') }}
      </BaseHeading>
      <BaseText bold v-test="'register-payment-total-price'">
        {{ filters.currency(totalPrice) }}
      </BaseText>
    </div>
  </div>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import OrderItem from './OrderItem.vue';
import { useRegisterOrderStore } from '@/modules/register/stores/order';
import { storeToRefs } from 'pinia';

const { order, totalPrice } = storeToRefs(useRegisterOrderStore());
</script>

<style lang="scss" module>
.base {
  padding: $spacing;
}

.items {
  margin-bottom: $spacing * 2;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
