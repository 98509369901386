<template>
  <BaseModal
    :heading="$t('drawer.open_register_header')"
    small
    useForm
    testId="open-drawer"
    @submit="submit"
    @close="$emit('close')"
  >
    <BaseText mb>
      {{ $t('drawer.open_description') }}
    </BaseText>
    <BaseText bold>
      {{ location?.internalName }}
    </BaseText>
    <BaseText mb> {{ $t('drawer.opened_by') }} {{ user?.fullName }} </BaseText>
    <BaseInput
      v-model="cashFloatAmount"
      :label="$t('drawer.cash_float_amount')"
      type="currency"
      :minValue="0"
      v-test="'cash-float-amount'"
    />
    <template #footer>
      <BaseButton color="inverted" @click="emits('close')">
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton :loading="isLoading" submitForm v-test="'btn-open-cashup'">
        {{ $t('global.actions.open_item', { item: $t('global.register') }) }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRegisterDrawerStore } from '@/stores/register-drawer';
import { useLocationsStore } from '@/stores/locations';
import { useUserStore } from '@/stores/user';

const { openCashup, previousCashFloatAmount } = useRegisterDrawerStore();
const { location } = useLocationsStore();
const { user } = useUserStore();

const emits = defineEmits(['success', 'close']);

const cashFloatAmount = ref(previousCashFloatAmount);
const isLoading = ref(false);

const submit = () => {
  isLoading.value = true;

  openCashup(cashFloatAmount.value)
    .then(() => {
      emits('success');
      emits('close');
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
