<template>
  <BaseButton
    mr
    color="inverted"
    :loading="disputeCheckoutSessionLoading"
    @click="payInvoice"
    v-test="`invoices-card-invoice-${stripeInvoice.id}-pay-action`"
  >
    {{ $t('global.actions.pay') }}
  </BaseButton>
</template>

<script lang="ts" setup>
import { useStripe } from '@/helpers/stripe';
import { InvoiceStatus, type StripeInvoice } from '@/types';

const props = defineProps<{
  stripeInvoice: StripeInvoice;
}>();

const { goToDisputeCheckoutSession, disputeCheckoutSessionLoading } =
  useStripe();

const payInvoice = () => {
  if (props.stripeInvoice.realStatus === InvoiceStatus.Failed) {
    goToDisputeCheckoutSession(props.stripeInvoice.id);
    return;
  }

  if (props.stripeInvoice.hostedInvoiceUrl) {
    window.location.href = props.stripeInvoice?.hostedInvoiceUrl;
  }
};
</script>
