<template>
  <div>
    <ImportFiles
      v-if="step === 0"
      :importId="importId"
      @columnMapping="columnMapping = $event"
      @columnData="handleStep($event)"
      @close="$emit('close')"
    />
    <ImportTable
      v-if="step === 1"
      :importId="importId"
      :columnData="columnData"
      :columnMapping="columnMapping"
      @success="step = 2"
      @closeModal="$emit('close')"
    />
    <CustomerImportSuccessful v-if="step === 2" @close="$emit('close')" />
  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';
import ImportFiles from './ImportFiles.vue';
import ImportTable from './ImportTable.vue';
import CustomerImportSuccessful from './CustomerImportSuccessful.vue';

defineEmits(['close']);
const mixpanel = inject<any>('mixpanel');

const handleStep = (value: any) => {
  mixpanel.track('Onboarding tasks - Imports - Step', { step: step.value });
  columnData.value = value;
  step.value = 1;
};

const columnMapping = ref([]);
const columnData = ref();

const step = ref(0);

defineProps<{
  importId: number;
}>();
</script>
