<template>
  <div v-if="query" :class="$style.base">
    <div v-if="loading" :class="$style.loading">
      <BaseSpinner inline />
    </div>
    <div v-else :class="$style.main">
      <div v-if="customers.length || pages.length">
        <ResultGroup
          v-if="customers.length"
          :heading="$t('global.items.customer', 2)"
        >
          <ResultItem
            v-for="(customer, index) in customers"
            :key="index"
            :label="customer.fullName"
            :subLabel="customer.email"
            :routerLink="{
              name: 'customer',
              params: { customerId: customer.id }
            }"
            :activity="customer.activity"
            :index="index"
            icon="person"
            addToHistory
            @updateHistory="$emit('updateHistory', $event)"
            @select="onSelect('customer')"
          />
        </ResultGroup>
        <ResultGroup v-if="pages.length" :heading="$t('search.pages_heading')">
          <ResultItem
            v-for="(page, index) in pages"
            :key="index"
            :label="page.title"
            :icon="pagesIcons[page.category]"
            :routerLink="{
              path: getRoutePrefix() + page.path
            }"
            :index="customers.length + index"
            addToHistory
            @updateHistory="$emit('updateHistory', $event)"
            @select="onSelect('page')"
          />
        </ResultGroup>
      </div>
      <div v-else :class="$style.noResults" v-test="'search-noresults'">
        <img src="/img/visual-page-404.svg" />
        <BaseText bold>{{ $t('global.no_results') }}</BaseText>
      </div>
      <div :class="$style.helpArticleLink">
        <ResultItem
          key="help-article"
          icon="question-mark"
          :subLabel="$t('search.help_article_link', { query })"
          @select="
            () => {
              mixpanel.track('search_helpdesk_selected');
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ResultItem from './ResultItem.vue';
import ResultGroup from './ResultGroup.vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { filterRoutes, getRoutePrefix } from './helpers';

const emit = defineEmits(['updateHistory', 'select', 'selectIndex']);
const query = inject<any>('query');
const mixpanel = inject<any>('mixpanel');

const onSelect = (type: 'page' | 'customer') => {
  emit('select');
  mixpanel.track('search_result_selected', {
    type
  });
};

const { loading, result } = useQuery(
  gql`
    query search($query: String!) {
      search(query: $query) {
        customers {
          activity
          email
          fullName
          id
        }
        internalPages {
          category
          path
          title
        }
      }
    }
  `,
  () => ({
    query: query.value
  })
);

const customers = computed(() => result.value?.search.customers);
const pages = computed(() =>
  result.value ? filterRoutes(result.value.search.internalPages) : []
);

const noResults = computed(
  () => !loading && !customers.value.length && !pages.value.length
);
watch(noResults, (value) => {
  if (value) {
    mixpanel.track('search_noresults_shown');
  }
});

const pagesIcons = {
  account: 'person',
  calendar: 'calendar',
  customers: 'people',
  dashboard: 'monitor',
  feedback: 'message',
  marketing: 'marketing',
  messages: 'email',
  support: 'info',
  products: 'pricetag',
  register: 'shopping-cart',
  reports: 'activity',
  settings: 'settings',
  treatwell: 'treatwell'
};

const selectedIndex = inject<any>('selectedIndex');

const onKeyPress = (e) => {
  if (e.key === 'ArrowDown') {
    if (selectedIndex.value < customers.value.length + pages.value.length - 1) {
      emit('selectIndex', selectedIndex.value + 1);
    }
  } else if (e.key === 'ArrowUp') {
    if (selectedIndex.value > 0) {
      emit('selectIndex', selectedIndex.value - 1);
    }
  }
};

window.addEventListener('keydown', onKeyPress);

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyPress);
});
</script>

<style lang="scss" module>
.base {
  position: relative;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 247px;
}

.helpArticleLink {
  padding-top: $spacing * 0.5;
  margin-top: $spacing * 0.5;
  border-top: 1px solid $color-border;
}

.query {
  font-weight: bold;
}

.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing;
  padding: $spacing;

  img {
    max-width: 120px;
  }
}
</style>
