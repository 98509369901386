<template>
  <div :class="[$style.base, { [$style.smallScreen]: $screen === 's' }]">
    <div :class="$style.container">
      <img src="/img/salonized-pay/clock.svg" :class="$style.clockImg" />
      <BaseHeading center>
        {{ $t('register.salonized_pay.landing.steps_section.heading') }}
      </BaseHeading>
    </div>
    <div :class="$style.pills">
      <div :class="$style.pillContainer">
        <div :class="$style.pill">
          <BaseText bold>
            {{ $t('register.salonized_pay.landing.steps_section.step_1') }}
          </BaseText>
          <BaseIcon name="pin" :ml="0.5" color="primary" />
        </div>
        <div :class="$style.text">
          <BaseText :mt="2">
            {{
              replacePinapparaat(
                $t('register.salonized_pay.landing.steps_section.step_1_title'),
                company.country
              )
            }}
          </BaseText>
          <BaseButton :mt="2" @click="$emit('getStarted')">
            {{ $t('register.salonized_pay.landing.steps_section.get_started') }}
          </BaseButton>
        </div>
      </div>
      <div :class="$style.pillContainer">
        <div :class="$style.pill">
          <BaseText bold>
            {{ $t('register.salonized_pay.landing.steps_section.step_2') }}
          </BaseText>
        </div>
        <div :class="$style.text">
          <BaseText :mt="2">
            {{
              $t('register.salonized_pay.landing.steps_section.step_2_title')
            }}
          </BaseText>
        </div>
      </div>
      <div :class="$style.pillContainer">
        <div :class="$style.pill">
          <BaseText bold>
            {{ $t('register.salonized_pay.landing.steps_section.step_3') }}
          </BaseText>
        </div>
        <div :class="$style.text">
          <BaseText :mt="2">
            {{
              $t('register.salonized_pay.landing.steps_section.step_3_title')
            }}
          </BaseText>
        </div>
      </div>
      <div :class="$style.pillContainer">
        <div :class="$style.pill">
          <BaseText bold>
            {{ $t('register.salonized_pay.landing.steps_section.step_4') }}
          </BaseText>
        </div>
        <div :class="$style.text">
          <BaseText :mt="2">
            {{
              $t('register.salonized_pay.landing.steps_section.step_4_title')
            }}
          </BaseText>
        </div>
      </div>
      <div :class="$style.line" />
      <div :class="$style.secondLine" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCompanyStore } from '@/stores/company';
import { replacePinapparaat } from '@/helpers/formatting';

const { company } = useCompanyStore();

defineEmits(['getStarted']);
</script>

<style lang="scss" module>
.base {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 150px $spacing 0px $spacing;

  background-size: 100% 100%;
  background-position:
    0px 0px,
    0px 0px,
    0px 0px;
  background-image: linear-gradient(130deg, #dcdcf9d1 10%, #ffffff00 50%),
    radial-gradient(75% 75% at 88% -21%, #03cfa569 1%, #ffffff00 62%),
    radial-gradient(75% 75% at 50% 50%, #ffffffff 0%, #ffffffff 99%);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 950px;
  width: 100%;
}

.clockImg {
  height: 100px;
  width: 50px;
}

.pills {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  max-width: 950px;
  width: 100%;

  margin-top: $spacing * 4;

  .smallScreen & {
    flex-direction: column;
  }

  .pillContainer:first-child .pill {
    border: 4px solid $color-primary;
  }
}

.pillContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  z-index: 3;

  .smallScreen & {
    height: 160px;

    &:first-of-type {
      height: 220px;
    }
  }
}

.pill {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 4px solid $color-border;
  border-radius: 40px;
  width: max-content;
  padding: $spacing * 0.5 $spacing;
  min-width: 120px;
  background-color: white;
}

.text {
  max-width: 200px;
  text-align: center;
}

.line {
  display: flex;
  position: absolute;
  height: 4px;
  background-color: $color-border;
  width: 80%;
  z-index: 1;
  top: 22px;
  left: 50px;

  .smallScreen & {
    display: none;
  }
}

.secondLine {
  position: absolute;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgba(63, 57, 172, 1) 0%,
    rgba(219, 219, 219, 1) 100%
  );
  width: 35%;
  z-index: 2;
  top: 22px;
  left: 50px;

  .smallScreen & {
    display: none;
  }
}
</style>
