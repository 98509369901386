<template>
  <SalesReport type="services" />
</template>

<script lang="ts">
import SalesReport from '@/modules/reports/components/SalesReport.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Services',
  components: { SalesReport }
});
</script>
