<template>
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 5.16675C11.04 5.16675 10.6666 5.54008 10.6666 6.00008C10.6666 6.46008 11.04 6.83341 11.5 6.83341C11.96 6.83341 12.3333 6.46008 12.3333 6.00008C12.3333 5.54008 11.96 5.16675 11.5 5.16675ZM11.5 8.50008C10.1216 8.50008 8.99996 7.37841 8.99996 6.00008C8.99996 4.62175 10.1216 3.50008 11.5 3.50008C12.8783 3.50008 14 4.62175 14 6.00008C14 7.37841 12.8783 8.50008 11.5 8.50008ZM11.5 0.166748H6.49996C3.28413 0.166748 0.666626 2.78425 0.666626 6.00008C0.666626 9.21591 3.28413 11.8334 6.49996 11.8334H11.5C14.7158 11.8334 17.3333 9.21591 17.3333 6.00008C17.3333 2.78425 14.7158 0.166748 11.5 0.166748Z"
      fill="#03CFA5"
    />
  </svg>
</template>
