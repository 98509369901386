<template>
  <div :class="$style.base" v-test="'register-empty-results'">
    {{ $t('global.no_results') }}
  </div>
</template>
<style lang="scss" module>
.base {
  display: flex;
  padding: $spacing;
  align-items: center;
}
</style>
