<template>
  <BaseModalLarge
    :heading="template.heading"
    noPadding
    :loading="loading || loadingFooter"
    :parentRoute="parentRoute"
    useForm
    @submit="submitForm"
  >
    <TemplateVariables
      v-if="showVariablesModal"
      @close="showVariablesModal = false"
    />
    <FooterTemplateModal
      v-if="showFooterModal"
      :content="footerContent"
      :variables="footerVariables"
      @close="showFooterModal = false"
      @update="footerContent = $event"
    />
    <div
      :class="[
        $style.base,
        {
          [$style.smallScreen]: $screen === 's'
        }
      ]"
    >
      <PageSplit :width="45">
        <template #left>
          <BaseText mb>
            {{ template.description }}
          </BaseText>

          <EmailReminderSettings
            v-if="templateId === 'appointment_reminder_email'"
            :formData="companySettingsData"
          />

          <NoShowSettings
            v-if="templateId === 'appointment_no_show_email'"
            :formData="companySettingsData"
          />

          <FeedbackRequestSettings
            v-if="templateId === 'feedback_request_email'"
            :formData="companySettingsData"
          />

          <BaseInput
            v-model="subject"
            :label="$t('email_templates.subject')"
            mb
            required
            :disabled="disableFields"
            v-test="'email-template-subject'"
          />
          <BaseEditor
            v-if="!hideContentEditor"
            v-model="content"
            :label="$t('email_templates.message')"
            :mb="1.5"
            html
            :cloudinaryName="UploadedImageUsage.NewsletterContent"
            required
            :disabled="disableFields"
            v-test="'email-template-content'"
          />
          <div>
            <BaseText
              link
              :mb="0.5"
              @click="showVariablesModal = true"
              v-test="'email-template-variables-link'"
            >
              {{ $t('marketing.email.sections.personalize_email') }}
            </BaseText>
          </div>
          <BaseText
            link
            @click="showFooterModal = true"
            v-test="'email-template-footer-link'"
          >
            {{ $t('email_templates.footer.click_here') }}
          </BaseText>
        </template>
        <template #right>
          <Preview
            v-if="!hideContentEditor"
            :content="content"
            :footerContent="footerContent"
          />
        </template>
      </PageSplit>
    </div>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="close"
        v-test="'email-template-cancel'"
      >
        {{ $t('global.actions.cancel') }}
      </BaseButton>
      <BaseButton
        :loading="saving || savingCompany"
        submitForm
        v-test="'email-template-submit'"
      >
        {{ $t('global.actions.save') }}
      </BaseButton>
    </template>
  </BaseModalLarge>
</template>

<script lang="ts">
export default {
  name: 'EmailTemplate'
};
</script>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useTemplateData } from '../data';
import gql from 'graphql-tag';
import TemplateVariables from './TemplateVariables.vue';
import Preview from './Preview.vue';
import FooterTemplateModal from './FooterTemplateModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { flash } from '@/helpers/ui';
import { useI18n } from 'vue-i18n';
import PageSplit from '@/modules/PageSplit.vue';
import { UploadedImageUsage } from '@/types';
import { useSettingsData } from '../../settings';
import EmailReminderSettings from './settings/EmailReminder.vue';
import NoShowSettings from './settings/NoShow.vue';
import FeedbackRequestSettings from './settings/FeedbackRequest.vue';

const { t } = useI18n();

const parentRoute = ref('message-templates');
const showVariablesModal = ref(false);
const showFooterModal = ref(false);

const { templates } = useTemplateData();
const route = useRoute();

const templateId = computed(() => route.params.id);
provide('templateId', templateId);

const template = computed(
  () =>
    templates.find((templateData) => templateData.id === templateId.value) || {
      heading: '',
      description: ''
    }
);

const subject = ref('');
const content = ref('');
const hideContentEditor = computed(
  () => templateId.value === 'digital_invoice'
);

const stripParagraphs = (textContent: string) => {
  let text = textContent;
  // eslint-disable-next-line no-useless-escape
  text = text
    .replaceAll('<p><span style="font-size: 0.875rem;"></span></p>', '')
    .replaceAll('<p></p>', '');
  text = text.replaceAll('<p>', '').replaceAll('</p>', '<br><br>');
  return text;
};

const { onResult, loading } = useQuery(
  gql`
    query getCompanyEmailTemplate($id: ID!) {
      companyEmailTemplate(id: $id) {
        content
        id
        subject
      }
    }
  `,
  {
    id: templateId.value
  }
);

onResult(({ data: { companyEmailTemplate } }) => {
  subject.value = companyEmailTemplate.subject || '';
  content.value = companyEmailTemplate.content
    ? stripParagraphs(companyEmailTemplate.content)
    : '';
});

const { onResult: onFooterResult, loading: loadingFooter } = useQuery(
  gql`
    query getCompanyEmailFooterTemplate($id: ID!) {
      companyEmailTemplate(id: $id) {
        content
        id
        variables
      }
    }
  `,
  {
    id: 'email_footer'
  }
);

const footerContent = ref('');
const footerVariables = ref([]);

onFooterResult(({ data: { companyEmailTemplate } }) => {
  footerContent.value = companyEmailTemplate.content
    ? stripParagraphs(companyEmailTemplate.content)
    : '';
  footerVariables.value = companyEmailTemplate.variables.map(
    (variable: string) => variable.toLowerCase()
  );
});

const { companySettingsData, updateCompany, savingCompany, dataChanged } =
  useSettingsData();

const disableFields = computed(
  () =>
    (templateId.value === 'appointment_reminder_email' &&
      !companySettingsData.settings.reminders.emailReminderEnabled) ||
    (templateId.value === 'appointment_no_show_email' &&
      !companySettingsData.settings.communication.appointmentNoShow) ||
    (templateId.value === 'feedback_request_email' &&
      !companySettingsData.settings.customers.appointmentFeedback)
);

const { mutate, loading: saving } = useMutation(gql`
  mutation updateCompanyEmailTemplate(
    $input: UpdateCompanyEmailTemplateInput!
  ) {
    updateCompanyEmailTemplate(input: $input) {
      companyEmailTemplate {
        id
      }
    }
  }
`);

const router = useRouter();
const close = () => {
  router.push({
    name: parentRoute.value
  });
};

const submitForm = () => {
  mutate({
    input: {
      id: templateId.value,
      subject: subject.value,
      content: content.value
    }
  }).then(() => {
    if (dataChanged.value) {
      updateCompany().then(() => {
        afterMutation();
      });
    } else {
      afterMutation();
    }
  });
};

const afterMutation = () => {
  flash(t('global.flash.template_saved'));
  close();
};
</script>

<style lang="scss" module>
.base {
  height: 100%;
}
</style>
../../data
