<template>
  <div
    :class="[
      $style.base,
      {
        [$style.isSmall]: small,
        [$style.hasValue]: !!localValue
      }
    ]"
  >
    <div :class="$style.icon">
      <BaseIcon name="search" />
    </div>
    <div :class="$style.input">
      <BaseInput
        v-model="localValue"
        :placeholder="placeholder || `${$t('global.actions.search')}..`"
        type="search"
        :focus="focus"
        :required="required"
        :size="small ? 's' : 'm'"
        :error="error"
        @focus="onSearchFocus"
        @blur="onSearchBlur"
        v-test="'baseSearch'"
      />
      <div
        v-show="localValue"
        :class="$style.close"
        @click="removeQuery"
        v-test="'search-close-button'"
      >
        <BaseIcon name="close" />
      </div>
    </div>
    <div ref="history" :class="$style.history">
      <SearchHistory
        v-if="historyKey"
        :show="!localValue && showCustomerSearchHistory"
        :historyKey="historyKey"
        :searchHistory="searchHistory"
        @select="(item) => selectHistoryItem(item)"
        @delete="(item) => deleteHistoryItem(item)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import SearchHistory from './SearchHistory.vue';
import { useStorage } from '@vueuse/core';

export default defineComponent({
  name: 'BaseSearch',
  components: {
    SearchHistory
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    focus: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Boolean,
      default: false
    },
    error: String,
    required: {
      type: Boolean,
      default: false
    },
    historyKey: {
      type: String
    }
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props) {
    const searchHistory: Ref<string[]> = props.historyKey
      ? useStorage(props.historyKey, [])
      : ref([]);
    const maxLength = 10;

    const addItem = (item: string) => {
      // only save actual strings
      if (!item || !props.historyKey) {
        return;
      }

      // Remove item from history
      searchHistory.value = searchHistory.value.filter(
        (element) => element !== item
      );

      // Add item to start of history
      if (
        searchHistory.value.length &&
        item.startsWith(searchHistory.value[0])
      ) {
        searchHistory.value[0] = item;
      } else {
        searchHistory.value.unshift(item);
      }

      // Limit size of history
      if (searchHistory.value.length > maxLength) {
        searchHistory.value = searchHistory.value.splice(0, maxLength);
      }
    };

    const deleteItem = (item: string) => {
      if (!item || !props.historyKey) {
        return;
      }
      // Remove item from history
      searchHistory.value = searchHistory.value.filter(
        (element) => element !== item
      );
    };

    return {
      addItem,
      deleteItem,
      searchHistory
    };
  },
  data() {
    return {
      isTyping: null,
      showCustomerSearchHistory: false
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        if (this.isTyping) {
          clearTimeout(this.isTyping);
        }

        const emitValue = () => {
          if (value && this.historyKey) {
            this.addItem(value);
          }
          this.$emit('update:modelValue', value);
        };

        this.debounce && value
          ? (this.isTyping = setTimeout(emitValue, 500))
          : emitValue();
      }
    }
  },
  methods: {
    removeQuery() {
      this.showCustomerSearchHistory = false;
      this.localValue = '';
    },
    onSearchFocus() {
      this.showCustomerSearchHistory = true;
      this.$emit('focus');
    },
    onSearchBlur() {
      if (this.showCustomerSearchHistory) {
        setTimeout(() => {
          this.showCustomerSearchHistory = false;
        }, 200);
      }
    },
    selectHistoryItem(value: string) {
      if (value && this.historyKey) {
        this.addItem(value);
      }
      this.$emit('update:modelValue', value);
      this.showCustomerSearchHistory = false;
    },
    deleteHistoryItem(value: string) {
      setTimeout(() => {
        this.showCustomerSearchHistory = true;
      }, 250);
      if (value && this.historyKey) {
        this.deleteItem(value);
      }
    }
  }
});
</script>

<style lang="scss" module>
.base {
  position: relative;
  min-width: 140px;
  max-width: 100%;
}

.input {
  position: relative;

  input {
    padding-left: 36px !important;

    .base.hasValue & {
      padding-right: 28px !important;
    }
  }
}

.icon,
.close {
  position: absolute;
  top: 0;
  width: 20px;
  z-index: 1;
  display: flex;
  align-items: center;

  .base.isSmall & {
    height: $input-height-small;
  }

  .base:not(.isSmall) & {
    height: $input-height;
  }
}

.icon {
  left: 12px;
  pointer-events: none;
}

.close {
  padding: $spacing * 0.5 $spacing * 1.75 $spacing * 0.5 0;
  cursor: pointer;
  right: 0;
}
</style>
