<template>
  <div :class="$style.base">
    <div :class="$style.line">
      <BaseText color="inverted">
        {{ $t('register.salonized_pay.landing.pricing_section.title_1') }}
      </BaseText>
      <BaseText color="inverted" bold>
        {{ $t('register.salonized_pay.landing.pricing_section.description_1') }}
      </BaseText>
    </div>
    <div :class="$style.line">
      <BaseText color="inverted">
        {{ $t('register.salonized_pay.landing.pricing_section.title_2') }}
      </BaseText>
      <BaseText color="inverted" bold>
        {{ $t('register.salonized_pay.landing.pricing_section.description_2') }}
      </BaseText>
    </div>
    <div :class="$style.line">
      <BaseText color="inverted">
        {{ $t('register.salonized_pay.landing.pricing_section.title_3') }}
      </BaseText>
      <BaseText color="inverted" bold>
        {{ $t('register.salonized_pay.landing.pricing_section.description_3') }}
      </BaseText>
    </div>
  </div>
</template>
<style lang="scss" module>
.base {
  background-size: 100% 100%;
  background-position:
    0px 0px,
    0px 0px,
    0px 0px,
    0px 0px;
  background-image: radial-gradient(
      70% 300% at 5% 104%,
      #c9c8ee5a 1%,
      #073aff00 30%
    ),
    radial-gradient(30% 180% at 35% 12%, #03cfa644 0.1%, #073aff00 30%),
    radial-gradient(20% 100% at 105% 51%, #c9c8ee70 1%, #073aff00 30%),
    linear-gradient(136deg, #262563 24.66%, #6d73db 98.66%);
  border-radius: $radius;
  padding: 0px $spacing 0px $spacing;
  width: 84%;
  max-width: 950px;
  width: 100%;
}

.line {
  display: flex;
  justify-content: space-between;

  border: none;
  padding: $spacing 0px $spacing 0px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-primary-light;
  }
}
</style>
