import gql from 'graphql-tag';

const treatmentFragment = gql`
  fragment iplTreatment on IplTreatment {
    description
    id
    treatments
  }
`;

const reportFragment = gql`
  fragment iplReport on IplTreatmentReport {
    amount
    date
    employeeId
    employeeName
    id
    iplTreatmentId
    notes
    power
    treatment
  }
`;

export const GET_IPL_TREATMENTS = gql`
  query getIplTreatments($customerId: ID!) {
    iplTreatments(customerId: $customerId) {
      ...iplTreatment
    }
  }
  ${treatmentFragment}
`;
export const GET_IPL_REPORTS = gql`
  query getIplTreatmentReports($customerId: ID!, $ids: [ID!]) {
    iplTreatmentReports(customerId: $customerId, ids: $ids) {
      ...iplReport
    }
  }
  ${reportFragment}
`;

export const CREATE_IPL_TREATMENT = gql`
  mutation createIplTreatment($input: CreateIplTreatmentInput!) {
    createIplTreatment(input: $input) {
      iplTreatment {
        ...iplTreatment
      }
    }
  }
  ${treatmentFragment}
`;

export const UPDATE_IPL_TREATMENT = gql`
  mutation updateIplTreatment($input: UpdateIplTreatmentInput!) {
    updateIplTreatment(input: $input) {
      iplTreatment {
        ...iplTreatment
      }
    }
  }
  ${treatmentFragment}
`;

export const DELETE_IPL_TREATMENT = gql`
  mutation deleteIplTreatment($input: DeleteIplTreatmentInput!) {
    deleteIplTreatment(input: $input) {
      iplTreatment {
        id
      }
    }
  }
`;

export const CREATE_IPL_REPORT = gql`
  mutation createIplTreatmentReport($input: CreateIplTreatmentReportInput!) {
    createIplTreatmentReport(input: $input) {
      iplTreatmentReport {
        ...iplReport
      }
    }
  }
  ${reportFragment}
`;

export const UPDATE_IPL_REPORT = gql`
  mutation updateIplTreatmentReport($input: UpdateIplTreatmentReportInput!) {
    updateIplTreatmentReport(input: $input) {
      iplTreatmentReport {
        ...iplReport
      }
    }
  }
  ${reportFragment}
`;

export const DELETE_IPL_REPORT = gql`
  mutation deleteIplTreatmentReport($input: DeleteIplTreatmentReportInput!) {
    deleteIplTreatmentReport(input: $input) {
      iplTreatmentReport {
        id
      }
    }
  }
`;
