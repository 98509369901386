<template>
  <BaseCard
    :heading="filters.capitalize($t('global.items.note', 2))"
    :loading="loading"
    :headerMargin="false"
  >
    <CreateCustomerNote
      v-if="showCreateModal"
      :note="modalData"
      @created="onCreated"
      @updated="onUpdated"
      @close="onModalClose"
    />

    <template #header>
      <BaseText
        link
        @click="showCreateModal = true"
        v-test="'customer-note-create'"
      >
        {{ $t('global.actions.create_note') }}
      </BaseText>
    </template>

    <div v-if="notes && notes.length">
      <BaseTableRow
        v-for="(note, index) in notes"
        :key="index"
        :options="['complete', 'delete', 'edit']"
        @optionClick="onOptionClick(note, $event)"
        v-test="'customer-note'"
      >
        <BaseTableCell :class="$style.align">
          <BaseAvatar
            :name="resourceById(note.resourceId).name"
            :picture="resourceById(note.resourceId).picture"
            mr
          />
          <div>
            <div :class="$style.alignIcon">
              <BaseIcon
                v-if="note.completedAt && $screen !== 'l'"
                size="s"
                name="check"
                color="success"
                :mr="0.5"
              />
              <BaseText bold>
                {{ resourceById(note.resourceId).name }}
                <BaseText
                  v-if="note.date"
                  size="s"
                  inline
                  color="secondary"
                  v-test="'customer-note-followup'"
                >
                  – {{ $t('customers.follow_up') }}
                  {{ filters.date(note.date) }}
                </BaseText>
              </BaseText>
            </div>
            <BaseText>
              <span v-html="filters.formatEnters(note.text)" />
            </BaseText>
            <BaseText size="s" color="secondary">
              {{ filters.date(note.createdAt) }}
            </BaseText>
          </div>
        </BaseTableCell>
        <BaseTableCell v-if="note.completedAt && $screen === 'l'" small right>
          <BaseLabel color="success" v-test="'customer-note-completed'">
            {{ $t('global.completed') }}
          </BaseLabel>
        </BaseTableCell>
      </BaseTableRow>
    </div>
    <EmptyPageContent
      v-else-if="!notes.length"
      imageName="customer-notes"
      :text="{
        description: $t('customers.empty_state.notes.description')
      }"
    />
  </BaseCard>
</template>

<script lang="ts" setup>
import filters from '@/filters';
import { flash, modal } from '@/helpers/ui';
import CreateCustomerNote from './CreateCustomerNote.vue';
import EmptyPageContent from '@/components/EmptyPageContent.vue';
import BaseTableRow from '@/components/_deprecated/BaseTableRow.vue';
import BaseTableCell from '@/components/_deprecated/BaseTableCell.vue';
import { inject, ref } from 'vue';
import { GET_NOTES, DELETE_NOTE, UPDATE_NOTE } from '../graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import type { Note } from '@/types';
import { useResourcesStore } from '@/stores/resources';
import { useI18n } from 'vue-i18n';
import dayjs from '@/dayjs';

const customer = inject<any>('customer');
const showCreateModal = ref(false);
const { resourceById } = useResourcesStore();

const notes = ref<Note[]>([]);

const { onResult, loading } = useQuery(GET_NOTES, () => ({
  customerId: customer.value.id
}));

onResult(({ data }) => {
  notes.value = data.notes;
});

const { t } = useI18n();

const modalData = ref();
const onOptionClick = (note: Note, option: string) => {
  switch (option) {
    case 'complete':
      {
        note.completedAt = note.completedAt ? null : dayjs().format();

        const { mutate } = useMutation(UPDATE_NOTE);
        mutate({
          input: {
            id: note.id,
            completedAt: note.completedAt
          }
        }).then(() => {
          flash(t('global.flash.note_updated'));
        });
      }
      break;
    case 'edit':
      {
        const _modalData = {
          ...note
        };
        delete _modalData.__typename;
        modalData.value = _modalData;

        showCreateModal.value = true;
      }
      break;
    case 'delete':
      {
        modal('confirmation', {
          type: 'delete',
          item: t('global.items.note', 1)
        }).then(() => {
          const index = notes.value.findIndex((n: Note) => n.id === note.id);
          notes.value.splice(index, 1);

          const { mutate } = useMutation(DELETE_NOTE);

          mutate({
            input: {
              id: note.id
            }
          }).then(() => {
            flash(t('global.flash.note_created'));
          });
        });
      }
      break;
  }
};

const onModalClose = (data: any) => {
  if (data.id) {
    modalData.value = null;
  } else {
    modalData.value = data;
  }

  showCreateModal.value = false;
};

const onCreated = (note: Note) => {
  showCreateModal.value = false;
  notes.value.unshift(note);
};

const onUpdated = (note: Note) => {
  showCreateModal.value = false;
  const existing = notes.value.find((n) => n.id === note.id);
  if (existing) {
    Object.keys(note).forEach((key) => {
      existing[key] = note[key];
    });
  }
};
</script>

<style lang="scss" module>
.align {
  display: flex;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
  line-height: 16px;
}

.alignIcon {
  display: flex;
  align-items: center;
}

.lineHeight {
  line-height: 20px;
}

.normal {
  font-weight: normal;
}
</style>
