import { nextTick } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { devWarning } from '@/helpers/dev';
import {
  isRouteAllowed,
  redirectRoute,
  redirectRouteWithoutParams
} from './helpers';
import routes from './routes';
import { modal } from '@/helpers/ui';

import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useLocationsStore } from '@/stores/locations';
import { useSessionStore } from '@/stores/session';

import { useGtm } from '@gtm-support/vue-gtm';
import i18n from '@/i18n';
import eventBus from '@/event-bus';
import config from '@/config';
import axios from '@/axios';

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[]
});

let localeFetched = false;
let firstRouteLoaded = false;

router.beforeEach(async (to) => {
  if (!firstRouteLoaded && to.name) {
    firstRouteLoaded = true;
    const sessionStore = useSessionStore();
    sessionStore.initialRoute = to;
  }

  if (to.params.locationId && typeof to.params.locationId === 'string') {
    //  Save the locationId param from the route in the store
    const locationStore = useLocationsStore();
    locationStore.routeLocationId = to.params.locationId;
  }

  const { requiredDataLoaded, fetchAllData } = useSessionStore();

  if (!requiredDataLoaded) {
    // Required data has not been loaded yet, fetch all data
    await fetchAllData();
  }

  const { user } = useUserStore();

  const { needsVerification } = useUserStore();
  if (needsVerification) {
    // User is logged in, but still needs to verify their account
    if (to.name !== 'verification') {
      return {
        name: 'verification'
      };
    } else {
      return;
    }
  } else if (to.name === 'verification') {
    // User is visiting the verification page, but does not need to verify

    return user
      ? redirectRoute({ query: to.query })
      : { name: 'login', query: to.query };
  }

  if (!user) {
    // User is not logged in

    if (to.query.treatwell === 'true') {
      const companyStore = useCompanyStore();
      companyStore.treatwellSignup = true;
    }

    if (to.path === '/') {
      // User is visiting the root path, redirect to login page

      return {
        name: 'login',
        query: to.query
      };
    } else if (!isRouteAllowed(to)) {
      // When not logged in, the user is not allowed to visit any "logged in" routes. Redirect to login page in that case

      devWarning(
        'This user is not allowed to visit the requested route. Redirecting to login page.'
      );
      return {
        name: 'login',
        query: to.query
      };
    } else {
      // No redirect. Return here to not execute the other checks, which are only needed when the user is logged in
      return;
    }
  }

  const { isBlocked } = useCompanyStore();
  if (isBlocked) {
    // Company is blocked
    if (
      to.name !== 'admin-billing' &&
      to.name !== 'admin-billing-legacy' &&
      to.name !== 'admin-invoice' &&
      to.name !== 'logged-out'
    ) {
      return redirectRoute({ name: 'admin-billing' });
    }
    // should not redirect if already on admin-billing page
    return;
  }

  //  Redirect when user has trial expired
  const { isTrialExpired, isTreatwellUser } = useCompanyStore();
  if (
    !isTreatwellUser &&
    isTrialExpired &&
    !to.matched.find((route) => route.name === 'subscription') &&
    to.name !== 'logged-out'
  ) {
    return redirectRoute({ name: 'stripe-checkout' });
  } else if (
    isTreatwellUser &&
    isTrialExpired &&
    !to.matched.find((route) => route.name === 'tw-checkout') &&
    to.name !== 'logged-out'
  ) {
    return redirectRoute({ name: 'tw-checkout' });
  }

  if (to.path === '/') {
    // User is visiting the root path. Redirect to dashboard

    return redirectRoute({ name: 'dashboard', query: to.query });
  }

  if (!isRouteAllowed(to)) {
    // User is not allowed to visit this route. Redirect to dashboard

    devWarning(
      'This user is not allowed to visit the requested route. Redirecting to dashboard page.'
    );
    return redirectRoute({ name: 'dashboard', query: to.query });
  }

  const { companyId } = useCompanyStore();
  const { locationId } = useLocationsStore();

  if (to.name === '404' || to.name === 'outside-app') {
    // Instead of showing a 404 page, we redirect the user based on the requested url
    // When company or location params are missing, we redirect them to the correct page
    // For example, visiting /calendar or /c/1/calendar should both redirect to /c/l/1/calendar
    // We do this by matching the last segment of the route path with existing routes

    const paths = to.path.split('/').filter((path) => !!path);
    if (companyId && locationId) {
      const routeObj = redirectRouteWithoutParams(paths, {
        companyId,
        locationId
      });
      if (routeObj && routeObj.name) {
        devWarning(
          'This user did not specify both companyId and locationId in the path, redirecting with those params'
        );
        return redirectRoute({
          name: routeObj.name.toString(),
          params: routeObj.params,
          query: to.query
        });
      }
    }
  }

  if (
    to.name &&
    to.name !== 'become' &&
    (to.params.companyId !== companyId?.toString() ||
      to.params.locationId !== locationId?.toString())
  ) {
    if (user.salonizedAdmin && to.params.companyId) {
      return {
        name: 'become'
      };
    } else {
      // User is visiting a correct and allowed route, but it using the wrong companyId or locationId
      // Redirect to the same route with the correct params
      devWarning(
        'This user is not using the correct companyId and locationId. Redirecting'
      );
      return redirectRoute({ name: to.name.toString(), query: to.query });
    }
  }
});

router.afterEach((to) => {
  const { company, companyNeedsType } = useCompanyStore();

  // Company needs to select type, show the type selection modal
  if (companyNeedsType) {
    nextTick(() => modal('company-type'));
  }

  // Track pageview with Google Tag Manager
  const gtm = useGtm();
  if (gtm) {
    gtm.trackEvent({
      event: 'pageview',
      route: to.name,
      url: to.path
    });
  }

  // When there is no company data and the route does not have a locale param, fetch the locale from the backend
  if (!company && !to.params.locale && !localeFetched && !config.isTest) {
    axios.get('/sessions/visitor_locale').then(({ data: { locale } }) => {
      i18n.locale.value = locale?.default_language || 'en';
      localeFetched = true;
    });
  }

  // When the route has a locale param, set the i18n locale
  const locale = to.params.locale?.toString();
  if (locale && i18n.availableLocales?.includes(locale)) {
    // Only set the locale if the locale from the route param actually exists in i18n
    i18n.locale.value = locale;
  } else if (locale && to.name) {
    // The route has a locale param, but the locale doesn't exist in the available locales of i18n
    // Replace the route without the param

    router.replace({
      name: to.name,
      params: {
        locale: null
      }
    });
  }
});

eventBus.$on('logout', () => {
  const { isTreatwellUser } = useCompanyStore();

  router.push({ name: 'logged-out' }).then(() => {
    const { resetData } = useSessionStore();
    resetData();

    const companyStore = useCompanyStore();
    if (isTreatwellUser) {
      companyStore.treatwellSignup = true;
    }
  });
});

export default router;
