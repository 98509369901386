import { defineStore } from 'pinia';

interface State {
  currentOnboardingFlow: string;
}

export const useOnboardingStore = defineStore('onboarding', {
  state: (): State => ({
    currentOnboardingFlow: ''
  })
});
