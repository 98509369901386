<template>
  <BaseModalSmall testId="email-domain-info">
    <BaseHeading mb>{{ $t('email_domain.title') }}</BaseHeading>
    <BaseText inline :mr="0.25">{{
      $t('email_domain.email_domain_popup_info')
    }}</BaseText>
    <BaseText inline :href="$t('email_domain.help_url')">
      {{ $t('email_domain.more_info') }}
    </BaseText>
  </BaseModalSmall>
</template>
