const Account = () => import('@/modules/account/index.vue');
const TwoFactorAuthSettingsEnable = () =>
  import('@/modules/account/modals/TwoFactorAuthSettingsEnable.vue');
const TwoFactorAuthSettingsDisable = () =>
  import('@/modules/account/modals/TwoFactorAuthSettingsDisable.vue');

export default {
  path: 'account',
  name: 'account',
  component: Account,
  meta: {
    hideIntercomButton: {
      mobile: true
    }
  },
  children: [
    {
      path: 'enabletwofactor',
      name: 'enable-two-factor-auth-settings',
      component: TwoFactorAuthSettingsEnable
    },
    {
      path: 'disabletwofactor',
      name: 'disable-two-factor-auth-settings',
      component: TwoFactorAuthSettingsDisable
    }
  ]
};
