<template>
  <BaseCard
    :loading="loading"
    :heading="
      dashboard
        ? $t('global.pages.insights')
        : $t('marketing.insights.stats.rebook_reminder.title')
    "
  >
    <template #header>
      <BaseSwitch
        v-if="dashboard"
        v-model="selectedPeriod"
        :options="[
          {
            label: $t('global.this_week'),
            value: 'week'
          },
          {
            label: $t('global.this_month'),
            value: 'month'
          },
          {
            label: $t('global.this_year'),
            value: 'year'
          }
        ]"
        small
      />
      <BaseText
        v-else
        :routerLink="{
          name: 'messages',
          query: { filter: 'rebook_reminder' }
        }"
      >
        {{ $t('nav.messages') }}
      </BaseText>
    </template>
    <MainStat
      :value="marketingStats.rebookMessages"
      text="rebook_reminder.delivered"
    />
    <SubStat
      :mainValue="marketingStats.rebookMessages"
      :subValue="marketingStats.rebookMessagesOpened"
      text="rebook_reminder.opened"
    />
    <SubStat
      :mainValue="marketingStats.rebookMessages"
      :subValue="marketingStats.rebookButtonClicked"
      text="rebook_reminder.clicked"
    />
    <SubStat
      :mainValue="marketingStats.rebookMessages"
      :subValue="marketingStats.bookingsFromMailReminder"
      text="rebook_reminder.booked"
    />
    <BaseAlert
      color="success"
      :text="
        $t('marketing.insights.stats.rebook_reminder.bookedOther', {
          amount: marketingStats.bookingsFromOtherReminder
        })
      "
    />
    <BaseButton
      v-if="!rebookReminderEnabled"
      :routerLink="{ name: 'rebook-reminder' }"
      mt
      v-test="'enable-rebook-reminder'"
    >
      {{ $t('marketing.insights.stats.rebook_reminder.enable') }}
    </BaseButton>
  </BaseCard>
</template>

<script setup lang="ts">
import dayjs from '@/dayjs';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import SubStat from './SubStat.vue';
import MainStat from './MainStat.vue';
import { useCompanyStore } from '@/stores/company';

defineProps<{
  dashboard?: boolean;
}>();

const selectedPeriod = ref<'week' | 'month' | 'year'>('month');
const startDate = ref(dayjs().startOf('month').format());
const endDate = ref(dayjs().format());

watch(selectedPeriod, (value) => {
  const range = value === 'week' ? 'isoWeek' : value;
  startDate.value = dayjs().startOf(range).format();
  endDate.value = dayjs().format();
});

const { result, loading } = useQuery(
  gql`
    query MarketingStats($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
      marketingStats(startDate: $startDate, endDate: $endDate) {
        bookingsFromReminder
        bookingsFromMailReminder
        bookingsFromOtherReminder
        rebookButtonClicked
        rebookMessages
        rebookMessagesOpened
      }
    }
  `,
  () => ({
    startDate: startDate.value,
    endDate: endDate.value
  })
);

const marketingStats = computed(
  () =>
    result.value?.marketingStats || {
      bookingsFromReminder: 0,
      bookingsFromMailReminder: 0,
      bookingsFromOtherReminder: 0,
      rebookButtonClicked: 0,
      rebookMessages: 0,
      rebookMessagesOpened: 0
    }
);

const { companySettings } = useCompanyStore();
const rebookReminderEnabled = computed(
  () => !!companySettings.bookings?.rebookReminderEnabled
);
</script>
