<template>
  <label :for="uniqueLabelId" :class="$style.base">
    <BaseHeading size="s" inline>
      {{ filters.capitalize(text) }} <span v-if="required">*</span>
    </BaseHeading>
    <div v-if="info" :class="$style.icon">
      <BaseIcon
        name="info"
        size="s"
        :tooltip="{
          text: info,
          touch: true
        }"
      />
    </div>
  </label>
</template>

<script lang="ts">
import filters from '@/filters';
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    text: {
      type: String
    },
    uniqueLabelId: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    info: {
      type: String
    }
  },
  setup() {
    return {
      filters
    };
  }
});
</script>

<style lang="scss" module>
.base {
  display: inline-flex;
  margin-bottom: $spacing * 0.5;
}

.icon {
  display: inline-block;
  margin-top: -5px;
  position: relative;
  top: 3px;
  margin-left: 3px;
}
</style>
