<template>
  <div :class="$style.base">
    <BaseSpinner v-if="loading" />
    <div
      v-if="company.blockReason === BlockReason.PausedCollection"
      :class="$style.centeredContent"
    >
      <img
        :class="$style.image"
        src="/img/billing/retry.svg"
        v-test="`billing-page-paused-blocked-image`"
      />
      <div :class="$style.textContainer">
        <div>
          <BaseHeading
            :mt="2"
            mb
            center
            size="l"
            v-test="`billing-page-paused-blocked-title`"
          >
            {{ t('subscription.paused_subscription_page.title') }}
          </BaseHeading>
          <BaseText center v-test="`billing-page-paused-blocked-description`">
            {{ t('subscription.paused_subscription_page.description') }}
          </BaseText>
        </div>
      </div>
      <BaseButton
        :loading="isLoadingMutation"
        @click="reactivatePausedAccount"
        v-test="`billing-page-paused-blocked-action`"
      >
        {{ t('subscription.paused_subscription_page.action') }}
      </BaseButton>
    </div>
    <div v-else-if="unpaidInvoices.length > 0">
      <div :class="$style.textContainer">
        <div>
          <BaseHeading
            :mb="0.5"
            size="l"
            v-test="`billing-page-blocked-title`"
            >{{
              t(`subscription.blocked_account_${translationKey}_page.title`)
            }}</BaseHeading
          >
          <BaseText v-test="`billing-page-blocked-description`">{{
            t(`subscription.blocked_account_${translationKey}_page.description`)
          }}</BaseText>
        </div>
        <img
          :class="$style.invoiceImage"
          src="/img/empty-states/hand-holding-invoice.svg"
          v-test="`billing-page-blocked-image`"
        />
      </div>
      <InvoicesCard
        :invoices="unpaidInvoices"
        :loading="loading"
        :title="$t('admin.cards.invoices.invoices_to_be_paid')"
        :description="$t('admin.cards.invoices.keep_invoices_up_to_date')"
        allInvoicesLink
        @allInvoicesLinkClick="modalOpen = true"
        @downloadInvoice="(id) => openInvoice(id)"
      />
      <BaseText
        link
        :loading="loadingStripePortalUrl"
        @click="goToStripePortal"
        v-test="`stripe-user-portal`"
      >
        {{ t('subscription.blocked_account_change_payment_method') }}
      </BaseText>
      <BaseModal v-if="modalOpen" @close="modalOpen = false">
        <BaseTable
          :headers="[
            $t('global.date'),
            $t('global.amount'),
            $t('global.status'),
            $t('global.number')
          ]"
          :rows="tableData"
          @action="handleAction"
          v-test="'invoices-card-modal-table'"
        />
        <LoadMore v-show="!allDataFetched" @shown="fetchMore" />
      </BaseModal>
    </div>
    <div v-else :class="$style.centeredContent">
      <div :class="$style.textContainer">
        <div>
          <BaseHeading
            :mb="0.5"
            size="l"
            v-test="`billing-page-other-reason-blocked-title`"
            >{{
              t('subscription.blocked_account_other_reason_page.title')
            }}</BaseHeading
          >
          <BaseText v-test="`billing-page-other-reason-blocked-description`">{{
            t('subscription.blocked_account_other_reason_page.description')
          }}</BaseText>
        </div>
      </div>
      <img
        :class="$style.image"
        src="/img/billing/blocked.svg"
        v-test="`billing-page-other-reason-blocked-image`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useMutation } from '@vue/apollo-composable';

import { BlockReason } from '@/types';
import { useStripe } from '@/helpers/stripe';
import { flash, modal } from '@/helpers/ui';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useInvoices } from './stripe-invoices-card/useInvoices';
import { UNPAUSE_SUBSCRIPTION_MUTATION } from './cancel-subscription/graphql';

import InvoicesCard from './stripe-invoices-card/InvoicesCard.vue';

const modalOpen = ref(false);
const isLoadingMutation = ref(false);

const { goToStripePortal, loadingStripePortalUrl } = useStripe();

const {
  unpaidInvoices,
  loading,
  payInvoice,
  openInvoice,
  tableData,
  allDataFetched,
  fetchMore
} = useInvoices();

const { company, hasDispute } = storeToRefs(useCompanyStore());
const { t } = useI18n();

const translationKey = computed(() => (hasDispute.value ? 'dispute' : 'empty'));

const handleAction = ({ id, option }: { id: string; option: string }) => {
  if (option === 'pdf') {
    openInvoice(id);
  }

  if (option === 'pay') {
    payInvoice(id);
  }
};

const reactivatePausedAccount = () => {
  modal('confirmation', {
    message: t('subscription.paused_subscription_page.confirmation_message')
  }).then(() => {
    const { mutate } = useMutation(UNPAUSE_SUBSCRIPTION_MUTATION);
    isLoadingMutation.value = true;
    mutate({ input: {} })
      .then((data) => {
        if (!data?.errors) {
          flash(t('subscription.paused_subscription_page.success'));
          const { getUser } = useUserStore();
          getUser();
        }
      })
      .finally(() => {
        isLoadingMutation.value = false;
      });
  });
};
</script>

<style lang="scss" module>
.base {
  padding-top: $spacing * 4;
  flex-grow: 1;
  max-width: 800px;
  margin: 0 auto;
}

.textContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto $spacing * 2;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invoiceImage {
  margin-left: 30px;
  width: 140px;
}

.image {
  padding-top: $spacing * 2;
}
</style>
