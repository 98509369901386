<template>
  <Builder v-if="!loading" :settings="settings">
    <template #actions>
      <TemplateActions :loading="isLoading" @save="onSave" />
    </template>
  </Builder>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { modal, flash } from '@/helpers/ui';
import Builder from '@/modules/marketing/email/builder/index.vue';
import TemplateActions from '../../TemplateActions.vue';
import { useI18n } from 'vue-i18n';
import { emailTemplateFragment } from '@/graphql-fragments';

import { useStore } from 'vuex';
const { t } = useI18n();
const isLoading = ref(false);
const isInvalid = ref(false);
const router = useRouter();
const mixpanel = inject<any>('mixpanel');
const store = useStore();

const settings = {
  emailType: 'ONLINE_FORMS',
  title: t('marketing.email.automated_emails.online_forms.title'),
  description: t('marketing.email.automated_emails.online_forms.intro'),
  sentDescription: t('marketing.email.automated_emails.online_forms.when'),
  receiveDescription: t(
    'marketing.email.automated_emails.online_forms.receive_info'
  ),
  showToggle: false,
  hideDiscount: true,
  hideButtonToggle: true,
  hideButtonOptions: true
};

const onSave = () => {
  store.commit('marketing/SET_SUBMITTED', true);

  if (isInvalid.value) {
    return;
  }

  modal('confirmation').then(() => {
    isLoading.value = true;
    store
      .dispatch('marketing/updateEmailTemplate', 'ONLINE_FORMS')
      .finally(() => {
        router.push({ name: 'marketing-email' });
        flash(t('global.flash.email_saved'));
        store.commit('marketing/SET_SUBMITTED', false);
        isLoading.value = false;
        mixpanel.track('Online forms - Saved');
      });
  });
};

const { result, onResult, loading } = useQuery(
  gql`
    query getEmailTemplate($emailType: EmailTemplateEnum!) {
      emailTemplate(emailType: $emailType) {
        ...emailTemplate
      }
      emailTemplateDefault: emailTemplate(
        emailType: $emailType
        defaultValues: true
      ) {
        ...emailTemplate
      }
    }
    ${emailTemplateFragment}
  `,
  {
    emailType: 'ONLINE_FORMS'
  }
);

onResult(() => {
  if (result.value.emailTemplate) {
    result.value.emailTemplate.emailTemplateDefault =
      result.value.emailTemplateDefault;
    store.commit('marketing/SET_ATTRS', result.value.emailTemplate);
  }
});
</script>
