import gql from 'graphql-tag';
import { DispatchTypeEnum } from '@/types';
import apolloClient from '@/apollo/client';
import { addLabelIndex } from '@/helpers/formatting';
import dayjs from '@/dayjs';
import builder from './builder';

const defaultImages = {
  BIRTHDAY:
    'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1685021588/marketing_newsletter/Balloons_2_tfbvb3.png',
  MARKETING_CAMPAIGN:
    'http://res.cloudinary.com/salonized-eu-cld/image/upload/v1636022906/marketing_newsletter/os64rkcj7bsvzyf4555a.png',
  DEFAULT_MARKETING:
    'http://res.cloudinary.com/salonized-eu-cld/image/upload/v1636022906/marketing_newsletter/os64rkcj7bsvzyf4555a.png',
  REBOOK_REMINDER:
    'http://res.cloudinary.com/salonized-eu-cld/image/upload/v1636022906/marketing_newsletter/os64rkcj7bsvzyf4555a.png',
  WAITING_LIST:
    'http://res.cloudinary.com/salonized-eu-cld/image/upload/v1636022906/marketing_newsletter/os64rkcj7bsvzyf4555a.png',
  ONLINE_FORMS:
    'https://res.cloudinary.com/salonized-eu-cld/image/upload/v1684849136/marketing_newsletter/Email-cover-forms_ujdf60.png'
};

const getEmptyState = () => ({
  id: null,
  emailType: 'MARKETING_CAMPAIGN',
  hasLogo: true,
  hasImage: true,
  hasDiscount: false,
  hasButton: true,
  hasFooter: true,
  hasDeliverySchedule: false,
  deliverAt: dayjs().add(1, 'day').format(),
  defaultImages,
  imageUrl: defaultImages.MARKETING_CAMPAIGN,
  subject: '',
  message: '',
  discount: null,
  discountMessage: '',
  buttonTitle: '',
  buttonUrl: '',
  color: '',
  allFilters: [],
  isSubmitted: false,
  messageIsInvalid: false,
  discountIsIvalid: false,
  buttonIsInvalid: false,
  scrollContainer: null,
  showFilters: false,
  rebookEnabled: false,
  name: '',
  emailTemplateDefault: null,
  dispatchType: DispatchTypeEnum.Manual
});

const store = {
  namespaced: true,
  modules: {
    builder
  },
  state: getEmptyState(),
  getters: {
    id: (state) => state.id,
    hasLogo: (state) => state.hasLogo,
    hasImage: (state) => state.hasImage,
    hasDiscount: (state) => state.hasDiscount,
    hasButton: (state) => state.hasButton,
    hasFooter: (state) => state.hasFooter,
    hasDeliverySchedule: (state) => state.hasDeliverySchedule,
    discountMessage: (state) => state.discountMessage,
    discount: (state) => state.discount,
    discountCode: (state) => state.discount?.code,
    discountCodeId: (state) => state.discount?.id,
    subject: (state) => state.subject,
    message: (state) => state.message,
    defaultImageUrl: (state) => state.defaultImages[state.emailType],
    imageUrl: (state) => state.imageUrl,
    buttonTitle: (state) => state.buttonTitle,
    buttonUrl: (state) => state.buttonUrl,
    color: (state) => state.color,
    name: (state) => state.name,
    allFilters: (state) => state.allFilters,
    allFiltersForGraphql: (state) =>
      state.allFilters.map((filter) => {
        const objForGraphql = {
          filterName: filter.filter_name.toUpperCase(),
          values: filter.values
        };
        return objForGraphql;
      }),
    isSubmitted: (state) => state.isSubmitted,
    messageIsInvalid: (state) => state.messageIsInvalid,
    discountIsInvalid: (state) => state.discountIsInvalid,
    buttonIsInvalid: (state) => state.buttonIsInvalid,
    scrollContainer: (state) => state.scrollContainer,
    showFilters: (state) => state.showFilters,
    isInvalid: (state, getters) =>
      (getters.hasDiscount && getters.discountIsInvalid) ||
      getters.messageIsInvalid ||
      (getters.hasButton && getters.buttonIsInvalid),
    rebookEnabled: (state) => state.rebookEnabled,
    deliverAt: (state) => state.deliverAt,
    dispatchType: (state) => state.dispatchType,
    formInput:
      (state, getters) =>
      (emailType, options = {}) => {
        const { newRecord = true, sendEmail = false, testEmail } = options;
        const {
          name,
          hasLogo,
          hasImage,
          hasDiscount,
          hasButton,
          hasFooter,
          hasDeliverySchedule,
          deliverAt,
          discountMessage,
          discountCodeId,
          subject,
          message,
          imageUrl,
          buttonTitle,
          buttonUrl,
          color,
          id,
          allFiltersForGraphql,
          dispatchType
        } = getters;

        const input = {
          name,
          hasLogo,
          hasImage,
          hasDiscount,
          hasButton,
          hasFooter,
          hasDeliverySchedule,
          subject,
          message,
          color,
          id,
          dispatchType
        };

        if (newRecord && !id) {
          delete input.id;
        } else {
          input.id = id;
        }

        if (testEmail) {
          input.testEmail = testEmail;
        }

        if (!sendEmail) {
          input.emailType = emailType;
        }

        if (emailType === 'MARKETING_CAMPAIGN') {
          input.filters = allFiltersForGraphql;
        }

        if (hasImage && imageUrl) {
          input.imageUrl = imageUrl;
        }

        if (hasDiscount && discountCodeId) {
          input.discountMessage = discountMessage;
          input.discountCodeId = parseInt(discountCodeId);
        }

        if (hasButton) {
          input.buttonTitle = buttonTitle;
          input.buttonUrl = buttonUrl;
        }

        if (hasDeliverySchedule && deliverAt) {
          input.deliverAt = deliverAt;
        }

        if (emailType !== 'WAITING_LIST') {
          input.dispatchType = null;
        }

        return input;
      },
    emailTemplateDefault: (state) => state.emailTemplateDefault
  },
  mutations: {
    SET_SUBMITTED(state, payload) {
      state.isSubmitted = payload;
    },
    SET_MESSAGE_VALIDATION(state, payload) {
      state.messageIsInvalid = payload;
    },
    SET_DISCOUNT_VALIDATION(state, payload) {
      state.discountIsInvalid = payload;
    },
    SET_BUTTON_VALIDATION(state, payload) {
      state.buttonIsInvalid = payload;
    },
    SET_DISPATCH_TYPE(state, payload) {
      state.dispatchType = payload;
    },
    TOGGLE_LOGO(state) {
      state.hasLogo = !state.hasLogo;
    },
    TOGGLE_DISCOUNT(state) {
      state.hasDiscount = !state.hasDiscount;
    },
    TOGGLE_BUTTON(state) {
      state.hasButton = !state.hasButton;
    },
    TOGGLE_FOOTER(state) {
      state.hasFooter = !state.hasFooter;
    },
    TOGGLE_DELIVERY_SCHEDULE(state) {
      state.hasDeliverySchedule = !state.hasDeliverySchedule;
    },
    SET_DISCOUNT_TOGGLE(state, payload) {
      state.hasDiscount = payload;
    },
    SET_LOGO_STATE(state, payload) {
      state.hasLogo = payload;
    },
    TOGGLE_IMAGE(state) {
      state.hasImage = !state.hasImage;
    },
    SET_IMAGE_URL(state, payload) {
      state.imageUrl = payload;
    },
    SET_SUBJECT(state, payload) {
      state.subject = payload;
    },
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_NAME(state, payload) {
      state.name = payload;
    },
    SET_DISCOUNT_CODE(state, payload) {
      state.discount = payload;
    },
    SET_DISCOUNT_MESSAGE(state, payload) {
      state.discountMessage = payload;
    },
    SET_BUTTON_TITLE(state, payload) {
      state.buttonTitle = payload;
    },
    SET_BUTTON_URL(state, payload) {
      state.buttonUrl = payload;
    },
    SET_COLOR(state, payload) {
      state.color = payload;
    },
    SET_DELIVER_AT(state, payload) {
      state.deliverAt = payload;
    },
    RESET_CONTENT_ONLY(state) {
      const emptyContent = {
        ...getEmptyState(),
        id: state.id,
        name: state.name
      };
      Object.assign(state, emptyContent);
    },
    RESET_TO_EMPTY_STATE(state) {
      Object.assign(state, getEmptyState());
    },
    RESET_TO_DEFAULT_STATE(state) {
      if (state.emailTemplateDefault) {
        this.commit('marketing/SET_ATTRS', state.emailTemplateDefault);
      } else if (state.id) {
        this.commit('marketing/RESET_CONTENT_ONLY');
      } else {
        this.commit('marketing/RESET_TO_EMPTY_STATE');
      }
    },
    CLEAR_FILTERS(state) {
      state.allFilters = [];
    },
    SET_FILTER(state, payload) {
      payload
        ? state.allFilters.push(addLabelIndex(state.allFilters, payload))
        : (state.allFilters = []);
    },
    SET_ALL_FILTERS(state, payload) {
      state.allFilters = payload.map((filter) =>
        addLabelIndex(state.allFilters, filter)
      );
    },
    REMOVE_FILTER(state, payload) {
      state.allFilters = state.allFilters.filter(
        (filter) => filter.labelIndex !== payload.labelIndex
      );
    },
    SET_SCROLL_CONTAINER(state, payload) {
      state.scrollContainer = payload;
    },
    SET_SHOW_FILTERS(state, payload) {
      state.showFilters = payload;
    },
    TOGGLE_FILTERS(state) {
      state.showFilters = !state.showFilters;
    },
    SET_ATTRS(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'discountCode' && value) {
          state.discount = value;
        } else if (key === 'filters' && value) {
          const mappedWithIndex = value.map((filter) => {
            const filterData = addLabelIndex(state.allFilters, filter);
            filterData.filter_name = filterData.filterName;
            delete filterData.filterName;
            return filterData;
          });
          state.allFilters = mappedWithIndex;
        } else {
          state[key] = value;
        }
      }
    }
  },
  actions: {
    createEmailTemplate(context) {
      return new Promise((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation createEmailTemplate($input: CreateEmailTemplateInput!) {
                createEmailTemplate(input: $input) {
                  emailTemplate {
                    id
                  }
                }
              }
            `,
            variables: {
              input: context.getters.formInput('MARKETING_CAMPAIGN', {
                newRecord: true
              })
            }
          })
          .then(() => {
            resolve(null);
          });
      });
    },
    updateEmailTemplate(context, emailType) {
      return new Promise((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateEmailTemplate($input: UpdateEmailTemplateInput!) {
                updateEmailTemplate(input: $input) {
                  emailTemplate {
                    emailType
                  }
                }
              }
            `,
            variables: {
              input: context.getters.formInput(emailType, { newRecord: false })
            }
          })
          .then(() => {
            resolve(null);
          });
      });
    },
    sendMarketingEmail(context, options = {}) {
      return new Promise((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation sendMarketingEmail($input: SendMarketingEmailInput!) {
                sendMarketingEmail(input: $input) {
                  errors
                }
              }
            `,
            variables: {
              input: context.getters.formInput('MARKETING_CAMPAIGN', {
                newRecord: true,
                sendEmail: true,
                testEmail: options.testEmail
              })
            }
          })
          .then(() => {
            resolve(null);
          });
      });
    }
  }
};

export default store;
