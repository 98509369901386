/* eslint-disable */

export default [
  {
    code: 'af',
    prefix: 93,
    name: 'Afghanistan'
  },
  {
    code: 'al',
    prefix: 355,
    name: 'Albania'
  },
  {
    code: 'dz',
    prefix: 213,
    name: 'Algeria'
  },
  {
    code: 'as',
    prefix: 1,
    name: 'AmericanSamoa'
  },
  {
    code: 'ad',
    prefix: 376,
    name: 'Andorra'
  },
  {
    code: 'ao',
    prefix: 244,
    name: 'Angola'
  },
  {
    code: 'ai',
    prefix: 1,
    name: 'Anguilla'
  },
  {
    code: 'ag',
    prefix: 1268,
    name: 'Antigua and Barbuda'
  },
  {
    code: 'ar',
    prefix: 54,
    name: 'Argentina'
  },
  {
    code: 'am',
    prefix: 374,
    name: 'Armenia'
  },
  {
    code: 'aw',
    prefix: 297,
    name: 'Aruba'
  },
  {
    code: 'au',
    prefix: 61,
    name: 'Australia'
  },
  {
    code: 'at',
    prefix: 43,
    name: 'Austria'
  },
  {
    code: 'az',
    prefix: 994,
    name: 'Azerbaijan'
  },
  {
    code: 'bs',
    prefix: 1,
    name: 'Bahamas'
  },
  {
    code: 'bh',
    prefix: 973,
    name: 'Bahrain'
  },
  {
    code: 'bd',
    prefix: 880,
    name: 'Bangladesh'
  },
  {
    code: 'bb',
    prefix: 1,
    name: 'Barbados'
  },
  {
    code: 'by',
    prefix: 375,
    name: 'Belarus'
  },
  {
    code: 'be',
    prefix: 32,
    name: 'Belgium'
  },
  {
    code: 'bz',
    prefix: 501,
    name: 'Belize'
  },
  {
    code: 'bj',
    prefix: 229,
    name: 'Benin'
  },
  {
    code: 'bm',
    prefix: 1,
    name: 'Bermuda'
  },
  {
    code: 'bt',
    prefix: 975,
    name: 'Bhutan'
  },
  {
    code: 'ba',
    prefix: 387,
    name: 'Bosnia and Herzegovina'
  },
  {
    code: 'bw',
    prefix: 267,
    name: 'Botswana'
  },
  {
    code: 'br',
    prefix: 55,
    name: 'Brazil'
  },
  {
    code: 'io',
    prefix: 246,
    name: 'British Indian Ocean Territory'
  },
  {
    code: 'bg',
    prefix: 359,
    name: 'Bulgaria'
  },
  {
    code: 'bf',
    prefix: 226,
    name: 'Burkina Faso'
  },
  {
    code: 'bi',
    prefix: 257,
    name: 'Burundi'
  },
  {
    code: 'kh',
    prefix: 855,
    name: 'Cambodia'
  },
  {
    code: 'cm',
    prefix: 237,
    name: 'Cameroon'
  },
  {
    code: 'ca',
    prefix: 1,
    name: 'Canada'
  },
  {
    code: 'cv',
    prefix: 238,
    name: 'Cape Verde'
  },
  {
    code: 'ky',
    prefix: 0,
    name: 'Cayman Islands'
  },
  {
    code: 'cf',
    prefix: 236,
    name: 'Central African Republic'
  },
  {
    code: 'td',
    prefix: 235,
    name: 'Chad'
  },
  {
    code: 'cl',
    prefix: 56,
    name: 'Chile'
  },
  {
    code: 'cn',
    prefix: 86,
    name: 'China'
  },
  {
    code: 'cx',
    prefix: 61,
    name: 'Christmas Island'
  },
  {
    code: 'co',
    prefix: 57,
    name: 'Colombia'
  },
  {
    code: 'km',
    prefix: 269,
    name: 'Comoros'
  },
  {
    code: 'cg',
    prefix: 242,
    name: 'Congo'
  },
  {
    code: 'ck',
    prefix: 682,
    name: 'Cook Islands'
  },
  {
    code: 'cr',
    prefix: 506,
    name: 'Costa Rica'
  },
  {
    code: 'hr',
    prefix: 385,
    name: 'Croatia'
  },
  {
    code: 'cu',
    prefix: 53,
    name: 'Cuba'
  },
  {
    code: 'cy',
    prefix: 537,
    name: 'Cyprus'
  },
  {
    code: 'cz',
    prefix: 420,
    name: 'Czech Republic'
  },
  {
    code: 'dk',
    prefix: 45,
    name: 'Denmark'
  },
  {
    code: 'dj',
    prefix: 253,
    name: 'Djibouti'
  },
  {
    code: 'dm',
    prefix: 1,
    name: 'Dominica'
  },
  {
    code: 'do',
    prefix: 1,
    name: 'Dominican Republic'
  },
  {
    code: 'ec',
    prefix: 593,
    name: 'Ecuador'
  },
  {
    code: 'eg',
    prefix: 20,
    name: 'Egypt'
  },
  {
    code: 'sv',
    prefix: 503,
    name: 'El Salvador'
  },
  {
    code: 'gq',
    prefix: 240,
    name: 'Equatorial Guinea'
  },
  {
    code: 'er',
    prefix: 291,
    name: 'Eritrea'
  },
  {
    code: 'ee',
    prefix: 372,
    name: 'Estonia'
  },
  {
    code: 'et',
    prefix: 251,
    name: 'Ethiopia'
  },
  {
    code: 'fo',
    prefix: 298,
    name: 'Faroe Islands'
  },
  {
    code: 'fj',
    prefix: 679,
    name: 'Fiji'
  },
  {
    code: 'fi',
    prefix: 358,
    name: 'Finland'
  },
  {
    code: 'fr',
    prefix: 33,
    name: 'France'
  },
  {
    code: 'gf',
    prefix: 594,
    name: 'French Guiana'
  },
  {
    code: 'pf',
    prefix: 689,
    name: 'French Polynesia'
  },
  {
    code: 'ga',
    prefix: 241,
    name: 'Gabon'
  },
  {
    code: 'gm',
    prefix: 220,
    name: 'Gambia'
  },
  {
    code: 'ge',
    prefix: 995,
    name: 'Georgia'
  },
  {
    code: 'de',
    prefix: 49,
    name: 'Germany'
  },
  {
    code: 'gh',
    prefix: 233,
    name: 'Ghana'
  },
  {
    code: 'gi',
    prefix: 350,
    name: 'Gibraltar'
  },
  {
    code: 'gr',
    prefix: 30,
    name: 'Greece'
  },
  {
    code: 'gl',
    prefix: 299,
    name: 'Greenland'
  },
  {
    code: 'gd',
    prefix: 1,
    name: 'Grenada'
  },
  {
    code: 'gp',
    prefix: 590,
    name: 'Guadeloupe'
  },
  {
    code: 'gu',
    prefix: 1,
    name: 'Guam'
  },
  {
    code: 'gt',
    prefix: 502,
    name: 'Guatemala'
  },
  {
    code: 'gn',
    prefix: 224,
    name: 'Guinea'
  },
  {
    code: 'gw',
    prefix: 245,
    name: 'Guinea-Bissau'
  },
  {
    code: 'gy',
    prefix: 595,
    name: 'Guyana'
  },
  {
    code: 'ht',
    prefix: 509,
    name: 'Haiti'
  },
  {
    code: 'hn',
    prefix: 504,
    name: 'Honduras'
  },
  {
    code: 'hu',
    prefix: 36,
    name: 'Hungary'
  },
  {
    code: 'is',
    prefix: 354,
    name: 'Iceland'
  },
  {
    code: 'in',
    prefix: 91,
    name: 'India'
  },
  {
    code: 'id',
    prefix: 62,
    name: 'Indonesia'
  },
  {
    code: 'iq',
    prefix: 964,
    name: 'Iraq'
  },
  {
    code: 'ie',
    prefix: 353,
    name: 'Ireland'
  },
  {
    code: 'il',
    prefix: 972,
    name: 'Israel'
  },
  {
    code: 'it',
    prefix: 39,
    name: 'Italy'
  },
  {
    code: 'jm',
    prefix: 1,
    name: 'Jamaica'
  },
  {
    code: 'jp',
    prefix: 81,
    name: 'Japan'
  },
  {
    code: 'jo',
    prefix: 962,
    name: 'Jordan'
  },
  {
    code: 'kz',
    prefix: 7,
    name: 'Kazakhstan'
  },
  {
    code: 'ke',
    prefix: 254,
    name: 'Kenya'
  },
  {
    code: 'ki',
    prefix: 686,
    name: 'Kiribati'
  },
  {
    code: 'kw',
    prefix: 965,
    name: 'Kuwait'
  },
  {
    code: 'kg',
    prefix: 996,
    name: 'Kyrgyzstan'
  },
  {
    code: 'lv',
    prefix: 371,
    name: 'Latvia'
  },
  {
    code: 'lb',
    prefix: 961,
    name: 'Lebanon'
  },
  {
    code: 'ls',
    prefix: 266,
    name: 'Lesotho'
  },
  {
    code: 'lr',
    prefix: 231,
    name: 'Liberia'
  },
  {
    code: 'li',
    prefix: 423,
    name: 'Liechtenstein'
  },
  {
    code: 'lt',
    prefix: 370,
    name: 'Lithuania'
  },
  {
    code: 'lu',
    prefix: 352,
    name: 'Luxembourg'
  },
  {
    code: 'mg',
    prefix: 261,
    name: 'Madagascar'
  },
  {
    code: 'mw',
    prefix: 265,
    name: 'Malawi'
  },
  {
    code: 'my',
    prefix: 60,
    name: 'Malaysia'
  },
  {
    code: 'mv',
    prefix: 960,
    name: 'Maldives'
  },
  {
    code: 'ml',
    prefix: 223,
    name: 'Mali'
  },
  {
    code: 'mt',
    prefix: 356,
    name: 'Malta'
  },
  {
    code: 'mh',
    prefix: 692,
    name: 'Marshall Islands'
  },
  {
    code: 'mq',
    prefix: 596,
    name: 'Martinique'
  },
  {
    code: 'mr',
    prefix: 222,
    name: 'Mauritania'
  },
  {
    code: 'mu',
    prefix: 230,
    name: 'Mauritius'
  },
  {
    code: 'yt',
    prefix: 262,
    name: 'Mayotte'
  },
  {
    code: 'mx',
    prefix: 52,
    name: 'Mexico'
  },
  {
    code: 'mc',
    prefix: 377,
    name: 'Monaco'
  },
  {
    code: 'mn',
    prefix: 976,
    name: 'Mongolia'
  },
  {
    code: 'me',
    prefix: 382,
    name: 'Montenegro'
  },
  {
    code: 'ms',
    prefix: 1664,
    name: 'Montserrat'
  },
  {
    code: 'ma',
    prefix: 212,
    name: 'Morocco'
  },
  {
    code: 'mm',
    prefix: 95,
    name: 'Myanmar'
  },
  {
    code: 'na',
    prefix: 264,
    name: 'Namibia'
  },
  {
    code: 'nr',
    prefix: 674,
    name: 'Nauru'
  },
  {
    code: 'np',
    prefix: 977,
    name: 'Nepal'
  },
  {
    code: 'nl',
    prefix: 31,
    name: 'Netherlands'
  },
  {
    code: 'an',
    prefix: 599,
    name: 'Netherlands Antilles'
  },
  {
    code: 'nc',
    prefix: 687,
    name: 'New Caledonia'
  },
  {
    code: 'nz',
    prefix: 64,
    name: 'New Zealand'
  },
  {
    code: 'ni',
    prefix: 505,
    name: 'Nicaragua'
  },
  {
    code: 'ne',
    prefix: 227,
    name: 'Niger'
  },
  {
    code: 'ng',
    prefix: 234,
    name: 'Nigeria'
  },
  {
    code: 'nu',
    prefix: 683,
    name: 'Niue'
  },
  {
    code: 'nf',
    prefix: 672,
    name: 'Norfolk Island'
  },
  {
    code: 'mp',
    prefix: 1,
    name: 'Northern Mariana Islands'
  },
  {
    code: 'no',
    prefix: 47,
    name: 'Norway'
  },
  {
    code: 'om',
    prefix: 968,
    name: 'Oman'
  },
  {
    code: 'pk',
    prefix: 92,
    name: 'Pakistan'
  },
  {
    code: 'pw',
    prefix: 680,
    name: 'Palau'
  },
  {
    code: 'pa',
    prefix: 507,
    name: 'Panama'
  },
  {
    code: 'pg',
    prefix: 675,
    name: 'Papua New Guinea'
  },
  {
    code: 'py',
    prefix: 595,
    name: 'Paraguay'
  },
  {
    code: 'pe',
    prefix: 51,
    name: 'Peru'
  },
  {
    code: 'ph',
    prefix: 63,
    name: 'Philippines'
  },
  {
    code: 'pl',
    prefix: 48,
    name: 'Poland'
  },
  {
    code: 'pt',
    prefix: 351,
    name: 'Portugal'
  },
  {
    code: 'pr',
    prefix: 1,
    name: 'Puerto Rico'
  },
  {
    code: 'qa',
    prefix: 974,
    name: 'Qatar'
  },
  {
    code: 'ro',
    prefix: 40,
    name: 'Romania'
  },
  {
    code: 'rw',
    prefix: 250,
    name: 'Rwanda'
  },
  {
    code: 'ws',
    prefix: 685,
    name: 'Samoa'
  },
  {
    code: 'sm',
    prefix: 378,
    name: 'San Marino'
  },
  {
    code: 'sa',
    prefix: 966,
    name: 'Saudi Arabia'
  },
  {
    code: 'sn',
    prefix: 221,
    name: 'Senegal'
  },
  {
    code: 'rs',
    prefix: 381,
    name: 'Serbia'
  },
  {
    code: 'sc',
    prefix: 248,
    name: 'Seychelles'
  },
  {
    code: 'sl',
    prefix: 232,
    name: 'Sierra Leone'
  },
  {
    code: 'sg',
    prefix: 65,
    name: 'Singapore'
  },
  {
    code: 'sk',
    prefix: 421,
    name: 'Slovakia'
  },
  {
    code: 'si',
    prefix: 386,
    name: 'Slovenia'
  },
  {
    code: 'sb',
    prefix: 677,
    name: 'Solomon Islands'
  },
  {
    code: 'za',
    prefix: 27,
    name: 'South Africa'
  },
  {
    code: 'gs',
    prefix: 500,
    name: 'South Georgia and the South Sandwich Islands'
  },
  {
    code: 'es',
    prefix: 34,
    name: 'Spain'
  },
  {
    code: 'lk',
    prefix: 94,
    name: 'Sri Lanka'
  },
  {
    code: 'sd',
    prefix: 249,
    name: 'Sudan'
  },
  {
    code: 'sr',
    prefix: 597,
    name: 'Suriname'
  },
  {
    code: 'sz',
    prefix: 268,
    name: 'Swaziland'
  },
  {
    code: 'se',
    prefix: 46,
    name: 'Sweden'
  },
  {
    code: 'ch',
    prefix: 41,
    name: 'Switzerland'
  },
  {
    code: 'tj',
    prefix: 992,
    name: 'Tajikistan'
  },
  {
    code: 'th',
    prefix: 66,
    name: 'Thailand'
  },
  {
    code: 'tg',
    prefix: 228,
    name: 'Togo'
  },
  {
    code: 'tk',
    prefix: 690,
    name: 'Tokelau'
  },
  {
    code: 'to',
    prefix: 676,
    name: 'Tonga'
  },
  {
    code: 'tt',
    prefix: 1,
    name: 'Trinidad and Tobago'
  },
  {
    code: 'tn',
    prefix: 216,
    name: 'Tunisia'
  },
  {
    code: 'tr',
    prefix: 90,
    name: 'Turkey'
  },
  {
    code: 'tm',
    prefix: 993,
    name: 'Turkmenistan'
  },
  {
    code: 'tc',
    prefix: 1,
    name: 'Turks and Caicos Islands'
  },
  {
    code: 'tv',
    prefix: 688,
    name: 'Tuvalu'
  },
  {
    code: 'ug',
    prefix: 256,
    name: 'Uganda'
  },
  {
    code: 'ua',
    prefix: 380,
    name: 'Ukraine'
  },
  {
    code: 'ae',
    prefix: 971,
    name: 'United Arab Emirates'
  },
  {
    code: 'gb',
    prefix: 44,
    name: 'United Kingdom'
  },
  {
    code: 'us',
    prefix: 1,
    name: 'United States'
  },
  {
    code: 'uy',
    prefix: 598,
    name: 'Uruguay'
  },
  {
    code: 'uz',
    prefix: 998,
    name: 'Uzbekistan'
  },
  {
    code: 'vu',
    prefix: 678,
    name: 'Vanuatu'
  },
  {
    code: 'wf',
    prefix: 681,
    name: 'Wallis and Futuna'
  },
  {
    code: 'ye',
    prefix: 967,
    name: 'Yemen'
  },
  {
    code: 'zm',
    prefix: 260,
    name: 'Zambia'
  },
  {
    code: 'zw',
    prefix: 263,
    name: 'Zimbabwe'
  },
  {
    code: 'ax',
    prefix: 0,
    name: 'land Islands'
  },
  {
    code: 'bo',
    prefix: 591,
    name: 'Bolivia, Plurinational State of'
  },
  {
    code: 'bn',
    prefix: 673,
    name: 'Brunei Darussalam'
  },
  {
    code: 'cc',
    prefix: 61,
    name: 'Cocos (Keeling) Islands'
  },
  {
    code: 'cd',
    prefix: 243,
    name: 'Congo, The Democratic Republic of the'
  },
  {
    code: 'ci',
    prefix: 225,
    name: "Cote d'Ivoire"
  },
  {
    code: 'fk',
    prefix: 500,
    name: 'Falkland Islands (Malvinas)'
  },
  {
    code: 'gg',
    prefix: 44,
    name: 'Guernsey'
  },
  {
    code: 'va',
    prefix: 379,
    name: 'Holy See (Vatican City State)'
  },
  {
    code: 'hk',
    prefix: 852,
    name: 'Hong Kong'
  },
  {
    code: 'ir',
    prefix: 98,
    name: 'Iran, Islamic Republic of'
  },
  {
    code: 'im',
    prefix: 44,
    name: 'Isle of Man'
  },
  {
    code: 'je',
    prefix: 44,
    name: 'Jersey'
  },
  {
    code: 'kp',
    prefix: 850,
    name: "Korea, Democratic People's Republic of"
  },
  {
    code: 'kr',
    prefix: 82,
    name: 'Korea, Republic of'
  },
  {
    code: 'la',
    prefix: 856,
    name: "Lao People's Democratic Republic"
  },
  {
    code: 'ly',
    prefix: 218,
    name: 'Libyan Arab Jamahiriya'
  },
  {
    code: 'mo',
    prefix: 853,
    name: 'Macao'
  },
  {
    code: 'mk',
    prefix: 389,
    name: 'Macedonia, The Former Yugoslav Republic of'
  },
  {
    code: 'fm',
    prefix: 691,
    name: 'Micronesia, Federated States of'
  },
  {
    code: 'md',
    prefix: 373,
    name: 'Moldova, Republic of'
  },
  {
    code: 'mz',
    prefix: 258,
    name: 'Mozambique'
  },
  {
    code: 'ps',
    prefix: 970,
    name: 'Palestinian Territory, Occupied'
  },
  {
    code: 'pn',
    prefix: 872,
    name: 'Pitcairn'
  },
  {
    code: 're',
    prefix: 262,
    name: 'RÃ©union'
  },
  {
    code: 'ru',
    prefix: 7,
    name: 'Russia'
  },
  {
    code: 'bl',
    prefix: 590,
    name: 'Saint BarthÃ©lemy'
  },
  {
    code: 'sh',
    prefix: 290,
    name: 'Saint Helena, Ascension and Tristan Da Cunha'
  },
  {
    code: 'kn',
    prefix: 1,
    name: 'Saint Kitts and Nevis'
  },
  {
    code: 'lc',
    prefix: 1,
    name: 'Saint Lucia'
  },
  {
    code: 'mf',
    prefix: 590,
    name: 'Saint Martin'
  },
  {
    code: 'pm',
    prefix: 508,
    name: 'Saint Pierre and Miquelon'
  },
  {
    code: 'vc',
    prefix: 1,
    name: 'Saint Vincent and the Grenadines'
  },
  {
    code: 'st',
    prefix: 239,
    name: 'Sao Tome and Principe'
  },
  {
    code: 'so',
    prefix: 252,
    name: 'Somalia'
  },
  {
    code: 'sj',
    prefix: 47,
    name: 'Svalbard and Jan Mayen'
  },
  {
    code: 'sy',
    prefix: 963,
    name: 'Syrian Arab Republic'
  },
  {
    code: 'tw',
    prefix: 886,
    name: 'Taiwan, Province of China'
  },
  {
    code: 'tz',
    prefix: 255,
    name: 'Tanzania, United Republic of'
  },
  {
    code: 'tl',
    prefix: 670,
    name: 'Timor-Leste'
  },
  {
    code: 've',
    prefix: 58,
    name: 'Venezuela, Bolivarian Republic of'
  },
  {
    code: 'vn',
    prefix: 84,
    name: 'Viet Nam'
  },
  {
    code: 'vg',
    prefix: 1,
    name: 'Virgin Islands, British'
  },
  {
    code: 'vi',
    prefix: 1,
    name: 'Virgin Islands, U.S.'
  }
];
