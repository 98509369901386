<template>
  <div>
    <BaseGrid container>
      <BaseGrid :size="7">
        <BaseHeading :mb="0.25">
          {{ title }}
        </BaseHeading>
        <BaseText>
          {{ description }}
        </BaseText>
      </BaseGrid>
      <BaseGrid alignRight alignCenter>
        <BaseButton
          color="inverted"
          @click="goToRoute"
          v-test="`visit-${buttonRoute}`"
        >
          {{ buttonText }}
        </BaseButton>
      </BaseGrid>
    </BaseGrid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['mixpanel'],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonRoute: {
      type: String,
      required: true
    },
    mixpanelEvent: {
      type: String
    }
  },
  methods: {
    goToRoute() {
      this.$router.push({ name: this.buttonRoute });
      if (this.mixpanelEvent) {
        return this.mixpanel.track(this.mixpanelEvent);
      }
    }
  }
});
</script>
