<template>
  <BaseText bold mb>
    {{ $t('onboarding.new_onboarding.services.title') }}
  </BaseText>
  <div :class="$style.baseContainer">
    <BaseButtonList
      v-model="categoryName"
      :options="categories"
      v-test="'btn-onboarding-category'"
    />
    <img
      v-if="$screen !== 's'"
      :src="`/img/onboarding/${companyImage}.svg`"
      :class="$style.img"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { companyImage } from './onboarding-utils';
const mixpanel = inject<any>('mixpanel');
const emits = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: string;
  categories: any[];
}>();

const categoryName = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
    mixpanel.track('Onboarding journey - Category selected', {
      choice: value
    });
  }
});
</script>

<style lang="scss" module>
.baseContainer {
  display: flex;
  align-items: center;
}

.img {
  height: 175px;
}
</style>
